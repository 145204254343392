import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EPrefix } from '../../../model/EPrefix';
import { UserData } from '../../../model/application/UserData';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { Store } from '../../../services/store.service';

@Injectable()
export class SettingsService {

	//#region METHODS

	constructor(private readonly isvcStore: Store) { }

	/** Récupère le document des préférences de l'utilisateur connecté. */
	public get$<T extends IStoreDocument>(): Observable<T> {
		const lsId = `${EPrefix.settings}${UserHelper.getUserGuid(UserData.current?.name)}`;
		const loDataSource: IDataSource<T> = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.userContext),
			viewParams: {
				key: lsId,
				include_docs: true
			},
			live: true
		};

		return this.isvcStore.getOne<T>(loDataSource, false)
			.pipe(map((poUserSettings: T | undefined) => poUserSettings ?? { _id: lsId } as T));
	}

	/** Met à jour les préférences utilisateur.
	 * @param poSetting Document des préférences utilisateur.
	 */
	public updateAsync(poSetting: IStoreDocument): Promise<void> {
		const lsDatabaseId: string = StoreHelper.getDatabaseIdFromCacheData(
			poSetting,
			ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.userContext))
		);

		return this.isvcStore.put(StoreHelper.getCleanedDocument(poSetting), lsDatabaseId).pipe(mapTo(undefined)).toPromise();
	}

	//#endregion METHODS

}