import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, defer, from, of } from 'rxjs';
import { mapTo, mergeMap, startWith, tap } from 'rxjs/operators';
import { ObjectHelper } from '../../../../../../helpers/objectHelper';
import { secure } from '../../../../../utils/rxjs/operators/secure';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';

interface IMetaField {
	modelKey: string;
	value: any;
	oneTimeFilled: boolean;
}

@Component({
	selector: 'calao-document-meta-field',
	templateUrl: './document-meta-field.component.html',
	styleUrls: ['./document-meta-field.component.scss'],
})
export class DocumentMetaFieldComponent extends FieldBase<{}> implements OnInit {

	//#region METHODS

	public params: { fields: { [key: string]: IMetaField } };

	constructor(
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.params = this.to.data ?? { fields: {} };
		this.fieldValue = this.fieldValue ?? {};

		this.init();
	}

	private init(): void {
		const laValueChangesListeners: Observable<void>[] = [];

		Object.entries(this.params.fields).forEach(([psKey, poValue]: [string, IMetaField]) => {
			if (poValue.modelKey) {
				const loFormControl: AbstractControl<any, any> | undefined = this.form.controls[poValue.modelKey];
				const loUpdateFieldValue$ = defer(() => loFormControl ?
					loFormControl.valueChanges.pipe(startWith(this.model[poValue.modelKey])) :
					of(this.model[poValue.modelKey])
				).pipe(
					tap((poFieldValue: any) => {
						if (ObjectHelper.isDefined(poFieldValue)) {
							this.fieldValue[psKey] = poFieldValue;
							this.formControl.patchValue(this.fieldValue);
						}
					}),
					mapTo(undefined)
				);
				laValueChangesListeners.push(loUpdateFieldValue$);
			}
			else if (ObjectHelper.isDefined(poValue.value) && (!ObjectHelper.isDefined(this.fieldValue[psKey]) || !poValue.oneTimeFilled)) {
				this.fieldValue[psKey] = poValue.value;
				this.formControl.patchValue(this.fieldValue);
			}
		});

		defer(() => from(laValueChangesListeners)).pipe(
			mergeMap((poValueChanges$: Observable<void>) => poValueChanges$),
			secure(this)
		).subscribe();
	}

	//#endregion

}
