import { NumberHelper } from "../../../helpers/numberHelper";
import { SqlFilesHelper } from "../helpers/sql-files.helper";
import { BadSqlDataSourceHashCodeError } from "./errors/bad-sql-data-source-hash-code-error";
import { TSqliteExtension } from "./tsqlite-extension";

export class SqlDataSource {

	//#region FIELDS

	private static readonly C_SEPARATOR = "_";

	//#endregion FIELDS

	//#region PROPERTIES

	public readonly isRemote: boolean;
	/** Extension du fichier pointée par la source de données, `undefined` si c'est une base de données distante. */
	public readonly extension: TSqliteExtension | undefined;

	//#endregion PROPERTIES

	//#region METHODS

	public constructor(
		public readonly databaseId: string,
		public readonly version: number,
		/** Remote: Url HTTP, Local: Chemin de fichier + nom du fichier. */
		public readonly path: string,
		public readonly ignoreExistenceCheck?: boolean
	) {
		this.isRemote = path.startsWith("http");
		this.extension = SqlFilesHelper.extractExtension(path);

		if (!this.canInit())
			throw new Error();
	}

	private canInit(): boolean {
		return this.databaseId.indexOf(SqlDataSource.C_SEPARATOR) === -1 &&
			this.path.indexOf(SqlDataSource.C_SEPARATOR) === -1 &&
			NumberHelper.isValid(this.version) &&
			(this.isRemote || !!this.extension); // Il faut qu'on soit en "remote" ou qu'on est une extension de valide.
	}

	/** Retourne le hash code de la base de données.  */
	public toHashcode(): string {
		return `${this.databaseId}${SqlDataSource.C_SEPARATOR}${this.version}${SqlDataSource.C_SEPARATOR}${this.path}`;
	}

	/** Transforme le hash code de la base de données en SqlDataSource et le retourne.
	 * @param psHashCode Hash code de la base de données.
	 * @throws Lève une erreur `BadSqlDataSourceHashCodeError` si le hash code n'a pas le bon format.
	 */
	public static fromHashCode(psHashCode: string): SqlDataSource {
		const laValues: string[] = psHashCode.split(SqlDataSource.C_SEPARATOR);
		if (laValues.length !== 3 || !NumberHelper.isStringNumber(laValues[1]))
			throw new BadSqlDataSourceHashCodeError(psHashCode);

		return new SqlDataSource(laValues[0], +laValues[1], laValues[2]);
	}

	//#endregion METHODS

}