import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EPrefix } from '../../../model/EPrefix';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { Store } from '../../../services/store.service';
import { WrongIdFormatError } from '../../logistics/task/models/WrongIdFormat';
import { ITask } from '../../logistics/task/models/itask';
import { IDataSourceRemoteChanges } from '../../store/model/IDataSourceRemoteChanges';
import { ITour } from '../models/ITour';
import { TourService } from '../tour/tour.service';

@Injectable({ providedIn: "root" })
export class AppointmentService {

	//#region FIELDS

	private static readonly C_LOG_ID = "APT.SVC::";

	//#endregion

	//#region METHODS

	constructor(protected readonly isvcStore: Store) { }

	/** Récupère tous les rendez-vous d'une tournée.
	 * @param psTourId Identifiant de la tournée dont il faut récupérer tous les rendez-vous.
	 * @param poActivePageManager Gestionnaire d'activité de l'appelant.
	 */
	public getAppointments<R extends IStoreDocument>(psTourId: string, poActivePageManager?: ActivePageManager): Observable<R[]>;
	/** Récupère tous les rendez-vous d'une tournée.
	 * @param poTour Tournée dont il faut récupérer tous les rendez-vous.
	 */
	public getAppointments<T extends ITour<any>, R extends IStoreDocument>(poTour: T, poActivePageManager?: ActivePageManager): Observable<R[]>;
	public getAppointments<T extends ITour<any>, R extends IStoreDocument>(poTourData: string | T, poActivePageManager?: ActivePageManager): Observable<R[]> {

		const lsKeyRoot = `${this.getAppointmentIdWithoutGuid(poTourData as T)}_`;
		const loDataSource: IDataSourceRemoteChanges | IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				startkey: lsKeyRoot,
				endkey: `${lsKeyRoot}${Store.C_ANYTHING_CODE_ASCII}`,
				include_docs: true
			},
			live: !!poActivePageManager
		};

		if (poActivePageManager) {
			(loDataSource as IDataSourceRemoteChanges).remoteChanges = true;
			(loDataSource as IDataSourceRemoteChanges).activePageManager = poActivePageManager;
		}

		return this.isvcStore.get<R>(loDataSource);
	}

	/** Récupération d'un rendez-vous en base de données, lève une erreur si pas de résultat.
	 * @param psTourId Identifiant de la tournée (avec ou sans préfixe).
	 * @param psAppointmentGuid Guid du rendez-vous (correspond à la dernière partie de l'id d'un rendez-vous -> `appoint_tour_tourGuid_appointGuid`).
	 */
	public getAppointment<T extends IStoreDocument>(psTourId: string, psAppointmentGuid: string): Observable<T> {
		return this.isvcStore.getOne<T>(
			{
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				viewParams: {
					key: this.getAppointmentId(psTourId, psAppointmentGuid),
					include_docs: true
				}
			} as IDataSource
		);
	}

	/** Récupération d'un rendez-vous en base de données, `undefined` si non trouvé.
	 * @param psAppointmentId Identifiant du rendez-vous.
	 */
	public getAppointmentFromId<T extends IStoreDocument>(psAppointmentId: string): Observable<T | undefined> {
		return this.isvcStore.getOne<T>(
			{
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				viewParams: { key: IdHelper.buildId(EPrefix.appoint, psAppointmentId), include_docs: true }
			} as IDataSource,
			false
		);
	}

	/** Retourne le GUID de l'identifiant d'un rendez-vous.
	 * @param poAppointment Rendez-vous dont il faut extraire le GUID.
	 * @example ```typescript
	 * getId({ _id: "appoint_tour_123_456" }); // Retournera "456".
	 * ```
	 * @throws WrongIdFormatError
	 */
	public getGuid<T extends IStoreDocument>(poAppointment: T): string {
		if (poAppointment._id.startsWith(EPrefix.appoint))
			return ArrayHelper.getLastElement(poAppointment._id.split("_"));
		else {
			console.error(`${AppointmentService.C_LOG_ID}Impossible d'extraire l'identifiant de l'appointment "${poAppointment._id}".`);
			throw new WrongIdFormatError();
		}
	}

	/** Récupère l'identifiant de la tournée d'un rendez-vous.
	 * @param psAppoinmentId Identifiant du rendez-vous dont il faut extraire l'identifiant de la tournée.
	 * @example ```typescript
	 * getId({ _id: "appoint_tour_123_456" }); // Retournera "tour_123".
	 * ```
	 * @throws WrongIdFormatError
	 */
	public static getTourId(psAppoinmentId: string): string;
	/** Récupère l'identifiant de la tournée d'un rendez-vous.
	 * @param poAppointment Rendez-vous dont il faut extraire l'identifiant de la tournée.
	 * @example ```typescript
	 * getId({ _id: "appoint_tour_123_456" }); // Retournera "tour_123".
	 * ```
	 * @throws WrongIdFormatError
	 */
	public static getTourId<T extends IStoreDocument>(poAppointment: T): string;
	public static getTourId<T extends IStoreDocument>(poAppointmentData: string | T): string {
		const lsId: string = typeof poAppointmentData === "string" ? poAppointmentData : poAppointmentData._id;

		if (!StringHelper.isBlank(lsId)) {
			const loResult: RegExpMatchArray | null = lsId.match(new RegExp(`${TourService.C_TOUR_PREFIX}[^_]+`));

			if (loResult)
				return ArrayHelper.getFirstElement(loResult);
		}

		console.error(`${AppointmentService.C_LOG_ID}Impossible d'extraire l'identifiant de la tournée depuis l'identifiant du rendez-vous "${lsId}".`);
		throw new WrongIdFormatError();
	}

	/** Récupère l'indentifiant du rendez-vous associé à la tâche.
	 * @param poTask Tâche dont on veut récupérer l'identifiant du rendez-vous.
	 */
	public static getAppointmentIdFromTask(poTask: ITask): string {
		return this.getAppointmentIdFromTaskId(poTask._id);
	}

	/** Récupère l'indentifiant du rendez-vous associé à la tâche.
	 * @param psTaskId Identifiant de la tâche dont on veut récupérer l'identifiant du rendez-vous.
	 */
	public static getAppointmentIdFromTaskId(psTaskId: string): string {
		return psTaskId.split("_").slice(1, -1).join("_");
	}

	/** Construit et retourne l'id d'un rendez-vous.
	 * @param psTourId L'id de la tournée du rendez-vous.
	 * @param psAppointmentGuid Le guid du rendez-vous.
	 */
	public getAppointmentId(psTourId: string, psAppointmentGuid: string): string;
	/** Construit et retourne l'id d'un rendez-vous.
	 * @param poTour La tournée du rendez-vous.
	 * @param psAppointmentGuid Le guid du rendez-vous.
	 */
	public getAppointmentId<T extends ITour<any>>(poTour: T, psAppointmentGuid: string): string;
	public getAppointmentId<T extends ITour<any>>(poTour: string | T, psAppointmentGuid: string): string {
		return `${this.getAppointmentIdWithoutGuid(typeof poTour === "string" ? poTour : poTour._id)}_${psAppointmentGuid}`;
	}

	/** Construit et retourne l'id d'un rendez-vous sans son guid.
	 * @param psTourId L'id de la tournée du rendez-vous.
	 */
	public getAppointmentIdWithoutGuid(psTourId: string): string;
	/** Construit et retourne l'id d'un rendez-vous sans son guid.
	 * @param poTour La tournée du rendez-vous.
	 */
	public getAppointmentIdWithoutGuid<T extends ITour<any>>(poTour: T): string;
	public getAppointmentIdWithoutGuid<T extends ITour<any>>(poTour: string | T): string {
		return `${EPrefix.appoint}${typeof poTour === "string" ? poTour : poTour._id}`;
	}

	//#endregion

}