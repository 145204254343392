import { Type } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ConfigOption, FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { MailHelper } from '../../../helpers/MailHelper';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { ActionButtonFieldMapComponent } from '../../entities/components/entity-form/action-buttons/actionButtonFieldMap.component';
import { ISliderFieldParams } from '../models/ISliderFieldParams';
import { ITextareaFieldParams } from '../models/inputs/ITextareaFieldParams';
import { ActionButtonFieldConversationComponent } from './components/customFields/actionButtons/actionButtonFieldConversation.component';
import { ActionButtonFieldMeetingComponent } from './components/customFields/actionButtons/actionButtonFieldMeeting.component';
import { ActionButtonFieldNavigationComponent } from './components/customFields/actionButtons/actionButtonFieldNavigation.component';
import { ActionButtonFieldPositionComponent } from './components/customFields/actionButtons/actionButtonFieldPosition.component';
import { AiGenerateTextFieldComponent } from './components/customFields/ai-generate-text-field.component';
import { AssignmentFieldComponent } from './components/customFields/assignment-field/assignment-field.component';
import { BoolLabelFieldComponent } from './components/customFields/boolLabelField.component';
import { CheckboxFieldComponent } from './components/customFields/checkbox-field.component';
import { ContactNameFieldComponent } from './components/customFields/contact-name-field.component';
import { ContactsListFieldComponent } from './components/customFields/contactsListField.component';
import { DashboardFieldComponent } from './components/customFields/dashboardField.component';
import { DateFieldComponent } from './components/customFields/date-field/date-field.component';
import { DateTimeSpinnerFieldComponent } from './components/customFields/dateTimeSpinnerField.component';
import { DocumentMetaFieldComponent } from './components/customFields/document-meta-field/document-meta-field.component';
import { DrawingFieldComponent } from './components/customFields/drawingField.component';
import { EventDurationFieldComponent } from './components/customFields/event-duration-field/event-duration-field.component';
import { EventIconFieldComponent } from './components/customFields/event-icon-field/event-icon-field.component';
import { EventNotificationFieldComponent } from './components/customFields/event-notification-field/event-notification-field.component';
import { FormListFieldComponent } from './components/customFields/formListField.component';
import { GalleryFieldComponent } from './components/customFields/galleryField.component';
import { GroupsChecklistFieldComponent } from './components/customFields/groups-checklist-field.component';
import { HiddenFieldComponent } from './components/customFields/hiddenField.component';
import { HTMLTextEditorFieldComponent } from './components/customFields/htmlTextEditorField.component';
import { ColorFieldComponent } from './components/customFields/inputs/color-field.component';
import { EmailFieldComponent } from './components/customFields/inputs/email-field.component';
import { InputFieldComponent } from './components/customFields/inputs/inputField.component';
import { LocationFieldComponent } from './components/customFields/inputs/locationField.component';
import { MultiInputFieldComponent } from './components/customFields/inputs/multiInputField.component';
import { TextareaFieldComponent } from './components/customFields/inputs/textareaField.component';
import { LabelFieldComponent } from './components/customFields/labelField.component';
import { LikeFieldComponent } from './components/customFields/likeField.component';
import { LinkFieldComponent } from './components/customFields/linkField.component';
import { OptionalDateTimePickerFieldComponent } from './components/customFields/optional-date-time-picker-field/optional-date-time-picker-field.component';
import { PermissionRolesSelectFieldComponent } from './components/customFields/permission-roles-select-field.component';
import { PictureFieldComponent } from './components/customFields/pictureField.component';
import { RecurrencesFieldComponent } from './components/customFields/recurrences-field/recurrences-field.component';
import { RolesSelectorFieldComponent } from './components/customFields/roles-selector-field/roles-selector-field.component';
import { SectorsListFieldComponent } from './components/customFields/sectors-list-field.component';
import { SelectFieldComponent } from './components/customFields/select-field.component';
import { SliderFieldComponent } from './components/customFields/sliderField.component';
import { ToggleFieldComponent } from './components/customFields/toggleField.component';
import { ReadOnlyWrapperComponent } from './components/customWrappers/readonlyWrapper/readOnlyWrapper.component';
import { SectionWrapperComponent } from './components/customWrappers/sectionWrapper/sectionWrapper.component';

/** Clé pour le champs `email`. */
const emailKey = "email";
/** Clé pour le champs `zipCode`. */
const zipCodeKey = "zipCode";
/** Objet d'erreur de validation du champ `email` (email non valide). */
const emailValidationErrors = { [emailKey]: true };
const zipCodeValidationErrors = { [zipCodeKey]: true };
/** Clé pour le champs `roles`. */
const rolesKey = "roles";

/** Fonction permettant de valider un champs email. */
export function isNotValidEmail(poControl: AbstractControl, poField: FormlyFieldConfig): ValidationErrors {
	if (StringHelper.isBlank(poControl.value))
		return poField.templateOptions.required ? emailValidationErrors : null;
	else
		return MailHelper.isValid(poControl.value) ? null : emailValidationErrors;
}

export function isNotValidZipCode(poControl: AbstractControl, poField: FormlyFieldConfig): ValidationErrors {
	if (StringHelper.isBlank(poControl.value))
		return poField.templateOptions.required ? zipCodeValidationErrors : null;
	else
		return /^[0-9]{1,5}$/.test(poControl.value) ? null : zipCodeValidationErrors;
}

/** Fonction permettant de valider que le champs roles contient un élément. */
export function hasRole(poControl: AbstractControl, poField: FormlyFieldConfig): ValidationErrors {
	if (poField.props?.required)
		return ArrayHelper.hasElements(poControl.value) ? null : { [poField.key?.toString()]: true };
	else
		return null;
}

export function readonlySwitchExtension(poField: FormlyFieldConfig): void {
	const lbIsReadOnly: boolean = poField.templateOptions && poField.templateOptions.data && poField.templateOptions.data.readOnly;

	if (lbIsReadOnly && poField.wrappers && poField.wrappers.indexOf("readOnly") === -1)
		poField.wrappers = ["readOnly"];
}

/** Ce tableau contient l'ensemble des composants de liens customs. Ils seront inclus via ce tableau dans le module supérieur. */
export const C_FORMLY_CUSTOM: Array<Type<FieldType>> = [
	InputFieldComponent,
	BoolLabelFieldComponent,
	ContactsListFieldComponent,
	ContactNameFieldComponent,
	DashboardFieldComponent,
	FormListFieldComponent,
	HTMLTextEditorFieldComponent,
	LabelFieldComponent,
	LinkFieldComponent,
	ActionButtonFieldConversationComponent,
	ActionButtonFieldNavigationComponent,
	ActionButtonFieldPositionComponent,
	ActionButtonFieldMapComponent,
	PictureFieldComponent,
	SelectFieldComponent,
	SliderFieldComponent,
	MultiInputFieldComponent,
	DateTimeSpinnerFieldComponent,
	ToggleFieldComponent,
	HiddenFieldComponent,
	GalleryFieldComponent,
	DrawingFieldComponent,
	LikeFieldComponent,
	TextareaFieldComponent,
	SectionWrapperComponent,
	ReadOnlyWrapperComponent,
	GroupsChecklistFieldComponent,
	PermissionRolesSelectFieldComponent,
	EmailFieldComponent,
	ColorFieldComponent,
	SectorsListFieldComponent,
	RecurrencesFieldComponent,
	EventDurationFieldComponent,
	RolesSelectorFieldComponent,
	EventIconFieldComponent,
	DocumentMetaFieldComponent,
	ActionButtonFieldMeetingComponent,
	AssignmentFieldComponent,
	OptionalDateTimePickerFieldComponent,
	CheckboxFieldComponent,
	LocationFieldComponent,
	AiGenerateTextFieldComponent,
	EventNotificationFieldComponent,
	DateFieldComponent
];

/** Configuration des formulaires. Cette config doit être incluse en forRoot lors de l'inclusion du module FormlyModule. */
export const FormlyConfig: ConfigOption = {
	validators: [
		{ name: emailKey, validation: isNotValidEmail },
		{ name: zipCodeKey, validation: isNotValidZipCode },
		{ name: rolesKey, validation: hasRole }
	],
	/** Messages de validation. */
	validationMessages: [
		{ name: "required", message: "Le champs doit être renseigné" },
		{ name: emailKey, message: "Veuillez entrer une adresse valide" },
		{ name: zipCodeKey, message: "Veuillez entrer un code postal valide" }
	],
	/** Configuration des champs customs. */
	types: [
		{
			name: "input",
			component: InputFieldComponent
		},
		{
			name: emailKey,
			component: EmailFieldComponent,
			extends: "input",
			defaultOptions: {
				validators: {
					validation: [emailKey]
				}
			}
		},
		{
			name: "boolLabel",
			component: BoolLabelFieldComponent
		},
		{
			name: "contactsList",
			component: ContactsListFieldComponent
		},
		{
			name: "contactName",
			component: ContactNameFieldComponent
		},
		{
			name: "dashboard",
			component: DashboardFieldComponent
		},
		{
			name: "dateTimeSpinner",
			component: DateTimeSpinnerFieldComponent,
			wrappers: ["form-field"]
		},
		{
			name: "formList",
			component: FormListFieldComponent
		},
		{
			name: "locationField",
			component: LocationFieldComponent
		},
		{
			name: "label",
			component: LabelFieldComponent
		},
		{
			name: "link",
			component: LinkFieldComponent
		},
		{
			name: "actionButtonConversation",
			component: ActionButtonFieldConversationComponent
		},
		{
			name: "actionButtonMeeting",
			component: ActionButtonFieldMeetingComponent
		},
		{
			name: "actionButtonNavigation",
			component: ActionButtonFieldNavigationComponent
		},
		{
			name: "actionButtonPosition",
			component: ActionButtonFieldPositionComponent
		},
		{
			name: "actionButtonMap",
			component: ActionButtonFieldMapComponent
		},
		{
			name: "picture",
			component: PictureFieldComponent
		},
		{
			name: "select",
			component: SelectFieldComponent
		},
		{
			name: "slider",
			component: SliderFieldComponent,
			defaultOptions: {
				wrappers: [
					"form-field"
				],
				props: {
					data: {
						readOnly: true,
						placeholder: "",
						minValue: 0,
						maxValue: 100,
						step: 1,
						pin: true,
						ticks: false,
						snaps: true
					} as ISliderFieldParams
				}
			}
		},
		{
			name: "multiInput",
			component: MultiInputFieldComponent
		},
		{
			name: "toggleField",
			component: ToggleFieldComponent
		},
		{
			name: "hidden",
			component: HiddenFieldComponent
		},
		{
			name: "gallery",
			component: GalleryFieldComponent
		},
		{
			name: "drawing",
			component: DrawingFieldComponent
		},
		{
			name: "like",
			component: LikeFieldComponent
		},
		{
			name: "textarea",
			component: TextareaFieldComponent,
			defaultOptions: {
				templateOptions: {
					data: {
						label: "",
						placeholder: ""
					} as ITextareaFieldParams
				}
			}
		},
		{
			name: "buttonAi",
			component: AiGenerateTextFieldComponent,
		},
		{
			name: "htmlTextEditor",
			component: HTMLTextEditorFieldComponent,
			wrappers: ["form-field"],
			defaultOptions: {
				templateOptions: {
					floatLabel: "always"
				}
			}
		},
		{
			name: "groupsChecklist",
			component: GroupsChecklistFieldComponent
		},
		{
			name: "sectorsList",
			component: SectorsListFieldComponent
		},
		{
			name: "permissionRolesSelect",
			component: PermissionRolesSelectFieldComponent
		},
		{
			name: "color",
			component: ColorFieldComponent
		},
		{
			name: "recurrences",
			component: RecurrencesFieldComponent,
			wrappers: ["form-field"]
		},
		{
			name: "eventDuration",
			component: EventDurationFieldComponent,
			wrappers: ["form-field"]
		},
		{
			name: "rolesSelector",
			component: RolesSelectorFieldComponent,
			defaultOptions: {
				validators: {
					validation: [rolesKey]
				}
			}
		},
		{
			name: "eventIcon",
			component: EventIconFieldComponent
		},
		{
			name: "assignment",
			component: AssignmentFieldComponent
		},
		{
			name: "documentMeta",
			component: DocumentMetaFieldComponent,
			wrappers: ["readOnly"]
		},
		{
			name: "optionalDateTimePicker",
			component: OptionalDateTimePickerFieldComponent
		},
		{
			name: "checkbox",
			component: CheckboxFieldComponent
		},
		{
			name: "reminder",
			component: EventNotificationFieldComponent
		},
		{
			name: "date",
			component: DateFieldComponent
		},
	],
	wrappers: [
		{
			name: "section",
			component: SectionWrapperComponent
		},
		{
			name: "readOnly",
			component: ReadOnlyWrapperComponent
		}
	],
	extensions: [
		{
			name: "readOnlySwitch",
			extension: { onPopulate: readonlySwitchExtension }
		}
	]
};