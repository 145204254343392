import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ISettingItemParams } from '../models/isetting-item-params';

@Component({
	selector: 'calao-setting-item',
	templateUrl: './setting-item.component.html',
	styleUrls: ['./setting-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingItemComponent extends ComponentBase implements ISettingItemParams {

	//#region PROPERTIES

	private msLabel?: string;

	/**@implements */
	public get label(): string {
		return this.msLabel;
	}

	@Input() public set label(psLabel: string) {
		if (psLabel !== this.msLabel) {
			this.msLabel = psLabel;
			this.detectChanges();
		}
	}

	private msValue?: string;

	/**@implements */
	public get value(): string {
		return this.msValue;
	}

	@Input() public set value(psValue: string) {
		if (psValue !== this.msValue) {
			this.msValue = psValue;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	//#endregion

}
