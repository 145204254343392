import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HeaderModule } from '../../header/header.module';
import { BluetoothModule } from '../bluetooth/bluetooth.module';
import { TslService } from '../devices/tsl/tsl.service';
import { AdvancedReadingRepresentationComponent } from './advanced-reading-representation/advanced-reading-representation.component';
import { ReadingModalDebug } from './reading-modal-debug/reading-modal-debug.component';
import { ReadingPage } from './reading-page.component';
import { ReadingService } from './reading.service';
import { SimpleReadingRepresentationComponent } from './simple-reading-representation/simple-reading-representation.component';

@NgModule({
	declarations: [
		ReadingPage,
		SimpleReadingRepresentationComponent,
		AdvancedReadingRepresentationComponent,
		ReadingModalDebug
	],
	imports: [
		CommonModule,
		IonicModule,
		HeaderModule,
		BluetoothModule,
		ScrollingModule
	],
	providers: [
		ReadingService,
		TslService
	]
})
export class ReadingPageModule { }