import { Directory, FileInfo } from "@capacitor/filesystem";
import { ArrayHelper } from "../../../../helpers/arrayHelper";
import { MapHelper } from "../../../../helpers/mapHelper";
import { StringHelper } from "../../../../helpers/stringHelper";
import { LoadingService } from "../../../../services/loading.service";
import { PlatformService } from "../../../../services/platform.service";
import { FilesystemService } from "../../../filesystem/services/filesystem.service";
import { Loader } from "../../../loading/Loader";
import { ELogActionId } from "../../../logger/models/ELogActionId";
import { LoggerService } from "../../../logger/services/logger.service";
import { PatchBase } from "../../../patch/patches/patch-base";
import { SqlDatabase } from "../sql-database";

export abstract class RemoveInvalidSqlDatabasesPatchBase extends PatchBase {

	//#region FIELDS

	/** Regex pour les fichiers se terminant par ".tmp" ou ".tmp.db" ou ".zip" -> suppression automatique. */
	private static readonly C_AUTO_REMOVABLE_REGEX = /\.(tmp|tmp\.db|zip)$/;

	protected static readonly C_LOG_ID = "RMV.INVLD.SQL.DB.PATCH.BASE::";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcLoading: LoadingService,
		private readonly isvcFilesystem: FilesystemService,
		private readonly isvcLogger: LoggerService,
		private readonly isvcPlatform: PlatformService,
		psDescription: string
	) {
		super(psDescription);
	}

	/** Chemin vers les bases de données sqlite à traiter. */
	protected abstract getSqlDatabasesPath(): string;

	/** Chemin vers le dossier des bases de données sqlite à traiter. */
	protected abstract getSqlDatabasesDirectoryPath(): Directory;

	/** Préfixe des bases de données à traiter. */
	protected abstract getDatabasesPrefix(): string;

	/** Regex permettant de délimiter le nommage possible des bases de données à traiter (sans extension). */
	protected abstract getDatabaseNameRegex(): RegExp;

	public override async applyPatchAsync(): Promise<boolean> {
		const lsSqlDatabasesPath: string = this.getSqlDatabasesPath();

		if (!this.isvcPlatform.isMobileApp) // Si pas sur app mobile, on peut pas appliquer le patch.
			return true;
		else if (StringHelper.isBlank(lsSqlDatabasesPath)) { // Pas de chemin, on passe au patch suivant.
			console.error(`${RemoveInvalidSqlDatabasesPatchBase.C_LOG_ID}No sql databases path defined, can not apply patch !`);
			return true;
		}
		else {
			let laEntries: FileInfo[];

			try {
				laEntries = await this.isvcFilesystem.listDirectoryEntriesAsync(lsSqlDatabasesPath, this.getSqlDatabasesDirectoryPath());
			}
			catch (poError) {
				console.error(`${RemoveInvalidSqlDatabasesPatchBase.C_LOG_ID}Patch can not be applied :`, poError);
				laEntries = [];
			}

			// Il y a forcément la bdd de config a minima dans le dossier, donc si un deuxième fichier est présent on regarde dedans.
			return laEntries.length > 1 ? this.showLoaderAndRemoveInvalidSqlDatabasesAsync(laEntries) : true;
		}
	}

	private async showLoaderAndRemoveInvalidSqlDatabasesAsync(paEntries: FileInfo[]): Promise<boolean> {
		let loLoader: Loader | undefined;

		try {
			loLoader = await this.isvcLoading.create(`${this.patchDescription} ...`);
			await loLoader.present();
			return this.removeInvalidSqlDatabasesAsync(paEntries);
		}
		catch (poError) {
			return false;
		}
		finally {
			loLoader?.dismiss();
		}
	}

	/** Supprime les bases de données sqlite invalides : dont une nouvelle version est présente ou les fichiers temporaires (tmp/zip).
	 * @param paFiles Tableau des fichiers sur le disque (à l'emplacement défini).
	 */
	private async removeInvalidSqlDatabasesAsync(paFiles: FileInfo[]): Promise<boolean> {
		const laOldDatabases: FileInfo[] = this.getInvalidDatabases(paFiles);
		const lsSqlDatabasesPath: string = this.getSqlDatabasesPath();
		const leSqlDatabasesDirectoryPath: Directory = this.getSqlDatabasesDirectoryPath();
		let lbHasError = false;

		for (let lnIndex = 0; lnIndex < laOldDatabases.length; lnIndex++) {
			const loDatabase: FileInfo = laOldDatabases[lnIndex];

			await this.isvcFilesystem.removeAsync(
				`${lsSqlDatabasesPath}${loDatabase.name}`,
				leSqlDatabasesDirectoryPath
			)
				.then(() => {
					this.isvcLogger.action(
						RemoveInvalidSqlDatabasesPatchBase.C_LOG_ID,
						`File '${loDatabase.name}' removed from path '${lsSqlDatabasesPath}'.`,
						ELogActionId.catalogRemoveSuccess
					);
				})
				.catch(poError => {
					console.error(`${RemoveInvalidSqlDatabasesPatchBase.C_LOG_ID}File '${loDatabase.name}' can not removed from path '${lsSqlDatabasesPath}'.`, poError);
					lbHasError = true;
				});
		}

		return !lbHasError;
	}

	/** Retourne les bases de données invalides (celles n'ayant pas été correctement installées et les plus anciennes).
	 * @param paDatabaseFiles Tableau des bases de données dont il faut obtenir les invalides.
	 */
	private getInvalidDatabases(paDatabaseFiles: FileInfo[]): FileInfo[] {
		const laAutoRemovableDatabaseFiles: FileInfo[] = [];
		const loSqlDatabasesByName = new Map<string, SqlDatabase[]>();
		const lsDatabasesPrefix: string = this.getDatabasesPrefix();
		const loCompleteDatabaseNameRegex: RegExp = this.getCompleteDatabaseNameRegex();

		for (let lnIndex = 0; lnIndex < paDatabaseFiles.length; ++lnIndex) {
			const loDatabaseFile: FileInfo = paDatabaseFiles[lnIndex];

			if (loDatabaseFile.name.startsWith(lsDatabasesPrefix)) {
				if (RemoveInvalidSqlDatabasesPatchBase.C_AUTO_REMOVABLE_REGEX.test(loDatabaseFile.name)) // Suppression automatique.
					laAutoRemovableDatabaseFiles.push(loDatabaseFile);
				else {
					const loRegexMatch: RegExpMatchArray | null = loDatabaseFile.name.match(loCompleteDatabaseNameRegex);

					if (loRegexMatch) {
						const lsName: string = loRegexMatch[1]; // Préfixe de la base de données.
						const lnVersion: number = +loRegexMatch[2]; // Version de la base de données.
						const loSqlDatabase: SqlDatabase = new SqlDatabase(lnVersion, loDatabaseFile);
						const laSqlDatabases: SqlDatabase[] | undefined = loSqlDatabasesByName.get(lsName);

						laSqlDatabases ? laSqlDatabases.push(loSqlDatabase) : loSqlDatabasesByName.set(lsName, [loSqlDatabase]);
					}
				}
			}
		}

		// On concatène les bases de données qui n'ont pas été correctement installées avec les plus anciennes.
		return laAutoRemovableDatabaseFiles.concat(this.getOldestValidDatabaseFiles(loSqlDatabasesByName));
	}

	private getCompleteDatabaseNameRegex(): RegExp {
		return new RegExp(`${this.getDatabaseNameRegex().source}\\.(db|tmp|zip|tmp\\.db|tmp\\.zip)$`, "i");
	}

	private getOldestValidDatabaseFiles(poSqlDatabasesByName: Map<string, SqlDatabase[]>): FileInfo[] {
		const laResults: FileInfo[] = [];

		MapHelper.valuesToArray(poSqlDatabasesByName)
			.forEach((paSqlDatabases: SqlDatabase[]) => {
				const laSortedSqlDatabases: SqlDatabase[] = ArrayHelper.dynamicSort(paSqlDatabases, "version");

				laSortedSqlDatabases.pop(); // On retire la base de données la plus récente parce qu'on veut les plus anciennes.

				laResults.push(...laSortedSqlDatabases.map((poDatabase: SqlDatabase) => poDatabase.file));
			});

		return laResults;
	}

	//#endregion

}