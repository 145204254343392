import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { EPrefix } from '../../../../model/EPrefix';
import { EDatabaseRole } from '../../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../../model/store/IDataSource';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { Store } from '../../../../services/store.service';
import { IStoreCategory } from '../counting/models/IStoreCategory';
import { IStoreCategoryGroup } from '../counting/models/IStoreCategoryGroup';

@Injectable()
export class CategoryService {

	//#region FIELDS

	/** Préfixe d'un objet `IStoreCategory`. */
	public static readonly C_CATEGORY_PREFIX: EPrefix = "cat_" as EPrefix;
	/** Préfixe d'un objet `IStoreGroupCategory`. */
	public static readonly C_GROUP_CATEGORY_PREFIX: EPrefix = "catgroup_" as EPrefix;

	//#endregion

	//#region METHODS

	public constructor(private readonly isvcStore: Store) { }

	/** Retourne une HashMap où la clé est l'id de la catégorie et la valeur est l'objet `Category` issue de la base de données. */
	public getMap(paIds: string[]): Observable<Map<string, IStoreCategory>> {
		return this.isvcStore.get<IStoreCategory>({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				keys: paIds,
				include_docs: true,
			},
		}).pipe(
			map((paCategories: IStoreCategory[]) => {
				const loMap = new Map<string, IStoreCategory>();

				for (let i = 0; i < paCategories.length; i++) {
					loMap.set(paIds[i], paCategories[i]);
				}

				return loMap;
			}),
		);
	}

	/** Récupère les catégories souhaitées depuis la base de données, toutes si aucun paramètre.
	 * @param paIds Tableau des identifiants de catégorie à récupérer.
	 */
	public getCategories(paIds?: string[]): Observable<IStoreCategory[]> {
		return this.get<IStoreCategory>(CategoryService.C_CATEGORY_PREFIX, paIds);
	}

	/** Récupère les groupes de catégories souhaités depuis la base de données, tous si aucun paramètre.
	 * @param paIds Tableau des identifiants de groupes de catégories à récupérer.
	 */
	public getCategoryGroups(paIds?: string[]): Observable<IStoreCategoryGroup[]> {
		return this.get<IStoreCategoryGroup>(CategoryService.C_GROUP_CATEGORY_PREFIX, paIds);
	}

	/** Récupère des documents en base de données en fonction d'un tableau d'identifiants, tous les documents avec un préfixe spécifié si tableau non valide.
	 * @param pePrefix Préfixe permettant de récupérer des données spécifiques.
	 * @param paIds Tableau des identifiants qu'il faut récupérer.
	 */
	private get<T extends IStoreDocument>(pePrefix: EPrefix, paIds?: string[]): Observable<T[]> {
		let lsStartKey: string;
		let lsEndKey: string;

		if (!ArrayHelper.hasElements(paIds)) {
			lsStartKey = pePrefix;
			lsEndKey = `${pePrefix}${Store.C_ANYTHING_CODE_ASCII}`;
		}

		return this.isvcStore.get<T>({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				keys: paIds,
				startkey: lsStartKey,
				endkey: lsEndKey,
				include_docs: true
			}
		} as IDataSource);
	}

	/** Retourne le tableau d'identifiant des enfants du catgroup passé en paramètre.
	 * @param paCategoryGroups Tableau de tous les groupes de catégories.
	 * @param psCategoryId Identifiant de la catégorie à rechercher.
	 */
	public getCategoryGroupChildren(paCategoryGroups: IStoreCategoryGroup[], psCategoryId: string): string[] {
		return paCategoryGroups.find((poCatGroup: IStoreCategoryGroup) => poCatGroup._id === psCategoryId).children;
	}

	/** Retourne `true` si tous les identifiants du tableau sont des identifiants de catégorie, sinon false.
	 * @param paCategoriesIds Tableau des catégories.
	 */
	public areCategoryIds(paCategoriesIds: string[]): boolean {
		return paCategoriesIds.every((psCategoryId: string) => this.isCategoryId(psCategoryId) === true);
	}

	/** Retourne `true` si l'identifiant de la catégorie commence par `cat_`, sinon false.
	 * @param psCategoryId Identifiant de la catégorie.
	 */
	public isCategoryId(psCategoryId: string): boolean {
		return psCategoryId.startsWith(CategoryService.C_CATEGORY_PREFIX);
	}

	//#endregion

}