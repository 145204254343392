import { Exclude } from "class-transformer";
import { Entity } from "../../entities/models/entity";
import { ObservableArray } from "../../observable/models/observable-array";
import { ModelMatch } from "../../utils/models/decorators/model-match.decorator";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { DocumentFields } from "./document-fields";
import { Folder } from "./folder";
import { IDocumentFields } from "./idocument-fields";
import { IFormDocument } from "./iform-document";
import { IUserStatus } from "./iuser-status";

@ModelMatch((poFormDocument?: any) => !!poFormDocument?.$document, Entity)
export class FormDocument extends Entity implements IFormDocument, IDocumentFields {

	//#region PROPERTIES

	/** @implements */
	@ResolveModel(DocumentFields)
	public $document: DocumentFields;
	/** @implements */
	@ResolveModel(Date)
	public archiveDate?: Date;
	/** @implements */
	@ResolveModel(Date)
	public restoreDate?: Date;

	/** @implements */
	public get name(): string { return this.$document.name; };
	/** @implements */
	public get authorId(): string { return this.$document.authorId; };
	/** @implements */
	public get createDate(): string { return this.$document.createDate; };
	/** @implements */
	public get displayDate(): string | undefined { return this.$document.displayDate; };
	/** @implements */
	public get paths(): string[] { return this.$document.paths; };

	@Exclude()
	/** Icône du type de fichier. */
	@Exclude()
	public icon?: string;
	@Exclude()
	/** Nom de l'auteur. */
	@Exclude()
	public authorName?: string;
	@Exclude()
	/** Type de document. */
	@Exclude()
	public type?: string;
	@Exclude()
	/** Arbre de navigation pour atteindre le document avec son 1er chemin. */
	@Exclude()
	public observableNavigationTree = new ObservableArray<Folder>([]);
	@Exclude()
	/** Statut de l'utilisateur par rapport au document. */
	public userStatus?: IUserStatus;

	//#endregion PROPERTIES

	//#region METHODS

	public get isInTrash(): boolean {
		return this.paths.some((psPath: string) => psPath.startsWith("trash"));
	}

	public get canArchive(): boolean {
		return this.paths.some((psPath: string) => !this.isInTrash && !psPath.startsWith("archives"));
	}

	public get canRestore(): boolean {
		return this.paths.some((psPath: string) => this.isInTrash || psPath.startsWith("archives"));
	}

	//#endregion METHODS

}
