export enum EKnownTaskSubType {

	/** Normal. */
	normal = "normal",
	/** Petit. */
	petit = "petit",
	/** Solde. */
	solde = "solde",
	/** Outlet. */
	outlet = "outlet",
	/** Inventaire de tous les articles qui seront soldés. Les articles restent physiquement dans le magasin. */
	preSolde = "presolde",
	/** Reprise intégrale (aussi appelée reprise intégrale pour changement de client).\
	 * Un changement de client signifie qu'il y a eu un changement de propriétaire, tous les articles sont rachetés à l'ancien client et revendu au nouveau propriétaire.\
	 * Les articles restent physiquement dans le magasin.
	 */
	integrale = "repint",
	/** Reprise intégrale pour cause de changement de contrat.\
	 * Le client modifie son contrat, cela change certaines règles de calcul pour la facturation. L'utilisateur réalise un inventaire complet du magasin pour remettre à 0 la facturation.\
	 * Les articles restent physiquement dans le magasin.
	*/
	integralePourChangementContrat = "repintchgcontrat",
}