import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ILine } from '../models/ILine';

@Component({
	selector: 'advanced-search',
	templateUrl: './advanced-search.component.html',
	styleUrls: ['./advanced-search.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedSearchComponent extends ComponentBase {

	//#region PROPERTIES

	@Input() public headers: Array<string>;
	@Input() public lines: Array<ILine>;

	// TODO A ajuster en fonction des lignes (paramétrables).
	public columnSize = 4;

	//#endregion

	//#region METHODS

	constructor(poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	//#endregion
}