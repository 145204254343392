import { Directory } from "@capacitor/filesystem";
import { ArrayHelper } from "../../../helpers/arrayHelper";
import { StringHelper } from "../../../helpers/stringHelper";
import { InjectorService } from "../../../services/injector.service";
import { PlatformService } from "../../../services/platform.service";
import { TSqliteExtension } from "../models/tsqlite-extension";

/** Permet de mettre à disposition des méthodes pour aider à manipuler des fichiers sql. */
export abstract class SqlFilesHelper {

	//#region PROPERTIES

	private static msSqliteDatabasesPath: string;
	/** Récupère le chemin vers les bases de données sqlite sur l'app mobile, chaîne vide si on n'est pas sur l'app mobile. */
	public static get mobileAppDatabasesPath(): string {
		if (this.msSqliteDatabasesPath === undefined)
			this.initDatabasesLocationData();

		return this.msSqliteDatabasesPath;
	}

	public static readonly mobileAppDatabasesDirectory = Directory.Library;

	//#endregion

	//#region METHODS

	private constructor() { }

	private static initDatabasesLocationData(): void {
		const lsvcPlatform: PlatformService = InjectorService.instance.get(PlatformService);

		if (lsvcPlatform.isMobileApp) {
			if (lsvcPlatform.isAndroid)
				this.msSqliteDatabasesPath = "../databases/";
			else if (lsvcPlatform.isIOS)
				this.msSqliteDatabasesPath = "LocalDatabase/";
		}
		else
			this.msSqliteDatabasesPath = "";
	}

	/** `true` si la chaîne de caractères se termine par une extension possible de base de données sqlite, `false` sinon.
	 * @param psFileNameOrPath Chaîne de caractères à vérifier.
	 */
	public static hasCorrectSqliteExtension(psFileNameOrPath: string): boolean {
		return StringHelper.isValid(psFileNameOrPath) && (psFileNameOrPath.endsWith(".db") || psFileNameOrPath.endsWith(".zip"));
	}

	/** L'extension de base de données sqlite contenue dans la chaîne de caractères, `undefined` si la chaîne ne se termine pas par une extension possible.
	 * @param psFileNameOrPath Chaîne de caractères dont on veut extraire l'extension de base de données sqlite.
	 */
	public static extractExtension(psFileNameOrPath: string): TSqliteExtension | undefined {
		return this.hasCorrectSqliteExtension(psFileNameOrPath) ? ArrayHelper.getLastElement(psFileNameOrPath.split(".")) as TSqliteExtension : undefined;
	}

	//#endregion METHODS

}