import { Injectable } from '@angular/core';
import { SQLite, SQLiteDatabaseConfig, SQLiteObject, SQLiteTransaction } from '@ionic-native/sqlite/ngx';
import PCancelable, { CancelError } from 'p-cancelable';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { PlatformService } from '../../../services/platform.service';
import { PerformanceManager } from '../../performance/PerformanceManager';
import { CancelablePromiseTracker } from '../../promises/models/cancelable-promise-tracker';
import { ISqlGetResult } from '../models/ISqliteGetResult';
import { SqlAdapter } from '../models/SqlAdapter';
import { DatabaseCreationError } from '../models/errors/DatabaseCreationError';
import { SqlDataSource } from '../models/sql-data-source';
import { SqlRequestResult } from '../models/sql-request-result';
import { TRequestParam } from '../models/trequest-param';
import { TTransactionParams } from '../models/ttransaction-params';
import { TTransactionRequest } from '../models/ttransaction-request';

@Injectable()
export class SqliteAdapterService extends SqlAdapter<SQLiteObject> {

	//#region FIELDS

	private static readonly C_SQLITE_LOG_ID = "SQLITE.S::";

	//#endregion FIELDS

	//#region METHODS

	constructor(private readonly ioSqlite: SQLite, private readonly isvcPlatform: PlatformService) {
		super();
	}

	/** Crée une base de données sqlite à partir d'une configuration données.
	 * @param poConfig Configuration pour la création d'une base de données sqlite.
	 * @throws `DatabaseCreationError` si une erreur s'est produite,
	 */
	public async openDatabaseAsync(poDataSource: SqlDataSource): Promise<SQLiteObject> {
		if (poDataSource.isRemote)
			return Promise.reject(`${SqliteAdapterService.C_SQLITE_LOG_ID}Sqlite doesn't support remote Datasource.`);

		// `default` obligatoire avec le nouveau plugin sqlite car il n'est plus possible de spécifier le chemin.
		const loConfig: SQLiteDatabaseConfig = {
			name: poDataSource.path,
			androidDatabaseLocation: "default",
			iosDatabaseLocation: "default",
			location: undefined
		};

		const lsCreationLogMessage = `${loConfig.name} (androidLocation=${loConfig.androidDatabaseLocation} | iosLocation=${loConfig.iosDatabaseLocation})`;

		try {
			await this.isvcPlatform.readyAsync;
			const loDatabase: SQLiteObject = await this.ioSqlite.create(loConfig);
			console.info(`${SqliteAdapterService.C_SQLITE_LOG_ID}Database '${lsCreationLogMessage}' in version '${poDataSource.version}' opened`);
			return loDatabase;
		}
		catch (poError) {
			return this.throwOpenDatabaseErrorAsync(poDataSource, new DatabaseCreationError(lsCreationLogMessage), poError);
		}
	}

	/** @implements */
	protected execRequestAsync<T>(
		poDatabase: SQLiteObject,
		psRequest: string,
		paParams: TRequestParam[]
	): Promise<SqlRequestResult<T>> {
		const loSqlRequestResult: SqlRequestResult<T> = new SqlRequestResult();
		const loPerfManager = new PerformanceManager();

		loPerfManager.markStart();

		return poDatabase.executeSql(psRequest, paParams)
			.then((poGetResult: ISqlGetResult<T>) => {
				loSqlRequestResult.time = loPerfManager.markEnd().measure();
				loSqlRequestResult.results.push(...this.transformResult(poGetResult));

				return loSqlRequestResult;
			});
	}

	protected override execTransactionAsync(
		poDatabase: SQLiteObject,
		paRequests: TTransactionRequest[],
		paParams: TTransactionParams[]
	): PCancelable<SqlRequestResult<any>[]> {
		return new PCancelable<SqlRequestResult<any>[]>(async (pfResolve, pfReject, onCancel) => {
			try {
				const laRequests: TTransactionRequest[] = [...paRequests];
				const laParams: TTransactionParams[] = [...paParams];
				await poDatabase.transaction(async (poTransaction: SQLiteTransaction) => {
					onCancel(() => poTransaction.abort(new CancelError()));

					const laResults: SqlRequestResult<any>[] = await this.addStatements(laRequests, laParams, poTransaction);
					pfResolve(laResults);
				});
			} catch (poError) {
				pfReject(poError);
			}
		});
	}

	/** Ajoute les requêtes à la transaction.
	 * @param paRequests
	 * @param paParams
	 * @param poTransaction
	 * @param paResults
	 * @returns
	 */
	private addStatements(
		paRequests: TTransactionRequest[],
		paParams: TTransactionParams[],
		poTransaction: SQLiteTransaction,
		paResults?: SqlRequestResult<any>[]
	): PCancelable<SqlRequestResult<any>[]> {
		return new PCancelable((pfResolve, pfReject, pfOnCancel) => {
			const loTracker = new CancelablePromiseTracker;
			pfOnCancel(() => {
				poTransaction.abort(new CancelError);
				loTracker.cancelAll();
			});

			if (ArrayHelper.hasElements(paRequests)) { // Si il reste des requêtes,
				const loPerfManager = new PerformanceManager().markStart();
				// on récupère la requête et ses paramètres à injecter
				const loRequest: TTransactionRequest | undefined = paRequests.shift();
				const loParams: TTransactionParams | undefined = paParams.shift();
				poTransaction.addStatement( // puis on ajoute la requête à la transaction
					typeof loRequest === "function" ? loRequest(paResults) : loRequest,
					typeof loParams === "function" ? loParams(paResults) : loParams,
					async (poResultTransaction: SQLiteTransaction, poResult: ISqlGetResult<any>) => { // On attend la fin de la requête pour ajouter la prochaine et utiliser le résultat de cette requête.
						const loSqlRequestResult: SqlRequestResult<any> = this.createSqlResult(
							loPerfManager,
							poResult
						);

						pfResolve(await loTracker.track(this.addStatements(
							paRequests, paParams, poResultTransaction, [...(paResults ?? []), loSqlRequestResult]
						)));
					},
					(poError: any) => pfReject(poError)
				);
			}
			else // Si plus de requêtes alors on peut retourner les résultats
				pfResolve(paResults);
		});
	}

	private createSqlResult(poPerfManager: PerformanceManager, poResult: ISqlGetResult<any>): SqlRequestResult<any> {
		const loSqlRequestResult: SqlRequestResult<any> = new SqlRequestResult();
		loSqlRequestResult.time = poPerfManager.markEnd().measure();
		loSqlRequestResult.results.push(...this.transformResult(poResult));

		return loSqlRequestResult;
	}

	/**  Transforme un résultat de requête ISqlGetResult en `T[]`.
	 * @param poGetResult Résultat de la requête.
	 * @returns Le résultat de la requête sous forme `T[]`.
	 */
	private transformResult<T>(poGetResult: ISqlGetResult<T>): T[] {
		const laResults: T[] = [];

		for (let lnIndex = 0; lnIndex < poGetResult.rows.length; ++lnIndex) {
			const loItem: T | undefined = poGetResult.rows.item(lnIndex);

			if (loItem !== undefined)
				laResults.push(loItem);
		}

		return laResults;
	}

	protected closeDatabaseAsync(poDatabase: SQLiteObject): Promise<void> {
		return poDatabase.close();
	}

	/** Log une erreur et retourne l'instance de l'erreur sous forme d'observable d'erreur.
	 * @param poErrorInstance Instance de l'erreur à lever.
	 * @param poError Erreur survenue.
	 */
	private throwOpenDatabaseErrorAsync<T extends Error>(poDataSource: SqlDataSource, poErrorInstance: T, poError?: any): Promise<never> {
		console.error(`${SqliteAdapterService.C_SQLITE_LOG_ID}Error opening database '${poDataSource.databaseId}' in version '${poDataSource.version}' : ${poErrorInstance.message}`, poError);
		return Promise.reject(poErrorInstance);
	}

	//#endregion METHODS

}