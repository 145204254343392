import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { IonicModule } from '@ionic/angular';
import { CsvService } from '../../services/csv.service';
import { MailService } from '../../services/mail.service';
import { HeaderModule } from '../header/header.module';
import { ScanditModule } from '../scandit/scandit.module';
import { SettingsModule } from '../settings/settings.module';
import { BarcodeReaderModule } from './barcode-reader/barcode-reader.module';
import { BluetoothModule } from './bluetooth/bluetooth.module';
import { CatalogModule } from './catalog/catalog.module';
import { DevicesModule } from './devices/devices.module';
import { TslModule } from './devices/tsl/tsl.module';
import { LogisticsDiagnosticsModule } from './diagnostics/diagnostics.module';
import { LogisticsRoutingModule } from './logistics-routing.module';
import { ReadingPageModule } from './reading/reading-page.module';
import { RfidService } from './rfid.service';
import { SearchPageModule } from './search/search-page.module';
import { LogisticsSettingsComponent } from './settings/logistics-settings/logistics-settings.component';
import { LogisticsSettingsService } from './settings/services/logistics-settings.service';
import { TaskModule } from './task/task.module';

/** Module de gestion du module Logistics d'OSApp. */
@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		HeaderModule,
		BluetoothModule,
		LogisticsDiagnosticsModule,
		LogisticsRoutingModule,
		ReadingPageModule,
		SearchPageModule,
		DevicesModule,
		ScanditModule,
		TslModule,
		CatalogModule,
		SettingsModule,
		BarcodeReaderModule
	],
	providers: [
		RfidService,
		BluetoothSerial,
		MailService,
		CsvService,
		LogisticsSettingsService
	],
	declarations: [
		LogisticsSettingsComponent
	],
	exports: [
		TaskModule,
		LogisticsSettingsComponent
	]
})
export class LogisticsModule {
	constructor() { }
}