import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, of } from 'rxjs';
import { delay, mapTo, mergeMap, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { NumberHelper } from '../../../helpers/numberHelper';
import { ConfigData } from '../../../model/config/ConfigData';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { IRfidAcquisition } from '../devices/models/IRfidAcquisition';
import { ReadingService } from '../reading/reading.service';
import { RfidService } from '../rfid.service';
import { ILine } from './models/ILine';

@Component({
	templateUrl: './search-page.component.html',
	styleUrls: ['./search-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchPage extends ComponentBase implements OnInit {

	//#region FIELDS

	/** Délai entre deux émissions RFID pendant une phase de recherche. */
	private static readonly C_DELAY_RFID: number = 1500;

	private maWanted: string[];
	/** Nombre de dBm minimale qu'un tag a émis. */
	private mnMin: number;
	/** Nombre de dBm maximale qu'un tag a émis. */
	private mnMax: number;

	//#endregion

	//#region PROPERTIES

	public get wanted(): Array<string> { return this.maWanted; }

	/** Données à afficher à l'utilisateur. */
	public lines: Array<ILine> = [];
	/** Si `true` la recherche est en cours (l'appareil RFID émet des ondes). */
	public isSearching = false;
	/** Utilisé en cas de recherche métier par l'application. */
	private searchDocuments: IStoreDocument[];

	//#endregion

	//#region METHODS

	constructor(
		private ioRoute: ActivatedRoute,
		poChangeDetectorRef: ChangeDetectorRef,
		private isvcRfid: RfidService,
		private isvcReading: ReadingService
	) {
		super(poChangeDetectorRef);
	}

	public ngOnInit(): void {
		// Le changement des query params permet de modifier les paramètres de la lecture.
		this.ioRoute.queryParams.subscribe((poParams: Params) => {
			// Changement des objets recherchés.
			this.onWantedChanged((poParams.wanted as string).split(","));
		});

		this.initSearchedDocuments().subscribe();

		this.isvcRfid.reading
			.pipe(
				tap(
					(poResponse: IRfidAcquisition[]) => this.onReading(poResponse),
					poError => console.error("SEARCH.P::Erreur pendant la lecture :", poError)
				)
			)
			.subscribe();
	}

	/** Initialise l'utilitaire pour afficher un contenu spécifique au lieu d'un code, s'il y a une configuration dans l'app. */
	private initSearchedDocuments(): Observable<void> {
		if (!ConfigData.logistics || !ConfigData.logistics.rfidSearch)
			return of(undefined);

		// Charge la base de données associée.
		return this.isvcReading.loadCodeData(ConfigData.logistics.rfidDisplay.database)
			.pipe(
				tap((paDocuments: IStoreDocument[]) => this.searchDocuments = paDocuments),
				mapTo(undefined)
			);
	}

	/** Callback appelée lorsque les éléments recherchés changent. */
	private onWantedChanged(paWanted?: string[]): void {
		this.maWanted = paWanted;
		this.lines = this.maWanted.map((psWanted: string): ILine => { return { code: psWanted, percentage: "0" }; });
		this.detectChanges();
	}

	/** Callback appelée lors du clic sur le bouton de lancement de la recherche. */
	public onSearchClicked(): void {
		this.isSearching = !this.isSearching;

		if (this.isSearching)
			this.launchSearch();
	}

	/** Relance une lecture. */
	private launchSearch(pnDelay: number = 1000): void {
		// TODO La logique de cette fonction est à déplacer dans le RfidService.
		console.debug("SEARCH.P::Lancement d'une nouvelle recherche.");

		// Pour éviter d'abimer la batterie du TSL, on attend.
		of(true)
			.pipe(
				delay(pnDelay),
				mergeMap(() => this.isvcRfid.startReading({ alert: false, withOutputPower: true }))
			)
			.subscribe();
	}

	/** Callback appelée lors d'une lecture. */
	private onReading(paResponses: IRfidAcquisition[]): void {
		console.debug("SEARCH.P::Valeur de la lecture reçue:", paResponses);

		// Si on est en train de rechercher, on relance une lecture sinon non.
		if (this.isSearching)
			this.launchSearch(SearchPage.C_DELAY_RFID);

		// Met à jour les valeurs minimales et maximales reçues.
		this.updateMinMax(paResponses);

		// On met à jour les lignes qui ne sont pas présentes dans cette lecture et celle déjà présentes.
		this.lines = this.lines.map((poLine: ILine): ILine => {
			const lnIndex: number = paResponses.findIndex((poRfid: IRfidAcquisition) => this.isWanted(poRfid, poLine));

			if (lnIndex === -1)
				return { code: poLine.code, percentage: "0" };
			else
				return { code: poLine.code, percentage: NumberHelper.getPercentage(this.mnMin, this.mnMax, paResponses[lnIndex].dbm).toString() };
		});

		this.detectChanges();
	}

	/** Met à jour les valeurs minimales et maximales. */
	private updateMinMax(paAcquisitions: Array<IRfidAcquisition>): void {
		const loMinMax: { min: number, max: number } =
			NumberHelper.updateMinMax<IRfidAcquisition>(paAcquisitions, (poAcq: IRfidAcquisition) => poAcq.dbm, this.mnMin, this.mnMax);

		this.mnMin = loMinMax.min;
		this.mnMax = loMinMax.max;
	}

	/** Retourne `true` si le tag RFID en paramètre est recherché. */
	private isWanted(poRfid: IRfidAcquisition, poLine: ILine): boolean {
		if (ConfigData.logistics.rfidSearch)
			return ConfigData.logistics.rfidSearch.isSameCode(poRfid.code, poLine.code, this.searchDocuments);
		else
			return poLine.code === poRfid.code;
	}

	//#endregion
}