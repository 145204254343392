<ion-content>
	<ion-list *ngIf="!areFormsVisible" lines="full">
		<ion-item (click)="takePicture()">
			<ion-icon slot="start" name="camera-outline"></ion-icon>
			<ion-label>Appareil photo</ion-label>
		</ion-item>
		<ion-item (click)="pickImage()">
			<ion-icon slot="start" name="images-outline"></ion-icon>
			<ion-label>Galerie</ion-label>
		</ion-item>
		<ion-item (click)="pickFile()">
			<ion-icon slot="start" name="document-outline"></ion-icon>
			<ion-label>Documents</ion-label>
		</ion-item>
	</ion-list>
</ion-content>