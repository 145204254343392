import { Injectable, InjectionToken, Injector, Type } from '@angular/core';

/** @deprecated Utiliser le `ServicesInjectorService` à la place. */
@Injectable({ providedIn: 'root' })
export class InjectorService {

	//#region PROPERTIES

	public static instance: InjectorService;

	//#endregion

	//#region METHODS

	constructor(private readonly ioInjector: Injector) {
		InjectorService.instance = this;
	}

	/** @deprecated Utiliser le `ServicesInjectorService`. */
	public get<T>(poToken: Type<T> | InjectionToken<T>): T {
		return this.ioInjector.get<T>(poToken);
	}

	//#endregion

}