<div class="container">

	<ng-container
		*ngTemplateOutlet="(observableDisplayCustomReminder.value$ | async) ? customReminders : defaultReminders"></ng-container>

	<div class="buttons">
		<ion-button (click)="cancelAsync()" class="cancel-button">Annuler</ion-button>
		<ion-button [disabled]="observableDisableSubmit.value$ | async" (click)="validateAsync()"
			class="submit-button">Enregistrer</ion-button>
	</div>
</div>

<ng-template #defaultReminders>
	<ion-radio-group value="15" (ionChange)="onChange($event)">
		<ion-list-header>
			<ion-label>Ajouter un rappel ...</ion-label>
		</ion-list-header>

		<ion-item lines="none">
			<ion-radio slot="start" value="15"></ion-radio>
			<ion-label>15 minutes avant</ion-label>
		</ion-item>

		<ion-item lines="none">
			<ion-radio slot="start" value="60"></ion-radio>
			<ion-label>1 heure avant</ion-label>
		</ion-item>

		<ion-item lines="none">
			<ion-radio slot="start" value="120"></ion-radio>
			<ion-label>2 heures avant</ion-label>
		</ion-item>

		<ion-item lines="none">
			<ion-radio slot="start" value="1440"></ion-radio>
			<ion-label>1 jour avant</ion-label>
		</ion-item>

		<ion-item lines="none">
			<ion-radio slot="start" value="custom"></ion-radio>
			<ion-label>Personnalisé</ion-label>
		</ion-item>

	</ion-radio-group>
</ng-template>

<ng-template #customReminders>
	<ion-radio-group value="minutes" (ionChange)="onChange($event)">
		<ion-list-header>
			<ion-label>Rappel personnalisé</ion-label>
		</ion-list-header>

		<ion-input [value]="observableCustomValue.value$ | async" type="number" min="1" step="1" class="duration"
			(ionChange)="onCustomValueChanged($event)"></ion-input>

		<ion-item lines="none">
			<ion-radio slot="start" value="minutes"></ion-radio>
			<ion-label>minutes avant</ion-label>
		</ion-item>

		<ion-item lines="none">
			<ion-radio slot="start" value="hours"></ion-radio>
			<ion-label>heures avant</ion-label>
		</ion-item>

		<ion-item lines="none">
			<ion-radio slot="start" value="days"></ion-radio>
			<ion-label>jours avant</ion-label>
		</ion-item>

		<ion-item lines="none">
			<ion-radio slot="start" value="weeks"></ion-radio>
			<ion-label>semaines avant</ion-label>
		</ion-item>

	</ion-radio-group>
</ng-template>