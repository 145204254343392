import { ECatalogVersionResponseStatus } from '@calaosoft/osapp/modules/logistics/catalog/models/ECatalogVersionResponseStatus';
import { ICatalogVersionResponse } from '@calaosoft/osapp/modules/logistics/catalog/models/ICatalogVersionResponse';
import { CatalogService } from '@calaosoft/osapp/modules/logistics/catalog/services/catalog.service';
import { TaskBase } from "@calaosoft/osapp/services/backgroundTask/TaskBase";
import { TaskDescriptor } from "@calaosoft/osapp/services/backgroundTask/TaskDescriptor";
import { InjectorService } from '@calaosoft/osapp/services/injector.service';
import { EMPTY, Observable, defer } from 'rxjs';
import { catchError, last, mapTo, mergeMap } from 'rxjs/operators';
import { IArticleCatalogUpdateTaskParams } from './IArticleCatalogUpdateTaskParams';

export class ArticleCatalogUpdateTask<T extends IArticleCatalogUpdateTaskParams> extends TaskBase<T> {

	//#region FIELDS

	private static readonly C_LOG_ID = "CTLG.UPDATETASK::";

	private readonly msvcCatalogService: CatalogService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);

		this.msvcCatalogService = InjectorService.instance.get(CatalogService);
	}

	protected override execTask$(): Observable<void> {
		return this.msvcCatalogService.getLastVersionData()
			.pipe(
				mergeMap((poCatalogResponse: ICatalogVersionResponse) => {
					if (poCatalogResponse.status === ECatalogVersionResponseStatus.available) {
						return defer(() => this.msvcCatalogService.isUpToDateAsync(poCatalogResponse))
							.pipe(mergeMap((pbIsUpToDate: boolean) => this.donwloadAndInstallIfNeeded$(poCatalogResponse, pbIsUpToDate)));
					}
					else
						return EMPTY;
				}),
				catchError((poError) => {
					console.error(`${ArticleCatalogUpdateTask.C_LOG_ID}Error when updating catalog:`, poError);
					return EMPTY;
				})
			);
	}

	private donwloadAndInstallIfNeeded$(poCatalogResponse: ICatalogVersionResponse, pbIsUpToDate: boolean): Observable<void> {
		if (pbIsUpToDate)
			return EMPTY;
		else {
			return this.msvcCatalogService.downloadAndInstall$(poCatalogResponse)
				.pipe(
					last(),
					mapTo(undefined)
				);
		}
	}

	//#endregion

}