<ion-item *ngIf="observableItem.value$ | async as group" lines="none" (click)="onGroupClicked()">
	<calao-conflict-list-marker *ngIf="group._conflicts"></calao-conflict-list-marker>
	<!-- Avatar -->
	<div class="start">
		<ion-avatar class="avatar" calaoBadgeIcon="trade" calaoBadgeIconPosition="below after">
			<avatar [src]="getGroupAvatar(group)" cssClass="icon-area opacity60">
			</avatar>
		</ion-avatar>
	</div>
	<!-- Affichage des textes. -->
	<ion-text class="text-container">
		<ion-label>{{ group.name }}</ion-label>
		<ion-note class="no-margin contact-groups">{{ observableMembersLabel.value$ | async }}</ion-note>
	</ion-text>
	<div slot="end" class="no-margin-start">
		<ion-button class="itemb btn-more" fill="clear" (click)="$event.stopPropagation(); presentPopOverAsync($event)">
			<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
		</ion-button>
	</div>
</ion-item>