<ng-container calaoHeader title="Recherche">
</ng-container>

<ion-content>
	<!-- Bouton pour lancer/arrêter la recherche. -->
	<ion-button (click)="onSearchClicked()" [color]="isSearching ? 'primary' : 'tertiary'" expand="full">
		{{ isSearching ? "Arrêter":"Rechercher" }}</ion-button>

	<!-- Affichage de toutes les valeurs voulues et leur proximité. -->
	<advanced-search [lines]="lines" [headers]="['Données', 'pourcentage']"></advanced-search>
</ion-content>