import { IControlItem } from "@calaosoft/osapp/modules/logistics/task/returns/models/IControlItem";

/** Permet de mettre à disposition des méthodes pour aider à manipuler un élément de contrôle d'un portant. */
export abstract class ReturnControlHelper {

	//#region METHODS

	/** Récupère la clé d'un élément de contrôle (identifiant permettant de distinguer deux éléments de contrôle entre eux en prenant en compte les motifs).
	 * @param poItem Élément de contrôle dont on veut récupérer la clé.
	 */
	public static getControlItemKey<T extends IControlItem>(poItem: T): string {
		if (!poItem.reasonId) // Si pas de motif, cas normal où on a scanné ; clé simple égale au code-barres présent.
			return poItem.code ?? "";

		// Motif présent, il faut regarder plus précisément l'élément pour déterminer sa clé.
		else if (poItem.code) // Code-barres présent.
			return `${poItem.code}${poItem.reasonId}`;

		else if (poItem.variantId) // Identifiant de variant présent.
			return `${poItem.variantId}${poItem.reasonId}`;

		else // Identifiant d'article présent.
			return `${poItem.itemId}${poItem.reasonId}`;
	}

	/** Récupère la clé d'un élément de contrôle en se basant uniquement sur `code`, `variantId` ou `itemId` respectivement.
	 * @param poItem Élément de contrôle agrégé.
	 */
	public static getGenericItemKey<T extends IControlItem>(poItem: T): string {
		return (poItem.code || poItem.variantId || poItem.itemId) ??
			(console.error("RTRNS.CTRL.H::Item", poItem, "has no generic key !"), "");
	}

	/** Retourne `true` si les deux éléments sont égaux (même clé), `false` sinon.
	 * @param poItemA Premier élément qu'il faut tester.
	 * @param poItemB Seconde élément qu'il faut tester.
	 */
	public static areControlItemsEqual<T extends IControlItem>(poItemA: T, poItemB: T): boolean {
		return this.getControlItemKey(poItemA) === this.getControlItemKey(poItemB);
	}

	//#endregion

}