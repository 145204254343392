import { Component, OnInit } from '@angular/core';
import { ENetworkFlag } from '@calaosoft/osapp/model/application/ENetworkFlag';
import { IAppStartPauseLogData } from '@calaosoft/osapp/model/application/iapp-start-pause-log-data';
import { ConfigData } from '@calaosoft/osapp/model/config/ConfigData';
import { IConfig } from '@calaosoft/osapp/model/config/IConfig';
import { ESecurityFlag } from '@calaosoft/osapp/model/security/ESecurityFlag';
import { EStoreFlag } from '@calaosoft/osapp/model/store/EStoreFlag';
import { DeviceNotAuthorizedError } from '@calaosoft/osapp/model/store/error/device-not-authorized-error';
import { BatteryService } from '@calaosoft/osapp/modules/battery/services/battery.service';
import { EFlag } from '@calaosoft/osapp/modules/flags/models/EFlag';
import { Loader } from '@calaosoft/osapp/modules/loading/Loader';
import { ELogActionId } from '@calaosoft/osapp/modules/logger/models/ELogActionId';
import { LoggerService } from '@calaosoft/osapp/modules/logger/services/logger.service';
import { BarcodeReaderService } from '@calaosoft/osapp/modules/logistics/barcode-reader/services/barcode-reader.service';
import { LogisticsSettings } from '@calaosoft/osapp/modules/logistics/settings/models/logistics-settings';
import { LogisticsSettingsService } from '@calaosoft/osapp/modules/logistics/settings/services/logistics-settings.service';
import { PatchService } from '@calaosoft/osapp/modules/patch/services/patch.service';
import { PwaUpdateService } from '@calaosoft/osapp/modules/pwa/services/pwa-update.service';
import { AppComponentBase } from '@calaosoft/osapp/modules/utils/components/app-component-base';
import { ApplicationService } from '@calaosoft/osapp/services/application.service';
import { BackgroundTaskService } from '@calaosoft/osapp/services/backgroundTask.service';
import { TaskDescriptor } from '@calaosoft/osapp/services/backgroundTask/TaskDescriptor';
import { ConfigService } from '@calaosoft/osapp/services/config.service';
import { FlagService } from '@calaosoft/osapp/services/flag.service';
import { ShowMessageParamsPopup } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '@calaosoft/osapp/services/loading.service';
import { PlatformService } from '@calaosoft/osapp/services/platform.service';
import { UiMessageService } from '@calaosoft/osapp/services/uiMessage.service';
import { MenuController } from '@ionic/angular';
import { AlertButton } from '@ionic/core';
import { EMPTY, defer } from 'rxjs';
import { finalize, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import packageJson from '../../package.json';
import * as constants from '../config';
import { ArticleCatalogUpdateTask } from '../models/background-tasks/ArticleCatalogUpdateTask';
import { IArticleCatalogUpdateTaskParams } from '../models/background-tasks/IArticleCatalogUpdateTaskParams';
import { IStockUpdateTaskParams } from '../models/background-tasks/istock-update-task-params';
import { RemoveObsoleteCustomerHistoryTask } from '../models/background-tasks/remove-obsolete-customer-history-task';
import { StockUpdateTask } from '../models/background-tasks/stock-update-task';
import { EMerchFlag } from '../models/emerch-flag';

@Component({
	selector: "app-root",
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent extends AppComponentBase implements OnInit {

	//#region FIELDS

	private static readonly C_LOG_ID = "APP.C::"

	//#endregion

	//#region PROPERTIES

	/** Clé du menu sur le côté. */
	public readonly menuKey = "sideMenu";
	/** Titre du menu, si vide, pas de toolbar. */
	public readonly menuTitle = "Merch";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcConfig: ConfigService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly ioMenu: MenuController,
		private readonly isvcLoading: LoadingService,
		private readonly isvcFlag: FlagService,
		private readonly isvcPatch: PatchService,
		private readonly isvcLogger: LoggerService,
		private readonly isvcPlatform: PlatformService,
		private readonly isvcPwaUpdate: PwaUpdateService,
		private readonly isvcLogisticsService: LogisticsSettingsService,
		private readonly isvcBarcodeReader: BarcodeReaderService,
		private readonly isvcBackgroundTask: BackgroundTaskService,
		psvcBattery: BatteryService,
		psvcApplication: ApplicationService
	) {
		super(psvcApplication, isvcPlatform, psvcBattery);

		this.initAsync();
	}

	public ngOnInit(): void {
		let loLoader: Loader;

		this.ioMenu.swipeGesture(true);
		this.ioMenu.enable(false);

		constants.environment.version = packageJson.version;

		defer(() => this.isvcPatch.applyPatchesAsync()) // Application des patchs.
			.pipe(
				mergeMap(_ => this.isvcLoading.create("Démarrage de l'application ...")),
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.isvcConfig.init(constants as IConfig)), // Initialisation des configs de l'app.
				tap(
					_ => {
						loLoader.dismiss();
						this.isvcFlag.setFlagValue(EFlag.appInitialized, true);
						this.logAppInitialized();
					},
					poError => this.initError(poError)
				),
				finalize(() => loLoader.dismiss())
			)
			.subscribe();

		this.isvcPwaUpdate.initUpdateCheck();
	}

	/** Configure des paramètres de services et les tâches récurentes. */
	private initAsync(): Promise<void> {
		return this.isvcFlag.waitForFlag(ESecurityFlag.authenticated, true).pipe(
			tap(_ => {
				this.addArticleCatalogUpdateTask();
				this.addRemoveObsoleteCustomerHistoryTask();

				// On télécharge / met à jour les stocks uniquement sur mobile.
				if (this.isvcPlatform.isMobileApp)
					this.addStockUpdateTask();
			}),
			mergeMap(() => {
				if (this.isvcPlatform.isAndroid) {
					return this.isvcFlag.waitForFlag(EStoreFlag.DBInitialized, true)
						.pipe(
							mergeMap(_ => this.isvcLogisticsService.get$()),
							take(1),
							mergeMap((poLogisticsSettings: LogisticsSettings) => this.isvcBarcodeReader.setAccuracyAsync(poLogisticsSettings.barcodeReader.accuracy))
						);
				}
				return EMPTY;
			}),
			takeUntil(this.destroyed$)
		).toPromise();
	}

	/** Crée la tâche récurente pour mettre à jour le catague article. */
	private addArticleCatalogUpdateTask(): void {
		const lsTaskType = "ArticleCatalogUpdateTask";

		this.isvcBackgroundTask.addTaskType(lsTaskType, ArticleCatalogUpdateTask);
		this.isvcBackgroundTask.addTask(new TaskDescriptor<IArticleCatalogUpdateTaskParams>({
			id: `${lsTaskType}Id`,
			name: `${lsTaskType}Name`,
			taskType: lsTaskType,
			intervalMultiplicator: 1,
			intervalRepetition: 1800000,
			execAfter: [
				{ key: EStoreFlag.DBInitialized, value: true },
				{ key: ENetworkFlag.isOnlineReliable, value: true }
			]
		}));
	}

	/** Crée la tâche récurrente pour supprimer les stocks obsolètes. */
	private addRemoveObsoleteCustomerHistoryTask(): void {
		const lsTaskType = "RemoveObsoleteCustomerHistoryTask";

		this.isvcBackgroundTask.addTaskType(lsTaskType, RemoveObsoleteCustomerHistoryTask);
		this.isvcBackgroundTask.addTask(new TaskDescriptor({
			id: `${lsTaskType}Id`,
			name: `${lsTaskType}Name`,
			taskType: lsTaskType,
			intervalMultiplicator: 1,
			intervalRepetition: 86_400_000,// 24h == 1x/jour ; même 1x/j c'est largement suffisant vu que les stocks vont pas être mis à jour 10x/j.
			execAfter: [
				{ key: EStoreFlag.DBInitialized, value: true }
			]
		}));
	}

	/** Crée la tâche récurente pour mettre à jour les stocks. */
	private addStockUpdateTask(): void {
		const lsTaskType = "StockUpdateTask";

		this.isvcBackgroundTask.addTaskType(lsTaskType, StockUpdateTask);
		this.isvcBackgroundTask.addTask(new TaskDescriptor<IStockUpdateTaskParams>({
			id: `${lsTaskType}Id`,
			name: `${lsTaskType}Name`,
			taskType: lsTaskType,
			params: {} as IStockUpdateTaskParams,
			intervalMultiplicator: 1,
			intervalRepetition: 1800000,
			execAfter: [
				{ key: EStoreFlag.DBInitialized, value: true },
				{ key: ENetworkFlag.isOnlineReliable, value: true },
				{ key: EMerchFlag.obsoleteStocksRemoved, value: true }
			]
		}));
	}

	private initError(poError: string | DeviceNotAuthorizedError | any): void {
		console.error(`APP.C::Application initialization error.`, poError);

		//! Nécessaire afin de supprimer le loader : si utilisation de l'instance du loader ou si cette ligne de code est appelée dans le finalize() alors le loader restera.
		this.isvcLoading.dismissAll();

		/** Message d'erreur à afficher à l'utilisateur. */
		let lsMessage: string;

		// Si l'erreur reçu est de type `string`, elle est générée par OSApp, on l'affiche directement à l'utilisateur.
		if (typeof poError === "string")
			lsMessage = poError;
		// Si c'est un type d'erreur géré par OSApp, on l'affiche.
		else if (poError instanceof DeviceNotAuthorizedError)
			lsMessage = poError.message;
		else	// Type d'erreur non géré.
			lsMessage = "Une erreur critique s'est produite. Veuillez contacter le support technique.";

		this.isvcUiMessage.showMessage(
			new ShowMessageParamsPopup({
				header: "Erreur",
				message: lsMessage,
				buttons: [
					{ text: "Quitter", handler: () => ApplicationService.exitApp() } as AlertButton,
					{ text: "Réessayer", handler: () => { ApplicationService.reloadApp(); } } as AlertButton
				]
			})
		);
	}

	private logAppInitialized(): void {
		console.log("APP.C::Application initialized");

		const loAppStartPauseLogData: IAppStartPauseLogData = {
			version: ConfigData.appInfo.appVersion!,
			environment: ConfigData.environment.id
		};

		this.isvcLogger.action(
			AppComponent.C_LOG_ID,
			"Démarrage de l'application.",
			ELogActionId.appStart,
			loAppStartPauseLogData
		);
	}

	//#endregion

}