import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { IonicModule } from '@ionic/angular';
import { CsvService } from '../../services/csv.service';
import { MailService } from '../../services/mail.service';
import { HeaderModule } from '../header/header.module';
import { ScanditModule } from '../scandit/scandit.module';
import { BluetoothModule } from './bluetooth/bluetooth.module';
import { CatalogModule } from './catalog/catalog.module';
import { DevicesModule } from './devices/devices.module';
import { TslModule } from './devices/tsl/tsl.module';
import { LogisticsDiagnosticsModule } from './diagnostics/diagnostics.module';
import { ReadingPageModule } from './reading/reading-page.module';
import { RfidService } from './rfid.service';
import { SearchPageModule } from './search/search-page.module';
import { TaskModule } from './task/task.module';

// Ce module est équivalent à LogisticsModule, mais n'inclue pas le système de routage.

/** Module de gestion du module Logistics d'OSApp. */
@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		HeaderModule,
		BluetoothModule,
		LogisticsDiagnosticsModule,
		ReadingPageModule,
		SearchPageModule,
		DevicesModule,
		ScanditModule,
		TslModule,
		CatalogModule
	],
	providers: [
		RfidService,
		BluetoothSerial,
		MailService,
		CsvService
	],
	exports: [TaskModule]
})
export class LogisticsWithoutRoutingModule {
	constructor() { }
}