<ion-content scrollY="false">
	<ion-list *ngIf="items.length > 0" lines="full">
		<ion-item *ngFor="let item of items" (click)="execute(item)" [color]="item.color">
			<div class="align-item">
				<ion-icon *ngIf="item.icon" slot="start" [name]="item.icon"></ion-icon>
				<avatar *ngIf="item.avatar" slot="start" [src]="item.avatar" [cssClass]="'round'"></avatar>
				<ion-label class="white-space">{{ item.title }}</ion-label>
			</div>
		</ion-item>
	</ion-list>
</ion-content>