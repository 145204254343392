import { ArrayHelper } from "../../../../helpers/arrayHelper";
import { IdHelper } from "../../../../helpers/idHelper";
import { ConfigData } from "../../../../model/config/ConfigData";
import { ELogActionId } from "../../../logger/models/ELogActionId";
import { ICounting } from "../counting/models/ICounting";
import { EKnownTaskType } from "../models/eknown-task-type";
import { ETaskLogActionId } from "../models/etask-log-action-id";
import { ETaskStatus } from "../models/ETaskStatus";
import { ITask } from "../models/itask";

/** Permet de mettre à disposition des méthodes pour aider à manipuler portant. */
export abstract class TaskHelper {

	//#region FIELDS

	private static readonly C_LOG = "TSK.H::";

	//#endregion FIELDS

	//#region METHODS

	/** Récupère un log action en le préfixant avec l'identifiant de l'app.
	 * @param peTaskLogActionId Identifiant du log action.
	 */
	public static getAppActionId(peTaskLogActionId: ETaskLogActionId): ELogActionId {
		return `${ConfigData.appInfo.appId}-${peTaskLogActionId}` as ELogActionId;
	}

	/** Récupère l'icône de la tâche.
	 * @param poTask Tâche dont on veut récupérer l'icône.
	 * @param psIcon Icône de la tâche par défaut.
	 */
	public static getIcon(poTask: ITask): string {
		if (poTask.icon)
			return poTask.icon;
		else {
			switch (poTask.taskType) {
				case EKnownTaskType.counting:
					return "counting";

				case EKnownTaskType.delivery:
					return "delivery";

				case EKnownTaskType.inventory:
					return "inventory";

				case EKnownTaskType.return:
					return "return";

				default:
					console.warn(`${TaskHelper.C_LOG}Task type "${poTask.taskType}" unknown.`);
					return "";
			}
		}
	}

	/** Retourne la couleur correspondant au statut de la tâche.
	 * @param poTask Tâche dont on veut récupérer la couleur associée à son statut.
	 */
	public static getStatusColor(poTask: ITask): string {
		if (!poTask?.status || poTask.status === ETaskStatus.toDo)
			return "";

		else if (poTask.status === ETaskStatus.active)
			return "active";

		else if (poTask.status === ETaskStatus.done)
			return "success";

		else if (poTask.status === ETaskStatus.cancelled)
			return "secondary";

		else {
			console.error(`${TaskHelper.C_LOG}Can not find color associated to status task "${poTask.status}".`);
			return "";
		}
	}

	/** Retourne l'icône correspondant au statut de la tâche.
	 * @param poTask Tâche dont on veut récupérer l'icône associée à son statut.
	 */
	public static getStatusIcon(poTask: ITask): string {
		if (!poTask?.status || poTask.status === ETaskStatus.toDo)
			return "";

		else if (poTask.status === ETaskStatus.active)
			return "loading-circle";

		else if (poTask.status === ETaskStatus.done)
			return "check-circle";

		else if (poTask.status === ETaskStatus.cancelled)
			return "cancel-circle";

		else {
			console.error(`${TaskHelper.C_LOG}Can not find icon associated to status task "${poTask.status}".`);
			return "";
		}
	}

	/** `true` si la tâche est une tâche de 'provisioning', `false` sinon.
	 * @param poTask Tâche à vérifier.
	 */
	public static isProvisioningTask(poTask: ITask): poTask is ICounting {
		return poTask.taskType === EKnownTaskType.counting && (poTask as unknown as ICounting).automaticProvisioning;
	}

	/** `true` si la tâche est annulée ou terminée, `false` sinon.
	 * @param poTask Tâche à vérifier.
	 */
	public static isTaskCompleted(poTask: ITask): boolean {
		return poTask.status === ETaskStatus.cancelled || poTask.status === ETaskStatus.done;
	}

	public static isOneInState(paTasks: ITask[], paStatuses: Array<ETaskStatus | undefined>): boolean {
		return ArrayHelper.hasElements(paTasks) &&
			paTasks.some((poOriginTask: ITask) => paStatuses.some((peStatus?: ETaskStatus) => this.hasSameStatus(peStatus, poOriginTask.status)));
	}

	public static areAllInStates(paTasks: ITask[], paStatuses: Array<ETaskStatus | undefined>): boolean {
		return ArrayHelper.hasElements(paTasks) &&
			paTasks.every((poOriginTask: ITask) => this.isInState(poOriginTask, paStatuses));
	}

	/** `true` si la tâche a un des statuts demandés, traite le cas de non statut comme `toDo`.
	 * @param poTask Tâche à vérifier.
	 * @param paStatuses Tableau des statuts acceptés.
	 */
	public static isInState(poTask: ITask, paStatuses: Array<ETaskStatus | undefined>): boolean {
		return paStatuses.some((peStatus?: ETaskStatus) => this.hasSameStatus(peStatus, poTask.status));
	}

	/** Vérifie que les statuts sont égaux. Si un statut n'est pas défini il est considéré `toDo`. */
	public static hasSameStatus(peStatus?: ETaskStatus, peTargetStatus?: ETaskStatus): boolean {
		return peStatus === peTargetStatus ||
			(!peStatus && peTargetStatus === ETaskStatus.toDo) || (peStatus === ETaskStatus.toDo && !peTargetStatus);
	}

	public static getAppointmentId(psTaskId: string): string {
		return IdHelper.extractParentId(psTaskId);
	}

	//#endregion

}