<ion-button (click)="showSyncStatusModalAsync()">
	<ng-container *ngTemplateOutlet="(isAnimating$ | async) ? animatedIcon : staticIcon"></ng-container>
</ion-button>

<ng-template #animatedIcon>
	<div class="icon-synchro">
		<calao-database-sync-animated-icon [databaseSyncStatus]="databaseSyncStatus$ | async" className="toolbar-icon">
		</calao-database-sync-animated-icon>
	</div>
</ng-template>

<ng-template #staticIcon>
	<ion-icon [name]="iconName$ | async" slot="icon-only" class="icon-synchro"></ion-icon>
</ng-template>