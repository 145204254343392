import { of } from 'rxjs';
import { catchError, map, mergeMap, take, timeout } from 'rxjs/operators';
import { SqlService } from '../services/sql.service';

const LOG_ID = "WI$.D::";

/** Attend l'initialisation de la base de données Sqlite pendant un certain temps avant d'exécuter la méthode appelée.
 * @param pnWaitingMsBeforeTimeout Temps d'attente en millisecondes avant de tomber en timeout, 10000 (10s) par défaut.
 */
export function WaitInitialisation$<T extends SqlService, U extends Promise<T[]>>(pnWaitingMsBeforeTimeout: number = 10000):
	(poTarget: T, psMethodName: string, poDescriptor: TypedPropertyDescriptor<(...paArgs: any[]) => U>) => TypedPropertyDescriptor<(...paArgs: any[]) => U> {

	return function (poTarget: T, psMethodName: string, poDescriptor: TypedPropertyDescriptor<(...paArgs: any[]) => U>)
		: TypedPropertyDescriptor<(...paArgs: any[]) => U> {
		const lfOriginalMethod: ((...paArgs: any[]) => U) | undefined = poDescriptor.value; // On sauvegarde l'ancienne implémentation de la méthode.

		poDescriptor.value = function (): U {
			const loArguments: IArguments = arguments; // Arguments de la méthode sur laquelle on applique le décorateur.
			const loTarget: T = this; // Instance qui appelle le décorateur.

			return loTarget.initialization$
				.pipe(
					timeout(pnWaitingMsBeforeTimeout),
					map(() => true),
					catchError(_ => { // Une erreur est levée si le timeout est atteint.
						console.info(`${LOG_ID}Sql database initialization timeout`);
						return of(false);
					}),
					take(1),
					mergeMap(_ => {
						if (lfOriginalMethod)
							return lfOriginalMethod.apply(loTarget, loArguments);
						else {
							console.error(`${LOG_ID}Original method is undefined ! Caller is '${poTarget.constructor.name}.${psMethodName}' with a timeout of ${pnWaitingMsBeforeTimeout}ms.`, poDescriptor);
							return of(undefined);
						}
					})
				).toPromise() as U;
		};

		return poDescriptor;
	};
};