import { TaskBase } from 'libs/osapp/src/services/backgroundTask/TaskBase';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ENetworkFlag } from '../../../model/application/ENetworkFlag';
import { IDmsTaskParams } from '../../../model/backgroundTask/taskParams/IDmsTaskParams';
import { TaskDescriptor } from '../../../services/backgroundTask/TaskDescriptor';
import { FlagService } from '../../../services/flag.service';
import { InjectorService } from '../../../services/injector.service';
import { SyncDmsService } from '../services/syncDms.service';

export class SyncDmsTask<T extends IDmsTaskParams> extends TaskBase<T> {

	//#region FIELDS

	private msvcSyncDms: SyncDmsService;
	/** Service permettant de manipuler les flags. */
	private msvcFlag: FlagService;

	//#endregion

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
		this.msvcSyncDms = InjectorService.instance.get(SyncDmsService);
		this.msvcFlag = InjectorService.instance.get(FlagService);
	}

	public execTask$(): Observable<Array<string>> {
		return this.msvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)
			.pipe(mergeMap(_ => this.msvcSyncDms.syncPendingChanges(true)));
	}

	//#endregion
}