import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { EMPTY, Observable, defer } from 'rxjs';
import { catchError, finalize, last, mapTo, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { NumberHelper } from '../../../../helpers/numberHelper';
import { IUiResponse } from '../../../../model/uiMessage/IUiResponse';
import { ShowMessageParamsPopup } from '../../../../services/interfaces/ShowMessageParamsPopup';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { UpdateEvent } from '../../../sqlite/models/update-event';
import { ECatalogVersionResponseStatus } from '../models/ECatalogVersionResponseStatus';
import { ICatalogVersionResponse } from '../models/ICatalogVersionResponse';
import { CatalogService } from '../services/catalog.service';

@Component({
	selector: 'calao-catalog-diagnostics',
	templateUrl: './catalog-diagnostics.component.html',
	styleUrls: ['./catalog-diagnostics.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogDiagnosticsComponent extends ComponentBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "CTLG.DIAGNOSTICS::";

	//#endregion

	//#region PROPERTIES

	private mbIsCatalogUpdating: boolean;

	public get isCatalogUpdating(): boolean {
		return this.mbIsCatalogUpdating;
	}

	public set isCatalogUpdating(pbIsCatalogUpdating: boolean) {
		if (pbIsCatalogUpdating !== this.mbIsCatalogUpdating) {
			this.mbIsCatalogUpdating = pbIsCatalogUpdating;
			this.detectChanges();
		}
	}

	private moReadyVersion = new ObservableProperty<string>("Chargement");
	public readonly readyVersion$: Observable<string> = this.moReadyVersion.value$.pipe(takeUntil(this.destroyed$));

	private moLoadedVersion = new ObservableProperty<string>("Chargement");
	public readonly loadedVersion$: Observable<string> = this.moLoadedVersion.value$.pipe(takeUntil(this.destroyed$));

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcCatalog: CatalogService,
		private readonly isvcUiMessage: UiMessageService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
		this.updateVersionsAsync();
	}

	private setOpenedVersion(): void {
		this.moLoadedVersion.value = this.isvcCatalog.getOpenedDataSource()?.version.toString() ?? "Aucune";
		this.detectChanges();
	}

	private async setReadyVersionAsync(): Promise<void> {
		const lnVersion: number | undefined = await this.isvcCatalog.getLastReadyVersionAsync();
		this.moReadyVersion.value = NumberHelper.isValid(lnVersion) ? lnVersion.toString() : "Aucune";
		this.detectChanges();
	}

	private async updateVersionsAsync(): Promise<void> {
		this.setOpenedVersion();
		await this.setReadyVersionAsync();
	}

	public updateCatalogVersionAsync(): Promise<void> {
		this.isCatalogUpdating = true;

		return this.isvcCatalog.getLastVersionData()
			.pipe(
				mergeMap((poCatalogResponse: ICatalogVersionResponse) => {
					if (poCatalogResponse.status === ECatalogVersionResponseStatus.available) {
						return defer(() => this.createDownloadPopupAsync())
							.pipe(
								mergeMap((poAlert: HTMLIonAlertElement) => poAlert.present().then(() => poAlert)),
								mergeMap((poAlert: HTMLIonAlertElement) => this.downloadAndInstall$(poCatalogResponse, poAlert))
							);
					}
					else
						return this.showNoUpdateAvailablePopupAsync();
				}),
				finalize(() => this.isCatalogUpdating = false),
				catchError((poError) => {
					console.error(`${CatalogDiagnosticsComponent.C_LOG_ID}Erreur lors de la récupération ou installation du catalogue:${JSON.stringify(poError)}`);
					return EMPTY;
				})
			)
			.toPromise();
	}

	private downloadAndInstall$(poCatalogResponse: ICatalogVersionResponse, poAlert: HTMLIonAlertElement): Observable<void> {
		return this.isvcCatalog.downloadAndInstall$(poCatalogResponse)
			.pipe(
				tap((poCatalogUpdateEvent?: UpdateEvent) => {
					if (poCatalogUpdateEvent) {
						if (poCatalogUpdateEvent.progression === 100)
							this.updateVersionsAsync();
						else
							poAlert.message = this.getProgressMessage(poCatalogUpdateEvent.progression);
					}
				}),
				last(),
				mapTo(undefined),
				finalize(() => poAlert.dismiss())
			);
	}

	private showNoUpdateAvailablePopupAsync(): Promise<void> {
		return this.isvcUiMessage.showAsyncMessage(new ShowMessageParamsPopup({
			message: `Pas de mise à jour disponible.`,
			buttons: [{ text: "Ok", handler: () => UiMessageService.getFalsyResponse() }],
			backdropDismiss: true
		}))
			.toPromise()
			.then(_ => { });
	}

	/** Supprime le catalogue et redémarre l'application.
	 */
	public deleteCatalogAsync(): Promise<void> {
		return this.isvcUiMessage.showAsyncMessage(new ShowMessageParamsPopup({
			message: `Supprimer le catalogue`,
			buttons: [
				{ text: "Ok", handler: () => UiMessageService.getTruthyResponse() },
				{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() }
			],
			backdropDismiss: true
		}))
			.pipe(
				mergeMap((poResult: IUiResponse<boolean>) => {
					if (poResult.response) {
						return defer(() => this.isvcCatalog.removeCatalog())
							.pipe(
								mergeMap((_) => this.updateVersionsAsync()),
								tap(() => this.detectChanges())
							);
					}
					else
						return EMPTY;
				})
			)
			.toPromise();
	}

	/** Crée et retourne la popup de téléchargement de la base de données. */
	private createDownloadPopupAsync(): Promise<HTMLIonAlertElement> {
		return this.isvcUiMessage.createPopupAsync(
			new ShowMessageParamsPopup({ header: "Téléchargement en cours", message: this.getProgressMessage(0) })
		);
	}

	/** Retourne un message pour la popup de téléchargement avec la progression du téléchargement.
	 * @param pnPercentage Pourcentage du téléchargement.
	 */
	private getProgressMessage(pnPercentage: number): string {
		return `<div class="ion-text-center"><ion-label>${pnPercentage}%</ion-label></div>`;
	}

	//#endregion

}