<div class="container" *ngIf="databaseSyncStatus">
	<div class="database-info">
		<p class="database-name">{{ databaseSyncStatus.title }}</p>
		<p *ngIf="databaseSyncStatus.description" class="detail">({{ databaseSyncStatus.description }})</p>
	</div>

	<ion-badge color="primary">
		<ng-container *ngTemplateOutlet="(observableIsAnimating.value$ | async) ? animatedIcon : staticIcon"></ng-container>
	</ion-badge>
</div>

<ng-template #animatedIcon>
	<div class="icon-synchro">
		<calao-database-sync-animated-icon [databaseSyncStatus]="databaseSyncStatus.status" width="50%"
			height="47%"></calao-database-sync-animated-icon>
	</div>
</ng-template>

<ng-template #staticIcon>
	<ion-icon [name]="observableStaticIcon.value$ | async"></ion-icon>
</ng-template>