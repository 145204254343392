import { ILastChange } from '../../../../hooks/models/ilast-change';
import { ILastChangeAgent } from '../../../../hooks/models/ilast-change-agent';
import { ILastChangeBrowser } from '../../../../hooks/models/ilast-change-browser';
import { ILastChangeClient } from '../../../../hooks/models/ilast-change-client';
import { ILastChangeDevice } from '../../../../hooks/models/ilast-change-device';
import { ILastChangePlatform } from '../../../../hooks/models/ilast-change-platform';
import { ILastChangeUser } from '../../../../hooks/models/ilast-change-user';

export class LastChange implements ILastChange {

	//#region PROPERTIES

	/** @implements */
	public agent: ILastChangeAgent;
	/** @implements */
	public user: ILastChangeUser;
	/** @implements */
	public date: string;
	/** @implements */
	public client?: ILastChangeClient;
	/** @implements */
	public platform?: ILastChangePlatform;
	/** @implements */
	public browser?: ILastChangeBrowser;
	/** @implements */
	public device?: ILastChangeDevice;

	//#endregion

}
