import { Injectable } from '@angular/core';

/** Service de configuration pour la lecture des codes-barres. Doit être redéfini dans une application et injecté dans le module de celle-ci. */
@Injectable()
export abstract class BarcodeReaderConfigService {

	//#region METHODS

	constructor() { }

	/** Récupère le tableau des préfixes de code-barres qu'on ne veut pas prendre en compte lors d'un scan. */
	public abstract getExcludePrefixBarcodes(): ReadonlyArray<string>;

	//#endregion METHODS

}