import { ObjectHelper } from "../../../../../helpers/objectHelper";

export class DevalidateRackError extends Error {

	constructor(psMessage: string) {
		super(psMessage);
		// Nécessaire pour que le 'instanceof DevalidateRackError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, DevalidateRackError);
	}

}