import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { IReason } from './IReason';

export class Reason<Behavior = any> implements IReason<Behavior> {

	//#region PROPERTIES

	/** @implements */
	public readonly _id: string;

	/** @implements */
	public _rev?: string;

	/** @implements */
	public readonly description?: string;

	/** @implements */
	public readonly childIds?: string[];

	/** @implements */
	public readonly scope?: "item" | "sku";

	/** @implements */
	public readonly lock?: boolean;

	/** @implements */
	public readonly behavior?: Behavior;

	/** @implements */
	public readonly icon?: string;

	/** @implements */
	public readonly color?: string;

	//#endregion PROPERTIES

	//#region METHODS

	constructor() { }

	/** Indique si le motif est un motif parent. */
	public isParentReason(): boolean {
		return ArrayHelper.hasElements(this.childIds);
	}

	//#endregion METHODS

}