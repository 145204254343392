import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, ElementRef, QueryList, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { SwiperContainer } from 'swiper/element';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';
import { TabsTabComponent } from '../tabs-tab/tabs-tab.component';

@Component({
	selector: 'calao-tabs-tab-container',
	templateUrl: './tabs-tab-container.component.html',
	styleUrls: ['./tabs-tab-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsTabContainerComponent extends DestroyableComponentBase implements AfterContentInit {

	//#region FIELDS

	@ContentChildren(TabsTabComponent) private moTabs: QueryList<TabsTabComponent>;
	@ContentChildren("tabTemplate") private moTemplates: QueryList<TemplateRef<HTMLElement>>;

	//#endregion

	//#region PROPERTIES

	public readonly observableActiveTabIndex = new ObservableProperty<number>(0);

	public readonly observableTemplates = new ObservableArray<TemplateRef<HTMLElement>>();

	//#endregion

	//#region METHODS

	constructor(private readonly ioElementRef: ElementRef<HTMLElement>) {
		super();
	}

	public ngAfterContentInit(): void {
		const loSwiperContainer: SwiperContainer = this.ioElementRef.nativeElement.querySelector('swiper-container');

		loSwiperContainer.addEventListener('swiperslidechange', () => {
			this.observableActiveTabIndex.value = loSwiperContainer.swiper.activeIndex;
		});

		this.observableTemplates.resetSubscription(this.getTemplates$());

		this.observableActiveTabIndex.value$.pipe(
			tap((pnIndex: number) => loSwiperContainer.swiper?.slideTo(pnIndex)),
			secure(this)
		).subscribe();

		this.moTabs.changes.pipe(
			startWith({}),
			tap(() => {
				this.moTabs.forEach((poTab: TabsTabComponent, pnIndex: number) => poTab.index = pnIndex);
			}),
			secure(this)
		).subscribe();
	}

	private getTemplates$(): Observable<TemplateRef<HTMLElement>[]> {
		return this.moTemplates.changes.pipe(
			startWith({}),
			map(() => this.moTemplates.toArray()),
			secure(this)
		);
	}

	//#endregion

}
