import { EPrefix } from "@calaosoft/osapp/model/EPrefix";
import { ELogActionId } from "@calaosoft/osapp/modules/logger/models/ELogActionId";
import { EPermission } from "@calaosoft/osapp/modules/permissions/models/EPermission";

//#region ACTION ID

export const VALIDATE_COUNTING_LOG_ACTION_ID = "merch-task-validate-counting" as ELogActionId;
export const DEVALIDATE_COUNTING_LOG_ACTION_ID = "merch-task-devalidate-counting" as ELogActionId;
export const ACTIVATE_COUNTING_LOG_ACTION_ID = "merch-task-activate-counting" as ELogActionId;
export const VALIDATE_RETURN_LOG_ACTION_ID = "merch-task-validate-return" as ELogActionId;
export const VALIDATE_DELIVERY_LOG_ACTION_ID = "merch-task-validate-delivery" as ELogActionId;
export const VALIDATE_INVENTORY_LOG_ACTION_ID = "merch-task-validate-inventory" as ELogActionId;
export const TASK_OUTDATED_ENTERED_ACTION_ID = "merch-task-outdated-entered" as ELogActionId;
export const TASK_COUNTING_LOT_SAVED_ACTION_ID = "merch-task-counting-lot-saved" as ELogActionId;
export const TASK_COUNTING_LOT_DELETED_ACTION_ID = "merch-task-counting-lot-deleted" as ELogActionId;
export const DELIVERY_TASK_VALIDATION_DECLINED = "merch-task-validation-declined-delivery" as ELogActionId;
export const RETURN_TASK_VALIDATION_DECLINED = "merch-task-validation-declined-return" as ELogActionId;
export const COUNTING_TASK_VALIDATION_DECLINED = "merch-task-validation-declined-counting" as ELogActionId;
export const INVENTORY_TASK_VALIDATION_DECLINED = "merch-task-validation-declined-inventory" as ELogActionId;
export const DELIVERY_TASK_VALIDATION_REJECTED = "merch-task-validation-rejected-delivery" as ELogActionId;
export const INVENTORY_TASK_VALIDATION_REJECTED = "merch-task-validation-rejected-inventory" as ELogActionId;
export const RETURN_TASK_VALIDATION_REJECTED = "merch-task-validation-rejected-return" as ELogActionId;
export const COUNTING_TASK_VALIDATION_REJECTED = "merch-task-validation-rejected-counting" as ELogActionId;

//#endregion

//#region MOTIFS

/** Identifiant du document pour le motif de non prélèvement. */
export const NO_PICKING_REASON_ID = "reason_RP300";
/** Identifiant du motif parent pour les anomalies dans une tâche de reprise. */
export const ANOMALY_PARENT_REASON_ID = "reason_RP000";
/** Identifiant du motif parent pour les anomalies des conditionnements de livraisons.
 * Suffixe `_new` temporaire le temps que tous les utilisateurs soient à jour pour afficher plusieurs niveaux de motifs.
 */
export const PACK_ANOMALY_PARENT_REASON_ID = "reason_HC000_new";
/** Identifiant du motif parent pour les anomalies de livraisons d'articles manquants. */
export const DELIVERY_CONTROL_MISSING_ITEMS_REASON_ID = "reason_LP200";
/** Identifiant du motif parent pour les anomalies de livraisons d'articles supplémentaires. */
export const DELIVERY_CONTROL_EXTRA_ITEMS_REASON_ID = "reason_LP300";
/** Identifiant du motif parent pour l'annulation d'une tâche de comptage. */
export const CANCEL_COUNTING_PARENT_ID = "reason_DC000";
/** Identifiant du motif parent pour l'annulation d'une tâche de reprise. */
export const CANCEL_RETURN_PARENT_ID = "reason_RC000";
/** Identifiant du motif parent pour l'annulation d'une tâche de livraison. */
export const CANCEL_DELIVERY_PARENT_ID = "reason_LC000";
/** Identifiant du motif parent pour l'annulation d'une tâche d'inventaire. */
export const CANCEL_INVENTORY_PARENT_ID = "reason_IC000";

//#endregion

//#region RÔLES

/** Rôle "customerContact" pour les groupes ayant le rôle de contact client. */
export const CUSTOMER_CONTACT_ROLE = "customerContact";

//#endregion

//#region Prefix

export const CONTACT_CUSTOMER_PREFIX: EPrefix = `${EPrefix.contact}${EPrefix.customer}` as EPrefix;

//#endregion Prefix

//#region Permissions

export const CUSTOMER_CONTACT_PERMISSION = "customers" as EPermission;

//#endregion Permissions

/** Identifiant utilisé pour renseigner la propriété lastChange */
export const APPLICATION_CLIENT_ID = "merchapp";
/** Libellé pour les articles de bon qui sont en défaut (anomalie de défaut). */
export const DEFAULT_ARTICLES_LABEL = "Défaut";
/** Identifiant gencode de Textilot. */
export const TEXTILOT_ID = "359834";
export const C_CIPHERLAB_MODEL = "RS35";
export const ARTICLE_QUANTITY_LABEL_SINGULAR = "pièce";
export const ARTICLE_QUANTITY_LABEL_PLURAL = "pièces";
export const DEFAULT_RACK_LABEL = "portant";
export const DEFAULT_RACKS_LABEL = "portants";
/** Constante utilisée tant que les reprises et les inventaires partagent les mêmes pages. */
export const RETURN_TASK_TYPE_URL_PART_REGEX = "(return|inventory)";