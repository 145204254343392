import { Injectable } from '@angular/core';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { Store } from '../../../services/store.service';
import { CustomersService } from '../../customer/services/customers.service';
import { IGetUsersTourParams } from '../../tour/models/IGetUsersTourParams';
import { ITour } from '../../tour/models/ITour';
import { TourService } from '../../tour/tour/tour.service';

@Injectable()
export class CustomerTourService extends CustomersService {

	//#region METHODS

	constructor(private readonly isvcTour: TourService, psvcStore: Store) {
		super(psvcStore);
	}

	/** Retourne les identifiants des clients de toutes les tournées de l'utilisateur courant. */
	public getCustomerIdsAsync(poGetUsersTourParams?: IGetUsersTourParams): Promise<string[]> {
		return this.isvcTour.getUserTours(poGetUsersTourParams)
			.toPromise()
			.then((paTours: ITour<any>[]) => {
				return ArrayHelper.getValidValues(
					ArrayHelper.unique(paTours.map((poTour: ITour<any>) => poTour.customerIds).flat())
				);
			});
	}

	//#endregion

}