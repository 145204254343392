import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICategoryClick } from './models/ICategoryClick';
import { ICategoryItem } from './models/ICategoryItem';

@Component({
	selector: "calao-categories",
	templateUrl: './categories.component.html',
	styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent {

	//#region FIELDS

	/** Ensemble des catégories avec une quantité. */
	@Input() public categories: ICategoryItem[];

	@Output("categoryClick") private readonly moCategoryClickEvent = new EventEmitter<ICategoryClick>();

	//#endregion

	//#region METHODS

	public constructor() { }

	public onCategoryClick(poValue: ICategoryClick): void {
		this.moCategoryClickEvent.emit(poValue);
	}

	//#endregion

}