import { Injectable } from '@angular/core';
import { IDmsMeta } from '@calaosoft/osapp/modules/dms/model/IDmsMeta';
import { MetaEntityBuilderBase } from '@calaosoft/osapp/modules/dms/model/MetaEntityBuilderBase';
import { IEntity } from '@calaosoft/osapp/modules/entities/models/ientity';

@Injectable()
export class MetaMerchBuilderBase extends MetaEntityBuilderBase {

	//#region METHODS

	public override prepareMeta(poEntity: IEntity, poMeta: IDmsMeta): Promise<IDmsMeta> {
		poMeta.documentType = this.getEntityDocumentType(poEntity);

		return Promise.resolve(poMeta);
	}

	public override getEntityDocumentType(poEntity: IEntity): string {
		return "RPTDOC";
	}

	//#endregion

}