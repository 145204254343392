<ion-item *ngIf="observableItem.value$ | async as contact" lines="none" (click)="onContactClicked(contact)">
	<ng-container *ngTemplateOutlet="itemContent; context : { contact : contact }"></ng-container>
	<div *ngIf="(observablePopOverItemsParams.changes$ | async).length > 0" slot="end" class="no-margin-start">
		<ion-button class="itemb btn-more" fill="clear" (click)="$event.stopPropagation(); presentPopOverAsync($event)">
			<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
		</ion-button>
	</div>
</ion-item>

<ng-template #itemContent let-contact="contact">
	<calao-conflict-list-marker *ngIf="contact._conflicts"></calao-conflict-list-marker>
	<!-- Avatar -->
	<div class="start">
		<ion-avatar class="avatar" calaoBadgeIcon="trade" calaoBadgeIconPosition="below after"
			[calaoBadgeIconHidden]="!contact.userId">
			<avatar [src]="getContactAvatar(contact)" cssClass="icon-area opacity60">
			</avatar>
		</ion-avatar>
	</div>
	<!-- Affichage des textes. -->
	<ion-text class="text-container">
		<ng-container
			*ngTemplateOutlet="isUserContact(contact) ? userContactLabel: contactLabel; context : { contact : contact }">
		</ng-container>
		<ion-note class="no-margin contact-groups">{{ observableGroupsLabel.value$ | async }}</ion-note>
	</ion-text>
</ng-template>

<ng-template #userContactLabel>
	<ion-label ngClass="first-name">Moi</ion-label>
</ng-template>

<ng-template #contactLabel let-contact="contact">
	<div class="contactlabel">
		<ion-label class="uppercase last-name">{{ contact.lastName }}</ion-label>
		<ion-label class="capitalize">{{ contact.firstName }}</ion-label>
	</div>
</ng-template>