import { IBookingConfig } from '../../modules/booking/IBookingConfig';
import { ILogisticsConfig } from '../../modules/logistics/reading/models/ILogisticsConfig';
import { ISecurityConfig } from '../../services/security/ISecurityConfig';
import { IAppInfo } from '../application/IAppInfo';
import { IBackgroundTask } from '../backgroundTask/IBackgroundTask';
import { IEnvironment } from '../environment/IEnvironment';
import { IAuthentication } from '../IAuthentication';
import { IDatabaseConfig } from '../store/IDatabaseConfig';
import { IUpdateConfig } from '../update/IUpdateConfig';
import { IConfigOneSignal } from './IConfigOneSignal';
import { IContactsConfig } from './icontacts-config';
import { IConversationConfig } from './IConversationConfig';
import { IDocumentsConfiguration } from './idocument-configuration';
import { ILoggerConfig } from './ILoggerConfig';

export abstract class ConfigData {

	/** Informations statique sur l'application. */
	public static appInfo: IAppInfo;
	/** Informations statique sur le type d'authentification qui va être utilisé par l'application. */
	public static authentication: IAuthentication;
	/** Informations sur l'environnement. */
	public static environment: IEnvironment;
	/** Informations sur les tâches de fond. */
	public static backgroundTask: IBackgroundTask;
	/** Configuration pour la mise à jour de l'application. */
	public static update: IUpdateConfig;
	/** Indique si on est en version de production ou non. */
	public static isProductionEnvironment: boolean;
	/** Information sur les configurations des conversation. */
	public static conversation?: IConversationConfig;
	/** Configuration des bases de données. */
	public static databases: IDatabaseConfig[] = [];
	/** Configuration des bases de données de workspace. */
	public static workspaceDatabases: IDatabaseConfig[];
	/** Configuration de la sécurité de l'application. */
	public static security?: ISecurityConfig;
	/** Configuration pour OneSignal (utilisé par le NotificationService). Si `undefined`, le notification.service n'est jamais intialisé. */
	public static oneSignal?: IConfigOneSignal;
	/** Configuration pour le module Calao. */
	public static logistics?: ILogisticsConfig;

	/** Configuration du logging. */
	public static logger: ILoggerConfig;

	/** Configuration du module Booking. */
	public static booking?: IBookingConfig;
	/** Configuration du module contact. */
	public static contacts?: IContactsConfig;
	/** Identifiants des descripteurs d'entités inclus dans l'application. */
	public static builtInEntityDescIds?: string[];

	/** Configuration pour l'enregistrement des documents. */
	public static documentsConfiguration?: IDocumentsConfiguration;
}