import { Injectable } from '@angular/core';
import { combineLatest, defer, forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, map, mapTo, mergeMap, mergeMapTo, switchMap, tap, toArray } from 'rxjs/operators';
import { ArrayHelper } from '../helpers/arrayHelper';
import { EntityHelper } from '../helpers/entityHelper';
import { IdHelper } from '../helpers/idHelper';
import { StoreHelper } from '../helpers/storeHelper';
import { StringHelper } from '../helpers/stringHelper';
import { UserData } from '../model/application/UserData';
import { EBarElementAction } from '../model/barElement/EBarElementAction';
import { EEntityLinkCacheData } from '../model/entities/EEntityLinkCacheData';
import { IEntityLink } from '../model/entities/IEntityLink';
import { IEntityLinkCache } from '../model/entities/IEntityLinkCache';
import { EPrefix } from '../model/EPrefix';
import { ELinkAction } from '../model/link/ELinkAction';
import { ELinkTemplate } from '../model/link/ELinkTemplate';
import { LinkInfo } from '../model/link/LinkInfo';
import { ILinkedItemsListParams } from '../model/linkedItemsList/ILinkedItemsListParams';
import { INavbarEvent } from '../model/navbar/INavbarEvent';
import { ActivePageManager } from '../model/navigation/ActivePageManager';
import { PageInfo } from '../model/PageInfo';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { ICacheData } from '../model/store/ICacheData';
import { ICustomPouchError } from '../model/store/ICustomPouchError';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { IEntity } from '../modules/entities/models/ientity';
import { ObservableProperty } from '../modules/observable/models/observable-property';
import { PageManagerService } from '../modules/routing/services/pageManager.service';
import { IDataSourceRemoteChanges } from '../modules/store/model/IDataSourceRemoteChanges';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { Store } from './store.service';
import { UiMessageService } from './uiMessage.service';
import { WorkspaceService } from './workspace.service';

@Injectable({ providedIn: "root" })
export class EntityLinkService {

	//#region FIELDS

	/** Liste des liens associés */
	private static readonly C_LINKED_ITEMS_LIST_NAV_BUTTON_ID = "linkedItemsList";
	/** Identifiant du service pour les logs. */
	private static readonly C_LOG_ID = "EL.S::";
	/** Base de données relative pour les liens. */
	private static readonly C_RELATIVE_DATABASE_SOURCE = ".";

	/** Tableau contenant les entités courantes. La dernière est la plus récente. */
	private maCurrentEntityStack: IEntity[] = [];

	//#endregion

	//#region PROPERTIES

	public static readonly C_DEEPLINK_SUB_PATH_SEPARATOR = "/";

	/** Retourne l'entité courante. */
	public get currentEntity(): IEntity { return ArrayHelper.getLastElement(this.maCurrentEntityStack); }

	public readonly observableLinkInfo = new ObservableProperty<LinkInfo>();

	//#endregion

	//#region METHODS

	constructor(
		/** Service de gestion des requêtes en base de données. */
		private readonly isvcStore: Store,
		/** Service de gestion des pages. */
		private readonly isvcPageManager: PageManagerService,
		private readonly isvcWorkspace: WorkspaceService,
		private readonly isvcUiMessage: UiMessageService
	) { }

	//#region Links

	/** Création de documents de liaison entre un sujet et un ensemble de cibles.
	 * Ex: on veut créer une conversation à partir d'un contact (la conversation sera liée au contact) : lnk_cont_guid-conv_guid.
	 * @param poSomething Source du lien, quelque chose va lui être lié.
	 * @param paSomethingElse Tableau des éléments qui vont être liées à la source.
	 */
	private createEntityLinks(poSomething: IEntity, paSomethingElse: IEntityLinkCache[]): Observable<Array<IStoreDataResponse | ICustomPouchError>> {
		const laItemLinks: IEntityLink[] = ArrayHelper.flat(paSomethingElse.map((poEntity: IEntityLinkCache) => {
			const laEntityLinks: IEntityLink[] = this.buildEntityLinks(poSomething, poEntity.entity);
			const loCacheData: ICacheData = {
				databaseId: this.isvcWorkspace.isDocumentFromWorkspace(poSomething) ?
					StoreHelper.getDatabaseIdFromCacheData(poSomething) : this.isvcWorkspace.getWorkspaceDatabaseIdFromDatabaseId(StoreHelper.getDatabaseIdFromCacheData(poEntity.entity))
			};

			laEntityLinks.forEach((poEntityLink: IEntityLink) => StoreHelper.updateDocumentCacheData(poEntityLink, loCacheData));

			return laEntityLinks;
		}));

		return this.isvcStore.putMultipleDocuments(laItemLinks, undefined, true)
			.pipe(
				tap((paResults: IStoreDataResponse[]) => console.debug(`${EntityLinkService.C_LOG_ID}Création des documents de lien : '${paResults.every((poResult: IStoreDataResponse) => poResult.ok)}'.`)),
				catchError(poError => {
					console.error(`${EntityLinkService.C_LOG_ID}Erreur de création des liens (Avez-vous ajouté l'entité au config.ts de l'application ?) :`, poError);
					return throwError(poError);
				})
			);
	}

	/** Retourne l'ensemble des liens depuis les workspaces qui ont `psItemId` comme document lié.
	 * @param psItemId Identifiant de l'item pour lequel on veut tous les domaines associés.
	 * @param paLinkedEntityPrefixes Tableau des préfixes de liens d'entités qu'il faut récupérer, optionnel (les récupère tous dans ce cas).
	 * @param pbLive Indique si la requête est live.
	 * @param pbIncludeDeeplinks Indique si l'on doit inclure les deeplinks. Faux par défaut. (format `lnk_entity_a/sub/subGuid-entity_b`)
	 */
	public getEntityLinks(
		psItemId: string,
		paLinkedEntityPrefixes?: Array<EPrefix>,
		pbLive?: boolean,
		pbIncludeDeeplinks?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Array<IEntityLink>> {
		if (StringHelper.isBlank(psItemId))
			return of([]);

		else {
			return this.isvcStore.get(this.createEntityLinksDataSource(psItemId, paLinkedEntityPrefixes, pbLive, pbIncludeDeeplinks, poActivePageManager))
				.pipe(
					tap((paEntityLinks: IEntityLink[]) => this.replaceRelativeDatabaseSource(paEntityLinks)),
					mergeMap((paEntityLinks: IEntityLink[]) => {
						return from(paEntityLinks)
							.pipe(
								tap((poEntityLink: IEntityLink) => {
									if (poEntityLink.createDate)
										poEntityLink.createDate = new Date(poEntityLink.createDate);
								}),
								toArray()
							);
					})
				);
		}
	}

	/** Remplace les bases de données relatives d'une entité liée si elle en possède.
	 * @param poLink Lien d'entité dont il faut remplacer les bases de données relatives (s'il y en a).
	 */
	private replaceRelativeDatabaseSource(poLink: IEntityLink): void;
	/** Remplace les bases de données relatives d'un tableau d'entités liées si elles en possèdent.
	 * @param paLinks Tableau des liens d'entité dont il faut remplacer les bases de données relatives (s'il y en a).
	 */
	private replaceRelativeDatabaseSource(paLinks: IEntityLink[]): void;
	private replaceRelativeDatabaseSource(poData: IEntityLink | IEntityLink[]): void {
		// On initialise le tableau des liens en vérifiant que le paramètre est valide ou tableau vide par défaut.
		const laLinks: IEntityLink[] = poData instanceof Array ? poData : (poData ? [poData] : []);

		laLinks.forEach((poLink: IEntityLink) => {
			poLink.databasesSource.forEach((psSource: string, pnIndex: number) => {
				if (psSource === EntityLinkService.C_RELATIVE_DATABASE_SOURCE)
					poLink.databasesSource[pnIndex] = StoreHelper.getDatabaseIdFromCacheData(poLink);
			});
		});

	}

	/** Crée la dataSource pour récupérer les liens d'entités.
	 * @param psItemId Identifiant de l'item pour lequel on veut tous les domaines associés.
	 * @param paLinkedEntityPrefixes Tableau des préfixes de liens d'entités qu'il faut récupérer, optionnel (les récupère tous dans ce cas).
	 * @param pbLive Indique si la requête est live.
	 * @param pbIncludeDeeplinks Indique si l'on doit inclure les deeplinks. Faux par défaut. (format `lnk_entity_a/sub/subGuid-entity_b`)
	 */
	private createEntityLinksDataSource(
		psItemId: string,
		paLinkedEntityPrefixes?: Array<EPrefix>,
		pbLive?: boolean,
		pbIncludeDeeplinks?: boolean,
		poActivePageManager?: ActivePageManager
	): IDataSource {
		const lsId =
			`${IdHelper.buildId(EPrefix.link, psItemId)}${pbIncludeDeeplinks ? "" : `-${paLinkedEntityPrefixes?.length === 1 ? ArrayHelper.getFirstElement(paLinkedEntityPrefixes) : ""}`}`;

		const loDataSource: IDataSourceRemoteChanges = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				startkey: lsId,
				endkey: lsId + Store.C_ANYTHING_CODE_ASCII,
				include_docs: true
			},
			live: pbLive,
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager,
			filter: ArrayHelper.hasElements(paLinkedEntityPrefixes) && pbIncludeDeeplinks ? this.filterEntityLinksFunction(paLinkedEntityPrefixes) : undefined
		};

		return loDataSource;
	}

	/** Retourne une fonction permettant de filtrer les liens d'entités en fonction d'un tableau de préfixes.
	 * @param paLinkedEntityPrefixes Tableau des préfixes de liens d'entités qu'il faut récupérer, optionnel (les récupère tous dans ce cas).
	 */
	private filterEntityLinksFunction(paLinkedEntityPrefixes: Array<EPrefix>): (poDoc: IEntityLink) => boolean {
		return (poDoc: IEntityLink) => paLinkedEntityPrefixes.some((pePrefix: EPrefix) => poDoc._id.indexOf(`-${pePrefix}`) >= 0);
	}

	/** Permet de récupérer l'identifiant de la cible du lien.
	 * ### Attention, utiliser de préférence la méthode *getEntityLinkPartFromPrefix(entityLink, prefix?)* sauf si le lien est de type `lnk_a-a`.
	 * @param poItemLink Lien.
	 */
	public getLinkTargetId(poItemLink: IEntityLink): string {
		return EntityHelper.getIdsFromLinkId(poItemLink._id)[1];
	}

	/** Permet de récupérer l'identifiant de la base de données où se trouve la cible du lien.
	 * ### Attention, utiliser de préférence la méthode `getEntityLinkPartFromPrefix()` sauf si le lien est de type `lnk_a-a`.
	 * @param poItemLink Lien.
	 */
	public getLinkTargetDatabaseId(poItemLink: IEntityLink): string {
		return poItemLink.databasesSource[1];
	}

	/** Permet de récupérer l'identifiant de la source du lien.
	 * ### Attention, utiliser de préférence la méthode `getEntityLinkPartFromPrefix()` sauf si le lien est de type `lnk_a-a`.
	 * @param poItemLink Lien.
	 */
	public getLinkSourceId(poItemLink: IEntityLink): string {
		return EntityHelper.getIdsFromLinkId(poItemLink._id)[0];
	}

	/** Permet de récupérer l'identifiant de la base de données où se trouve la source du lien.
	 * ### Attention, utiliser de préférence la méthode `getEntityLinkPartFromPrefix()` sauf si le lien est de type `lnk_a-a`.
	 * @param poItemLink Lien.
	 */
	public getLinkSourceDatabaseId(poItemLink: IEntityLink): string {
		return poItemLink.databasesSource[0];
	}

	/** Supprime un lien.
	 * @param poLink lien a supprimer
	 * @param psDatabaseId base de donnée du lien, si le paramètre n'est pas fournit il sera pris dans le $cacheData
	 */
	public deleteItemLink(poLink: IEntityLink, psDatabaseId?: string): Observable<IStoreDataResponse> {
		return this.isvcStore.delete(poLink, psDatabaseId)
			.pipe(
				tap(
					_ => console.debug(`${EntityLinkService.C_LOG_ID}Le lien: ${poLink._id} à été supprimé.`),
					poError => console.error(`${EntityLinkService.C_LOG_ID}Erreur pendant la suppression du lien: ${poLink._id}.`, poError)
				)
			);
	}

	/** Supprime tous les liens d'une entité en fonction d'un identifiant.
	 * @param psEntityId Identifiant de l'entité dont il faut supprimer tous les liens.
	 * @returns `true` si la suppression a réussi, `false` sinon.
	 */
	public deleteEntityLinksById(poData: string | string[]): Observable<boolean> {
		let loLinkedIdsBySourceIds$: Observable<Map<string, string[]>>;

		if (poData instanceof Array)
			loLinkedIdsBySourceIds$ = this.getLinkedEntityIds(poData);
		else {
			loLinkedIdsBySourceIds$ = this.getLinkedEntityIds(poData).pipe(map((paLinkedIds: string[]) => {
				const loMap = new Map<string, string[]>();

				loMap.set(poData, paLinkedIds);

				return loMap;
			}));
		}

		return loLinkedIdsBySourceIds$
			.pipe(
				mergeMap((poLinkedIdsBySourceIds: Map<string, string[]>) => {
					const laKeys: string[] = [];

					poLinkedIdsBySourceIds.forEach((paLinkedIds: string[], psSourceId: string) => {
						paLinkedIds.forEach((psLinkedId: string) =>
							laKeys.push(this.createEntityLinkId(psLinkedId, psSourceId), this.createEntityLinkId(psSourceId, psLinkedId))
						);
					});

					const loDataSource: IDataSource = {
						databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
						viewParams: { keys: laKeys }
					};

					return this.isvcStore.get<IEntityLink>(loDataSource);
				}),
				mergeMap((paResults: Array<IEntityLink>) => this.isvcStore.deleteMultipleDocuments(paResults))
			);
	}

	//#endregion

	//#region Entity

	/** Lève un événement de portée application pour notifier un changement d'entité active.
	 * @param poOldEntity Ancienne entité
	 * @param poNewEntity Nouvelle entité
	 */
	private onCurrentEntityChanged(poOldEntity: IEntity, poNewEntity: IEntity): Observable<void> {
		return of(poNewEntity)
			.pipe(
				mergeMap((poEntity: IEntity) => !!poEntity ? this.getEntityLinks(poEntity._id) : of(null)),
				catchError(poError => {
					console.error(`${EntityLinkService.C_LOG_ID}Erreur lors de la récupération des liens.`, poError);
					// En cas d'erreur on notifie l'événement sans entité liée.
					return this.updateCurrentEntityLinks(poOldEntity, poNewEntity).pipe(mergeMapTo(throwError(poError)));
				}),
				mergeMap((paResults: IEntityLink[]) => this.updateCurrentEntityLinks(poOldEntity, poNewEntity, paResults))
			);
	}

	private updateCurrentEntityLinks(poOldEntity: IEntity, poNewEntity: IEntity, paLinkedEntities: IEntityLink[] = []): Observable<void> {
		console.debug(
			`${EntityLinkService.C_LOG_ID}Current entity changed from ${poOldEntity != null ? poOldEntity._id : "none"} to ${poNewEntity != null ? poNewEntity._id : "none"}.\nNew entity:`,
			poNewEntity,
			"\nLinked entities :",
			paLinkedEntities
		);

		if (poNewEntity)
			return this.updateNavbar();
		else
			return of(this.removeLinkedEntitiesNavButton());
	}

	/** Fixe l'entité courante de l'application.
	 * @param poModel Modèle utilisé
	 * @param pbCheckModelDiffers Indique si on doit vérifier que le modèle est différent de l'actuel ou non, `true` par défaut.
	 */
	private setCurrentEntity(poModel: IStoreDocument, pbCheckModelDiffers: boolean = true): Observable<boolean> {
		if (!poModel || StringHelper.isBlank(poModel._id))
			return throwError("No model was provided to set the current entity. Please use clearCurrentEntity() if you intend to reset it.");

		else if (UserData.current.isGuest) {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity will be not set because user is guest.`);
			return of(false);
		}

		const loCurrentEntity: IEntity = this.currentEntity;

		// On s'assure qu'il ne s'agisse pas de la même entité avant de faire quoi que ce soit.
		if (!pbCheckModelDiffers || !loCurrentEntity || (loCurrentEntity && loCurrentEntity._id !== poModel._id)) {

			const loOldEntity: IEntity = loCurrentEntity;
			this.maCurrentEntityStack.push(poModel);
			return this.onCurrentEntityChanged(loOldEntity, this.currentEntity).pipe(mapTo(true));
		}
		else {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity was already set to the same model.`);
			return of(true);
		}
	}

	/** @implements */
	public trySetCurrentEntity(poModel: IStoreDocument): Observable<boolean> {
		return this.setCurrentEntity(poModel)
			.pipe(
				catchError((poError) => {
					console.warn(`${EntityLinkService.C_LOG_ID}Cannot set current entity for `, poModel, "Erreur :", poError);
					return of(false);
				})
			);
	}

	/** @implements */
	public clearCurrentEntity(psModelId: string): Observable<boolean> {
		const loOldEntity: IEntity = this.currentEntity;

		if (ArrayHelper.removeElementByFinder(this.maCurrentEntityStack, (poItem: IEntity) => poItem?._id === psModelId)) {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity was cleared.`);
			return this.onCurrentEntityChanged(loOldEntity, this.currentEntity).pipe(mapTo(true));
		}
		else {
			console.debug(`${EntityLinkService.C_LOG_ID}Current entity was already cleared.`);
			return of(false);
		}
	}

	/** Vérifie si le modèle est valide.
	 * @param poModel Modèle à vérifier
	 */
	public isValidEntityModel(poModel: IStoreDocument): boolean {
		return poModel && !StringHelper.isBlank(poModel._id);
	}

	//#endregion

	//#region EntityLinks

	/** Crée l'identifiant d'une entité liée à partir de deux entités.
	 * @param psSourceId Identifiant de l'entité source (partie de gauche de l'identifiant).
	 * @param psTargetId Identifiant de l'entité cible qui sera liée à la source (partie de droite de l'identifiant).
	 */
	public createEntityLinkId(psSourceId: string, psTargetId: string): string;
	/** Crée l'identifiant d'une entité liée à partir de deux documents issus de la base de données.
	 * @param poSourceDocument Document source (partie de gauche de l'identifiant).
	 * @param poTargetDocument Document cible qui sera lié à la source (partie de droite de l'identifiant).
	 */
	public createEntityLinkId(poSourceDocument: IStoreDocument, poTargetDocument: IStoreDocument): string;
	public createEntityLinkId(poSource: IStoreDocument | string, poTarget: IStoreDocument | string): string {
		let lsLeftPartId: string;
		let lsRightPartId: string;

		if (typeof poSource === "string")
			lsLeftPartId = poSource;
		else
			lsLeftPartId = poSource._id;

		if (typeof poTarget === "string")
			lsRightPartId = poTarget;
		else
			lsRightPartId = poTarget._id;

		return `${IdHelper.buildId(EPrefix.link, lsLeftPartId)}-${lsRightPartId}`;
	}

	/** Construit un lien avec les éléments source et cible.
	 * @param poCurrentEntity Entité courante.
	 * @param poEntity Entité cible.
	 */
	public buildEntityLinks(poCurrentEntity: IEntity, poEntity: IEntity): IEntityLink[] {
		return [this.buildEntityLink(poCurrentEntity, poEntity), this.buildEntityLink(poEntity, poCurrentEntity)];
	}

	/** Construit un lien avec les éléments source et cible.
	 * @param poCurrentEntity Entité courante.
	 * @param poEntity Entité cible.
	 */
	public buildEntityLink(poCurrentEntity: IEntity, poEntity: IEntity): IEntityLink {
		const laDBSources: Array<string> = [];

		const lsCurrentDbId: string = StoreHelper.getDatabaseIdFromCacheData(poCurrentEntity);
		if (!StringHelper.isBlank(lsCurrentDbId))
			laDBSources.push(lsCurrentDbId);

		const lsDbId: string = StoreHelper.getDatabaseIdFromCacheData(poEntity);
		if (!StringHelper.isBlank(lsDbId))
			laDBSources.push(lsDbId);

		// Construit le document lien.
		return {
			_id: this.createEntityLinkId(poCurrentEntity, poEntity),
			createDate: new Date(),
			databasesSource: laDBSources
		};
	}

	/** Marque des liens à ajouter dans les données de cache du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param paEntities Tableau des entités à ajouter.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToAdd(poModel: IStoreDocument, paEntities: IEntity[], psSubPath?: string): void;
	/** Marque un lien à ajouter dans les données de cache du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param poEntity Entité à ajouter.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToAdd(poModel: IStoreDocument, poEntity: IEntity, psSubPath?: string): void;
	public cacheLinkToAdd(poModel: IStoreDocument, poEntityData: IEntity | IEntity[], psSubPath?: string): void {
		if (!(poEntityData instanceof Array))
			poEntityData = [poEntityData];

		poEntityData.forEach((poEntity: IEntity) => this.cacheLinkToAddRemoveInternal(poModel, poEntity, EEntityLinkCacheData.Add, psSubPath));
	}

	/** Méthode interne d'ajout d'un lien dans le cacheData.
	 * @param poModel Modèle à sauvegarder.
	 * @param poItemLink Lien à mettre à jour.
	 * @param peCacheDataState Action à réaliser sur le lien.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	private cacheLinkToAddRemoveInternal(poModel: IStoreDocument, poEntity: IEntity, peCacheDataState: EEntityLinkCacheData, psSubPath?: string): void {
		if (!poEntity)
			return;

		const loModelCacheData: ICacheData = StoreHelper.getDocumentCacheData(poModel);

		if (loModelCacheData?.links) {
			const lnIndex: number = loModelCacheData.links.findIndex((poEntityLinkCache: IEntityLinkCache) => poEntityLinkCache.entity._id === poEntity._id &&
				psSubPath === poEntityLinkCache.targetSubPath);

			if (lnIndex >= 0) { // Entité déjà présente dans la cacheData, il faut metttre à jour.
				// Si les états sont différents, c'est une annulation (add/remove disponibles => add + remove = annulation). Sinon, pas besoin de le réajouter.
				if (loModelCacheData.links[lnIndex].cacheDataState !== peCacheDataState)
					loModelCacheData.links.splice(lnIndex, 1);
			}
			else  // Entité non présente dans la cacheData, il faut l'ajouter.
				loModelCacheData.links.push({ entity: poEntity, cacheDataState: peCacheDataState, targetSubPath: psSubPath });
		}
		else {
			const loCacheData: ICacheData = { links: [{ entity: poEntity, cacheDataState: peCacheDataState, targetSubPath: psSubPath }] };
			StoreHelper.updateDocumentCacheData(poModel, loCacheData);
		}
	}

	/** Marque des liens à supprimer dans le $cacheData du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param paEntity Entités à supprimer.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToRemove(poModel: IStoreDocument, paEntity: IEntity[], psSubPath?: string): void;
	/** Marque un lien à supprimer dans le $cacheData du modèle.
	 * @param poModel Modèle à sauvegarder.
	 * @param poEntity Entité à supprimer.
	 * @param psSubPath Chemin pour retrouver l'entité ciblée dans le modèle.
	 */
	public cacheLinkToRemove(poModel: IStoreDocument, poEntity: IEntity, psSubPath?: string): void;
	public cacheLinkToRemove(poModel: any, poEntityData: IEntity | IEntity[], psSubPath?: string): void {
		if (!(poEntityData instanceof Array))
			poEntityData = [poEntityData];

		poEntityData.forEach((poEntity: IEntity) => this.cacheLinkToAddRemoveInternal(poModel, poEntity, EEntityLinkCacheData.Remove, psSubPath));
	}

	/** Retourne la liste des liens à créer ou supprimer.
	 * @param poModel Modèle à enregistrer.
	 * @param peEntityLinkCacheData État du cacheData (ajout ou suppression).
	 */
	private getCachedEntities(poModel: IStoreDocument, peEntityLinkCacheData: EEntityLinkCacheData): Array<IEntityLinkCache> {
		const loCacheData: ICacheData = StoreHelper.getDocumentCacheData(poModel);

		if (loCacheData?.links) {
			return loCacheData.links
				.filter((poEntityLink: IEntityLinkCache) => poEntityLink.cacheDataState === peEntityLinkCacheData)
				.map((poEntityLink: IEntityLinkCache) => poEntityLink);
		}
		else
			return [];
	}

	/** Persistence de sauvegarde et de suppression des liens.
	 * @param poModel Modèle a sauvegarder.
	 */
	public saveEntityLinks<T extends IStoreDocument = IStoreDocument>(poData: T): Observable<boolean> {
		return forkJoin([this.saveEntityLinkAdded(poData), this.saveEntityLinkRemoved(poData)])
			.pipe(
				mergeMap((paResults: boolean[]) => this.updateNavbar().pipe(mapTo(paResults))),
				map((paResults: boolean[]) => {
					const lbResult: boolean = paResults.every((pbResult: boolean) => pbResult);
					if (lbResult)
						EntityLinkService.deleteLinksFromDocumentCacheData(poData);
					return lbResult;
				})
			);
	}

	/** Persistence des liens à ajouter.
	 * @param poModelEntity Entité du modèle.
	 */
	private saveEntityLinkAdded(poModelEntity: IEntity): Observable<boolean> {
		const laCachedLinks: IEntityLinkCache[] = this.getCachedEntities(poModelEntity, EEntityLinkCacheData.Add);

		if (!ArrayHelper.hasElements(laCachedLinks)) // Si tableau vide, alors succès.
			return of(true);

		else {
			return this.createEntityLinks(poModelEntity, laCachedLinks)
				.pipe(
					catchError(poError => { console.error(`${EntityLinkService.C_LOG_ID}Error create entityLinks`, poError); return of({ id: poModelEntity._id, ok: false } as IStoreDataResponse); }),
					map((paResults: Array<IStoreDataResponse | ICustomPouchError>) => {
						const laCorrectCreatedLinks: IStoreDataResponse[] = [];
						const laErrors: ICustomPouchError[] = [];

						paResults.forEach((poItem: IStoreDataResponse | ICustomPouchError) => {
							if ((poItem as IStoreDataResponse).ok)
								laCorrectCreatedLinks.push(poItem as IStoreDataResponse);
							else if ((poItem as ICustomPouchError).error)
								laErrors.push(poItem as ICustomPouchError);
						});

						if (laCorrectCreatedLinks.length === paResults.length)
							return true;
						else {
							if (ArrayHelper.hasElements(laErrors))
								console.error(`${EntityLinkService.C_LOG_ID}Erreur création des liens d'entités :`, laErrors);
							return false;
						}
					})
				);
		}
	}

	/** Persistence des liens à supprimer.
	 * @param poModelEntity Entité du modèle.
	 */
	private saveEntityLinkRemoved(poModelEntity: IEntity): Observable<boolean> {
		const laCachedEntities: IEntityLinkCache[] = this.getCachedEntities(poModelEntity, EEntityLinkCacheData.Remove);

		if (ArrayHelper.hasElements(laCachedEntities)) { // Si on a des entités liées à supprimer on les supprime, sinon on a terminé.
			const loDataSource: IDataSource = {
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				viewParams: {
					include_docs: true,
					keys: ArrayHelper.flat(laCachedEntities.map((poEntity: IEntityLinkCache) => {
						return this.buildEntityLinks(poModelEntity, poEntity.entity).map((poEntityLink: IEntityLink) => poEntityLink._id);
					}))
				}
			};

			return this.isvcStore.get(loDataSource)
				.pipe(mergeMap((paResults: IStoreDocument[]) => this.isvcStore.deleteMultipleDocuments(paResults)));
		}

		else
			return of(true);
	}

	/** Renvoie le préfixe d'une entité.
	 * @param psEntityId Identifiant de l'entité dont veut récupérer le préfixe.
	 */
	public getEntityPrefix(psEntityId: string): string {
		return psEntityId.substring(0, psEntityId.indexOf("_") + 1);
	}

	/** Supprime les liens du cacheData du document fourni.
	 * @param poDocument Document dont il faut supprimer les liens de son cacheData.
	 */
	public static deleteLinksFromDocumentCacheData(poDocument: IStoreDocument): void {
		const loDocumentCacheData: ICacheData = StoreHelper.getDocumentCacheData(poDocument);

		if (loDocumentCacheData)
			delete loDocumentCacheData.links;
	}

	/** Met à jour le nombre d'entités liées dans la barre de navigation.*/
	private updateNavbar(poEntity?: IEntity): Observable<void> {
		if (this.currentEntity || poEntity) { // Si une entité courante est définie, il faut aller chercher ses entités liées.
			return this.getLinkedEntities((poEntity ?? this.currentEntity)._id)
				.pipe(
					tap((paResults: IStoreDocument[]) => this.setNavbar(this.currentEntity, paResults)),
					mapTo(undefined)
				);
		}
		else // Sinon on ne met pas à jour la navbar.
			return of(undefined);
	}

	private setNavbar(poEntity: IEntity, paLinks: IStoreDocument[]): void {
		const loLinkInfo = new LinkInfo({
			meta: { schemaVersion: "2.0.0" },
			id: EntityLinkService.C_LINKED_ITEMS_LIST_NAV_BUTTON_ID,
			label: paLinks.length.toString(),
			templateId: ELinkTemplate.icon,
			params: { icon: "link" },
			action: ELinkAction.callback,
			actionParams: { function: () => this.routeToLinkedItemsList(poEntity) }
		});
		const loNavbarEvent: INavbarEvent = {
			id: EntityLinkService.C_LINKED_ITEMS_LIST_NAV_BUTTON_ID,
			action: EBarElementAction.add,
			links: [loLinkInfo]
		};

		this.observableLinkInfo.value = loLinkInfo;

		this.isvcPageManager.raiseNavbarEvent(loNavbarEvent);
	}

	/** Supprime le bouton d'ouverture de la popup des liens. */
	private removeLinkedEntitiesNavButton(): void {
		this.observableLinkInfo.value = undefined;

		this.isvcPageManager.raiseNavbarEvent({
			id: EntityLinkService.C_LINKED_ITEMS_LIST_NAV_BUTTON_ID,
			action: EBarElementAction.clear
		} as INavbarEvent);
	}

	/** Navigue vers la page de la liste des items liés.
	 * @param poEntity Entité courante.
	 */
	private routeToLinkedItemsList(poEntity: IEntity): void {
		const loPageInfo: PageInfo = new PageInfo({
			componentName: "linkedItemsList",
			isModal: true,
			title: "Informations liées",
			params: {
				params: {
					itemId: poEntity._id
				} as ILinkedItemsListParams
			}
		});

		this.isvcPageManager.routePageFromInfo(loPageInfo);
	}

	/** Marque les liens vers les entités à mettre à jour dans un modèle.
	 * @param poModel Modèle dont il faut mettre à jour les liens.
	 * @param paOldEntity Liste des entités préalables.
	 * @param paNewEntity Liste des entités sélectionnés.
	 */
	public updateCachedEntityLinks(poModel: IStoreDocument, paOldEntity: Array<IEntity>, paNewEntity: Array<IEntity>): void {

		// Si une entité n'est pas dans l'ancienne liste mais dans la nouvelle alors il a été ajouté.
		const laAddLinks: Array<IEntity> = paNewEntity.filter((poNew: IEntity) => paOldEntity.findIndex((poOld: IEntity) => poOld._id === poNew._id) === -1);
		this.cacheLinkToAdd(poModel, laAddLinks);

		// Si une entité n'est pas dans la nouvelle liste mais dans la vieille alors il a été supprimé.
		const laRemoveLinks: Array<IEntity> = paOldEntity.filter((poOld: IEntity) => paNewEntity.findIndex((poNew: IEntity) => poOld._id === poNew._id) === -1);
		this.cacheLinkToRemove(poModel, laRemoveLinks);
	}

	/** Détermine si l'entité indiquée peut être supprimée.
	 * @param psEntityId Identifiant de l'entité qu'on veut supprimer.
	 */
	public ensureIsDeletableEntity(poEntity: IStoreDocument, paLinkedDocs: IStoreDocument[] = []): Observable<boolean> {
		return this.getEntityLinks(poEntity._id)
			.pipe(
				map((paLinks: IEntityLink[]) => {
					if (ArrayHelper.hasElements(paLinks)) {
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({
							message: `Cet élément est lié à ${paLinks.length} élément${paLinks.length > 1 ? "s" : ""}.`, header: "Suppression interdite"
						}));
						return false;
					}
					else
						return true;
				}) // TODO remplacer par une gestion avec type de relation
			);
	}

	/** Récupère les identifiants des entités liées à un identifiant.
	 * @param psItemId Identifiant de la données source des liens.
	 * @param paLinkedEntityPrefixes Préfixes des données liées.
	 * @param pbLive Indique si la requête doit être live ou non.
	 */
	public getLinkedEntityIds(psItemId: string, paLinkedEntityPrefixes?: Array<EPrefix>, pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<string[]>;
	/** Récupère les identifiants des entités liées à un tableau d'identifiants.
	 * @param paItemIds Tableau des identifiants des données source des liens.
	 * @param paLinkedEntityPrefixes Préfixes des données liées.
	 * @param pbLive Indique si la requête doit être live ou non.
	 */
	public getLinkedEntityIds(paItemIds: string[], paLinkedEntityPrefixes?: Array<EPrefix>, pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Map<string, string[]>>;
	public getLinkedEntityIds(poData: string | string[], paLinkedEntityPrefixes?: Array<EPrefix>, pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<string[] | Map<string, string[]>> {
		if (poData instanceof Array)
			return this.getLinkedEntityIdsForMultipleItems(poData, paLinkedEntityPrefixes, pbLive, poActivePageManager);
		else
			return this.getEntityLinks(poData, paLinkedEntityPrefixes, pbLive, false, poActivePageManager)
				.pipe(map((paEntityLinks: IEntityLink[]) => paEntityLinks.map((poEntityLink: IEntityLink) => this.getLinkTargetId(poEntityLink))));
	}

	private getLinkedEntityIdsForMultipleItems(
		paItemIds: string[],
		paLinkedEntityPrefixes: EPrefix[] = [],
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<string, string[]>> {
		return this.getEntityLinksForMultipleItems(paItemIds, paLinkedEntityPrefixes, pbLive, false, false, poActivePageManager)
			.pipe(map((paEntityLinks: IEntityLink[]) => this.groupTargetIdsBySourceId(paEntityLinks)));
	}

	private getEntityLinksForMultipleItems(
		paItemIds: string[],
		paLinkedEntityPrefixes: EPrefix[] = [],
		pbLive?: boolean,
		pbIncludeDocs?: boolean,
		pbIncludeDeeplinks?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<IEntityLink[]> {

		return defer(() => {
			const laGetEntityLinks$: Observable<IEntityLink[]>[] = [];

			ArrayHelper.groupBy(paItemIds.filter((psId: string) => !StringHelper.isBlank(psId)), (psId: string) => IdHelper.getPrefixFromId(psId))
				.forEach((paIds: string[], psPrefix: string) => laGetEntityLinks$.push(
					this.buildGetEntityLinksObservable(paIds, psPrefix, pbIncludeDeeplinks, paLinkedEntityPrefixes, pbLive, pbIncludeDocs, poActivePageManager)
				));

			return combineLatest(laGetEntityLinks$);
		})
			.pipe(
				map((paEntityLinks: IEntityLink[][]) => paEntityLinks.flat()),
				defaultIfEmpty([])
			);
	}

	private buildGetEntityLinksObservable(
		paIds: string[],
		psPrefix: string,
		pbIncludeDeeplinks: boolean,
		paLinkedEntityPrefixes: EPrefix[],
		pbLive: boolean,
		pbIncludeDocs: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<IEntityLink[]> {

		return defer(() => {
			const lsStartKey = `${EPrefix.link}${paIds.length === 1 ? ArrayHelper.getFirstElement(paIds) : psPrefix}`;

			const lfFilter: (poEntityLink: IEntityLink) => boolean = (poEntityLink: IEntityLink) => (pbIncludeDeeplinks || !poEntityLink._id.includes(EntityLinkService.C_DEEPLINK_SUB_PATH_SEPARATOR)) &&
				paIds.some((poItemId: string) => poEntityLink._id.includes(poItemId));

			const loDataSource: IDataSourceRemoteChanges<IEntityLink> = {
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				viewParams: {
					startkey: lsStartKey,
					endkey: lsStartKey + Store.C_ANYTHING_CODE_ASCII
				},
				live: pbLive,
				remoteChanges: !!poActivePageManager,
				activePageManager: poActivePageManager,
				filter: ArrayHelper.hasElements(paLinkedEntityPrefixes) ?
					(poEntityLink: IEntityLink) => lfFilter(poEntityLink) && this.filterEntityLinksFunction(paLinkedEntityPrefixes)(poEntityLink) : lfFilter
			};

			return this.isvcStore.get(loDataSource)
				.pipe(
					switchMap((paEntityLinks: IStoreDocument[]) => (!pbIncludeDocs || !ArrayHelper.hasElements(paEntityLinks)) ?
						of(paEntityLinks as IEntityLink[]) : this.getEntityLinksDetails(paEntityLinks, pbLive, poActivePageManager)
					),
					tap((paResults: IEntityLink[]) => {
						if (pbIncludeDocs)
							this.replaceRelativeDatabaseSource(paResults);
					})
				);
		});
	}

	private getEntityLinksDetails(
		paEntityLinks: IStoreDocument[],
		pbLive: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<IEntityLink[]> {
		const loDocsDataSource: IDataSourceRemoteChanges = {
			databasesIds: ArrayHelper.unique(paEntityLinks.map((poEntityLink: IStoreDocument) => StoreHelper.getDatabaseIdFromCacheData(poEntityLink))),
			viewParams: {
				keys: paEntityLinks.map((poEntityLink: IStoreDocument) => poEntityLink._id),
				include_docs: true
			},
			live: pbLive,
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager,
		};

		return this.isvcStore.get<IEntityLink>(loDocsDataSource);
	}

	/** Récupère les entités liées à un identifiant ou à un document issu de la base de données.
	 * @param poItem Identifiant ou document issu de la base de données dont il faut récupérer les liens associés.
	 * @param paLinkedEntityPrefixes Préfixe ou tableau de préfixes des données liées.
	 * @param pbLive Indique si la requête doit être live ou non.
	 * @param pbConflicts Indique si l'on doit récupérer les conflits ou non.
	 */
	public getLinkedEntities<T extends IStoreDocument = IStoreDocument>(
		poItem: string | IStoreDocument,
		paLinkedEntityPrefixes?: EPrefix | EPrefix[],
		pbLive?: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<T[]>;
	/** Récupère les entités liées à un tableau de documents issus du de bases de données ou d'idnetifiants.
	 * @param paItems Tableau des documents (ou identifiants) dont il faut récupérer les liens.
	 * @param paLinkedEntityPrefixes Préfixe ou tableau des préfixes des données liées à récupérer.
	 * @param pbLive Indique si la requête doit être live ou non.
	 * @param pbConflicts Indique si l'on doit récupérer les conflits ou non.
	 */
	public getLinkedEntities<T extends IStoreDocument = IStoreDocument>(
		paItems: string[] | IStoreDocument[],
		paLinkedEntityPrefixes?: EPrefix | EPrefix[],
		pbLive?: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<string, T[]>>;
	public getLinkedEntities<T extends IStoreDocument = IStoreDocument>(
		poData: string | string[] | IStoreDocument | IStoreDocument[],
		paLinkedEntityPrefixes?: EPrefix | EPrefix[],
		pbLive?: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<T[] | Map<string, T[]>> {
		if (!poData)
			return of(undefined);

		const laPrefixes: EPrefix[] = paLinkedEntityPrefixes instanceof Array ? paLinkedEntityPrefixes :
			paLinkedEntityPrefixes ? [paLinkedEntityPrefixes] : undefined;

		if (poData instanceof Array) {
			let laDataIds: string[];

			if (typeof ArrayHelper.getFirstElement(poData as string[]) === "string")
				laDataIds = poData as string[];
			else
				laDataIds = (poData as IStoreDocument[]).map((poItem: IStoreDocument) => poItem._id);

			return this.getLinkedEntitiesForMultipleItems<IStoreDocument>(laDataIds, laPrefixes, pbLive, pbConflicts, poActivePageManager) as any as Observable<Map<string, T[]>>;
		}
		else {
			const lsDataId: string = typeof poData === "string" ? poData : poData._id;

			return this.getEntityLinks(lsDataId, laPrefixes, pbLive, false, poActivePageManager)
				.pipe(switchMap((paEntityLinks: IEntityLink[]) => this.innerGetLinkedEntities_getEntities<IStoreDocument>(paEntityLinks, pbLive, pbConflicts))) as any as Observable<T[]>;
		}
	}

	private getLinkedEntitiesForMultipleItems<T extends IStoreDocument = IStoreDocument>(
		paItemIds: string[],
		paLinkedEntityPrefixes?: EPrefix[],
		pbLive?: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<string, T[]>> {

		return this.getEntityLinksForMultipleItems(paItemIds, paLinkedEntityPrefixes, pbLive, true, false, poActivePageManager)
			.pipe(
				switchMap((paEntityLinks: IEntityLink[]) => {
					return this.innerGetLinkedEntities_getEntities<T>(paEntityLinks, pbLive, pbConflicts, poActivePageManager)
						.pipe(map((paEntities: T[]) => this.groupEntitiesBySourceId<T>(paEntities, this.groupSourceIdsByTargetId(paEntityLinks))));
				})
			);
	}

	private groupEntitiesBySourceId<T extends IStoreDocument = IStoreDocument>(paEntities: T[], poSourceIdsByTargetIds: Map<string, string[]>): Map<string, T[]> {
		const loEntitiesByItemIdMap = new Map<string, T[]>();

		paEntities.forEach((poEntity: T) => {
			poSourceIdsByTargetIds.get(poEntity._id)
				.forEach((psSourceId: string) => {
					if (loEntitiesByItemIdMap.has(psSourceId))
						loEntitiesByItemIdMap.get(psSourceId).push(poEntity);
					else
						loEntitiesByItemIdMap.set(psSourceId, [poEntity]);
				});
		});

		return loEntitiesByItemIdMap;
	}

	/** Regroupe et retourne la map des identifiants cibles des liens d'entités en fonction de leur identifiant source.
	 * @param paEntityLinks Tableau des entités liées dont il faut regrouper les identifiants cibles par l'identifiant source.
	 */
	private groupTargetIdsBySourceId(paEntityLinks: IEntityLink[]): Map<string, string[]> {
		const loTargetIdsBySourceIds = new Map<string, string[]>();

		paEntityLinks.forEach((poEntityLink: IEntityLink) => {
			const lsSourceId: string = this.getLinkSourceId(poEntityLink);
			const laTargetIds: string[] = loTargetIdsBySourceIds.get(lsSourceId) ?? [];

			laTargetIds.push(this.getLinkTargetId(poEntityLink));

			loTargetIdsBySourceIds.set(lsSourceId, laTargetIds);
		});

		return loTargetIdsBySourceIds;
	}

	/** Regroupe et retourne la map des identifiants sources des liens d'entités en fonction de leur identifiant cible.
	 * @param paEntityLinks Tableau des entités liées dont il faut regrouper les identifiants sources par l'identifiant cible.
	 */
	private groupSourceIdsByTargetId(paEntityLinks: IEntityLink[]): Map<string, string[]> {
		const loSourceIdsByTargetIds = new Map<string, string[]>();

		paEntityLinks.forEach((poEntityLink: IEntityLink) => {
			const lsTargetId: string = this.getLinkTargetId(poEntityLink);
			const laSourceIds: string[] = loSourceIdsByTargetIds.get(lsTargetId) ?? [];

			laSourceIds.push(this.getLinkSourceId(poEntityLink));
			loSourceIdsByTargetIds.set(lsTargetId, laSourceIds);
		});

		return loSourceIdsByTargetIds;
	}

	private innerGetLinkedEntities_getEntities<T extends IStoreDocument = IStoreDocument>(
		paEntityLinks: IEntityLink[],
		pbLive: boolean,
		pbConflicts?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<T[]> {

		if (!ArrayHelper.hasElements(paEntityLinks))
			return of([]);

		const loEntitiesByDatabaseId = new Map<string, IEntityLink[]>();
		const laEntities$: Observable<T[]>[] = [];

		paEntityLinks.forEach((poEntityLink: IEntityLink) => {
			const lsEntityLinkDatabaseId: string = this.getLinkTargetDatabaseId(poEntityLink);
			const laEntities: IEntityLink[] = loEntitiesByDatabaseId.get(lsEntityLinkDatabaseId);

			laEntities ? laEntities.push(poEntityLink) : loEntitiesByDatabaseId.set(lsEntityLinkDatabaseId, [poEntityLink]);
		});

		loEntitiesByDatabaseId.forEach((paDatabaseEntityLinks: IEntityLink[], psDatabaseId: string) => {
			const loDataSource: IDataSourceRemoteChanges = {
				databaseId: psDatabaseId,
				viewParams: {
					include_docs: true,
					keys: paDatabaseEntityLinks.map((poEntityLink: IEntityLink) => this.getLinkTargetId(poEntityLink)),
					conflicts: pbConflicts
				},
				live: pbLive,
				remoteChanges: !!poActivePageManager,
				activePageManager: poActivePageManager
			};

			laEntities$.push(this.isvcStore.get<T>(loDataSource));
		});

		return combineLatest(laEntities$)
			.pipe(
				map((paResults: T[][]) => paResults.flat()),
				defaultIfEmpty([])
			);
	}

	//#endregion

	//#endregion

}