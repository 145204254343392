import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { of } from 'rxjs';
import { MapHelper } from '../../helpers/mapHelper';
import { Store } from '../../services/store.service';

@Component({
	selector: 'osapp-import',
	templateUrl: './import.component.html',
	styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
	public event;
	public response;
	public Entities = [];
	public Sources = [];
	public File: string;
	public maDatabaseList = [];

	//#region Form
	public form = new UntypedFormGroup({});
	public model: any = {};
	public options: FormlyFormOptions = {};
	public fields: FormlyFieldConfig[] = [
		{
			fieldGroupClassName: 'select-field-group',
			fieldGroup: [
				{
					className: 'file-button',
					key: 'file',
					type: 'file',
					templateOptions: {
						label: 'Fichier',
						required: true,
						change: ($event) => this.onFileChange($event),
					}
				},
				{
					key: 'entities',
					type: 'select',
					templateOptions: {
						label: 'Entité',
						required: true,
						data: {
							readOnly: false,
						},
						options: [],
					},
				},
				{
					key: 'sources',
					type: 'select',
					templateOptions: {
						label: 'Source',
						required: true,
						data: {
							readOnly: false,
						},
						options: [],
					}
				}
			]
		},
		{
			fieldGroupClassName: 'autocomplete-field',
			fieldGroup: [
				{
					key: 'autocomplete',
					type: 'autocomplete',
					templateOptions: {
						required: true,
						label: 'Autocomplete',
						placeholder: 'Workspace ou Base de données',
						// tslint:disable-next-line: deprecation
						filter: (term) => of(term ? this.filterStates(term) : this.maDatabaseList.slice()),
					}
				}
			]
		},
		{
			fieldGroupClassName: 'action-button-group',
			fieldGroup: [
				{
					className: 'action-button',
					type: 'button',
					templateOptions: {
						text: 'Importer',
						onClick: () => this.importInApi(this.form.value.entities, this.form.value.sources, this.form.value.autocomplete, false)
					}
				},
				{
					className: 'action-button',
					type: 'button',
					templateOptions: {
						text: 'Preview',
						onClick: () => this.importInApi(this.form.value.entities, this.form.value.sources, this.form.value.autocomplete, true)
					}
				}
			]
		}

	];
	//#endregion

	@ViewChild('fileImportInput', {
		read: String
	}) public fileImportInput: any;

	constructor(
		private ioHttpClient: HttpClient,
		private ioStore: Store
	) { }

	public ngOnInit() {
		this.ioHttpClient.get('http://127.0.0.1:5050/apps/merch/entities', { responseType: 'json' }).subscribe((data) => {
			for (const lsKey in data) {
				if (lsKey) {
					this.Entities.push({
						value: lsKey, label: lsKey
					});
				}
			}
			this.fields[0].fieldGroup[1].templateOptions.options = this.Entities;
		});

		this.ioHttpClient.get('http://127.0.0.1:5050/apps/merch/entities/entity/import', { responseType: 'json' }).subscribe((data) => {
			for (const lsKey in data) {
				if (data[lsKey]) {
					this.Sources.push({
						value: data[lsKey], label: data[lsKey]
					});
				}
			}
			this.fields[0].fieldGroup[2].templateOptions.options = this.Sources;
		});

		this.maDatabaseList.push(...MapHelper.valuesToArray(this.ioStore.getDatabases()));
		console.log(this.maDatabaseList);
	}

	public onFileChange($event: any) {
		this.event = $event;
		const reader = new FileReader();
		reader.readAsText(this.model.file[0], 'utf-8');
		reader.onload = () => {
			this.File = reader.result as string;
		};
	}

	public importInApi(psEntities: string, psSources: string, psTarget: string, pbPreview: boolean): void {
		const files = this.model.file;

		if (this.isCSVFile(files[0]) || this.isJSONFile(files[0])) {
			let header: any;

			if (this.isCSVFile(files[0])) {
				header = {
					'Content-Type': 'text/plain'
				};
			} else {
				header = {
					'Content-Type': 'application/json'
				};
			}

			const type = psSources;
			const reader = new FileReader();
			// tslint:disable-next-line: max-line-length
			const url = `http://127.0.0.1:5050/apps/merch/entities/${psEntities}/import?sourcetype=${type}&target=${psTarget}`;
			const preview = '&preview=true';
			let request: string;
			if (pbPreview === true) {
				request = url + preview;
			} else {
				request = url;
			}

			reader.readAsText(files[0]);

			reader.onload = () => {
				// SEND FILE TO API

				this.ioHttpClient.post(request, reader.result, {
					headers: header,
				}).subscribe((data) => {
					if (pbPreview === true) {
						this.response = data;
					} else {
						alert(data);
					}
				},
					(error) => {
						if (!error.error) {
							alert(error);
						} else {
							const lsMessage = `Erreur : ${error.status} => ${error.error.message}`;
							alert(lsMessage);
						}
					});
			};

			reader.onerror = function () {
				alert(`Unable to read ${files[0]}`);
			};

		} else {
			alert('Please import valid file.');
			this.fileReset();
		}
	}

	// CHECK IF FILE IS A VALID CSV FILE
	public isCSVFile(file: any) {
		return file.name.endsWith('.csv');
	}
	// CHECK IF FILE IS A VALID JSON FILE
	public isJSONFile(file: any) {
		return file.name.endsWith('.json');
	}

	public fileReset() {
		this.fileImportInput.nativeElement.value = '';
	}

	public filterStates(name: string) {
		return this.maDatabaseList.filter(state =>
			state.toLowerCase().indexOf(name.toLowerCase()) === 0);
	}
}
