import { NgModule } from '@angular/core';
import { PackModule } from '../../pack/pack.module';
import { DeliveryBarcodeItemService } from './services/delivery-barcode-item.service';
import { DeliveryRackService } from './services/delivery-rack.service';
import { DeliveryService } from './services/delivery.service';

@NgModule({
	declarations: [],
	imports: [PackModule],
	providers: [
		DeliveryService,
		DeliveryRackService,
		DeliveryBarcodeItemService
	]
})
export class DeliveryModule { }