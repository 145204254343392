import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'calao-filter-bar-item-reset',
	templateUrl: './filter-bar-item-reset.component.html',
	styleUrls: ['./filter-bar-item-reset.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarItemResetComponent {

	//#region METHODS

	constructor() { }

	//#endregion METHODS

}
