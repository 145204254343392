import { IEntityLink } from "../model/entities/IEntityLink";
import { IEntityLinkPart } from "../model/entities/IEntityLinkPart";
import { EPrefix } from "../model/EPrefix";
import { ArrayHelper } from "./arrayHelper";
import { IdHelper } from "./idHelper";

export abstract class EntityHelper {

	//#region FIELDS

	/** Caractère utilisé pour séparer deux id. */
	private static readonly C_SEPARATOR_CARACTER: string = "-";
	private static readonly C_LOG_ID: string = "ENTITY.H::";

	//#endregion

	//#region METHODS

	/** Récupère l'identifiant d'une entité et l'identifiant de sa base de données de provenance à l'aide d'un lien d'entité et d'un préfixe.
	 * ### Attention, cette méthode doit être utilisée prioritairement pour récupérer l'identifiant et/ou l'identifiant de base de données d'une entité à condition que le lien ne soit pas de type `lnk_a-a`.
	 * @param poEntityLink Entité liée dont on veut récupérer les informations.
	 * @param pePrefix Préfixe permettant de déterminer ce qui doit être retourner, optionnel si on veut récupérer un identifiant sans préfixe.
	 */
	public static getEntityLinkPartFromPrefix(poEntityLink: IEntityLink, pePrefix?: EPrefix): IEntityLinkPart {
		const laEntityLinkPartsId: string[] = poEntityLink._id.substring(EPrefix.link.length).split('-'); // Séparation des identifiants, sans le préfixe de lien.
		const lsLeftPartId: string = ArrayHelper.getFirstElement(laEntityLinkPartsId);

		// Si un préfixe est présent et que la partie de gauche correspond, ou s'il n'y a pas de préfixe et que la partie de gauche n'a pas de préfixe,
		// on renvoie la partie de gauche car mode 'normal'.
		if (pePrefix && (lsLeftPartId.startsWith(pePrefix) || (!pePrefix && !IdHelper.hasPrefixId(lsLeftPartId))))
			return this.getLeftEntityLinkPart(lsLeftPartId, poEntityLink);
		else
			return this.getRightEntityLinkPart(laEntityLinkPartsId, poEntityLink);
	}

	/** Récupère l'identifiant d'une entité et l'identifiant de sa base de données de provenance à l'aide d'un lien d'entité et du préfixe qui ne nous intéresse pas.
	 * ### Attention, cette méthode doit être utilisée prioritairement pour récupérer l'identifiant et/ou l'identifiant de base de données d'une entité à condition que le lien ne soit pas de type `lnk_a-a`.
	 * @param poEntityLink Entité liée dont on veut récupérer les informations.
	 * @param peSourcePrefix Préfixe de la source permettant de déterminer ce qui ne doit pas être retourner, optionnel si on veut récupérer un identifiant sans préfixe.
	 */
	public static getEntityLinkPartFromSourcePrefix(poEntityLink: IEntityLink, peSourcePrefix?: EPrefix): IEntityLinkPart {
		const laEntityLinkPartsId: string[] = EntityHelper.getIdsFromLinkId(poEntityLink._id);

		const lsLeftPartId: string = ArrayHelper.getFirstElement(laEntityLinkPartsId);

		// Si un préfixe est présent et que la partie de gauche correspond, ou s'il n'y a pas de préfixe et que la partie de gauche n'a pas de préfixe,
		// on renvoie la partie de droite car mode 'reverse'.
		if (peSourcePrefix && (lsLeftPartId.startsWith(peSourcePrefix) || (!peSourcePrefix && !IdHelper.hasPrefixId(lsLeftPartId))))
			return this.getRightEntityLinkPart(laEntityLinkPartsId, poEntityLink);
		else
			return this.getLeftEntityLinkPart(lsLeftPartId, poEntityLink);
	}

	/** Retourne les identifiants qui composent le lien d'entité dans l'ordre de lecture.\
	 * Par exemple: `"lnk_truc_00001-truc_00002"` retournera `["truc_00001", "truc_00002"]`.
	 * @param poData Lien d'entité ou identifiant du lien d'entité dont il faut retourner les identifiants qui le composent.
	 * @throws {Error} Si le pattern de l'identifiant n'est pas reconnu/pris en charge.
	 */
	public static getIdsFromLinkId(poData: string | IEntityLink): string[] {
		const lsEntityLinkId: string = typeof poData === "string" ? poData : poData._id;
		const lsId: string = lsEntityLinkId.replace(EPrefix.link, "");
		const laSeparatedIds: string[] = lsId.split(this.C_SEPARATOR_CARACTER);

		if (laSeparatedIds.length === 2) // S'il n'y a qu'un '-', c'est qu'il sépare les deux ids.
			return laSeparatedIds;
		else {
			const loTwoIdsRegex = new RegExp(`${this.C_SEPARATOR_CARACTER}[0-9a-zA-Z]*(_)`, "g");
			const laRegexResults: Array<string> = lsId.match(loTwoIdsRegex) || [];

			if (laRegexResults.length === 1) {
				// Un des id est composés du caractère séparateur.
				// Exemple : mon-premier-id-prefix_mon-second-id ou prefix_mon-premier-id-prefix_mon-second-id.
				const lnStartSecondPrefix: number = lsId.indexOf(laRegexResults[0]);

				return [lsId.substring(0, lnStartSecondPrefix), lsId.substring(lnStartSecondPrefix + 1)];
			}
			else {
				console.error(`${this.C_LOG_ID}Le pattern d'id '${lsId}' n'est pas reconnu.`);
				throw new Error("Pattern d'id non reconnu.");
			}
		}
	}

	private static getLeftEntityLinkPart(psLeftPartId: string, poLink: IEntityLink): IEntityLinkPart {
		return {
			entityId: psLeftPartId,
			databaseId: ArrayHelper.getFirstElement(poLink.databasesSource),
		} as IEntityLinkPart;
	}

	private static getRightEntityLinkPart(paEntityLinkPartsId: string[], poLink: IEntityLink): IEntityLinkPart {
		return {
			entityId: ArrayHelper.getLastElement(paEntityLinkPartsId),
			databaseId: ArrayHelper.getLastElement(poLink.databasesSource),
		} as IEntityLinkPart;
	}

	public static reverseEntityLinkId(psEntityLinkId: string): string {
		const laParts: string[] = this.getIdsFromLinkId(psEntityLinkId);
		return IdHelper.buildId(EPrefix.link, `${laParts[1]}-${laParts[0]}`);
	}

	/** Récupère l'identifiant de gauche qui compose un lien d'entité.
	 * @param psEntityLinkId Identifiant de l'entité liée dont on veut récupérer l'identifiant de gauche.
	 * @throws {Error} Si le pattern de l'identifiant n'est pas reconnu/pris en charge.
	 */
	public static getEntityLinkLeftId(psEntityLinkId: string): string;
	/** Récupère l'identifiant de gauche qui compose un lien d'entité.
	 * @param poEntityLink Entité liée dont on veut récupérer l'identifiant de gauche.
	 * @throws {Error} Si le pattern de l'identifiant n'est pas reconnu/pris en charge.
	 */
	public static getEntityLinkLeftId(poEntityLink: IEntityLink): string;
	public static getEntityLinkLeftId(poData: string | IEntityLink): string {
		return ArrayHelper.getFirstElement(this.getIdsFromLinkId(poData));
	}

	/** Récupère l'identifiant de droite qui compose un lien d'entité.
	 * @param psEntityLinkId Identifiant de l'entité liée dont on veut récupérer l'identifiant de droite.
	 * @throws {Error} Si le pattern de l'identifiant n'est pas reconnu/pris en charge.
	 */
	public static getEntityLinkRightId(psEntityLinkId: string): string;
	/** Récupère l'identifiant de droite qui compose un lien d'entité.
	 * @param poEntityLink Entité liée dont on veut récupérer l'identifiant de droite.
	 * @throws {Error} Si le pattern de l'identifiant n'est pas reconnu/pris en charge.
	 */
	public static getEntityLinkRightId(poEntityLink: IEntityLink): string;
	public static getEntityLinkRightId(poData: string | IEntityLink): string {
		return ArrayHelper.getLastElement(this.getIdsFromLinkId(poData));
	}

	//#endregion

}