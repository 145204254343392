import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../../../pipes/pipes.module';
import { HeaderModule } from '../../../header/header.module';
import { ReturnRackService } from './services/return-rack.service';
import { ReturnsService } from './services/returns.service';

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		HeaderModule,
		ScrollingModule,
		MatExpansionModule,
		MatChipsModule,
		PipeModule
	],
	providers: [ReturnsService, ReturnRackService]
})
export class ReturnsModule { }