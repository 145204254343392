import { Observable } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';

/** Permet de gérer un écart minimum entre 2 événements.
 * @param pnGapMs Ecart en millisecondes.
*/
export function minimumGap<T>(pnGapMs: number, pbIgnorePending?: boolean): (poSource$: Observable<T>) => Observable<T> {
	return (poSource$: Observable<T>) => {
		return poSource$.pipe(
			(pbIgnorePending ? switchMap : concatMap)((poValue: T, pnIndex: number) => {
				return new Promise((pfResolve: (poResult: T) => void) => {
					const lnBeginDate: number = Date.now();

					if (pnIndex === 0) // Si c'est la première valeur, on la retourne directement.
						pfResolve(poValue);
					else // Sinon il faut attendre au moins le gap demandé.
						setTimeout(() => {
							const lnEffectiveGap: number = Date.now() - lnBeginDate;

							if (lnEffectiveGap < pnGapMs) // Si le gap n'est pas respecté, on réattend la différence.
								setTimeout(() => pfResolve(poValue), pnGapMs - lnEffectiveGap);
							else
								pfResolve(poValue);
						}, pnGapMs);
				});
			})
		);
	};
}