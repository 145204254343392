import { ResolveModel } from "../../../../utils/models/decorators/resolve-model.decorator";
import { ControlFilter } from "./control-filter";
import { ControlCriterion } from "./criteria/control-criterion";
import { ETypeCriterion } from "./criteria/etype-criterion";
import { IControlStep } from "./icontrol-step";

export class ControlStep implements IControlStep {

	//#region PROPERTIES

	/** @implements */
	@ResolveModel(ControlFilter)
	public excludeFilters?: Array<ControlFilter>;

	/** Permet de récupérer tous les critères du type voulu.
	 * @param peTypeCriterion Type du critère.
	 */
	public getCriteriaByType<T extends ControlCriterion>(peTypeCriterion: ETypeCriterion): T[] {
		const laCriteria: ControlCriterion[] = [];

		this.excludeFilters?.forEach((poControlFilter: ControlFilter) => {
			poControlFilter.criteria?.forEach((poCriterion: ControlCriterion) => {
				if (poCriterion.type === peTypeCriterion)
					laCriteria.push(poCriterion);
			});
		});

		return laCriteria as T[];
	}

	//#endregion

}