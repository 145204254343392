{{to.label}}
<!-- MODE VISU -->
<div *ngIf="params.readOnly" [class]="params.rounded ? 'img-rounded' : 'img'">
	<div [class]="params.cssClass ? 'lil-logo' : 'picture-edit'">
		<ion-img #picture *ngIf="imageSource"
			[src]="imageSource | imageSanitizer: (observableFieldValue.value$ | async)?.mimeType"
			[alt]="(observableFieldValue.value$ | async)?.alt" class="avatar img-thumbnail" (ionError)="onError()"></ion-img>
		<ion-icon *ngIf="!imageSource" [name]="params.icon" class="avatar auto marg-ava txt100px txt-center" role="img">
		</ion-icon>
	</div>
</div>

<!-- CHARGEMENT -->
<ion-spinner *ngIf="isLoading" name="crescent"></ion-spinner>

<!-- MODE EDIT -->
<div *ngIf="!params.readOnly" class="lil-size picture-edit visu-icon" [hidden]="isLoading">
	<ion-img *ngIf="imageSource" [src]="imageSource | imageSanitizer: (observableFieldValue.value$ | async)?.mimeType"
		[alt]="(observableFieldValue.value$ | async)?.alt" class="avatar img-thumbnail"></ion-img>
	<ion-icon *ngIf="!imageSource" [name]="params.icon" class="marg-ava avatar auto txt100px txt-center marge-icon"
		role="img">
	</ion-icon>
</div>

<div *ngIf="!params.readOnly" class="txt-center elements-center">
	<span>
		<file-picker (fileSelected)="onSelectedPictureChanged($event)" [options]="params.cameraOptions"
			[iconName]="'images'" [accept]="'image/*'" [maxSizeKb]="params.maxSizeKb" (isLoading)="onLoadingChanged($event)">
		</file-picker>
	</span>
	<ion-button [hidden]="isLoading" (click)="takePhoto()">
		<ion-icon slot="icon-only" name="camera"></ion-icon>
	</ion-button>
</div>