import { Injectable } from "@angular/core";
import { Directory } from "@capacitor/filesystem";
import { LoadingService } from "../../../../services/loading.service";
import { PlatformService } from "../../../../services/platform.service";
import { FilesystemService } from "../../../filesystem/services/filesystem.service";
import { LoggerService } from "../../../logger/services/logger.service";
import { SqlFilesHelper } from "../../../sqlite/helpers/sql-files.helper";
import { RemoveInvalidSqlDatabasesPatchBase } from "../../models/patches/remove-invalid-sql-databases-patch-base";

@Injectable()
export class UpdateCatalogPatch extends RemoveInvalidSqlDatabasesPatchBase {

	//#region METHODS

	constructor(
		psvcLoading: LoadingService,
		psvcFilesystem: FilesystemService,
		psvcLogger: LoggerService,
		psvcPlatform: PlatformService
	) {
		super(psvcLoading, psvcFilesystem, psvcLogger, psvcPlatform, "Remplacement du catalogue d'articles actuel par le nouveau");
	}

	protected override getSqlDatabasesPath(): string {
		return SqlFilesHelper.mobileAppDatabasesPath;
	}

	protected override getSqlDatabasesDirectoryPath(): Directory {
		return SqlFilesHelper.mobileAppDatabasesDirectory;
	}

	protected override getDatabasesPrefix(): string {
		return "catalog-";
	}

	protected override getDatabaseNameRegex(): RegExp {
		return /(catalog.*?)-(\d+)/; // catalog-{{version}} -> "catalog-42".
	}

	//#endregion

}