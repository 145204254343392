import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { DateHelper } from "../../../../../helpers/dateHelper";
import { EDateTimePickerMode } from '../../../../../model/date/EDateTimePickerMode';
import { ETimetablePattern } from '../../../../../model/date/ETimetablePattern';
import { IDateTimePickerParams } from '../../../../../model/date/IDateTimePickerParams';
import { PlatformService } from "../../../../../services/platform.service";
import { ModalComponentBase } from "../../../../modal";
import { ObservableProperty } from '../../../../observable/models/observable-property';
import { IEventOptimizedInputModalResult } from '../models/ievent-optimized-input-modal-result';

enum EOptimizationPeriod {
	halfWeek = '3days',
	today = "today",
	week = "week"
}

@Component({
	selector: "event-optimization-input-modal",
	templateUrl: "./event-optmized-input-modal.component.html",
	styleUrls: ["./event-optmized-input-modal.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventOptmizedModalComponent extends ModalComponentBase<undefined | IEventOptimizedInputModalResult> {

	//#region FIELDS
	private static readonly EVENT_DURATION_MAX_HOUR = 9;

	//endregion

	//#region PROPERTIES

	public frontDuration: Date = DateHelper.addMinutes(DateHelper.resetDay(new Date()), 30);

	private mePeriode: EOptimizationPeriod = EOptimizationPeriod.halfWeek;
	public get periode(): EOptimizationPeriod { return this.mePeriode; }
	public set periode(peNewPeriode: EOptimizationPeriod) {
		this.mePeriode = peNewPeriode;
	}

	public readonly observableStartTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		autoFill: false,
		maxHour: `${EventOptmizedModalComponent.EVENT_DURATION_MAX_HOUR}:00`,
		pickerMode: EDateTimePickerMode.time,
		displayFormat: ETimetablePattern.HH_mm,
		hideIcon: true,
	});

	//endregion

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService
	) {
		super(poModalCtrl, psvcPlatform);
	}

	public ondDateChanged(pdDate: Date) { this.frontDuration = pdDate; }

	public submitFormAsync(): Promise<boolean> {
		const lnDureeMn: number = (this.frontDuration.getHours() * 60) + this.frontDuration.getMinutes();
		const ldEndTimeWindow: Date = DateHelper.addDays(
			new Date(),
			this.periode === EOptimizationPeriod.today ? 1 :
				this.periode === EOptimizationPeriod.halfWeek ? 3 : 7);

		const loReturn: IEventOptimizedInputModalResult = { dureeMn: lnDureeMn, endTw: ldEndTimeWindow };
		return this.close(loReturn);
	}
	//#endregion

}