import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { ScanditService } from '../../scandit/scandit.service';
import { InputReaderModule } from '../devices/input/input-reader.module';
import { TslService } from '../devices/tsl/tsl.service';
import { ArrowedBarcodeReaderComponent } from './components/barcode-reader/arrowed-barcode-reader.component';
import { BarcodeReaderService } from './services/barcode-reader.service';
import { CordovaBarcodeReaderService } from './services/cordova-barcode.service';

@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		InputReaderModule
	],
	exports: [
		ArrowedBarcodeReaderComponent
	],
	declarations: [
		ArrowedBarcodeReaderComponent
	],
	providers: [
		BarcodeReaderService,
		CordovaBarcodeReaderService,
		ScanditService,
		TslService,
		ModalController
	]
})
export class BarcodeReaderModule { }