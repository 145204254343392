/** Liste des lecteurs de code-à-barres supportés par le module `@calao/logistics`. */
export enum EBarcodeReaders {
	/** Plugin de la communauté Cordova. */
	cordova = "CORDOVA",
	/** Plugin Cordova de Scandit. */
	scandit = "SCANDIT",
	/** Lecteur physique du TSL. */
	tsl = "TSL",
	/** Utilisation d'une balise HTML input en tant que récepteur de code-barres. */
	input = "INPUT",
	/** Utilisation du SDK du lecteur de code-barres spécfiques aux appareils de la marque Cipherlab. */
	cipherlab = "CIPHERLAB",
}