import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ObserveProperty } from '../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../observable/models/observable-property';
import { ISettingClickableItemParams } from '../models/isetting-clickable-item-params';

@Component({
	selector: 'calao-setting-clickable-item',
	templateUrl: './setting-clickable-item.component.html',
	styleUrls: ['./setting-clickable-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingClickableItemComponent extends ComponentBase implements ISettingClickableItemParams {

	//#region FIELDS

	@Output("onItemClick") private readonly moOnItemClick = new EventEmitter<CustomEvent>();

	//#endregion

	//#region PROPERTIES

	private msLabel?: string;
	/**@implements */
	public get label(): string {
		return this.msLabel;
	}
	@Input() public set label(psLabel: string) {
		if (psLabel !== this.msLabel) {
			this.msLabel = psLabel;
			this.detectChanges();
		}
	}

	private msValue: string;
	/**@implements */
	public get value(): string {
		return this.msValue;
	}
	@Input() public set value(psValue: string) {
		if (psValue !== this.msValue) {
			this.msValue = psValue;
			this.detectChanges();
		}
	}

	private mbDisabled: boolean;
	/**@implements */
	public get disabled(): boolean { return this.mbDisabled; }
	@Input() public set disabled(pbDisabled: boolean) {
		if (pbDisabled !== this.mbDisabled) {
			this.mbDisabled = pbDisabled;
			this.detectChanges();
		}
	}

	/** Couleur du bouton. */
	@Input() public color?: string;
	@ObserveProperty<SettingClickableItemComponent>({ sourcePropertyKey: "color" })
	public readonly observableColor = new ObservableProperty<string>();

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	public itemClick(): void {
		this.moOnItemClick.emit();
	}

	//#endregion

}