<calao-sub-header *ngIf="displayHeader$ | async">
	<calao-sub-header-title>{{observableTitle.value$ | async}}</calao-sub-header-title>
	<calao-sub-header-end-buttons *ngIf="observableDisplayActionButtons.value$ | async">
		<calao-sub-header-button *ngIf="observableCanEdit.value$ | async" (click)="onEditAsync()" class="primary-btn">
			<ion-icon name="create" slot="icon-only"></ion-icon>
		</calao-sub-header-button>
		<calao-sub-header-button *ngIf="observableCanMoveToTrash.value$ | async" (click)="onMoveToTrashAsync()"
			class="primary-btn">
			<ion-icon name="trash" slot="icon-only"></ion-icon>
		</calao-sub-header-button>
		<calao-sub-header-button *ngIf="observableCanRestore.value$ | async" (click)="onRestoreAsync()" class="primary-btn">
			<ion-icon name="refresh" slot="icon-only"></ion-icon>
		</calao-sub-header-button>
		<calao-sub-header-button *ngIf="observableCanDelete.value$ | async" (click)="onDeleteAsync()" class="delete-btn">
			<ion-icon name="trash" slot="icon-only"></ion-icon>
		</calao-sub-header-button>
	</calao-sub-header-end-buttons>
</calao-sub-header>