import { AfterContentInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ObjectHelper } from '../../../../helpers/objectHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { CustomIonInputStringEvent } from '../../../ionic/models/icustom-ion-input-event';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { FilterBarComponent } from '../filter-bar/filter-bar.component';

@Component({
	selector: 'calao-filter-bar-search-input',
	templateUrl: './filter-bar-search-input.component.html',
	styleUrls: ['./filter-bar-search-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterBarSearchInputComponent implements AfterContentInit {

	//#region FIELDS

	/** Événement lors du changement de la valeur de la recherche. */
	@Output("onValueChange") private readonly moOnValueChangeEvent = new EventEmitter<string>();

	//#endregion

	//#region PROPERTIES

	@Input() public placeholder?: string;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "placeholder" })
	public readonly observablePlaceholder = new ObservableProperty<string>();

	@Input() public value?: string | null;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "value" })
	public readonly observableValue = new ObservableProperty<string>();

	/** Indique si on doit afficher le bouton toggle de modale. */
	@Input() public hasModal?: boolean;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "hasModal" })
	public readonly observableHasModal = new ObservableProperty<boolean>();

	/** Doc propriété. */
	@Input() public filterBadgeLabel?: string | null;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "filterBadgeLabel" })
	public readonly observableFilterBadgeLabel = new ObservableProperty<string>();

	/** Temps d'attente pour déclencher l'événement, en ms. */
	@Input() public debounce?: number | null;
	@ObserveProperty<FilterBarSearchInputComponent>({ sourcePropertyKey: "debounce" })
	public readonly observableDebounce = new ObservableProperty<number>(0);

	//#endregion

	//#region METHODS

	constructor(private readonly ioFilterBar: FilterBarComponent) { }

	public ngAfterContentInit(): void {
		if (!ObjectHelper.isDefined(this.observableHasModal.value))
			this.observableHasModal.value = this.ioFilterBar.hasModal;
	}

	public onValueChange(poEvent: Event): void {
		const lsValue: string = (poEvent as CustomIonInputStringEvent)?.detail?.value;
		this.moOnValueChangeEvent.emit(StringHelper.isBlank(lsValue) ? undefined : lsValue);
	}

	public presentFilterModal(): void {
		this.ioFilterBar.presentFilterModal();
	}

	//#endregion

}
