import { Directive } from '@angular/core';

@Directive({
	selector: '[tourStatus]'
})
export class StatusDirective {

	public constructor() { }

}
