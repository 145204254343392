import { Exclude, Expose } from 'class-transformer';
import { NumberHelper } from '../../../../../helpers/numberHelper';
import { ResolveModel } from '../../../../utils/models/decorators/resolve-model.decorator';
import { ItemPrice } from '../../models/item-price';
import { IDeliveryItem } from './idelivery-item';

export class DeliveryItem implements IDeliveryItem {

	//#region PROPERTIES

	/** @implements */
	public readonly code: string;

	@Exclude({ toPlainOnly: true })
	private mnQty: number;
	/** ## Ne doit pas être setté !
	 * @implements
	 */
	@Expose()
	public get qty(): number { return this.mnQty; }
	public set qty(pnQty: number) {
		//! Il faut passer par la méthode pour mettre à jour parce qu'on est pas censé modifier la quantité des items.
		//todo : mettre setter privé et vérifier que ResolveModel/PlainToClass peuvent setter correctement après migration version typescript permettant accesseurs avec plusieurs visibilités.
		this.updateQty(pnQty);
	}

	/** @implements */
	@ResolveModel(ItemPrice)
	public readonly price: ItemPrice;

	/** @implements */
	public readonly codes?: string[];

	//#endregion

	//#region METHODS

	constructor(psCode: string, pnQty: number, poPrice: ItemPrice, paCodes?: string[]) {
		this.code = psCode;
		this.qty = pnQty;
		this.price = poPrice;
		this.codes = paCodes;
	}

	/** Met à jour la quantité de l'article si le paramètre est valide. */
	public updateQty(pnQty: number): void {
		if (this.mnQty !== pnQty && NumberHelper.isValidPositive(pnQty))
			this.mnQty = pnQty;
	}

	//#endregion

}