<ion-item lines="full" (click)="raiseClickedEvent($event)">
	<div class="tour-status">
		<ng-content select="[tourStatus]"></ng-content>
	</div>

	<div>
		<div class="title-icon">
			<ion-icon *ngIf="titleIcon" [name]="titleIcon"></ion-icon>
			<span class="title">{{ title }}</span>
		</div>
		<span class="subtitle">{{ subtitle }}</span>
	</div>

	<div slot="end" class="note">{{ note }}</div>

	<ion-img *ngIf="logo; else noLogo" [src]="logo" class="logo ion-no-margin ion-no-padding" slot="end"></ion-img>
	<ng-template #noLogo>
		<div *ngIf="!logo" class="logo" slot="end"></div>
	</ng-template>

</ion-item>