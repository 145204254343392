import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { EMPTY, Observable, combineLatest, concat, defer, merge, of, throwError } from 'rxjs';
import { endWith, ignoreElements, map, mergeMap, takeWhile, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { ENetworkFlag } from '../../../model/application/ENetworkFlag';
import { Database } from '../../../model/store/Database';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { IStoreReplicationResponse } from '../../../model/store/IStoreReplicationResponse';
import { IStoreReplicationToLocalResponse } from '../../../model/store/IStoreReplicationToLocalResponse';
import { IStoreReplicationToServerResponse } from '../../../model/store/IStoreReplicationToServerResponse';
import { ConversationService } from '../../../services/conversation.service';
import { FlagService } from '../../../services/flag.service';
import { Store } from '../../../services/store.service';
import { SyncDmsService } from '../../dms/services/syncDms.service';
import { ELogActionId } from '../../logger/models/ELogActionId';
import { LoggerService } from '../../logger/services/logger.service';
import { NoOnlineReliableNetworkError } from '../../network/models/errors/NoOnlineReliableNetworkError';
import { EDatabaseSyncStatus } from '../../store/model/EDatabaseSyncStatus';
import { IResetDatabasesResult } from '../../store/model/IResetDatabasesResult';
import { ISynchronizationEvent } from '../../store/model/isynchronization-event';
import { IDatabaseGroupingConfiguration } from '../model/IDatabaseGroupingConfiguration';
import { IDatabaseSyncStatus } from '../model/IDatabaseSyncStatus';
import { IDmsSyncConfig } from '../model/IDmsSyncConfig';

export const DATABASES_GROUPING_CONFIG = new InjectionToken<IDatabaseGroupingConfiguration[]>("DATABASES_GROUPING_CONFIG");
export const DMS_SYNC_CONFIG = new InjectionToken<IDmsSyncConfig>("DMS_SYNC_CONFIG");

@Injectable()
export class DatabaseSynchroService {

	//#region FIELDS

	private static readonly C_LOG_ID = "DB.SYNC.S::";
	/** Tableau des configurations des bases de données par défaut. */
	private static readonly defaultDatabasesGroupingConfigs: IDatabaseGroupingConfiguration[] = [
		{
			roles: [EDatabaseRole.workspace, EDatabaseRole.userContext],
			title: "Espace de travail"
		},
		{
			roles: [EDatabaseRole.conversations],
			title: "Conversations"
		}
	];

	/** Configuration des bases de données de synchro du DMS par défaut. */
	private static readonly defaultDmsSyncConfig: IDmsSyncConfig = {
		title: "Documents"
	};

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcSyncDms: SyncDmsService,
		private readonly isvcFlag: FlagService,
		private readonly isvcConversations: ConversationService,
		private readonly isvcLogger: LoggerService,
		@Inject(DATABASES_GROUPING_CONFIG) @Optional() private iaDatabasesGroupingConfigs?: IDatabaseGroupingConfiguration[],
		@Inject(DMS_SYNC_CONFIG) @Optional() private ioDmsSyncConfig?: IDmsSyncConfig
	) { }

	/** Récupère un tableau de configuration des bases de données. */
	public getDatabasesGroupingConfigs(): IDatabaseGroupingConfiguration[] {
		return this.iaDatabasesGroupingConfigs ?? DatabaseSynchroService.defaultDatabasesGroupingConfigs;
	}

	/** Récupère la configuration des bases de données de synchro du DMS. */
	public getDmsSyncConfig(): IDmsSyncConfig {
		return this.ioDmsSyncConfig ?? DatabaseSynchroService.defaultDmsSyncConfig;
	}

	/** Récupère un tableau de toutes les bases de données initialisées par le `Store`. */
	public getDatabases(): Database[] {
		return MapHelper.valuesToArray(this.isvcStore.getDatabases());
	}

	public static getSyncIcon(peStatus: EDatabaseSyncStatus): string {
		switch (peStatus) {
			case EDatabaseSyncStatus.upToDate:
				return "sync-checkmark";

			case EDatabaseSyncStatus.obsolete:
				return "sync-alert";

			case EDatabaseSyncStatus.serverToLocal:
			case EDatabaseSyncStatus.localToServer:
				return "sync-loading-circle";

			case EDatabaseSyncStatus.error:
				return "sync-close";
		};
	}

	public static getCommonStatus(paDatabaseSyncStatuses: EDatabaseSyncStatus[]): EDatabaseSyncStatus {
		// On priorise "localToServer" puis "serverToLocal".
		if (paDatabaseSyncStatuses.includes(EDatabaseSyncStatus.localToServer))
			return EDatabaseSyncStatus.localToServer;

		if (paDatabaseSyncStatuses.includes(EDatabaseSyncStatus.serverToLocal))
			return EDatabaseSyncStatus.serverToLocal;

		else if (paDatabaseSyncStatuses.includes(EDatabaseSyncStatus.error))
			return EDatabaseSyncStatus.error;

		else if (paDatabaseSyncStatuses.includes(EDatabaseSyncStatus.obsolete))
			return EDatabaseSyncStatus.obsolete;

		else
			return EDatabaseSyncStatus.upToDate;
	}

	public static areDatabasesStatusEquals(poDatabaseStatusA: IDatabaseSyncStatus, poDatabaseStatusB: IDatabaseSyncStatus): boolean {
		return poDatabaseStatusA?.title === poDatabaseStatusB?.title &&
			poDatabaseStatusA?.description === poDatabaseStatusB?.description &&
			poDatabaseStatusA?.status === poDatabaseStatusB?.status;
	}

	public getSyncStatus$(paRoles: EDatabaseRole[]): Observable<EDatabaseSyncStatus> {
		const laDatabases: Database[] = this.getDatabases().filter((poDatabase: Database) => ArrayHelper.hasElements(ArrayHelper.intersection(poDatabase.roles, paRoles)));

		return combineLatest(laDatabases.map((poDatabase: Database) => poDatabase.syncStatus$)).pipe(
			map((paDatabasesSyncStatus: EDatabaseSyncStatus[]) => DatabaseSynchroService.getCommonStatus(paDatabasesSyncStatus))
		);
	}

	public getDatabasesCommonStatus$(): Observable<EDatabaseSyncStatus> {
		return combineLatest([this.getSyncStatus$(this.getGroupingConfigsRoles()), this.getDmsSyncStatus$()])
			.pipe(
				map((paDatabasesSyncStatus: EDatabaseSyncStatus[]) => DatabaseSynchroService.getCommonStatus(paDatabasesSyncStatus)),
			);
	}

	/** Récupère le statut de synchronisation des documents du DMS (documents en attente de téléchargement ou téléversement). */
	public getDmsSyncStatus$(): Observable<EDatabaseSyncStatus> {
		return this.isvcSyncDms.getPendingDownloadAndUploadDocs(true)
			.pipe(map((paPendingDocs: IStoreDocument[]) => ArrayHelper.hasElements(paPendingDocs) ? EDatabaseSyncStatus.obsolete : EDatabaseSyncStatus.upToDate));
	}

	/** Synchronise une base (montant et descendant) et un observable qui retourne un texte représentant l'avancement de la synchronisation.
	 * @param poDatabase
	 * @throws
	 * - `NoOnlineReliableNetworkError` si pas de connexion internet.
	 * - autre erreur.
	 */
	public syncDatabase$(poDatabase: Database): Observable<string> {
		if (poDatabase.hasLocalInstance() && poDatabase.hasRemoteInstance()) {
			if (!this.isvcFlag.getFlagValue(ENetworkFlag.isOnlineReliable))
				return throwError(new NoOnlineReliableNetworkError());
			else {
				return defer(() => {
					poDatabase.isSynchroFromServerOnError = false;
					poDatabase.isSynchroToServerOnError = false;
					return of(undefined);
				})
					.pipe(
						mergeMap(() => {
							const loReplicationToServer$: Observable<IStoreReplicationToServerResponse> = this.getReplicationToServer$(poDatabase);
							const loReplicationToLocal$: Observable<IStoreReplicationToLocalResponse> = this.getReplicationToLocal$(poDatabase);

							this.logSyncDatabase(poDatabase, ELogActionId.manualSyncBegin);

							return concat(
								this.execReplication$(loReplicationToServer$, poDatabase.synchronizationToServerEvent$, 1),
								this.execReplication$(loReplicationToLocal$, poDatabase.synchronizationFromServerEvent$, 2)
							);
						}),
						tap(
							_ => { },
							poError => this.logSyncDatabase(poDatabase, ELogActionId.manualSyncError, poError),
							() => this.logSyncDatabase(poDatabase, ELogActionId.manualSyncEnd)
						)
					);
			}
		}

		return EMPTY;
	}

	private logSyncDatabase(poDatabase: Database, peLogActionId: ELogActionId, poError?: any): void {
		this.isvcLogger.action(
			DatabaseSynchroService.C_LOG_ID,
			`Database '${poDatabase.id}' manual synchro ${peLogActionId === ELogActionId.manualSyncBegin ? "began" : ELogActionId.manualSyncEnd ? "ended" : "error"}`,
			peLogActionId,
			undefined,
			poError
		);
	}

	private getReplicationToServer$(poDatabase: Database): Observable<IStoreReplicationToServerResponse> {
		return defer(() => {
			// On a une gestion spéciale pour les conversations pour conserver le filtrage.
			if (poDatabase.hasRole(EDatabaseRole.conversations))
				return this.isvcConversations.replicateConversationsToServer$();
			else
				return this.isvcStore.replicateToServer(poDatabase.id);
		});
	}

	private getReplicationToLocal$(poDatabase: Database): Observable<IStoreReplicationToLocalResponse> {
		return defer(() => {
			// On a une gestion spéciale pour les conversations pour conserver le filtrage.
			if (poDatabase.hasRole(EDatabaseRole.conversations))
				return this.isvcConversations.replicateConversationsToLocal$();
			else
				return this.isvcStore.replicateToLocal(poDatabase.id);
		});
	}

	private execReplication$(
		poReplicationToServer$: Observable<IStoreReplicationResponse>,
		poSynchronizationEvent$: Observable<ISynchronizationEvent | undefined>,
		pnStepIndex: number
	): Observable<string> {

		return merge(
			poReplicationToServer$.pipe(ignoreElements(), endWith({ loaded: 100, total: 100 } as ISynchronizationEvent)), // On force l'état à 100% à la fin.
			poSynchronizationEvent$
		).pipe(
			takeWhile((poEvent?: ISynchronizationEvent) => !poEvent || poEvent.loaded !== poEvent.total, true),
			map((poEvent?: ISynchronizationEvent) => `Étape ${pnStepIndex}/2 : ${this.isvcStore.getProgressPercentageString(poEvent)}`)
		);
	}

	/** Récupère un tableau des rôles des configurations des bases de données. */
	public getGroupingConfigsRoles(): EDatabaseRole[] {
		const laRoles: EDatabaseRole[] = [];

		this.getDatabasesGroupingConfigs().forEach((poConfig: IDatabaseGroupingConfiguration) => laRoles.push(...poConfig.roles));

		return laRoles;
	}

	/** Récupère un tableau des bases de données qui ont au moins un rôle assigné dans les configurations des bases de données. */
	public getGroupingConfigsDatabases(): Database[] {
		return this.getDatabases().filter((poDatabase: Database) => ArrayHelper.hasElements(ArrayHelper.intersection(poDatabase.roles, this.getGroupingConfigsRoles())));
	}

	/** Optimise/Réinitialise des bases de données (réinitialisation après avoir remonté les modifs sur le serveur).
	 * @returns Bases de données optimisées.
	 * @throws
	 * - `NoOnlineReliableNetworkError` si pas de connexion internet,
	 * - `NoDatabaseLocalInstanceError` si pas d'instance locale pour une base de données,
	 * - `NoDatabaseRemoteInstanceError` si pas d'instance distante pour une base de données,
	 * - `autre erreur.
	 */
	public async resetDatabasesAsync(): Promise<IResetDatabasesResult> {
		// On optimise toujours les conversations si on les utilise.
		if (ArrayHelper.hasElements(this.isvcStore.getDatabasesByRole(EDatabaseRole.conversations, false)))
			await this.isvcConversations.optimizeDatabasesAsync();

		return this.isvcStore.resetDatabasesAsync(this.isvcStore.getDatabasesToOptimize());
	}

	public getSyncTaskIds(laDatabases: Database[]): string[] {
		return laDatabases.map(
			(poDatabase: Database) => [
				this.isvcStore.getLocalToServerReplicationTaskId(poDatabase.id),
				this.isvcStore.getServerToLocalReplicationTaskId(poDatabase.id)
			]
		).flat();
	}

	//#endregion

}