import { Injectable } from '@angular/core';
import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { ArticleColor } from '../../../catalog/models/article-color';
import { DeliveryBarcodeItem } from '../models/delivery-barcode-item';
import { DeliveryItem } from '../models/delivery-item';

@Injectable()
export class DeliveryBarcodeItemService {

	//#region METHODS

	constructor() { }

	/** Retourne les couleurs disponibles.
	 * @param paItems Articles restants à scanner du portant.
	 */
	public getItemColors(paItems: DeliveryBarcodeItem[]): ArticleColor[] {
		const laColors: ArticleColor[] = [];

		paItems.forEach((poItem: DeliveryBarcodeItem) => {
			if (!laColors.some((poColorInfo: ArticleColor) => poColorInfo.equals(poItem.color)))
				laColors.push(poItem.color);
		});

		return laColors;
	}

	/** Récupère le nombre de prix différents pour une liste d'articles.
	 * @param paItems Liste d'articles.
	 */
	public getDifferentPriceCount(paItems: DeliveryItem[]): number {
		return ArrayHelper.groupBy(paItems, (poItem: DeliveryItem) => poItem.price.uid).size;
	}

	//#endregion

}