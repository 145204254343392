import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core';
import { IonInfiniteScroll, ModalController } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { ObjectHelper } from '../../../../../helpers/objectHelper';
import { PlatformService } from '../../../../../services/platform.service';
import { ModalComponentBase } from '../../../../modal/model/ModalComponentBase';
import { IAcquisitionSummary } from '../../../IAcquisitionSummary';
import { ReadingConfigService } from '../../../reading/ReadingConfig.service';
import { IBarcodeAcquisition } from '../../models/ibarcode-acquisition';
import { TslService } from '../tsl.service';

@Component({
	templateUrl: './tsl-barcode-modal.component.html',
	styleUrls: ['./tsl-barcode-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TslBarcodeModalComponent extends ModalComponentBase<IBarcodeAcquisition[]> {

	//#region FIELDS

	/** Nombre de composants créés par lot dans le infinite scroll. */
	private static readonly C_CUSTOM_COMPONENTS_LOAD_SIZE = 20;

	/** Permet d'afficher la liste des composants custom de l'application, chargés dynamiquement. */
	@ViewChild(IonInfiniteScroll) private moInfiniteScroll: IonInfiniteScroll;
	/** Tag HTML hôte pour le composant qui décrit de manière sommaire un élément scanné. */
	@ViewChild("scanArticlesSummaries", { static: false, read: ViewContainerRef }) private moScanSummariesHost: ViewContainerRef;

	/** Dernier élément chargé dans le ion dynamic scroll. */
	private mnInfiniteScrollLastLoaded = 0;
	/** Factory pour le composant spécifique qui doit être utilisé pour afficher des informations sur un code-barres acquis. */
	private moScanSummaryComponentFactory: ComponentFactory<IAcquisitionSummary>;

	//#endregion

	//#region PROPERTIES

	public acquisitions: IBarcodeAcquisition[] = [];
	public totalScanned = 0;

	//#endregion

	//#region METHODS

	constructor(
		private isvcTsl: TslService,
		private isvcReadingConfig: ReadingConfigService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef,
		poResolver: ComponentFactoryResolver
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
		this.moScanSummaryComponentFactory = poResolver.resolveComponentFactory(this.isvcReadingConfig.barcodeDetail());

		this.isvcTsl.barcode$.pipe(
			tap((poBarcode: IBarcodeAcquisition) => {
				this.onRead(poBarcode.code);
			})
		).subscribe();
	}

	public onRead(psCode: string): void {
		console.debug("TSL-MODAL.C::Récupération d'une nouvelle acquisition: ", psCode);
		const lnIndex: number = this.acquisitions.findIndex((poValue: IBarcodeAcquisition) => poValue.code === psCode);

		// On incrémente s'il est connu, sinon on l'ajoute au tableau.
		// Dans tous les cas, le dernier élément scanné est en haut du tableau.
		if (lnIndex === -1) {
			this.acquisitions.unshift({ code: psCode, numberOfScans: 1, taken: [new Date()] });
		} else {
			this.acquisitions[lnIndex] = ObjectHelper.clone(this.acquisitions[lnIndex]);
			this.acquisitions[lnIndex].numberOfScans += 1;
			this.acquisitions[lnIndex].taken.push(new Date());
			ArrayHelper.moveElement(this.acquisitions, lnIndex, 0);
		}

		this.totalScanned++;

		this.recreateUiList();

		this.detectChanges();
	}

	private recreateUiList(): void {
		this.moScanSummariesHost.clear();
		this.mnInfiniteScrollLastLoaded = 0;
		this.loadNextWage();
	}

	/** Charge les prochains composant du Ion infinite scroll. */
	public loadNextWage(): void {
		console.debug("TSL-BARCODE-MODAL.C::Création de nouveaux composants dans l'infinite scroll.");

		const laAcquisitions: IBarcodeAcquisition[] = this.acquisitions;

		/** Dernier élément qui sera chargé après cette vague. */
		const lnWageEnd: number = (this.mnInfiniteScrollLastLoaded + TslBarcodeModalComponent.C_CUSTOM_COMPONENTS_LOAD_SIZE > laAcquisitions.length) ?
			laAcquisitions.length : (this.mnInfiniteScrollLastLoaded + TslBarcodeModalComponent.C_CUSTOM_COMPONENTS_LOAD_SIZE);

		for (let i = this.mnInfiniteScrollLastLoaded; i < lnWageEnd; i++) {
			// Créé un composant sommaire et l'injecte dans le DOM.
			const loCurrent: IBarcodeAcquisition = laAcquisitions[i];

			const loComponentRef: ComponentRef<IAcquisitionSummary> = this.moScanSummariesHost.createComponent(this.moScanSummaryComponentFactory);
			loComponentRef.instance.code = loCurrent.code;
			loComponentRef.instance.numberOfScans = loCurrent.numberOfScans;
		}

		this.mnInfiniteScrollLastLoaded = lnWageEnd;

		if (lnWageEnd === laAcquisitions.length)	// Si tous les éléments sont affichés, on désactive l'infinite scroll.
			this.moInfiniteScroll.disabled = true;

		this.moInfiniteScroll.complete();
	}

	public validate(): void {
		this.close(this.acquisitions);
	}

	//#endregion

}