import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StoreDocumentHelper } from '../../../helpers/storeDocumentHelper';
import { IIndexedObject } from '../../../model/IIndexedObject';
import { EntityLinkService } from '../../../services/entityLink.service';
import { LoadingService } from '../../../services/loading.service';
import { PatternResolverService } from '../../../services/pattern-resolver.service';
import { PlatformService } from '../../../services/platform.service';
import { Store } from '../../../services/store.service';
import { ObserveProperty } from '../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../observable/models/observable-property';
import { EPermission } from '../../permissions/models/EPermission';
import { TCRUDPermissions } from '../../permissions/models/tcrud-permissions';
import { PermissionsService } from '../../permissions/services/permissions.service';
import { ModelResolver } from '../../utils/models/model-resolver';
import { secure } from '../../utils/rxjs/operators/secure';
import { tapError } from '../../utils/rxjs/operators/tap-error';
import { EntitiesUpdateService } from '../services/entities-update.service';
import { EntitiesService } from '../services/entities.service';
import { CurrentEntityTrackerModal } from './current-entity-tracker-modal';
import { ELayoutType } from './elayout-type';
import { Entity } from './entity';
import { IEntityDescriptor } from "./ientity-descriptor";
import { ILayoutParams } from './ilayout-params';
import { ILayoutParamsComponent } from './ilayout-params-component';
import { IStackLayoutParams } from './istack-layout-params';

@Component({ template: "" })
export abstract class EntityModalBase extends CurrentEntityTrackerModal {

	//#region FIELDS

	private readonly moObservablePermissionScope = new ObservableProperty<string>();
	private readonly moObservableSourceModel = new ObservableProperty<Entity>();

	//#endregion

	//#region PROPERTIES

	@Input() public entityDescGuid: string;
	@ObserveProperty<EntityModalBase>({ sourcePropertyKey: "entityDescGuid" })
	public readonly observableEntityDescGuid = new ObservableProperty<string>();

	@Input() public entityGuid: string;
	@ObserveProperty<EntityModalBase>({ sourcePropertyKey: "entityGuid" })
	public readonly observableEntityGuid = new ObservableProperty<string>();

	@Input() public context: IIndexedObject;
	@ObserveProperty<EntityModalBase>({ sourcePropertyKey: "context" })
	public readonly observableContext = new ObservableProperty<IIndexedObject>({ state: {} });

	@Input() public isEdit: boolean;
	@ObserveProperty<EntityModalBase>({ sourcePropertyKey: "isEdit" })
	public readonly observableIsEdit = new ObservableProperty<boolean>(false);

	public readonly params$: Observable<ILayoutParams | undefined> = this.getLayoutParams$().pipe(secure(this));
	public readonly observableIsLoading = new ObservableProperty<boolean>(true);
	public readonly observableHasError = new ObservableProperty<boolean>(false);
	public readonly observableDescriptor = new ObservableProperty<IEntityDescriptor>();

	public readonly canRead$: Observable<boolean> = this.getPermission$("read").pipe(secure(this));
	public readonly canDelete$: Observable<boolean> = this.getPermission$("delete").pipe(secure(this));
	public readonly observableCanEdit = new ObservableProperty<boolean>(false).bind(this.getPermission$("edit"), this);

	//#endregion

	//#region METHODS

	constructor(
		protected readonly isvcEntities: EntitiesService,
		protected readonly isvcEntitiesUpdate: EntitiesUpdateService,
		protected readonly isvcPatternResolver: PatternResolverService,
		protected readonly isvcPermissions: PermissionsService,
		protected readonly isvcLoading: LoadingService,
		protected readonly isvcStore: Store,
		psvcEntityLink: EntityLinkService,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(psvcEntityLink, poModalCtrl, psvcPlatform, poChangeDetector);

		this.observableDescriptor.value$.pipe(
			tap((poDescriptor: IEntityDescriptor) => {
				if (!StoreDocumentHelper.hasRevision(poDescriptor?.entry))
					this.observableCanEdit.bind(this.getPermission$("create"), this);

				// Mise en cache du modèle.
				if (poDescriptor.entry && !this.moObservableSourceModel.value)
					this.moObservableSourceModel.value = ModelResolver.toClass(Entity, ModelResolver.toPlain(poDescriptor.entry));
			}),
			secure(this)
		).subscribe();
	}

	protected getLayoutParams$(): Observable<ILayoutParams | undefined> {
		return combineLatest([this.observableEntityDescGuid.value$, this.observableEntityGuid.value$, this.observableContext.value$]).pipe(
			switchMap(([psEntityDescGuid, psEntityGuid, poContext]: [string, string, IIndexedObject]) => combineLatest([this.isvcEntities.getDescriptor$(psEntityDescGuid, psEntityGuid, undefined, poContext), this.observableIsEdit.value$])),
			switchMap(([poDesc, pbIsEdit]: [IEntityDescriptor | undefined, boolean]) => {
				if (poDesc) {
					if (poDesc.permissionScope)
						this.moObservablePermissionScope.value = poDesc.permissionScope;

					this.observableDescriptor.value = poDesc;
					this.moObservableEntity.value = poDesc.entry;

					return this.moObservableCurrentEntitySet.value$.pipe(
						map(() => {
							this.observableIsLoading.value = false;
							const lsLayoutId: string = this.getLayoutId(poDesc, pbIsEdit);
							const loLayoutParams: ILayoutParams | undefined = poDesc.layouts.find((poLayout: ILayoutParams) => lsLayoutId === poLayout.id);

							// Moyen simple de faire abstraction du header du descripteur et de garder seulement celui de la modale.
							if (loLayoutParams && loLayoutParams.type === ELayoutType.stack)
								ArrayHelper.removeElementByFinder((loLayoutParams as IStackLayoutParams).components, (poCompParams: ILayoutParamsComponent) => poCompParams.type.endsWith("header"));

							return loLayoutParams;
						})
					);
				}

				this.observableIsLoading.value = false;
				return EMPTY;
			}),
			tapError(() => {
				this.observableIsLoading.value = false;
				this.observableHasError.value = true;
			})
		);
	}

	protected getLayoutId(poDesc: IEntityDescriptor, pbIsEdit: boolean): string {
		if (pbIsEdit)
			return poDesc.defaultEditLayoutId;
		return poDesc.defaultViewLayoutId;
	}

	protected getPermission$(psPermission: TCRUDPermissions): Observable<boolean> {
		return combineLatest([this.moObservablePermissionScope.value$, this.observableDescriptor.value$]).pipe(
			map(([psScope, poDescriptor]: [string, IEntityDescriptor]) =>
				this.isvcPermissions.evaluatePermission(psScope as EPermission, psPermission, poDescriptor.entry)
			)
		);
	}

	/** Permet de fermer la modale. */
	public override async close(): Promise<boolean> {
		let lbCanClose: boolean = true;
		if (this.observableIsEdit.value) {
			if (!ObjectHelper.areEqual(this.moObservableSourceModel.value, this.observableDescriptor.value?.entry))
				lbCanClose = await this.isvcEntities.showUnsavedEntityPopupAsync();
		}

		return lbCanClose ? super.close() : false;
	}

	//#endregion

}