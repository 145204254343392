import { ArrayHelper } from "../../../../helpers/arrayHelper";
import { EPrefix } from "../../../../model/EPrefix";
import { ESortOrder } from "../../../../model/ESortOrder";
import { IRack } from "../models/irack";

/** Permet de mettre à disposition des méthodes pour aider à manipuler des portants. */
export abstract class RackHelper {

	//#region METHODS

	/** Récupère l'identifiant de la tâche associé à un portant.
	 * @param poRack Portant à partir duquel retrouver le'identifiant de la tâche associée.
	 */
	public static getTaskIdFromRack<T extends IRack>(poRackInfo: T | string): string {
		const lsRackId = typeof (poRackInfo) === "string" ? poRackInfo : poRackInfo._id;

		// On sépare les caractères en fonction d'un underscore, puis on supprime le premier et le dernier élément (préfixe et guid du portant),
		// pour enfin recoller les caractères restants sous forme d'identifiant (séparation par underscore).
		return lsRackId.split("_").slice(1, -1).join("_");
	}

	/** Récupère l'identifiant du rendez-vous associé au portant.
	 * @param poRack Portant dont il faut récupérer l'identifiant de rendez-vous associé.
	 */
	public static getAppointIdFromRack<T extends IRack>(poRack: T): string {
		// id de portant : rack_task_appoint_tour_{tourGuid}_{appointGuid}_{taskGuid}_{rackGuid}
		return RackHelper.getAppointIdFromRackId(poRack._id);
	}

	/** Récupère l'identifiant du rendez-vous associé au portant.
	 * @param poRackId Id du portant dont il faut récupérer l'identifiant de rendez-vous associé.
	 */
	public static getAppointIdFromRackId(poRackId: string): string {
		// id de portant : rack_task_appoint_tour_{tourGuid}_{appointGuid}_{taskGuid}_{rackGuid}
		return poRackId.split("_").slice(2, -2).join("_");
	}

	/** Trie le tableau des portants et retourne le tableau triés.
	 * @param paRacks Le tableau des portants à trier.
	 * @param psProperty La propriété à comparer sur les éléments du tableau.
	 * @param peSortOrder L'ordre du tri ascendant/descendant
	 */
	public static sortRacksBy<T extends IRack>(paRacks: T[], psProperty: keyof T, peSortOrder: ESortOrder = ESortOrder.ascending): T[] {
		return ArrayHelper.dynamicSort(paRacks, psProperty, peSortOrder);
	}

	/** Retourne l'identifiant complet d'un portant.
	 * @param psTaskId Identifiant de la tâche.
	 * @param psRackGuid Guid du portant.
	 */
	public static buildRackId(psTaskId: string, psRackGuid: string): string {
		return `${EPrefix.rack}${psTaskId}_${psRackGuid}`;
	}

	//#endregion

}
