import { EAccuracyType } from "./eaccuracy-type";
import { IBarcodeReaderSettings } from "./ibarcode-reader-settings";

export class BarecodeReaderSettings implements IBarcodeReaderSettings {

	//#region PROPERTIES

	/** @implements */
	public accuracy: EAccuracyType = EAccuracyType.crosshair;

	//#endregion

}
