import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ErrorMessageComponent } from './components/error-message/error-message.component';



@NgModule({
	declarations: [ErrorMessageComponent],
	exports: [ErrorMessageComponent],
	imports: [
		CommonModule,
		IonicModule
	]
})
export class ErrorsModule { }
