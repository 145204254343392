export class DmsArticleColor {

	//#region PROPERTIES

	/** Identifiant de l'article. */
	public readonly itemId: string;
	/** Identifiant de la couleur de l'article. */
	public readonly colorId?: string;
	/** Libellé de la couleur de l'article. */
	public readonly colorLabel?: string;
	/** Identifiant de la couleur de l'article sur la ged. */
	public readonly colorDmsId?: string;
	/** Identifiant de la miniature de la couleur de l'article sur la ged. */
	public readonly thumbnailDmsId?: string;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(psItemId: string, psColorId?: string, psColorLabel?: string, psColorDmsId?: string, psThumbnailDmsId?: string) {
		this.itemId = psItemId;
		this.colorId = psColorId;
		this.colorLabel = psColorLabel;
		this.colorDmsId = psColorDmsId;
		this.thumbnailDmsId = psThumbnailDmsId;
	}

	//#endregion METHODS

}