import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../helpers/ComponentBase';

@Component({
	selector: 'calao-error-message',
	templateUrl: './error-message.component.html',
	styleUrls: ['./error-message.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		class: "empty-screen"
	}
})
export class ErrorMessageComponent extends ComponentBase {

	//#region FIELDS

	private static readonly C_DEFAULT_MESSAGE = "Une erreur est survenue.";
	private static readonly C_DEFAULT_ICON = "warning-outline";

	//#endregion

	//#region PROPERTIES

	private msMessage: string = ErrorMessageComponent.C_DEFAULT_MESSAGE;
	public get message(): string {
		return this.msMessage;
	}
	@Input()
	public set message(psMessage: string) {
		if (psMessage !== this.msMessage) {
			this.msMessage = psMessage;
			this.detectChanges();
		}
	}

	private msIcon: string = ErrorMessageComponent.C_DEFAULT_ICON;
	public get icon(): string {
		return this.msIcon;
	}
	@Input()
	public set icon(psIcon: string) {
		if (psIcon !== this.msIcon) {
			this.msIcon = psIcon;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	//#endregion

}
