<calao-filter-bar #filterBar [filterValues]="observableFilterValues.value$ | async"
	[nbTmpResults]="observableNbTmpResults.value$ | async" (onFilterValuesChange)="onFilterValuesChange($any($event))"
	(onTmpFilterValuesChange)="onTmpFilterValuesChange($any($event))">
	<calao-filter-bar-main-content>
		<calao-filter-bar-item key="name">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-search-input placeholder="Rechercher un dossier ou un document"
					[value]="observableValue.value$ | async" (onValueChange)="observableValue.value = $event"
					[filterBadgeLabel]="nbFiltersLabel$ | async" [debounce]="500">
				</calao-filter-bar-search-input>
			</ng-template>
		</calao-filter-bar-item>
	</calao-filter-bar-main-content>
	<calao-filter-bar-modal-content canValidate>
		<calao-filter-bar-item key="name">
			<ng-template let-observableValue="observableValue">
				<calao-filter-bar-search-input placeholder="Rechercher un dossier ou un document"
					[value]="observableValue.value$ | async" [hasModal]="false" (onValueChange)="observableValue.value = $event">
				</calao-filter-bar-search-input>
			</ng-template>
		</calao-filter-bar-item>
	</calao-filter-bar-modal-content>
</calao-filter-bar>