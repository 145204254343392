import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, combineLatest, defer, of } from 'rxjs';
import { catchError, defaultIfEmpty, distinctUntilChanged, filter, map, mapTo, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ContactHelper } from '../../../helpers/contactHelper';
import { GuidHelper } from '../../../helpers/guidHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { StoreHelper } from '../../../helpers/storeHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EPrefix } from '../../../model/EPrefix';
import { ESortOrder } from '../../../model/ESortOrder';
import { ConfigData } from '../../../model/config/ConfigData';
import { IConversation } from '../../../model/conversation/IConversation';
import { IFlag } from '../../../model/flag/IFlag';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { ApplicationService } from '../../../services/application.service';
import { ContactsService } from '../../../services/contacts.service';
import { ConversationService } from '../../../services/conversation.service';
import { Store } from '../../../services/store.service';
import { Contact } from '../../contacts/models/contact';
import { IDmsDocument } from '../../dms/model/IDmsDocument';
import { IDmsMeta } from '../../dms/model/IDmsMeta';
import { DmsPermissionError } from '../../dms/services/DmsPermissionError';
import { DmsMetaService } from '../../dms/services/dms-meta.service';
import { Entity } from '../../entities/models/entity';
import { IEntityDescriptor } from '../../entities/models/ientity-descriptor';
import { EntitiesUpdateService } from '../../entities/services/entities-update.service';
import { EntitiesService } from '../../entities/services/entities.service';
import { ILogActionHandler } from '../../logger/models/ILogActionHandler';
import { LogActionHandler } from '../../logger/models/log-action-handler';
import { LoggerService } from '../../logger/services/logger.service';
import { ObservableProperty } from '../../observable/models/observable-property';
import { EPermissionsFlag } from '../../permissions/models/EPermissionsFlag';
import { IPermissionContext } from '../../permissions/models/ipermission-context';
import { TCRUDPermissions } from '../../permissions/models/tcrud-permissions';
import { PermissionsService } from '../../permissions/services/permissions.service';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { IDataSourceRemoteChanges } from '../../store/model/IDataSourceRemoteChanges';
import { ModelResolver } from '../../utils/models/model-resolver';
import { secure } from '../../utils/rxjs/operators/secure';
import { IPathPermission } from '../models/IPathPermission';
import { DmsDocument } from '../models/dms-document';
import { DocExplorerConfig } from '../models/doc-explorer-config';
import { Document } from '../models/document';
import { DocumentsCache } from '../models/documents-cache';
import { DocumentsCounters } from '../models/documents-counters';
import { Folder } from '../models/folder';
import { FolderConfig } from '../models/folder-config';
import { FolderContent } from '../models/folder-content';
import { FormDocument } from '../models/form-document';
import { IDocExplorerConfig } from '../models/idoc-explorer-config';
import { IFormDocument } from '../models/iform-document';
import { IUserStatus } from '../models/iuser-status';
import { DocumentStatusService } from './document-status.service';

@Injectable({
	providedIn: 'root'
})
export class DocExplorerDocumentsService extends DestroyableServiceBase implements ILogActionHandler {

	//#region FIELDS

	private readonly C_WS_CONFIG_NAME = "docExplorer";

	private static readonly C_DOCUMENTS_VIEW_NAME = "app/documents-by-path";
	private static readonly C_LOG_ID = "DOC.EXPLR.DOC.S::";

	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter, () => true);

	/** Fichier de configuration. */
	private moObservableConfig = new ObservableProperty<DocExplorerConfig>();
	/** Cache des documents. */
	private readonly moDocumentsCache = new DocumentsCache();

	private readonly moEntitiesCache = new Map<string, ObservableProperty<Entity | undefined>>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** @implements */
	public readonly logSourceId: string = DocExplorerDocumentsService.C_LOG_ID;
	/** @implements */
	public readonly logActionHandler = new LogActionHandler(this);

	public static readonly C_UNDEFINED_DATE_KEY = "undefined";
	public static readonly C_UNDEFINED_DATE_LABEL = "Date non définie";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcContacts: ContactsService,
		private readonly isvcPermissions: PermissionsService,
		private readonly isvcMeta: DmsMetaService,
		private readonly ioRouter: Router,
		private readonly isvcDocumentStatus: DocumentStatusService,
		/** @implements */
		public readonly isvcLogger: LoggerService,
		private readonly isvcEntities: EntitiesService,
		private readonly isvcEntitiesUpdate: EntitiesUpdateService,
		private readonly isvcConversations: ConversationService,
		psvcApplication: ApplicationService,
	) {
		super();

		psvcApplication.observeFlag(EPermissionsFlag.isLoaded)
			.pipe(
				filter((poFlag: IFlag) => !!ConfigData.appInfo.useDocExplorer && poFlag.value !== undefined),
				map((poFlag: IFlag) => poFlag.value),
				distinctUntilChanged(),
				mergeMap((pbValue: boolean) => pbValue ? this.init$() : of(undefined))
			)
			.subscribe();
	}

	//#region EXPLORER

	private init$() {
		return this.initConfig$().pipe(
			mergeMap(() => this.initDocuments$())
		);
	}

	/** Initialise le fichier de configuration de l'explorateur de documents. */
	private initConfig$(): Observable<DocExplorerConfig> {
		const loDataSource: IDataSourceRemoteChanges = {
			databaseId: ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)),
			viewParams: {
				include_docs: true,
				key: `${EPrefix.workspaceConfig}${this.C_WS_CONFIG_NAME}`
			},
			live: true,
			remoteChanges: true,
			activePageManager: this.moActivePageManager,
			baseClass: DocExplorerConfig
		};

		return this.isvcStore.getOne<IDocExplorerConfig>(loDataSource, false).pipe(
			tap((poConfig: DocExplorerConfig) => this.moObservableConfig.value = poConfig),
		);
	}

	private initDocuments$() {
		const loDataSource: IDataSourceRemoteChanges = {
			role: EDatabaseRole.workspace,
			viewName: DocExplorerDocumentsService.C_DOCUMENTS_VIEW_NAME,
			viewParams: {
				include_docs: true
			},
			live: true,
			remoteChanges: true,
			activePageManager: this.moActivePageManager
		};

		return this.isvcStore.get<IDmsDocument | IFormDocument>(loDataSource).pipe(
			map((paAllDocuments: (IDmsDocument | IFormDocument)[]) => this.getInstantiatedDocuments(paAllDocuments)),
			mergeMap((paAllDocuments: Document[]) => defer(() => this.fillDocumentsAsync(this.initReadableDocuments(paAllDocuments))).pipe(
				mergeMap((paDocuments: Document[]) => this.initDocumentsStatuses$(paDocuments)),
				tap((paDocuments: Document[]) => this.moDocumentsCache.init(paAllDocuments, paDocuments))
			)),
		);
	}

	private getInstantiatedDocuments(paDocuments: (IDmsDocument | IFormDocument)[]): Document[] {
		const laFilteredDocumentIds: string[] = [];
		const laFilteredDocuments: Document[] = [];
		paDocuments.forEach((poDocument: IDmsDocument | IFormDocument) => {
			if (!laFilteredDocumentIds.includes(poDocument._id)) {
				laFilteredDocumentIds.push(poDocument._id);
				if ((poDocument as IFormDocument).$document)
					laFilteredDocuments.push(ModelResolver.toClass(FormDocument, ModelResolver.toPlain(poDocument)));
				else
					laFilteredDocuments.push(ModelResolver.toClass(DmsDocument, ModelResolver.toPlain(poDocument)));
			}
		});
		return laFilteredDocuments;
	}

	private initReadableDocuments(paDocuments: Document[]): Document[] {
		const laReadableDocuments: Document[] = [];

		paDocuments.forEach((poDocument: Document) => {
			const loCacheDocument: Document | undefined = this.moDocumentsCache.documentsById.get(poDocument._id);
			const laCacheDocumentPaths: string[] | undefined = this.moDocumentsCache.allDocumentsPathsById.get(poDocument._id);
			let pbCanRead: boolean;

			if (ArrayHelper.areArraysEqual(laCacheDocumentPaths, poDocument.paths)) // Si les chemins sont les même alors la permission reste la même.
				pbCanRead = !!loCacheDocument;
			else
				pbCanRead = this.checkDocumentPermissions(poDocument, "read", false);

			if (pbCanRead)
				laReadableDocuments.push(poDocument);
		});

		return laReadableDocuments;
	}

	private initDocumentsStatuses$(paDocuments: Document[]): Observable<Document[]> {
		return this.isvcDocumentStatus.getDocumentsUserStatusesById$(paDocuments, true, this.moActivePageManager).pipe(
			map((poDocumentsStatusesById: Map<string, IUserStatus | undefined>) => {
				paDocuments.forEach((poDocument: Document) => poDocument.userStatus = poDocumentsStatusesById.get(poDocument._id));
				return paDocuments;
			})
		);
	}

	private async fillDocumentsAsync(paDocuments: Document[]): Promise<Document[]> {
		const laAuthorIds: string[] = paDocuments.map((poDocument: Document) => poDocument.authorId);
		const loAuthorById: Map<string, Contact> = await this.isvcContacts.getContactById(laAuthorIds, undefined, false).pipe(take(1)).toPromise();

		return paDocuments.filter((poDocument: Document) => {
			const loFolderConfig: FolderConfig | undefined = this.moObservableConfig.value?.getFolderConfig(ArrayHelper.getFirstElement(poDocument.paths));

			if (loFolderConfig) {
				poDocument.type = loFolderConfig.shortName;
				poDocument.icon = loFolderConfig.icon;
				poDocument.authorName = ContactHelper.getCompleteFormattedName(loAuthorById.get(poDocument.authorId));
				return true;
			}
			else
				return false;
		});
	}

	/** Récupère le fichier de configuration de l'explorateur de documents. */
	public getConfig$(): Observable<DocExplorerConfig | undefined> {
		return this.moObservableConfig.value$;
	}

	/** Récupère les documents.
	* @param psPath Chemin des documents à récupérer. Si `null` on récupère tous les documents.
	*/
	public getDocuments$(psPath?: string | null): Observable<Document[]> {
		return this.moDocumentsCache.observableDocumentsByEndPath.value$.pipe(
			map((poDocumentsByPath: Map<string, Document[]>) => {
				if (psPath === null)
					return MapHelper.valuesToArray(poDocumentsByPath).flat();
				return poDocumentsByPath.get(psPath ?? "") ?? [];
			})
		);
	}

	/** Récupère un document.
	* @param psId L'id du document à récupérer.
	* @param pbLive
	*/
	public getDocumentById$(psId: string, pbLive?: boolean): Observable<Document | undefined> {
		const loDataSource: IDataSourceRemoteChanges = {
			role: EDatabaseRole.workspace,
			viewParams: {
				key: psId,
				include_docs: true,
			},
			live: pbLive,
			remoteChanges: true,
			activePageManager: this.moActivePageManager
		};

		return this.isvcStore.getOne<Document>(loDataSource).pipe(
			map((poDocument: Document) => {
				if ((poDocument as IFormDocument).$document)
					return ModelResolver.toClass(FormDocument, ModelResolver.toPlain(poDocument));
				else
					return ModelResolver.toClass(DmsDocument, ModelResolver.toPlain(poDocument));
			}));
	}

	/** Récupère les documents.
	* @param psPath Chemin des documents à récupérer. Si `null` on récupère tous les documents.
	*/
	public getDocumentsByPaths$(): Observable<Map<string, Document[]> | undefined> {
		return this.moDocumentsCache.observableDocumentsByEndPath.value$;
	}

	/** Récupère le nombre de documents. */
	public getDocumentsCounters$(): Observable<DocumentsCounters> {
		return this.getDocuments$(null).pipe(
			map((paDocuments: Document[]) => new DocumentsCounters(paDocuments))
		);
	}

	public getOrganizedDocumentsByDate(paDocuments: Document[]): Map<string, Map<string, Document[]>[]> {
		const loOrganizedMap = new Map<string, Map<string, Document[]>[]>();

		ArrayHelper.dynamicSort(paDocuments, "displayDate", ESortOrder.descending).forEach((poDoc: Document) => {
			const ldDisplayDate: Date | undefined = poDoc.displayDate ? new Date(poDoc.displayDate) : undefined;
			const lsYearMonthKey = ldDisplayDate ? `${ldDisplayDate.getFullYear()}-${(ldDisplayDate.getMonth() + 1).toString().padStart(2, '0')}` : DocExplorerDocumentsService.C_UNDEFINED_DATE_KEY;

			if (!loOrganizedMap.has(lsYearMonthKey))
				loOrganizedMap.set(lsYearMonthKey, []);

			const lsDayKey: string = ldDisplayDate ? ldDisplayDate.getDate().toString().padStart(2, '0') : DocExplorerDocumentsService.C_UNDEFINED_DATE_KEY;

			const laDayMaps: Map<string, Document[]>[] = loOrganizedMap.get(lsYearMonthKey) ?? [];
			let loDayMap: Map<string, Document[]> | undefined = laDayMaps.find((dayDoc) => dayDoc.has(lsDayKey));

			if (!loDayMap) {
				loDayMap = new Map<string, Document[]>();
				laDayMaps.push(loDayMap);
			}

			if (!loDayMap.has(lsDayKey))
				loDayMap.set(lsDayKey, []);

			const laDayDocs: Document[] | undefined = loDayMap.get(lsDayKey);
			if (laDayDocs)
				laDayDocs.push(poDoc);
		});

		return loOrganizedMap;
	}

	public getFolderContent$(psPath: string = ""): Observable<FolderContent> {
		return combineLatest([
			this.moObservableConfig.value$,
			this.moDocumentsCache.observableDocumentsByPath.value$
		]).pipe(
			map(([poConfig, poDocumentsByPath]: [DocExplorerConfig, Map<string, Document[]>]) => {
				return this.getFolderContent(poConfig, psPath, poDocumentsByPath);
			}),
			switchMap((poFolderContent: FolderContent) =>
				this.resolveFolderContentPropertiesPattern$(poFolderContent)
			)
		);
	}

	private getFolderContent(
		poConfig: DocExplorerConfig,
		psPath: string,
		poDocumentsByPath: Map<string, Document[]>
	): FolderContent {
		const loFolderContent: FolderContent = this.createFolderContent(psPath, poConfig);
		let laDirectChildFolderPath: string[] = [];
		const lnPathPartsLength: number = StringHelper.isBlank(psPath) ? 0 : psPath.split("/").length;

		(poDocumentsByPath.get(psPath) ?? []).forEach((poDocument: Document) => {
			poDocument.paths.forEach((psDocumentPath: string) => {
				if (psDocumentPath.startsWith(psPath)) { // Un document pouvant avoir plusieurs paths, il est nécessaire de re-filtrer.
					const lsDirectChildFolderPath: string = psDocumentPath.split("/").slice(0, lnPathPartsLength + 1).join("/");

					if (psDocumentPath === psPath) // Dans le dossier courant
						loFolderContent.documents.push(poDocument);
					else if (!laDirectChildFolderPath.includes(lsDirectChildFolderPath))
						laDirectChildFolderPath.push(lsDirectChildFolderPath);
				}
			});
		});

		loFolderContent.current.documentsCounters = new DocumentsCounters(this.moDocumentsCache.observableDocumentsByPath.value?.get(psPath) ?? []);

		laDirectChildFolderPath = this.fillBuiltInFolders(poConfig, psPath, loFolderContent, poDocumentsByPath, laDirectChildFolderPath);

		// On ajoute les autres dossiers.
		this.fillFolders(laDirectChildFolderPath, loFolderContent, poConfig, poDocumentsByPath);

		return loFolderContent;
	}

	private fillBuiltInFolders(
		poConfig: DocExplorerConfig,
		psPath: string,
		loFolderContent: FolderContent,
		poDocumentsByPath: Map<string, Document[]>,
		paDirectChildFolderPath: string[]
	): string[] {
		const laDirectChildFolderPath: string[] = [...paDirectChildFolderPath];
		// On récupère les configurations des dossiers built-in.
		const laBuiltInFolders: FolderConfig[] = poConfig.getChildStaticFoldersConfig(psPath);
		// On ajoute les dossiers built-in.
		laBuiltInFolders.forEach((poFolderConfig: FolderConfig) => {
			const lsPath: string = psPath ? `${psPath}/${poFolderConfig.lastPathPart}` : poFolderConfig.path;
			loFolderContent.folders.push(this.getFolderContent(
				poConfig,
				lsPath,
				poDocumentsByPath
			));
			ArrayHelper.removeElement(laDirectChildFolderPath, lsPath);
		});

		return laDirectChildFolderPath;
	}

	private fillFolders(
		paDirectChildFolderPath: string[],
		poFolderContent: FolderContent,
		poConfig: DocExplorerConfig,
		poDocumentsByPath: Map<string, Document[]>
	): void {
		paDirectChildFolderPath.forEach((psChildFolderPath: string) => {
			poFolderContent.folders.push(this.getFolderContent(
				poConfig,
				psChildFolderPath,
				poDocumentsByPath
			));
		});
	}

	public createFolderContent(psPath: string, poConfig?: DocExplorerConfig): FolderContent {
		const loFolderContent = new FolderContent();
		// On créé le dossier courant.
		const loCurrentFolderConfig: FolderConfig | undefined = poConfig?.getFolderConfig(psPath);
		loFolderContent.current = this.createFolder(loCurrentFolderConfig, psPath);
		if (StringHelper.isBlank(psPath))
			loFolderContent.current.icon = "documents";
		return loFolderContent;
	}

	public createFolder(poCurrentFolderConfig: FolderConfig | undefined, psPath: string): Folder {
		return new Folder(
			poCurrentFolderConfig ?
				{ ...poCurrentFolderConfig, path: psPath, configPath: poCurrentFolderConfig.path } :
				{ path: psPath }
		);
	}

	public resolveFolderContentPropertiesPattern$(
		poFolderContent: FolderContent,
	): Observable<FolderContent> {
		return defer(() => {
			const laEntityIds: string[] = this.extractEntityIdsFromFolderContent(poFolderContent);
			return this.getEntities$(laEntityIds);
		}).pipe(
			map((paEntities: Entity[]) => {
				const loEntitiesById: Map<string, Entity> = ArrayHelper.groupByUnique(paEntities, (poEntity: Entity) => poEntity._id);
				this.resovleFolderContentPattern(poFolderContent, loEntitiesById);
				return poFolderContent;
			})
		);
	}

	private resovleFolderContentPattern(poFolderContent: FolderContent, poEntitiesById: Map<string, Entity>): void {
		const laFolderContents: FolderContent[] = [poFolderContent, ...poFolderContent.folders];

		for (let lnIndex = 0; lnIndex < laFolderContents.length; ++lnIndex) {
			const loFolderContent: FolderContent = laFolderContents[lnIndex];
			this.resolveFolderPropertiesPattern(loFolderContent.current, poEntitiesById);

			if (loFolderContent !== poFolderContent)
				this.resovleFolderContentPattern(loFolderContent, poEntitiesById);
		}
	}

	private extractEntityIdsFromFolderContent(poFolderContent: FolderContent): string[] {
		const laFolderContents: FolderContent[] = [poFolderContent, ...poFolderContent.folders];
		const loEntityIds = new Set<string>();

		for (let lnIndex = 0; lnIndex < laFolderContents.length; ++lnIndex) {
			const loFolderContent: FolderContent = laFolderContents[lnIndex];

			if (loFolderContent.current.hasPattern()) {
				const laFolderEntityIds: string[] = this.extractEntityIdsFromFolder(loFolderContent.current);

				for (let lnEntityIndex = 0; lnEntityIndex < laFolderEntityIds.length; ++lnEntityIndex) {
					loEntityIds.add(laFolderEntityIds[lnEntityIndex]);
				}
			}
		}

		return Array.from(loEntityIds.values());
	}


	public extractEntityIdsFromFolder(poFolder: Folder): string[] {
		let laFolderEntityIds: string[] = new RegExp(poFolder.configPath ?? "").exec(poFolder.path) ?? [];
		laFolderEntityIds.shift(); // Supprime le 1er résultat de la RegExp.
		laFolderEntityIds = ArrayHelper.unique(laFolderEntityIds);
		return laFolderEntityIds;
	}

	public resolveFolderPropertiesPattern(poFolder: Folder, poEntitiesById: Map<string, Entity>): Folder {
		if (poFolder.hasPattern()) {
			Object.entries(poFolder).forEach(([psKey, poValue]: [string, any]) => {
				if (typeof poValue === "string" && /\{{.*}}/.test(poValue)) {
					const loExtractedValues: { methodName: string; params: string[]; } | undefined = DocExplorerDocumentsService.extractPatternMethodAndParams(poValue);
					if (loExtractedValues)
						poFolder[psKey] = this[loExtractedValues.methodName](...loExtractedValues.params, poEntitiesById);

					else
						poFolder[psKey] = "Inconnu";
				}
			});
		}

		return poFolder;
	}

	/** Récupère le nom du dossier. (utilisé par les patterns du fichier de configuration du docExplorer). */
	public getFolderName(psEntityId: string, poEntitiesById: Map<string, Entity>): string {
		const loEntity: Entity | undefined = poEntitiesById.get(psEntityId);
		return this.isvcEntities.getEntityName(loEntity);
	}

	/** Récupère le nom court du dossier. (utilisé par les patterns du fichier de configuration du docExplorer). */
	public getFolderShortName(psEntityId: string, poEntitiesById: Map<string, Entity>): string {
		const loEntity: Entity | undefined = poEntitiesById.get(psEntityId);
		return this.isvcEntities.getEntityName(loEntity);
	}

	public getEntity$(psEntityId: string, psDatabaseId?: string): Observable<Entity | undefined> {
		return this.isvcEntities.getModel$(psEntityId, psDatabaseId);
	}

	public getEntityAsync(psEntityId: string, psDatabaseId?: string): Promise<Entity | undefined> {
		return this.isvcEntities.getModelAsync(psEntityId, psDatabaseId);
	}

	public getEntitiesAsync(paEntityIds: string[]): Promise<Entity[]> {
		return this.isvcEntities.getModels$(paEntityIds).pipe(take(1)).toPromise();
	}

	private getEntities$(paEntityIds: string[]): Observable<Entity[]> {
		const loObservablePropertiesByIds: Map<string, ObservableProperty<Entity | undefined>> =
			this.initEntities(paEntityIds);

		return combineLatest(
			MapHelper.valuesToArray(loObservablePropertiesByIds).map(
				(poObservableProperty: ObservableProperty<Entity | undefined>) => poObservableProperty.value$
			)
		).pipe(
			map((paEntities: (Entity | undefined)[]) => ArrayHelper.getValidValues(paEntities)),
			defaultIfEmpty([])
		);
	}

	private initEntities(paEntityIds: string[]): Map<string, ObservableProperty<Entity | undefined>> {
		const laEntityIds: string[] = [];
		const loObservablePropertiesByIds = new Map<string, ObservableProperty<Entity | undefined>>();

		paEntityIds.forEach((psId: string) => {
			let loObservableProperty: ObservableProperty<Entity | undefined> | undefined = this.moEntitiesCache.get(psId);
			if (!loObservableProperty) {
				laEntityIds.push(psId);
				loObservableProperty = new ObservableProperty();
				this.moEntitiesCache.set(psId, loObservableProperty);
			}

			loObservablePropertiesByIds.set(psId, loObservableProperty);
		});

		this.getEntitiesNotInCache$(laEntityIds).pipe(
			tap((paEntities: Entity[]) => {
				const laEntityByIds: Map<string, Entity> = ArrayHelper.groupByUnique(paEntities, (poEntity: Entity) => poEntity._id);

				laEntityIds.forEach((psId: string) => {
					const loEntity: Entity | undefined = laEntityByIds.get(psId);
					const loObservableProperty: ObservableProperty<Entity | undefined> | undefined =
						loObservablePropertiesByIds.get(psId);

					if (loObservableProperty)
						loObservableProperty.value = loEntity;
				});
			}),
			secure(this)
		).subscribe();

		return loObservablePropertiesByIds;
	}

	private getEntitiesNotInCache$(
		paEntityIds: string[],
	): Observable<Entity[]> {
		return this.isvcEntities.getModels$(paEntityIds, this.moActivePageManager);
	}

	public static extractPatternMethodAndParams(psValue: string): { methodName: string, params: string[] } | undefined {
		const nameRegex = /\{\{(.+?)\((.+?)\)\}\}/;
		const match = psValue.match(nameRegex);

		if (match)
			return { methodName: match[1], params: match[2].split(',').map(param => param.trim()) };
		else
			return undefined;
	}

	//#endregion EXPLORER

	//#region DOCUMENTS


	public moveToTrashFormDocument$(psPath: string, poDocument: FormDocument): Observable<Document> {
		return this.getFolderContent$(psPath).pipe(
			take(1),
			switchMap((poFolder: FolderContent) => {
				return this.isvcEntities.getDescriptor$(
					ArrayHelper.getFirstElement(poFolder.current.forms).descriptor,
					GuidHelper.extractGuid(poDocument._id)
				);
			}),
			take(1),
			mergeMap((poDescriptor: IEntityDescriptor) => this.isvcEntitiesUpdate.saveModel(poDocument, poDescriptor)),
			mapTo(poDocument)
		);
	}

	public moveToTrashDmsDocument$(poDocument: DmsDocument): Observable<Document> {
		return this.isvcMeta.saveSharedDocumentMeta$(poDocument, StoreHelper.getDatabaseIdFromCacheData(poDocument));
	}

	public restoreFormDocument$(psPath: string, poDocument: FormDocument): Observable<FormDocument> {
		return this.getFolderContent$(psPath).pipe(
			take(1),
			switchMap((poFolder: FolderContent) => {
				return this.isvcEntities.getDescriptor$(
					ArrayHelper.getFirstElement(poFolder.current.forms).descriptor,
					GuidHelper.extractGuid(poDocument._id)
				);
			}),
			take(1),
			mergeMap((poDescriptor: IEntityDescriptor) => this.isvcEntitiesUpdate.saveModel(poDocument, poDescriptor)),
			mapTo(poDocument)
		);
	}

	public restoreDmsDocument$(poDocument: DmsDocument): Observable<DmsDocument> {
		return this.isvcMeta.saveSharedDocumentMeta$(poDocument, StoreHelper.getDatabaseIdFromCacheData(poDocument));
	}

	public deleteFormDocument$(psPath: string, poDocument: FormDocument): Observable<boolean> {
		return this.getFolderContent$(psPath).pipe(
			take(1),
			switchMap((poFolder: FolderContent) => {
				return this.isvcEntities.getDescriptor$(
					ArrayHelper.getFirstElement(poFolder.current.forms).descriptor,
					GuidHelper.extractGuid(poDocument._id)
				);
			}),
			take(1),
			mergeMap((poDescriptor: IEntityDescriptor) => this.isvcEntitiesUpdate.deleteEntity(poDocument, poDescriptor)),
			mapTo(true)
		);
	}

	public deleteDmsDocument$(poDocument: DmsDocument): Observable<boolean> {
		return this.isvcMeta.deleteSharedDocument$(poDocument._id);
	}

	/**
	 * Déterminer un document dont le chemin de classification est indiqué est concerné par une configuration de dossier.
	 * @param psConfigPath chemin ou expression régulière représentant un ensemble de chemins.
	 * @param psDocumentPath chemin de classification d'un document.
	 * @returns true si la configuration s'applique au document.
	 */
	private matchPath(psConfigPath: string, psDocumentPath: string): boolean {
		try {
			return new RegExp(psConfigPath).test(psDocumentPath);
		} catch (_) {
			console.warn(`${DocExplorerDocumentsService.C_LOG_ID}::Invalid path regex: ${psConfigPath} matched on ${psDocumentPath}.`);
			return false;
		}
	}

	/** Détermine si l'utilisateur dispose de la permission indiquée sur le dossier
	 * de classification dont la configuration est fournie, en évaluant l'ensemble des permissions requises.
	 * NB : supporte l'agrégation de plusieurs permissions pour constituer la permission du dossier : dans ce
	 * cas, TOUTES les permissions doivent être accordées pour que la permission sur le dossier soit accordée.
	 *
	 * @param poPermissionValue
	 * @param poDocument
	 * @param pbThrowOnPermissionRefused
	 * @returns true si la permission est accordée
	 * @throws Error si une configuration de dossier de classification n'a pas pu être interprêtée.
	 */
	private evaluateDynamicPathPermission(
		poPermissionValue?: boolean | IPathPermission[],
		poDocument?: IPermissionContext,
		pbThrowOnPermissionRefused?: boolean
	): boolean | undefined {
		let lbGlobalPermissionResult: boolean | undefined;

		if (poPermissionValue !== undefined) {
			if (typeof poPermissionValue === "boolean")
				lbGlobalPermissionResult = poPermissionValue;
			else {
				try {// poPermissionValue doit être de type IPathPermission[] sinon => catch
					lbGlobalPermissionResult = poPermissionValue.every((poPermission: IPathPermission) => {
						const lbSinglePermissionResult: boolean = this.isvcPermissions.evaluatePermission(poPermission.scope, poPermission.permission, poDocument);

						if (pbThrowOnPermissionRefused && !lbSinglePermissionResult)
							throw new DmsPermissionError(poPermission.scope, poPermission.permission);
						else
							return lbSinglePermissionResult;
					});
				} catch (poError) {
					if (poError instanceof DmsPermissionError)
						throw poError;
					else
						throw new Error(`${DocExplorerDocumentsService.C_LOG_ID}::Malformed path permissions set: ${JSON.stringify(poPermissionValue)}.\nError:\n${JSON.stringify(poError)}`);
				}
			}
		}

		return lbGlobalPermissionResult;
	}

	/** Détermine si l'utilisateur actif dispose de la permission indiquée sur le document.
	 * @param poDocument Document.
	 * @param pePermission Identifiant d'une permission CRUD.
	 * @param pbThrowOnPermissionRefused Détermine si une exception DmsPermissionError doit être levée si l'autorisation est refusée
	 * @returns true si l'utilisateur est autorisé, false sinon.
	 */
	public checkDocumentPermissions(poDocument: IPermissionContext & { paths: string[] }, pePermission: TCRUDPermissions, pbThrowOnPermissionRefused: boolean): boolean {
		return this.checkPermissions(poDocument.paths, pePermission, poDocument, pbThrowOnPermissionRefused);
	}

	/** Détermine si l'utilisateur actif dispose de la permission indiquée sur le dossier.
	 * @param psPath Chemin du dossier.
	 * @param pePermission Identifiant d'une permission CRUD.
	 * @param pbThrowOnPermissionRefused Détermine si une exception DmsPermissionError doit être levée si l'autorisation est refusée
	 * @returns true si l'utilisateur est autorisé, false sinon.
	 */
	public checkFolderPermissions(psPath: string, pePermission: TCRUDPermissions, pbThrowOnPermissionRefused: boolean): boolean {
		return this.checkPermissions([psPath], pePermission, undefined, pbThrowOnPermissionRefused);
	}

	/** Détermine si l'utilisateur actif dispose de la permission indiquée sur l'ensemble des chemins indiqués.
	 * @param paPaths Liste des chemins.
	 * @param pePermission Identifiant d'une permission CRUD.
	 * @param poContext Contexte, utile pour tester la permission `mine` par exemple.
	 * @param pbThrowOnPermissionRefused Détermine si une exception DmsPermissionError doit être levée si l'autorisation est refusée
	 * @returns true si l'utilisateur est autorisé, false sinon.
	 */
	private checkPermissions(
		paPaths: string[],
		pePermission: TCRUDPermissions,
		poContext?: IPermissionContext & { paths: string[] },
		pbThrowOnPermissionRefused?: boolean
	): boolean {
		if (ArrayHelper.hasElements(paPaths) && !!ConfigData.environment.dms.shareDocumentMeta) {
			const laFolderConfigs: FolderConfig[] | undefined = this.moObservableConfig.value?.paths.filter(
				(poPathConfig: FolderConfig) => paPaths.some(psDocumentPath => this.matchPath(poPathConfig.path, psDocumentPath))
			);

			const laFoldersPermissions: (boolean | undefined)[] = [];
			laFolderConfigs?.forEach((poFolderConfig: FolderConfig) => laFoldersPermissions.push(poFolderConfig.permissions ?
				this.evaluateDynamicPathPermission(poFolderConfig.permissions[pePermission], poContext, pbThrowOnPermissionRefused ?? false) :
				true
			));

			return laFoldersPermissions.every((pbPermission: boolean | undefined) => pbPermission !== false) && laFoldersPermissions.includes(true);
		}
		else // Pas de contrôle de permission si aucun path n'est associé au document ou si le partage des méta est désactivé
			return true;
	}

	/** Détermine si l'utilisateur actif dispose de la permission indiquée sur l'ensemble des chemins du document dont l'ID est indiqué.
	 * @param psDocGuid Guid du document.
	 * @param pePermission Identifiant d'une permission CRUD.
	 * @param pbThrowOnPermissionRefused Détermine si une exception DmsPermissionError doit être levée si l'autorisation est refusée.
	 * @returns true si l'utilisateur est autorisé, false sinon.
	 */
	public checkDocumentPathPermissionsFromGuid$(psDocGuid: string, pePermission: TCRUDPermissions, pbThrowOnPermissionRefused: boolean): Observable<boolean> {
		if (!!ConfigData.environment.dms.shareDocumentMeta) {
			return this.isvcMeta.getSharedDocument$(psDocGuid)
				.pipe(
					map((poDocumentMeta: IDmsMeta) => this.checkDocumentPermissions(poDocumentMeta, pePermission, pbThrowOnPermissionRefused)),
					catchError(() => of(false))
				);
		}
		else
			return of(true);
	}

	public async shareAsync(poDocument: Document): Promise<boolean> {
		const loConversation: IConversation | undefined = await this.isvcConversations.createOrOpenConversation(UserHelper.getUserContactId(), { sharedDocuments: [poDocument] }).toPromise();
		return !!loConversation;
	}

	//#endregion DOCUMENTS

	//#endregion METHODS

}
