import { IPack } from "./ipack";

export class Pack implements IPack {

	//#region PROPERTIES

	/** @implements */
	public readonly _id: string;

	/** @implements */
	public readonly pluralLabel: string;

	/** @implements */
	public readonly singularLabel: string;

	/** @implements */
	public readonly icon: string;

	//#endregion

	//#region METHODS

	constructor(
		psId: string,
		psPluralLabel: string,
		psSingularLabel: string,
		psIcon: string
	) {
		this._id = psId;
		this.pluralLabel = psPluralLabel;
		this.singularLabel = psSingularLabel;
		this.icon = psIcon;
	}

	//#endregion

}