import { Injectable } from "@angular/core";
import { take } from "rxjs/operators";
import { EModalSize } from "../../../modal";
import { ModalService } from "../../../modal/services/modal.service";
import { IVisioService } from '../../models/IVisio.service';
import { JitsiModalComponent } from "../components/modal/jitsi-modal.component";

@Injectable()
export class JitsiService implements IVisioService {

	constructor(
		private readonly iscvModal: ModalService,
	) { }

	public showVisioModalAsync(psRoom?: string, psName?: string): Promise<void> {
		const loComponentParams: { name?: string, room?: string } = {
			name: psName,
			room: psRoom
		};

		return this.iscvModal.open({
			component: JitsiModalComponent,
			componentProps: loComponentParams
		}, EModalSize.screen)
			.pipe(take(1))
			.toPromise();
	}
}
