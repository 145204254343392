import { ObjectHelper } from '../../../../helpers/objectHelper';

export class DatabaseNotExistsError extends Error {

	constructor(psDatabaseId: string) {
		super(`La base de données ${psDatabaseId} n'existe pas !`);
		// Nécessaire pour que le 'instanceof DatabaseNotExistsError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, DatabaseNotExistsError);
	}

}