import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { DateHelper } from '../../../helpers/dateHelper';
import { NumberHelper } from '../../../helpers/numberHelper';
import { IAttachment } from '../../../model/mail/IAttachment';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { EStoreType } from '../../../model/store/EStoreType';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { CsvService } from '../../../services/csv.service';
import { Store } from '../../../services/store.service';
import { IBluetoothDevice } from '../bluetooth/models/IBluetoothDevice';
import { IRfidAcquisition } from '../devices/models/IRfidAcquisition';
import { EAcquisitionType } from '../models/EAcquisitionType';
import { RfidService } from '../rfid.service';
import { EQualityAlgorithm } from './models/EQualityAlgorithm';
import { IAcquisitionQualityAlgorithm } from './models/IAcquisitionQualityAlgorithm';
import { IReadingMailOptions } from './models/IReadingMailOptions';
import { IReadingQualityAlgorithm } from './models/IReadingQualityAlgorithm';
import { IStoreNominal } from './models/IStoreNominal';
import { SqalAlgorithm } from './models/SqalAlgorithm';
import { Timer } from './models/Timer';

@Injectable()
export class ReadingService {

	//#region FIELDS

	/** Pourcentage de qualité nécessaire à la lecture pour qu'elle soit considérée comme bonne. */
	private static readonly C_THRESHOLD_READING_QUALITY: number = 80;

	/** Données brutes d'acquisition. */
	private maAcquisitions: Array<IRfidAcquisition> = [];

	/** Algorithme utilisé pour évaluer la qualité d'une lecture et des acquisitions.
	 * L'évaluation de ces deux qualités pourra être dissociées facilement à l'avenir.
	 */
	private moQualityAlgo: IReadingQualityAlgorithm & IAcquisitionQualityAlgorithm;

	/** Stocke le nombre d'acquisition d'un code. */
	public counter = new Map<string, number>();

	public timer = new Timer();

	//#endregion

	//#region PROPERTIES

	public get acquisitions(): Array<IRfidAcquisition> {
		return this.maAcquisitions;
	}

	public get qualityAlgo(): IReadingQualityAlgorithm & IAcquisitionQualityAlgorithm {
		return this.moQualityAlgo;
	}

	/** Permet de calculer la durée d'une lecture. */
	private moTimer: Timer = new Timer();	// TODO #3666 Fonctionne en barcode uniquement, ajouter en RFID.

	/** Retourne le nombre total de scans. */
	public get numberOfScans(): number {
		if (!ArrayHelper.hasElements(this.acquisitions))
			return 0;
		else {
			let nbOfScans = 0;

			this.acquisitions.forEach((poValue: IRfidAcquisition): void => {
				nbOfScans += poValue.numberOfScans;
			});

			return nbOfScans;
		}
	}

	//#endregion


	//#region METHODS

	constructor(
		private isvcRfid: RfidService,
		private isvcCsv: CsvService,
		private store: Store
	) {

	}

	/** Se connecte à un appareil RFID. */
	public connect(psDeviceId: string): Observable<IBluetoothDevice> {
		return this.isvcRfid.connect(psDeviceId);
	}

	/** Ajoute une acquisition à la lecture courante.
	 * Il faut appeler detectChanges() après cette méthode.
	 */
	public addAcquisitions(paNewAcquisitions: IRfidAcquisition[]): void {
		// On éclate le tableau, sinon pas de détection de changement des composants enfants.
		for (let i = 0; i < paNewAcquisitions.length; i++)
			this.maAcquisitions = [...this.handleNewAcquisition(this.maAcquisitions, paNewAcquisitions[i])];

		this.updateQuality(this.maAcquisitions);
	}

	/** Ajoute une acquisition à la lecture courante.
	 * Il faut appeler detectChanges() après cette méthode.
	 */
	public addAcquisition(poNewAcquisition: IRfidAcquisition): void {
		// On éclate le tableau, sinon pas de détection de changement des composants enfants.
		this.maAcquisitions = [...this.handleNewAcquisition(this.maAcquisitions, poNewAcquisition)];

		this.updateQuality(this.maAcquisitions);
	}

	/** Ajoute l'acquisition aux acquisitions de la lecture. Ne met à jour le dbm que s'il est supérieur.
	 * @param {IRfidAcquisition} poNewAcq Nouvelle acquisition.
	 */
	private handleNewAcquisition(paAcquisitions: Array<IRfidAcquisition>, poNewAcq: IRfidAcquisition): Array<IRfidAcquisition> {
		const lnIndex: number = paAcquisitions.findIndex((poAcqArr: IRfidAcquisition) => poAcqArr.code === poNewAcq.code);

		if (lnIndex === -1) {
			paAcquisitions.unshift({
				code: poNewAcq.code,
				dbm: poNewAcq.dbm,
				numberOfScans: NumberHelper.isValid(poNewAcq.numberOfScans) ? poNewAcq.numberOfScans : 1,
				taken: poNewAcq.taken ? poNewAcq.taken : [new Date()]
			});
			this.counter.set(poNewAcq.code, paAcquisitions[0].numberOfScans);
		} else {
			// Acquisition déjà présente, on met à jour son dBm s'il est inférieur.
			paAcquisitions[lnIndex].dbm = (paAcquisitions[lnIndex].dbm > poNewAcq.dbm ? paAcquisitions[lnIndex].dbm : poNewAcq.dbm);
			paAcquisitions[lnIndex].numberOfScans += NumberHelper.isValid(poNewAcq.numberOfScans) ? poNewAcq.numberOfScans : 1;
			paAcquisitions[lnIndex].taken.push(...(poNewAcq.taken ? poNewAcq.taken : [new Date()]));
			this.counter.set(poNewAcq.code, paAcquisitions[lnIndex].numberOfScans);
		}

		return paAcquisitions;
	}

	/** Met à jour l'algorithme de qualité. */
	public updateQuality(paNewAcquisitions: IRfidAcquisition[]): void {
		if (this.hasQualityAlgorithm())
			this.moQualityAlgo.updateReading(paNewAcquisitions);
	}

	public changeQualityAlgorithm(peQuality: EQualityAlgorithm): void {
		switch (peQuality) {
			case EQualityAlgorithm.NONE:
				this.moQualityAlgo = undefined;
				break;

			case EQualityAlgorithm.SQAL:
				// TODO Lorsqu'on aura d'autres algorithmes de qualité, mettre à jour.
				if (!this.qualityAlgo)
					this.moQualityAlgo = new SqalAlgorithm(this.acquisitions);
				break;

			default:
				console.error("READ.S::Algorithme de qualité non pris en charge: ", peQuality);
		}
	}

	public hasQualityAlgorithm(): boolean {
		return !!this.moQualityAlgo;
	}

	/** Remet le service à son état initial. */
	public reload(): void {
		this.maAcquisitions = [];

		if (this.moQualityAlgo)
			this.moQualityAlgo = new SqalAlgorithm();

		this.counter = new Map<string, number>();

		this.timer = new Timer();
	}

	/** Génère une pièce-jointe au format csv qui comprend tous les éléments de la lecture. */
	public generateAcquisitionsAttachment(peType: EAcquisitionType, poMailOptions?: IReadingMailOptions): IAttachment {
		poMailOptions = this.setDefaultMailOptions(poMailOptions);

		return {
			name: poMailOptions.filename,
			content: this.isvcCsv.generate<IRfidAcquisition>(
				this.maAcquisitions,
				(poValue: IRfidAcquisition): string[] => {
					return this.getAttachmentBody(peType, poValue, poMailOptions);
				},
				this.getAttachmentHeaders(peType, poMailOptions)
			)
		};
	}

	/** Génère une pièce-jointe qui contient des données méta sur la lecture.
	 * @param poMetadata Objet contenant les méta-données à envoyer par mail.
	 */
	public generateMetaAttachment(poMetadata: any): IAttachment {
		return {
			name: "metadata.json",
			content: poMetadata
		};
	}

	/** !Nécessaire pour les tests, à supprimer dans MerchApp.
	 * Génère une pièce-jointe qui contient tous les temps de lecture.
	 */
	public generateTimeAttachment(): IAttachment {
		/** Ensemble de tous les temps d'acquisition, peu importe l'objet scanné. */
		const laTimes: Array<Date> = new Array<Date>();

		// Récupération de tous les temps.
		for (let index = 0; index < this.acquisitions.length; index++)
			laTimes.push(...this.acquisitions[index].taken);

		// Mis dans l'ordre d'acquisition.
		laTimes.sort((poDateA: Date, poDateB: Date): number => {
			return +new Date(poDateA) - +new Date(poDateB);
		});

		return {
			name: "time.csv",
			content: this.isvcCsv.generate(
				laTimes,
				(poValue: Date, pnIndex: number): string[] => {
					const loCurrent: Date = new Date(poValue);
					const lnDiff: number = pnIndex === 0 ? 0 : loCurrent.getTime() - new Date(laTimes[pnIndex - 1]).getTime();

					return [
						loCurrent.toISOString(),
						lnDiff.toLocaleString("fr-FR", {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2
						}).replace(/[^ -~]/g, ''),
						Math.floor(lnDiff * 0.001).toFixed(0),
					];
				},
				["Temps (ISO)", "Difference (ms)", "Categorie (s)"]
			),
		};
	}

	public getAcquisition(psCode: string): IRfidAcquisition {
		return this.acquisitions.find((poValue: IRfidAcquisition) => poValue.code === psCode);
	}

	/** Retourne les paramètres par défaut combinés aux options passées en paramètre. */
	private setDefaultMailOptions(poMailOptions?: IReadingMailOptions): IReadingMailOptions {
		let poResult = poMailOptions;

		if (!poResult)
			poResult = {};

		if (!poResult.filename)
			poResult.filename = "Lecture.csv";

		if (poResult.exportNumberOfScans === undefined)
			poResult.exportNumberOfScans = true;

		if (poResult.exportDateOfScans === undefined)
			poResult.exportDateOfScans = false;

		return poResult;
	}

	/** Retourne l'en-tête d'un fichier Csv pour une pièce-jointe. */
	private getAttachmentHeaders(peType: EAcquisitionType, poMailOptions: IReadingMailOptions): string[] {
		let laHeaders: string[] = [];

		switch (peType) {
			case EAcquisitionType.rfid:
				laHeaders = ["epc", "dBm"];

				if (!!poMailOptions.quality)
					laHeaders.push("qualite");

				if (!!poMailOptions.exportNumberOfScans)
					laHeaders.push("scans");

				break;
			case EAcquisitionType.barcode:
				laHeaders = ["Codes-a-barres"];

				if (!!poMailOptions.exportNumberOfScans)
					laHeaders.push("scans");

				break;
			default:
				console.error("READ.S::Type d'acquisition non supporté pour l'export.");
		}

		return laHeaders;
	}

	private getAttachmentBody(peType: EAcquisitionType, poValue: IRfidAcquisition, poMailOptions: IReadingMailOptions): string[] {
		let laBody: string[] = [];

		switch (peType) {
			case EAcquisitionType.rfid:
				laBody = [poValue.code, poValue.dbm ? poValue.dbm.toString() : ""];

				if (!!poMailOptions.quality)
					laBody.push(poMailOptions.quality.getAcquisitionQuality(poValue.code).toString());

				if (!!poMailOptions.exportNumberOfScans)
					laBody.push(poValue.numberOfScans.toString());

				break;
			case EAcquisitionType.barcode:
				laBody = [poValue.code];

				if (!!poMailOptions.exportNumberOfScans)
					laBody.push(poValue.numberOfScans.toString());

				if (poMailOptions.exportDateOfScans) {
					/** Date de la dernière acquisition. */
					const ldLast: Date = DateHelper.getMax(poValue.taken);
					laBody.push(`${ldLast.toLocaleTimeString()}.${ldLast.getMilliseconds()}`);
				}

				break;
			default:
				console.error("READ.S::Type d'acquisition non supporté pour l'export.");
		}

		return laBody;
	}

	public loadNominal(psId: string): Observable<IStoreNominal> {
		return this.store.get<IStoreNominal>({
			type: EStoreType.PouchDb,
			role: EDatabaseRole.workspace,
			viewParams: {
				key: psId
			},
			fields: ["type", "values"]
		})
			.pipe(map((paDocuments: IStoreNominal[]) => paDocuments[0]));
	}

	/** Charge un jeu de données qui permet de transformer un code en un message pour l'utilisateur. */
	public loadCodeData(poDatasource: IDataSource): Observable<IStoreDocument[]> {
		return this.store.get(poDatasource);
	}

	//#endregion

}