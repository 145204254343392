import { NgModule } from '@angular/core';
import { BluetoothModule } from '../bluetooth/bluetooth.module';
import { TslModule } from './tsl/tsl.module';

@NgModule({
	declarations: [],
	imports: [
		BluetoothModule, TslModule
	],
	providers: []
})
export class DevicesModule { }
