import { Observable, ReplaySubject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { ObjectHelper } from "../../../helpers/objectHelper";
import { IDestroyable } from "../../utils/lifecycle/models/IDestroyable";

export class ObservableProperty<T = any> {

	//#region FIELDS

	private readonly moSubject: ReplaySubject<T | undefined>;
	private readonly mfOnValueChanged?: (poValue: T) => void;

	private mbInitialized?: boolean;

	//#endregion

	//#region PROPERTIES

	private moValue?: T;
	public get value(): T | undefined { return this.moValue; }
	public set value(poNewValue: T) {
		if (!ObjectHelper.isDefined(this.moValue) && !ObjectHelper.isDefined(poNewValue) && !this.mbInitialized) {
			this.mbInitialized = true;
			this.moSubject.next(this.moValue = poNewValue);
		}
		else if (poNewValue !== this.moValue) {
			this.mbInitialized = true;
			this.moSubject.next(this.moValue = poNewValue);
			if (this.mfOnValueChanged)
				this.mfOnValueChanged(this.moValue);
		}
	}

	public get value$(): Observable<T | undefined> { return this.moSubject.asObservable(); }

	//#endregion

	//#region METHODS

	constructor(poInitialValue?: T, pfOnValueChanged?: (poValue: T) => void) {
		this.moSubject = new ReplaySubject<T>(1);
		if (ObjectHelper.isDefined(poInitialValue))
			this.moSubject.next(this.moValue = poInitialValue);

		this.mfOnValueChanged = pfOnValueChanged;
	}

	/** Lie les changements de l'instance avec le flux en paramètre.
	 * @param poNewValue$ Flux de la nouvelle valeur.
	 * @param poCaller Composant appelant.
	 */
	public bind(poNewValue$: Observable<T>, poCaller: IDestroyable): this {
		poNewValue$.pipe(
			tap((poRes: T) => this.value = poRes),
			takeUntil(poCaller.destroyed$)
		)
			.subscribe();

		return this;
	}

	//#endregion

}