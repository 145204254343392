<div *ngIf="(reversedLots.changes$ | async).length > 0" class="lots-list">
	<ng-container *ngFor="let lot of reversedLots.changes$ | async; index as lotIndex;">
		<ng-container *ngIf="lot.segments.length > 0">
			<div class="lot-space">
				<ion-text color="primary" class="ion-margin lot-name">
					LOT {{ (reversedLots.changes$ | async).length - lotIndex }}
				</ion-text>
				<ion-list>
					<ion-list-header lines="full">
						<ion-item lines="none" class="header">
							<ion-text color="primary">
								<div>Comptage</div>
							</ion-text>
							<ion-text slot="end" color="primary" class="quantity">
								<div>Quantité</div>
							</ion-text>
							<ion-button slot="end" class="end-button">
								<ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
							</ion-button>
						</ion-item>
					</ion-list-header>
					<calao-sliding-list-item #itemSliding
						*ngFor="let segment of lot.segments.slice().reverse(); index as segmentIndex" [contextualSlot]="true"
						class="ion-text-uppercase">
						<div>
							{{ segment.title }}
							{{ segment.subtitle }}
							{{ lot.segments.length - segmentIndex }}
						</div>
						<div slot="end" class="quantity">
							{{ segment.quantity }}
						</div>

						<calao-sliding-list-item-options>
							<ion-item-option color="medium" (click)="onEdit(lot, segment, itemSliding)">
								Recompter
							</ion-item-option>

							<ion-item-option color="danger" (click)="onRemove(lot, segment, itemSliding)">
								Supprimer
							</ion-item-option>
						</calao-sliding-list-item-options>

					</calao-sliding-list-item>

				</ion-list>
			</div>
		</ng-container>
	</ng-container>
</div>