import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ENetworkFlag } from '../../../model/application/ENetworkFlag';
import { IDmsTaskParams } from '../../../model/backgroundTask/taskParams/IDmsTaskParams';
import { TaskDescriptor } from '../../../services/backgroundTask/TaskDescriptor';
import { DmsTaskBase } from './DmsTaskBase';

export class DeleteDmsFileTask<T extends IDmsTaskParams> extends DmsTaskBase<T> {

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);
	}

	protected override execTask$(): Observable<Array<string>> {
		return this.msvcFlag.waitForFlag(ENetworkFlag.isOnlineReliable, true)
			.pipe(mergeMap(_ => this.msvcSyncDms.execPendingDelete(this.descriptor.params.fileGuid)));
	}

	//#endregion
}