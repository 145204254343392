import { Component, OnInit } from '@angular/core';
import { AlertButton, AlertInput } from '@ionic/core';
import { Observable, from } from 'rxjs';
import { filter, map, mapTo, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from '../../../services/interfaces/ShowMessageParamsToast';
import { LoadingService } from '../../../services/loading.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { CustomIonInputStringEvent } from '../../ionic/models/icustom-ion-input-event';
import { Loader } from '../../loading/Loader';
import { EBarcodeReaders } from '../barcode-reader/models/EBarcodeReaders';
import { BarcodeReaderService } from '../barcode-reader/services/barcode-reader.service';
import { BluetoothService } from '../bluetooth/bluetooth.service';
import { IBluetoothDevice } from '../bluetooth/models/IBluetoothDevice';
import { ETslType } from '../devices/models/ETslType';
import { TslService } from '../devices/tsl/tsl.service';

@Component({
	templateUrl: './diagnostics-page.component.html',
	styleUrls: ['./diagnostics-page.component.scss']
})
export class DiagnosticsLogisticsPage extends ComponentBase implements OnInit {

	//#region FIELDS

	/** Appareil configuré pour se connecter lorsqu'on clique sur le bouton "Connexion". */
	private static readonly TslDevice = "00:07:80:A8:EE:07";

	//#endregion

	//#region PROPERTIES

	private mnCipherlabSecondsDelay: number;
	/** Délai en secondes entre deux lectures de code-barre. */
	public get cipherlabSecondsDelay(): number { return this.mnCipherlabSecondsDelay; }

	public bluetoothDevice: IBluetoothDevice;
	/** Observable qui indique si le Bluetooth est activé/désactivé. */
	public bluetoothState$: Observable<boolean>;
	/** Type d'appareil connecté en bluetooth. `undefined` sauf si un appareil est connecté et que son type est connu. */
	public type: string;
	/** Durée pendant laquelle le lecteur de code-à-barres bloque la lecture d'un même code. */
	public blockingDuration: number;
	public fixedFocus: number;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcBluetooth: BluetoothService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcTsl: TslService,
		public readonly isvcBarcodeReader: BarcodeReaderService,
		private readonly isvcLoading: LoadingService
	) {
		super();

		this.mnCipherlabSecondsDelay = this.isvcBarcodeReader.delayBetweenCipherlabScansMs;
	}

	public ngOnInit(): void {
		this.initBluetooth();

		this.isvcBarcodeReader.scanditBlockingDuration$.pipe(
			tap((pnValue: number) => this.blockingDuration = pnValue),
			takeUntil(this.destroyed$)
		).subscribe();

		this.isvcBarcodeReader.scanditFocus$.pipe(
			tap((pnValue: number) => this.fixedFocus = pnValue),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	/** Met à jour les attributs de la classes concernant le Bluetooth. */
	private initBluetooth(): void {
		// Indique l'état du Bluetooth sur l'appareil.
		this.bluetoothState$ = this.isvcBluetooth.isEnabled();

		// Met à jour les appareils connectés dynamiquement.
		this.isvcBluetooth.getConnectedDevice$()
			.pipe(
				tap((poDevice: IBluetoothDevice) => this.bluetoothDevice = poDevice),
				filter((poDevice: IBluetoothDevice) => !!poDevice),
				mergeMap((poDevice: IBluetoothDevice) => this.updateType(poDevice.address))
			)
			.subscribe();
	}

	/** Clique sur le bouton pour voir les appareils appairés. */
	public onListBluetoothDevicesClicked(): void {
		this.isvcBluetooth.list()
			.pipe(
				tap((paDevices: IBluetoothDevice[]) => {
					const lsMessage: string = paDevices
						.map((poDevice: IBluetoothDevice): string => this.formatBluetoothDeviceHtml(poDevice))
						.reduce((psPrev: string, psCur: string): string => `${psPrev}<br/><br/>${psCur}`);

					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: lsMessage, header: "Appareils Bluetooth à portée" }));
				}),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	/** Quand l'utilisateur clique sur le bouton de connexion. */
	public onConnectClicked(): void {
		const loToastParams = new ShowMessageParamsToast({ header: "Connexion" });
		let loLoader: Loader;

		from(this.isvcLoading.create("Tentative de connexion ..."))
			.pipe(
				tap((poLoader: Loader) => loLoader = poLoader),
				mergeMap((poLoader: Loader) => poLoader.present()),
				mergeMap(_ => this.isvcTsl.connect(DiagnosticsLogisticsPage.TslDevice)),
				tap(
					(poDevice: IBluetoothDevice) => {
						console.log("LOGIS-DIAG.P::Connexion réussie...", poDevice);
						loToastParams.message = `Connexion réussie\n${poDevice.name}`;
						this.isvcUiMessage.showMessage(loToastParams);

						loLoader.dismiss();
						this.onDeviceConnected(DiagnosticsLogisticsPage.TslDevice);
					},
					(poError: any) => {
						console.error("LOGIS-DIAG.P::Connexion échouée...", poError);
						loToastParams.message = `Connexion échouée\n${poError}`;
						this.isvcUiMessage.showMessage(loToastParams);

						loLoader.dismiss();
					}
				),
				// takeUntil(this.destroyed$) Pas activé, car dès qu'on sort du composant, l'appareil RFID se déconnecte.
			)
			.subscribe();
	}

	/** Méthode appelée lorsqu'on se connecte à un appareil. */
	public onDeviceConnected(psAddress: string): void {
		this.updateType(psAddress).subscribe();
	}

	/** Regarde si le type de l'appareil bluetooth connecté correspond à un connu. */
	private updateType(psAddress: string): Observable<void> {
		return this.isvcBluetooth.list()
			.pipe(
				map((paDevices: IBluetoothDevice[]) => paDevices.find((poDevice: IBluetoothDevice) => poDevice.address === psAddress)),
				filter((poDevice: IBluetoothDevice) => !!poDevice),
				map((poDevice: IBluetoothDevice) => TslService.getTslType(poDevice.name)),
				tap((peType: ETslType) => this.type = peType),
				mapTo(undefined),
				takeUntil(this.destroyed$)
			);
	}

	/** Appelée lorsque l'utilisateur clique sur le bouton pour voir les fonctionnalités de l'inventaire. */
	public onInventoryFeaturesClicked(): void {
		this.isvcTsl.getInventoryParam()
			.pipe(
				tap(
					poResult => console.debug("LOGIS-DIAG.P::Récupération fonctionnalités matérielles", poResult),
					poError => console.error("LOGIS-DIAG.P::Erreur récupération fonctionnalités matérielles", poError)
				)
			)
			.subscribe();
	}

	private formatBluetoothDeviceHtml(poDevice: IBluetoothDevice): string {
		return `<b>${poDevice.name}</b><br/>${poDevice.address}<br/>${poDevice.id}`;
	}

	//#region BARCODE READER

	/** Fonction appelée lorsque l'utilisateur clique sur le bouton pour changer de lecteur de code-à-barres. */
	public onChangeBarcodeReaderClicked(): void {
		// Affichage d'une popup pour que l'utilisateur puisse choisir son lecteur de code-à-barres.
		this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				header: "Lecteur code-à-barres",
				message: "Choisir un lecteur",
				backdropDismiss: false,
				buttons: this.getOnChangeBarcodeReaderClickedButtons(),
			})
		)
			.pipe(
				tap(_ => console.debug("LOGIS-DIAG.P::Changement du lecteur de code-à-barres effectué.")),
				takeUntil(this.destroyed$)
			).subscribe();
	}

	private getOnChangeBarcodeReaderClickedButtons(): AlertButton[] {
		/** Méthode pour créer un bouton de lecteur. */
		const lfButtonCreation = (psText: string, peReader: EBarcodeReaders): AlertButton => {
			return {
				text: psText,
				handler: () => {
					console.debug(`LOGIS-DIAG.P::Sélection d'un nouveau lecteur de code-à-barres: ${psText}.`);
					if (peReader)
						this.isvcBarcodeReader.selectReaderAsync(peReader);
					return { response: !!peReader };
				}
			};
		};
		/** Tableau des buttons à afficher dans la modale. */
		const laButtons: AlertButton[] = [
			lfButtonCreation("Scandit", EBarcodeReaders.scandit), lfButtonCreation("Cordova", EBarcodeReaders.cordova),
			lfButtonCreation("Clavier", EBarcodeReaders.input),
			lfButtonCreation("Cipherlab SDK", EBarcodeReaders.cipherlab),
			lfButtonCreation("Annuler", undefined),
		];

		// On ajoute le TSL s'il est connecté par Bluetooth.
		if (this.bluetoothDevice && TslService.isTsl(this.bluetoothDevice.name))
			laButtons.splice(2, 0, lfButtonCreation("TSL", EBarcodeReaders.tsl));

		return laButtons;
	}

	public onCipherlabDelayChanged(poEvent: Event): void {
		this.isvcBarcodeReader.raiseNewDelayBetweenCipherlabScansMs(this.mnCipherlabSecondsDelay = (+(poEvent as CustomIonInputStringEvent).detail.value * 1000));
	}

	public setFixedFocus(): void {
		/** Méthode qui permet de créer un bouton de durée. */
		const lfButtonCreation = (pnDuration: number): AlertInput => {
			return {
				type: "radio",
				label: pnDuration === undefined ? "Pas de focus fixe" : `${pnDuration}`,
				value: pnDuration,
				checked: pnDuration === this.fixedFocus,
				handler: (poInput) => { lnFocus = poInput.value; }
			};
		};
		const loPopupParams = new ShowMessageParamsPopup({
			header: "Scandit Focus",
			message: "Choisir la valeur de blocage du focus.",
			backdropDismiss: true,
			inputs: [lfButtonCreation(undefined), lfButtonCreation(0.2), lfButtonCreation(0.4), lfButtonCreation(0.6), lfButtonCreation(1), lfButtonCreation(10), lfButtonCreation(15)]
		});
		/** Valeur qui sera envoyé au service de gestion des lecteurs code-barres. */
		let lnFocus: number;

		// Affichage d'une popup pour que l'utilisateur puisse choisir son lecteur de code-à-barres.
		this.isvcUiMessage.showAsyncMessage(loPopupParams)
			.pipe(
				tap(_ => this.isvcBarcodeReader.setFocus(lnFocus)),
				takeUntil(this.destroyed$)
			)
			.subscribe();
	}

	//#endregion

	//#endregion

}
