<ion-list class="marge-btm-list" *ngIf="hasElements">
	<!-- Chaque groupe -->
	<ion-item-group *ngFor="let item of linksByCategory | keyvalue;">

		<!-- Chaque division des types -->
		<ion-item-divider *ngIf="item.value?.length > 0 && !params.hideCategories">
			<!-- Nom du type -->
			<ion-label class="txt-left txt24px ion-no-padding">{{ item.key }}</ion-label>
		</ion-item-divider>

		<!-- Chaque membre du groupe courant -->
		<ion-item *ngFor="let link of item.value" (click)="goToItem(link)" class="linked-items">
			<ion-label>{{ getName(link) }}</ion-label>
		</ion-item>

	</ion-item-group>
</ion-list>

<div *ngIf="!hasElements"
	style="display: flex; align-items: center; justify-content: center; min-height: 100%; font-size: 1.6rem;">
	<span class="dis-flex">
		<ion-icon style="margin: auto; margin-right: 1rem;" name="information-circle"></ion-icon>
		<ion-label>Aucune information liée</ion-label>
	</span>
</div>