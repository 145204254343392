import { Component, OnDestroy } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, mergeMap, tap, toArray } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { EntityHelper } from '../../../helpers/entityHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { UserData } from '../../../model/application/UserData';
import { IContact } from '../../../model/contacts/IContact';
import { IEntitySelectorParams } from '../../../model/entities/IEntitySelectorParams';
import { NoCurrentUserDataError } from '../../../model/errors/NoCurrentUserDataError';
import { ISelectorValue } from '../../../model/selector/ISelectorValue';
import { SelectorWrapperComponentBase } from '../../../model/selector/SelectorWrapperComponentBase';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { IEntity } from '../../../modules/entities/models/ientity';
import { EntitiesService } from '../../../modules/entities/services/entities.service';
import { ContactsService } from '../../../services/contacts.service';
import { EntityLinkService } from '../../../services/entityLink.service';
import { Store } from '../../../services/store.service';

@Component({
	selector: 'osapp-entity-selector',
	templateUrl: './entitySelector.component.html',
	styleUrls: ['./entitySelector.component.scss']
})
export class EntitySelectorComponent extends SelectorWrapperComponentBase<IEntity, IEntitySelectorParams> implements OnDestroy {

	//#region METHODS

	constructor(
		private isvcStore: Store,
		private isvcEntityLink: EntityLinkService,
		private isvcEntities: EntitiesService,
		private isvcContacts: ContactsService) {

		super();
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		if (this.params && this.params.entitySelectionSubject)
			this.params.entitySelectionSubject.complete();
	}

	protected override initSearchOptions(): void {
		if (this.params.hasSearchbox) {
			if (!this.params.searchOptions) {
				this.params.searchOptions = {
					hasPreFillData: true,
					isSearchPanelEnable: false,
					searchFunction: (poEntity: ISelectorValue<IEntity>, psSearchValue: string) =>
						poEntity.displayValue.toLowerCase().indexOf(psSearchValue.toLowerCase()) >= 0,
					searchboxPlaceholder: "Rechercher",
				};
			}
		}
	}

	protected override initValues(): void {
		let loGetValues$: Observable<IStoreDocument[]>;

		// TODO Rendre le composant générique.
		if (typeof this.params.entityDataSource.viewParams.startkey === "string" && ContactsService.isContact(this.params.entityDataSource.viewParams.startkey)) {
			if (UserData.current) {
				loGetValues$ = this.isvcContacts.getContactsByPrefix()
					.pipe(map((paContacts: IContact[]) => paContacts.filter((poContact: IContact) => !UserHelper.isCurrentUserContact(poContact))));
			}
			else
				loGetValues$ = throwError(new NoCurrentUserDataError());
		}
		else
			loGetValues$ = this.isvcStore.get(this.params.entityDataSource);

		loGetValues$
			.pipe(
				mergeMap((paResults: IEntity[]) => paResults),
				map((poResult: IEntity) => {
					return {
						value: poResult,
						displayValue: this.getDisplayValue(poResult),
						isSelected: ArrayHelper.hasElements(this.params.preSelectedIds) &&
							this.params.preSelectedIds?.some((psPreselectedId: string) => {
								const laEntityIds: string[] = EntityHelper.getIdsFromLinkId(psPreselectedId);

								return poResult._id === laEntityIds[0] || poResult._id === laEntityIds[1];
							})
					} as ISelectorValue<IEntity>;
				}),
				toArray(),
				tap((paEntities: ISelectorValue<IEntity>[]) => this.values = paEntities)
			)
			.subscribe();
	}

	protected override getDisplayValue(poValue: IEntity): string {
		return this.isvcEntities.getEntityName(poValue);
	}

	public override onSelectionValidated(paSelectedValues: ISelectorValue<IEntity>[]): void {

		if (this.params.model) { // Si le modèle est renseigné, on peut ajouter/supprimer des liens.
			this.getSelectorValuesForLinksPersistance(paSelectedValues).forEach((poSelectedValue: ISelectorValue<IEntity>) => {
				if (poSelectedValue.isSelected)
					this.isvcEntityLink.cacheLinkToAdd(this.params.model, poSelectedValue.value);
				else
					this.isvcEntityLink.cacheLinkToRemove(this.params.model, poSelectedValue.value);
			});
		}

		this.params.entitySelectionSubject.next(this.getValuesFromSelectorValues(paSelectedValues));
	}

	/** Permet de récupérer les valeurs du sélecteur à utiliser pour la persistances des liens.
	 * @param paSelectedValues Valeurs sélectionnées dans le sélecteur.
	 */
	private getSelectorValuesForLinksPersistance(paSelectedValues: ISelectorValue<IEntity>[]): ISelectorValue<IEntity>[] {
		// Si il n'y a pas d'anciennes valeurs sélectionnées alors on retourne le tableau de valeurs reçues du sélecteur.
		if (!ArrayHelper.hasElements(this.params.preSelectedIds))
			return paSelectedValues;

		// On récupère les nouvelles valeurs sélectionnées.
		const laSelectedValues: ISelectorValue<IEntity>[] = paSelectedValues.filter((poValue: ISelectorValue<IEntity>) =>
			this.params.preSelectedIds?.some((psPreselectedId: string) => psPreselectedId.indexOf(poValue.value._id) < 0) &&
			poValue.isSelected
		);

		// On récupère les valeurs supprimées par rapport aux anciennes valeurs.
		const laRemovedValues: ISelectorValue<IEntity>[] = this.values.filter((poValue: ISelectorValue<IEntity>) =>
			this.params.preSelectedIds?.some((psPreselectedId: string) => psPreselectedId.indexOf(poValue.value._id) >= 0) &&
			!poValue.isSelected
		);

		// On retourne la concaténation des 2 tableaux.
		return [...laSelectedValues, ...laRemovedValues];
	}

	//#endregion
}