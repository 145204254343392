import { IAuthentication } from '@calaosoft/osapp/model/IAuthentication';
import { IAppInfo } from '@calaosoft/osapp/model/application/IAppInfo';
import { EAuthenticationAction } from '@calaosoft/osapp/model/authenticator/EAuthenticationAction';
import { IBackgroundTask } from '@calaosoft/osapp/model/backgroundTask/IBackgroundTask';
import { EFormEngine } from '@calaosoft/osapp/model/forms/EFormEngine';
import { Credentials } from '@calaosoft/osapp/model/security/Credentials';
import { EUpdateProvider } from '@calaosoft/osapp/model/update/EUpdateProvider';
import { IUpdateConfig } from '@calaosoft/osapp/model/update/IUpdateConfig';
import { TaskService } from '@calaosoft/osapp/modules/logistics/task/services/task.service';
import { C_ADMINISTRATORS_ROLE_ID } from '@calaosoft/osapp/modules/permissions/services/permissions.service';
import { AppointmentService } from '@calaosoft/osapp/modules/tour/appointment/appointment.service';
import { ApplicationService } from '@calaosoft/osapp/services/application.service';
import { DomainService } from '@calaosoft/osapp/services/domain.service';
import { ISecurityConfig } from '@calaosoft/osapp/services/security/ISecurityConfig';
import { templateSettings } from 'lodash';
import { CUSTOMER_CONTACT_PERMISSION } from './app.constants';
import { APP_CORE_DATABASE_PREFIX, APP_ID, environment } from './environments/environment';

export * from './environments/environment';

templateSettings.imports = {
	...templateSettings.imports,
	AppointmentService: AppointmentService,
	TaskService: TaskService,
};

const defaultHearbeatIntervalMilliseconds = 60000;

export const appInfo: IAppInfo = {
	appId: APP_ID,
	formEngine: EFormEngine.formly,
	deviceId: "123",
	appName: "MerchApp",
	supportEmail: "support@calaosoft.fr",
	firstName: "",
	lastName: "",
	login: "",
	applicationDatabaseId: `${APP_CORE_DATABASE_PREFIX}local_application_storage`,
	applicationServiceProvider: { provide: ApplicationService },
	domainServiceProvider: { provide: DomainService },
	useLinks: true,
	unlockRequired: environment.unlockRequired,
	coreDatabasePrefix: APP_CORE_DATABASE_PREFIX,
	changelogsUrl: "https://install.mobile.calaosoft.fr/public/merchapp/changelog.html"
};

export const authentication: IAuthentication = {
	type: "disable",
	hearbeatIntervalMilliseconds: defaultHearbeatIntervalMilliseconds,
	appCredentials: environment.appCredentials!,
	credentials: new Credentials(),
	authenticatorParams: {
		_id: "",
		loginEditEnabled: true,
		newAccountEnabled: false,
		passwordForgottenEnabled: false,
		authenticationAction: EAuthenticationAction.route,
		imgEnabled: true,
		backgroundStyles: "auth",
		login: "",
		password: "",
		thumbnailPicture: environment.thumbnailLogo,
		thumbnailIcon: environment.thumbnailLogo,
		pinEnabled: true,
		initUserContactAfterAuth: false,
		destinationPathAfterAuth: "home"
	}
};

export const update: IUpdateConfig = {
	android: {
		updateCheckIntervalMinutes: 30,
		updateProvider: EUpdateProvider.calaoStore,
		updatesApiUrl: `${environment.cloud_url}${environment.cloud_api_apps_suffix}updates`
	},
	ios: {
		updateCheckIntervalMinutes: 30,
		updateProvider: EUpdateProvider.calaoStore,
		updatesApiUrl: `${environment.cloud_url}${environment.cloud_api_apps_suffix}updates`,
	}
};

export const backgroundTask: IBackgroundTask = {
	execTasksIntervalMs: 30000,
	maxRepeatTask: 10
};

export const security: ISecurityConfig = {
	builtInRoles: {
		administrators: {
			id: C_ADMINISTRATORS_ROLE_ID,
			label: "Administrateur"
		}
	},
	permissions: {
		default: {
			contacts: {
				create: true,
				edit: true,
				delete: false,
				read: true,
				[CUSTOMER_CONTACT_PERMISSION]: {
					create: true,
					edit: true,
					delete: false,
					read: true
				}
			},
			conversations: {
				create: true,
				edit: true,
				delete: false,
				read: true
			}
		},
		[C_ADMINISTRATORS_ROLE_ID]: {
			contacts: {
				create: true,
				edit: true,
				delete: true,
				read: true
			},
			conversations: {
				create: true,
				edit: true,
				delete: true,
				read: true
			}
		}
	}
};

export const builtInEntityDescIds: string[] = [
	"customer",
	"task",
	"customerContact"
];