import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SqlDataSource } from "../../models/sql-data-source";

@Injectable()
export abstract class SqlRemoteProvider {

	//#region METHODS

	public constructor(protected readonly ioHttpClient: HttpClient) { }

	/** Il peut exister plusieurs remote providers pour une même application (ex: Merchapp: Stock, Catalog).
	 * `canProcess` permet de ségréguer les types de base de données qui sont gérés par ce provider en fonction de l'identifiant de base de données.
	 */
	public abstract canProcess(psDatabaseId: string): boolean;

	/** Retourne l'url de la dernière version d'une base de données. */
	public abstract getLastUrl(psDatabaseId: string): string;

	/** Retourne l'url de la base de données avec une version. */
	public abstract getUrl(psDatabaseId: string, pnVersion: number): string;

	/** Retourne la base de données la plus récente.
	 * @param psDatabaseId Identifiant de la base de données.
	 */
	public abstract getLastDatabaseAsync(psDatabaseId: string): Promise<SqlDataSource>;

	//#endregion METHODS

}