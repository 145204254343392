<ion-grid class="reading-area" (click)="readAsync()">
	<ion-row class="infos-area">
		<ion-col>
			<div [class]="!showHeaderArrowValue ? 'empty-barcode-area' : 'arrowed-barcode-area'"
				[ngClass]="{'arrowed-barcode-area-warning': !isCounting, 'reader-color-danger' : (isBarcodeValid$ | async) === false }">
				<div class="reader-color">
					<ion-icon class="barcode" name="barcode-counting"></ion-icon>
					<ion-label class="ref-txt-num">{{ lastReadBarcode ? lastReadBarcode : "" }}</ion-label>
					<ion-note>{{ middleBarcodeTitle }}</ion-note>
				</div>
				<div class="top-arrowed-barcode-area">
					<div *ngIf="showHeaderArrowValue" class="content-arrowed-barcode">
						<div *ngIf="topBarcodeTitle" class="top-part">
							<div class="txt-area top-txt">
								<ion-note>{{ itemCount?.value$ | async }}</ion-note>
								<ion-label>{{ topBarcodeTitle }}</ion-label>
							</div>
						</div>
						<div *ngIf="bottomBarcodeTitle" class="bottom-part">
							<div class="txt-area top-txt">
								<ion-label>{{ bottomBarcodeTitle }}</ion-label>
								<ion-note>{{ lastBarcodeCount?.value$ | async }}</ion-note>
							</div>
						</div>
					</div>
					<div class="top-arrowed-barcode-round"></div>
				</div>
			</div>
		</ion-col>
	</ion-row>

	<!-- Ligne des boutons de lecture. -->
	<ion-row class="buttons-area" *ngIf="displayCountingButtons">
		<ion-col size="4" class="ion-align-self-end">
			<ng-container *ngTemplateOutlet="notCountingButton"></ng-container>
		</ion-col>
		<ion-col size="4" class="play-txt">
		</ion-col>
		<ion-col size="4">
			<ng-container *ngTemplateOutlet="countingButton"></ng-container>
		</ion-col>
	</ion-row>
</ion-grid>

<!-- Template du bouton "décompter". -->
<ng-template #notCountingButton>
	<ion-fab>
		<ion-fab-button color="alert" (click)="onSelectCountingType($event, false)">
			<ion-icon name="remove-circle-outline"></ion-icon>
		</ion-fab-button>
	</ion-fab>
	<ion-label class="orange-label">Décompter</ion-label>
</ng-template>

<!-- Template du bouton "compter". -->
<ng-template #countingButton>
	<ion-fab>
		<ion-fab-button color="success" (click)="onSelectCountingType($event, true)">
			<ion-icon name="add-circle-outline"></ion-icon>
		</ion-fab-button>
	</ion-fab>
	<ion-label class="green-label">Compter</ion-label>
</ng-template>