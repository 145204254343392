import { Component, OnDestroy } from '@angular/core';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IEntityTypeSelectorParams } from '../../../model/entities/IEntityTypeSelectorParams';
import { ISelectorValue } from '../../../model/selector/ISelectorValue';
import { SelectorWrapperComponentBase } from '../../../model/selector/SelectorWrapperComponentBase';
import { IEntityDescriptor } from '../../../modules/entities/models/ientity-descriptor';

@Component({
	selector: "osapp-entity-type-selector",
	templateUrl: './entityTypeSelector.component.html',
	styleUrls: ['./entityTypeSelector.component.scss']
})
export class EntityTypeSelectorComponent extends SelectorWrapperComponentBase<IEntityDescriptor, IEntityTypeSelectorParams> implements OnDestroy {

	//#region METHODS

	constructor() {
		super();
	}

	protected override initSearchOptions(): void { }

	protected override initValues(): void {
		const laEntityDescriptors: IEntityDescriptor[] = this.params.entityDescriptors;
		this.values = laEntityDescriptors.map((poEntityDescriptor: IEntityDescriptor) => ({
			value: poEntityDescriptor,
			displayValue: this.getDisplayValue(poEntityDescriptor),
			isSelected: false
		} as ISelectorValue<IEntityDescriptor>));
	}

	public override ngOnDestroy(): void {
		if (this.params && this.params.entityTypeSelectionSubject)
			this.params.entityTypeSelectionSubject.complete();
	}

	protected getDisplayValue(poValue: IEntityDescriptor): string {
		return poValue.category.name;
	}

	public onSelectionValidated(paSelectedValues: ISelectorValue<IEntityDescriptor>[]): void {
		if (this.params && this.params.entityTypeSelectionSubject)
			this.params.entityTypeSelectionSubject.next(ArrayHelper.getFirstElement(this.getValuesFromSelectorValues(paSelectedValues)));
	}

	//#endregion

}