import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ISettingSelectOption } from '../../../settings/models/isetting-select-option';
import { ISettingSelectParams } from '../../../settings/models/isetting-select-params';
import { BarcodeReaderService } from '../../barcode-reader/services/barcode-reader.service';
import { BarecodeReaderSettings } from '../models/barecode-reader-settings';
import { EAccuracyType } from '../models/eaccuracy-type';
import { LogisticsSettings } from '../models/logistics-settings';

@Component({
	selector: 'calao-logistics-settings',
	templateUrl: './logistics-settings.component.html',
	styleUrls: ['./logistics-settings.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush

})
export class LogisticsSettingsComponent extends ComponentBase {

	//#region PROPERTIES

	/** Notifie que les paramètres ont changés. */
	@Output("onChange") private readonly moSettingsChanged = new EventEmitter<LogisticsSettings>();

	private moSettings = new LogisticsSettings();
	/** Paramètres */
	@Input() public set settings(poSettings: LogisticsSettings | undefined) {
		if (poSettings && poSettings !== this.moSettings)
			this.moSettings = poSettings;
		this.init();
	}

	private moBarcodeReaderModeParams: ISettingSelectParams;
	/** Paramètres pour la sélection du mode de lecture du lecteur de code-barres */
	public get barcodeReaderModeParams(): ISettingSelectParams { return this.moBarcodeReaderModeParams; }

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcBarcodeReader: BarcodeReaderService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poChangeDetectorRef);
	}

	private init(): void {
		this.setBarcodeReaderModeParams(this.moSettings.barcodeReader);
		this.detectChanges();
	}

	public onAccurancyChangedAsync(poAccurancyOption: ISettingSelectOption): Promise<void> {
		this.moSettings.barcodeReader.accuracy = poAccurancyOption.id as EAccuracyType;
		this.moSettingsChanged.emit(this.moSettings);

		return this.isvcBarcodeReader.setAccuracyAsync(this.moSettings.barcodeReader.accuracy);
	}

	private setBarcodeReaderModeParams(poBarcodeReader: BarecodeReaderSettings): void {
		const laOptions: ISettingSelectOption[] = [{ id: EAccuracyType.crosshair, text: "Précis" }, { id: EAccuracyType.fullSquare, text: "Large" }];

		this.moBarcodeReaderModeParams = {
			label: "Précision lecteur",
			values: laOptions,
			defaultValue: laOptions.find((poSelectedOption: ISettingSelectOption) => poSelectedOption.id === poBarcodeReader.accuracy)
		};
	}

	//#endregion METHODS

}