import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { SuperTabsModule } from '@ionic-super-tabs/angular';
import { IonicModule } from '@ionic/angular';
import { CalaoAvatarModule } from '../../modules/avatar/calao-avatar.module';
import { GesturesModule } from '../../modules/gestures/gestures.module';
import { LiveModule } from "../../modules/live/live.module";
import { RoutingModule } from '../../modules/routing/routing.module';
import { SpinnerModule } from '../../modules/spinner/spinner.module';
import { VirtualScrollModule } from '../../modules/virtual-scroll/virtual-scroll.module';
import { PipeModule } from '../../pipes/pipes.module';
import { ContactsService } from '../../services/contacts.service';
import { GalleryModule } from '../gallery/gallery.module';
import { SearchModule } from '../search/search.module';
import { ToolbarModule } from '../toolbar/toolbar.module';
import { AttachmentsPopoverComponent } from './attachmentsPopover/attachmentsPopover.component';
import { ConversationComponent } from './conversation.component';
import { ConversationEditComponent } from './edit/conversationEdit.component';
import { CanLeaveConversationEdit } from './edit/guards/canLeaveConversationEdit.guard';
import { ConversationsListComponent } from './list/conversations-list.component';
import { ConversationTabComponent } from './tab/conversation-tab.component';

const modules: (Type<any> | ModuleWithProviders<any>)[] = [
	IonicModule,
	CommonModule,
	ToolbarModule,
	PipeModule,
	CalaoAvatarModule,
	GalleryModule,
	FormsModule,
	SearchModule,
	MatBadgeModule,
	GesturesModule.forRoot({ longPress: { time: 1000 } }),
	SpinnerModule,
	SuperTabsModule,
	VirtualScrollModule,
	LiveModule,
	RoutingModule.forRoot({ defaultRoute: "conversations" })
];
const components: Type<any>[] = [
	ConversationComponent,
	ConversationsListComponent,
	ConversationEditComponent,
	ConversationTabComponent,
	AttachmentsPopoverComponent
];
const providers: Provider[] = [
	ContactsService,
	CanLeaveConversationEdit
];

/** Module de gestion des conversations. */
@NgModule({
	imports: modules,
	exports: components,
	declarations: components,
	providers: providers
})
export class ConversationModule { }