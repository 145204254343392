import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { ChangeTrackingFromIdbToSqlitePatch } from './patches/change-tracking-from-idb-to-sqlite-patch';
import { PatchBase } from './patches/patch-base';
import { Sqlite2ToSqliteEvcoreExtbuildFreePatch } from './patches/sqlite2-to-sqlite-evcore-extbuild-free-patch';
import { PATCHES_CONFIG, PatchService } from './services/patch.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [
		PatchService,
		Sqlite2ToSqliteEvcoreExtbuildFreePatch,
		ChangeTrackingFromIdbToSqlitePatch
	]
})
export class PatchModule {

	//#region METHODS

	public static forRoot(paClassPatches: Type<PatchBase>[]): ModuleWithProviders<PatchModule> {
		return {
			ngModule: PatchModule,
			providers: [
				{ provide: PATCHES_CONFIG, useValue: paClassPatches }
			]
		};
	}

	//#endregion

}