import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { EModalSize } from '../../../modal/model/EModalSize';
import { ModalService } from '../../../modal/services/modal.service';
import { EDatabaseSyncStatus } from '../../../store/model/EDatabaseSyncStatus';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';
import { DatabaseSynchroService } from '../../services/database-synchro.service';
import { DatabasesSyncStatusModalComponent } from '../databases-sync-status-modal/databases-sync-status-modal.component';

@Component({
	selector: 'calao-database-sync-status-icon',
	templateUrl: './database-sync-status-icon.component.html',
	styleUrls: ['./database-sync-status-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatabaseSyncStatusIconComponent extends DestroyableComponentBase {

	//#region PROPERTIES

	public readonly databaseSyncStatus$: Observable<EDatabaseSyncStatus>;
	public readonly isAnimating$: Observable<boolean>;
	public readonly iconName$: Observable<string>;

	//#endregion

	//#region METHODS

	constructor(private readonly isvcModal: ModalService, psvcDatabaseSynchro: DatabaseSynchroService) {
		super();

		this.databaseSyncStatus$ = this.getDatabaseSyncStatus$(psvcDatabaseSynchro).pipe(secure(this));
		this.isAnimating$ = this.getIsAnimating$().pipe(secure(this));
		this.iconName$ = this.getIconName$().pipe(secure(this));
	}

	private getDatabaseSyncStatus$(psvcDatabaseSynchro: DatabaseSynchroService): Observable<EDatabaseSyncStatus> {
		return psvcDatabaseSynchro.getDatabasesCommonStatus$()
			.pipe(
				distinctUntilChanged(),
				debounceTime(500)
			);
	}

	private getIsAnimating$(): Observable<boolean> {
		return this.databaseSyncStatus$.pipe(
			map((peDatabaseSyncStatus: EDatabaseSyncStatus) => peDatabaseSyncStatus === EDatabaseSyncStatus.localToServer || peDatabaseSyncStatus === EDatabaseSyncStatus.serverToLocal)
		);
	}

	private getIconName$(): Observable<string> {
		return this.databaseSyncStatus$.pipe(
			map((peDatabaseSyncStatus: EDatabaseSyncStatus) => DatabaseSynchroService.getSyncIcon(peDatabaseSyncStatus))
		);
	}

	public showSyncStatusModalAsync(): void {
		this.isvcModal.open<boolean>(
			{ component: DatabasesSyncStatusModalComponent },
			EModalSize.medium
		)
			.pipe(takeUntil(this.destroyed$))
			.toPromise();
	}

	//#endregion

}