import { Injectable } from '@angular/core';
import { BarcodeScanner, ScanResult, StopScanOptions } from '@capacitor-community/barcode-scanner';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { StringHelper } from '../../../../helpers/stringHelper';
import { PlatformService } from '../../../../services/platform.service';
import { PageManagerService } from '../../../routing/services/pageManager.service';
import { IBarcodeAcquisition } from '../../devices/models/ibarcode-acquisition';
import { IBarcodeReader } from '../models/IBarcodeReader';

const C_APP_ROOT_QUERY_SELECTOR = "app-root";
const C_GUIDELINE_HTML_ID = "android-barcode-scanner-guideline";

@Injectable()
export class CordovaBarcodeReaderService implements IBarcodeReader {

	//#region FIELDS

	private readonly moBarcodeReadedSubject = new Subject<ScanResult>();

	//#endregion

	//#region METHODS

	public constructor(private readonly isvcPlatform: PlatformService, private readonly isvcPageManager: PageManagerService) { }

	/** @implements */
	public initializeBarcode(): void {
		// Pas d'initialisation nécessaire pour le plugin "BarcodeScanner".
	}

	/** @implements */
	public readBarcode(): void {
		let loSubscription: Subscription;
		BarcodeScanner.checkPermission({ force: true })
			.then(() => {
				loSubscription = this.isvcPlatform.getBackButtonSubscription(() => BarcodeScanner.stopScan({ resolveScan: true } as StopScanOptions));
				this.changeApplicationDisplay("none");
				this.displayGuideline();

				return BarcodeScanner.startScan();
			})
			.then((poBarcodeResult?: ScanResult) => {
				if (poBarcodeResult.hasContent)
					this.moBarcodeReadedSubject.next(poBarcodeResult);
			})
			.finally(() => {
				// On supprime le viseur
				document.getElementById(C_GUIDELINE_HTML_ID).remove();
				this.changeApplicationDisplay("block");
				loSubscription.unsubscribe();
			});
	}

	/** Construit puis affiche une ligne rouge qui servira de guide pour viser le code-barres */
	private displayGuideline(): void {
		const loBarcodeGuideline: HTMLDivElement = document.createElement("div");
		loBarcodeGuideline.setAttribute("id", C_GUIDELINE_HTML_ID);
		document.querySelector("body").appendChild(loBarcodeGuideline);
	}

	private changeApplicationDisplay(psDisplay: "block" | "none"): void {
		(document.querySelector(C_APP_ROOT_QUERY_SELECTOR) as HTMLElement).style.display = psDisplay;
	}

	/** @implements */
	public onBarcodeReaded(): Observable<IBarcodeAcquisition[]> {
		return this.moBarcodeReadedSubject.asObservable().pipe(
			tap(
				(poBarcodeResult: ScanResult) => console.debug("CORD-BARCODE.S::Code-à-barres lues ", [poBarcodeResult.content]),
				poError => console.error("CORD-BARCODE.S::Erreur scan code-barres :", poError)
			),
			map((poBarcodeResult: ScanResult): IBarcodeAcquisition[] => {
				// Si l'utilisateur fait retour dans la modale poBarcodeResult.text = "".
				if (StringHelper.isValid(poBarcodeResult.content))
					return [{ code: poBarcodeResult.content, numberOfScans: 1, taken: [new Date] }];
				else
					return [];
			})
		);
	}

	//#endregion
}