import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { ModalController } from '@ionic/angular';
import { AlertButton, OverlayEventDetail } from '@ionic/core';
import { EMPTY, Observable, concat, defer, from, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { ReverseGeocodingModalComponent } from '../components/geolocation/reverse-geocoding-modal/reverse-geocoding-modal.component';
import { ArrayHelper } from '../helpers/arrayHelper';
import { NumberHelper } from '../helpers/numberHelper';
import { StringHelper } from '../helpers/stringHelper';
import { ConfigData } from '../model/config/ConfigData';
import { IContact } from '../model/contacts/IContact';
import { EGeoApiPositionError } from '../model/navigation/EGeoApiPositionError';
import { ENavigationType } from '../model/navigation/ENavigationType';
import { IGeoApiResponse } from '../model/navigation/IGeoApiResponse';
import { IGeoApiResponseAddress } from '../model/navigation/IGeoApiResponseAddress';
import { IGeolocData } from '../model/navigation/IGeolocData';
import { IReverseGeocodingData } from '../model/navigation/IReverseGeocodingData';
import { NotImplementedNavigationError } from '../model/navigation/NotImplementedNavigationError';
import { IUiButton } from '../model/uiMessage/IUiButton';
import { IUiResponse } from '../model/uiMessage/IUiResponse';
import { AuthenticatedRequestOptionBuilder } from '../modules/api/models/authenticated-request-option-builder';
import { IGeolocationCoordinates } from '../modules/navigation/models/igeolocation-coordinates';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { LoadingService } from './loading.service';
import { NetworkService } from './network.service';
import { PlatformService } from './platform.service';
import { UiMessageService } from './uiMessage.service';

interface INavigationUrls {
	googleUrl: string;
	gouvUrl: string;
	wazeUrl: string;
	wazeMarketAndroid: string;
	wazeMarketIos: string;
	wazeSchemeAndroid: string;
}

interface IHttpRequestOptions {
	responseType: "json";
	timeout: number;
}

interface INavigationConfig {
	typeMap: ENavigationType;
	googleKey: string;
}

interface ModelGeolocData {
	street: string,
	zipCode: string,
	city: string,
	latitude: number,
	longitude: number
}

/** Service permettant la récupération des données de navigation. */
@Injectable({ providedIn: "root" })
export class NavigationService {

	//#region FIELDS

	/** Objet qui définit le timeout pour la recherche de localisation et le temps maximum de la localisation à garder en cache avant de lancer une nouvelle localisation. */
	private static readonly C_GEOLOCATION_OPTIONS: PositionOptions = {
		enableHighAccuracy: true,
		maximumAge: 300000,
		timeout: 10000
	};
	/** Objet contenant les URLs de base pour le service de navigation. */
	private static readonly C_NAVIGATION_URLS: INavigationUrls = {
		googleUrl: "https://maps.googleapis.com/maps/api/geocode/json?",
		gouvUrl: "https://api-adresse.data.gouv.fr/",
		wazeUrl: "https://waze.com/ul?",
		wazeMarketAndroid: "market://details?id=com.waze",
		wazeMarketIos: "http://itunes.apple.com/fr/app/id323229106",
		wazeSchemeAndroid: "com.waze"
	};
	/** Options pour les requêtes http get du service. */
	private static readonly C_HTTP_REQUEST_OPTIONS: IHttpRequestOptions = { responseType: "json", timeout: 5000 };
	/** Paramètre pour ouvrir une nouvelle fenêtre plutôt que d'utiliser celle de l'app (ouvre waze dans une nouvelle fenêtre et évite un bug notamment). */
	private static readonly C_IN_APP_BROWSER_EXTERN_WINDOW_PARAM = "_system";
	/**  */
	private static readonly C_IN_APP_BROWSER_NO_LOCATION_PARAM = "location=no";

	/** Objet de configuration pour le GPS. */
	private moConfig: INavigationConfig;

	//#endregion

	//#region PROPERTIES

	/** Racine de l'API reverse geocoding */
	private get reverseGeocodingUrlApi(): string {
		return `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_suffix}apps/${ConfigData.appInfo.appId}/geolocation/geocoding/adresses`;
	}

	//#endregion

	//#region METHODS

	constructor(
		/** Service permettant l'affichage de popups et toasts. */
		private isvcUiMessage: UiMessageService,
		/** Service d'ouverture de la page des réglages. */
		private ioNativeSettings: OpenNativeSettings,
		/** Service de navigateur internet dans l'application. */
		private ioInAppBrowser: InAppBrowser,
		/** Service permettant d'afficher les loaders. */
		private isvcLoading: LoadingService,
		private isvcNetwork: NetworkService,
		private ioHttpClient: HttpClient,
		private isvcPlatform: PlatformService,
		private ioModalCtrl: ModalController
	) {

		this.resetConfig(ENavigationType.waze);
	}

	/** Réinitialise l'objet de configuration pour le GPS.
	 * @param peTypeMap Type de la carte.
	 * @param psGoogleKey Clé google.
	 */
	public resetConfig(peTypeMap: ENavigationType, psGoogleKey: string = ""): void {
		this.moConfig = {
			typeMap: peTypeMap,
			googleKey: psGoogleKey
		};
	}

	/** Obtient les coordonnées GPS de la position actuelle à l'aide du GPS du téléphone. Peut afficher des popups d'erreur.
	 * @param pbDisplayError Indique si les erreurs doivent être affichées ou non, `true` par défaut.
	 */
	public getCurrentPosition(pbDisplayError: boolean = true): Observable<IGeolocationCoordinates> {
		return from(this.isvcLoading.present("Géolocalisation en cours ..."))
			.pipe(
				mergeMap(_ => Geolocation.getCurrentPosition(NavigationService.C_GEOLOCATION_OPTIONS)),
				catchError((poError: any) => {
					console.warn("NAV.S:: Erreur d'obtention de la position courante via GPS : ", poError);

					if (pbDisplayError)
						this.managePositionError(poError);

					return throwError(poError);
				}),
				map((poGeoPosition: Position) => poGeoPosition.coords),
				finalize(() => this.isvcLoading.dismiss())
			);
	}

	/** Gère une erreur de position.
	 * @param poError Erreur de position survenue.
	 */
	private managePositionError(poError: any): void {
		switch (poError.code) {

			case EGeoApiPositionError.PERMISSION_DENIED:
				this.displayErrorPopupForPermissionDeniedOrTimeout(); // On affiche un message pour demander les autorisations nécessaires.
				break;

			case EGeoApiPositionError.TIMEOUT:
				this.displayErrorPopupForPermissionDeniedOrTimeout("La requête n'a pu aboutir. Vérifiez votre connexion internet et votre géolocalisation.");
				break;

			default:
			case EGeoApiPositionError.POSITION_UNAVAILABLE:
				this.displayErrorPopup(`Données GPS non disponibles. Essayez de vous déplacer puis relancez la géolocalisation.`);
				break;
		}
	}

	/** Affiche une popup d'erreur indiquant que la localisation GPS n'a pas fonctionné et comment vérifier si la localisation est activée ou non (sur mobile).
	 * @param psMessage Message à mettre dans la popup d'erreur.
	 */
	private displayErrorPopupForPermissionDeniedOrTimeout(psMessage?: string): void {
		let lsMessage: string;
		let loSettingButton: IUiButton;

		if (this.isvcPlatform.isMobileApp) {
			lsMessage = StringHelper.isBlank(psMessage) ? `Vérifiez que la géolocalisation de votre appareil est activée.` : psMessage;
			loSettingButton = { text: "Réglages", cssClass: "button-positive", action: () => { this.ioNativeSettings.open("location"); } };
		}
		else
			lsMessage = StringHelper.isBlank(psMessage) ? `Vérifiez que vous avez autorisé la géolocalisation.` : psMessage;

		this.displayErrorPopup(lsMessage, loSettingButton);
	}

	/** Crée une popup d'erreur pour le service de navigation.
	 * @param psMessageTemplate Message à afficher dans la popup d'erreur.
	 * @param poSettingsButton Bouton des réglages pour accéeder à l'activation du GPS sur mobile uniquement.
	 */
	private displayErrorPopup(psMessageTemplate: string, poSettingsButton?: IUiButton): void {
		const loPopupParams = new ShowMessageParamsPopup({ message: psMessageTemplate, header: "Votre position ne peut être déterminée" });
		loPopupParams.buttons = [{ text: "OK", cssClass: "button-positive" }];

		if (poSettingsButton) {
			loPopupParams.buttons.push({
				text: poSettingsButton.text,
				cssClass: poSettingsButton.cssClass,
				role: poSettingsButton.hasCancelRole?.toString(),
				handler: poSettingsButton.action
			} as AlertButton);
		}

		this.isvcUiMessage.showMessage(loPopupParams);
	}

	/** Lance la navigation sur l'application aux coordonnées GPS saisies.
	 * @param pnLatitude Latitude du point recherché.
	 * @param pnLongitude Longitude du point recherché.
	 * @param pbDisplayError Indique si les erreurs doivent être affichées ou non, `true` par défaut.
	 */
	public navigateToCoordinates(pnLatitude: number, pnLongitude: number, pbDisplayError: boolean = true): Observable<boolean> {
		let loNavigate$: Observable<boolean>;

		if (!NumberHelper.isValid(pnLatitude) || !NumberHelper.isValid(pnLongitude)) {
			if (NumberHelper.isStringNumber(pnLatitude) && NumberHelper.isStringNumber(pnLongitude)) {
				pnLatitude = +pnLatitude;
				pnLongitude = +pnLongitude;
			}
			else if (pbDisplayError) {
				console.error(`NAV.S:: Coordonnées de destination invalides : latitude=${pnLatitude} ; longitude=${pnLongitude}`);
				loNavigate$ = throwError("Aucune donnée GPS n'est renseignée.");
			}
		}

		if (!loNavigate$)
			loNavigate$ = this.innerNavigateToCoordinates(pnLatitude, pnLongitude);

		return loNavigate$
			.pipe(
				tap(
					_ => { },
					(poError: NotImplementedNavigationError | string) => {
						const lsMessage: string = typeof poError === "string" ? poError : poError.message;
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: lsMessage, header: "Navigation impossible" }));
					}
				)
			);
	}

	private innerNavigateToCoordinates(pnLatitude: number, pnLongitude: number): Observable<boolean> {
		switch (this.moConfig.typeMap) {

			case ENavigationType.waze:
				return this.innerNavigateToCoordinates_waze(pnLatitude, pnLongitude);

			case ENavigationType.google:
				return this.innerNavigateToCoordinates_google(pnLatitude, pnLongitude);

			default:
				return throwError(new NotImplementedNavigationError(this.moConfig.typeMap));
		}
	}

	private innerNavigateToCoordinates_waze(pnLatitude: number, pnLongitude: number): Observable<boolean> {
		return defer(() => {
			this.ioInAppBrowser.create(
				`${NavigationService.C_NAVIGATION_URLS.wazeUrl}ll=${pnLatitude},${pnLongitude}&navigate=yes`,
				NavigationService.C_IN_APP_BROWSER_EXTERN_WINDOW_PARAM,
				NavigationService.C_IN_APP_BROWSER_NO_LOCATION_PARAM
			);

			return of(true);
		})
			.pipe(
				catchError(poError => {
					console.error("NAV.S:: Erreur lors de la navigation avec Waze : ", poError);
					return throwError("Une erreur est survenue lors de la navigation avec Waze.");
				})
			);
	}

	private innerNavigateToCoordinates_google(pnLatitude: number, pnLongitude: number): Observable<boolean> {
		return throwError(new NotImplementedNavigationError(this.moConfig.typeMap));
	}

	/** Lance la navigation sur l'application à l'adresse renseignée.
	 * @param psAddress Adresse à laquelle on veut aller.
	 */
	public navigateByAddress(psAddress: string): void {
		switch (this.moConfig.typeMap) {

			case ENavigationType.waze:
				this.ioInAppBrowser.create(
					`${NavigationService.C_NAVIGATION_URLS.wazeUrl}q=${psAddress}`,
					NavigationService.C_IN_APP_BROWSER_EXTERN_WINDOW_PARAM,
					NavigationService.C_IN_APP_BROWSER_NO_LOCATION_PARAM
				);
				break;

			default:
				throw new NotImplementedNavigationError(this.moConfig.typeMap);
		}
	}

	/** Récupère les données GPS d'un endroit à partir d'une adresse.
	 * @param psStreet Numéro et rue à partir de laquelle trouver les données GPS.
	 * @param psZipcode Code postal à partir duquel trouver les données GPS.
	 * @param psCity Ville à partir de laquelle trouver les données GPS.
	 */
	public getGeolocDataByAddress(psStreet: string, psZipcode: string, psCity: string): Observable<IGeolocData[]>;
	/** Récupère les données GPS d'un endroit à partir d'une adresse.
	 * @param psAddress Adresse à partir de laquelle trouver les données GPS.
	 */
	public getGeolocDataByAddress(psAddress: string): Observable<IGeolocData[]>;
	public getGeolocDataByAddress(psAddressOrStreet: string, psZipcode?: string, psCity?: string): Observable<IGeolocData[]> {

		return this.isvcNetwork.asyncIsNetworkReliable()
			.pipe(
				mergeMap((pbHasNetwork: boolean) => {
					if (pbHasNetwork) {
						const lsAddress: string = this.createAddressString(psAddressOrStreet, psZipcode, psCity);

						//todo Mettre en place avec google et vérifier le type d'objet de retour.
						return this.ioHttpClient.get(this.getUrlFromConfigTypeMap(lsAddress), NavigationService.C_HTTP_REQUEST_OPTIONS)
							.pipe(
								catchError(poError => {
									console.error(`NAV.S:: Erreur de récupération des coordonnées pour l'adresse "${lsAddress}" :`, poError);
									return throwError(poError);
								}),
								tap((poResponse: IGeoApiResponse) => console.info(`NAV.S:: Données GPS trouvées pour l'adresse "${lsAddress}" :`, poResponse)),
								map((poResponse: IGeoApiResponse) => poResponse.features.map((poElement: IGeoApiResponseAddress) => this.createGeolocData(poElement)))
							);
					}
					else
						return of([]);
				})
			);
	}

	/** Crée une adresse sous forme de chaîne de caractères à partir du numéro et rue, code postal, ville.
	 * @param psStreet Numéro et rue de l'adresse à créer, ou adresse complète directement.
	 * @param psZipcode Code postal de l'adresse.
	 * @param psCity Ville de l'adresse.
	 */
	private createAddressString(psStreet: string, psZipcode?: string, psCity?: string): string {
		let lsAddress: string = psStreet;

		if (!StringHelper.isBlank(psZipcode))
			lsAddress += ` ${psZipcode}`;

		if (!StringHelper.isBlank(psCity))
			lsAddress += ` ${psCity}`;

		return lsAddress;
	}

	/** Récupère l'url pour naviguer vers l'adresse en fonction du type de carte utilisée.
	 * @param psAddress Adresse à partir de laquelle trouver les coordonnées GPS.
	 */
	private getUrlFromConfigTypeMap(psAddress: string): string {
		switch (ENavigationType.gouv) { //* Réutiliser `this.moConfig.typeMap` quand l'api de Google fonctionnera.
			case ENavigationType.gouv:
				return `${NavigationService.C_NAVIGATION_URLS.gouvUrl}search/?q=${psAddress}`;

			// case ENavigationType.google:
			// 	let lsUrl = `${NavigationService.C_NAVIGATION_URLS.googleUrl}address=${psAddress}`;

			// 	if (!StringHelper.isBlank(this.moConfig.googleKey))
			// 		lsUrl += `&key=${this.moConfig.googleKey}`;

			// 	return lsUrl;

			default:
				throw new NotImplementedNavigationError(this.moConfig.typeMap);
		}
	}

	/** Crée et retourne un objet de données GPS à partir d'un objet `IGeoApiResponseAddress`.
	 * @param poGeoApiResponseAddress Objet dont il faut extraire les données GPS nécessaires.
	 */
	private createGeolocData(poGeoApiResponseAddress: IGeoApiResponseAddress): IGeolocData {
		return {
			latitude: poGeoApiResponseAddress.geometry.coordinates[1],
			longitude: poGeoApiResponseAddress.geometry.coordinates[0],
			street: poGeoApiResponseAddress.properties.name,
			zipCode: poGeoApiResponseAddress.properties.postcode,
			city: poGeoApiResponseAddress.properties.city
		};
	}

	/** Récupère les données GPS d'un endroit à partir de ses coordonnées.
	 * @param poCoordinates Coordonnées à utiliser pour récupérer les données GPS.
	 * @param psLocal Localité du pays dans lequel on recherche l'adresse (en France par défaut).
	 */
	public getGeolocDataByCoordinates(poCoordinates: IGeolocationCoordinates, psLocal?: string): Observable<IGeolocData[]>;
	/** Récupère les données GPS d'un endroit à partir d'une latitude et d'une longitude.
	 * @param poLatitude Latitude à utiliser pour récupérer les données GPS.
	 * @param poLongitude Longitude à utiliser pour récupérer les données GPS.
	 * @param psLocal Localité du pays dans lequel on recherche l'adresse (en France par défaut).
	 */
	public getGeolocDataByCoordinates(poLatitude: string | number, poLongitude: string | number, psLocal?: string): Observable<IGeolocData[]>;
	public getGeolocDataByCoordinates(poCoordinatesOrLatitude: IGeolocationCoordinates | string | number, poLongitudeOrLocal?: string | number, psLocal?: string)
		: Observable<IGeolocData[]> {

		return this.isvcNetwork.asyncIsNetworkReliable()
			.pipe(
				mergeMap((pbHasNetwork: boolean) => {
					if (pbHasNetwork) {
						let lnLatitude: number;
						let lnLongitude: number;
						let lsLocal: string;

						if (typeof poCoordinatesOrLatitude === "object") {
							lnLatitude = +poCoordinatesOrLatitude.latitude;
							lnLongitude = +poCoordinatesOrLatitude.longitude;
							lsLocal = poLongitudeOrLocal as string;
						}
						else {
							lnLatitude = +poCoordinatesOrLatitude;
							lnLongitude = +poLongitudeOrLocal;
							lsLocal = psLocal;
						}

						if (!this.areLatitudeAndLongitudeValid(lnLatitude, lnLongitude)) {
							const lsMesage = `NAV.S:: Les coordonnées ne sont pas valides : latitude=${lnLatitude} ; longitude=${lnLongitude}`;
							console.error(lsMesage);
							return throwError(lsMesage);
						}
						else
							return this.searchAddressByCoordinates(lnLatitude, lnLongitude, lsLocal);
					}

					else
						return of([]);
				})
			);
	}

	/** Cherche une adresse via des coordonnées.
	 * @param pnLatitude Latitude avec laquelle chercher l'adresse.
	 * @param pnLongitude Longitude avec laquelle chercher l'adresse.
	 * @param psLocal Localité du pays où chercher l'adresse (France par défaut).
	 */
	public searchAddressByCoordinates(pnLatitude: number, pnLongitude: number, psLocal?: string): Observable<IGeolocData[]> {
		const lsLatitudeLongitude = `lat=${pnLatitude}&lng=${pnLongitude}`;
		let lsUrl = `${this.reverseGeocodingUrlApi}?${lsLatitudeLongitude}`;

		if (!StringHelper.isBlank(psLocal))
			lsUrl += `&locale=${psLocal}`;

		return this.ioHttpClient.get<IGeoApiResponse>(lsUrl, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions())
			.pipe(
				catchError(poError => {
					console.error(`NAV.S:: Erreur de récupération de l'adresse pour les coordonnées "${lsLatitudeLongitude}" :`, poError);
					return throwError(poError);
				}),
				tap((poResponse: IGeoApiResponse) => console.info(`NAV.S:: Données GPS trouvées pour les coordonnées "${lsLatitudeLongitude}" :`, poResponse)),
				map((poResponse: IGeoApiResponse) => poResponse.features.map((poElement: IGeoApiResponseAddress) => this.createGeolocData(poElement)))
			);
	}

	/** Récupère les données GPS de l'endroit où on se situe actuellement.
	 * @param pbDisplayError Indique si les erreurs doivent être affichées ou non, `true` par défaut.
	 */
	public getCurrentGeolocData(pbDisplayError: boolean = true): Observable<IGeolocData[]> {
		return this.isvcNetwork.asyncIsNetworkReliable()
			.pipe(
				mergeMap((pbHasNetwork: boolean) => {
					if (pbHasNetwork) {
						return this.getCurrentPosition(pbDisplayError)
							.pipe(mergeMap((poCoordinates: IGeolocationCoordinates) => this.getGeolocDataByCoordinates(poCoordinates)));
					}
					else
						return of([]);
				})
			);
	}

	/** Retourne `true` si la latitude et la longitude sont des nombres valides, `false` sinon.
	 * @param poLatitude Latitude à vérifier.
	 * @param poLongitude Longitude à vérifier.
	 */
	private areLatitudeAndLongitudeValid(poLatitude: string | number, poLongitude: string | number): boolean {
		return !isNaN(+poLatitude) && !isNaN(+poLongitude);
	}

	/** Transforme des données de géolocalisation en données de reverseGeocoding.
	 * @param paData Tableau des données de géolocalisation à transformer.
	 * @param psTitle Titre à donner aux adresses à transformer, "Autre suggestion" par défaut.
	 */
	public transformGeolocDataToReverseGeocodingData(paData: IGeolocData[], psTitle: string = "Autre suggestion"): IReverseGeocodingData[] {
		return paData.map((poElement: IGeolocData) => ({ title: psTitle, ...poElement } as IReverseGeocodingData));
	}

	/** Ouvre une modale de reverseGeocoding.
	 * @param paData Tableau des données GPS pour initialiser le composant.
	 */
	public openReverseGeocodingModal(paData: IReverseGeocodingData[]): Observable<IGeolocData> {
		return defer(() => {
			return this.ioModalCtrl.create({
				component: ReverseGeocodingModalComponent,
				componentProps: { data: paData }
			});
		})
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				filter((poResult: OverlayEventDetail<IGeolocData>) => !!poResult.data),
				map((poResult: OverlayEventDetail<IGeolocData>) => poResult.data)
			);
	}

	/**
	 * Ouvre une modale pour recalculer les coordonnées GPS d'un contact.
	 * @param poContact Le contact pour lequel il faut recalculer les coordonnées GPS.
	 */
	public recalculateContactGPSData(poContact: IContact): Observable<IContact> {
		const lsFilledAddressText = "Adresse renseignée";
		const lsUserPositionText = "Votre position";

		this.resetConfig(ENavigationType.gouv);

		return concat(this.getModelGeocodingData(this.getModelGeolocData(poContact), lsFilledAddressText), this.getCurrentGeolocDataWithTitle(lsUserPositionText))
			.pipe(
				catchError(poError => { console.error("NAV.S:: ", poError); return EMPTY; }),
				toArray(),
				mergeMap((paResults: IReverseGeocodingData[][]) => {
					const laAllData: IReverseGeocodingData[] = ArrayHelper.flat(paResults);

					if (ArrayHelper.hasElements(laAllData)) { // Si au moins une adresse a été récupérée, on trie les adresses puis on se dirige vers le composant.
						const lnUserAddressLocationIndex: number = laAllData.findIndex((poItem: IReverseGeocodingData) => poItem.title === lsUserPositionText);
						if (lnUserAddressLocationIndex !== -1) { // Si la position courante a été récupérée, il faut la déplacer.
							const lnFilledAddressLocationIndex: number = laAllData.findIndex((poItem: IReverseGeocodingData) => poItem.title === lsFilledAddressText);
							// On déplace l'adresse de la position courante juste après l'adresse renseignée dans le modèle (index de cette adresse + 1).
							ArrayHelper.moveElement(laAllData, lnUserAddressLocationIndex, lnFilledAddressLocationIndex + 1);
						}

						return this.manageReverseGeocodingModalAndResponse(laAllData, poContact);
					}
					else { // Sinon, on affiche un message à l'utilisateur.
						this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: "Aucune donnée GPS n'a pu être trouvée.", header: "Erreur" }));
						return EMPTY;
					}
				})
			);
	}

	/** Récupère les données GPS en fonction du modèle.
	 * @param poModelGeolocData Objet GeolocData.
	 * @param psFilledAddressText Texte pour le titre de l'adresse acutelle du modèle.
	 */
	public getModelGeocodingData(poModelGeolocData: ModelGeolocData, psFilledAddressText: string): Observable<IReverseGeocodingData[]> {
		const lbHasModelData = !StringHelper.isBlank(poModelGeolocData.street);
		let loGetGeolocData$: Observable<IGeolocData[]>;

		// Si au moins la rue de l'adresse est renseignée, on récupère les données GPS par adresse car elles ont pu être manipulées.
		if (lbHasModelData)
			loGetGeolocData$ = this.getGeolocDataByAddress(poModelGeolocData.street, poModelGeolocData.zipCode, poModelGeolocData.city);

		else { // Si la latitude et la longitude sont valides, on récupère les données GPS à partir de ces coordonnées, sinon on retourne un tableau vide.
			loGetGeolocData$ = NumberHelper.isValid(poModelGeolocData.latitude) && NumberHelper.isValidPositive(poModelGeolocData.longitude) ?
				this.getGeolocDataByCoordinates(poModelGeolocData.latitude, poModelGeolocData.longitude) : of([]);
		}

		return loGetGeolocData$
			.pipe(
				map((paResults: IGeolocData[]) => ArrayHelper.hasElements(paResults) ? this.transformGeolocDataToReverseGeocodingData(paResults) : []),
				map((paResults: IReverseGeocodingData[]) => this.getSortedModelGeolocData(paResults, poModelGeolocData, lbHasModelData, psFilledAddressText))
			);
	}

	/** Récupère les données GPS de l'endroit actuel et affiche une popup d'erreur en cas de problème.
	 * @param psUserPositionText Texte pour le titre de l'adresse à sa position actuelle.
	 */
	public getCurrentGeolocDataWithTitle(psUserPositionText: string): Observable<IReverseGeocodingData[]> {
		return this.getCurrentGeolocData()
			.pipe(
				map((paCurrentData?: IGeolocData[]) => paCurrentData ? this.transformGeolocDataToReverseGeocodingData(paCurrentData) : []),
				tap((paCurrentReverseData: IReverseGeocodingData[]) => {
					if (ArrayHelper.hasElements(paCurrentReverseData))
						ArrayHelper.getFirstElement(paCurrentReverseData).title = psUserPositionText;
				})
			);
	}

	/**
	 * Retourne les données de géolocalisation d'un contact.
	 * @param poModel Le contact.
	 */
	private getModelGeolocData(poModel: IContact): ModelGeolocData {
		const loModelGeolocData: ModelGeolocData = {
			street: poModel.street,
			zipCode: poModel.zipCode,
			city: poModel.city,
			latitude: undefined,
			longitude: undefined
		};

		const loLatitudeValue: string | number | undefined = poModel.latitude;
		const loLongitudeValue: string | number | undefined = poModel.longitude;

		if (NumberHelper.isValid(loLatitudeValue))
			loModelGeolocData.latitude = loLatitudeValue as number;
		else if (NumberHelper.isStringNumber(loLatitudeValue))
			loModelGeolocData.latitude = +loLatitudeValue;

		if (NumberHelper.isValid(loLongitudeValue))
			loModelGeolocData.latitude = loLongitudeValue as number;
		else if (NumberHelper.isStringNumber(loLongitudeValue))
			loModelGeolocData.latitude = +loLongitudeValue;

		return loModelGeolocData;
	}

	/** Trie les données GPS récupérées pour le modèle.
	 * @param paData Tableau des données GPS récupérées.
	 * @param poModelGeolocData Objet contenant les valeurs des champs du modèle liées aux données GPS.
	 * @param pbHasModelData Indique si des données GPS sont présentes dans le modèle.
	 * @param psFilledAddressText Titre pour l'adresse du modèle.
	 */
	private getSortedModelGeolocData(paData: IReverseGeocodingData[], poModelGeolocData: ModelGeolocData, pbHasModelData: boolean, psFilledAddressText: string)
		: IReverseGeocodingData[] {

		if (ArrayHelper.hasElements(paData)) {
			// Si on a un modèle de renseigné, on vérifie le code postal du modèle avec celui trouvé par le GPS pour s'assurer qu'il s'agit de la même adresse.
			if (pbHasModelData) {
				const loFirstItem: IReverseGeocodingData = ArrayHelper.getFirstElement(paData);
				// Ce n'est pas la même adresse, on en crée une nouvelle avec les informations du modèle.
				if (loFirstItem.zipCode.trim() !== poModelGeolocData.zipCode.trim() ||
					loFirstItem.street.trim() !== poModelGeolocData.street ||
					loFirstItem.city !== poModelGeolocData.city) {

					const loCustomData: IReverseGeocodingData = {
						street: poModelGeolocData.street,
						zipCode: poModelGeolocData.zipCode,
						city: poModelGeolocData.city,
						latitude: poModelGeolocData.latitude ?? loFirstItem.latitude,
						longitude: poModelGeolocData.longitude ?? loFirstItem.longitude,
						title: ""
					};

					paData.unshift(loCustomData); // On insère l'adresse personnalisée en première position,
					paData.pop(); // et on supprime la dernière (la moins probable).
				}
			}

			ArrayHelper.getFirstElement(paData).title = psFilledAddressText;
			return paData;
		}
		else
			return [];
	}

	/** Ouvre la page de reverseGeocoding et retourne les changements.
	 * @param paData Tableau des données GPS pour initialiser le composant.
	 * @param poContact Le contact.
	 */
	private manageReverseGeocodingModalAndResponse(paData: IReverseGeocodingData[], poContact: IContact): Observable<IContact> {
		return this.openReverseGeocodingModal(paData)
			.pipe(
				map((poResult: IGeolocData) => {
					poContact.latitude = typeof poResult.latitude === "string" ? +poResult.latitude : poResult.latitude;
					poContact.longitude = typeof poResult.longitude === "string" ? +poResult.longitude : poResult.longitude;
					poContact.street = poResult.street;
					poContact.zipCode = poResult.zipCode;
					poContact.city = poResult.city;

					return poContact;
				})
			);
	}

	/** Afficher la popup pour demander s'il faut recalculer les coordonnées GPS.
	 * @param psMessage Le message à afficher.
	 */
	public showRecalculateGPSDataPopup(psMessage: string): Observable<boolean> {
		return this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				header: "Recalculer les coordonnées GPS ?",
				message: psMessage,
				buttons: [
					{ text: "Non", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Oui", handler: () => UiMessageService.getTruthyResponse() }
				]
			})
		)
			.pipe(map((poResult: IUiResponse<boolean>) => poResult.response));
	}

	/**	Test si on peut afficher la popup de recalcul des coordonnées GPS.
	 * @param poContact Le contact à tester.
	 */
	public static canShowRecalculateGPSDataPopup(poContact: IContact): boolean {
		if (!poContact.latitude && !poContact.longitude && poContact.city && poContact.zipCode && poContact.street)
			return true;
		return false;
	}

	//#endregion
}