import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../pipes/pipes.module';
import { CalaoAvatarModule } from '../avatar/calao-avatar.module';
import { BlankInformationModule } from '../blank-information/blank-information.module';
import { ErrorsModule } from '../errors/errors.module';
import { FormModule } from '../forms/form/form.module';
import { HeaderModule } from '../header/header.module';
import { ListsModule } from '../lists/lists.module';
import { ModalModule } from '../modal/modal.module';
import { SpinnerModule } from '../spinner/spinner.module';
import { TabsModule } from '../tabs/tabs.module';
import { EntityEntriesListComponent } from './components/entity-entries-list/entity-entries-list.component';
import { EntityFormComponent } from './components/entity-form/entity-form.component';
import { EntityHeaderComponent } from './components/entity-header/entity-header.component';
import { EntityModalComponent } from './components/entity-modal/entity-modal.component';
import { LayoutComponent } from './components/layout/layout.component';
import { StackLayoutComponent } from './components/stack-layout/stack-layout.component';
import { TabLayoutComponent } from './components/tab-layout/tab-layout.component';
import { EntitiesService } from './services/entities.service';

function initializeEntitiesFactory(isvcEntities: EntitiesService) {
	return () => {
		isvcEntities.initAsync();
	};
}

const components: Array<Type<any> | any[]> = [
	TabLayoutComponent,
	LayoutComponent,
	StackLayoutComponent,
	EntityEntriesListComponent,
	EntityFormComponent,
	EntityModalComponent,
	EntityHeaderComponent
];

@NgModule({
	declarations: components,
	exports: components,
	imports: [
		CommonModule,
		TabsModule,
		IonicModule,
		ListsModule,
		CalaoAvatarModule,
		PipeModule,
		FormModule,
		SpinnerModule,
		BlankInformationModule,
		HeaderModule,
		ErrorsModule,
		ModalModule
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			deps: [EntitiesService],
			useFactory: initializeEntitiesFactory,
			multi: true
		}
	]
})
export class EntitiesModule { }
