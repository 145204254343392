import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReasonService } from '../../reason/services/reason.service';
import { IPackReason } from '../models/ipack-reason';

@Injectable()
export class PackReasonService {

	//#region METHODS

	constructor(
		private readonly isvcReason: ReasonService
	) { }

	/** Retourne la liste des ids de motif dont le comportement de rejet est vrai.
	 * @param psReasonParentId Id du motif parent.
	 */
	public getRejectedPackReasonsIds$(psReasonParentId: string): Observable<string[]> {
		return this.isvcReason.getParentChildrenReasons$(psReasonParentId).pipe(
			map((paReasons: IPackReason[]) => {
				return paReasons
					.filter((poPackReason: IPackReason) => poPackReason.behavior?.reject)
					.map((poRejectedPackReason: IPackReason) => poRejectedPackReason._id);
			})
		);
	}

	//#endregion

}
