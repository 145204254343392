import { ResolveModel } from "../../../../utils/models/decorators/resolve-model.decorator";
import { ControlAction } from "./actions/control-action";
import { ControlCriterion } from "./criteria/control-criterion";
import { IControlFilter } from "./icontrol-filter";

export class ControlFilter implements IControlFilter {

	//#region PROPERTIES

	/** @implements */
	@ResolveModel(ControlCriterion)
	public readonly criteria?: ReadonlyArray<ControlCriterion>;

	/** @implements */
	@ResolveModel(ControlAction)
	public readonly actions?: ReadonlyArray<ControlAction>;

	//#endregion

	//#region METHODS

	public toString(): string {
		return `${this.constructor.name}: { criteria: [ ${this.criteria?.map((poCriterion: ControlCriterion) => poCriterion.constructor.name).join(", ") ?? ""} ], actions: [ ${this.actions?.map((poAction: ControlAction) => poAction.constructor.name).join(", ") ?? ""} ] }`;
	}

	//#endregion METHODS

}