import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalOptions, OverlayEventDetail } from '@ionic/core';
import { from, Observable, Subject } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { IBarcodeReader } from '../logistics/barcode-reader/models/IBarcodeReader';
import { IBarcodeAcquisition } from '../logistics/devices/models/ibarcode-acquisition';
import { IScanditParam } from './IScanditParam';
import { BarcodeCapture } from './scandit';
import { ScanditModalComponent } from './scandit-modal.component';

let Scandit: any;

@Injectable()
export class ScanditService implements IBarcodeReader {

	//#region FIELDS

	// Clé valable jusqu'au 2020/10/29.
	private static readonly C_LICENCE_KEY: string = "AUSvrwqbFfQTMaMl30Lg11YKZtj3KdetJ1/lGvpFN2hnVH9WJEh10QlLHsRgcTosNFTUXG5O6n2RZkn00FB3WmVAwMZkds67vkI1cdJWymf7XkF3D2B58ydbbHHVWTNkoneznE9nNOStGiVxUl3na5AFkM1DEkOvdThkTyMwsxB0pOH7ghCWmgH4pscJH8gJc7YWO9OanUfcfa9Sg+eMWUaMQHBuhanfYDRbwBdX9/sqPp7Hl6+wbHa8EuSXyLickTjGXleBwuvIq44ot6gVASaCl2GMyYQbByEeshllyPdCa2d4cWr+LdkCKzipkNQ69H2wlHe4TNLAsN9K49+He4HA6NaQQ2/4HzkkFy9iO9P4MoE+/k2LxBbIQhUqIoqyOBZ9lIimLg8BpK2PcPncptYdtQQJwFrOuGKXosUs3BbLkpHYqDhyP5WR+mZ36Fu8R3ZI2KOpiQiQ2JdL+4tX8qPc70qk70Vq0U9WNAhI0zGb2QdTrtwT1/Ivi/lHEAm8jps0DiWOK5d6TFVT26BoefERLflWEPIaIfWVdSOlksxYPIiIYWURmVC8ldj1NksKFgDIu2t8MMbPj1vU+lll4lcJJJIQBQkO4Hn023XC1yJkc4Z9uK0Tw0nmRKUpvsCp7RJ47Si6P4PuM6TEHB5wj9ObcLVIYo974S6o7vgbSglBt9fcrAP0vbx613Kabt+VdRLsc2bLIcRWZ7MKC6sgZ0aRqP5eQs0Kw5ZpWpxKdIK4WJPdMEVCJVPMJvsKRpPTs2FXmFpcJI0PgOdsqLI1InBmfoTuUCS8XpB9k5T4/KTNWI4LvmdOkltLoKCg/4n+w4y5/NWDp/lOB+MeDomx";

	/** Durée en millisecondes pour qu'un code déjà scanné ne soit pas recompté. */
	private static readonly C_DUPLICATE_FILTER_DURATION: number = 3000;

	/** Couleur du carré affiché dans l'overlay lors d'une acquisition. */
	private static readonly C_OVERLAY_COLOR = "E00022";

	/** Valeur par défaut pour bloquer le focus de Scandit. Valeur donnée par le support de Scandit dans le snippet. */
	public static readonly C_DEFAULT_BLOCK_FOCUS: number = 0.6;

	// Beaucoup de 'any' car le SDK de Scandit est non typé en v6.
	private moContext: any;
	private moCamera: any;
	private moSettings: any;
	private moView: any;
	private moBarcodeCapture: BarcodeCapture;
	private readonly moReadSubject = new Subject<IBarcodeAcquisition[]>();

	public get blockFocus(): boolean { return this.manualLensPosition !== undefined; };	// Comparaison à `undefined`, car si comparé à 0 alors retourne true.
	public manualLensPosition = ScanditService.C_DEFAULT_BLOCK_FOCUS;

	public get userFeedback(): boolean {
		return !!this.moContext.modes[0].feedback.success.vibration;
	}

	public set userFeedback(psVibration: boolean) {
		if (psVibration)
			this.moContext.modes[0].feedback.success = Scandit.Feedback.defaultFeedback;	// Ajoute la vibration et le beep.
		else
			this.moContext.modes[0].feedback.success = new Scandit.Feedback(null, null);
	}

	//#endregion

	//#region METHODS

	constructor(
		private ioModalCtrl: ModalController,
	) { }

	/** Indique la balise HTML où doit être présente le rendu de la caméra de Scandit.
	 * @param htmlElement nativeElement où sera affiché la caméra.
	 */
	private setElement(poHtmlElement: HTMLElement): void {
		this.moView.connectToElement(poHtmlElement);

		// Mis en place de l'overlay (carré lors d'une acquisition).
		console.debug("SCANDIT.S::Mise en place de l'overlay.");
		const loOverlay = Scandit.BarcodeCaptureOverlay.withBarcodeCaptureForView(this.moBarcodeCapture, this.moView);
		loOverlay.brush = new Scandit.Brush(undefined, ScanditService.C_OVERLAY_COLOR, 2);
	}

	public initializeBarcode(): void {
		if (!this.moContext) {	// On ne créé qu'une fois le contexte et le modifie si besoin.
			this.moContext = Scandit.DataCaptureContext.forLicenseKey(ScanditService.C_LICENCE_KEY);

			this.moSettings = new Scandit.BarcodeCaptureSettings();
			this.moSettings.enableSymbologies([
				Scandit.Symbology.EAN13UPCA
			]);
			this.moSettings.codeDuplicateFilter = ScanditService.C_DUPLICATE_FILTER_DURATION;

			this.moBarcodeCapture = Scandit.BarcodeCapture.forContext(this.moContext, this.moSettings);

			this.moCamera = Scandit.Camera.default;

			this.moCamera.applySettings(this.getCameraSettings());

			this.moContext.setFrameSource(this.moCamera);

			this.userFeedback = false;

			this.moView = Scandit.DataCaptureView.forContext(this.moContext);
		}
	}

	/** Fixe le focus de la camera à une valeur donnée.
	 * @param psValue Si `undefined` met le focus de l'appareil en automatique.
	 */
	public setFixedFocus(psValue: number): void {
		this.manualLensPosition = psValue;

		// Si la camera a déjà été créé on met à jour les settings.
		if (this.moCamera)
			this.moCamera.applySettings(this.getCameraSettings());
	}

	private getCameraSettings(): any {
		const cameraSettings = Scandit.BarcodeCapture.recommendedCameraSettings;

		// Depending on the use case further camera settings adjustments can be made here.

		if (this.blockFocus) {
			console.debug(`SCANDIT.S::Blocage du focus sur ${this.manualLensPosition}.`);
			cameraSettings.setProperty("api", 2);
			cameraSettings.setProperty("manualLensPosition", this.manualLensPosition);
			cameraSettings.setProperty("disableManualLensPositionSupportCheck", true);
		}

		console.debug(`SCANDIT.S::Pas de blocage du focus.`);

		return cameraSettings;
	}

	/** @implements */
	public readBarcode(): void {
		this.openModal().subscribe((paAcquisitions: IBarcodeAcquisition[]) => this.moReadSubject.next(paAcquisitions));
	}

	/** @implements */
	public onBarcodeReaded(): Observable<IBarcodeAcquisition[]> {
		return this.moReadSubject.asObservable();
	}

	/** Change le temps nécessaire pour pouvoir scanner deux fois le même code.
	 * @param pnValue Le temps en secondes.
	 */
	public updateDuplicateFilterDuration(pnValue: number): void {
		this.moSettings.codeDuplicateFilter = pnValue * 1000;
	}

	/** Création d'une modale qui affiche la caméra de Scandit ainsi que quelques informations sur le nombre d'éléments. */
	public openModal(): Observable<IBarcodeAcquisition[]> {
		const loModalParam: IScanditParam = {
			onCreate: (poHtmlElement: any) => {
				this.setElement(poHtmlElement); // Récupère la balise HTML où l'on doit afficher la caméra de Scandit.
				this.moCamera.switchToDesiredState(Scandit.FrameSourceState.On);
			},
			barcodeCapture: this.moBarcodeCapture,
			onClose: (): void => {
				this.moCamera.switchToDesiredState(Scandit.FrameSourceState.Off);
			}
		};

		const loModalOptions: ModalOptions = {
			component: ScanditModalComponent,
			componentProps: {
				params: loModalParam,
				camera: this.moCamera,
			}
		};

		return from(this.ioModalCtrl.create(loModalOptions))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				map((poValue: OverlayEventDetail<IBarcodeAcquisition[]>) => {
					// Si l'utilisateur fait retour, data est 'undefined'.
					return ArrayHelper.hasElements(poValue.data) ? poValue.data : [];
				})
			);
	}

	//#endregion
}