import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { LoadingService } from '../../../../services/loading.service';
import { Loader } from '../../../loading/Loader';

@Component({
	selector: 'calao-iframe',
	templateUrl: './iframe.component.html',
	styleUrls: ['./iframe.component.scss'],
})
export class IFrameComponent {

	//#region FIELDS

	private mbFirstLoad = true;

	@Output("onUrlChanged") private readonly moUrlChangedEvent = new EventEmitter<Location>();

	//#endregion

	//#region PROPERTIES

	@Input() public src: SafeUrl;

	private mbHideLoader: boolean = false;
	public get hideLoader(): boolean { return this.mbHideLoader; }
	@Input() public set hideLoader(pbHideLoader: boolean) {
		if (pbHideLoader !== this.mbHideLoader) {
			this.mbHideLoader = pbHideLoader;
		}
	}

	private moIFrame: ElementRef<HTMLIFrameElement>;
	@ViewChild('iframe') public set iframe(poIFrame: ElementRef<HTMLIFrameElement>) {
		if (!this.moIFrame && poIFrame) {
			this.moIFrame = poIFrame;
			this.initIFrame();
		}
	};

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcLoading: LoadingService,
	) { }

	private async initIFrame(): Promise<void> {
		let loLoader: Loader;
		if (!this.mbHideLoader) {
			loLoader = await this.isvcLoading.create("Chargement");
			await loLoader.present();
		}

		this.moIFrame.nativeElement.addEventListener("load", () => {
			if (!this.mbFirstLoad)
				this.moUrlChangedEvent.emit(this.moIFrame.nativeElement.contentWindow.location);
			loLoader?.dismiss();
			this.mbFirstLoad = false;
		});
	}

	//#endregion METHODS

}
