import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { InputBarcodeModalComponentComponent } from './input-barcode-modal-component/input-barcode-modal-component.component';
import { InputBarcodeReaderServiceService } from './input-barcode-reader-service.service';

@NgModule({
	imports: [
		CommonModule, IonicModule,
	],
	declarations: [InputBarcodeModalComponentComponent],
	providers: [InputBarcodeReaderServiceService],
})
export class InputReaderModule { }
