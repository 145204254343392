import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from '../../../../../services/store.service';
import { UiMessageService } from '../../../../../services/uiMessage.service';
import { LoggerService } from '../../../../logger/services/logger.service';
import { ModelResolver } from '../../../../utils/models/model-resolver';
import { ERackStatus } from '../../models/ERackStatus';
import { ITask } from '../../models/itask';
import { RackService } from '../../services/rack.service';
import { TaskService } from '../../services/task.service';
import { DeliveryRack } from '../models/delivery-rack';
import { DeliveryRackPack } from '../models/delivery-rack-pack';
import { IDeliveryRack } from '../models/idelivery-rack';
import { IDeliveryRackPack } from '../models/idelivery-rack-pack';

@Injectable()
export class DeliveryRackService extends RackService<IDeliveryRack> {

	//#region METHODS

	constructor(
		psvcStore: Store,
		psvcLogger: LoggerService,
		psvcTask: TaskService,
		psvcUiMessage: UiMessageService
	) {
		super(psvcStore, psvcLogger, psvcTask, psvcUiMessage);
	}

	/** Récupère la liste des portant associés à une tâche.
	 * @param psTaskId Identifiant du document de la tâche de livraison.
	 * @param pbLive Indique si on souhaite écouter les changements (`false` par défaut).
	 */
	public override getRacksFromTaskId$(psTaskId: string, pbLive: boolean = false): Observable<DeliveryRack[]> {
		return super.getRacksFromTaskId$(psTaskId, pbLive)
			.pipe(map((paRacks: IDeliveryRack[]): DeliveryRack[] => this.getInstances(paRacks)));
	}

	/** Récupère la liste des portant associés à une tâche.
	 * @param poTask Tâche de livraison.
	 * @param pbLive Indique si on souhaite écouter les changements (`false` par défaut).
	 */
	public override getRacksFromTask$(poTask: ITask, pbLive: boolean = false): Observable<DeliveryRack[]> {
		return super.getRacksFromTaskId$(poTask._id, pbLive)
			.pipe(map((paRacks: IDeliveryRack[]): DeliveryRack[] => this.getInstances(paRacks)));
	}

	/** Récupère un portant de livraison.
	 * @param psRackId Identifiant du portant.
	 * @param pbLive Indique si on souhaite écouter les changements (false par défaut).
	 */
	public override getRack(psRackId: string, pbLive = false): Observable<DeliveryRack> {
		return super.getRack(psRackId, pbLive)
			.pipe(map((poRack: IDeliveryRack): DeliveryRack => this.getInstance(poRack)));
	}

	/** Créer et retourne un nouveau portant.
	 * @param psTaskId Identifiant de la tâche de livraison.
	 */
	public override createRack(psTaskId: string): DeliveryRack {
		return this.getInstance(
			{
				...super.createRack(psTaskId),
				items: [],
				packs: []
			}
		);
	}

	/** Transforme un tableau d'objets `IDeliveryRack` en tableau d'instances de `DeliveryRack` et retourne le tableau résultat.
	 * @param poObject Objet à transformer en instance.
	 */
	private getInstances(paObjects: IDeliveryRack[]): DeliveryRack[] {
		return paObjects.map((poObject: IDeliveryRack) => this.getInstance(poObject));
	}

	/** Transforme un objet `IDeliveryRack` en instance de `DeliveryRack` et retourne l'instance créée.
	 * @param poObject Objet à transformer en instance.
	 */
	public getInstance(poObject: IDeliveryRack): DeliveryRack {
		return ModelResolver.toClass(DeliveryRack, poObject);
	}

	/** Récupère les portants validés d'une tâche.
	 * @param psTaskId Identifiant de tâche à partir de laquelle récupérer les portants.
	 */
	public getValidatedRacksFromTaskIdAsync(psTaskId: string): Promise<DeliveryRack[]> {
		return this.getRacksFromTaskId$(psTaskId)
			.pipe(
				map((paRacks: DeliveryRack[]) => paRacks.filter((poRack: DeliveryRack) => poRack.status === ERackStatus.closed))
			)
			.toPromise();
	}

	/** Indique si au moins une housse a été lue.
	 * @param paPacks Liste des housses.
	 */
	public hasReadPacks(paPacks: IDeliveryRackPack[] = []): boolean {
		return paPacks?.filter((poPack: IDeliveryRackPack) => !!poPack?.readDate).length > 0;
	}

	/** Indique si le portant à seulement des housses avec des motifs ayant un comportement de rejet.
	 * @param poRack Portant à tester.
	 * @param paRejectedReasonIds Liste des ids des motifs ayant un comportement de rejet.
	 */
	public haveAllPacksRejectedReason(poRack: DeliveryRack, paRejectedReasonIds: string[]): boolean {
		return poRack.hasReadPack() && poRack.packs.every((poPack: DeliveryRackPack) => poPack.reasonId && paRejectedReasonIds.includes(poPack.reasonId));
	}

	//#endregion

}