import { Inject, Injectable, InjectionToken, Optional, Type } from '@angular/core';
import { defer } from 'rxjs';
import { catchError, concatMap, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { FlagService } from '../../../services/flag.service';
import { InjectorService } from '../../../services/injector.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { PlatformService } from '../../../services/platform.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { EFlag } from '../../flags/models/EFlag';
import { PerformanceManager } from '../../performance/PerformanceManager';
import { PatchBase } from '../patches/patch-base';

export const PATCHES_CONFIG = new InjectionToken<Type<PatchBase>[]>("configForPatches");

@Injectable()
export class PatchService {

	//#region FIELDS

	private static readonly C_LOG_ID = "PATCH.S::";

	/** Tableau des types de patchs connus. */
	private readonly maPatchTypes: Type<PatchBase>[];

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcMessage: UiMessageService,
		private readonly isvcFlag: FlagService,
		private readonly isvcPlatform: PlatformService,
		@Inject(PATCHES_CONFIG) @Optional() paPatchTypes: Type<PatchBase>[]
	) {
		this.maPatchTypes = paPatchTypes || [];
	}

	/** Applique tous les patchs connus du service, déterminés dans le module de l'app avec `PatchModule.forRoot([tableau des patchs])`. */
	public applyPatchesAsync(): Promise<boolean> {
		return defer(() => this.isvcPlatform.readyAsync)
			.pipe(
				mergeMap(_ => this.maPatchTypes),
				concatMap((poPatchType: Type<PatchBase>) => this.applyPatchAsync(poPatchType)),
				toArray(),
				map((paResults: boolean[]) => paResults.every((pbResult: boolean) => pbResult)),
				tap(_ => this.isvcFlag.setFlagValue(EFlag.patchesDone, true))
			)
			.toPromise();
	}

	private applyPatchAsync(poPatchType: Type<PatchBase>): Promise<boolean> {
		const loPatch: PatchBase | undefined = InjectorService.instance.get<PatchBase>(poPatchType);

		if (loPatch) {
			const loPerfManager = new PerformanceManager().markStart();

			return defer(() => loPatch.applyPatchAsync())
				.pipe(
					map((pbResult: boolean) => {
						if (pbResult) {
							console.info(`${PatchService.C_LOG_ID}Patch '${loPatch.patchDescription}' executed in ${loPerfManager.markEnd().measure()}ms.`);
							return pbResult;
						}
						else
							throw new Error(`Patch '${poPatchType.name}' application failed !`);
					}),
					catchError(poError => this.onApplyPatchErrorAsync(poError, loPatch.patchDescription))
				)
				.toPromise();
		}
		else
			return this.onApplyPatchErrorAsync("Injection from the services injector is undefined", poPatchType.name);
	}

	/** Log une erreur et affiche une popup pour signaler qu'une erreur est survenue lors de l'application d'un patch.
	 * @param poError Erreur survenue.
	 * @param poPatch Patch qui n'a pas pu être appliqué.
	 */
	private onApplyPatchErrorAsync(poError: any, psPatchDescription: string): Promise<false> {
		console.error(`${PatchService.C_LOG_ID}Error when applying patch '${psPatchDescription}'`, poError);

		// On attend que l'utilisateur clique sur "continuer" pour continuer l'application des patchs.
		return this.isvcMessage.showPopupMessageAsync<boolean>(
			new ShowMessageParamsPopup({
				message: `Erreur lors de l'application du patch :\n${psPatchDescription}`,
				header: "Erreur",
				buttons: [{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse() }],
				backdropDismiss: false
			})
		) // Typage "(_): false" nécessaire pour que le compilo comprenne que ce n'est pas "boolean" mais bien "false".
			.then((_): false => false)
			.catch((_): false => false);
	}

	//#endregion

}