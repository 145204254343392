import { Injectable } from '@angular/core';
import { TaskHelper } from '@calaosoft/osapp/modules/logistics/task/helpers/task.helper';
import { ETaskStatus } from '@calaosoft/osapp/modules/logistics/task/models/ETaskStatus';
import { EKnownTaskSubType } from '@calaosoft/osapp/modules/logistics/task/models/eknown-task-sub-type';
import { EKnownTaskType } from '@calaosoft/osapp/modules/logistics/task/models/eknown-task-type';
import { ITask } from '@calaosoft/osapp/modules/logistics/task/models/itask';
import { EProvisiningState } from '../models/eprovisining-state';

@Injectable()
export class ProvisioningService {

	//#region METHODS

	constructor() { }

	public getState(poTask: ITask, paTasks: ITask[]): EProvisiningState {
		const laOriginTasks: ITask[] = this.getOriginTasks(poTask, paTasks);

		// Si pas de tâche d'origine ou pas provisionnée automatiquement, la tâche doit être réalisée.
		if (!TaskHelper.isProvisioningTask(poTask) || laOriginTasks.length === 0)
			return EProvisiningState.mustBeDone;

		// Si toutes les tâches d'origine sont validées.
		if (TaskHelper.areAllInStates(laOriginTasks, [ETaskStatus.done])) {
			// Et si la tâche de comptage est 'toDo'/'active' alors elle est en cours de génération, sinon elle a été générée.
			return TaskHelper.isInState(poTask, [ETaskStatus.toDo, ETaskStatus.active]) ?
				EProvisiningState.generating : EProvisiningState.generated;
		}

		// Si au moins une tâche n'est ni annulée, ni terminée.
		if (!TaskHelper.areAllInStates(laOriginTasks, [ETaskStatus.done, ETaskStatus.cancelled]))
			return EProvisiningState.hidden;

		// Si au moins une tâche d'origine a été annulée, la tâche doit être réalisée.
		if (TaskHelper.isOneInState(laOriginTasks, [ETaskStatus.cancelled]))
			return EProvisiningState.mustBeDone;

		// Si toutes les tâches d'origine sont 'à faire' ou 'active', la tâche doit être cachée.
		if (TaskHelper.areAllInStates(laOriginTasks, [ETaskStatus.toDo, ETaskStatus.active]))
			return EProvisiningState.hidden;

		// Par défaut, une tâche doit être réalisée.
		return EProvisiningState.mustBeDone;
	}

	/** Retourne les tâches d'origine d'une tâche donnée. */
	public getOriginTasks(poTask: ITask, paTasks: ITask[]): ITask[] {
		const laOriginTasks: ITask[] = [];

		if (poTask.taskType === EKnownTaskType.counting) {
			// Les tâches d'origine des tâches de comptage sont: Inventaire, Reprise Intégrale et Reprise Changement de contrat.
			laOriginTasks.push(
				...paTasks.filter((poTestedTask: ITask) => {
					if (poTestedTask.taskType === EKnownTaskType.inventory)
						return true;

					if ((poTestedTask.taskType === EKnownTaskType.return) &&
						(poTestedTask.taskSubType === EKnownTaskSubType.integrale || poTestedTask.taskSubType === EKnownTaskSubType.integralePourChangementContrat)) {

						return true;
					}

					return false;
				}));
		}

		return laOriginTasks;
	}

	//#endregion METHODS

}