import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, ModalOptions } from '@ionic/angular';
import { EMPTY, Observable, combineLatest, from, of } from 'rxjs';
import { filter, map, mergeMap, take, tap, toArray } from 'rxjs/operators';
import { GalleryAddModalComponent } from '../../../../components/gallery/components/gallery-add-modal/gallery-add-modal.component';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { GuidHelper } from '../../../../helpers/guidHelper';
import { IdHelper } from '../../../../helpers/idHelper';
import { PathHelper } from '../../../../helpers/path-helper';
import { EPrefix } from '../../../../model/EPrefix';
import { IGalleryFile } from '../../../../model/gallery/IGalleryFile';
import { PlatformService } from '../../../../services/platform.service';
import { IDmsMeta } from '../../../dms/model/IDmsMeta';
import { DmsService } from '../../../dms/services/dms.service';
import { EntityModalComponent } from '../../../entities/components/entity-modal/entity-modal.component';
import { Entity } from '../../../entities/models/entity';
import { IEntityModalParams } from '../../../entities/models/ientity-modal-params';
import { EntitiesService } from '../../../entities/services/entities.service';
import { ModalComponentBase } from '../../../modal';
import { ModalService } from '../../../modal/services/modal.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../../observable/models/observable-array';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { ESelectorDisplayMode } from '../../../selector/selector/ESelectorDisplayMode';
import { ISelectOption } from '../../../selector/selector/ISelectOption';
import { Queue } from '../../../utils/queue/decorators/queue.decorator';
import { secure } from '../../../utils/rxjs/operators/secure';
import { Document } from '../../models/document';
import { Folder } from '../../models/folder';
import { FolderContent } from '../../models/folder-content';
import { IAddDocumentParams } from '../../models/iadd-document-modal-params';
import { DocExplorerDocumentsService } from '../../services/doc-explorer-documents.service';

enum ENextStep {
	form = "form",
	file = "file"
}

@Component({
	selector: 'calao-add-document-modal',
	templateUrl: './add-document-modal.component.html',
	styleUrls: ['./add-document-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDocumentModalComponent extends ModalComponentBase<Document | undefined> implements IAddDocumentParams, OnInit {

	//#region PROPERTIES

	/** @implements */
	@Input() public path?: string;
	@ObserveProperty<AddDocumentModalComponent>({ sourcePropertyKey: "path" })
	public readonly observablePath = new ObservableProperty<string | undefined>();

	/** @implements */
	@Input() public parentEntity?: Entity;
	@ObserveProperty<AddDocumentModalComponent>({ sourcePropertyKey: "parentEntity" })
	public readonly observableParentEntity = new ObservableProperty<Entity | undefined>();

	@Input() public activatedRoute: ActivatedRoute;

	/** Catégorie de l'entité parent. */
	public readonly observableParentEntityCategory = new ObservableProperty<string | undefined>();

	/** Liste des options. */
	public readonly observableFoldersOptions = new ObservableArray<ISelectOption<Folder>>([]);

	/** Dossier courant. */
	public readonly observableCurrentFolder = new ObservableProperty<Folder | undefined>();

	/** Dossier sélectionné. */
	public readonly observableSelectedFolder = new ObservableProperty<Folder | undefined>();

	/** Indique si on a la possibilité . */
	public readonly observableHasForm = new ObservableProperty<boolean>(false);

	/** Liste des options pour l'étape suivante. */
	public readonly nextStepOptions: ISelectOption<ENextStep>[] = [
		{ label: "Nouveau formulaire", value: ENextStep.form },
		{ label: "Importer un fichier", value: ENextStep.file }
	];

	/** Etape suivante sélectionnée. */
	public readonly observableSelectedNextStep = new ObservableProperty<ENextStep>(ENextStep.file);

	/** Mode de sélection. */
	public readonly selectorDisplayMode = ESelectorDisplayMode;
	/** Mode de sélection. */
	public readonly nextStep = ENextStep;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcDocExplorerDocuments: DocExplorerDocumentsService,
		private readonly isvcModal: ModalService,
		private readonly isvcDms: DmsService,
		private readonly isvcEntities: EntitiesService,
		private readonly ioRouter: Router,
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.observableParentEntity.value$.pipe(
			filter((poEntity?: Entity) => !!poEntity),
			tap((poEntity: Entity) => this.observableParentEntityCategory.value = this.isvcEntities.getEntityName(poEntity)),
			secure(this)
		).subscribe();

		this.observablePath.value$.pipe(
			filter((psPath?: string) => !!psPath),
			map((psPath: string) => PathHelper.parsePath(psPath)),
			mergeMap((psPath: string) => this.isvcDocExplorerDocuments.getFolderContent$(psPath)),
			mergeMap((poFolderContent: FolderContent) => from(poFolderContent.folders).pipe(
				tap((poSubFolder: FolderContent) => {
					if (this.isvcDocExplorerDocuments.checkFolderPermissions(poSubFolder.current.path, "create", false))
						this.observableFoldersOptions.push({ label: poSubFolder.current.name, value: poSubFolder.current });
				}),
				toArray(),
				tap(() => {
					if (!this.observableFoldersOptions.length)
						this.observableSelectedFolder.value = poFolderContent.current;
				})
			)),
			secure(this)
		).subscribe();

		this.observableSelectedFolder.value$.pipe(
			tap((poFolder?: Folder) => {
				const pbHasForm: boolean = ArrayHelper.hasElements(poFolder?.forms);
				this.observableHasForm.value = pbHasForm;
				if (!pbHasForm) {
					this.observableSelectedNextStep.value = ENextStep.file;
					this.onSubmitAsync();
				}
			}),
			secure(this)
		).subscribe();
	}

	public onFolderChanged(paSelectedFolders: Folder[]): void {
		this.observableSelectedFolder.value = ArrayHelper.getFirstElement(paSelectedFolders);
	}

	public onNextStepChanged(paSelectedNextStep: ENextStep[]): void {
		this.observableSelectedNextStep.value = ArrayHelper.getFirstElement(paSelectedNextStep);
	}

	public onSubmitAsync(): Promise<boolean> {
		return this.submit$().toPromise();
	}

	@Queue<AddDocumentModalComponent, Parameters<AddDocumentModalComponent["submit$"]>, ReturnType<AddDocumentModalComponent["submit$"]>>({
		excludePendings: true
	})
	private submit$(): Observable<boolean> {
		return combineLatest([this.observableSelectedFolder.value$, this.observableSelectedNextStep.value$]).pipe(
			take(1),
			mergeMap(([poFolder, peNextStep]: [Folder, ENextStep]) => {
				if (peNextStep === ENextStep.file)
					return this.addDocument$(poFolder);
				else
					return this.addFromFormAsync(poFolder);
			})
		);
	}

	private addDocument$(poFolder: Folder): Observable<boolean> {
		return this.isvcModal.open<IGalleryFile>({
			component: GalleryAddModalComponent,
			componentProps: { title: "Nouveau document", multiple: false }
		}).pipe(
			filter((poFile?: IGalleryFile) => !!poFile),
			mergeMap((poFile: IGalleryFile) => {
				if (poFile.file)
					return this.isvcDms.save(poFile.file, poFile.file.createDmsMeta(poFile.guid, undefined, poFolder.lastPathPart, [poFolder.path]));
				return EMPTY;
			}),
			mergeMap((poDmsData?: IDmsMeta) => {
				if (!!poDmsData)
					return this.isvcDocExplorerDocuments.getDocumentById$(IdHelper.buildId(EPrefix.dmsDoc, GuidHelper.extractGuid(poDmsData._id)), false);
				return of(undefined);
			}),
			mergeMap((poDocument: Document | undefined) => this.close(poDocument))
		);
	}

	private async addFromFormAsync(poFolder: Folder): Promise<boolean> {
		const loModalParams: IEntityModalParams = {
			entityDescGuid: IdHelper.getGuidFromId(ArrayHelper.getFirstElement(poFolder.forms).descriptor, EPrefix.entityDesc),
			entityGuid: undefined,
			context: { state: { paths: [PathHelper.preparePath(poFolder.path)] }, relativeTo: this.activatedRoute },
			isEdit: true,
			closeAfterSave: true
		};

		const loModalOptions: ModalOptions = {
			component: EntityModalComponent,
			componentProps: loModalParams
		};

		const loDocument: Entity | undefined = await this.isvcModal.open<Entity>(loModalOptions).toPromise();

		if (!loDocument)
			return false;
		return this.isvcDocExplorerDocuments.getDocumentById$(loDocument._id, false).pipe(
			take(1),
			map((poDocument: Document | undefined) => {
				this.close(poDocument);
				return !!poDocument;
			})
		).toPromise();
	}

	public getName(poEntity: Entity): string {
		return this.isvcEntities.getEntityName(poEntity);
	}

	//#endregion

}
