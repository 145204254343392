import { ObjectHelper } from '../../../../helpers/objectHelper';

export class WrongIdFormatError extends Error {

	constructor() {
		super("Impossible d'obtenir l'identifiant");
		// Nécessaire pour que le 'instanceof WrongIdFormatError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, WrongIdFormatError);
	}

}