import { IDeliveryControlItem } from './idelivery-control-item';

export class DeliveryControlItem implements IDeliveryControlItem {

	//#region PROPERTIES

	/** @implements */
	public readonly code?: string;

	/** @implements */
	public reasonId?: string;

	/** @implements */
	public qty?: number;

	//#endregion

	//#region METHODS

	constructor(psCode?: string, pnQty?: number, psReasonId?: string) {
		this.code = psCode;
		this.qty = pnQty;
		this.reasonId = psReasonId;
	}

	//#endregion

}