import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { NumberHelper } from '../../helpers/numberHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { ITitledInputParams } from './ITitledInputParams';

@Component({
	selector: "calao-titled-input",
	templateUrl: './titled-input.component.html',
	styleUrls: ['./titled-input.component.scss']
})
export class TitledInputComponent implements AfterViewInit, ITitledInputParams {

	//#region FIELDS

	/** Envoie sous forme de nombre la valeur de l'input à chaque nouveau changement. */
	@Output("onValueChanged") private readonly moOnValueChanged = new EventEmitter<number>();
	/** Envoie sous forme de chaîne de caractères la valeur de l'input à chaque nouveau changement. */
	@Output("onStringifiedValueChanged") private readonly moOnStringifiedValueChanged = new EventEmitter<string>();

	/** Valeur de l'input avec que celui-ci ne soit créé, permet d'affecter l'input quand il sera créé. */
	private moValueBeforeIonInputCreated: string | number;

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@Input() public icon?: string;
	/** @implements */
	@Input() public label: string;
	/** @implements */
	@Input() public subLabel?: string;
	/** @implements */
	@Input() public infoLabel?: string;
	/** @implements */
	private mbFocusOnStart: boolean;
	public get focusOnStart(): boolean { return this.mbFocusOnStart; }
	@Input() public set focusOnStart(pbValue: boolean | string) {
		this.mbFocusOnStart = coerceBooleanProperty(pbValue);
	};
	/** @implements */
	@Input() public totalCategoryItems?: number;
	/** @implements */
	@Input() public readonly: boolean;

	private msValue?: string;
	/** @implements */
	public get value(): string | undefined { return this.msValue; }
	@Input() public set value(psValue: string | undefined | null) {
		if (psValue !== this.msValue && /^[0-9]+$/.test(psValue as string) && NumberHelper.isValid(+psValue) && +psValue >= this.minValue) {
			this.msValue = psValue;
			this.raiseValueChanged();
		}
		else if (this.ionInput) { // Si la valeur est invalide et que le composant d'input existe, on vide le champ.
			this.ionInput.value = "";
			this.msValue = undefined; // Passe à 'undefined' car retourne 0 avec le cast de 'null' en 'number'.
			this.raiseValueChanged();
		}
		else
			this.moValueBeforeIonInputCreated = psValue;
	}

	private mbSelectOnFocus: boolean;
	/** Indique si le texte doit être séléctionné si l'utilisateur clique dessus. */
	@Input() public set selectOnFocus(pbvalue: boolean) {
		if (this.mbSelectOnFocus !== pbvalue)
			this.mbSelectOnFocus = pbvalue;
	}

	/** Valeur minimum que peut entrer un utilisateur. */
	public readonly minValue = 0; //todo : rendre paramétrable

	@ViewChild("input", { static: false }) public ionInput: IonInput;

	//#endregion

	//#region METHODS

	constructor() { }

	public ngAfterViewInit(): void {
		// Si la valeur reçue avant création de l'input est une chaîne valide ou un nombre valide on affecte.
		if (NumberHelper.isValid(this.moValueBeforeIonInputCreated) || !StringHelper.isBlank(this.moValueBeforeIonInputCreated as string))
			this.value = this.moValueBeforeIonInputCreated.toString();

		delete this.moValueBeforeIonInputCreated;

		if (this.focusOnStart)
			setTimeout(() => this.ionInput.setFocus(), 500);
	}

	private raiseValueChanged(): void {
		this.moOnValueChanged.emit(+this.msValue);
		this.moOnStringifiedValueChanged.emit(this.msValue);
	}

	/** Permet d'enlever le focus sur l'input. */
	public blurFocus(): void {
		this.ionInput.getInputElement()
			.then((poElement: HTMLInputElement) => poElement.blur());
	}

	/** Selectionne le texte de l'input. */
	public selectValue(): void {
		if (this.mbSelectOnFocus) {
			this.ionInput.getInputElement()
				.then((poElement: HTMLInputElement) => poElement.select());
		}
	}

	//#endregion

}