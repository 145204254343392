import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigData } from "../../../../model/config/ConfigData";
import { OsappApiHelper } from "../../../osapp-api/helpers/osapp-api.helper";
import { SqlDataSource } from "../../../sqlite/models/sql-data-source";
import { SqlRemoteProvider } from "../../../sqlite/services/providers/sql-remote-provider.service";
import { CATALOG_DATABASE_ID } from "../catalog.constants";
import { ICatalogVersionResponse } from "../models/ICatalogVersionResponse";

@Injectable()
export class CatalogRemoteProviderService extends SqlRemoteProvider {

	//#region PROPERTIES

	//! Ne pas mettre le préfixe d'url dans une constante statique car ConfigData.environment est défini dans un service, plus tardivement que la construction de cet objet.
	private msUrlPrefix?: string;
	private get urlPrefix(): string {
		if (this.msUrlPrefix)
			return this.msUrlPrefix;
		else
			return this.msUrlPrefix = `${ConfigData.environment.cloud_url}/api/apps/${ConfigData.appInfo.appId}/workspaces/common/entities/items/catalog/`;
	}

	//#endregion PROPERTIES

	//#region METHODS

	public constructor(poHttpClient: HttpClient) {
		super(poHttpClient);
	}

	public override canProcess(psDatabaseId: string): boolean {
		return psDatabaseId === CATALOG_DATABASE_ID;
	}

	public override getLastUrl(psDatasourceId: string): string {
		return `${this.urlPrefix}last`;
	}

	public override getUrl(psDatabaseId: string, pnVersion: number): string {
		return `${this.urlPrefix}${pnVersion.toString()}`;
	}

	public override getLastDatabaseAsync(psDatabaseId: string): Promise<SqlDataSource> {
		return this.getRemoteLastVersionAsync(psDatabaseId)
			.then((poResult: ICatalogVersionResponse) => new SqlDataSource(psDatabaseId, +poResult.version, poResult.url));
	}

	private getRemoteLastVersionAsync(psDatasourceId: string): Promise<ICatalogVersionResponse> {
		return this.ioHttpClient.get(this.getLastUrl(psDatasourceId),
			{
				headers: new HttpHeaders({
					appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
					token: ConfigData.authentication.token,
					"api-key": ConfigData.environment.API_KEY,
					accept: "application/json"
				}),
				observe: "body",
				responseType: "json"
			}
		).toPromise() as Promise<ICatalogVersionResponse>;
	}

	//#endregion METHODS

}