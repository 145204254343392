import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { EPrefix } from '../../../../model/EPrefix';
import { ActivePageManager } from '../../../../model/navigation/ActivePageManager';
import { EDatabaseRole } from '../../../../model/store/EDatabaseRole';
import { IChangeEvent } from '../../../../model/store/IChangeEvent';
import { Store } from '../../../../services/store.service';
import { DestroyableServiceBase } from '../../../services/models/destroyable-service-base';
import { IDataSourceRemoteChanges } from '../../../store/model/IDataSourceRemoteChanges';
import { ModelResolver } from '../../../utils/models/model-resolver';
import { secure } from '../../../utils/rxjs/operators/secure';
import { DeliveryRack } from '../../task/delivery/models/delivery-rack';
import { DeliveryRackPack } from '../../task/delivery/models/delivery-rack-pack';
import { DeliveryReceipt } from '../../task/delivery/models/delivery-receipt';
import { DeliveryReceiptPack } from '../../task/delivery/models/delivery-receipt-pack';
import { IPack } from '../models/ipack';
import { Pack } from '../models/Pack';

@Injectable()
export class PackService extends DestroyableServiceBase {

	//#region FIELDS

	/** Map associant un conditionnement à son identifiant. */
	private readonly moPackById = new Map<string, Pack>();
	/** Observable permettant d'écouter les changements distants sur les conditionnements. */
	private readonly moChanges$: Observable<Pack>;

	//#endregion

	//#region METHODS

	constructor(private readonly ioRouter: Router, private readonly isvcStore: Store) {
		super();
		// Permet de lancer l'écoute des changements sur les conditionnements en base.
		this.moChanges$ = this.listenRemoteChanges().pipe(secure(this));
	}

	/** Récupère des conditionnements à partir d'identifiants.
	 * @param paPackTypeIds Liste des identifiants de conditionnements.
	 */
	public getPacksFromIds(paPackTypeIds: string[]): Observable<Pack[]> {
		return combineLatest([
			this.getPacks(paPackTypeIds),
			this.moChanges$.pipe(startWith(undefined))
		])
			.pipe(
				map((paResults: [Pack[], Pack]) => {
					if (paResults[1]) {
						const lsPackId: string = paResults[1]._id;
						ArrayHelper.replaceElementByFinder(paResults[0], (poPack: Pack) => poPack._id === lsPackId, paResults[1]);
					}
					return paResults[0];
				})
			);
	}

	/** Écoute les changements sur les conditionnements en base. */
	private listenRemoteChanges(): Observable<Pack> {
		return this.isvcStore.changes({
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			remoteChanges: true,
			live: true,
			activePageManager: new ActivePageManager(this, this.ioRouter, () => true),
			viewParams: {
				include_docs: true,
				startkey: EPrefix.pack,
				endkey: `${EPrefix.pack}${Store.C_ANYTHING_CODE_ASCII}`
			}
		} as IDataSourceRemoteChanges)
			.pipe(
				map((poChangeEvent: IChangeEvent<IPack>) => this.getInstance(poChangeEvent.document)),
				tap((poPack: Pack) => {
					if (!this.moPackById.has(poPack._id))
						this.moPackById.set(poPack._id, poPack);
				})
			);
	}

	/** Transforme un objet `IPack` en instance de `Pack` et la retourne.
	 * @param poObject Objet à transformer en instance.
	 */
	private getInstance(poObject: IPack): Pack {
		return ModelResolver.toClass(Pack, poObject);
	}

	/** Récupère un tableau de conditionnements.
	 * @param paPackIds Tableau des identifiants des conditionnements à récupérer.
	 */
	public getPacks(paPackIds: string[]): Observable<Pack[]> {
		const laCachedPacks: Pack[] = []; // Conditionnements trouvés dans le cache.
		const laNotCachedPackIds: string[] = []; // Identifiants des conditionnements à récupérer en base.

		ArrayHelper.unique(paPackIds).forEach((psId: string) =>
			this.moPackById.has(psId) ? laCachedPacks.push(this.moPackById.get(psId)) : laNotCachedPackIds.push(psId)
		);

		if (ArrayHelper.hasElements(laNotCachedPackIds)) {
			return this.isvcStore.get({
				databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
				viewParams: { keys: laNotCachedPackIds, include_docs: true }
			})
				.pipe(
					map((paPacks: IPack[]): Pack[] => {
						return paPacks.map((poPack: IPack) => {
							const loPack: Pack = this.getInstance(poPack);
							this.moPackById.set(loPack._id, loPack);
							return loPack;
						})
							.concat(laCachedPacks);
					})
				);
		}
		else
			return of(laCachedPacks);
	}

	/** Retourne un tableau de conditionnements contenus dans les bons.
	 * @param paReceipts Bons à parcourir.
	 */
	public extractReceiptPacks(paReceipts: DeliveryReceipt[]): DeliveryReceiptPack[] {
		return ArrayHelper.flat(paReceipts.map((poReceipt: DeliveryReceipt) => poReceipt.packs));
	}

	/** Retourne un tableau de conditionnements contenus dans les portants.
	 * @param paRacks Portants à parcourir.
	 * @param psRackIdToKeep Identifiant du portant qui sert pour le filtrage.
	 */
	private extractRackPacks(paRacks: DeliveryRack[], psRackIdToKeep: string): DeliveryRackPack[] {
		return ArrayHelper.flat(
			paRacks.filter((poRack: DeliveryRack) => poRack._id === psRackIdToKeep).map((poRack: DeliveryRack) => poRack.packs)
		);
	}

	public getPacksFromCurrentRack(poDeliveryRack: DeliveryRack): DeliveryRackPack[];
	public getPacksFromCurrentRack(paDeliveryRack: DeliveryRack[], psCurrentRackId: string): DeliveryRackPack[];
	public getPacksFromCurrentRack(poRackData: DeliveryRack[] | DeliveryRack, psCurrentRackId?: string): DeliveryRackPack[] {
		if (poRackData instanceof Array) {
			if (ArrayHelper.hasElements(poRackData))
				return this.extractRackPacks(poRackData, psCurrentRackId);
			else
				return [];
		}
		else
			return poRackData.packs;
	}

	/** Récupère les conditionnements d'un portant.
	 * @param poRack Portant dont il faut récupérer les conditionnements.
	 */
	public getRackPacks(poRack: DeliveryRack): DeliveryRackPack[];
	/** Récupère les conditionnements de portants.
	 * @param paRacks Tableau des portants dont il faut récupérer les conditionnements.
	 */
	public getRackPacks(paRacks: DeliveryRack[]): DeliveryRackPack[];
	public getRackPacks(poRackData: DeliveryRack[] | DeliveryRack): DeliveryRackPack[] {
		if (poRackData instanceof Array) {
			const laPacks: DeliveryRackPack[] = [];
			poRackData.forEach((poRack: DeliveryRack) => {
				this.fillPackRackStatus(poRack.packs, poRack);
				laPacks.push(...poRack.packs);
			});

			return laPacks;
		}
		else {
			this.fillPackRackStatus(poRackData.packs, poRackData);
			return poRackData.packs;
		}
	}

	/** Ajoute l'état du portant dans l'objet `DeliveryRackPack`.
	 * @param poRackPacks Conditionnements du portant.
	 * @param poRack Portant.
	 */
	private fillPackRackStatus(poRackPacks: DeliveryRackPack[], poRack: DeliveryRack): void {
		poRackPacks.forEach((poRackPack: DeliveryRackPack) => poRackPack.rackStatus = poRack.status);
	}

	//#endregion

}