import { ArrayHelper } from "../../../../../helpers/arrayHelper";
import { ResolveModel } from "../../../../utils/models/decorators/resolve-model.decorator";
import { ItemPrice } from "../../models/item-price";
import { DeliveryItem } from "./delivery-item";
import { IDeliveryReceiptPack } from "./idelivery-receipt-pack";

export class DeliveryReceiptPack implements IDeliveryReceiptPack {

	//#region PROPERTIES

	/** @implements */
	public readonly code: string;

	/** @implements */
	public readonly label: string;

	/** @implements */
	@ResolveModel(DeliveryItem)
	public readonly items: ReadonlyArray<DeliveryItem>;

	/** @implements */
	public readonly packTypeId?: string;

	/** @implements */
	public readonly allowMissingItems?: boolean;

	//#region METHODS

	constructor(psCode: string, psLabel: string, paItems?: DeliveryItem[], psPackTypeId?: string) {
		this.code = psCode;
		this.label = psLabel;
		this.items = paItems ?? [];
		this.packTypeId = psPackTypeId;
	}

	/** Récupère le tableau des prix d'un conditionnement de bon, sans doublon. */
	public getPrices(): ItemPrice[] {
		return ArrayHelper.unique(
			this.items.map((poItem: DeliveryItem) => poItem.price),
			(poPrice: ItemPrice) => poPrice.uid
		);
	}

	//#endregion

}