/** Empêche la surcharge d'une fonction depuis une classe héritée. */
export function final():
	(poTarget: any, psMethodName: string, poDescriptor: TypedPropertyDescriptor<any>) => TypedPropertyDescriptor<any> {

	return function (poTarget: any, psMethodName: string, poDescriptor: TypedPropertyDescriptor<any>)
		: TypedPropertyDescriptor<any> {

		// https://stackoverflow.com/a/61701406/6595016
		poDescriptor.writable = false;

		return poDescriptor;
	};
};