import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EInput } from '../../../../../../model/uiMessage/EInput';
import { FormHelper } from '../../../../helpers/formHelper';
import { FieldBase } from '../../../../models/FieldBase';
import { ITemplateOptions } from '../../../../models/fieldComponent/ITemplateOptions';
import { IOneTimeFilledField } from '../../../../models/fieldComponent/ione-time-filled-field';
import { IInputField } from '../../../../models/fieldComponent/specifications/IInputField';
import { FormsService } from '../../../../services/forms.service';

@Component({
	templateUrl: './inputField.component.html',
	styleUrls: ['./inputs.component.scss']
})
export class InputFieldComponent extends FieldBase<string | number> implements IInputField, OnInit, IOneTimeFilledField { //todo : params.

	//#region FIELDS

	/** Nombre maximum de chiffres pour un numéro de téléphone (10 chiffres, ou un préfixe (+33) et 9 chiffres). */
	private static readonly C_TEL_MAX_DIGITS = 12;

	//#endregion

	//#region PROPERTIES

	@ViewChild("input") public input: ElementRef;

	/** Valeur réellement affichée. */
	private msDisplayValue: string | number;

	public get displayValue(): string | number {
		return this.msDisplayValue;
	}
	public set displayValue(psDisplayValue: string | number) {
		if (this.msDisplayValue !== psDisplayValue) {
			this.msDisplayValue = psDisplayValue;
			this.onModelChanged();
		}
	}

	/** Définit la clavier à afficher pour la navigateur et des critères de gestion supplémentaire sont appliqués si le type est `tel`. */
	public inputType: string = EInput.text;
	/** `true` si la valeur attendue est un numéro de téléphone. */
	public isTel = false;
	/** Taille max de caractères sur le champ. */
	public maxLength: number;
	/** Indique quel type de champ on traite. */
	public type: string;
	/** Paramètres supplémentaires du champ. */
	public templateOptions: ITemplateOptions<never>;

	public wasFilled: boolean;

	//#endregion

	//#region METHODS

	constructor(psvcForms: FormsService) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.wasFilled = !!this.fieldValue;

		if (this.to.type) {

			if (this.to.type === EInput.zipCode)
				this.inputType = EInput.text;
			else
				this.inputType = this.to.type;

			if (this.inputType === EInput.number)
				this.msDisplayValue = this.fieldValue;
			else if (this.to.type === EInput.tel)
				this.initTelValue();
			else
				this.msDisplayValue = this.fieldValue ? this.fieldValue : "";
		}
		else
			this.msDisplayValue = this.fieldValue ? this.fieldValue : "";

		if (this.to.data)
			this.to.disabled = !FormHelper.canBeFilled(this.to.data.oneTimeFilled, this, this.fieldValue);

		if (this.input && this.to.focus)
			this.input.nativeElement.focus();
	}

	/** Initialise des attributs de classe si le type de la valeur est `tel`. */
	public initTelValue(): void {
		this.isTel = true;
		this.maxLength = InputFieldComponent.C_TEL_MAX_DIGITS;
		this.setTelValue(this.fieldValue as string);
	}

	/** Récupère un numéro de téléphone dans n'importe quel format, et l'applique à la variable `displayValue` dans un format utilisateur `06...`. */
	private setTelValue(psValue: string): void {
		if (psValue && psValue.startsWith("+33"))
			this.msDisplayValue = `0${psValue.slice(3, psValue.length)}`;
		else
			this.msDisplayValue = psValue;
	}

	public onModelChanged(): void {
		if (this.inputType === EInput.number)
			this.fieldValue = new Number(this.fieldValue).valueOf();

		this.markAsDirty();
	}

	//#endregion
}