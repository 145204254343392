import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertButton } from '@ionic/core';
import { Observable, combineLatest, defer, of, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { GuidHelper } from '../../../helpers/guidHelper';
import { IdHelper } from '../../../helpers/idHelper';
import { MapHelper } from '../../../helpers/mapHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { EPrefix } from '../../../model/EPrefix';
import { IIndexedArray } from '../../../model/IIndexedArray';
import { IIndexedObject } from '../../../model/IIndexedObject';
import { ConfigData } from '../../../model/config/ConfigData';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { EAvatarSize } from '../../../model/picture/EAvatarSize';
import { IAvatar } from '../../../model/picture/IAvatar';
import { ERouteUrlPart } from '../../../model/route/ERouteUrlPart';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IUiResponse } from '../../../model/uiMessage/IUiResponse';
import { FlagService } from '../../../services/flag.service';
import { ShowMessageParamsPopup } from '../../../services/interfaces/ShowMessageParamsPopup';
import { LoadingService } from '../../../services/loading.service';
import { PatternResolverService } from '../../../services/pattern-resolver.service';
import { Store } from '../../../services/store.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { IFormDefinition } from '../../forms/models/IFormDefinition';
import { IListDefinition } from '../../forms/models/IListDefinition';
import { Loader } from '../../loading/Loader';
import { EPermission } from '../../permissions/models/EPermission';
import { EPermissionsFlag } from '../../permissions/models/EPermissionsFlag';
import { PermissionsService } from '../../permissions/services/permissions.service';
import { DestroyableServiceBase } from '../../services/models/destroyable-service-base';
import { ModelResolver } from '../../utils/models/model-resolver';
import { IGetVersionedDocumentsParams } from '../../versioned-documents/models/iget-versioned-documents-params';
import { VersionedDocumentsService } from '../../versioned-documents/services/versioned-documents.service';
import { EEntityFlag } from '../models/eentity-flag';
import { Entity } from '../models/entity';
import { IEntity } from '../models/ientity';
import { IEntityCategory } from '../models/ientity-category';
import { IEntityDescriptor } from '../models/ientity-descriptor';

interface IDescriptorContext extends IIndexedArray<any> {
	entry: Entity;
	entityDescriptor: IEntityDescriptor;
	guid: string;
	permissions: ICanPermissions;
}

interface ICanPermissions {
	canCreate: boolean;
	canEdit: boolean;
	canDelete: boolean;
}

@Injectable({
	providedIn: "root"
})
export class EntitiesService extends DestroyableServiceBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "ENTITIES.S::";
	/** URL de base pour accéder aux descripteurs de formulaires locaux.  */
	private static readonly C_LOCAL_DESCRIPTORS_BASE_URL = "/entities/descriptors/";
	/** Extension des descripteurs de formulaires locaux.  */
	private static readonly C_LOCAL_DESCRIPTORS_EXTENSION = ".entityDesc.json";
	private static C_ENTITY_DESCRIPTORS_BY_GUID_CACHE = new Map<string, IEntityDescriptor>();

	private readonly moActivePageManager = new ActivePageManager(this, this.ioRouter, () => true); // On force l'activité en permanence

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcStore: Store,
		private readonly isvcPatternResolver: PatternResolverService,
		private readonly isvcPermissions: PermissionsService,
		private readonly ioRouter: Router,
		private readonly isvcFlags: FlagService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcVersionedDocuments: VersionedDocumentsService,
		private readonly isvcUiMessage: UiMessageService
	) {
		super();
	}

	public async initAsync(): Promise<void> {
		// On ne fait l'init que si elle n'a pas déjà été faite.
		if (!this.isvcFlags.getFlagValue(EEntityFlag.entitiesReady)) {
			await this.isvcFlags.waitForFlagAsync(EPermissionsFlag.isLoaded, true);

			const loLoader: Loader = await this.isvcLoading.create("Chargement des entités");
			await loLoader.present();

			const loGetVersionedDocumentsParams: IGetVersionedDocumentsParams = {
				prefix: EPrefix.entityDesc,
				suffix: EntitiesService.C_LOCAL_DESCRIPTORS_EXTENSION,
				ids: ConfigData.builtInEntityDescIds,
				baseUrl: EntitiesService.C_LOCAL_DESCRIPTORS_BASE_URL,
				roles: [EDatabaseRole.formsDefinitions],
				activePageManager: this.moActivePageManager
			};

			this.isvcVersionedDocuments.getVersionedDocumentsByGuid$<IEntityDescriptor>(loGetVersionedDocumentsParams).pipe(
				tap((poResult: Map<string, IEntityDescriptor>) => {
					EntitiesService.C_ENTITY_DESCRIPTORS_BY_GUID_CACHE = poResult;
					loLoader.dismiss();
					this.isvcFlags.setFlagValue(EEntityFlag.entitiesReady, true);
				}),
				finalize(() => loLoader.dismiss())
			).subscribe();

			await this.isvcFlags.waitForFlagAsync(EEntityFlag.entitiesReady, true);
		}
	}

	public getEntityDescGuidFromEntityDescId(psEntityDescId?: string): string {
		return psEntityDescId?.split("_")[1] ?? "";
	}

	/** Récupère la description dont l'id est en paramètre sur la base de données associée aux descriptions d'entités (descriptionsDb).
	 * @param psEntityDescGuidOrId id du descripteur d'entité.
	 */
	public getDescriptor$(
		psEntityDescGuidOrId: string,
		psEntityGuid?: string,
		poActivePageManager?: ActivePageManager,
		poContext?: IIndexedObject
	): Observable<IEntityDescriptor | undefined> {
		return defer(() => {
			const loDescriptor: IEntityDescriptor | undefined = EntitiesService.C_ENTITY_DESCRIPTORS_BY_GUID_CACHE.get(
				IdHelper.getGuidFromId(psEntityDescGuidOrId, EPrefix.entityDesc)
			);

			if (loDescriptor) {
				console.debug(`${EntitiesService.C_LOG_ID}Form descriptor ${loDescriptor._id} is selected for application version ${ConfigData.appInfo.appVersion}.`);
				return of(ObjectHelper.clone(loDescriptor)); // On clone pour ne pas polluer le cache.
			}
			else
				return throwError(new Error(`Aucun formulaire trouvé pour le type "${psEntityDescGuidOrId}"`));
		}).pipe(
			switchMap((poDesc: IEntityDescriptor) =>
				this.hydrateEntityDescriptor(poDesc, poContext, psEntityGuid, poActivePageManager)
			),
			catchError(poError => {
				console.error(`${EntitiesService.C_LOG_ID}Erreur récupération des données :`, poError);
				return throwError(poError);
			})
		);
	}

	/** Récupère la description dont l'id est en paramètre.
	 * @param psEntityDescUid id du descripteur d'entité.
	 */
	public getDescriptor(psEntityDescUid: string,): IEntityDescriptor | undefined {
		return EntitiesService.C_ENTITY_DESCRIPTORS_BY_GUID_CACHE.get(psEntityDescUid);
	}

	private hydrateEntityDescriptor(
		poDescriptor: IEntityDescriptor,
		poContext?: IIndexedArray<any>,
		psEntityGuid?: string,
		poActivePageManager?: ActivePageManager
	): Observable<IEntityDescriptor> {
		const lsEntityId: string = this.getEntityId(poDescriptor, psEntityGuid);
		const lsParentEntityId: string = this.getParentEntityId(poDescriptor, poContext);
		return combineLatest([
			this.getModel$(lsEntityId, undefined, poActivePageManager),
			this.getModel$(lsParentEntityId, undefined, poActivePageManager)
		]).pipe(
			map(([poEntity, poParentEntity]: [Entity | undefined, Entity | undefined]) => {
				const loDesc: IEntityDescriptor = ObjectHelper.clone(poDescriptor);
				const loContext: IDescriptorContext = this.createDescriptorContext(poContext, loDesc, psEntityGuid, poDescriptor, poParentEntity);

				this.prepareDescriptorEntry(loDesc, poEntity, lsEntityId, poDescriptor, loContext);

				loContext.entry = loDesc.entry;
				loDesc.forms = this.isvcPatternResolver.resolveContextualPatterns(loDesc.forms, loContext);
				loDesc.layouts = this.isvcPatternResolver.resolveContextualPatterns(loDesc.layouts, loContext);
				loDesc.lists = this.isvcPatternResolver.resolveContextualPatterns(loDesc.lists, loContext);
				return loDesc;
			})
		);
	}

	private createDescriptorContext(
		poContext: IIndexedArray<any> | undefined,
		poDesc: IEntityDescriptor,
		psEntityGuid: string | undefined,
		poDescriptor: IEntityDescriptor,
		poParentEntity?: Entity
	): IDescriptorContext {
		return {
			entry: poDesc.entry,
			parentEntry: poParentEntity,
			entityDescriptor: poDesc,
			guid: psEntityGuid ?? GuidHelper.newGuid(),
			permissions: {
				canCreate: this.isvcPermissions.evaluatePermission(poDescriptor.permissionScope as EPermission, "create"),
				canEdit: this.isvcPermissions.evaluatePermission(poDescriptor.permissionScope as EPermission, "edit"),
				canDelete: this.isvcPermissions.evaluatePermission(poDescriptor.permissionScope as EPermission, "delete")
			},
			...(poContext ?? {})
		};
	}

	private prepareDescriptorEntry(
		poDesc: IEntityDescriptor,
		poModel: Entity | undefined,
		psEntityId: string,
		poDescriptor: IEntityDescriptor,
		poContext: IDescriptorContext
	): void {
		poDesc.entry = ModelResolver.toClass(
			Entity,
			ObjectHelper.assign(poDesc.entry, ModelResolver.toPlain(
				poModel ??
				{
					_id: psEntityId,
					...(
						poDescriptor.baseModel ?
							this.isvcPatternResolver.resolveContextualPatterns(poDesc.baseModel, poContext) :
							{}
					)
				}))
		);
	}

	public getEntityId(poDescriptor: IEntityDescriptor, psEntityGuid: string | undefined): string {
		return this.isvcPatternResolver.resovleStringPattern(poDescriptor.idPattern, { guid: psEntityGuid ?? GuidHelper.newGuid() });
	}

	public getParentEntityId(poDescriptor: IEntityDescriptor, poContext?: IIndexedArray<any>): string {
		return this.isvcPatternResolver.resovleStringPattern(poDescriptor.parentEntityIdPattern, poContext);
	}

	public getModel$(
		psModelId?: string,
		psDatabaseId?: string,
		poActivePageManager?: ActivePageManager
	): Observable<Entity | undefined> {
		if (StringHelper.isBlank(psModelId))
			return of(undefined);

		return this.isvcStore.getOne(
			{
				databaseId: psDatabaseId,
				role: StringHelper.isBlank(psDatabaseId) ? EDatabaseRole.workspace : undefined,
				viewParams: {
					key: psModelId,
					include_docs: true
				},
				live: true,
				remoteChanges: !!poActivePageManager,
				activePageManager: poActivePageManager,
				baseClass: Entity
			},
			false
		);
	}

	public getModels$(
		paEntityPaths: string[],
		poActivePageManager?: ActivePageManager
	): Observable<Entity[]> {
		if (!ArrayHelper.hasElements(paEntityPaths))
			return of([]);

		const laGetObservables: Observable<Entity[]>[] = [];

		ArrayHelper.groupBy(paEntityPaths, (psPath: string) => Store.getDatabaseIdFromDocumentPath(psPath)).forEach(
			(paPaths: string[], psDatabaseId: string) => {
				if (ArrayHelper.hasElements(paPaths)) {
					laGetObservables.push(
						this.isvcStore.get(
							{
								databaseId: psDatabaseId,
								role: StringHelper.isBlank(psDatabaseId) ? EDatabaseRole.workspace : undefined,
								viewParams: {
									keys: paPaths.map((psPath: string) => Store.getDocumentIdFromPath(psPath)),
									include_docs: true
								},
								live: true,
								remoteChanges: !!poActivePageManager,
								activePageManager: poActivePageManager,
								baseClass: Entity
							}
						)
					);
				}
			}
		);

		return combineLatest(laGetObservables).pipe(
			map((paResults: Entity[][]) => paResults.flat())
		);
	}

	public getModelAsync(
		psModelId?: string,
		psDatabaseId?: string
	): Promise<Entity | undefined> {
		return this.getModel$(psModelId, psDatabaseId).pipe(take(1)).toPromise();
	}

	public getModelsAsync(
		paEntityPaths: string[]
	): Promise<Entity[]> {
		return this.getModels$(paEntityPaths).pipe(take(1)).toPromise();
	}

	/** Retourne la définition de formulaire correspondant à l'id passé en paramètre, `undefined` si non trouvée.
	 * @param poDescriptor
	 * @param psDefinitionId
	 */
	public getDefinition(poDescriptor: IEntityDescriptor, psDefinitionId: string): IFormDefinition | undefined {
		return poDescriptor.forms[psDefinitionId];
	}

	/** Retourne la définition de liste correspondant à l'id passé en paramètre, `undefined` si non trouvée.
	 * @param poDescriptor
	 * @param psDefinitionId
	 */
	public getListDefinition(poDescriptor: IEntityDescriptor, psDefinitionId: string): IListDefinition | undefined {
		return poDescriptor.lists[psDefinitionId];
	}

	/** Retourne la source de données correspondant à l'id passé en paramètre, `undefined` si non trouvée.
	 * @param poDescriptor
	 * @param psDefinitionId
	 */
	public getDataSource(poDescriptor: IEntityDescriptor, psDefinitionId: string): IDataSource | undefined {
		if (poDescriptor.dataSources)
			return poDescriptor.dataSources[psDefinitionId];
		return undefined;
	}

	public navigateToEntityViewAsync(psEntityGuid: string, psEntityDescGuid: string, poActivatedRoute: ActivatedRoute): Promise<boolean> {
		return this.ioRouter.navigate([
			"entities",
			psEntityDescGuid,
			psEntityGuid
		],
			{ relativeTo: poActivatedRoute }
		);
	}

	public navigateToEntityEditAsync(psEntityGuid: string, psEntityDescGuid: string, poActivatedRoute: ActivatedRoute): Promise<boolean> {
		return this.ioRouter.navigate([
			"entities",
			psEntityDescGuid,
			psEntityGuid,
			ERouteUrlPart.edit
		],
			{ relativeTo: poActivatedRoute }
		);
	}

	public getEntityDescriptor(poEntity?: IEntity): IEntityDescriptor | undefined {
		let loDescriptor: IEntityDescriptor | undefined;

		if (!StringHelper.isBlank(poEntity?.meta?.entityDescId))
			loDescriptor = EntitiesService.C_ENTITY_DESCRIPTORS_BY_GUID_CACHE.get(this.getEntityDescGuidFromEntityDescId(poEntity?.meta?.entityDescId));
		else if (poEntity) {
			const laDescriptors: IEntityDescriptor[] = MapHelper.valuesToArray(EntitiesService.C_ENTITY_DESCRIPTORS_BY_GUID_CACHE);
			for (let lnIndex = 0; lnIndex < laDescriptors.length; ++lnIndex) {
				const loCacheDescriptor: IEntityDescriptor = laDescriptors[lnIndex];

				if (this.isCorrectEntityDescriptor(loCacheDescriptor, poEntity)) {
					loDescriptor = loCacheDescriptor;
					break;
				}
			}
		}

		return loDescriptor;
	}

	private isCorrectEntityDescriptor(poDescriptor: IEntityDescriptor, poEntity: IEntity): boolean {
		return StringHelper.isValid(poDescriptor.entityMatchPattern) &&
			coerceBooleanProperty(
				this.isvcPatternResolver.resovleStringPattern(
					poDescriptor.entityMatchPattern,
					{ entry: poEntity }
				)
			);
	}

	public getEntityName(poEntity?: IEntity): string {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);

		return loDescriptor ?
			this.isvcPatternResolver.resovleStringPattern(loDescriptor.namePattern, { entry: poEntity }) :
			"Entité inconnue";
	}

	public getEntityShortName(poEntity?: IEntity): string {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);

		return loDescriptor ?
			this.isvcPatternResolver.resovleStringPattern(
				loDescriptor.shortNamePattern ?? loDescriptor.namePattern,
				{ entry: poEntity }
			) :
			"Inconnue";
	}

	public getEntityCategory(poEntity: IEntity): IEntityCategory {
		return this.getEntityDescCategory(this.getEntityDescriptor(poEntity));
	}

	public getEntityDescCategory(poEntityDesc?: IEntityDescriptor): IEntityCategory {
		return poEntityDesc?.category ?? { name: "Catégorie inconnue" };
	}

	public getEntityRoute(poEntity: IEntity): string {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);

		return loDescriptor ?
			this.isvcPatternResolver.resovleStringPattern(
				loDescriptor.routePattern,
				{ entry: poEntity, entryGuid: GuidHelper.extractGuid(poEntity._id), entityDescriptor: loDescriptor }
			) :
			"";
	}

	public getEntityAvatar(poEntity: IEntity): IAvatar | undefined {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);

		return loDescriptor?.avatar ?
			{
				size: typeof loDescriptor.avatar.size === "string" ?
					this.isvcPatternResolver.resolveContextualPattern(loDescriptor.avatar.size, { entry: poEntity }) :
					EAvatarSize.medium, // Taille par défaut
				guid: this.isvcPatternResolver.resovleStringPattern(loDescriptor.avatar.guid, { entry: poEntity }),
				icon: this.isvcPatternResolver.resovleStringPattern(loDescriptor.avatar.icon, { entry: poEntity })
			} :
			undefined;
	}

	public getEntityDocumentPaths(poEntity: IEntity): string[] {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);
		const leEntityPrefix: EPrefix = IdHelper.getPrefixFromId(poEntity._id);

		return ArrayHelper.hasElements(loDescriptor?.documentPathPatterns) ?
			ArrayHelper.getValidValues(loDescriptor.documentPathPatterns.map((psPattern: string) => this.isvcPatternResolver.resovleStringPattern(
				psPattern,
				{ entry: poEntity }
			))) :
			loDescriptor ?
				[`${ConfigData.documentsConfiguration?.defaultPathPrefix ? `${ConfigData.documentsConfiguration.defaultPathPrefix}\\` : ""}${leEntityPrefix.replace("_", "")}\\${IdHelper.getGuidFromId(poEntity._id, leEntityPrefix)}`] :
				[];
	}

	public getEntityRole(poEntity: IEntity): EDatabaseRole {
		const loDescriptor: IEntityDescriptor | undefined = this.getEntityDescriptor(poEntity);

		return loDescriptor?.role ?? EDatabaseRole.workspace;
	}

	public showUnsavedEntityPopupAsync(): Promise<boolean> {
		return this.isvcUiMessage.showAsyncMessage(
			new ShowMessageParamsPopup({
				message: "Des modifications ont été réalisées. Si vous continuez, les données modifiées seront perdues. Voulez-vous vraiment continuer ?",
				header: "Modifications non enregistrées",
				buttons: [
					{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Continuer", handler: () => UiMessageService.getTruthyResponse(), cssClass: "rounded-popup-button" }
				] as AlertButton[],
				backdropDismiss: false
			})
		).pipe(
			mergeMap((poResponse: IUiResponse<boolean, any>) => {
				return of(!!poResponse.response);
			})
		).toPromise();
	}

	//#endregion

}
