import { ObjectHelper } from '../../../../helpers/objectHelper';

export class TypeNotSupportedError extends Error {

	constructor(psType: string) {
		super(`${psType} not supported.`);
		// Nécessaire pour que le 'instanceof TypeNotSupportedError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, TypeNotSupportedError);
	}

}