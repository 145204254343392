import { Injectable } from '@angular/core';
import { BarcodeReaderConfigService } from '@calaosoft/osapp/modules/logistics/barcode-reader/services/barcode-reader-config.service';
import { TEXTILOT_ID } from '../../../app.constants';

/** Service de configuration pour la lecture des codes-barres. Doit être redéfini dans une application et injecté dans le module de celle-ci. */
@Injectable()
export class MerchBarcodeReaderConfigService extends BarcodeReaderConfigService {

	//#region METHODS

	constructor() { super(); }

	/** @override */
	public override getExcludePrefixBarcodes(): ReadonlyArray<string> {
		return [TEXTILOT_ID];
	}

	//#endregion METHODS

}