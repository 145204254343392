import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EMPTY, Observable, from } from 'rxjs';
import { catchError, filter, finalize, mergeMap, takeUntil, tap, toArray } from "rxjs/operators";
import { ComponentBase } from '../../../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { ObservableProperty } from '../../../../observable/models/observable-property';
import { CatalogService } from '../../../catalog/services/catalog.service';
import { IBarcodeAcquisition } from '../../../devices/models/ibarcode-acquisition';
import { IRack } from '../../../task/models/irack';
import { BarcodeReaderService } from '../../services/barcode-reader.service';
import { IBarcodeReaderParams } from './models/ibarcode-reader-params';

@Component({
	selector: "calao-arrowed-barcode-reader",
	templateUrl: './arrowed-barcode-reader.component.html',
	styleUrls: ['./arrowed-barcode-reader.component.scss'],
	// En mettant le Onpush, bug de duplication d'item donc on laisse Angular gérer le rafraîchissement.
})
export class ArrowedBarcodeReaderComponent extends ComponentBase implements IBarcodeReaderParams {

	//#region FIELDS

	/** Identifiant du composant pour les logs. */
	private static readonly C_LOG_ID = "BRCD.RDR.C::";

	/** Événement lors de la lecture de code-barres en mode comptage. */
	@Output("onCountingRead") private readonly moCountingReadEvent = new EventEmitter<IBarcodeAcquisition[]>();
	/** Événement lors de la lecture de code-barres en mode décomptage. */
	@Output("onCountDownRead") private readonly moCountDownReadEvent = new EventEmitter<IBarcodeAcquisition[]>();
	/** Événement lors duchangement de mode : `true` = comptage ; `false` = décomptage. */
	@Output("onSelectCountingTypeChanged") private readonly moSelectCountingTypeEvent = new EventEmitter<boolean>();

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@Input() public readonly itemCount: ObservableProperty<number>;

	/** @implements */
	@Input() public readonly lastBarcodeCount: ObservableProperty<number>;

	private readonly moIsBarcodeValidProp = new ObservableProperty<boolean | undefined>();
	/** @implements */
	@Input() public set isBarcodeValid(poValue: boolean | ObservableProperty<boolean>) {
		if (poValue instanceof ObservableProperty)
			this.moIsBarcodeValidProp.bind(poValue.value$, this);
		else
			this.moIsBarcodeValidProp.value = poValue;
	}
	/** Flux indiquant si le dernier code-barres lu est valide ou non. */
	public readonly isBarcodeValid$: Observable<boolean | undefined> = this.moIsBarcodeValidProp.value$;

	/** @implements */
	@Input() public readonly rack: IRack;

	private msMiddleBarcodeTitle?: string;
	/** @implements */
	public get middleBarcodeTitle(): string { return this.msMiddleBarcodeTitle; }
	@Input() public set middleBarcodeTitle(psValue: string) {
		if (this.msMiddleBarcodeTitle !== psValue)
			this.msMiddleBarcodeTitle = psValue;
	}

	private msTopBarcodeTitle?: string;
	/** @implements */
	public get topBarcodeTitle(): string { return this.msTopBarcodeTitle; }
	@Input() public set topBarcodeTitle(psValue: string) {
		if (this.msTopBarcodeTitle !== psValue)
			this.msTopBarcodeTitle = psValue;
	}

	private msBottomBarcodeTitle?: string;
	/** @implements */
	public get bottomBarcodeTitle(): string { return this.msBottomBarcodeTitle; }
	@Input() public set bottomBarcodeTitle(psBottomRightPartTitle: string) {
		if (psBottomRightPartTitle !== this.msBottomBarcodeTitle)
			this.msBottomBarcodeTitle = psBottomRightPartTitle;
	}

	private mbIsCounting?: boolean;
	/** @implements */
	public get isCounting(): boolean { return this.mbIsCounting; }
	@Input() public set isCounting(pbIsCounting: boolean) {
		if (pbIsCounting !== this.mbIsCounting)
			this.mbIsCounting = pbIsCounting;
	}

	private mbDisplayCountingButtons?: boolean;
	/** @implements */
	public get displayCountingButtons(): boolean { return this.mbDisplayCountingButtons; }
	@Input() public set displayCountingButtons(pbDisplayCountingButtons: boolean) {
		if (pbDisplayCountingButtons !== this.mbDisplayCountingButtons)
			this.mbDisplayCountingButtons = pbDisplayCountingButtons;
	}

	private readonly moIgnoreBarcodeProp = new ObservableProperty<boolean | undefined>();
	/** @implements */
	@Input() public set ignoreBarcode(poValue: boolean | ObservableProperty<boolean>) {
		if (poValue instanceof ObservableProperty)
			this.moIgnoreBarcodeProp.bind(poValue.value$, this);
		else
			this.moIgnoreBarcodeProp.value = poValue;
	}

	private msLastReadBarcode: string;
	/** Dernier code-barres lu. */
	public get lastReadBarcode(): string { return this.msLastReadBarcode; }

	private mbIsSending = false;
	/** Indique si des codes barres sont en cours d'envoi. */
	public get isSending(): boolean { return this.mbIsSending; }

	private mbIncrementationInfo: boolean;
	/** Info de l'incrémentation remontée à l'utilisateur undefined => - , true => +1, false => -1 */
	public get incrementationInfo(): boolean { return this.mbIncrementationInfo; }

	/** `true` s'il faut montrer au moins un texte/valeur de l'embout de la flèche, `false` sinon. */
	public get showHeaderArrowValue(): boolean { return !!this.msTopBarcodeTitle || !!this.msBottomBarcodeTitle; }

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcBarcodeReader: BarcodeReaderService,
		private readonly isvcCatalog: CatalogService
	) {
		super();

		this.initBarcodeRead();
	}

	private initBarcodeRead(): void {
		this.isvcBarcodeReader.onBarcodeRead().pipe(
			filter(() => !this.moIgnoreBarcodeProp.value && !this.mbIsSending),
			tap((paAcquisitions: IBarcodeAcquisition[]) => {
				this.mbIsSending = true;
				if (paAcquisitions.length > 0)
					this.msLastReadBarcode = ArrayHelper.getLastElement(paAcquisitions).code;
			}),
			mergeMap((paAcquisitions: IBarcodeAcquisition[]) => {
				return from(paAcquisitions)
					.pipe(
						mergeMap((poAcquisition: IBarcodeAcquisition) => this.isvcCatalog.getPricedItemFromBarcode(poAcquisition.code)),
						toArray(),
						tap(_ => this.readChanges(paAcquisitions)),
						catchError(poError => this.onReadError(poError)),
						finalize(() => this.mbIsSending = false)
					);
			}),
			takeUntil(this.destroyed$) // On garde le flux ouvert jusqu'à ce que le composant soit détruit.
		)
			.subscribe();
	}

	/** Lance une lecture de code-barres ; si les codes-barres sont ignorés, on les "désignore". */
	public readAsync(): Promise<void> {
		this.ignoreBarcode = false;

		return this.isvcBarcodeReader.readAsync();
	}

	/** Traite la lecture des code-barres pour gérer l'incrémentation/décrémentation.
	 * @param paAcquisitions Tableau des acquisitions faites.
	 */
	private readChanges(paAcquisitions: IBarcodeAcquisition[]): void {
		if (this.mbIsCounting) // En mode comptage on ajoute le dernier code barres scanné au tableau.
			this.moCountingReadEvent.emit(paAcquisitions);
		else // En mode décomptage on supprime le dernier code barre du tableau.
			this.moCountDownReadEvent.emit(paAcquisitions);

		this.mbIncrementationInfo = this.mbIsCounting; // Change l'incrementationInfo à `true` si c'est un comptage, `false` sinon.
	}

	/** Termine le flux de lecture d'un code-barres en débloquant la relecture et en loggant l'erreur si nécessaire.
	 * @param poError Erreur survenue.
	 */
	private onReadError(poError: any): Observable<never> {
		console.error(`${ArrowedBarcodeReaderComponent.C_LOG_ID}Error while scanning :`, poError);
		return EMPTY;
	}

	public onSelectCountingType(poEvent: Event, pbValue: boolean): void {
		poEvent.stopPropagation();

		if (pbValue !== this.mbIsCounting) {
			this.mbIsCounting = pbValue;
			this.moSelectCountingTypeEvent.emit(pbValue);
		}

		this.mbIncrementationInfo = undefined;
		this.moIsBarcodeValidProp.value = true;
	}

	public resetScannedBarcodes(): void {
		this.msLastReadBarcode = undefined;
		this.mbIncrementationInfo = undefined;
		this.itemCount.value = 0;
		this.lastBarcodeCount.value = 0;
		this.moIsBarcodeValidProp.value = true;
	}

	//#endregion

}