import { Injectable } from '@angular/core';
import { Cipherlab, IOnBarcodeReadEvent } from 'capacitor-plugin-cipherlab';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IBarcodeAcquisition } from '../../devices/models/ibarcode-acquisition';
import { EAccuracyType } from '../../settings/models/eaccuracy-type';
import { IBarcodeReader } from '../models/IBarcodeReader';

@Injectable({ providedIn: "root" })
export class CipherlabBarcodeReaderService implements IBarcodeReader {

	//#region FIELDS

	private readonly moBarcodeReadSubject = new Subject<string>();

	//#endregion

	//#region METHODS

	constructor() {
		Cipherlab.addListener("onBarcodeRead", (poEvent: IOnBarcodeReadEvent) => this.moBarcodeReadSubject.next(poEvent.barcode));
	}

	/** @implements */
	public initializeBarcode(): void {
		Cipherlab.init();
	}

	/** @implements */
	public readBarcode(): void {
		Cipherlab.startBarcodeReader();
	}

	/** @implements */
	public onBarcodeReaded(): Observable<IBarcodeAcquisition[]> {
		return this.moBarcodeReadSubject.asObservable()
			.pipe(
				map((psBarcode: string): IBarcodeAcquisition[] => {
					if (psBarcode.endsWith("\n"))
						psBarcode = psBarcode.substring(0, psBarcode.length - 1);

					return [{ code: psBarcode, numberOfScans: 1, taken: [new Date()] }];
				})
			);
	}

	/** @implements */
	public stopReadBarcode(): void {
		Cipherlab.stopBarcodeReader();
	}

	/** @implements */
	public setAccuracy(peAccuracy: EAccuracyType): void {
		switch (peAccuracy) {
			case EAccuracyType.fullSquare:
				Cipherlab.disablePickListMode();
				break;

			default:
				Cipherlab.enablePickListMode();
				break;
		}
	}

	//#endregion

}