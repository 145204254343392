import { coerceArray, coerceBooleanProperty, coerceNumberProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatChip } from '@angular/material/chips';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { NumberHelper } from '../../../helpers/numberHelper';
import { ISearchOptions } from '../../../model/search/ISearchOptions';
import { ShowMessageParamsToast } from '../../../services/interfaces/ShowMessageParamsToast';
import { PlatformService } from '../../../services/platform.service';
import { UiMessageService } from '../../../services/uiMessage.service';
import { CustomIonSelectEvent } from '../../ionic/models/icustom-ion-input-event';
import { ObserveProperty } from '../../observable/decorators/observe-property.decorator';
import { ObservableArray } from '../../observable/models/observable-array';
import { ObservableProperty } from '../../observable/models/observable-property';
import { ESelectorDisplayMode } from './ESelectorDisplayMode';
import { ISelectOption } from './ISelectOption';
import { ISelectorParams } from './ISelectorParams';

@Component({
	selector: "osapp-selector",
	templateUrl: './selector.component.html',
	styleUrls: ['./selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorComponent<T = any> extends ComponentBase implements ISelectorParams<T>, OnInit {

	//#region FIELDS

	/** Retourne les changements de sélection. */
	@Output("selectionChanged") private readonly moSelectionChangedEventEmitter = new EventEmitter<T[]>();

	private moLastSelectedChip: MatChip;

	//#endregion

	//#region PROPERTIES

	private maPreselectedValues: T[];
	/** @implements */
	@Input() public set preselectedValues(paPreselectedValues: T[] | T) {
		if (paPreselectedValues) {
			const laPreselectedValues: T[] = coerceArray(paPreselectedValues);

			if (!ArrayHelper.areArraysEqual(this.maPreselectedValues, laPreselectedValues)) {
				if (this.maPreselectedValues)
					ArrayHelper.getDifferences(this.maPreselectedValues, laPreselectedValues).forEach((poElement: T) => ArrayHelper.removeElement(this.selectedValues, poElement));

				if (ArrayHelper.hasElements(laPreselectedValues))
					laPreselectedValues.forEach((poValue: T) => {
						if (!this.selectedValues.includes(poValue))
							this.selectedValues.push(poValue);
					});

				this.maPreselectedValues = laPreselectedValues;
				this.defaultSingleValue = ArrayHelper.getFirstElement(this.maPreselectedValues);

				this.moSelectionChangedEventEmitter.emit([...this.selectedValues]);
				this.detectChanges();
			}
		}
	}

	private maOptions?: ISelectOption<T>[];
	public get options(): ISelectOption<T>[] | undefined { return this.maOptions; }
	/** @implements */
	@Input() public set options(paOptions: ReadonlyArray<ISelectOption<T>> | undefined) {
		const laOptions: ISelectOption<T>[] = Array.from(coerceArray(paOptions));

		if (!ArrayHelper.areArraysEqual(this.maOptions, laOptions)) {
			this.maOptions = laOptions;

			if (!this.filteredOptions)
				this.filteredOptions = this.maOptions;

			this.values = this.maOptions.map((poOption: ISelectOption<T>) => poOption.value);

			if (!this.searchOptions) // Si il n'y a pas de barre de recherche on rafraichi l'affichage.
				this.onFilteredValuesChanged(this.values);

			this.detectChanges();
		}
	}

	private meDisplayMode: ESelectorDisplayMode;
	public get displayMode(): ESelectorDisplayMode { return this.meDisplayMode ?? ESelectorDisplayMode.actionSheet; }
	/** @implements */
	@Input() public set displayMode(peDisplayMode: ESelectorDisplayMode) {
		if (this.meDisplayMode !== peDisplayMode) {
			this.meDisplayMode = peDisplayMode;
			this.detectChanges();
		}
	}

	private mbMultiple: boolean;
	public get multiple(): boolean { return this.mbMultiple; }
	/** @implements */
	@Input() public set multiple(pbMultiple: boolean | string) {
		const lbMultiple: boolean = coerceBooleanProperty(pbMultiple);

		if (this.mbMultiple !== lbMultiple) {
			if (!lbMultiple && this.selectedValues.length > 1)
				this.emitChange([ArrayHelper.getFirstElement(this.selectedValues)]);

			this.mbMultiple = lbMultiple;
			this.detectChanges();
		}
	}

	private msSelectCss: string;
	public get selectCss(): string { return this.msSelectCss; }
	/** @implements */
	@Input() public set selectCss(psSelectCss: string) {
		if (this.msSelectCss !== psSelectCss) {
			this.msSelectCss = psSelectCss;
			this.detectChanges();
		}
	}

	private moSearchOptions: ISearchOptions<T>;
	public get searchOptions(): ISearchOptions<T> { return this.moSearchOptions; }
	/** @implements */
	@Input() public set searchOptions(poSearchOptions: ISearchOptions<T>) {
		if (this.moSearchOptions !== poSearchOptions) {
			this.moSearchOptions = poSearchOptions;
			this.detectChanges();
		}
	}

	private mnLimit: number;
	public get limit(): number { return this.mnLimit; }
	/** @implements */
	@Input() public set limit(pnLimit: number) {
		const lnLimit: number = pnLimit === undefined ? undefined : coerceNumberProperty(pnLimit);

		if (this.mnLimit !== lnLimit) {
			this.mnLimit = lnLimit;
			this.multiple = lnLimit > 1;
			this.detectChanges();
		}
	}

	private mnMin: number;
	public get min(): number { return this.mnMin ?? 0; }
	/** @implements */
	@Input() public set min(pnMin: number) {
		const lnMin: number = pnMin === undefined ? undefined : coerceNumberProperty(pnMin);
		if (this.mnMin !== lnMin) {
			this.mnMin = lnMin;
			this.detectChanges();
		}
	}

	public get canUnselect(): boolean {
		if (NumberHelper.isValidPositive(this.min))
			return this.selectedValues.length > this.min;
		return false;
	}

	private msLabel: string;
	public get label(): string { return this.msLabel; }
	/** @implements */
	@Input() public set label(psLabel: string) {
		if (psLabel !== this.msLabel) {
			this.msLabel = psLabel;
			this.detectChanges();
		}
	}

	private msColor: string;
	public get color(): string { return this.msColor; }
	/** @implements */
	@Input() public set color(psColor: string) {
		if (psColor !== this.msColor) {
			this.msColor = psColor;
			this.detectChanges();
		}
	}

	private mbScrollWrapper = true;
	/** Indique si on doit afficher les tags dans un conteneur scrollable ou non, `true` par défaut. */
	public get scrollWrapper(): boolean { return this.mbScrollWrapper; }
	@Input() public set scrollWrapper(pbScrollWrapper: boolean) {
		if (pbScrollWrapper !== this.mbScrollWrapper) {
			this.mbScrollWrapper = coerceBooleanProperty(pbScrollWrapper);
			this.detectChanges();
		}
	}

	@Input() public disabled: boolean;

	private msDisabledWarningMessage: string;
	public get disabledWarningMessage(): string { return this.msDisabledWarningMessage; }
	@Input() public set disabledWarningMessage(psMessage: string) {
		if (psMessage !== this.msDisabledWarningMessage) {
			this.msDisabledWarningMessage = psMessage;
			this.detectChanges();
		}
	}

	/** @implements */
	@Input() public placeholder: string;
	@ObserveProperty<SelectorComponent>({ sourcePropertyKey: "placeholder" })
	public readonly observablePlaceholder = new ObservableProperty<string>();

	@Input() public display = true;

	/** Valeurs sélectionnées. */
	public selectedValues = new ObservableArray<T>();
	/** Valeurs utilisées par le composant de recherche. */
	public values: T[] = [];
	/** Options filtrées par le composant de recherche. */
	public filteredOptions: ISelectOption<T>[];
	/** Indique si l'on peut sélectionner plus d'options. */
	public canSelectMore = true;
	/** Indique si l'on peut sélectionner moins d'options. */
	public canSelectLess = true;
	/** Valeur présélectionnée par le radio group dans le mode `list`. */
	public defaultSingleValue: T;
	/** Indique si le boutton de défilement vers la gauche doit être caché */
	public hidLeftBtn: boolean;
	/** Indique si le boutton de défilement vers la droite doit être caché */
	public hidRightBtn: boolean;

	public get isMobile(): boolean {
		return this.isvcPlatform.isMobile;
	}

	/** @implements */
	@Input() public showItemBorder: boolean;
	@ObserveProperty<SelectorComponent>({ sourcePropertyKey: "showItemBorder" })
	public readonly observableShowItemBorder = new ObservableProperty<boolean>(true);

	/** @implements */
	@Input() public listDirection: "row" | "column";
	@ObserveProperty<SelectorComponent>({ sourcePropertyKey: "listDirection" })
	public readonly observableListDirection = new ObservableProperty<"row" | "column">("column");

	/** Composant scrollable contenant les tags */
	@ViewChild('dragScroll') public dragScroll: DragScrollComponent;
	@ViewChild('dragScroll', { read: ElementRef }) public dragScrollElement: ElementRef<HTMLElement>;
	@ViewChildren(DragScrollItemDirective, { read: ElementRef }) public dragScrollDirectives: QueryList<ElementRef<HTMLElement>>;

	//#endregion

	//#region METHODS

	constructor(poChangeDetector: ChangeDetectorRef, private isvcPlatform: PlatformService, private isvcUiMessage: UiMessageService) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		if (this.dragScroll) {
			this.dragScroll.scrollbarHidden = true;
			this.dragScroll.snapOffset = 10;
			this.disableTouchPropagation(this.dragScrollElement);
			this.dragScrollDirectives.forEach((poElementRef: ElementRef<HTMLElement>) => this.disableTouchPropagation(poElementRef));
		}
	}

	/** Evite les bug de conflits lors du swipe. */
	public disableTouchPropagation(poElementRef: ElementRef<HTMLElement>): void {
		poElementRef.nativeElement.addEventListener("touchmove", (poEvent: TouchEvent) => poEvent.stopPropagation());
		poElementRef.nativeElement.addEventListener("touchstart", (poEvent: TouchEvent) => poEvent.stopPropagation());
		poElementRef.nativeElement.addEventListener("touchend", (poEvent: TouchEvent) => poEvent.stopPropagation());
	}

	public override detectChanges(): void {
		this.updateCanSelectMore();
		this.updateCanSelectLess();
		super.detectChanges();
	}

	public onTagValueSelected(poValue: T, pbIsUserInput: boolean, pbSelected: boolean, poChip: MatChip): void {
		const laSelectedValues: T[] = [];

		if (this.multiple)
			laSelectedValues.push(...this.selectedValues);

		if (pbSelected && !laSelectedValues.includes(poValue)) {
			this.moLastSelectedChip = poChip;
			laSelectedValues.push(poValue);
		}
		else if (!pbSelected)
			ArrayHelper.removeElement(laSelectedValues, poValue);

		if (pbIsUserInput)
			this.emitChange(laSelectedValues);

		this.detectChanges();
	}

	public onListValueSelected(poValue: T): void {
		const laSelectedValues: T[] = [];

		if (this.multiple)
			laSelectedValues.push(...this.selectedValues);

		if (!laSelectedValues.includes(poValue))
			laSelectedValues.push(poValue);
		else
			ArrayHelper.removeElement(laSelectedValues, poValue);

		this.emitChange(laSelectedValues);

		this.detectChanges();
	}

	public onPopupValueSelected(poEvent: Event): void {
		const loEvent: CustomIonSelectEvent<T | T[]> = poEvent as CustomIonSelectEvent<T | T[]>;
		if (loEvent.detail?.value) {
			this.emitChange(loEvent.detail.value instanceof Array ? loEvent.detail.value : [loEvent.detail.value]);
			this.detectChanges();
		}
	}

	private emitChange(paSelectedValues: T[]): void {
		if (!ArrayHelper.areArraysEqual(this.selectedValues, paSelectedValues)) {
			this.selectedValues.resetArray(paSelectedValues);
			this.moSelectionChangedEventEmitter.emit([...this.selectedValues]);
		}
	}

	private updateCanSelectMore(): void {
		this.canSelectMore = !NumberHelper.isValid(this.limit) || this.selectedValues.length < this.limit;
	}

	private updateCanSelectLess(): void {
		this.canSelectLess = !NumberHelper.isValid(this.min) || this.selectedValues.length > this.min;
	}

	public onPopupSelectionChanged(poEvent): void {
		console.log(poEvent);
	}

	public onFilteredValuesChanged(paFilteredValues: T[]): void {
		this.filteredOptions = this.options.filter((poOption: ISelectOption<T>) => paFilteredValues.includes(poOption.value));
		this.detectChanges();
	}

	public isDisabled(poOption: ISelectOption<T>): boolean {
		return this.disabled || (!this.selectedValues.includes(poOption.value) && (!this.canSelectMore || poOption.disabled)) || (this.selectedValues.includes(poOption.value) && !this.canSelectLess);
	}

	public toggleChip(poChip: MatChip): void {
		if (!poChip.disabled) {
			poChip.toggleSelected(true);
			this.detectChanges();
		}
		else if (this.disabledWarningMessage)
			this.isvcUiMessage.showMessage(new ShowMessageParamsToast({ message: this.disabledWarningMessage }));
	}

	public moveLeft(): void {
		for (let lnIndex = 0; lnIndex < 2; ++lnIndex) {
			this.dragScroll.moveLeft();
		}
	}

	public moveRight(): void {
		for (let lnIndex = 0; lnIndex < 2; ++lnIndex) {
			this.dragScroll.moveRight();
		}
	}

	public leftBoundStat(reachesLeftBound: boolean): void {
		this.hidLeftBtn = reachesLeftBound;
		this.detectChanges();
	}

	public rightBoundStat(reachesRightBound: boolean): void {
		this.hidRightBtn = reachesRightBound;
		this.detectChanges();
	}

	public resetSelection(paValues?: T[] | T): void {
		this.selectedValues.resetArray(paValues ? coerceArray(paValues) : []);
		this.detectChanges();
	}

	public unselectLastValue(): void {
		if (this.moLastSelectedChip) {
			this.moLastSelectedChip.toggleSelected(true);
			this.detectChanges();
		}
	}

	public unselectValue(poValue: T): void {
		const laSelectedValues: T[] = [...this.selectedValues];
		ArrayHelper.removeElement(laSelectedValues, poValue);
		this.emitChange(laSelectedValues);
	}

	public addOption(poSelectOption: ISelectOption<T>): void {
		if (poSelectOption.selected) {
			this.selectedValues.push(poSelectOption.value);
			this.moSelectionChangedEventEmitter.emit([...this.selectedValues]);
		}

		this.options = [...this.options, poSelectOption];
	}

	public getFirstElement(paValues: T[]): T {
		return ArrayHelper.getFirstElement(paValues);
	}

	public getValueOption(poValue: T): ISelectOption<T> {
		return this.options.find((poOption: ISelectOption<T>) => poOption.value === poValue);
	}

	public isSelected$(poValue: T): Observable<boolean> {
		return this.selectedValues.changes$.pipe(
			map((paSelectedValues: T[]) => paSelectedValues.includes(poValue))
		);
	}

	//#endregion

}
