import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/material';
import { Observable, ReplaySubject, of } from 'rxjs';
import { startWith, takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { ObservableProperty } from '../../observable/models/observable-property';
import { PerformanceManager } from '../../performance/PerformanceManager';
import { IDestroyable } from '../../utils/lifecycle/models/IDestroyable';
import { Queuer } from '../../utils/queue/models/queuer';
import { FormsService } from '../services/forms.service';

type KeyType = string | number;

@Component({ template: "" })
export abstract class FieldBase<T = any, M = any> extends FieldType<FormlyFieldConfig> implements OnInit, IDestroyable, OnDestroy, AfterViewInit {

	//#region FIELDS

	private static readonly C_FIELD_BASE_LOG_ID = "FIELD.BASE.C::";

	private readonly moDestroySubject = new ReplaySubject<void>(1);
	/** Indique si le composant possède une instance pour rafraîchir la vue ou non. */
	private mbHasChangeDetectorRef: boolean;

	protected mnDelayBetweenDetectChangesMs = 100;

	private readonly moDetectChangesQueue = new Queuer({
		thingToQueue: () => of(this.internalDetectChanges()),
		minimumGapMs: this.mnDelayBetweenDetectChangesMs,
		keepOnlyLastPending: true
	});
	private mbDetectChangesPending = false;

	/** Padding autour du composant au format top right bottom left. Ex : 10px 0 10px 0. */
	public readonly observablePadding = new ObservableProperty<string>();

	//#endregion

	//#region PROPERTIES

	/** Permet de s'abonner à l'event disant que le composant est détruit */
	public get fieldDestroyed$(): Observable<void> {
		return this.destroyed$;
	}

	public readonly observableFieldValue = new ObservableProperty<T>(undefined, (poValue: T) => this.fieldValue = poValue);

	public get fieldValue(): T {
		return this.formControl.value;
	}
	public set fieldValue(poValue: T) {
		if (this.formControl.value !== (this.observableFieldValue.value = poValue)) {
			this.formControl.patchValue(poValue);
			this.detectChanges();
		}
	}

	/** @implements */
	public get destroyed$(): Observable<void> { return this.moDestroySubject.asObservable(); }

	private mbDestroyed = false;
	/** @implements */
	public get destroyed(): boolean { return this.mbDestroyed; }

	public override get model(): M {
		return super.model;
	}

	public get fieldKey(): KeyType {
		return this.key instanceof Array ? this.key.join(".") : this.key;
	}

	public override get form(): FormGroup {
		return super.form as FormGroup;
	}

	//#endregion

	//#region METHODS

	constructor(protected readonly isvcForms: FormsService, private readonly ioChangeDetectorRef: ChangeDetectorRef = undefined) {
		super();

		this.mbHasChangeDetectorRef = !!this.ioChangeDetectorRef;
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();

		this.mbDestroyed = true;
		this.moDestroySubject.next();
		this.moDestroySubject.complete();

		if (this.ioChangeDetectorRef)
			this.ioChangeDetectorRef.detach();
	}

	public ngOnInit(): void {
		this.formControl?.valueChanges
			.pipe(
				startWith(null),
				tap(_ =>
					this.fieldValue = this.fieldValue // On force l'application de la nouvelle valeur.
				),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();

		if (!this.to.data)
			this.to.data = {};
	}

	public ngAfterViewInit(): void {
		this.moDetectChangesQueue.start().pipe(takeUntil(this.destroyed$)).subscribe();

		if (this.mbDetectChangesPending)
			this.detectChanges();
	}

	/** Rafraîchie la vue pour la mettre à jour. */
	protected detectChanges(): void {
		this.moDetectChangesQueue.exec();
		this.mbDetectChangesPending = true;
	}

	private internalDetectChanges(): void {
		const loPerformance = new PerformanceManager();

		if (this.mbHasChangeDetectorRef && !(this.ioChangeDetectorRef as ViewRef).destroyed) { // Si la vue n'est pas détruite, on peut la mettre à jour.
			loPerformance.markStart();
			this.ioChangeDetectorRef?.detectChanges();
			console.debug(`${FieldBase.C_FIELD_BASE_LOG_ID}DetectChanges duration (ms) : ${loPerformance.markEnd().measure()} ; class:`, this.constructor.name);
		}
		else if (!this.mbHasChangeDetectorRef)
			console.warn(`${FieldBase.C_FIELD_BASE_LOG_ID}Cannot detect changes without changeDetector injected.`);
	}

	/** Marque le composant de formulaire comment édité, des changements ont eu lieu. */
	protected markAsDirty(): void {
		this.formControl.markAsTouched();
		this.formControl.markAsDirty();
	}

	/** Marque le champ de formulaire comme non édité, aucun changement n'a eu lieu. */
	protected markAsPristine(): void {
		this.formControl.markAsUntouched();
		this.formControl.markAsPristine();
	}

	/** Met à jour les valeurs du modèle.
	 * @param paKeys Tableau des clés dont il faut mettre à jour les valeurs, `[this.key]` par défaut (clé du champ de formulaire).
	 * @param poUpdatedModel Modèle qui possède les valeurs à mettre à jour.
	 */
	protected updateValues(poUpdatedModel: IStoreDocument, paKeys: KeyType[] = [this.fieldKey]): void {
		// Si on a un tableau qui ne contient que la clé du champ de formulaire courant, on ne modifie que son contrôle et pas tout le formulaire.
		if (paKeys.length === 1 && ArrayHelper.getFirstElement(paKeys) === this.key)
			this.fieldValue = poUpdatedModel[this.key];
		else {
			paKeys.forEach((psKey: string) => this.model[psKey] = poUpdatedModel[psKey]);
			this.form.patchValue(this.model);
			this.detectChanges();
		}
	}

	//#endregion

}