import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SlidingListModule } from '../../../sliding-list/sliding-list.module';
import { TitledInputModule } from '../../../titled-input/titled-input.module';
import { DashboardService } from '../services/dashboard.service';
import { CategoriesComponent } from './categories/categories.component';
import { CategoryTileComponent } from './category-tile/category-tile.component';
import { LotEditComponent } from './lot-edit/lot-edit.component';
import { LotsComponent } from './lots/lots.component';

const components = [
	CategoriesComponent,
	LotEditComponent,
	LotsComponent,
	CategoryTileComponent
];

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		SlidingListModule,
		TitledInputModule,
	],
	exports: components,
	providers: [DashboardService]
})
export class CountingModule { }