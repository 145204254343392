<div class="ion-text-center lot-edit-area">
	<calao-titled-input *ngIf="(observableCategory.value$ | async) as category"
		[label]="category.title + ' ' + (category.subtitle ? category.subtitle : '')" subLabel="Pièces"
		[icon]="category.icon" (onValueChanged)="valueChanged($event)"
		[totalCategoryItems]="observableTotalCategoryItems.value$ | async" focusOnStart (keyup.enter)="onAddToLot()">
	</calao-titled-input>

	<div class="btn-area">
		<div class="btn-edit">
			<ion-button color="primary" (click)="onAddToLot()" [disabled]="!(isValid$ | async)">
				<ion-icon name="shirt-outline"></ion-icon>
			</ion-button>
			<ion-note>Ajouter <br>au lot {{ observableLastLot.value$ | async }}</ion-note>
		</div>

		<div class="btn-edit" *ngIf="observableCanAddNewLot.value$ | async">
			<ion-button (click)="onAddToNewLot()" class="outline-btn" [disabled]="!(isValid$ | async)">
				<ion-icon name="add"></ion-icon>
			</ion-button>
			<ion-note>Ajouter nouveau lot</ion-note>
		</div>

	</div>

</div>