import { Exclude } from 'class-transformer';
import { BehaviorSubject, from, Observable, ObservableInput, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ObjectHelper } from '../../../helpers/objectHelper';
import { ESortOrder } from '../../../model/ESortOrder';
import { MoveToObservableArrayError } from './errors/move-to-observable-array-error';

export class ObservableArray<T> extends Array<T> {

	//#region FIELDS

	private static readonly C_LOG_ID = "OBSARR::";

	@Exclude()
	private readonly moChanges: BehaviorSubject<Array<T>>;

	/** Abonnement du tableau observable pour écouter les changements dans le flux d'entrée. */
	@Exclude()
	private moSubscription?: Subscription;

	//#endregion

	//#region PROPERTIES

	/** Flux continu de récupération des changements du tableau. */
	public get changes$(): Observable<Array<T>> { return this.moChanges.asObservable(); }

	/** Flux continu de récupération des changements sur la longueur du tableau. */
	public get length$(): Observable<number> { return this.changes$.pipe(map((paValues: T[]) => paValues.length)); }

	//#endregion

	//#region METHODS

	constructor(paValues?: T[] | number);
	constructor(poValue$?: ObservableInput<T[]>, pfAreItemsEqual?: (poItemA: T, poItemB: T) => boolean);
	constructor(paValues?: T[] | ObservableInput<T[]> | number, pfAreItemsEqual?: (poItemA: T, poItemB: T) => boolean) {
		if (typeof paValues === "number")
			super(paValues);
		else if (paValues instanceof Array)
			super(...paValues);
		else
			super();

		this.moChanges = new BehaviorSubject([...this]);
		ObjectHelper.initInstanceOf(this, ObservableArray);

		if (!!paValues && typeof paValues !== "number" && !(paValues instanceof Array))
			this.resetSubscription(paValues, pfAreItemsEqual);
		else
			this.emitNewArray();
	}

	/** Réinitialise un abonnement sur un nouveau flux en se désabonnant du possible précédent.
	 * @param poObservableInput Flux d'entrée sur lequel s'abonner.
	 * @param pfAreItemsEqual Fonction qui détermine si deux éléments sont identiques, égalité simple par défaut (`===`).
	 */
	public resetSubscription(poObservableInput: ObservableInput<T[]>, pfAreItemsEqual?: (poItemA: T, poItemB: T) => boolean): void {
		this.moSubscription?.unsubscribe();

		this.moSubscription = from(poObservableInput)
			.subscribe(
				(paResults: T[]) => this.resetArray(paResults, pfAreItemsEqual),
				(poError: any) => console.error(`${ObservableArray.C_LOG_ID}Error in observableArray subscription.`, poError),
				() => this.moSubscription?.unsubscribe()
			);
	}

	public override push(...paValues: T[]): number {
		if (ArrayHelper.hasElements(paValues)) {
			const lnResult: number = super.push(...paValues);

			this.emitNewArray();

			return lnResult;
		}
		else
			return this.length;
	}

	public override pop(): T | undefined {
		const loResult: T | undefined = super.pop();

		this.emitNewArray();

		return loResult;
	}

	/** Vide le tableau de tous ses éléments. */
	public clear(): void {
		this.splice(0, this.length);
		this.emitNewArray();
	}

	public override sort(pfComp?: (a: T, b: T) => number): this {
		const loResult: this = super.sort(pfComp);

		this.emitNewArray();

		return loResult;
	}

	public override reverse(): this {
		super.reverse();

		this.emitNewArray();

		return this;
	}

	/** Envoi un événement contenant la liste dans le flux de changements. */
	private emitNewArray(): void {
		this.moChanges.next([...this]);
	}

	public override splice(start: number, deleteCount?: number): T[];
	public override splice(start: number, deleteCount: number, ...items: T[]): T[];
	public override splice(start: number, deleteCount?: number, ...rest: T[]): T[] {
		let laResults: T[];

		if (!!rest)
			laResults = super.splice(start, deleteCount, ...rest);
		else
			laResults = super.splice(start, deleteCount);

		this.emitNewArray();

		return laResults;
	}

	public override shift(): T {
		const loResult: T = super.shift();

		if (loResult)
			this.emitNewArray();

		return loResult;
	}

	public override unshift(...items: T[]): number {
		if (ArrayHelper.hasElements(items)) {
			const lnResult: number = super.unshift(...items);

			this.emitNewArray();

			return lnResult;
		}

		return this.length;
	}

	/** Réinitialise le tableau courant avec des nouvelles données ou en réinitialisant à tableau vide.
	 * @param paNewArray Nouveau tableau avec lequel réinitialiser le tableau courant.
	 * @param pfAreItemsEqual Fonction qui détermine si deux éléments sont identiques, égalité simple par défaut (`===`).
	 */
	public resetArray(paNewArray?: ReadonlyArray<T>, pfAreItemsEqual?: (poItemA: T, poItemB: T) => boolean): void {
		let lbChanged = false;

		if (!ArrayHelper.areArraysStrictEqual(paNewArray, this, pfAreItemsEqual)) {
			if (ArrayHelper.hasElements(this)) {
				super.splice(0, this.length);
				lbChanged = true;
			}
			if (ArrayHelper.hasElements(paNewArray)) {
				super.push(...paNewArray);
				lbChanged = true;
			}
		}

		if (lbChanged)
			this.emitNewArray();
	}

	/** Supprime un élément spécifique du tableau et le retourne si celui-ci a été supprimé, retourne `undefined` sinon.
	 * @param poItem Objet qu'il faut supprimer du tableau.
	 */
	public remove(poItem: T): T | undefined;
	/** Supprime un élément spécifique du tableau et le retourne si celui-ci a été supprimé, retourne `undefined` sinon.
	 * @param pfFinder Fonction permettant de trouver l'objet à supprimer.
	 */
	public remove(pfFinder: (poItem: T) => boolean): T | undefined;
	public remove(poData: T | ((poItem: T) => boolean)): T | undefined {
		const lnIndex: number = typeof poData === "function" ? this.findIndex((poData as (poItem: T) => boolean)) : this.indexOf(poData);
		let loRemovedElement: T;

		if (lnIndex !== -1) {
			loRemovedElement = this.splice(lnIndex, 1)[0];
			this.emitNewArray();
		}

		return loRemovedElement;
	}

	/** Retourne `true` si au moins un éléments est présent dans le tableau, `false` sinon. */
	public hasElements(): boolean {
		return this.length > 0;
	}

	/** Retourne le premier élément du tableau, `undefined` s'il n'y en a pas. */
	public first(): T | undefined {
		return this[0];
	}

	/** Retourne le dernier élément du tableau, `undefined` s'il n'y en a pas. */
	public last(): T | undefined {
		return this[this.length - 1];
	}

	/** Déplace un élément du tableau vers un nouvel index, en déplaçant si besoin les éléments entre l'ancien et le nouvel index.
	 * @param pnFromIndex Index courant de l'élément à déplacer.
	 * @param pnToIndex Nouvel index de l'élément après déplacement.
	 * @throws `MoveToObservableArrayError` si le déplacement de l'élément n'a pas pu se réaliser.
	 */
	public moveTo(pnFromIndex: number, pnToIndex: number): true {
		if (ArrayHelper.moveElement(this, pnFromIndex, pnToIndex)) {
			this.emitNewArray();
			return true;
		}
		else
			throw new MoveToObservableArrayError(pnFromIndex, pnToIndex, this.length);
	}

	public override filter(pfPredicate: (poValue: T, pnIndex: number, paArray: ObservableArray<T>) => boolean): ObservableArray<T> {
		return new ObservableArray(super.filter(pfPredicate));
	}

	/** Retourne le tableau trié (en modifiant directement le tableau).
	 * @param psKey Clé sur laquelle trier le tableau.
	 * @param peSortOrder Ordre de tri : croissant par défaut (alphabétique, plus vieux au plus récent).
	 */
	public sortBy(psKey: keyof T, peSortOrder: ESortOrder = ESortOrder.ascending): this {
		ArrayHelper.dynamicSort(this, psKey, peSortOrder);
		this.emitNewArray();
		return this;
	}

	//#endregion

}