export abstract class NominalHelper {

	//#region METHODS

	/** Retourne les epc en fonction des paramètres.
	 * @param poParam Si un des attributs est à `true`, retourne le résultat s'il est présent dans la liste,
	 * s'il est à false, retourne le paramètre s'il n'est pas présent.
	 */
	public static getNominalDifference(paCurrents: Array<string>, poNominal: Set<string>, poParam: { inNominal?: boolean, inCurrent?: boolean }): Set<string> {
		const lbInCurrentIsBool = typeof poParam.inCurrent === "boolean";
		const lbInNominalIsBool = typeof poParam.inNominal === "boolean";

		if (!lbInCurrentIsBool && !lbInNominalIsBool) {
			console.error("NOM.H::getNominalDifference attend au moins un paramètre.");
			return new Set();
		}

		const loResult: Set<string> = this.innerGetNominalDifference(paCurrents, poNominal, poParam);

		if (lbInNominalIsBool) {
			const loCurrent: Set<string> = new Set(paCurrents);

			poNominal.forEach((psKey: string) => {
				if (poParam.inNominal === true) {
					const lbIsInCurrent: boolean = loCurrent.has(psKey);

					if (!lbInCurrentIsBool ||
						(poParam.inCurrent === false && !lbIsInCurrent)) {
						loResult.add(psKey);
					}
				}
			});
		}

		return loResult;
	}

	private static innerGetNominalDifference(paCurrents: string[], poNominal: Set<string>, poParam: { inNominal?: boolean, inCurrent?: boolean }): Set<string> {
		return new Set(
			paCurrents.filter((psKey: string) => {
				const lbIsInNominal: boolean = poNominal.has(psKey);
				let lbResult = false;

				if (poParam.inCurrent &&
					!((poParam.inNominal === false && lbIsInNominal) || (poParam.inNominal && !lbIsInNominal))) {

					lbResult = true;
				}

				return lbResult;
			})
		);
	}

	/** Retourne tous les tags présents dans la lecture courante, et dans la lecture nominale. */
	public static getNominalOk(paCurrents: Array<string>, poNominal: Set<string>): number {
		return NominalHelper.getNominalDifference(paCurrents, poNominal, { inCurrent: true, inNominal: true }).size;
	}

	/** Retourne le nombre d'éléments dans la lecture courante, qui sont absents de la nominale */
	public static getNominalExtra(paCurrents: Array<string>, poNominal: Set<string>): number {
		return NominalHelper.getNominalDifference(paCurrents, poNominal, { inCurrent: true, inNominal: false }).size;
	}

	//#endregion
}