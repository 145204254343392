<ng-container calaoHeader title="Diagnostics Logistics"></ng-container>

<ion-content>
	<ion-list>
		<!-- Bluetooth général. -->
		<ion-item-divider color="primary">
			Bluetooth
			<ion-icon name="wifi" slot="start"></ion-icon>
		</ion-item-divider>

		<ng-container *ngIf="bluetoothState$ | async as bluetoothState">

			<ion-item class="ion-text-wrap">
				<ion-label>État</ion-label>
				<ion-label slot="end" style="vertical-align: middle">{{ bluetoothState ? "Activé" : "Désactivé"}}
				</ion-label>
			</ion-item>

			<ion-item *ngIf="bluetoothState" class="ion-text-wrap">
				<ion-label>Appareils appairés</ion-label>
				<ion-label slot="end" style="vertical-align: middle">
					<ion-button (click)="onListBluetoothDevicesClicked()">Voir</ion-button>
				</ion-label>
			</ion-item>

			<ion-item *ngIf="bluetoothState" class="ion-text-wrap">
				<ion-label>Se connecter</ion-label>
				<ion-label slot="end" style="vertical-align: middle">
					<ion-button (click)="onConnectClicked()">Connexion</ion-button>
				</ion-label>
			</ion-item>

		</ng-container>

		<!-- Bluetooth appareil connecté. -->
		<ng-container *ngIf="!!bluetoothDevice">
			<ion-item-divider color="secondary">
				Appareil connecté
				<ion-icon name="magnet" slot="start"></ion-icon>
			</ion-item-divider>

			<ion-item class="ion-text-wrap">
				<ion-label>Nom</ion-label>
				<ion-label slot="end" style="vertical-align: middle">{{ bluetoothDevice.name }}</ion-label>
			</ion-item>
			<ion-item class="ion-text-wrap">
				<ion-label>Adresse</ion-label>
				<ion-label slot="end" style="vertical-align: middle">{{ bluetoothDevice.address }}</ion-label>
			</ion-item>
			<ion-item class="ion-text-wrap">
				<ion-label>Id</ion-label>
				<ion-label slot="end" style="vertical-align: middle">{{ bluetoothDevice.id }}</ion-label>
			</ion-item>
			<ion-item class="ion-text-wrap">
				<ion-label>Classe</ion-label>
				<ion-label slot="end" style="vertical-align: middle">{{ bluetoothDevice.class }}</ion-label>
			</ion-item>

			<!-- Bluetooth appareil connecté. -->
			<ng-container *ngIf="type">
				<ion-item-divider color="secondary">
					{{ type }}
					<ion-icon name="magnet" slot="start"></ion-icon>
				</ion-item-divider>

				<ion-item class="ion-text-wrap">
					<ion-label>Fonctionnalités Inventaire</ion-label>
					<ion-label slot="end" style="vertical-align: middle">
						<ion-button (click)="onInventoryFeaturesClicked()">Voir</ion-button>
					</ion-label>
				</ion-item>
			</ng-container>
		</ng-container>

	</ion-list>

	<!-- Lecteur de code à barres général. -->
	<ion-item-divider color="primary">
		Lecteur Code-à-barres
		<ion-icon name="barcode" slot="start"></ion-icon>
	</ion-item-divider>

	<ion-item class="ion-text-wrap">
		<ion-label>Nom</ion-label>
		<ion-label slot="end" style="vertical-align: middle">
			<!-- Changement du lecteur de code à barres actif. -->
			<ion-button (click)="onChangeBarcodeReaderClicked()">{{ isvcBarcodeReader.reader$ | async }}</ion-button>
		</ion-label>
	</ion-item>

	<ion-item class="ion-text-wrap">
		<ion-label>Durée entre acquisitions</ion-label>
		<!-- Changement de la durée de blocage entre deux lectures du même code. -->
		<ion-input [value]="cipherlabSecondsDelay" (ionChange)="onCipherlabDelayChanged($event)" type="number" slot="end"
			style="vertical-align: middle">
		</ion-input>
		<ion-label slot="end">sec</ion-label>
	</ion-item>

	<ion-item class="ion-text-wrap">
		<ion-label>Focus</ion-label>
		<ion-label slot="end" style="vertical-align: middle">
			<ion-button (click)="setFixedFocus()">{{ fixedFocus }}</ion-button>
		</ion-label>
	</ion-item>

</ion-content>