import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, Optional, Output, ViewChild } from '@angular/core';
import { ComponentBase } from '../../helpers/ComponentBase';
import { ReadingConfigService } from '../logistics/reading/ReadingConfig.service';
import { UserFeedBackService } from '../user-feedback/services/user-feedback.service';
import { IScanditParam } from './IScanditParam';
import { Barcode, BarcodeCapture, BarcodeCaptureSession, ScannerDelegate } from './scandit';

@Component({
	selector: 'scandit-render',
	templateUrl: './scandit-render.component.html',
	styleUrls: ['./scandit-render.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanditRenderComponent extends ComponentBase implements OnDestroy, AfterViewInit {

	//#region FIELDS

	private static readonly C_LOG_ID = "SCANDIT-RENDER.C::";

	@Output("onRead") private readonly moOnReadEvent: EventEmitter<string> = new EventEmitter();

	/** La callback doit être stockée dans une variable de classe pour conserver la même instance (comparaison dans tableau lors du removeListener). */
	private moCallback: ScannerDelegate;

	//#endregion

	//#region PROPERTIES

	@Input() public params: IScanditParam;

	/** Balise HTML où est affichée la caméra de rendu de Scandit. */
	@ViewChild("barcodeView") public barcodeView: ElementRef;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcChangeDetectorRef: ChangeDetectorRef,
		private readonly isvcFeedback: UserFeedBackService,
		@Optional() private readonly isvcReadingConfig: ReadingConfigService,
	) {
		super();

		if (!this.isvcReadingConfig)
			console.warn(`${ScanditRenderComponent.C_LOG_ID}No reading config in app.`);
	}

	public override ngAfterViewInit(): void {
		super.ngAfterViewInit();

		this.params.onCreate(this.barcodeView.nativeElement);
		this.moCallback = this.onBarcodeScanned();
		this.params.barcodeCapture.addListener(this.moCallback);

		this.isvcChangeDetectorRef.detach();
	}

	public override ngOnDestroy(): void {
		this.params.barcodeCapture.removeListener(this.moCallback);
		this.params.onClose();
		super.ngOnDestroy();
	}

	/** Callback appelée lors de la lecture d'un ou plusieurs code barres. */
	public onBarcodeScanned(): ScannerDelegate {
		return {
			didScan: async (_: BarcodeCapture, poSession: BarcodeCaptureSession) => {
				const laReadBarcodes: Array<Barcode> = poSession.newlyRecognizedBarcodes;
				console.debug(`${ScanditRenderComponent.C_LOG_ID}Barcodes read :`, laReadBarcodes);

				for (let lnIndex = 0; lnIndex < laReadBarcodes.length; ++lnIndex) {
					// Le code qui vient d'être lu.
					const lsCode: string = this.removeBarcodeControlKey(laReadBarcodes[lnIndex].data);

					// S'il y a une configuration dans l'appli et qu'elle veut filtrer cet élément.
					if (!this.isvcReadingConfig?.filter(lsCode)) {
						this.isvcFeedback.notify();
						this.moOnReadEvent.next(lsCode);
					}
				}
			}
		};
	}

	/** Permet de supprimer la clé de contrôle d'un code-barres. */
	private removeBarcodeControlKey(psCode: string): string {
		if (psCode.length === 13) // Si c'est un code EAN-13.
			return psCode.substring(0, psCode.length - 1);
		else {
			console.warn(`${ScanditRenderComponent.C_LOG_ID}Unexpected barcode reading: ${psCode}.`);
			return psCode;
		}
	}

	//#endregion

}