import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PipeModule } from '../../pipes/pipes.module';
import { SettingsService } from './services/settings.service';
import { SettingClickableItemComponent } from './setting-clickable-item/setting-clickable-item.component';
import { SettingHeaderComponent } from './setting-header/setting-header.component';
import { SettingItemComponent } from './setting-item/setting-item.component';
import { SettingSelectComponent } from './setting-select/setting-select.component';
import { SettingsGroupComponent } from './settings-group/settings-group.component';

const components: Type<any>[] = [
	SettingClickableItemComponent,
	SettingHeaderComponent,
	SettingItemComponent,
	SettingsGroupComponent,
	SettingSelectComponent
];

@NgModule({
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		PipeModule
	],
	providers: [
		SettingsService
	],
	declarations: components,
	exports: components,
})
export class SettingsModule { };
