import { CoordinatesDTO } from "./coordinates-dto";
import { TimeWindowDTO } from "./time-window-dto";


export class StepDTO {
	public id: string;

	public durationMn: number;

	public coordinates?: CoordinatesDTO;

	public timeWindows: TimeWindowDTO[];

	public setupDurationMn?: number;

	public priority?: number;
}
