import { ObjectHelper } from "../../../../../helpers/objectHelper";

export class ValidateError extends Error {

	constructor(psMessage: string) {
		super(psMessage);
		// Nécessaire pour que le 'instanceof ValidateError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, ValidateError);
	}

}