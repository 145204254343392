import { StockService } from "@calaosoft/osapp/modules/logistics/stock/services/stock.service";
import { TaskBase } from "@calaosoft/osapp/services/backgroundTask/TaskBase";
import { TaskDescriptor } from "@calaosoft/osapp/services/backgroundTask/TaskDescriptor";
import { InjectorService } from "@calaosoft/osapp/services/injector.service";
import { Observable, defer, of } from "rxjs";
import { IStockUpdateTaskParams } from "./istock-update-task-params";

export class StockUpdateTask<T extends IStockUpdateTaskParams> extends TaskBase<T> {

	//#region FIELDS

	private static readonly C_LOG_ID = "STCK.UPDT.TSK::";

	private readonly msvcStock: StockService | undefined;

	//#endregion FIELDS

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor<T>) {
		super(poDescriptor);

		this.msvcStock = InjectorService.instance.get(StockService);

		if (!this.msvcStock)
			console.error(`${StockUpdateTask.C_LOG_ID}StockService is undefined, can not exec task !`);
	}

	/** @override */
	public execTask$(): Observable<void> {
		return defer(() => this.msvcStock?.updateAllStocksAsync() ?? of(undefined));
	}

	//#endregion METHODS

}