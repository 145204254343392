<div [ngSwitch]="templateId">

	<ng-template [ngSwitchCase]="'expandable'">
		<div class="user-part" [ngClass]="sectionInfo.class">
			<!-- TODO : Supprimer la couleur / ajouter la couleur dnas l'interface/classe ? https://dev.azure.com/calaosoft/osapp-project/_workitems/edit/2374 -->
			<ion-item-divider [color]="$any(sectionInfo).color" (click)="toggleExtend()" slot="end">
				<avatar *ngIf="userAvatar" [src]="userAvatar"></avatar>
				<ion-label *ngIf="sectionInfo.label">{{ sectionInfo.label }}</ion-label>
				<ion-icon *ngIf="isClosed && sectionInfo.isClosable" name="caret-forward-circle-outline"></ion-icon>
				<ion-icon *ngIf="!isClosed && sectionInfo.isClosable" name="caret-down-circle-outline"></ion-icon>
			</ion-item-divider>
			<ng-template [ngIf]="!isClosed || !sectionInfo.isClosable">
				<ion-menu-toggle auto-hide="false">
					<calao-link menuClose *ngFor="let link of sectionInfo.links" [linkInfo]="link"></calao-link>
				</ion-menu-toggle>
			</ng-template>
		</div>
	</ng-template>

	<ng-template [ngSwitchCase]="'invisible'">
		<calao-link menuClose *ngFor="let link of sectionInfo.links" [linkInfo]="link"></calao-link>
	</ng-template>

	<ng-template [ngSwitchCase]="'avatar'">
		<div class="menu-part">
			<ion-item-divider *ngIf="sectionInfo.label || $any(sectionInfo).params as params">
				<!-- TODO : Supprimer 'params' / ajouter 'params' dans l'interface/classe ? https://dev.azure.com/calaosoft/osapp-project/_workitems/edit/2374 -->
				<ng-container *ngIf="params">
					<ion-avatar slot="start" *ngIf="params.src">
						<img [src]="params.src">
					</ion-avatar>
					<ion-avatar slot="start" *ngIf="params.icon">
						<ion-icon [name]="params.icon"></ion-icon>
					</ion-avatar>
					<div *ngIf="params.iconClass">
						<i [ngClass]="params.src"></i>
					</div>
				</ng-container>
				<ion-label *ngIf="sectionInfo.label">{{sectionInfo.label}}</ion-label>
			</ion-item-divider>
			<ion-menu-toggle auto-hide="false">
				<calao-link menuClose *ngFor="let link of sectionInfo.links" [linkInfo]="link"></calao-link>
			</ion-menu-toggle>
		</div>
	</ng-template>

	<ng-template *ngSwitchDefault>Pas de template nommée {{templateId}}</ng-template>

</div>