import { Exclude } from "class-transformer";
import { BehaviorSubject, Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";

export class ObservableMap<K, V> extends Map<K, V> {

	//#region FIELDS

	@Exclude()
	private readonly moChanges: BehaviorSubject<Map<K, V>>;

	//#endregion FIELDS

	//#region PROPERTIES

	/** Flux continu de récupération des changements du tableau. */
	public get changes$(): Observable<Map<K, V>> { return this.moChanges.asObservable(); }

	//#endregion PROPERTIES

	//#region METHODS

	constructor() {
		super();
		this.moChanges = new BehaviorSubject(this);
	}

	public override set(key: K, value: V): this {
		const loResult: this = super.set(key, value);

		this.moChanges.next(this);

		return loResult;
	}

	public override delete(key: K): boolean {
		const lbResult: boolean = super.delete(key);

		if (lbResult)
			this.moChanges.next(this);

		return lbResult;
	}

	public get$(poKey: K): Observable<V | undefined> {
		return this.moChanges.asObservable().pipe(
			map((poMap: Map<K, V>) => poMap.get(poKey)),
			startWith(this.get(poKey))
		);
	}

	//#endregion METHODS

}
