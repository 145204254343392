import { FileInfo } from "@capacitor/filesystem";

export class SqlDatabase {

	//#region PROPERTIES

	/** Version de la base de données. */
	public readonly version: number;
	/** Informations sur le fichier de la base de données. */
	public readonly file: FileInfo;

	//#endregion PROPERTIES

	//#region METHODS

	public constructor(pnVersion: number, poFileInfo: FileInfo) {
		this.version = pnVersion;
		this.file = poFileInfo;
	}

	//#endregion METHODS

}