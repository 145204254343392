import { Injectable } from '@angular/core';
import { combineLatest, defer, of } from 'rxjs';
import { catchError, last, map, mapTo, mergeMap, tap, toArray } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { EXTENSIONS_AND_MIME_TYPES } from '../../../../helpers/fileHelper';
import { IdHelper } from '../../../../helpers/idHelper';
import { NumberHelper } from '../../../../helpers/numberHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { ConfigData } from '../../../../model/config/ConfigData';
import { ShowMessageParamsToast } from '../../../../services/interfaces/ShowMessageParamsToast';
import { UiMessageService } from '../../../../services/uiMessage.service';
import { CustomerTourService } from '../../../customer-tour/services/customer-tour.service';
import { NotImplementedYetError } from '../../../errors/model/not-implemented-yet-error';
import { OsappApiHelper } from '../../../osapp-api/helpers/osapp-api.helper';
import { SqlDatabaseProvider } from '../../../sqlite/models/SqlDatabaseProvider';
import { EUpdateStatus } from '../../../sqlite/models/eupdate-status';
import { SqlDataSource } from '../../../sqlite/models/sql-data-source';
import { SqlRequestResult } from '../../../sqlite/models/sql-request-result';
import { UpdateEvent } from '../../../sqlite/models/update-event';
import { SqlRequestService } from '../../../sqlite/services/sql-request.service';
import { SqlService } from '../../../sqlite/services/sql.service';
import { TTransferHeaders } from '../../../transfert/models/ttranfer-headers';
import { EKnownMovementState } from '../models/eknown-movement-state';
import { EUpdateStockStatus } from '../models/eupdate-stock-status';
import { IMovement } from '../models/imovement';
import { IStock } from '../models/istock';
import { STOCK_DATABASE_ID } from '../models/stock.constante';

enum EDownloadType {
	create,
	update
}

@Injectable()
export class StockService {

	//#region FIELDS

	private static readonly C_LOG_ID = "STOCK.S::";
	private static readonly C_DB_PREFIX = `stock`;

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcProvider: SqlDatabaseProvider,
		private readonly isvcSql: SqlService,
		private readonly isvcSqlRequest: SqlRequestService,
		private readonly isvcCustomerTour: CustomerTourService,
		private readonly isvcUiMessage: UiMessageService
	) { }

	/** Retourne le nombre de fichiers des stocks installés. */
	public getFilesNumberAsync(): Promise<number> {
		return this.isvcProvider.getNumberOfDatabasesAsync(StockService.C_DB_PREFIX, { includesNotUsableDatabases: true });
	}

	/** Retourne les informations sur les stocks (identifiant du client et version). */
	public async getAllStocksAsync(): Promise<IStock[]> {
		const laSqlDatabases: SqlDataSource[] = await this.isvcProvider.getDatabasesAsync(StockService.C_DB_PREFIX, { includesNotUsableDatabases: true });
		const laStocks: IStock[] = [];

		for (let lnIndex = 0; lnIndex < laSqlDatabases.length; lnIndex++) {
			const loSqlDatabase: SqlDataSource = laSqlDatabases[lnIndex];
			laStocks.push(this.buildStock(loSqlDatabase));
		}

		return this.sortByWarehouseId(laStocks);
	}

	/** Contruit et retourne un stock grâce aux informations sur la base de données du stock.
	 * @param poSqlDatabase Information sur la base de données du stock.
	 */
	private buildStock(poSqlDatabase: SqlDataSource): IStock {
		return {
			warehouseId: this.getWarehouseIdFromDatabaseId(poSqlDatabase.databaseId),
			version: poSqlDatabase.version,
			extension: poSqlDatabase.extension
		};
	}

	private getWarehouseIdFromDatabaseId(psDatabaseId: string): string {
		const loRegex = new RegExp(`^${StockService.C_DB_PREFIX}-(.*)`);
		const loRegexMatch: RegExpMatchArray | null = psDatabaseId.match(loRegex);

		if (!loRegexMatch || loRegexMatch.length < 1) {
			console.error(`${StockService.C_LOG_ID}Recovery of warehouse ID failed, because the database ID '${psDatabaseId}' is incorrect.`);
			throw new Error(`Recovery of warehouse ID failed, because the database ID '${psDatabaseId}' is incorrect.`);
		}

		return loRegexMatch[1];
	}

	/** Trie et retourne les informations sur les stocks en fonction de l'ID de l'entrepôt et de la version (croissant).
	 * @param paStocks Tableau des informations sur les stocks.
	 */
	public sortByWarehouseId(paStocks: IStock[]): IStock[] {
		return paStocks.sort((poStockInfoA: IStock, poStockInfoB: IStock) => {
			if (poStockInfoA.warehouseId.toLowerCase() === poStockInfoB.warehouseId.toLowerCase())
				return NumberHelper.compare(poStockInfoA.version, poStockInfoB.version);

			else if (NumberHelper.isStringNumber(poStockInfoA.warehouseId) && NumberHelper.isStringNumber(poStockInfoB.warehouseId))
				return NumberHelper.compare(+poStockInfoA.warehouseId, +poStockInfoB.warehouseId);

			else
				return StringHelper.compare(poStockInfoA.warehouseId, poStockInfoB.warehouseId);
		});
	}

	/** Met à jour tous les stocks des entrepôts présents dans toutes les tournées de l'utilisateur courant. */
	public async updateAllStocksAsync(): Promise<void> {
		const laCustomerIds: string[] = await this.isvcCustomerTour.getCustomerIdsAsync();
		const laCreatedWarehouseIds: string[] = [];
		const laUpdatedWarehouseIds: string[] = [];
		const laFailedWarehouseIds: string[] = [];

		for (let lnIndex = 0; lnIndex < laCustomerIds.length; ++lnIndex) {
			const lsWarehouseId: string = this.extractWarehouseIdFromCustomerId(laCustomerIds[lnIndex]);
			const leResult: EUpdateStockStatus = await this.updateStockAsync(lsWarehouseId, true);

			if (leResult === EUpdateStockStatus.created)
				laCreatedWarehouseIds.push(lsWarehouseId);
			else if (leResult === EUpdateStockStatus.updated)
				laUpdatedWarehouseIds.push(lsWarehouseId);
			else if (leResult === EUpdateStockStatus.failed)
				laFailedWarehouseIds.push(lsWarehouseId);
		}

		const lsMessage: string = this.getFinalUpdateAllStocksToastMessage(laCreatedWarehouseIds, laUpdatedWarehouseIds, laFailedWarehouseIds);

		if (!StringHelper.isBlank(lsMessage)) {
			return this.isvcUiMessage.createToastAsync(new ShowMessageParamsToast({ message: lsMessage }))
				.then((poToast: HTMLIonToastElement) => poToast.present());
		}
	}

	private getFinalUpdateAllStocksToastMessage(paCreatedWarehouseIds: string[], paUpdatedWarehouseIds: string[], paFailedWarehouseIds: string[]): string {
		const laMessageParts: string[] = [];

		if (paCreatedWarehouseIds.length === 1 && paUpdatedWarehouseIds.length === 0 && paFailedWarehouseIds.length === 0)
			return `Le stock du client ${paCreatedWarehouseIds[0]} a été téléchargé avec succès.`;

		if (paCreatedWarehouseIds.length === 0 && paUpdatedWarehouseIds.length === 1 && paFailedWarehouseIds.length === 0)
			return `Le stock du client ${paUpdatedWarehouseIds[0]} a été mis à jour avec succès.`;

		if (paCreatedWarehouseIds.length === 0 && paUpdatedWarehouseIds.length === 0 && paFailedWarehouseIds.length === 1)
			return `Le téléchargement du stock du client ${paFailedWarehouseIds[0]} a échoué.`;

		if (paCreatedWarehouseIds.length > 0) {
			laMessageParts.push(`- ${paCreatedWarehouseIds.length} `);
			laMessageParts.push(paCreatedWarehouseIds.length > 1 ? "stocks ont été créés." : "stock a été créé.<br/>");
		}

		if (paUpdatedWarehouseIds.length > 0) {
			laMessageParts.push(`- ${paUpdatedWarehouseIds.length} `);
			laMessageParts.push(paUpdatedWarehouseIds.length > 1 ? "stocks ont été mis à jour." : "stock a été mis à jour.<br/>");
		}

		if (paFailedWarehouseIds.length > 0) {
			laMessageParts.push(`- ${paFailedWarehouseIds.length} `);
			laMessageParts.push(paFailedWarehouseIds.length > 1 ? "stocks n'ont pas pu être installés." : "stock n'a pas pu être installé.");
		}

		return laMessageParts.join("");
	}

	/** Extrait l'identifiant d'entrepôt depuis un identifiant de client.
	 * @param psCustomerId Identifiant du client dont il faut extraire l'identifiant d'entrepôt.
	 */
	public extractWarehouseIdFromCustomerId(psCustomerId: string): string {
		return IdHelper.getLastGuidFromId(psCustomerId);
	}

	/** Met à jour le stock de l'entrepôt passé en paramètre.
	 * @param psWarehouseId Identifiant de l'entrepôt.
	 * @param pbHideToast Indique s'il faut ne pas afficher le toast de téléchargement.
	 */
	public updateStockAsync(psWarehouseId: string, pbHideToast?: boolean): Promise<EUpdateStockStatus> {
		return combineLatest([this.getLocalLastVersionAsync(psWarehouseId), this.getRemoteLastVersionAsync(psWarehouseId)])
			.pipe(
				mergeMap(([pnLocalLastVersion, pnRemoteLastVersion]: [number, number]) => {
					// Si la version locale est plus ancienne, alors mettre à jour.
					if (pnLocalLastVersion < pnRemoteLastVersion) {
						return defer(() => this.downloadStockAsync(psWarehouseId, pnRemoteLastVersion))
							.pipe(
								mergeMap(() => {
									if (!pbHideToast) {
										const leDownloadType: EDownloadType = pnLocalLastVersion === 0 ? EDownloadType.create : EDownloadType.update;
										return this.showDownloadedStockToastAsync(psWarehouseId, pnRemoteLastVersion, leDownloadType);
									}
									else
										return Promise.resolve(undefined);
								}),
								map(() => pnLocalLastVersion === 0 ? EUpdateStockStatus.created : EUpdateStockStatus.updated)
							);
					}
					else
						return of(EUpdateStockStatus.unchanged).pipe(
							tap(() => console.info(`${StockService.C_LOG_ID}The warehouse '${psWarehouseId}' stock is uptodate.`))
						);
				}),
				catchError(poError => {
					console.error(`${StockService.C_LOG_ID}Error when updating stock for warehouse id '${psWarehouseId}' :`, poError);
					return of(EUpdateStockStatus.failed);
				})
			)
			.toPromise();
	}

	/** Télécharge le stock de l'entrepôt passé en paramètre.
	 * @param psWarehouseId Identifiant de l'entrepôt.
	 * @param pnVersion Version du dernier stock disponible sur le serveur.
	 * @throws Lève une erreur si le téléchargement de la base de données à échoué.
	 */
	private downloadStockAsync(psWarehouseId: string, pnVersion: number): Promise<void> {
		const lsDatabasePath: string = this.isvcProvider.getLastUrl(STOCK_DATABASE_ID, psWarehouseId, pnVersion);
		const lsDatabaseId: string = this.getDatabaseId(psWarehouseId);
		const loSqlDataSource: SqlDataSource = new SqlDataSource(lsDatabaseId, pnVersion, lsDatabasePath);

		return this.isvcProvider.provide$(loSqlDataSource, this.getHttpHeaders())
			.pipe(
				tap((poUpdateEvent?: UpdateEvent) => {
					if (poUpdateEvent && poUpdateEvent.state === EUpdateStatus.saved)
						console.info(`${StockService.C_LOG_ID}The version '${pnVersion}' of warehouse '${psWarehouseId}' stock has been downloaded.`);
				}),
				last(), // On attend la fin du téléchargement avant de redonner la main.
				mapTo(undefined)
			)
			.toPromise();
	}

	/** Affiche un toast de création / mise à jour d'un stock.
	 * @param psWarehouseId Identifiant de l'entrepôt.
	 * @param pnVersion Nouvelle version installée.
	 * @param peDownloadType Type de téléchargement (création/mise à jour).
	 */
	public showDownloadedStockToastAsync(psWarehouseId: string, pnVersion: number, peDownloadType: EDownloadType): Promise<void> {
		const lsMessage = peDownloadType === EDownloadType.create ?
			`Le stock du client ${psWarehouseId} a été téléchargé avec succès.` :
			`Le stock du client ${psWarehouseId} a été mise à jour en version ${pnVersion} avec succès.`;

		return this.isvcUiMessage.createToastAsync(new ShowMessageParamsToast({ message: lsMessage }))
			.then((poToast: HTMLIonToastElement) => poToast.present());
	}

	/** Retourne l'en-tête HTTP.
	 * @throws Lève un erreur si le `token` et/ou la clé d'API sont manquant dans `ConfigData`.
	*/
	private getHttpHeaders(): TTransferHeaders {
		const loHeaders: TTransferHeaders = {
			appInfo: OsappApiHelper.stringifyForHeaders(ConfigData.appInfo),
			accept: `${EXTENSIONS_AND_MIME_TYPES.zip.mimeType}, ${EXTENSIONS_AND_MIME_TYPES.db.mimeType}`
		};

		if (!ConfigData.authentication.token)
			throw new Error("Unable to update sql database without token.");
		if (!ConfigData.environment.API_KEY)
			throw new Error("Unable to update sql database without api key.");

		loHeaders["token"] = ConfigData.authentication.token;
		loHeaders["api-key"] = ConfigData.environment.API_KEY;

		return loHeaders;
	}

	/** Retourne la dernière version disponible.
	 * - Android: La dernière version téléchargée.
	 * - WebApp: La dernière version disponible sur le serveur.
	 * @param psWarehouseId Identifiant de l'entrepôt.
	 */
	private getLocalLastVersionAsync(psWarehouseId: string): Promise<number> {
		const lsDatabaseId: string = this.getDatabaseId(psWarehouseId);

		return this.isvcProvider.getLastReadyAsync(lsDatabaseId)
			.then((poDataSource?: SqlDataSource) => {
				if (!poDataSource) {
					console.warn(`${StockService.C_LOG_ID}There is no latest version available for stock of warehouse '${psWarehouseId}'.`);
					return 0;
				}

				return poDataSource.version;
			});
	}

	/** Retourne la dernière version disponible sur le serveur.
	 * @param psWarehouseId Identifiant de l'entrepôt.
	 */
	private getRemoteLastVersionAsync(psWarehouseId: string): Promise<number> {
		return this.isvcProvider.getLastRemoteDatabaseAsync(STOCK_DATABASE_ID, psWarehouseId)
			.then((poSqlDataSource: SqlDataSource) => poSqlDataSource.version);
	}

	/** Contruit et retourne l'identifiant d'une base de données à partir de l'identifiant de l'entrepôt.
	 * @param psWarehouseId Identifiant de l'entrepôt.
	 */
	public getDatabaseId(psWarehouseId: string): string {
		return `${StockService.C_DB_PREFIX}-${psWarehouseId}`;
	}

	/** Supprime les stocks passés en paramètre.
	 * @param paStocks Tableau des stocks à supprimer.
	 */
	public removeStocksAsync(paStocks: IStock[]): Promise<void> {
		return defer(() => paStocks)
			.pipe(
				mergeMap((poStock: IStock) => this.removeStockAsync(poStock)),
				toArray(),
				mapTo(undefined)
			)
			.toPromise();
	}

	/** Supprime le stock passé en paramètre.
	 * @param poStock Stock à supprimer.
	 */
	public removeStockAsync(poStock: IStock): Promise<void> {
		return this.isvcProvider.removeDatabaseAsync(this.getDatabaseId(poStock.warehouseId), poStock.version)
			.catch(poError => {
				// La méthode n'est pas implémentée sur navigateur donc on considère que c'est ok, on ne lève pas l'erreur.
				if (poError instanceof NotImplementedYetError)
					return undefined;
				else {
					console.error(`${StockService.C_LOG_ID}Error when removing stock ${poStock.warehouseId} in version ${poStock.version} :`, poError);
					throw poError;
				}
			});
	}

	/** Télécharge un stock et retourne son `DataSource`.
	 * @param psWarehouseId Id de l'entrepôt.
	 * @throws Lève une erreur si le `DataSource` est manquant.
	 */
	private async downloadStockAndGetDataSourceAsync(psWarehouseId: string): Promise<SqlDataSource> {
		const lnVersion: number = await this.getRemoteLastVersionAsync(psWarehouseId);
		await this.downloadStockAsync(psWarehouseId, lnVersion);
		const loDataSource: SqlDataSource | undefined = await this.isvcSql.getDataSourceFromDatabaseIdAsync(this.getDatabaseId(psWarehouseId));

		if (!loDataSource) {
			console.error(`${StockService.C_LOG_ID}The SQL data source of warehouse database '${psWarehouseId}' is missing.`);
			return Promise.reject(new Error(`Recovery of the SQL data source of warehouse database '${psWarehouseId}' failed because it's missing.`));
		}
		else
			return loDataSource;
	}

	//#region Requests

	/** Récupère les mouvements de chaque article.
	 * @param psWarehouseId Id de l'entrepôt.
	 * @param paItemIds Liste des ids d'articles.
	 */
	public getMovementsFromItemIdsAsync(psWarehouseId: string, paItemIds: string[]): Promise<IMovement[]> {
		if (!ArrayHelper.hasElements(paItemIds))
			return Promise.resolve([]);
		else {
			const laUniqueItemIds: string[] = ArrayHelper.unique(paItemIds);

			return this.requestAsync(psWarehouseId, this.getMovementsFromItemIdsRequest(laUniqueItemIds), laUniqueItemIds)
				.then((poResult: SqlRequestResult<IMovement>) => {
					console.info(`${StockService.C_LOG_ID}Get movements in database '${psWarehouseId}' from item ids in ${poResult.time}ms`);
					return poResult.results;
				});
		}
	}

	private getMovementsFromItemIdsRequest(paItemIds: string[]): string {
		return `
			SELECT *
			FROM Movement
			WHERE itemId ${this.isvcSqlRequest.getInRequest(paItemIds.length)}
		`;
	}

	/** Récupère la dernière catégorie livrée pour chaque article.
	 * @param psWarehouseId Id de l'entrepôt.
	 * @param paItemIds Liste des ids d'articles.
	 */
	public getLastCategoryByItemIdAsync(psWarehouseId: string, paItemIds: string[]): Promise<Map<string, string>> {
		if (!ArrayHelper.hasElements(paItemIds))
			return Promise.resolve(new Map<string, string>());
		else {
			const laUniqueItemIds: string[] = ArrayHelper.unique(paItemIds);

			return this.requestAsync(psWarehouseId, this.getLastCategoryByItemIdRequest(laUniqueItemIds), laUniqueItemIds)
				.then((poResult: SqlRequestResult<IMovement>) => {
					console.info(`${StockService.C_LOG_ID}Get last category by itemId in ${poResult.time}ms for database '${psWarehouseId}'`);
					return new Map<string, string>(poResult.results.map((poItem: IMovement) => [poItem.itemId, poItem.categoryId]));
				});
		}
	}

	private getLastCategoryByItemIdRequest(paItemIds: string[]): string {
		return `
			SELECT itemId, MAX(date) as date, categoryId
			FROM Movement
			WHERE itemId ${this.isvcSqlRequest.getInRequest(paItemIds.length)}
			AND (stateId = "${EKnownMovementState.delivered}" OR stateId = "${EKnownMovementState.returns}")
			AND date <= CURRENT_DATE
			GROUP BY itemId
		`;
	}

	/** Ouvre une base de données en mémoire, ferme les autres, puis exécute et retourne le résultat d'une requête.
	 * @param psWarehouseId Id de l'entrepôt.
	 * @param psQuery Requête SQL à exécuter.
	 */
	private requestAsync<T>(psWarehouseId: string, psQuery: string, paParams: string[]): Promise<SqlRequestResult<T>> {
		return this.isvcSql.getDataSourceFromDatabaseIdAsync(this.getDatabaseId(psWarehouseId))
			.then((poDataSource?: SqlDataSource) => {
				if (!poDataSource)
					return this.downloadStockAndGetDataSourceAsync(psWarehouseId);
				else
					return poDataSource;
			})
			.then((poDataSource: SqlDataSource) => this.isvcSql.requestAsync<T>(poDataSource, psQuery, paParams, StockService.C_DB_PREFIX));
	}

	//#endregion Requests

	//#endregion METHODS

}