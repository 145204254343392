import { Injectable, Optional, Type } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalOptions, OverlayEventDetail } from '@ionic/core';
import { BehaviorSubject, Observable, Subject, defer, from } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { NumberHelper } from '../../../../helpers/numberHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { PlatformService } from '../../../../services/platform.service';
import { ScanditService } from '../../../scandit/scandit.service';
import { UserFeedBackService } from '../../../user-feedback/services/user-feedback.service';
import { afterSubscribe } from '../../../utils/rxjs/operators/after-subscribe';
import { InputBarcodeModalComponentComponent } from '../../devices/input/input-barcode-modal-component/input-barcode-modal-component.component';
import { InputBarcodeReaderServiceService } from '../../devices/input/input-barcode-reader-service.service';
import { IBarcodeAcquisition } from '../../devices/models/ibarcode-acquisition';
import { TslBarcodeModalComponent } from '../../devices/tsl/tsl-barcode-modal/tsl-barcode-modal.component';
import { TslService } from '../../devices/tsl/tsl.service';
import { EAccuracyType } from '../../settings/models/eaccuracy-type';
import { EBarcodeReaders } from '../models/EBarcodeReaders';
import { IBarcodeReader } from '../models/IBarcodeReader';
import { BarcodeReaderConfigService } from './barcode-reader-config.service';
import { CipherlabBarcodeReaderService } from './cipherlab-barcode.service';
import { CordovaBarcodeReaderService } from './cordova-barcode.service';

/** Niveau d'abstraction sur le SDK d'acquistion de lecteur de code-à-barres.
 * Permet de changer de lecteur et de l'utiliser.
 */
@Injectable()
export class BarcodeReaderService {

	//#region FIELDS

	/** Identifiant pour les logs. */
	private static readonly C_LOG_ID = "BARCODE-READER.S::";
	/** Durée minimum par défaut entre deux scans d'un même code (en secondes). */
	private static readonly C_DEFAULT_BLOCKING_DURATION = 3;

	/** Modèle du cipherlab. */
	public static readonly C_CIPHERLAB_MODEL = "RS35";

	/** Sujet qui détermine la durée minimum entre deux scans d'un même code (en secondes) pour Scandit uniquement. */
	private readonly moScanditBlockingDurationSubject = new BehaviorSubject<number>(BarcodeReaderService.C_DEFAULT_BLOCKING_DURATION);
	/** Sujet qui manipule le focus de Scandit. */
	private readonly moFocusSubject = new BehaviorSubject<number>(ScanditService.C_DEFAULT_BLOCK_FOCUS);
	/** Valeur de l'énumération du lecteur de code-à-barres actuellement utilisé. */
	private readonly moCurrentBarcodeReaderNameSubject: BehaviorSubject<EBarcodeReaders>;
	/** Sujet pour manipuler le délai, en miliseconde, entre chaque scan pour le cipherlab. */
	private readonly moDelayBetweenCipherlabScansMsSubject: BehaviorSubject<number>;
	private readonly moReadModalSubject = new Subject<IBarcodeAcquisition[]>();
	/** Promesse qui indique quand est-ce que la plateforme est prête afin d'utiliser les plugins. */
	private readonly moIsReadyAsync: Promise<string>;
	/** Tableau desx préfixes de code-barres qu'il ne faut pas accepter lors d'un scan. */
	private readonly maExcludePrefixBarcodes: ReadonlyArray<string>;

	/** Lecteur de code-à-barres actuellement utilisé. */
	private moCurrent: IBarcodeReader;
	/** Mode de lecture de code barre par défaut, `cordova` pour ne causer d'erreur si un plugin n'est pas installé pour une lecture spécifique. */
	private meDefaultBarcodeReader = EBarcodeReaders.cordova;

	//#endregion

	//#region PROPERTIES

	/** Observable qui indique la durée minimum entre deux scans d'un même code (en secondes). */
	public get scanditBlockingDuration$(): Observable<number> { return this.moScanditBlockingDurationSubject.asObservable(); }
	/** Observable qui indique le focus de Scandit. */
	public get scanditFocus$(): Observable<number> { return this.moFocusSubject.asObservable(); }
	/** Observable qui notifie lors du changement de lecteur de code-à-barres. */
	public get reader$(): Observable<EBarcodeReaders> { return this.moCurrentBarcodeReaderNameSubject.asObservable(); }
	/** Observable qui indique le délai d'attente en miliseconde entre chaque scan pour le cipherlab. */
	public get delayBetweenCipherlabScansMs$(): Observable<number> { return this.moDelayBetweenCipherlabScansMsSubject.asObservable(); }

	private mnDelayBetweenCipherlabScansMs = 500;
	/** Temps en miliseconde entre chaque lecture de code-barre. */
	public get delayBetweenCipherlabScansMs(): number { return this.mnDelayBetweenCipherlabScansMs; }

	private mnCipherlabMaxTimeScanMs = 3000;
	/** Temps maximum (en millisecondes) pour scanner un article. Temps par défaut : `3000`. */
	public get cipherlabMaxTimeScanMs(): number { return this.mnCipherlabMaxTimeScanMs; }

	//#endregion

	//#region METHODS

	public constructor(
		private readonly isvcPlatform: PlatformService,
		private readonly isvcBarcode: CordovaBarcodeReaderService,
		private readonly isvcScandit: ScanditService,
		private readonly isvcTsl: TslService,
		private readonly isvcInputReader: InputBarcodeReaderServiceService,
		private readonly isvcCipherlab: CipherlabBarcodeReaderService,
		private readonly ioModalCtrl: ModalController, //todo : supprimer
		private readonly isvcUserFeedback: UserFeedBackService,
		@Optional() psvcBarcodeReaderConfig?: BarcodeReaderConfigService
	) {
		this.moDelayBetweenCipherlabScansMsSubject = new BehaviorSubject(this.mnDelayBetweenCipherlabScansMs);
		this.moCurrentBarcodeReaderNameSubject = new BehaviorSubject<EBarcodeReaders>(this.meDefaultBarcodeReader);

		// Initialise la durée entre deux acquisitions avec une valeur par défaut.
		this.moScanditBlockingDurationSubject.next(BarcodeReaderService.C_DEFAULT_BLOCKING_DURATION);
		this.moIsReadyAsync = isvcPlatform.readyAsync;

		this.maExcludePrefixBarcodes = psvcBarcodeReaderConfig?.getExcludePrefixBarcodes() ?? [];

		this.selectDefaultReader();
	}

	/** Permet de choisir le lecteur de code-à-barres à utiliser, puis l'initialiser. */
	public async selectReaderAsync(peReader: EBarcodeReaders): Promise<void> {
		await this.moIsReadyAsync;

		switch (peReader) {
			case EBarcodeReaders.cordova:
				this.setCordovaReader();
				break;
			case EBarcodeReaders.scandit:
				this.setScanditReader();
				break;
			case EBarcodeReaders.tsl:
				this.setTslReader();
				break;
			case EBarcodeReaders.input:
				this.setInputReader();
				break;
			case EBarcodeReaders.cipherlab:
				this.setCipherlabReader();
				break;
			default:
				console.error(`${BarcodeReaderService.C_LOG_ID}Type de lecteur non reconnu "${peReader}".`);
		}
	}

	/** Sélectionne le lecteur (lecteur cordova par défaut). */
	private selectDefaultReader(): void {
		if (this.isvcPlatform.model === BarcodeReaderService.C_CIPHERLAB_MODEL)
			this.setCipherlabReader();
		else
			this.setCordovaReader();
	}

	/** Le lecteur de code-barres courant devient Scandit. */
	private setScanditReader(): void {
		this.setReader(EBarcodeReaders.scandit, this.isvcScandit);

		this.isvcScandit.updateDuplicateFilterDuration(this.moScanditBlockingDurationSubject.getValue());
	}

	/** Le lecteur de code-barres courant devient celui de Cordova. */
	private setCordovaReader(): void {
		this.setReader(EBarcodeReaders.cordova, this.isvcBarcode);
	}

	/** Le lecteur de code-barres courant devient celui du TSL. */
	private setTslReader(): void {
		this.setReader(EBarcodeReaders.tsl, this.isvcTsl);
	}

	/** Le lecteur de code-barres en simulant des appels de texte natif. */
	private setInputReader(): void {
		this.setReader(EBarcodeReaders.input, this.isvcInputReader);
	}

	/** Lecteur utilisant le SDK de Cipherlab. Utilisable pour les téléphones du constructeur uniquement. */
	private setCipherlabReader(): void {
		this.setReader(EBarcodeReaders.cipherlab, this.isvcCipherlab);
	}

	/** Change les valeurs de la classe pour le lecteur de code-à-barres et l'initialize. */
	private setReader(peReader: EBarcodeReaders, poReader: IBarcodeReader): void {
		this.moCurrentBarcodeReaderNameSubject.next(peReader);
		this.moCurrent = poReader;
		this.moCurrent.initializeBarcode();
	}

	/** Change la durée pendant laquelle une acquisition n'est pas considérée comme lue (en secondes).
	 * ! Ne fonctionne qu'avec Scandit.
	*/
	public changeScanditBlockingDuration(pnValue: number): void {
		console.debug(`${BarcodeReaderService.C_LOG_ID}Changement de durée minimum entre deux scans d'un même code: ${pnValue}.`);
		this.moScanditBlockingDurationSubject.next(pnValue);

		if (this.moCurrentBarcodeReaderNameSubject.value === EBarcodeReaders.scandit)
			this.isvcScandit.updateDuplicateFilterDuration(this.moScanditBlockingDurationSubject.getValue());
	}

	/** Change le focus de la camera.
	 * ! Ne fonctionne qu'avec Scandit.
	 */
	public setFocus(psValue: number): void {
		this.moFocusSubject.next(psValue);
		this.isvcScandit.setFixedFocus(psValue);
	}

	/** Lance une lecture avec l'appareil configuré via la méthode `selectReaderAsync()`. */
	public async readAsync(): Promise<void> {
		await this.moIsReadyAsync;

		// !TODO Temporaire (passer par une modale générique).
		if (this.moCurrentBarcodeReaderNameSubject.getValue() === EBarcodeReaders.tsl)
			this.openReadModal(TslBarcodeModalComponent)
				.subscribe((paAcquisitions: IBarcodeAcquisition[]) => this.moReadModalSubject.next(paAcquisitions));
		else if (this.moCurrentBarcodeReaderNameSubject.getValue() === EBarcodeReaders.input)
			this.openReadModal(InputBarcodeModalComponentComponent)
				.subscribe((paAcquisitions: IBarcodeAcquisition[]) => this.moReadModalSubject.next(paAcquisitions));
		else
			this.moCurrent.readBarcode();
	}

	public onBarcodeRead(): Observable<IBarcodeAcquisition[]> {
		return defer(() => this.moIsReadyAsync)
			.pipe(
				mergeMap(_ => {
					if (this.moCurrentBarcodeReaderNameSubject.getValue() === EBarcodeReaders.tsl && this.moCurrentBarcodeReaderNameSubject.getValue() === EBarcodeReaders.input)
						return this.moReadModalSubject.asObservable();
					else
						return this.moCurrent.onBarcodeReaded();
				}),
				map((paAcquisitions: IBarcodeAcquisition[]) => this.filterValidBarcodes(paAcquisitions)),
				filter((paFilteredAcquisitions: IBarcodeAcquisition[]) => {
					if (ArrayHelper.hasElements(paFilteredAcquisitions)) // Si on a au moins un code-barres de valide, on passe à la suite.
						return true;
					else { // Sinon, pas de code-barres donc on s'arrête et on notifie l'utilisateur.
						this.isvcUserFeedback.notifyError();
						return false;
					}
				}),
				tap((paFilteredAcquisitions: IBarcodeAcquisition[]) =>
					paFilteredAcquisitions.forEach((poAcquisition: IBarcodeAcquisition) => console.debug(`${BarcodeReaderService.C_LOG_ID}Code-barres '${poAcquisition.code}' lu.`))
				)
			);
	}

	/** Filtre les codes-barres valides (non vides, préfixes exclus).
	 * @param paAcquisitions Tableau des acquisitions à tester.
	 */
	private filterValidBarcodes(paAcquisitions: IBarcodeAcquisition[]): IBarcodeAcquisition[] {
		return paAcquisitions.filter((poAcquisition: IBarcodeAcquisition) => {
			return !StringHelper.isBlank(poAcquisition.code) &&
				this.maExcludePrefixBarcodes.every((psPrefix: string) => !poAcquisition.code.startsWith(psPrefix));
		});
	}

	public readOneBarcode(): Observable<IBarcodeAcquisition[]> {
		return defer(() => this.moIsReadyAsync)
			.pipe(
				mergeMap(_ => this.onBarcodeRead()),
				take(1),
				afterSubscribe(() => this.readAsync())
			);
	}

	/** Fonction temporaire pour afficher une modale pour le composant TSL. */
	private openReadModal<T>(poComponent: Type<T>): Observable<IBarcodeAcquisition[]> {
		const loModalParam: any = {
		};

		const loModalOptions: ModalOptions = {
			component: poComponent,
			componentProps: {
				params: loModalParam,
			}
		};

		return from(this.ioModalCtrl.create(loModalOptions))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				map((poValue: OverlayEventDetail<IBarcodeAcquisition[]>) => {
					// Si l'utilisateur fait retour, data est 'undefined'.
					return ArrayHelper.hasElements(poValue.data) ? poValue.data : [];
				})
			);
	}

	/** Lève un événement pour notifier un nouveau délai d'attente (qui doit être strictement supérieur à 0) entre chaque scan en miliseconde.
	 * @param pnDelayMs Nouveau temps d'attente en miliseconde entre chaque scan.
	 */
	public raiseNewDelayBetweenCipherlabScansMs(pnDelayMs: number): void {
		if (NumberHelper.isValidStrictPositive(pnDelayMs))
			this.moDelayBetweenCipherlabScansMsSubject.next(pnDelayMs);
	}

	/** Arrête la lecture des codes-barres (s'il y en a une en cours). */
	public async stopReadBarcodeAsync(): Promise<void> {
		await this.moIsReadyAsync;

		if (this.moCurrent.stopReadBarcode)
			this.moCurrent.stopReadBarcode();
	}

	/** Change la précision du lecteur.. */
	public async setAccuracyAsync(peAccuracy: EAccuracyType): Promise<void> {
		await this.moIsReadyAsync;

		if (this.moCurrent.setAccuracy)
			this.moCurrent.setAccuracy(peAccuracy);
	}

	//#endregion

}