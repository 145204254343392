import { Injectable } from '@angular/core';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { MapHelper } from '@calaosoft/osapp/helpers/mapHelper';
import { StringHelper } from '@calaosoft/osapp/helpers/stringHelper';
import { LoggerService } from '@calaosoft/osapp/modules/logger/services/logger.service';
import { CatalogService } from '@calaosoft/osapp/modules/logistics/catalog/services/catalog.service';
import { IReason } from '@calaosoft/osapp/modules/logistics/reason/models/IReason';
import { ERackStatus } from '@calaosoft/osapp/modules/logistics/task/models/ERackStatus';
import { IControlItem } from '@calaosoft/osapp/modules/logistics/task/returns/models/IControlItem';
import { IRackReturnArticle } from '@calaosoft/osapp/modules/logistics/task/returns/models/IRackReturnArticle';
import { IReturnTask } from '@calaosoft/osapp/modules/logistics/task/returns/models/IReturnTask';
import { IReturnRack } from '@calaosoft/osapp/modules/logistics/task/returns/models/ireturn-rack';
import { ReturnRackService } from '@calaosoft/osapp/modules/logistics/task/returns/services/return-rack.service';
import { TaskService } from '@calaosoft/osapp/modules/logistics/task/services/task.service';
import { ShowMessageParamsPopup } from '@calaosoft/osapp/services/interfaces/ShowMessageParamsPopup';
import { Store } from '@calaosoft/osapp/services/store.service';
import { UiMessageService } from '@calaosoft/osapp/services/uiMessage.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { NO_PICKING_REASON_ID } from '../../../../app.constants';
import { IAnomalyItem } from '../models/anomaly/ianomaly-item';
import { MerchReasonService } from './merch-reason.service';

@Injectable()
export class MerchReturnRackService extends ReturnRackService {

	//#region FIELDS

	private static readonly C_MERCH_LOG_ID = "MERCH.RACK.S::";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcCatalog: CatalogService,
		protected override readonly isvcReason: MerchReasonService,
		psvcStore: Store,
		psvcLogger: LoggerService,
		psvcTask: TaskService,
		psvcUiMessage: UiMessageService
	) {
		super(isvcReason, psvcStore, psvcLogger, psvcTask, psvcUiMessage);
	}

	/** Retourne le tableau des identifiants des articles qui n'ont pas été scannés.
	 * @param poRack Portant à vérifier.
	 */
	private getMissingScannedItemIdsAsync(poRack: IReturnRack): Promise<string[]> {
		if (poRack.status === ERackStatus.canceled)
			return Promise.resolve([]);
		else {
			return this.isvcReason.getNoPickingReasonsAsync()
				.then((paNoPickingReasons: IReason[]) => this.innerGetMissingScannedItemIdsAsync(poRack, paNoPickingReasons));
		}
	}

	/** Retourne un tableau vide si aucun identifiant ne doit être récupéré.
	 * @param poRack Portant à vérifier.
	 * @param paNoPickingReasons Tableau des motifs de non prélèvement.
	 */
	private innerGetMissingScannedItemIdsAsync(poRack: IReturnRack, paNoPickingReasons: IReason[]): Promise<string[]> {
		const laBarcodes: string[] = [];
		const laVariantIds: string[] = [];
		const laRemainingPickingArticlesIds: string[] = Object.values(poRack.picking)
			.filter((poArticle: IRackReturnArticle) => // On garde les articles qui n'ont pas de motif de non prélèvement.
				StringHelper.isBlank(poArticle.reasonId) || paNoPickingReasons.every((poReason: IReason) => poReason._id !== poArticle.reasonId)
			)
			.map((poRackArticle: IRackReturnArticle) => poRackArticle.itemId);

		poRack.controls.forEach((poItem: IControlItem, pnIndex: number) => {
			if (poItem.code) // Si on trouve un code-barres, on l'ajoute au tableau des code-barres qu'il faut relier à un identifiant d'article.
				laBarcodes.push(poItem.code);
			else if (poItem.itemId) // Si on a directement un identifiant d'article, on le supprime du tableau des identifiants d'articles restants.
				ArrayHelper.removeElement(laRemainingPickingArticlesIds, poItem.itemId);
			else if (poItem.variantId) // Si on a un identifiant de variant mais pas d'autre identifiant, on le met de côté pour récupérer l'identifiant d'article associé.
				laVariantIds.push(poItem.variantId);
			else // Pas normal de ne pas avoir de `code` ni de `itemId`.
				console.warn(`${MerchReturnRackService.C_MERCH_LOG_ID}le contrôle à l'index ${pnIndex} du portant ${poRack._id} n'a ni code-barres ni identifiant d'article associé !`);
		});

		if (ArrayHelper.hasElements(laRemainingPickingArticlesIds)) // Si on a des identifiants d'article manquants, il faut les récupérer pour trier.
			return this.getMissingScannedVariantIdsAsync(laRemainingPickingArticlesIds, laBarcodes, laVariantIds);
		else // Si on a pas d'identifiant à récupérer, c'est ok (portant complet).
			return Promise.resolve([]);
	}

	/** Retourne le tableau des identifiants des variants qui n'ont pas été scannés.
	 * @param paRemainingPickingArticlesIds Tableau des identifiants d'articles restants à vérifier s'ils ont été contrôlés.
	 * @param paBarcodes Tableau des code-barres contrôlés dans le portant.
	 * @param paVariantIds Tableau des identifiants de variant contrôlés dans le portant.
	 */
	private getMissingScannedVariantIdsAsync(paRemainingPickingArticlesIds: string[], paBarcodes: string[], paVariantIds: string[]): Promise<string[]> {
		return this.isvcCatalog.getItemIdByBarcode(paBarcodes)
			.pipe(
				map((poItemIdByBarcode: Map<string, string>) => this.getMissingIds(paRemainingPickingArticlesIds, poItemIdByBarcode)),
				mergeMap((paMissingItemIds: string[]) => {
					// Si le portant est complet ou s'il n'y a pas de variants à vérifier, on retourne le résultat.
					if (!ArrayHelper.hasElements(paMissingItemIds) && !ArrayHelper.hasElements(paVariantIds))
						return of([]);
					else { // Sinon, on récupère les identifiants d'article à partir des identifiants de variant.
						return this.isvcCatalog.getItemIdByVariantId(paVariantIds)
							.pipe(map((poItemIdByVariantId: Map<string, string>) => this.getMissingIds(paRemainingPickingArticlesIds, poItemIdByVariantId)));
					}
				})
			)
			.toPromise();
	}

	/** Retourne un tableau des identifiants des articles qui n'ont pas été scannés, sinon retourne un tableau vide.
	 * @param paPickingArticlesIds Tableau des identifiants d'articles prélevés dans le portant à vérifier s'ils ont été contrôlés au moins une fois.
	 * @param poItemIdByKey Map associant un identifiant d'article en fonction d'une clé avec laquelle vérifier si les articles prélevés ont été contrôlés.
	 */
	private getMissingIds(paPickingArticlesIds: string[], poItemIdByKey: Map<string, string>): string[] {
		const laUniqueItemIds: string[] = ArrayHelper.unique(MapHelper.valuesToArray(poItemIdByKey));

		for (let lnIndex = laUniqueItemIds.length - 1; lnIndex >= 0; --lnIndex) {
			const lsCurrentItemId: string = laUniqueItemIds[lnIndex];

			ArrayHelper.removeElement(paPickingArticlesIds, lsCurrentItemId);

			if (!ArrayHelper.hasElements(paPickingArticlesIds))
				return [];
		}

		return paPickingArticlesIds;
	}

	/** Ajoute une anomalie au portant.
	 * @param poAnomaly Anomalie qu'il faut ajouter au portant.
	 * @param poRack Portant dont il faut ajouter une anomalie.
	 */
	public addAnomalyToRack(poAnomaly: IAnomalyItem, poRack: IReturnRack): void {
		if (poAnomaly.itemId) {
			let loRackArticle: IRackReturnArticle | undefined = poRack.picking[poAnomaly.itemId];

			if (!loRackArticle) // Si l'article n'est pas déclaré dans les prélèvements, on l'ajoute.
				loRackArticle = poRack.picking[poAnomaly.itemId] = { itemId: poAnomaly.itemId, price: poAnomaly.price, pickingDate: new Date() };

			if (poAnomaly.reason.scope === "item")
				this.addItemAnomaly(poAnomaly, poRack);
			else
				this.addSkuAnomaly(poAnomaly, poRack);
		}
	}

	/** Ajoute une anomalie de type `item` dans le portant.
	 * @param poAnomaly Anomalie qu'il faut ajouter au portant.
	 * @param poRack Portant dans lequel ajouter l'anomalie.
	 */
	private addItemAnomaly(poAnomaly: IAnomalyItem, poRack: IReturnRack): void {
		// On ajoute le motif dans le prélèvement dans le cas d'une portée 'item'.
		if (poAnomaly.itemId)
			poRack.picking[poAnomaly.itemId].reasonId = poAnomaly.reason._id;
	}

	/** Ajoute une anomalie de type `sku` dans le portant.
	 * @param poAnomaly Anomalie qu'il faut ajouter au portant.
	 * @param poRack Portant dans lequel ajouter l'anomalie.
	 */
	private addSkuAnomaly(poAnomaly: IAnomalyItem, poRack: IReturnRack): void {
		const loNewControlItem: IControlItem = this.buildAnomalyControlItem(poAnomaly);
		loNewControlItem.reasonId = poAnomaly.reason._id; // On ajoute l'identifiant du motif pour un article de portée 'sku'.

		// On ajoute autant d'éléments de contrôles que de quantité souhaitée pour une portée 'sku'.
		for (let lnIndex = 0; lnIndex < (poAnomaly.qty ?? 0); ++lnIndex) {
			poRack.controls.unshift({ ...loNewControlItem });
		}
	}

	/** Crée et retourne un élément de contrôle depuis une anomalie générée par la modale de déclaration d'anomalie.
	 * @param poAnomaly Anomalie générée à partir de laquelle créer l'élément de contrôle.
	 */
	private buildAnomalyControlItem(poAnomaly: IAnomalyItem): IControlItem {
		if (!StringHelper.isBlank(poAnomaly.code)) // Si on a un code-barres, on l'utilise prioritairement.
			return { code: poAnomaly.code } as IControlItem;
		else // Sinon on utilise l'identifiant de l'article et du variant.
			return { itemId: poAnomaly.itemId, variantId: poAnomaly.variantId } as IControlItem;
	}

	/** Retourne `true` si l'article peut être contrôlé dans le portant donné, `false` sinon.
	 * @param psItemId Identifiant de l'article à tester.
	 * @param poRack Portant dont il faut vérifier si l'article peut être contrôlé.
	 */
	public override canItemBeControlledInRack(psItemId: string, poRack: IReturnRack): Observable<boolean> {
		return super.canItemBeControlledInRack(psItemId, poRack, NO_PICKING_REASON_ID);
	}

	/** Retourne une copie du portant passé en paramètre.
	 * @param poRack Portant à copier
	 */
	public createRackCopy(poRack: IReturnRack): IReturnRack {
		return { ...poRack, picking: { ...poRack.picking }, controls: [...(poRack.controls ?? [])] };
	}

	/** Retourne `true` si le portant peut être validé.
	 * @param poTask Tâche du portant à vérifier.
	 * @param poRack Portant à vérifier.
	 */
	public canValidateRackAsync(poTask: IReturnTask, poRack: IReturnRack): Promise<boolean> {
		return this.getMissingScannedItemIdsAsync(poRack)
			.then((paMissingItemsIds: string[]) => {
				if (paMissingItemsIds.length === 0)
					return true;
				else {
					const lsMessage: string = (paMissingItemsIds.length === 1) ?
						`L'article ${paMissingItemsIds} a été prélevé mais n'a pas été lu.` : `Les articles ${paMissingItemsIds.map((psId: string) => psId).join(", ")} ont été prélevés mais n'ont pas été lus.`;
					this.isvcUiMessage.showMessage(new ShowMessageParamsPopup({ message: lsMessage, header: `${poTask.rackLabel ?? "Portant"} incomplet`, }));
					return false;
				}
			});
	}

	//#endregion

}