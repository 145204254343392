import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { IStoreDocument } from '../../../../model/store/IStoreDocument';
import { SettingsService } from '../../../settings/services/settings.service';
import { LogisticsSettings } from '../models/logistics-settings';

@Injectable()
export class LogisticsSettingsService {

	//#region FIELDS

	private readonly LOGISTICS_PROP_NAME = "logistics";

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcSettings: SettingsService,
	) { }

	/** Retourne les paramètres logistiques. */
	public get$(): Observable<LogisticsSettings> {
		return this.isvcSettings.get$().pipe(
			map((poUserSettings: IStoreDocument) => poUserSettings[this.LOGISTICS_PROP_NAME] ? plainToClass(LogisticsSettings, poUserSettings[this.LOGISTICS_PROP_NAME]) : new LogisticsSettings())
		);
	}

	/** Met à jour les paramètres logistiques.
	 * @param poLogisticsSetting Document des préférences utilisateur.
	 */
	public updateAsync(poLogisticsSetting: LogisticsSettings): Promise<void> {
		return this.isvcSettings.get$().pipe(
			take(1),
			mergeMap((poUserSettings: IStoreDocument) => {
				poUserSettings[this.LOGISTICS_PROP_NAME] = poLogisticsSetting;
				return this.isvcSettings.updateAsync(poUserSettings);
			})
		).toPromise();
	}

	//#endregion

}
