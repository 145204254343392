<calao-tour-header [title]="title" [isCurrentTour]="isCurrentTour"
	[hasPreviousButton]="!!(observablePreviousTourId.value$ | async)"
	[hasNextButton]="!!(observableNextTourId.value$ | async)" (previousButtonClicked)="raisePreviousButtonClicked()"
	(nextButtonClicked)="raiseNextButtonClicked()"></calao-tour-header>

<ion-list *ngIf="!(observableShowOnlyHeader.value$ | async)">
	<ng-container *ngIf="(data.changes$ | async).length === 0">
		<calao-blank-information [title]="blankInformationTitle" [subtitle]="blankInformationSubtitle" icon="cactus">
		</calao-blank-information>
	</ng-container>

	<ng-container *ngFor="let item of (data.changes$ | async); let i = index; let first = first;">
		<!-- Divider si nouvelle journée. -->
		<ion-item-divider *ngIf="first || !isSameDay(item.expectedDate, data[i-1].expectedDate)" class="ion-no-padding">
			<!-- Pourcentage de complétion de la journée. -->
			<calao-completion-bar [id]="item.expectedDate" [value]="getPercentageDayCompletion(item.expectedDate)">
				<!-- Date de la journée. -->
				<ion-label class="date-tour" [color]="isCurrentDate(item) ? 'success': 'toolbar'">
					{{ getAppointmentFormattedDate(item) }}</ion-label>
			</calao-completion-bar>
		</ion-item-divider>

		<!-- Injection du template spécifique à l'application. -->
		<ng-container *ngTemplateOutlet="templates[item.template]; context:{ appointment: item }">
		</ng-container>
	</ng-container>
</ion-list>