<div class="title-area">
	<ion-icon [name]="icon"></ion-icon>
	<ion-label>{{ label }}</ion-label>
</div>

<div class="input-area">
	<div class="main">
		<ion-label *ngIf="!!subLabel">{{ subLabel }}</ion-label>
		<ion-input #input inputmode="numeric" (ionFocus)="selectValue()" [min]="minValue" [(ngModel)]="value"
			[disabled]="readonly">
			<div *ngIf="totalCategoryItems" class="total-elements">
				<ion-icon name="counting"></ion-icon>
				<ion-note>{{ totalCategoryItems }}</ion-note>
			</div>
		</ion-input>
	</div>
	<div *ngIf="!!infoLabel" class="info">
		<ion-note>{{ infoLabel }}</ion-note>
	</div>
</div>
