import { Injectable } from '@angular/core';
import { BluetoothSerial } from '@ionic-native/bluetooth-serial/ngx';
import { from, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, mapTo, mergeMap, tap } from 'rxjs/operators';
import { IBluetoothDevice } from './models/IBluetoothDevice';
import { UnableToConnectError } from './models/UnableToConnectError';

/** Module d'utilisation du Bluetooth pour le module Calao-Logistics. */
@Injectable()
export class BluetoothService {

	//#region FIELDS

	private moConnectedDeviceSubject: ReplaySubject<IBluetoothDevice> = new ReplaySubject(1);

	//#endregion

	//#region METHODS

	constructor(private ioBluetooth: BluetoothSerial) { }

	/** Permet de se connecter à un appareil donné.
	 * @param psMacAddOrDeviceId Adresse de l'appareil auquel on souhaite se connecter.
	 * @throws {UnableToConnectError}
	 */
	public connect(psMacAddOrDeviceId: string): Observable<IBluetoothDevice> {
		return this.isConnected()
			.pipe(
				mergeMap((pbIsConnected: boolean) => pbIsConnected ? this.ioBluetooth.disconnect() : of(true)), // Si on est connecté, on se déconnecte.
				mergeMap(_ => this.ioBluetooth.connect(psMacAddOrDeviceId)), // On se connecte à l'appareil.
				tap((poResult) => console.log("BLUE.S::Connexion réussie :", poResult)),
				mergeMap(_ => this.updateConnectedDevice(psMacAddOrDeviceId)),
				catchError(poError => {
					console.error("BLUE.S::Échec de connexion :", poError);
					return typeof poError === "string" ? throwError(new UnableToConnectError(poError)) : throwError(poError);
				})
			);
	}

	/** Retourne `true` si le mobile est connecté à un appareil. */
	public isConnected(): Observable<boolean> {
		const lsNotConnectedError = "Not connected.";

		return from(this.ioBluetooth.isConnected())
			.pipe(
				tap((poResult) => console.log("BLUE.S::Bluetooth connecté à un appareil: ", poResult)),
				mapTo(true),
				// isvcBluetooth.isConnected() retourne une erreur si on est pas connecté.
				catchError((poError: any) => poError === lsNotConnectedError ? of(false) : throwError(poError))
			);
	}

	/** Envoie un message à l'appareil bluetooth connecté.
	 * @param psCommand
	 */
	public write(psCommand: string): Observable<any> {
		return from(this.ioBluetooth.write(psCommand));
	}

	/** Lit un message de l'appareil connecté, depuis le buffer. */
	public read(): Observable<any> {
		return from(this.ioBluetooth.read());
	}

	/** Liste les appareils appairés. Ils ne sont pas forcément à portée et peuvent être éteinds. */
	public list(): Observable<Array<IBluetoothDevice>> {
		return from(this.ioBluetooth.list());
	}

	/** Met à jour l'appareil connecté et appelle le next du sujet. */
	private updateConnectedDevice(psConnectedAddress: string): Observable<IBluetoothDevice> {
		return this.list()
			.pipe(
				map((paDevices: IBluetoothDevice[]) => {
					const loCurrentDevice: IBluetoothDevice = paDevices.find((poDevice: IBluetoothDevice) => poDevice.address === psConnectedAddress);
					this.moConnectedDeviceSubject.next(loCurrentDevice);
					return loCurrentDevice;
				})
			);
	}

	/** Retourne un observable qui contient la valeur de l'appareil Bluetooth connecté. */
	public getConnectedDevice$(): Observable<IBluetoothDevice | null> {
		return this.moConnectedDeviceSubject.asObservable();
	}

	/** Retourne `true` si le bluetooth est activé sur le téléphone, sinon `false`. */
	public isEnabled(): Observable<boolean> {
		return from(this.ioBluetooth.isEnabled())
			.pipe(
				map((psResult: string) => {
					if (psResult === "OK")
						return true;
					else {
						console.error("BLUE.S::État 'isEnabled' inconnu : ", psResult);
						return false;
					}
				}),
				// bluetoothSerial.isEnabled tombe en erreur si le bluetooth est pas activé.
				catchError((poError: any) => poError === "Bluetooth is disabled." ? of(false) : throwError(poError))
			);
	}

	/** S'abonne aux données récupérées par l'appareil bluetooth connecté.
	 * @param {string} psDelimiter Le string que l'on veut observer.
	 */
	public subscribe(psDelimiter: string): Observable<any> {
		return this.ioBluetooth.subscribe(psDelimiter);
	}

	//#endregion

}