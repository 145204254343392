<ion-content>
	<ion-card>
		<ion-card-header class="ion-text-center">
			<ion-card-subtitle>Durée totale de lecture</ion-card-subtitle>
			<ion-card-title>{{ params.duration.total }} secondes</ion-card-title>
		</ion-card-header>
	</ion-card>

	<ion-card>
		<ion-card-header class="ion-text-center">
			<ion-card-subtitle>Durée moyenne par acquisition</ion-card-subtitle>
			<ion-card-title>{{ params.duration.average }} secondes</ion-card-title>
		</ion-card-header>
	</ion-card>
</ion-content>

<ion-fab vertical="bottom" horizontal="end" slot="fixed" class="fab-margin">
	<ion-fab-button color="success" (click)="validate()">
		<ion-icon name="checkmark"></ion-icon>
	</ion-fab-button>
</ion-fab>