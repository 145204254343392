import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CompletionBarComponent } from './completion-bar.component';

@NgModule({
	declarations: [CompletionBarComponent],
	imports: [
		CommonModule,
		IonicModule,
	],
	exports: [CompletionBarComponent],
})
export class CompletionBarModule { }
