import { Observable } from "rxjs";
import { tap } from "rxjs/operators";

/** Exécute une fonction suite à la fermeture/complétion du flux ou à la survenue d'une erreur dans le sens d'exécution du flux
 * (dans l'ordre des opérateurs du plus loin vers le plus proche du `subscribe`).
 * @param pfCallback Fonction à exécuter.
 */
export function tapComplete<T>(pfCallback: () => any): (poSource: Observable<T>) => Observable<T> {
	return (poSource: Observable<T>) =>
		poSource.pipe(tap(() => { }, _ => pfCallback(), () => pfCallback()));
}