import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DmsModule } from '@calaosoft/osapp/modules/dms/dms.module';
import { MetaMerchBuilderBase } from './models/MetaMerchBuilderBase';
import { MerchDmsMetaService } from './services/merch-dms-meta.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		DmsModule.forRoot([MetaMerchBuilderBase])
	],
	providers: [MetaMerchBuilderBase, MerchDmsMetaService]
})
export class MerchDmsModule { }