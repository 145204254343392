export class Usage {

	//#region PROPERTIES

	public get total() {
		return this.input + this.output;
	}

	//#endregion PROPERTIES

	//#region METHODS

	constructor(public input: number, public output: number) { }

	//#endregion METHODS

}
