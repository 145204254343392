import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ContactFieldType, Contacts, Contact as IonicContact } from '@ionic-native/contacts/ngx';
import { ModalController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { EMPTY, Observable, Subject, defer, from, merge, of, throwError } from 'rxjs';
import { catchError, filter, map, mapTo, mergeMap, switchMap, take, tap, toArray } from 'rxjs/operators';
import { DynamicPageComponent } from '../components/dynamicPage/dynamicPage.component';
import { ArrayHelper } from '../helpers/arrayHelper';
import { AvatarHelper } from '../helpers/avatarHelper';
import { ContactHelper } from '../helpers/contactHelper';
import { GuidHelper } from '../helpers/guidHelper';
import { IdHelper } from '../helpers/idHelper';
import { MapHelper } from '../helpers/mapHelper';
import { StoreDocumentHelper } from '../helpers/storeDocumentHelper';
import { StoreHelper } from '../helpers/storeHelper';
import { StringHelper } from '../helpers/stringHelper';
import { UserHelper } from '../helpers/user.helper';
import { EGender } from '../model/EGender';
import { EPrefix } from '../model/EPrefix';
import { PageInfo } from '../model/PageInfo';
import { IApplicationEvent } from '../model/application/IApplicationEvent';
import { UserData } from '../model/application/UserData';
import { ConfigData } from '../model/config/ConfigData';
import { IContact } from '../model/contacts/IContact';
import { IContactCacheData } from '../model/contacts/IContactCacheData';
import { IContactSelectorModalResponse } from '../model/contacts/IContactSelectorModalResponse';
import { IContactsSelectorParams } from '../model/contacts/IContactsSelectorParams';
import { IGetGroupMembersOptions } from '../model/contacts/IGetGroupMembersOptions';
import { IGroup } from '../model/contacts/IGroup';
import { IGroupMember } from '../model/contacts/IGroupMember';
import { IDesignDocument } from '../model/databaseDocument/IDesignDocument';
import { EDomainName } from '../model/domain/EDomainName';
import { NoCurrentUserDataError } from '../model/errors/NoCurrentUserDataError';
import { IFormParams } from '../model/forms/IFormParams';
import { ActivePageManager } from '../model/navigation/ActivePageManager';
import { EAvatarSize } from '../model/picture/EAvatarSize';
import { IAvatar } from '../model/picture/IAvatar';
import { PermissionMissingError } from '../model/security/errors/PermissionMissingError';
import { EDatabaseRole } from '../model/store/EDatabaseRole';
import { EStoreFlag } from '../model/store/EStoreFlag';
import { ICacheData } from '../model/store/ICacheData';
import { IDataSource } from '../model/store/IDataSource';
import { IStoreDataResponse } from '../model/store/IStoreDataResponse';
import { IStoreDocument } from '../model/store/IStoreDocument';
import { IUiResponse } from '../model/uiMessage/IUiResponse';
import { Contact } from '../modules/contacts/models/contact';
import { DmsFile } from '../modules/dms/model/DmsFile';
import { IDmsMeta } from '../modules/dms/model/IDmsMeta';
import { DmsService } from '../modules/dms/services/dms.service';
import { Entity } from '../modules/entities/models/entity';
import { IHydratedGroupMember } from '../modules/groups/model/IHydratedGroupMember';
import { ELogActionId } from '../modules/logger/models/ELogActionId';
import { LoggerService } from '../modules/logger/services/logger.service';
import { EPermission } from '../modules/permissions/models/EPermission';
import { PermissionsService } from '../modules/permissions/services/permissions.service';
import { ISector } from '../modules/sectors/models/isector';
import { IDataSourceRemoteChanges } from '../modules/store/model/IDataSourceRemoteChanges';
import { IContactPipe } from '../pipes/icontact.pipe';
import { ApplicationService } from './application.service';
import { DatabaseDocumentInitializerService } from './databaseDocumentInitializer.service';
import { EntityLinkService } from './entityLink.service';
import { ShowMessageParamsPopup } from './interfaces/ShowMessageParamsPopup';
import { ShowMessageParamsToast } from './interfaces/ShowMessageParamsToast';
import { Store } from './store.service';
import { UiMessageService } from './uiMessage.service';
import { WorkspaceService } from './workspace.service';

interface INeededPermissions {

	/** Indique qu'il faut la permission des utilisateurs. */
	users: boolean;
	/** Indique qu'il faut la permission des contacts. */
	contacts: boolean;
}

interface IContactLogAction {
	contactId: string;
	contactRev?: string;
}

@Injectable()
export class ContactsService {

	//#region FIELDS

	/** Vue par contact à créer. */
	private static readonly C_CONTACT_DESIGN_DOC: IDesignDocument = {
		_id: "_design/contacts",
		views: {
			contacts: {
				map: "function(doc) {\n if(doc._id.indexOf('cont_') == 0) emit(doc._id, doc); \n}"
			}
		},
		language: "javascript"
	};
	private static readonly C_LOG_ID = "CONT.S::";
	private static readonly C_CONTACT_CREATED_ACTION_ID = "calao-contact-create";
	private static readonly C_CONTACT_UPDATED_ACTION_ID = "calao-contact-updated";
	private static readonly C_USER_CONTACT_CSS_CLASS = "userContact";
	private static readonly C_REF_USER_CONTACT_CSS_CLASS = "referenceUserContact";
	private static readonly C_REPLIC_USER_CONTACT_CSS_CLASS = "replicaUserContact";
	private static readonly C_NO_PERMISSION_MESSAGE = `Vous n'avez pas l'autorisation`;

	/** Propriété de tri pour trier la liste des contacts dans un certain ordre ("lastName"). */
	public static readonly C_CONTACT_PROPERTY_SORT = "lastName";
	/** Identifiant de la définition de formulaire par défaut pour l'édition d'un contact. */
	public static readonly C_DEFAULT_CONTACTS_FORMDEF_ID = "contact_edit";
	/** Identifiant du descripteur de formulaire par défaut pour un contact. */
	public static readonly C_DEFAULT_CONTACTS_FORMDESC_ID = "formDesc_contacts";
	public static readonly C_CURRENT_USER_ID_FOR_ROUTE = "me";
	public static readonly C_NO_READ_PERMISSION_MESSAGE = `${ContactsService.C_NO_PERMISSION_MESSAGE} d'accéder aux contacts ou aux groupes.`;
	public static readonly C_NO_CREATE_PERMISSION_MESSAGE = `${ContactsService.C_NO_PERMISSION_MESSAGE} de créer un contact ou un groupe.`;
	public static readonly C_NO_DELETE_PERMISSION_MESSAGE = `${ContactsService.C_NO_PERMISSION_MESSAGE} de supprimer un contact ou un groupe.`;
	public static readonly C_NO_EDIT_PERMISSION_MESSAGE = `${ContactsService.C_NO_PERMISSION_MESSAGE} d'éditer un contact ou un groupe.`;

	private readonly moEventSubject: Subject<IApplicationEvent> = new Subject;

	//#endregion

	//#region PROPERTIES

	public get events$(): Observable<IApplicationEvent> { return this.moEventSubject.asObservable(); }

	//#endregion

	//#region METHODS

	constructor(
		/** Service pour les requêtes sur base de données. */
		private isvcStore: Store,
		/** Service de gestion des contacts. */
		private ioContacts: Contacts,
		/** Permet de transformer un contact ionic en IContact. */
		private ioIonicContactTransformPipe: IContactPipe,
		private isvcEntityLink: EntityLinkService,
		private ioRouter: Router,
		private isvcPermissions: PermissionsService,
		private isvcWorkspace: WorkspaceService,
		private ioModalCtrl: ModalController,
		private readonly isvcLogger: LoggerService,
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcDms: DmsService,
		psvcDatabaseDocInit: DatabaseDocumentInitializerService,
		psvcApplication: ApplicationService
	) {
		this.waitInitDatabasesToCreateDatabaseDocuments(psvcDatabaseDocInit, psvcApplication);
	}

	private static isGroupMember(poIdOrModel: string | IGroupMember, pePrefix: EPrefix): boolean {
		const lsId: string = typeof poIdOrModel === "string" ? poIdOrModel : poIdOrModel._id;
		return !StringHelper.isBlank(lsId) && lsId.indexOf(pePrefix) === 0;
	}

	/** Attend la fin de l'initialisation des bases de données pour demander la création des design documents.
	 * @param psvcDatabaseDocInit Service de création de documents spéciaux en base de données.
	 * @param psvcApplication Service d'application permettant de savoir quand les bases de données sont initialisées.
	 */
	private waitInitDatabasesToCreateDatabaseDocuments(psvcDatabaseDocInit: DatabaseDocumentInitializerService, psvcApplication: ApplicationService): void {
		psvcApplication.waitForFlag(EStoreFlag.DBInitialized, true)
			.pipe(
				tap(
					_ => {
						const laDatabaseIds = this.getContactsDatabaseIds(false);

						if (ArrayHelper.hasElements(laDatabaseIds))
							psvcDatabaseDocInit.addDocument({
								document: ContactsService.C_CONTACT_DESIGN_DOC,
								databaseIds: laDatabaseIds
							});
					},
					poError => console.error("CONT.S:: Erreur flag DBinitialized in contactsService : ", poError)
				)
			)
			.subscribe();
	}

	/** Crée un `IContact` depuis un contact ionic.
	 * @param poContact Contact d'un device à créer en `IContact`.
	 */
	private createIContactFromIonicContact(poContact: IonicContact): IContact {
		return this.ioIonicContactTransformPipe.transform(poContact);
	}

	//TODO Méthode à revoir (non appelée dans le code et provoque une erreur)
	/** Récupère tous les contacts (du store et du device) distinctement les uns des autres. */
	public getAllContacts(): Observable<Array<IContact>> {
		return merge(this.getContactsByPrefix(), this.getIContactsFromDeviceContacts())
			.pipe(
				tap((paContacts: Array<IContact>) => this.sortMembers(paContacts)),
				mergeMap((paContacts: Array<IContact>) => of(paContacts))
			);
	}

	/** Récupère un contact, `undefined` si non trouvé.
	 * @param psContactId Identifiant du contacts.
	 * @param psDatabaseId Identifiant de la base de données (optionnel).
	 * @param pbLive Indique si on doit récupérer de façon continu le contact ou non.
	 */
	public getContact<T extends Contact>(psContactId: string, psDatabaseId?: string, pbLive: boolean = false, poActivePageManager?: ActivePageManager)
		: Observable<T | undefined> {

		const loDataSource: IDataSourceRemoteChanges = {
			viewParams: {
				key: psContactId,
				include_docs: true
			},
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager,
			live: pbLive,
			baseClass: Entity
		};

		if (StringHelper.isBlank(psDatabaseId))
			loDataSource.databasesIds = this.getContactsDatabaseIds();
		else
			loDataSource.databaseId = psDatabaseId;

		return this.isvcStore.getOne<T>(loDataSource, false)
			.pipe(
				switchMap((poContact?: T) => {
					return this.isvcEntityLink.getLinkedEntities<IGroup>(poContact, EPrefix.group, pbLive, false, poActivePageManager)
						.pipe(
							map((paGroups: IGroup[]) => {
								if (poContact)
									poContact.groups = paGroups ?? [];

								return poContact;
							})
						);
				})
			);
	}

	/** Récupère la liste des contacts par prefix.
	 * @param poOptions Options de paramétrage pour récupérer les contacts.
	 */
	public getContactsByPrefix<T extends Contact>(poOptions?: IGetGroupMembersOptions & { includeDocs?: true | undefined }): Observable<T[]>;
	public getContactsByPrefix<T extends Contact>(poOptions: IGetGroupMembersOptions & { includeDocs: false }): Observable<string[]>;
	public getContactsByPrefix<T extends Contact>(poOptions?: IGetGroupMembersOptions & { includeDocs?: boolean }): Observable<T[] | string[]> {
		const loOptions: IGetGroupMembersOptions & { databaseIds?: string[] } = poOptions ? poOptions : {};
		const pbIncludeDocs: boolean = poOptions?.includeDocs ?? true;

		if (!loOptions.prefix) loOptions.prefix = EPrefix.contact;

		if (StringHelper.isBlank(loOptions.databaseId)) loOptions.databaseIds = this.getContactsDatabaseIds();

		const loDataSource: IDataSourceRemoteChanges = {
			databaseId: loOptions.databaseId,
			viewParams: {
				startkey: loOptions.prefix,
				endkey: loOptions.prefix + Store.C_ANYTHING_CODE_ASCII,
				include_docs: pbIncludeDocs,
				conflicts: loOptions.conflicts
			},
			live: loOptions.live,
			remoteChanges: !!loOptions.activePageManager,
			activePageManager: loOptions.activePageManager,
			baseClass: Entity
		};

		return this.getContacts<T>(loDataSource)
			.pipe(map((paContacts: T[]) => pbIncludeDocs ? paContacts : this.extractContactsIds(paContacts)));
	}

	/** Récupère la liste des contacts.
	 * @param poDataSource Source de données à utiliser pour récupérer les contacts.
	 * @param psDatabaseId Identifiant de la base de données dans laquelle rechercher les contacts.
	 */
	private getContacts<T extends IContact>(poDataSource: IDataSource): Observable<T[]> {
		if (StringHelper.isBlank(poDataSource.databaseId) && !ArrayHelper.hasElements(poDataSource.databasesIds))
			poDataSource.databasesIds = this.getContactsDatabaseIds();

		return defer(() => this.checkReadPermissionAsync())
			.pipe(
				mergeMap((pbResult: boolean) => {
					if (pbResult) {
						return this.isvcStore.get<T>(poDataSource)
							.pipe(
								tap((paContactResults: T[]) => this.sortMembers(paContactResults)),
								catchError(poError => { console.error("CONT.S:: getContacts from contactsDatabases fail :", poError); return throwError(poError); })
							);
					}
					else
						return throwError(new PermissionMissingError(ContactsService.C_NO_READ_PERMISSION_MESSAGE));
				})
			);
	}

	/** Récupère la liste des contacts du site courant.
	 * @param paAllSectors Liste de tous les secteurs.
	 * @param pePrefix Prefix des contacts.
	 */
	public getSiteContacts(
		paAllSectors: ISector[],
		pePrefix: EPrefix = EPrefix.contact,
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Contact[]> {
		if (!UserData.currentSite) {
			return of([]);
		}

		if (!UserData.currentSite.isDefaultSite) {
			return this.isvcEntityLink.getLinkedEntityIds(
				this.getSiteSectors(UserData.currentSite._id, paAllSectors).map((poSector: ISector) => poSector._id),
				[pePrefix],
				pbLive,
				poActivePageManager
			).pipe(
				map((paContactsIdsBySectorId: Map<string, string[]>) => ArrayHelper.unique(ArrayHelper.flat(MapHelper.valuesToArray(paContactsIdsBySectorId)))),
				switchMap((paContactsIds: string[]) => this.getContactsByIds(
					pePrefix === EPrefix.contact ? [UserHelper.getUserContactId(), ...paContactsIds] : paContactsIds,
					undefined,
					pbLive,
					poActivePageManager
				)),
			);
		}
		else {
			const laSiteSectorsIds: string[] = this.getSiteSectors(UserData.currentSite._id, paAllSectors).map((poSector: ISector) => poSector._id);
			const laSectorsIdsWithoutSiteSectorsIds: string[] = paAllSectors.map((poSector: ISector) => poSector._id).filter((psId: string) => !laSiteSectorsIds.includes(psId));

			return this.getContactsByPrefix({ prefix: pePrefix, live: pbLive, activePageManager: poActivePageManager })
				.pipe(
					switchMap((paContacts: IContact[]) => this.isvcEntityLink.getLinkedEntityIds(paContacts.map((poContact: IContact) => poContact._id), [EPrefix.group], true, poActivePageManager)
						.pipe(
							map((poGroupsIdsByContactId: Map<string, string[]>) => {
								const loContactById = new Map<string, Contact>();
								paContacts.forEach((poContact: Contact) => {
									loContactById.set(poContact._id, poContact);
									if (!poGroupsIdsByContactId.has(poContact._id))
										poGroupsIdsByContactId.set(poContact._id, []);
								});

								const laContacts: Contact[] = [];
								const lsUserContactId: string = UserHelper.getUserContactId();

								poGroupsIdsByContactId.forEach((paGroupsIds: string[], psContactId: string) => {
									if (psContactId === lsUserContactId || ArrayHelper.intersection(paGroupsIds, laSectorsIdsWithoutSiteSectorsIds).length === 0) {
										if (loContactById.has(psContactId))
											laContacts.push(loContactById.get(psContactId)!);
									}
								});

								return laContacts;
							})
						)
					)
				);
		};
	}

	/** Récupère la liste des ids des contacts du site courant.
	 * @param paAllSectors Liste de tous les secteurs.
	 * @param pePrefix Prefix des contacts.
	 */
	public getSiteContactsIds(paAllSectors: ISector[], pePrefix: EPrefix = EPrefix.contact): Observable<string[]> {
		if (!UserData.currentSite) {
			return of([]);
		}

		if (!UserData.currentSite.isDefaultSite) {
			return this.isvcEntityLink.getLinkedEntityIds(this.getSiteSectors(UserData.currentSite._id, paAllSectors).map((poSector: ISector) => poSector._id), [pePrefix])
				.pipe(map((paContactsIdsBySectorId: Map<string, string[]>) => ArrayHelper.unique(ArrayHelper.flat(MapHelper.valuesToArray(paContactsIdsBySectorId)))));
		}
		else {
			const laSiteSectorsIds: string[] = this.getSiteSectors(UserData.currentSite._id, paAllSectors).map((poSector: ISector) => poSector._id);
			const laSectorsIdsWithoutSiteSectorsIds: string[] = paAllSectors.map((poSector: ISector) => poSector._id).filter((psId: string) => !laSiteSectorsIds.includes(psId));

			return this.getContactsByPrefix({ prefix: pePrefix, includeDocs: false })
				.pipe(
					mergeMap((paContactsIds: string[]) => this.isvcEntityLink.getLinkedEntityIds(paContactsIds, [EPrefix.group])
						.pipe(
							map((poGroupsIdsByContactId: Map<string, string[]>) => {
								paContactsIds.forEach((psContactsIds: string) => {
									if (!poGroupsIdsByContactId.has(psContactsIds))
										poGroupsIdsByContactId.set(psContactsIds, []);
								});

								const laContactsIds: string[] = [];
								const lsUserContactId: string = UserHelper.getUserContactId();

								poGroupsIdsByContactId.forEach((paGroupsIds: string[], psContactId: string) => {
									if (psContactId === lsUserContactId || ArrayHelper.intersection(paGroupsIds, laSectorsIdsWithoutSiteSectorsIds).length === 0)
										laContactsIds.push(psContactId);
								});
								return laContactsIds;
							})
						)
					)
				);
		};
	}

	/** Retourne les secteurs appartenant au site
	 * @param psSiteId
	 * @param paAllSectors
	 */
	private getSiteSectors(psSiteId: string, paAllSectors: ISector[]): ISector[] {
		return paAllSectors?.filter((poSector: ISector) => poSector.siteId === psSiteId);
	}

	/** Récupère la liste des contacts par liste d'id.
	 * @param paContactIds Liste des identifiants des contacts à récupérer.
	 * @param psDatabaseId Identifiant de la base de données, optionnel.
	 */
	public getContactsByIds<T extends Contact>(
		paContactIds: string[],
		psDatabaseId?: string,
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<T[]> {
		const loDataSource: IDataSource | IDataSourceRemoteChanges = {
			viewParams: {
				keys: ArrayHelper.unique(ArrayHelper.getValidValues(paContactIds)),
				include_docs: true
			},
			databaseId: psDatabaseId,
			live: pbLive,
			activePageManager: poActivePageManager,
			remoteChanges: !!poActivePageManager,
			baseClass: Entity
		};

		return this.getContacts<T>(loDataSource);
	}

	/** Récupère la liste des contacts par liste d'id sous forme du d'une Map<id, contact>
	 * @param paContactIds Liste des identifiants des contacts à récupérer.
	 * @param psDatabaseId Identifiant de la base de données, optionnel.
	 */
	public getContactById<T extends Contact>(
		paContactIds: string[],
		psDatabaseId?: string,
		pbLive?: boolean,
		poActivePageManager?: ActivePageManager
	): Observable<Map<string, Contact>> {
		return this.getContactsByIds<T>(paContactIds, psDatabaseId, pbLive, poActivePageManager).pipe(
			map((paContacts: Contact[]) => ArrayHelper.groupByUnique(paContacts, (poContact: Contact) => poContact._id))
		);
	}

	/** Récupère le tableau des bases de données dont le rôle est "contact". */
	public getContactsDatabaseIds(pbFailIfNoResult: boolean = true): Array<string> {
		return this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.contacts, pbFailIfNoResult);
	}

	public getContactsIds(pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<string[]> {
		const loDataSource: IDataSource | IDataSourceRemoteChanges = {
			viewParams: {
				startkey: EPrefix.contact,
				endkey: EPrefix.contact + Store.C_ANYTHING_CODE_ASCII,
			},
			live: pbLive,
			activePageManager: poActivePageManager,
			remoteChanges: !!poActivePageManager
		};

		return this.getContacts(loDataSource).pipe(map((paContacts: IContact[]) => this.extractContactsIds(paContacts)));
	}

	/** Récupère les ids des contacts.
	 * @param paContacts
	 */
	public extractContactsIds(paContacts: IContact[]): Array<string> {
		return paContacts ? paContacts.map((poContact: IContact) => poContact._id) : [];
	}

	/** Permet d'ouvrir la page de sélection des contacts sous forme de modale (par défaut : userContactVisible = true, model = [], prefix = cont_).
	 * Un formulaire utilisé pour la création est défini par défaut.
	 * @param poParams Paramètres pour le composant contactSelector.
	 * @param psPageTitle Titre de la page de sélection de contacts.
	 */
	public openContactsSelectorAsModal<T extends IGroupMember = IGroupMember>(poParams: IContactsSelectorParams, psPageTitle: string = "Sélectionner des contacts")
		: Observable<T[]> {
		this.initParamsForContactsSelector(poParams);

		const loPageInfo: PageInfo = new PageInfo({
			componentName: "contact-selector",
			title: psPageTitle,
			params: { params: poParams },
			domainId: EDomainName.contacts,
			isWizard: true,
			isModal: true,
			cssId: poParams.cssId
		});

		return from(this.ioModalCtrl.create({ component: DynamicPageComponent, componentProps: { pageInfo: loPageInfo } }))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				mergeMap((poResult: OverlayEventDetail<IContactSelectorModalResponse<T>>) => poResult.data ? of(poResult.data.selected) : EMPTY)
			);
	}

	/** Retourne les paramètres pour un sélecteur de contact.
	 * @param peContactPrefix Préfixe à utiliser pour récupérer des contacts spécifiques.
	 * @param psSearchPlaceholder Placeholder de la barre de recherche du sélecteur de contacts.
	 * @param pbSelectAfterCreate
	 */
	public createContactSelectorParams(peContactPrefix: EPrefix, psSearchPlaceholder: string, pbSelectAfterCreate: boolean = false): IContactsSelectorParams {
		return {
			prefix: peContactPrefix,
			selectionLimit: 1,
			selectionMinimum: 1,
			hasSearchbox: true,
			userContactVisible: true,
			formParams: {
				formDefinitionId: ContactsService.C_DEFAULT_CONTACTS_FORMDEF_ID,
				formDescriptorId: ContactsService.C_DEFAULT_CONTACTS_FORMDESC_ID,
				customSubmit: (poContact: Contact) => this.saveContact(poContact)
			},
			searchOptions: {
				searchboxPlaceholder: psSearchPlaceholder
			},
			selectAfterCreate: pbSelectAfterCreate
		};
	}

	/** Permet d'ouvrir la page de sélection des contacts sous forme de modale (par défaut : userContactVisible = true, model = [], prefix = cont_)
	 * avec une préselection du workspace à utiliser.\
	 * Un formulaire utilisé pour la création est défini par défaut.
	 * @param poModel Modèle où seront enregistrés les contacts.
	 * @param poParams Paramètres pour le composant contactSelector.
	 * @param psPageTitle Titre de la page de sélection de contacts.
	 */
	public openContactsSelectorAsModalWithWorkspacePreSelection<T extends IGroupMember = IGroupMember>(poModel: IStoreDocument, poParams: IContactsSelectorParams,
		psPageTitle?: string): Observable<T[]> {

		const loContactsContainerCacheData: ICacheData = StoreHelper.getDocumentCacheData(poModel);

		// S'il n'y a pas de cacheData OU que l'identifiant de base de données des cacheData n'est pas renseigné,
		// il faut ouvrir le sélecteur de workspace avant d'ouvrir le sélecteur de contacts.
		if (!loContactsContainerCacheData ||
			(loContactsContainerCacheData && StringHelper.isBlank(loContactsContainerCacheData.databaseId))) {

			return this.isvcWorkspace.requestWorkspaceSelection(EDatabaseRole.contacts)
				.pipe(
					tap((psDatabaseId: string) => {
						poParams.databaseId = psDatabaseId;
						StoreHelper.updateDocumentCacheData(poModel, { databaseId: psDatabaseId });
					}),
					mergeMap(_ => this.openContactsSelectorAsModal<T>(poParams, psPageTitle)),
					tap((paSelectedMembers: T[]) => {
						// Si aucun membre n'a été sélectionné, on supprime le workspace sélectionné, l'utilisateur s'est peut-être trompé.
						if (!ArrayHelper.hasElements(paSelectedMembers)) {
							poParams.databaseId = undefined;
							StoreHelper.updateDocumentCacheData(poModel, { databaseId: undefined });
						}
					})
				);
		}
		else {
			// Si l'identifiant de base de données dans les cacheData est renseigné,
			// on ouvre directement le sélecteur de contacts, pas besoin du sélecteur de workspace.
			poParams.databaseId = loContactsContainerCacheData.databaseId;
			return this.openContactsSelectorAsModal(poParams, psPageTitle);
		}
	}

	/** Initialise les propriétés nécessaires au sélecteur de contacts à partir des paramètres reçus.
	 * @param poParams Paramètres pour le sélecteur de contacts.
	 */
	private initParamsForContactsSelector(poParams: IContactsSelectorParams): void {
		if (!poParams.prefix)
			poParams.prefix = EPrefix.contact;

		if (!poParams.preSelectedIds)
			poParams.preSelectedIds = [];

		if (poParams.userContactVisible !== false)
			poParams.userContactVisible = true;

		if (!poParams.formParams)
			poParams.formParams = {};

		// On vérifie directement que les deux soient renseignés car ils vont de pair.
		if (StringHelper.isBlank(poParams.formParams.formDefinitionId) && StringHelper.isBlank(poParams.formParams.formDescriptorId)) {
			poParams.formParams.formDescriptorId = ContactsService.C_DEFAULT_CONTACTS_FORMDESC_ID;
			poParams.formParams.formDefinitionId = ContactsService.C_DEFAULT_CONTACTS_FORMDEF_ID;
		}

		if (poParams.hideAllSelectionButton !== true)
			poParams.hideAllSelectionButton = false;
	}

	/** Récupère un contact d'un device sous la forme d'un `IContact` après l'avoir sélectionné. */
	public getIContactFromDeviceContactSelector(): Observable<IContact> {
		return from(this.ioContacts.pickContact()).pipe(map((poContact: IonicContact) => this.createIContactFromIonicContact(poContact)));
	}

	/** Récupère tous les contacts d'un device sous la forme d'un tableau de `IContact`. */
	private getIContactsFromDeviceContacts(): Observable<Array<IContact>> {
		const loContactFieldType: ContactFieldType = "*";

		return from(this.ioContacts.find([loContactFieldType]))
			.pipe(
				catchError(poError => { console.error("CONT.S:: Erreur getDeviceContacts :", poError); return throwError(poError); }),
				map((paDeviceContacts: Array<IonicContact>) => {
					const laContacts: Array<IContact> = paDeviceContacts.map((poDeviceContact: IonicContact) => this.createIContactFromIonicContact(poDeviceContact));
					this.sortMembers(laContacts);
					return laContacts;
				})
			);
	}

	public showDeleteContactPopup$(poContact: IContact): Observable<IUiResponse<boolean, any>> {
		return this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				header: `Voulez-vous supprimer définitivement le contact "${ContactHelper.getCompleteFormattedName(poContact)}" ? Cette action est irréversible.`,
				buttons: [
					{ text: "Annuler", handler: () => UiMessageService.getFalsyResponse() },
					{ text: "Oui, supprimer", cssClass: "validate-btn", handler: () => UiMessageService.getTruthyResponse() }
				],
				backdropDismiss: true
			})
		);
	}


	/** Permet de supprimer un contact et ses documents liés.
	 * @param poContact Contact à supprimer.
	 */
	public deleteContact(poContact: IContact): Observable<boolean> {
		return this.isvcEntityLink.ensureIsDeletableEntity(poContact)
			.pipe(
				filter((pbResult: boolean) => pbResult),
				mergeMap(_ => this.checkDeletePermissionAsync()),
				mergeMap((pbResult: boolean) => {
					if (pbResult)
						return this.isvcEntityLink.deleteEntityLinksById(poContact._id);
					else
						return throwError(new PermissionMissingError(ContactsService.C_NO_DELETE_PERMISSION_MESSAGE));
				}),
				mergeMap((pbResult: boolean) => pbResult ?
					this.isvcStore.delete(poContact).pipe(map((poResponse: IStoreDataResponse) => poResponse.ok)) : of(pbResult)
				),
				tap((pbContactDeleted: boolean) => {
					if (pbContactDeleted)
						this.isvcUiMessage.showToastMessage(new ShowMessageParamsToast({ message: `"${ContactHelper.getCompleteFormattedName(poContact)}" supprimé.`, color: "dark" }));
				})
			);
	}

	/** Retourne l'identifiant d'un contact à partir de son identifiant utilisateur.
	 * @param psUserId Identifiant d'un utilisateur dont on veut récupérer son identifiant de contact.
	 */
	public static getContactIdFromUserId(psUserId: string): string {
		return IdHelper.buildId(EPrefix.contact, UserHelper.getUserGuid(psUserId));
	}

	/** Retourne l'identifiant du contact de l'utilisateur courant.*/
	public static getUserContactId(): string {
		if (UserData.current)
			return IdHelper.buildId(EPrefix.contact, UserHelper.getUserGuid(UserData.current.name));
		else
			throw new NoCurrentUserDataError();
	}

	public getContactFromUserId(psUserId: string): Observable<IContact> {
		const lsUserContactId: string = ContactsService.getContactIdFromUserId(psUserId);
		const loDataSource: IDataSource = {
			databasesIds: this.getContactsDatabaseIds(),
			viewParams: {
				include_docs: true,
				key: lsUserContactId
			}
		};

		return this.isvcStore.get<IContact>(loDataSource)
			.pipe(map((paResults: IContact[]) => StoreDocumentHelper.getObjectWithMaxRevisionFromArray(paResults)));
	}

	/** Enregistre le contact dans une base de données spécifique, l'implémentation est spéciale dnas le cas de l'enregistrement du contact "moi".
	 * @param poContact Modèle du contact à enregistrer.
	 */
	public saveContact(poContact: Contact, poContactSource?: Contact, paPaths?: string[]): Observable<boolean> {
		let loDmsFile: DmsFile;
		let loDmsMeta: IDmsMeta;
		if (poContact.picture?.file && poContact.picture.alt) {
			loDmsFile = new DmsFile(poContact.picture.file, poContact.picture.alt);
			loDmsMeta = loDmsFile.createDmsMeta(
				poContact?.picture.guid ?? GuidHelper.newGuid(),
				undefined,
				undefined,
				paPaths
			);
		}

		return this.isvcStore.put(poContact).pipe(map((poResult: IStoreDataResponse) => poResult.ok))
			.pipe(
				mergeMap((pbResult: boolean) => loDmsFile ? this.isvcDms.save(loDmsFile, loDmsMeta).pipe(mapTo(poContact)) : of(pbResult)),
				mergeMap((pbResult: boolean) => {
					if (pbResult)
						return this.onContactSaved(poContact, pbResult, poContactSource);
					else {
						const lsErrorMessage: string = StringHelper.isBlank(poContact._rev) ?
							ContactsService.C_NO_CREATE_PERMISSION_MESSAGE : ContactsService.C_NO_EDIT_PERMISSION_MESSAGE;

						return throwError(new PermissionMissingError(lsErrorMessage));
					}
				})
			);
	}

	private onContactSaved(poContact: Contact, pbResult: boolean, poContactSource?: Contact): Observable<boolean> {
		if (poContactSource) {
			const laAddedGroups: IGroup[] = ArrayHelper.getDifferences(poContact.groups, poContactSource.groups);
			this.isvcEntityLink.cacheLinkToAdd(poContact, laAddedGroups);
			const laRemovedGroups: IGroup[] = ArrayHelper.getDifferences(poContactSource.groups, poContact.groups);
			this.isvcEntityLink.cacheLinkToRemove(poContact, laRemovedGroups);
		}

		const lbNewContact: boolean = StoreDocumentHelper.isNew(poContact);
		const lsLogActionId = lbNewContact ? ContactsService.C_CONTACT_CREATED_ACTION_ID : ContactsService.C_CONTACT_UPDATED_ACTION_ID;
		const loData: IContactLogAction = { contactId: poContact._id };
		if (!lbNewContact)
			loData.contactRev = poContact._rev;

		this.isvcLogger.action(ContactsService.C_LOG_ID, this.getLogActionMessage(poContact, lbNewContact, pbResult), lsLogActionId as ELogActionId, loData);

		return ConfigData.appInfo.useLinks ? this.isvcEntityLink.saveEntityLinks(poContact) : of(pbResult);
	}

	/** Retourne la chaine à enregistrer dans le document de log.
	 * @param poContact Le contact créé ou mis à jour.
	 * @param pbIsNewContact `true` pour une nouveau contact, sinon `false`.
	 * @param pbResult `true` si la sauvegarde a réussi, sinon `false`.
	 */
	private getLogActionMessage(poContact: IContact, pbIsNewContact: boolean, pbResult: boolean): string {
		const lsStatus: string = !pbResult ? "NOT saved" :
			pbIsNewContact ? "created" : "updated";
		const lsContactFirstName = poContact.firstName ? `${poContact.firstName}` : "";

		return `Contact ${lsContactFirstName} ${poContact.lastName} ${lsStatus}`;
	}

	/** Permet de créer la fiche contact lié au workspace à enregistrer dans l'appStorage.
	 * @param poContact Le contact.
	 */
	public createCacheContactFromContact(poContact: IContact): IContact {
		const loCacheContact: IContact = JSON.parse(JSON.stringify(poContact));
		loCacheContact._id = IdHelper.buildChildId(EPrefix.contact, ArrayHelper.getFirstElement(UserData.current.workspaceInfos).id, UserHelper.getUserGuid(UserData.current.name));
		delete loCacheContact._rev;
		StoreHelper.deleteDocumentCacheData(loCacheContact);
		return loCacheContact;
	}

	/** Donne une classe css en fonction du contact passé en paramètre.
	 * @param poContact Contact pour lequel nous devons donner une classe css.
	 * @param pbDisplayUserContact Permet de savoir si l'on doit traiter le contact utilisateur comme un réplicat.
	 */
	public getContactCssClass(poContact: IContact, pbDisplayUserContact: boolean = true): string {
		let lsCssClass = "";

		if (UserHelper.isUser(poContact) && UserHelper.isCurrentUserContact(poContact)) {
			const lsAppStorageDatabaseId: string = ArrayHelper.getFirstElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.applicationStorage));
			lsCssClass = `${ContactsService.C_USER_CONTACT_CSS_CLASS} `;

			if (StoreHelper.getDatabaseIdFromCacheData(poContact) === lsAppStorageDatabaseId && pbDisplayUserContact)
				lsCssClass += ContactsService.C_REF_USER_CONTACT_CSS_CLASS;
			else
				lsCssClass += ContactsService.C_REPLIC_USER_CONTACT_CSS_CLASS;
		}

		return lsCssClass;
	}

	public routeToContact(contact: IContact): void {
		this.ioRouter.navigate(["contacts", contact._id]);
	}

	/** Ouvre la modale de création d'un contact.
	 * @param poParams Paramètres du formulaire de création de contact.
	 */
	public openCreateContactModal(poParams: IFormParams): Observable<IContact> {
		poParams.visuAfterCreate = poParams.visuAfterCreate ?? false;
		if (poParams.model)
			poParams.model = { ...poParams.model };

		const loPageInfo = new PageInfo({
			componentName: "form",
			params: poParams,
			isModal: true,
			isClosable: true,
			cssId: "contact-form"
		});

		return from(this.ioModalCtrl.create({ component: DynamicPageComponent, componentProps: { pageInfo: loPageInfo } }))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				filter((poResult: OverlayEventDetail<IContact>) => !!poResult.data),
				map((poResult: OverlayEventDetail<IContact>) => poResult.data!)
			);
	}

	/** Marque les liens de contacts à mettre à jour dans un modèle.
	 * @param poModel Modèle dont il faut mettre à jour les liens.
	 * @param paOldContacts Liste des contacts préalables.
	 * @param paNewContacts Liste des contacts sélectionnés.
	 */
	public updateContactsLinks(poModel: IStoreDocument, paOldContacts: Array<IContact>, paNewContacts: Array<IContact>): void {
		if (!paOldContacts)
			paOldContacts = [];

		if (!paNewContacts)
			paNewContacts = [];

		// Si un contact n'est pas dans l'ancienne liste mais dans la nouvelle alors il a été ajouté.
		const laAddLinks: Array<IContact> =
			paNewContacts.filter((poNew: IContact) => paOldContacts.findIndex((poOld: IContact) => poOld._id === poNew._id) === -1);
		laAddLinks.forEach((poContact: IContact) => this.isvcEntityLink.cacheLinkToAdd(poModel, poContact));

		// Si un contact n'est pas dans la nouvelle liste mais dans la vieille alors il a été supprimé.
		const laRemoveLinks: Array<IContact> =
			paOldContacts.filter((poOld: IContact) => paNewContacts.findIndex((poNew: IContact) => poOld._id === poNew._id) === -1);
		laRemoveLinks.forEach((poContact: IContact) => this.isvcEntityLink.cacheLinkToRemove(poModel, poContact));
	}

	/** Crée un contact vierge en remplissant son identifiant et prénom et nom si renseignés.
	 * @param pePrefix Préfixe permettant la construction du contact vierge, `EPrefix.contact` par défaut.
	 */
	public static createBlankContact(pePrefix: EPrefix = EPrefix.contact, psFirstName: string = "", psLastName: string = ""): IContact {
		return {
			_id: IdHelper.buildId(pePrefix),
			firstName: ContactHelper.getFormattedFirstName(psFirstName),
			lastName: ContactHelper.getFormattedLastName(psLastName)
		};
	}

	/** Tri un tableau de membres en fonction d'une propriété.
	 * @param paMembers Tableau des membres (contacts, groupes) à trier.
	 * @param psSortPropoerty Nom de la propriété sur laquelle trier les contacts, `lastName` par défaut.
	 */
	public sortMembers<T extends IGroupMember = IGroupMember>(paMembers: T[], psSortPropoerty: keyof T = ContactsService.C_CONTACT_PROPERTY_SORT as any): void {
		ArrayHelper.dynamicSort(paMembers, psSortPropoerty);
	}

	/** Crée l'avatar d'un contact.
	 * @param poContact
	 * @param peAvatarSize
	 * @param useIconAsDefaultFallback Indique si l'icône doit être la solution de remplacement si l'image n'est pas disponible.
	 */
	public static createContactAvatar(poContact: IContact = { _id: "" }, peAvatarSize: EAvatarSize = EAvatarSize.big, useIconAsDefaultFallback?: boolean): IAvatar {
		return AvatarHelper.createAvatarFromContact(peAvatarSize, poContact, useIconAsDefaultFallback);
	}

	public static hydrateContact(poContact: IContact): IHydratedGroupMember<IContact> {
		return {
			groupMember: poContact,
			avatar: poContact ? ContactsService.createContactAvatar(poContact) : undefined
		};
	}

	/** Retourne `true` si l'identifiant est celui d'un contact, `false` sinon.
	 * @param psId Identifiant à analyser.
	 */
	public static isContact(psId: string): boolean;
	/** Retourne `true` si le modèle est celui d'un contact, `false` sinon.
	 * @param poModel Modèle à analyser.
	 */
	public static isContact(poModel: IGroupMember): boolean;
	public static isContact(poIdOrModel: string | IGroupMember): boolean {
		return poIdOrModel ? this.isGroupMember(poIdOrModel, EPrefix.contact) : false;
	}

	/** Indique si l'utilisateur a les droits de création de contact. */
	public checkCreatePermissionAsync(): Promise<boolean> {
		return this.isvcPermissions.waitPermissionsAsync()
			.then(() => this.isvcPermissions.evaluatePermission(EPermission.contacts, "create"));
	}

	/** Indique si l'utilisateur a les droits d'édition de contact.
	 * @param poData Contact ou tableau de contacts dont il faut vérifier si on a la permission requise pour le(s) modifier.
	 */
	public async checkEditPermissionAsync<T extends IContact>(poData: T | T[]): Promise<boolean> {
		let loData: T;

		await this.isvcPermissions.waitPermissionsAsync();

		if (!(poData instanceof Array))
			loData = poData;
		else if (poData instanceof Array && poData.length === 1)
			loData = ArrayHelper.getFirstElement(poData);

		if (loData?._id === ContactsService.getUserContactId()) // On autorise l'édition si c'est le contact utilisateur.
			return true;

		// Une fois l'exception du contact utilisateur gérée on peut vérifier la permission.

		const loPermissions: INeededPermissions = this.getPermissionsNeeded(poData);
		const laCheckPermissions: boolean[] = [];

		if (loPermissions.users)
			laCheckPermissions.push(this.isvcPermissions.evaluatePermission(EPermission.users, "edit"));
		if (loPermissions.contacts)
			laCheckPermissions.push(this.isvcPermissions.evaluatePermission(EPermission.contacts, "edit"));

		return laCheckPermissions.every((pbHasPermission: boolean) => pbHasPermission);
	}

	/** Indique si l'utilisateur a les droits de suppression de contact. */
	private checkDeletePermissionAsync(): Promise<boolean> {
		return this.isvcPermissions.waitPermissionsAsync()
			.then(() => this.isvcPermissions.evaluatePermission(EPermission.contacts, "delete"));
	}

	/** Indique si l'utilisateur a les droits de lecture de contact. */
	public checkReadPermissionAsync(): Promise<boolean> {
		return this.isvcPermissions.waitPermissionsAsync()
			// Si au moins une permission est acquise, on peut lire les contacts.
			.then(() => this.isvcPermissions.evaluatePermission(EPermission.users, "read") || this.isvcPermissions.evaluatePermission(EPermission.contacts, "read"));
	}

	/** Récupère un objet indiquant quelles permissions sont requises pour traiter des contacts.
	 * @param poData Contact ou tableau de contacts dont il faut vérifier si on a la permission requise pour le(s) traiter.
	 */
	private getPermissionsNeeded<T extends IContact>(poData: T | T[]): INeededPermissions {
		const laContacts: T[] = poData instanceof Array ? poData : [poData];
		const loPermission: INeededPermissions = { users: false, contacts: false };

		for (let lnIndex = 0; lnIndex < laContacts.length; ++lnIndex) {
			// Si on n'a pas encore modifié la nécessité de permission des utilisateurs et que l'item est un contact utilisateur, il faut la permission des utilisateurs.
			if (!loPermission.users && UserHelper.isUser(laContacts[lnIndex]))
				loPermission.users = true;
			else if (!loPermission.contacts) // Sinon, c'est un contact lambda, il faut la permission des contacts (si elle n'a pas déjà été prise en compte).
				loPermission.contacts = true;

			if (loPermission.users && loPermission.contacts) // Si toutes les permissions sont nécessaires, on sort de la boucle (inutile de continuer).
				break;
		}

		return loPermission;
	}

	/** Récupère des contacts à partir de leur chemin (en base de données).
	 * @param paPaths Tableau des chemins des contacts à récupérer.
	 */
	public getContactsByPaths<T extends IContact>(paPaths: string[]): Observable<T[]> {
		if (!ArrayHelper.hasElements(paPaths))
			return of([]);

		else {
			const loPathsByDatabaseId: Map<string, string[]> = ArrayHelper.groupBy(paPaths, (psPath: string) => Store.getDatabaseIdFromDocumentPath(psPath));
			return from(MapHelper.keysToArray(loPathsByDatabaseId))
				.pipe(
					mergeMap((psDatabaseId: string) => {
						const lsDatabaseId: string = this.isvcStore.isInitializedDatabase(psDatabaseId) ? psDatabaseId : undefined;

						return this.getContacts<T>({
							role: StringHelper.isBlank(lsDatabaseId) ? EDatabaseRole.contacts : undefined,
							databaseId: lsDatabaseId,
							viewParams: {
								keys: ArrayHelper.unique(loPathsByDatabaseId.get(psDatabaseId).map((psPath: string) => Store.getDocumentIdFromPath(psPath))),
								include_docs: true
							}
						} as IDataSource);
					}),
					toArray(),
					map((paResults: T[][]) => ArrayHelper.flat(paResults))
				);
		}
	}

	/** Récupère un contact à partir de son chemin (en base de données), `undefined` si non trouvé.
	 * @param psPath Chemin vers le contact.
	 */
	private getContactByPathAsync<T extends IContact>(psPath: string): Promise<T | undefined> {
		if (StringHelper.isBlank(psPath))
			return Promise.resolve(undefined);
		else {
			return this.getContact<T>(
				Store.getDocumentIdFromPath(psPath),
				Store.getDatabaseIdFromDocumentPath(psPath)
			)
				.pipe(take(1))
				.toPromise();
		}
	}

	/** Récupère le chemin vers le contact utilisateur qui se trouve dans le même workspace que le contact passé en paramètre.
	 * @param poModel Modèle qui sert à récupérer le workspace sur lequel se baser pour récupérer le chemin de l'utilisateur courant..
	 */
	public static getCurrentWorkspaceUserPath<T extends IContact>(poModel: T): string {
		return `${StoreHelper.getDatabaseIdFromCacheData(poModel)}/${ContactsService.getUserContactId()}`;
	}

	/** Récupère l'avatar d'un contact à partir du chemin (en base de données) du contact.
	 * @param psContactPath Chemin vers le contact.
	 * @param peSize Taille de l'avatar, `big` par défaut.
	 */
	public getContactAvatarAsync<T extends IContact>(psContactPath: string, peSize: EAvatarSize = EAvatarSize.big): Promise<IAvatar> {
		return this.getContactByPathAsync<T>(psContactPath)
			.then((poContact?: T) => AvatarHelper.createAvatarFromContact(peSize, poContact));
	}

	/** Récupère les avatars des contacts à partir de leur chemin (en base de données) indexés par ledit chemin.
	 * @param paContactPaths Tableau des chemins vers les contacts.
	 * @param peSize Taille des avatars, `big` par défaut.
	 */
	public getAvatarsByContactPaths<T extends IContact>(paContactPaths: string[], peSize: EAvatarSize = EAvatarSize.big): Observable<Map<string, IAvatar>> {
		return this.getContactsByPaths(paContactPaths)
			.pipe(
				map((paContacts: T[]) => {
					const loPathByContactId: Map<string, string> = ArrayHelper.groupByUnique(paContactPaths, (psPath: string) => Store.getDocumentIdFromPath(psPath));
					const loAvatarByContactPathMap = new Map<string, IAvatar>();

					paContacts.forEach((poContact: T) => {
						if (loPathByContactId.has(poContact._id))
							loAvatarByContactPathMap.set(loPathByContactId.get(poContact._id)!, AvatarHelper.createAvatarFromContact(peSize, poContact));
					});

					return loAvatarByContactPathMap;
				})
			);
	}

	/** Sauveagarde en cache les informations sur l'adresse du Contact.
	 * @param poContact
	 */
	public saveAdressCacheData(poContact: IContact): void {
		StoreHelper.updateDocumentCacheData(
			poContact,
			{ addressData: { street: poContact.street, zipCode: poContact.zipCode, city: poContact.city, latitude: poContact.latitude, longitude: poContact.longitude } } as IContactCacheData
		);
	}

	/** Si on detecte un changement dans l'adresse on supprime les données GPS pour ne plus se baser dessus car elles son prioritaire.
	 * @param poContact
	 * @param poContactCacheData
	 */
	public deleteGPSDataIfNeeded<T extends IContact = IContact>(poContact: T, poContactCacheData?: IContactCacheData): boolean {
		poContactCacheData = poContactCacheData ? poContactCacheData : StoreHelper.getDocumentCacheData(poContact);

		if ((ContactsService.isAddressDifferent(poContact, poContactCacheData) && !this.isGPSDataDifferent(poContact, poContactCacheData))) {
			delete poContact.latitude;
			delete poContact.longitude;
			return true;
		};
		return false;
	}

	/** Si le contact est un homme on supprime le nom de jeune fille.
	 * @param poContact
	 */
	public deleteMaidenNameIfNeeded<T extends IContact = IContact>(poContact: T): boolean {
		if (poContact.gender === EGender.Man) {
			delete poContact.maidenName;
			return true;
		};
		return false;
	}

	/** Retourne `true` si les adresses sont différentes.
	 * @param poContact
	 * @param poContactCacheData
	 */
	public static isAddressDifferent<T extends IContact = IContact>(poContact: T, poContactCacheData: IContactCacheData): boolean {
		if (poContact.street !== poContactCacheData.addressData?.street ||
			poContact.zipCode !== poContactCacheData.addressData?.zipCode ||
			poContact.city !== poContactCacheData.addressData?.city) {
			return true;
		};
		return false;
	}

	/** Retourne `true` si les coordonnées GPS sont différentes.
	 * @param poContact
	 * @param poContactCacheData
	 */
	public isGPSDataDifferent<T extends IContact = IContact>(poContact: T, poContactCacheData: IContactCacheData): boolean {
		if (poContact.latitude !== poContactCacheData.addressData?.latitude ||
			poContact.longitude !== poContactCacheData.addressData?.longitude) {
			return true;
		};
		return false;
	}

	public showImportationContactErrorPopup$(): Observable<IUiResponse<boolean, any>> {
		return this.isvcUiMessage.showAsyncMessage<boolean>(
			new ShowMessageParamsPopup({
				header: "Erreur",
				message: `Impossible d'importer un contact depuis votre carnet d'adresses. Vérifiez les permissions de l'application.`,
				buttons: [
					{ text: "OK", handler: () => UiMessageService.getFalsyResponse() }
				],
				backdropDismiss: true
			})
		);
	}

	//#endregion
}