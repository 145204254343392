<div *ngIf="showDisplayModeSelector" class="selector">
	<osapp-selector [displayMode]="selectorDisplayMode.tags" [options]="displayModeOptions"
		[preselectedValues]="displayMode" [scrollWrapper]="false" (selectionChanged)="onDisplayModeChange($event)">
	</osapp-selector>
</div>

<ng-container [ngSwitch]="displayMode">
	<ng-container *ngSwitchCase="'thumbnails'">
		<ng-container *ngTemplateOutlet="thumbnailsMode"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'list'">
		<ng-container *ngTemplateOutlet="listMode"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'add'">
		<ng-container *ngTemplateOutlet="addMode"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="'preview'">
		<ng-container *ngTemplateOutlet="previewMode"></ng-container>
	</ng-container>
</ng-container>

<!-- MODE MINIATURES -->
<ng-template #thumbnailsMode>
	<!-- GALLERY NOT READ ONLY SECTION -->
	<div *ngIf="!readOnly" class="ctn-liste-gallery ctn-liste-gallery-preview">

		<div class="thumbnails">
			<div *ngFor="let item of files.changes$ | async; let i = index"
				[class]="'item' + (!(item.observableIsAvailable.value$ | async)? ' stable-bg': '')">
				<ng-container *ngIf="!excludeFileGuids?.includes(item.guid)">
					<div class="preview">

						<!-- Affiche l'image si présente sinon une icône. -->
						<ng-container
							[ngSwitch]="!!item.file && !!item.file.MimeType && item.file.MimeType.indexOf('image') !== -1">
							<div *ngSwitchCase="true" (click)="openFileAt(i)" class="ctn-apercu">
								<!-- Mets l'opacité de l'image à 80% si le fichier est en attente d'upload. -->
								<osapp-image [ngClass]="(item.observableIsUploaded.value$ | async) ? '' : 'not-uploaded'"
									[src]="item.file.File" [alt]="item.name">
								</osapp-image>
							</div>
							<div *ngSwitchCase="false" (click)="openFileAt(i)" class="ctn-apercu">
								<ion-icon [name]="isImage(item) ? 'image' : 'document'" class="file-icon"></ion-icon>
							</div>
						</ng-container>
						<div *ngIf="observablePermissionsByGuid.value$ | async as permissionsByGuid" class="ctn-ico">
							<ng-container *ngIf="!!customButtonsTemplate">
								<div class="ctn-ico-suppr">
									<ng-container *ngTemplateOutlet="customButtonsTemplate; context: { item: item }"></ng-container>
								</div>
							</ng-container>
							<div *ngIf="permissionsByGuid.get(item.guid)?.get('trash')" class="ctn-ico-suppr">
								<ion-icon (click)="deleteItem(i, true)" class="icone ico-suppr" color="success" name="trash"></ion-icon>
							</div>
							<div *ngIf="permissionsByGuid.get(item.guid)?.get('delete')" class="ctn-ico-suppr">
								<ion-icon (click)="deleteItem(i)" class="icone ico-suppr" color="danger" name="trash"></ion-icon>
							</div>
							<div *ngIf="allowCustomDescription" class="ctn-ico-suppr">
								<ion-icon (click)="editDescription(item)" class="icone ico-suppr" color="primary" name="create">
								</ion-icon>
							</div>
						</div>
					</div>

					<!-- Ajoute les détails du fichier. -->
					<div *ngIf="!hideFileDetails" class="ctn-details-fichier">
						<!-- Affiche la progress bar. -->
						<ng-container *ngIf="networkAvailable && (item.observableIsLoading.value$ | async)">
							<ion-progress-bar [value]="item.observableProgress.value$ | async"></ion-progress-bar>
						</ng-container>
						<div>
							<!-- Ajoute la description du fichier. -->
							<div *ngIf="item.description as description" class="file-description">
								{{ description }}
							</div>
							<div [ngClass]="item.description ? 'small-details' : ''" class="details">
								<ng-container
									*ngIf="!(item.observableIsAvailable.value$ | async) && !(item.observableIsNew.value$ | async)">
									<!-- Affiche l'icône si le fichier est en attente d'upload. -->
									<ng-container
										*ngIf="(item.observableIsUploaded.value$ | async) === false && !(item.observableIsLoading.value$ | async);else downloadIcon">
										<ion-icon class="icone ico-doc txt24px" name="reload"></ion-icon>
									</ng-container>
								</ng-container>

								<ng-template #downloadIcon>
									<!-- Affiche une icône si le document est en attente de téléchargement. -->
									<ng-container *ngIf="!(networkAvailable && (item.observableIsLoading.value$ | async))">
										<ion-icon class="icone ico-doc txt24px" name="download"></ion-icon>
									</ng-container>
								</ng-template>

								<!-- Affiche le nom du fichier. -->
								<div class="sub-text">
									<div *ngIf="getItemNameWithoutExtension(item.name) as nameWithoutExtension" class="file-name">
										{{ nameWithoutExtension }}
									</div>
									<div *ngIf="getItemExtensionFromName(item.name) as extension">
										{{ extension }}
									</div>
								</div>

							</div>
						</div>

					</div>
				</ng-container>
			</div>
			<ion-item class="min-width" lines="none" *ngIf="isLoading">
				<ion-spinner name="crescent"></ion-spinner>
			</ion-item>
		</div>
		<div [ngClass]="!filePickerVisible && !cameraButtonVisible ? 'collapsed' : ''" class="picpos">
			<span *ngIf="cameraButtonVisible" style="background-color: transparent">
				<ion-button [disabled]="reachedLimit" (click)="takePicture()" class="licamera-button">
					<ion-icon [slot]="cameraButtonText ? 'start' : 'icon-only'" name="camera"></ion-icon>
					<ion-label *ngIf="cameraButtonText">
						{{ cameraButtonText }}
					</ion-label>
				</ion-button>
			</span>
			<span>
				<file-picker #imagePicker [disabled]="reachedLimit" [ngClass]="imagePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModelAsync($event)" [options]="cameraOptions" [multiple]="multiple"
					[maxSizeKb]="maxSizeKb" [accept]="acceptedImages" [label]="filePickerImagesButtonText"
					[iconName]="imagePickerIcon" (isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
			<span>
				<file-picker #filePicker [disabled]="reachedLimit" [ngClass]="filePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModelAsync($event)" [options]="cameraOptions" [multiple]="multiple"
					[maxSizeKb]="maxSizeKb" [accept]="acceptedFiles" [label]="filePickerFilesButtonText"
					[iconName]="filePickerIcon" (isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
		</div>
	</div>

	<!-- GALLERY READ ONLY SECTION -->
	<ng-container *ngIf="readOnly">
		<div *ngIf="(files?.length$ | async) > 0" class="ctn-liste-gallery thumbnails">
			<div *ngFor="let item of files.changes$ | async; let i = index"
				[class]="'item' + (!(item.observableIsAvailable.value$ | async)? ' stable-bg': '')">
				<ng-container *ngIf="!excludeFileGuids?.includes(item.guid)">

					<!-- Affiche l'image si présente sinon une icône. -->
					<ng-container [ngSwitch]="!!item.file && !!item.file.MimeType && item.file.MimeType.indexOf('image') !== -1">
						<div *ngSwitchCase="true" (click)="openFileAt(i)" class="ctn-apercu">
							<!-- Mets l'opacité de l'image à 80% si le fichier est en attente d'upload. -->
							<osapp-image [ngClass]="(item.observableIsUploaded.value$ | async) ? '' : 'not-uploaded'"
								[src]="item.file.File" [alt]="item.name">
							</osapp-image>
						</div>
						<div *ngSwitchCase="false" (click)="openFileAt(i)" class="ctn-apercu">
							<ion-icon [name]="isImage(item) ? 'image' : 'document'" class="file-icon"></ion-icon>
						</div>
					</ng-container>

					<!-- Ajoute les détails du fichier. -->
					<div *ngIf="!hideFileDetails" (click)="downloadOrUpload(item)" class="ctn-details-fichier">
						<!-- Affiche la progress bar. -->
						<ng-container *ngIf="networkAvailable && (item.observableIsLoading.value$ | async)">
							<ion-progress-bar [value]="item.observableProgress.value$ | async"></ion-progress-bar>
						</ng-container>
						<div>
							<!-- Ajoute la description du fichier. -->
							<div *ngIf="item.description as description" class="file-description">
								{{ description }}
							</div>
							<div [ngClass]="(item.description) ? 'small-details' : ''" class="details">
								<ng-container
									*ngIf="!(item.observableIsAvailable.value$ | async) && !(item.observableIsNew.value$ | async)">
									<!-- Affiche l'icône si le fichier est en attente d'upload. -->
									<ng-container
										*ngIf="(item.observableIsUploaded.value$ | async) === false && !(item.observableIsLoading.value$ | async);else downloadIcon">
										<ion-icon class="icone ico-doc txt24px" name="reload"></ion-icon>
									</ng-container>
								</ng-container>

								<ng-template #downloadIcon>
									<!-- Affiche une icône si le document est en attente de téléchargement. -->
									<ng-container *ngIf="!(networkAvailable && (item.observableIsLoading.value$ | async))">
										<ion-icon class="icone ico-doc txt24px" name="download"></ion-icon>
									</ng-container>
								</ng-template>

								<!-- Affiche le nom du fichier. -->
								<div class="sub-text">
									<div *ngIf="getItemNameWithoutExtension(item.name) as nameWithoutExtension" class="file-name">
										{{ nameWithoutExtension }}
									</div>
									<div *ngIf="getItemExtensionFromName(item.name) as extension">
										{{ extension }}
									</div>
								</div>

							</div>
						</div>

					</div>
				</ng-container>
			</div>
		</div>

		<div *ngIf="!hideNoFileText && (!files || files.length === 0)" class="empty-gallery">
			<p>Aucun fichier.</p>
		</div>
	</ng-container>
</ng-template>

<!-- MODE LISTE -->
<ng-template #listMode>
	<div class="list-item-ctn" *ngFor="let item of files; let i = index">
		<ng-container *ngIf="!excludeFileGuids?.includes(item.guid)">
			<div class="list-item" (click)="openFileAt(i)">
				<div class="list-item-icon">
					<ion-icon slot="icon-only" name="see-document"></ion-icon>
				</div>
				<div class="v-center">
					<div [ngClass]="!item.description ? 'only-name-ctn' : ''" class="list-item-detail">
						<div class="list-item-description" *ngIf="item.description as description" class="list-item-description">
							{{ description }}
						</div>
						<div [ngClass]="!item.description ? 'only-name' : ''" class="list-item-file-name">
							{{ item.name }}
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!readOnly && observablePermissionsByGuid.value$ | async as permissionsByGuid"
				class="list-item-options">
				<ng-container *ngIf="!!customButtonsTemplate">
					<ng-container *ngTemplateOutlet="customButtonsTemplate; context: { item: item }"></ng-container>
				</ng-container>
				<ion-icon class="option-icon" *ngIf="allowCustomDescription" (click)="editDescription(item)" color="primary"
					name="create">
				</ion-icon>
				<ion-icon *ngIf="permissionsByGuid.get(item.guid)?.get('trash')" class="option-icon"
					(click)="deleteItem(i, true)" color="primary" name="trash"></ion-icon>
				<ion-icon *ngIf="permissionsByGuid.get(item.guid)?.get('delete')" class="option-icon" (click)="deleteItem(i)"
					color="danger" name="trash-bin"></ion-icon>
			</div>
		</ng-container>
	</div>

	<div [ngClass]="!filePickerVisible && !cameraButtonVisible ? 'collapsed' : ''" class="picpos">
		<ng-container *ngIf="!readOnly">
			<span *ngIf="cameraButtonVisible" style="background-color: transparent">
				<ion-button [disabled]="reachedLimit" (click)="takePicture()" class="licamera-button">
					<ion-icon [slot]="cameraButtonText ? 'start' : 'icon-only'" name="camera"></ion-icon>
					<ion-label *ngIf="cameraButtonText">
						{{ cameraButtonText }}
					</ion-label>
				</ion-button>
			</span>
			<span>
				<file-picker #imagePicker [disabled]="reachedLimit" [ngClass]="imagePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModelAsync($event)" [options]="cameraOptions" [multiple]="multiple"
					[maxSizeKb]="maxSizeKb" [accept]="acceptedImages" [label]="filePickerImagesButtonText"
					[iconName]="imagePickerIcon" (isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
			<span>
				<file-picker #filePicker [disabled]="reachedLimit" [ngClass]="filePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModelAsync($event)" [options]="cameraOptions" [multiple]="multiple"
					[maxSizeKb]="maxSizeKb" [accept]="acceptedFiles" [label]="filePickerFilesButtonText"
					[iconName]="filePickerIcon" (isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
		</ng-container>

		<div *ngIf="!hideNoFileText && readOnly && (!files || files.length === 0)" class="empty-gallery">
			<p>Aucun fichier.</p>
		</div>
	</div>
</ng-template>

<ng-template #addMode>
	<div [ngClass]="!filePickerVisible && !cameraButtonVisible ? 'collapsed' : ''" class="picpos">
		<ng-container *ngIf="!readOnly">
			<span *ngIf="cameraButtonVisible" style="background-color: transparent">
				<ion-button [disabled]="reachedLimit" (click)="takePicture()" class="licamera-button">
					<ion-icon [slot]="cameraButtonText ? 'start' : 'icon-only'" name="camera"></ion-icon>
					<ion-label *ngIf="cameraButtonText">
						{{ cameraButtonText }}
					</ion-label>
				</ion-button>
			</span>
			<span>
				<file-picker #imagePicker [disabled]="reachedLimit" [ngClass]="imagePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModelAsync($event)" [options]="cameraOptions" [multiple]="multiple"
					[maxSizeKb]="maxSizeKb" [accept]="acceptedImages" [label]="filePickerImagesButtonText"
					[iconName]="imagePickerIcon" (isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
			<span>
				<file-picker #filePicker [disabled]="reachedLimit" [ngClass]="filePickerVisible ? '' : 'collapsed'"
					(fileSelected)="addToModelAsync($event)" [options]="cameraOptions" [multiple]="multiple"
					[maxSizeKb]="maxSizeKb" [accept]="acceptedFiles" [label]="filePickerFilesButtonText"
					[iconName]="filePickerIcon" (isLoading)="onLoadingChanged($event)">
				</file-picker>
			</span>
		</ng-container>
	</div>
</ng-template>

<!-- MODE PREVIEW -->
<ng-template #previewMode>
	<div *ngIf="(files?.length$ | async) > 0" class="ctn-liste-gallery thumbnails">
		<div *ngFor="let item of files.changes$ | async; let i = index"
			[class]="'preview-item' + (!(item.observableIsAvailable.value$ | async)? ' stable-bg': '')">
			<ng-container *ngIf="!excludeFileGuids?.includes(item.guid)">

				<!-- Affiche l'image si présente sinon une icône. -->
				<ng-container [ngSwitch]="!!item.file && !!item.file.MimeType && item.file.MimeType.indexOf('image') !== -1">
					<div *ngSwitchCase="true" (click)="openFileAt(i)" class="ctn-preview">
						<!-- Mets l'opacité de l'image à 80% si le fichier est en attente d'upload. -->
						<osapp-image [ngClass]="(item.observableIsUploaded.value$ | async) ? '' : 'not-uploaded'"
							[imgClass]="imgClass.noPaddingImg" [src]="item.file.File" [alt]="item.name">
						</osapp-image>
					</div>
					<div *ngSwitchCase="false" (click)="openFileAt(i)" class="ctn-preview">
						<ion-icon [name]="isImage(item) ? 'image' : 'document'" class="file-icon"></ion-icon>
					</div>
				</ng-container>
			</ng-container>
		</div>
	</div>

	<div *ngIf="!hideNoFileText && (!files || files.length === 0)" class="empty-gallery">
		<p>Aucun fichier.</p>
	</div>
</ng-template>