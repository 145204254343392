<ion-header>
	<ion-toolbar>
		<ion-buttons slot="start">
			<ion-button (click)="close()">
				<ion-icon slot="icon-only" name="close"></ion-icon>
			</ion-button>
			<ion-back-button text=""></ion-back-button>
		</ion-buttons>
		<ion-title>Détail des synchronisations</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-list>
		<calao-list-item *ngFor="let task of tasks$ | async">
			<calao-list-item-content>
				<calao-list-item-content-title>{{task.descriptor.params?.dbId}}</calao-list-item-content-title>
				<calao-list-item-content-suffix>{{task.descriptor.name}}</calao-list-item-content-suffix>
				<calao-list-item-end>{{task.progressLabel$ | async}}</calao-list-item-end>
			</calao-list-item-content>
		</calao-list-item>
	</ion-list>
</ion-content>