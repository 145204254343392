export enum EListItemOption {
	delete,
	read,
	markAsRead,
	markAsUnread,
	trash,
	edit,
	restore,
	share,
	pick
}
