import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { EDateTimePickerMode } from '../../../../../../model/date/EDateTimePickerMode';
import { ETimetablePattern } from '../../../../../../model/date/ETimetablePattern';
import { IDateTimePickerParams } from '../../../../../../model/date/IDateTimePickerParams';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IDateFieldParams } from './models/idate-field-params';

@Component({
	selector: 'calao-date-field',
	templateUrl: './date-field.component.html',
	styleUrls: ['./date-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateFieldComponent extends FieldBase<Date> implements OnInit {

	//#region PROPERTIES

	/** Paramètres du champs. */
	public readonly observableParams = new ObservableProperty<IDateFieldParams>();

	/** `true` si readOnly, sinon `false`. Est `false` par défaut. */
	public readonly observableIsReadOnly = new ObservableProperty<boolean>(false);

	/** Masque le sélecteur pour l'heure. Est `false` par défaut. */
	public readonly observableHideTimePicker = new ObservableProperty<boolean>(false);

	public readonly observableDatePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.date,
		displayFormat: ETimetablePattern.dd_MMM_yyyy,
		hideIcon: true
	});

	public readonly observableTimePickerParams = new ObservableProperty<IDateTimePickerParams>({
		pickerMode: EDateTimePickerMode.time,
		displayFormat: ETimetablePattern.HH_mm,
		hideIcon: true
	});

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		psvcForms: FormsService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(psvcForms, poChangeDetectorRef);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		const loParams: IDateFieldParams = this.props as IDateFieldParams ?? {};

		this.observableIsReadOnly.value = !!loParams.readOnly;
		this.observableHideTimePicker.value = !!loParams.hideTimePicker;
		if (!this.fieldValue)
			this.fieldValue = new Date();
	}

	public onDateChanged(pdStartDate: Date): void {
		this.fieldValue = pdStartDate;
	}

	//#endregion METHODS

}
