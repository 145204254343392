
/** Classe abstraite référence pour les patchs à appliquer.\
 * Les implémentations filles doivent être `@Injectable()`.
 */
export abstract class PatchBase {

	//#region PROPERTIES

	private readonly msDescription: string;
	/** Description du patch. */
	public get patchDescription(): string { return this.msDescription; }

	//#endregion

	//#region METHODS

	constructor(psDescription: string) {
		this.msDescription = psDescription;
	}

	/** Applique le patch. */
	public abstract applyPatchAsync(): Promise<boolean>;

	//#endregion

}