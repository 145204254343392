import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WorkspaceService } from '@calaosoft/osapp/services/workspace.service';
import { Observable } from 'rxjs';
import { ConfigData } from '../model/config/ConfigData';
import { AuthenticatedRequestOptionBuilder } from '../modules/api/models/authenticated-request-option-builder';
import { DestroyableServiceBase } from '../modules/services/models/destroyable-service-base';

@Injectable({ providedIn: "root" })
export class AiGenerationService extends DestroyableServiceBase {

	//#region METHODS

	constructor(
		private readonly isvcWorkspace: WorkspaceService,
		private readonly ioHttpClient: HttpClient,
	) {
		super();
	}

	public generateReport(psReportBody: string): Promise<object> {
		const lsApiUrl = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}workspaces/${this.isvcWorkspace.getCurrentWorkspaceId()}/ai/text/generate`;

		const loBody = {
			prompt: {
				user: psReportBody,
				system: `Tu es un assistant IA d'une entreprise.\nCette entreprise vend des vêtements à des supermarchés.\nTu dois corriger le rapport qui t'est donné par l'user.\n\nCe rapport doit être écrit avec un ton PROFESSIONNEL.\nTon rapport doit bien prendre TOUS les détails donnés par l'user et les retranscrire sans modifier le sens.\nSi tu ne comprends pas le sens d'une phrase, ne la modifie pas.\nSi des acronymes/sigles sont utilisés, laisse les sous leur forme abrégée.\n\nVoici une liste d'acronymes souvent utilisés :\n- TG : Tête de Gondole\n- B2 : Baie à 2 faces\n- PDV : Point De Vente\nS'ils apparaissent dans le rapport, écrit leurs formes abrégées\n\nTu dois écrire SEULEMENT le CORPS du rapport donc :\n- ne mets PAS des salutations\n- ne mets PAS de signature\n- ne mets PAS de conclusion\n\nTu dois répondre en français`,
				examples: [{
					user: `Voir avec Noémie car Plan Faux (2.5 élément) en moins en profondeur.\nA priori le PDV retirait les Peg côté négatif donc implantation de 8 modules + 2TG.\n\nBéatrice (RR) m'annonce qu'un projet de remodeling non al est prévu pour début Octobre ! Récupération des plans de Béatrice et envoi mail pour RV commun avec directrice.\n\nsatisfaite de la zone réimplantée.`,
					assistant: `Il faut contacter Noémie concernant un plan erroné (2,5 éléments manquants en profondeur). Apparemment, le PDV retirait les Peg côté négatif, ce qui a nécessité l'implantation de 8 modules + 2 TG.\n\nBéatrice (RR)m' a informé qu'un projet de remodeling non alimentaire est prévu pour début octobre. Récupérer les plans auprès de Béatrice et envoyer un e-mail pour convenir d'un rendez-vous commun avec la directrice.\n\nJe suis satisfaite de la zone réimplantée.`
				}]
			}
		};

		return this.ioHttpClient.post(lsApiUrl, loBody, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions()).toPromise();
	}

	public getUserUsage(): Observable<object> {
		const lsApiUrl = `${ConfigData.environment.cloud_url}${ConfigData.environment.cloud_api_apps_suffix}workspaces/${this.isvcWorkspace.getCurrentWorkspaceId()}/ai/text/quota`;

		return this.ioHttpClient.get(lsApiUrl, AuthenticatedRequestOptionBuilder.buildAuthenticatedRequestOptions());
	}

	//#endregion

}