import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { HeaderModule } from '../../header/header.module';
import { BluetoothModule } from '../bluetooth/bluetooth.module';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { SearchPage } from './search-page.component';

@NgModule({
	imports: [
		CommonModule, IonicModule, HeaderModule, BluetoothModule
	],
	exports: [SearchPage],
	declarations: [SearchPage, AdvancedSearchComponent],
	providers: [],
})
export class SearchPageModule { }
