export enum EAppointmentType {
	/** Visite client. */
	merchandising = "merchandising",
	/** Convoyage. */
	carrying = "carrying",
	/** Chargement. */
	loading = "loading",
	/** Déchargement */
	unloading = "unloading",
	/** Garage. */
	vehicleMaintenance = "vehicleMaintenance",
}