import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ArrayHelper } from '@calaosoft/osapp/helpers/arrayHelper';
import { GuidHelper } from '@calaosoft/osapp/helpers/guidHelper';
import { NumberHelper } from '@calaosoft/osapp/helpers/numberHelper';
import { StringHelper } from '@calaosoft/osapp/helpers/stringHelper';
import { IIndexedArray } from '@calaosoft/osapp/model/IIndexedArray';
import { LoggerService } from '@calaosoft/osapp/modules/logger/services/logger.service';
import { DmsArticleColor } from '@calaosoft/osapp/modules/logistics/catalog/models/dms-article-color';
import { IBarcodeItem } from '@calaosoft/osapp/modules/logistics/catalog/models/ibarcode-item';
import { IItemData } from '@calaosoft/osapp/modules/logistics/catalog/models/iitem-data';
import { ILabel } from '@calaosoft/osapp/modules/logistics/catalog/models/ilabel';
import { IPricedItem } from '@calaosoft/osapp/modules/logistics/catalog/models/ipriced-item';
import { CatalogService } from '@calaosoft/osapp/modules/logistics/catalog/services/catalog.service';
import { IReason } from '@calaosoft/osapp/modules/logistics/reason/models/IReason';
import { RackHelper } from '@calaosoft/osapp/modules/logistics/task/helpers/rack.helper';
import { ETaskStatus } from '@calaosoft/osapp/modules/logistics/task/models/ETaskStatus';
import { EKnownTaskSubType } from '@calaosoft/osapp/modules/logistics/task/models/eknown-task-sub-type';
import { EKnownTaskType } from '@calaosoft/osapp/modules/logistics/task/models/eknown-task-type';
import { IItemPrice } from '@calaosoft/osapp/modules/logistics/task/models/iitem-price';
import { IPickingStep } from '@calaosoft/osapp/modules/logistics/task/models/ipicking-step';
import { ITaskPhaseNavigationParams } from '@calaosoft/osapp/modules/logistics/task/models/navigation/itask-phase-navigation-params';
import { ReturnRackHelper } from '@calaosoft/osapp/modules/logistics/task/returns/helpers/return-rack.helper';
import { IControlItem } from '@calaosoft/osapp/modules/logistics/task/returns/models/IControlItem';
import { IPickingNavigationParams } from '@calaosoft/osapp/modules/logistics/task/returns/models/IPickingNavigationParams';
import { IReceipt } from '@calaosoft/osapp/modules/logistics/task/returns/models/IReceipt';
import { IReturnTask } from '@calaosoft/osapp/modules/logistics/task/returns/models/IReturnTask';
import { IReturnRack } from '@calaosoft/osapp/modules/logistics/task/returns/models/ireturn-rack';
import { ReturnsService } from '@calaosoft/osapp/modules/logistics/task/returns/services/returns.service';
import { TaskService } from '@calaosoft/osapp/modules/logistics/task/services/task.service';
import { Store } from '@calaosoft/osapp/services/store.service';
import { Observable, combineLatest, defer, from, of } from 'rxjs';
import { concatMap, map, mapTo, toArray } from 'rxjs/operators';
import { ANOMALY_PARENT_REASON_ID } from '../../../../app.constants';
import { ETasksUrlPart } from '../../models/etasks-url-part';
import { IArticlesAndDefaultArticlesTotalCount } from '../../models/validation/iarticles-and-default-articles-total-count';
import { IQuantifiedReceiptArticle } from '../components/receipts-list/models/iquantified-receipt-article';
import { ReturnControlHelper } from '../helpers/return-control.helper';
import { IAggregatedControlItem } from '../models/controls/iaggregated-control-item';
import { EReturnsUrlPart } from '../models/ereturns-url-part';
import { ICatalogItem } from '../models/icatalog-item';
import { IPricedCatalogItem } from '../models/ipriced-catalog-item';
import { IPricedControlItem } from '../models/ipriced-control-item';
import { MerchReasonService } from './merch-reason.service';
import { MerchReturnRackService } from './merch-return-rack.service';

@Injectable()
export class MerchReturnsService extends ReturnsService {

	//#region FIELDS

	private static readonly C_MERCH_LOG_ID = "MERCH.RTRNS.S::";

	private readonly moCatalogItemByControlItemKey = new Map<string, ICatalogItem>();

	//#endregion

	//#region METHODS

	constructor(
		private readonly ioRouter: Router,
		private readonly isvcCatalog: CatalogService,
		protected override readonly isvcReturnRack: MerchReturnRackService,
		private readonly isvcReason: MerchReasonService,
		private readonly isvcTask: TaskService,
		psvcLogger: LoggerService,
		psvcStore: Store
	) {
		super(psvcStore, psvcLogger, isvcReturnRack);
	}

	/** Navigue vers la page d'un prélèvement.
	 * @param poActivatedRoute Route courante afin de naviguer vers la page de prélèvement depuis la route actuelle.
	 * @param poNavigationParams Paramètres de navigation nécessaires pour aller sur la page de prélèvement,
	 * appeler `getNavigateToNewPickingParams` ou `getNavigateToPickingParams()` pour les obtenir.
	 */
	public navigateToPicking(poActivatedRoute: ActivatedRoute, poNavigationParams: IPickingNavigationParams): Observable<boolean> {
		const laUrlFragments: string[] = [];
		const loNavigationExtras: NavigationExtras = { relativeTo: poActivatedRoute, state: poNavigationParams };

		if (!this.ioRouter.url.includes(ETasksUrlPart.slashRacks))
			laUrlFragments.push(ETasksUrlPart.racks);

		laUrlFragments.push((loNavigationExtras.state as IPickingNavigationParams).rack._id, EReturnsUrlPart.picking);

		return from(this.ioRouter.navigate(laUrlFragments, loNavigationExtras));
	}

	public navigateToInventory(poPickingParams: IPickingNavigationParams): Observable<boolean>;
	public navigateToInventory(poRack: IReturnRack, pnRackIndex: number): Observable<boolean>;
	public navigateToInventory(poPickingParamsOrRack: IPickingNavigationParams | IReturnRack, pnRackIndex?: number): Observable<boolean> {
		let loReturnsTaskPhaseNavigationParams: ITaskPhaseNavigationParams<IReturnRack>;

		if ((poPickingParamsOrRack as IReturnRack)._id)
			loReturnsTaskPhaseNavigationParams = { rack: poPickingParamsOrRack as IReturnRack, index: pnRackIndex ?? 0 };
		else
			loReturnsTaskPhaseNavigationParams = poPickingParamsOrRack as ITaskPhaseNavigationParams<IReturnRack>;

		const loNavigationExtras: NavigationExtras = { state: loReturnsTaskPhaseNavigationParams };
		// Si on est sur la page de prélèvement il suffit de modifier le suffixe de l'url, sinon (page des reprises) il faut ajouter '/racks/:rackId' en plus du suffixe.
		const lsInventoryUrl: string = this.ioRouter.url.endsWith(EReturnsUrlPart.slashPicking) ?
			this.ioRouter.url.replace(EReturnsUrlPart.picking, EReturnsUrlPart.inventory) :
			`${this.ioRouter.url}/${ETasksUrlPart.racks}/${loReturnsTaskPhaseNavigationParams.rack._id}/${EReturnsUrlPart.inventory}`;

		return from(this.ioRouter.navigate(lsInventoryUrl.split("/"), loNavigationExtras));
	}

	/** Récupère un objet contenant le nombre d'articles normaux et le nombre total d'articles en défauts comptabilisés.
	 * @param poRack Portant dans lequel compter les totaux.
	 */
	public getTotalArticlesAndTotalDefaultArticlesCounts(poRack: IReturnRack): Observable<IArticlesAndDefaultArticlesTotalCount> {
		return this.isvcReason.getReasonChildren(ANOMALY_PARENT_REASON_ID)
			.pipe(
				map((paChildren: IReason[]) => paChildren.find((poChild: IReason) => MerchReasonService.isDefaultRootReason(poChild))),
				map((poReason?: IReason) => {
					const loResult: IArticlesAndDefaultArticlesTotalCount = { articlesTotalCount: 0, defaultArticlesTotalCount: 0 };

					if (poReason) {
						poRack.controls.forEach((poItem: IControlItem) => {
							poItem.reasonId && poReason.childIds?.some((psChildId: string) => psChildId === poItem.reasonId) ?
								++loResult.defaultArticlesTotalCount : ++loResult.articlesTotalCount;
						});
					}
					else
						loResult.articlesTotalCount = poRack.controls.length;

					return loResult;
				})
			);
	}

	/** Tri un tableau en mettant les articles avec motif défaut à la fin.
	 * @param paArticles Tableau des articles à trier.
	 * @param pfGetReasonId Fonction permettant de récupérer l'identifiant du motif d'un élément.
	 */
	public sortArticlesWithDefaultsAtEnd$<T>(paArticles: T[], pfGetReasonId: (poItem: T) => string | undefined): Observable<T[]> {
		return this.isvcReason.getDefaultReasonChildren()
			.pipe(
				map((paDefaultReasons: IReason[]) => {
					return [...paArticles].sort((poArticleA: T, poArticleB: T) => {
						const lbIsArticleADefault: boolean = this.isvcReason.isReasonIn(paDefaultReasons, pfGetReasonId(poArticleA));
						const lbIsArticleBDefault: boolean = this.isvcReason.isReasonIn(paDefaultReasons, pfGetReasonId(poArticleB));

						return lbIsArticleADefault && lbIsArticleBDefault ? 0 : lbIsArticleADefault ? 1 : lbIsArticleBDefault ? -1 : 0;
					});
				})
			);
	}

	/** Récupère un article avec pleins d'informations.
	 * @param poItem Élément de contrôle agrégé dont il faut récupérer les informations.
	 */
	private getItemFromCatalogAsync(poItem: IAggregatedControlItem): Promise<IBarcodeItem | IItemData | ILabel & { itemId?: string } | undefined> {
		return defer(() => {
			if (!StringHelper.isBlank(poItem.code)) // Code-barres présent, on peut récupérer toutes les infos nécessaires.
				return this.isvcCatalog.getBarcodeItemFromBarcode(poItem.code);

			else if (!StringHelper.isBlank(poItem.variantId)) // Identifiant de variant présent, on peut récupérer quasiment toutes les informations.
				return this.isvcCatalog.getItemDataFromVariantId(poItem.variantId);

			else // Identifiant d'article uniquement, on ne peut pas récupérer beaucoup d'informations car moins précis.
				return poItem.itemId ? this.isvcCatalog.getILabelFromItemId(poItem.itemId) : of(undefined);
		})
			.pipe(
				map((poResult?: IBarcodeItem | IItemData | ILabel): IBarcodeItem | IItemData | (ILabel & { itemId?: string }) => {
					if (poResult) { // Si on a un résultat, on le retourne en ajoutant si besoin le code article.
						return {
							...poResult,
							itemId: StringHelper.isBlank((poResult as IItemData).itemId) ? poItem.itemId : (poResult as IItemData).itemId
						};
					}
					else
						return { label: "", itemId: poItem.itemId, code: poItem.code };
				})
			)
			.toPromise();
	}

	/** Crée un élément du catalogue tariffé.
	 * @param poItem Élément de contrôle agrégé ou élément de bon quantifié.
	 * @param poRackData Portant ou tableau des portants dans lesquels récupérer le prix pour l'élément à créer.
	 * @param paReceipts Tableau des bons dans lesquels récupérer le prix pour l'élément à créer.
	 */
	private async createPricedCatalogItemsAsync<T extends IPricedCatalogItem>(poItem: IAggregatedControlItem | IQuantifiedReceiptArticle,
		poRackData: IReturnRack | IReturnRack[], paReceipts: IReceipt[]): Promise<T | undefined> {

		const lsControlItemKey: string = ReturnControlHelper.getGenericItemKey(poItem);

		if (StringHelper.isBlank(lsControlItemKey)) {
			console.error(`${MerchReturnsService.C_MERCH_LOG_ID}Control item key is missing.`);
			return undefined;
		}


		if (this.moCatalogItemByControlItemKey.has(lsControlItemKey)) { // Si on a déjà l'élément du catalogue sous la main on le réutilise.
			const loCatalogItem: ICatalogItem = this.moCatalogItemByControlItemKey.get(lsControlItemKey)!;

			return {
				...poItem,
				...loCatalogItem,
				...this.getCatalogItemPrice(loCatalogItem, poItem, poRackData, paReceipts)
			} as T;
		}
		else {
			// Récupération des infos de l'article dans le catalogue.
			const loCatalogItem: IBarcodeItem | IItemData | ILabel & { itemId?: string } | undefined = await this.getItemFromCatalogAsync(poItem);
			// Construction de l'article tariffé final en affectant le prix récupéré avec les infos de l'article par défaut.
			const loPricedCatalogItem: ICatalogItem = {
				...loCatalogItem as ICatalogItem,
				...this.getCatalogItemPrice(loCatalogItem as ICatalogItem, poItem, poRackData, paReceipts)
			};

			this.moCatalogItemByControlItemKey.set(lsControlItemKey, loPricedCatalogItem);

			return { ...poItem, ...loPricedCatalogItem } as T;
		}
	}

	private getCatalogItemPrice(poCatalogItem: ICatalogItem, poItem: IAggregatedControlItem | IQuantifiedReceiptArticle, poRackData: IReturnRack | IReturnRack[],
		paReceipts: IReceipt[]): IPricedItem {

		// Récupération du prix de l'article en priorisant le prix dans le bon.
		const loPrice: IItemPrice | undefined = (poItem as IQuantifiedReceiptArticle).price ?
			(poItem as IQuantifiedReceiptArticle).price : ReturnRackHelper.getArticlePriceByItemIdAndPriceKey(poCatalogItem, paReceipts, poRackData);

		return {
			priceType: StringHelper.isBlank(loPrice?.type) ? (poCatalogItem as ICatalogItem).priceType : loPrice?.type,
			priceValue: NumberHelper.isValidStrictPositive(loPrice?.value) ? loPrice?.value : (poCatalogItem as ICatalogItem).priceValue
		};
	}

	public getRacksAndReceipts(poRack: IReturnRack): Observable<[IReturnRack[], IReceipt[]]> {
		const lsTaskId: string = RackHelper.getTaskIdFromRack(poRack);

		return combineLatest([this.isvcReturnRack.getRacksFromTaskId$(lsTaskId), this.getReceipts(lsTaskId)]);
	}

	/** Récupère les éléments de catalogue tariffés.
	 * @param paAggregatedControlItems Tableau des éléments de contrôle agrégés.
	 * @param poRackData Portant ou tableau des portants dans lesquels récupérer le prix pour l'élément à créer.
	 * @param paReceipts Tableau des bons dans lesquels récupérer le prix pour l'élément à créer.
	 */
	public getPricedCatalogItems<T extends IPricedCatalogItem>(paAggregatedControlItems: IAggregatedControlItem[], poRackData: IReturnRack | IReturnRack[],
		paReceipts: IReceipt[]): Observable<T[]> {

		const loGroupedControlItems: IIndexedArray<IAggregatedControlItem[]> =
			ArrayHelper.groupBy(paAggregatedControlItems, (poItem: IAggregatedControlItem) => ReturnControlHelper.getGenericItemKey(poItem), true);

		return from(Object.keys(loGroupedControlItems)) // On requête pour chaque clé obtenue afin de ne pas multiplier inutilement le nombre de requêtes.
			.pipe(
				concatMap((psKey: string) => this.createPricedCatalogItemsAsync<T>(ArrayHelper.getFirstElement(loGroupedControlItems[psKey]), poRackData, paReceipts)),
				toArray(),
				map((paPricedCatalogItems: T[]) => this.getAllPricedCatalogItemsFromAggregatedControlItems(paAggregatedControlItems, paPricedCatalogItems))
			);
	}

	public getDmsArticleColorsByItemIdAsync(paPricedItems: IAggregatedControlItem[]): Promise<Map<string, DmsArticleColor[]>> {
		return this.isvcCatalog.getDmsArticleColorsByItemId(
			paPricedItems.map((poItem: IAggregatedControlItem) => poItem.itemId ?? "")
		).toPromise();
	}

	/** Récupère tous les éléments tariffés par rapport aux éléments agrégés d'origine.
	 * @param paAggregatedControlItems Éléments agrégés d'origine  sur lesquels se baser pour récupérer les éléments tariffés.
	 * @param paPricedCatalogItems Tableau des éléments tariffés récupérés dans la base de catalogue sur lesquels se baser pour récupérer les derniers éléments manquants.
	 */
	private getAllPricedCatalogItemsFromAggregatedControlItems<T extends IPricedCatalogItem>(paAggregatedControlItems: IAggregatedControlItem[],
		paPricedCatalogItems: T[]): T[] {

		const laAllPricedCatalogItems: T[] = [];

		// Une fois tous les éléments tariffés créés, il faut cloner certains éléments qui portaient un motif (car ils ont été fusionnés pour l'optimisation).
		paAggregatedControlItems.forEach((poAggregatedControlItem: IAggregatedControlItem) => {
			const lsKey: string = ReturnControlHelper.getGenericItemKey(poAggregatedControlItem);
			const loPricedItem: T | undefined =
				paPricedCatalogItems.find((poPricedItem: T) => ReturnControlHelper.getGenericItemKey(poPricedItem) === lsKey);

			if (loPricedItem) {
				// On ajoute une copie de l'élément tariffé pour retrouver tous les éléments qu'on avait en entrée dans 'paAggregatedControlItems'.
				laAllPricedCatalogItems.push(this.createPricedCatalogItem(loPricedItem, poAggregatedControlItem));
			}
			else // Ne doit pas arriver vu que les éléments tariffés ont été créés à partir des éléments agrégés.
				console.error(`${MerchReturnsService.C_MERCH_LOG_ID}priced catalog item '${lsKey}' not found in '${paPricedCatalogItems.map((poItem: T) => poItem.itemId).join(", ")}' to clone it.`);
		});

		return laAllPricedCatalogItems;
	}

	/** Récupère une map associant la clé d'un élément en fonction d'un tableau des éléments tariffés associés.
	 * @param paItems Tableau des éléments de contrôles agrégés ou éléments de bons quantifiés.
	 * @param poRackData Portant ou tableau des portants.
	 * @param paReceipts Tableau des bons.
	 * @param pfGetItemKey Fonction qui récupère la clé de chaque élément.
	 */
	public getPricedCatalogItemsByKeyAsync(paItems: IAggregatedControlItem[] | IQuantifiedReceiptArticle[], poRackData: IReturnRack | IReturnRack[],
		paReceipts: IReceipt[], pfGetItemKey: (poItem: IAggregatedControlItem | IQuantifiedReceiptArticle) => string | undefined): Promise<Map<string, IPricedControlItem[]>> {

		const loGroupedControlItems: IIndexedArray<IAggregatedControlItem[] | IQuantifiedReceiptArticle[]> =
			ArrayHelper.groupBy(paItems, (poItem: IAggregatedControlItem | IQuantifiedReceiptArticle) => pfGetItemKey(poItem), true);
		const loPricedItemsByGenericItemKey = new Map<string, IPricedControlItem[]>();

		return from(Object.keys(loGroupedControlItems)) // On requête pour chaque clé obtenue afin de ne pas multiplier inutilement le nombre de requêtes.
			.pipe(
				concatMap((psKey: string) => {
					return this.createPricedCatalogItemsAsync<IPricedCatalogItem>(
						ArrayHelper.getFirstElement((loGroupedControlItems[psKey] as IQuantifiedReceiptArticle[])),
						poRackData,
						paReceipts
					)
						.then((poPricedCatalogItem: IPricedCatalogItem) =>
							loPricedItemsByGenericItemKey.set(psKey, this.getPricedControlItems(loGroupedControlItems[psKey], poPricedCatalogItem))
						);
				}),
				toArray(),
				mapTo(loPricedItemsByGenericItemKey)
			)
			.toPromise();
	}

	/** Récupère tous les éléments tarifés par rapport aux éléments agrégés d'origine.
	 * @param paItems Tableau des éléments agrégés d'origine.
	 * @param poPricedCatalogItem Élément tarifé récupéré dans la base de catalogue.
	 */
	private getPricedControlItems(paItems: IAggregatedControlItem[] | IQuantifiedReceiptArticle[], poPricedCatalogItem: IPricedCatalogItem): IPricedControlItem[] {
		// Une fois tous les éléments tarifés créés, il faut cloner certains éléments qui portaient un motif (car ils ont été fusionnés pour l'optimisation).
		return paItems.map((poItem: IAggregatedControlItem | IQuantifiedReceiptArticle): IPricedControlItem =>
			this.createPricedCatalogItem(poPricedCatalogItem, poItem)
		);
	}

	private createPricedCatalogItem<T extends IPricedCatalogItem>(poPricedItem: T, poAggregatedControlItem: IAggregatedControlItem | IQuantifiedReceiptArticle): T {
		return {
			...poPricedItem, // Données récupérées dans le catalogue.
			qty: poAggregatedControlItem.qty,
			reasonId: (poAggregatedControlItem as IAggregatedControlItem).reasonId,
			variantId: (poAggregatedControlItem as IAggregatedControlItem).variantId
		};
	}

	/** Supprime le cache des éléments du catalogue en fonction de la clé d'un élément de contrôle afin de libérer de la mémoire. */
	public clearCatalogItemByControlItemKeyMap(): void {
		this.moCatalogItemByControlItemKey.clear();
	}

	/** Créé une tâche de reprise normale.
	 * @param psTourId Id de la tournée.
	 * @param psAppointmentId Id du rendez-vous.
	 */
	public createTask(psTourId: string, psAppointmentId: string): IReturnTask {
		return {
			_id: this.isvcTask.buildTaskId(psTourId, psAppointmentId, GuidHelper.newGuid()),
			type: "task",
			taskType: EKnownTaskType.return,
			taskSubType: EKnownTaskSubType.normal,
			order: 999,
			status: ETaskStatus.active,
			startDate: new Date(),
			endDate: new Date(),
			steps: {
				picking: {
					anomaly: {
						reasons: [
							{ id: "reason_RP100" },
							{ id: "reason_RP210" },
							{ id: "reason_RS200" },
							{ id: "reason_RS220" }
						]
					}
				},
				control: {
					anomaly: {
						reasons: [
							{ id: "reason_RP100" },
							{ id: "reason_RP210" },
							{ id: "reason_RS200" },
							{ id: "reason_RS220" }
						]
					}
				}
			}
		} as IReturnTask;
	}

	public getPickingObject(poTask: IReturnTask): IPickingStep | undefined {
		if (poTask.steps?.picking instanceof Object)
			return poTask.steps.picking;
		return undefined;
	}

	//#endregion

}