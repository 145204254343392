import { ObjectHelper } from '../../../../helpers/objectHelper';

export class DatabaseAlreadyExistsError extends Error {

	constructor(psDatabaseId: string) {
		super(`La base de données '${psDatabaseId}' existe déjà.`);
		// Nécessaire pour que le 'instanceof DatabaseAlreadyExistsError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, DatabaseAlreadyExistsError);
	}

}