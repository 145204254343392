import { ObjectHelper } from '../../../../../helpers/objectHelper';

export class DeviceUnknownError extends Error {

	constructor() {
		super("Appareil inconnu");
		// Nécessaire pour que le 'instanceof DeviceUnknownError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, DeviceUnknownError);
	}

}