import { ObjectHelper } from "../../../helpers/objectHelper";
import { EMessageAttachmentType } from "./emessage-attachment-type";
import { IMessageAttachment } from "./imessage-attachment";

export abstract class MessageAttachment implements IMessageAttachment {

	//#region PROPERTIES

	public readonly type: EMessageAttachmentType;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: IMessageAttachment) {
		if (poData)
			ObjectHelper.assign(this, poData);
	}

	//#endregion METHODS

}
