export enum EInput {
	checkbox = "checkbox",
	email = "email",
	number = "number",
	password = "password",
	radio = "radio",
	text = "text",
	tel = "tel",
	url = "url",
	zipCode = "zipCode"
}