import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ObserveProperty } from '../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../observable/models/observable-property';
import { ISettingHeaderParams } from '../models/isetting-header-params';

@Component({
	selector: 'calao-setting-header',
	templateUrl: './setting-header.component.html',
	styleUrls: ['./setting-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingHeaderComponent extends ComponentBase implements ISettingHeaderParams {

	//#region PROPERTIES

	/** Événement levé lorsque le bouton est cliqué. */
	@Output("onClicked") private readonly moClickEvent = new EventEmitter<void>();

	private msTitle: string;
	/**@implements */
	public get title(): string { return this.msTitle; }
	@Input() public set title(psTitle: string) {
		if (psTitle !== this.msTitle) {
			this.msTitle = psTitle;
			this.detectChanges();
		}
	}

	@Input() public buttonName?: string;
	@ObserveProperty<SettingHeaderComponent>({ sourcePropertyKey: "buttonName" })
	public readonly observableButtonName = new ObservableProperty<string>();

	private msIcon?: string;
	/**@implements */
	public get icon(): string | undefined { return this.msIcon; }
	@Input() public set icon(psIconName: string) {
		if (psIconName !== this.msIcon) {
			this.msIcon = psIconName;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	/** Lève un événement de clic. */
	public raiseOnClickedEvent(): void {
		this.moClickEvent.emit();
	}

	//#endregion

}
