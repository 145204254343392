import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { NumberHelper } from '../../../../helpers/numberHelper';
import { IRfidAcquisition } from '../../devices/models/IRfidAcquisition';
import { IAcquisitionQualityAlgorithm } from './IAcquisitionQualityAlgorithm';
import { IReadingQualityAlgorithm } from './IReadingQualityAlgorithm';

/** Fonction qui donne la qualité d'une lecture en se basant sur l'algorithme "Stratégie Qualitative d'Amélioration des Lectures". */
export class SqalAlgorithm implements IReadingQualityAlgorithm, IAcquisitionQualityAlgorithm {

	//#region FIELDS

	/** Pourcentage de qualité nécessaire à une acquisition pour qu'elle soit considérée comme suffisante. */
	private static readonly C_THRESHOLD_ACQUSITION_QUALITY = 80;

	/** Plus grande réception d'une acquistion en dB. */
	private mnMaxDb: number;
	/** Plus petite réception d'une acquisition en dB. */
	private mnMinDb: number;
	/** HashMap epc / qualité. */
	private moQualities: Map<string, number> = new Map();

	//#endregion

	//#region METHODS

	constructor(paAcquisitions?: Array<IRfidAcquisition>) {
		if (paAcquisitions)
			this.updateReading(paAcquisitions);
	}

	/** Retourne le pourcentage de qualité de la lecture. */
	public getReadingQuality(): number {
		if (this.moQualities.size === 0)
			return 0;

		// Nombre d'acquisitions au dessus du seuil.
		let lnCorrectAcquisitions = 0;
		this.moQualities.forEach((pnQuality: number) => {
			if (pnQuality >= SqalAlgorithm.C_THRESHOLD_ACQUSITION_QUALITY) // Si la qualité d'une acquisition est supérieure au seuil.
				lnCorrectAcquisitions++;
		});

		return Math.round((lnCorrectAcquisitions / this.moQualities.size) * 100);
	}

	public updateReading(paAcquisitions: Array<IRfidAcquisition>): void {
		// On ne change rien.
		if (!ArrayHelper.hasElements(paAcquisitions))
			return;

		// Met à jour les valeurs minimales et maximales.
		this.updateMinMax(paAcquisitions);

		paAcquisitions.forEach((poResponse: IRfidAcquisition) => {
			const lnQuality: number = this.getQuality(this.mnMinDb, this.mnMaxDb, poResponse.dbm);

			if (!this.moQualities.has(poResponse.code)) // Tag RFID non référencé => On l'ajoute.
				this.moQualities.set(poResponse.code, lnQuality);
			else if (this.moQualities.get(poResponse.code) < lnQuality) // Sinon, si la qualité est meilleure, on met à jour.
				this.moQualities.set(poResponse.code, lnQuality);
		});
	}

	/** Met à jour les valeurs minimales et maximales. */
	private updateMinMax(paAcquisitions: Array<IRfidAcquisition>): void {
		const loMinMax: { min: number, max: number } =
			NumberHelper.updateMinMax<IRfidAcquisition>(paAcquisitions, (poAcq: IRfidAcquisition) => poAcq.dbm, this.mnMinDb, this.mnMaxDb);

		this.mnMinDb = loMinMax.min;
		this.mnMaxDb = loMinMax.max;
	}

	/** Donne le niveau de qualité d'une réponse en fonction de son nombre de dBm. */
	private getQuality(pnMin: number, pnMax: number, pnValue: number): number {
		return NumberHelper.getPercentage(pnMin, pnMax, pnValue);
	}

	/** Retourne la qualité du tag passé en paramètre. */
	public getAcquisitionQuality(psTag: string): number {
		const lnQuality: number = this.moQualities.get(psTag);

		return NumberHelper.isValid(lnQuality) ? lnQuality : 0;	// Si inconnu, qualité nulle.
	}

	//#endregion
}