import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Input, Optional, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ArrayHelper } from '../../helpers/arrayHelper';
import { ObjectHelper } from '../../helpers/objectHelper';
import { StringHelper } from '../../helpers/stringHelper';
import { PlatformService } from '../../services/platform.service';
import { IBarcodeAcquisition } from '../logistics/devices/models/ibarcode-acquisition';
import { IAcquisitionSummary } from '../logistics/IAcquisitionSummary';
import { ReadingConfigService } from '../logistics/reading/ReadingConfig.service';
import { ModalComponentBase } from '../modal/model/ModalComponentBase';
import { ETorchState } from './ETorchState';
import { IScanditParam } from './IScanditParam';

@Component({
	templateUrl: './scandit-modal.component.html',
	styleUrls: ['./scandit-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanditModalComponent extends ModalComponentBase<IBarcodeAcquisition[]> {

	//#region FIELDS

	/** Factory pour le composant spécifique qui doit être utilisé pour afficher des informations sur un code-barres acquis. */
	private moScanSummaryComponentFactory: ComponentFactory<IAcquisitionSummary>;

	/** Tag HTML hôte pour le composant qui décrit de manière sommaire un élément scanné. */
	@ViewChild("scanArticleSummary", { static: true, read: ViewContainerRef }) private moScanSummaryHost: ViewContainerRef;

	//#endregion

	//#region PROPERTIES

	@Input() public params: IScanditParam;

	/** Retourne `true` si le flash est actif. */
	public get flash(): boolean { return this.camera.desiredTorchState === ETorchState.on; }

	/** Si la valeur passée en paramètre est `true` active le flash sinon le désactive. */
	public set flash(pbValue: boolean) {
		this.camera.desiredTorchState = pbValue ? ETorchState.on : ETorchState.off;
	}

	public camera: any;
	public acquisitions: IBarcodeAcquisition[] = [];
	public totalScanned = 0;

	//#endregion

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef,
		poResolver: ComponentFactoryResolver,
		@Optional() private isvcReadingConfig: ReadingConfigService
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetectorRef);
		this.moScanSummaryComponentFactory = poResolver.resolveComponentFactory(this.isvcReadingConfig.barcodeDetail());
	}

	public onRead(psCode: string): void {
		console.debug("SCANDIT-MODAL.C::Récupération d'une nouvelle acquisition: ", psCode);
		const lnIndex: number = this.acquisitions.findIndex((poValue: IBarcodeAcquisition) => poValue.code === psCode);

		// On incrémente s'il est connu, sinon on l'ajoute au tableau.
		// Dans tous les cas, le dernier élément scanné est en haut du tableau.
		if (lnIndex === -1) {
			this.acquisitions.unshift({ code: psCode, numberOfScans: 1, taken: [new Date()] });
		}
		else {
			this.acquisitions[lnIndex] = ObjectHelper.clone(this.acquisitions[lnIndex]);
			this.acquisitions[lnIndex].numberOfScans += 1;
			this.acquisitions[lnIndex].taken.push(new Date());
			ArrayHelper.moveElement(this.acquisitions, lnIndex, 0);
		}

		this.totalScanned++;

		this.updateScanSummary(this.acquisitions[0]);

		this.detectChanges();
	}

	/** Met à jour le composant spécifique qui affiche des données sommaires sur l'élément scanné. */
	private updateScanSummary(poAcq: IBarcodeAcquisition): void {
		this.moScanSummaryHost.clear();

		const loComponentRef: ComponentRef<IAcquisitionSummary> = this.moScanSummaryHost.createComponent(this.moScanSummaryComponentFactory);
		loComponentRef.instance.code = poAcq.code;
		loComponentRef.instance.numberOfScans = poAcq.numberOfScans;
	}

	/** Informations à afficher à l'utilisateur concernant un code. */
	public displayCode(psCode: string): string {
		if (StringHelper.isBlank(psCode))
			return "";

		if (this.isvcReadingConfig)
			return this.isvcReadingConfig.barcodeText(psCode);

		return psCode;
	}

	public validate(): void {
		this.close(this.acquisitions);
	}

	//#endregion
}