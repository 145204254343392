import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ModalOptions } from '@ionic/core';
import { Observable, from } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { StringHelper } from '../../../helpers/stringHelper';
import { ConfigData } from '../../../model/config/ConfigData';
import { IMailOptions } from '../../../model/mail/IMailOptions';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { MailService } from '../../../services/mail.service';
import { PlatformService } from '../../../services/platform.service';
import { BarcodeReaderService } from '../barcode-reader/services/barcode-reader.service';
import { IRfidAcquisition } from '../devices/models/IRfidAcquisition';
import { IBarcodeAcquisition } from '../devices/models/ibarcode-acquisition';
import { TslService } from '../devices/tsl/tsl.service';
import { EAcquisitionType } from '../models/EAcquisitionType';
import { ReadingConfigService } from './ReadingConfig.service';
import { EQualityAlgorithm } from './models/EQualityAlgorithm';
import { ERepresentationMode } from './models/ERepresentationMode';
import { IAcquisitionQualityAlgorithm } from './models/IAcquisitionQualityAlgorithm';
import { IReadingQualityAlgorithm } from './models/IReadingQualityAlgorithm';
import { IStoreNominal } from './models/IStoreNominal';
import { Timer } from './models/Timer';
import { IReadingModalDebug } from './reading-modal-debug/models/IReadingModalDebug';
import { ReadingModalDebug } from './reading-modal-debug/reading-modal-debug.component';
import { ReadingService } from './reading.service';

@Component({
	templateUrl: './reading-page.component.html',
	styleUrls: ['./reading-page.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadingPage extends ComponentBase implements OnInit {

	//#region PROPERTIES

	public readonly representationMode = ERepresentationMode;
	public readonly actionquisitionType = EAcquisitionType;

	/** Type de représentation, modifie le composant à afficher. */
	public representation: ERepresentationMode = ERepresentationMode.SIMPLE;
	/** Lecture à confronter à la lecture courante. Peut être `undefined`. */
	public nominal: Set<string>;
	/** Type de la lecture. */
	public type: EAcquisitionType;
	public codeCallback: (psCode: string) => string;
	/** Dernier code-barres scanné. */
	public lastScan: string;

	@ViewChild("barcodeView") public barcodeView: ElementRef<HTMLElement>;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcTsl: TslService,
		private readonly isvcPlatform: PlatformService,
		private readonly ioRouter: Router,
		private readonly ioRoute: ActivatedRoute,
		private readonly isvcReading: ReadingService,
		private readonly isvcMail: MailService,
		private readonly isvcBarcodeReader: BarcodeReaderService,
		private readonly ioModalCtrl: ModalController,
		poChangeDetector: ChangeDetectorRef,
		@Optional() private readonly isvcReadingConfig: ReadingConfigService,
	) {
		super(poChangeDetector);
	}

	public ngOnInit(): void {
		// Pour afficher des choses sur navigateurs et pouvoir réaliser des tests.
		if (!this.isvcPlatform.isMobileApp)
			this.generateRandomData();

		this.initQueryParamsSubscription().subscribe();
		// this.initDisplayCodeCallback(); // TODO À décommenter pour les tests en RFID. À terme méthode à supprimer.
		this.initInventorySubscription().subscribe();

		if (!this.isvcReadingConfig)
			console.warn("READ.P::Pas de configuration dans l'application pour le module Calao-Logistics.");
		else {
			this.isvcReadingConfig.init().pipe(
				tap(() => this.codeCallback = (psCode: string): string => this.isvcReadingConfig.barcodeText(psCode)),
				takeUntil(this.destroyed$)
			).subscribe();
		}
	}

	public ionViewWillLeave(): void {
		this.isvcBarcodeReader.stopReadBarcodeAsync();
	}

	/** Le changement des query params permet de modifier les paramètres de la lecture. */
	private initQueryParamsSubscription(): Observable<Params> {
		return this.ioRoute.queryParams
			.pipe(
				tap((poParams: Params) => {
					this.onQualityAlgoChanged(poParams.algo); // Change de l'algorithme de qualité.

					if (poParams.nominale) // Changement de lecture nominale.
						this.onNominalChanged(poParams.nominale);
					else if (poParams.nominale === undefined && this.hasNominal())
						this.onNominalChanged();

					// Gestion du type de lecture.
					this.onTypeChanged(poParams.type);
				}),
				takeUntil(this.destroyed$)
			);
	}

	/** La page Inventory écoute constamment l'observable qui retourne les inventaires. */
	private initInventorySubscription(): Observable<IRfidAcquisition> {
		return this.isvcTsl.inventory$
			.pipe(
				tap(
					(poNewAcquisition: IRfidAcquisition) => {
						this.isvcReading.addAcquisition(poNewAcquisition);
						this.detectChanges();
					},
					poError => console.error("READ.P::Erreur lors de l'écoute d'un inventaire: ", poError)
				),
				takeUntil(this.destroyed$)
			);
	}

	/** Initialise l'utilitaire pour afficher un contenu spécifique au lieu d'un code, s'il y a une configuration dans l'app. */
	private initDisplayCodeCallback(): void {
		// TODO Supprimer cette méthode et paramétrer le code à afficher via MerchAppReadingConfigService.
		if (!ConfigData.logistics || !ConfigData.logistics.rfidDisplay)
			return;

		// Charge la base de données associée.
		this.isvcReading.loadCodeData(ConfigData.logistics.rfidDisplay.database)
			.pipe(
				// Affecte la callback avec la base de données et le code Epc reçu.
				tap((paDocuments: IStoreDocument[]) => this.codeCallback = (psCode: string): string => ConfigData.logistics.rfidDisplay.callback(psCode, paDocuments))
			)
			.subscribe();
	}

	/** Appelé lors d'un clique sur le bouton "Lecture". */
	public onScanClicked(): void {
		// Décommenter la ligne pour générer des codes valides aléatoirements sur navigateur.
		// this.isvcPlatform.isMobileApp ? this.startScanning() : this.generateRandomData();
		this.startScanning();
	}

	/** !TEST: Utilisé sur navigateur pour tester. */
	private generateRandomData(): void {
		if (!ArrayHelper.hasElements(this.isvcReading.acquisitions)) {
			if (this.type === EAcquisitionType.rfid) {
				this.isvcReading.addAcquisitions([{
					code: "3014DBA01004C588D9AAF565",
					dbm: this.generateRandomDbm(80)
				}, {
					code: this.generateRandomEpc(),
					dbm: this.generateRandomDbm(70)
				}, {
					code: this.generateRandomEpc(),
					dbm: this.generateRandomDbm(80)
				}, {
					code: this.generateRandomEpc(),
					dbm: this.generateRandomDbm(10)
				}]);
			} else if (this.type === EAcquisitionType.barcode) {
				this.isvcReading.addAcquisitions([{
					code: "2057025000010",
					numberOfScans: 2,
				}, {
					code: "9994095000050",
					numberOfScans: 1,
				}, {
					code: "3711012000020",
					numberOfScans: 3,
				}, {
					code: "192180800019",
					numberOfScans: 1,
				}]);
			}
		}
		else if (Math.random() > 0) {
			if (this.type === EAcquisitionType.rfid) {
				this.isvcReading.addAcquisition({
					code: this.generateRandomEpc(),
					dbm: this.generateRandomDbm(80),
				});
			} else if (this.type === EAcquisitionType.barcode) {
				this.isvcReading.addAcquisition({
					code: "3645040000240",
					numberOfScans: 1,
				});
			}
		}
	}

	/** !TEST */
	private generateRandomEpc(): string {
		return `3014DBA01004C588D9AA${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}${Math.floor(Math.random() * 9)}`;
	}

	/** !TEST */
	private generateRandomDbm(pnMax: number): number {
		return Math.floor(-Math.random() * pnMax);
	}

	/** Lance une lecture. */
	public startScanning(): void {
		console.debug("READ.P::Lancement d'un scan.");

		switch (this.type) {
			case EAcquisitionType.rfid:
				this.isvcTsl.startReading({ alert: true, withOutputPower: true }).subscribe();
				break;

			case EAcquisitionType.barcode:
				this.isvcBarcodeReader.readOneBarcode()
					.pipe(
						tap((paAcquisitions: IBarcodeAcquisition[]) => {
							paAcquisitions.forEach((poNewAcq: IBarcodeAcquisition) => {	// TODO Transforme les IBarcodeAcquisition en IRfidAcquisition => Type commun ou ReadingPage<T>.
								if (this.isvcReadingConfig.filter(poNewAcq.code)) // Le plugin Cordova ne permet pas de filtrer (sauf sur le type de codes).
									console.warn(`BARCODE-READER.S::Récupération du code-à-barres ${poNewAcq.code} qui aurait dû être filtré.`);
								else
									this.isvcReading.addAcquisition({ code: poNewAcq.code, numberOfScans: poNewAcq.numberOfScans, taken: poNewAcq.taken });

								this.lastScan = poNewAcq.code;
							});

							this.updateTimer(paAcquisitions);
							this.detectChanges();
						}),
						takeUntil(this.destroyed$)
					)
					.subscribe();
				break;

			default:
				console.error("READ.P::Lancement d'un scan avec type inconnu:", this.type);
				break;
		}
	}

	/** Met à jour le timer en prenant la première et la dernière acquisition de l'ensemble en paramètre. */
	private updateTimer(paAcquisitions: IBarcodeAcquisition[], poTimer: Timer = this.isvcReading.timer): void {
		if (paAcquisitions.length <= 1)	// Si on a scanné 0 à 1 éléments, pas de temps entre chaque scan vide.
			return;

		/** Première acquisition de la liste. */
		let ldFirst: Date = new Date(paAcquisitions[0].taken[0]);
		/** Dernière acquisition. */
		let ldLast: Date = new Date(paAcquisitions[0].taken[0]);

		for (let acquisitionIndex = 0; acquisitionIndex < paAcquisitions.length; acquisitionIndex++) {
			for (let tabIndex = 0; tabIndex < paAcquisitions[acquisitionIndex].taken.length; tabIndex++) {
				const ldCurrent: Date = new Date(paAcquisitions[acquisitionIndex].taken[tabIndex]);

				if (ldFirst.getTime() > ldCurrent.getTime())
					ldFirst = ldCurrent;

				if (ldLast.getTime() < ldCurrent.getTime())
					ldLast = ldCurrent;
			}
		}

		poTimer.add(ldFirst, ldLast);
	}

	/** Méthode appelée lorsque l'utilisateur clique sur le bouton pour changer de représentation entre mode "Avancée" et "Simple". */
	public onSwitchRepresentationClicked(): void {
		if (this.representation === ERepresentationMode.SIMPLE)
			this.representation = ERepresentationMode.ADVANCED;
		else if (this.representation === ERepresentationMode.ADVANCED)
			this.representation = ERepresentationMode.SIMPLE;
		else
			console.error(`READ.P::Mode de représentation non pris en charge : ${this.representation}`);
	}

	public onReloadButtonClicked(): void {
		this.isvcReading.reload();
	}

	/** Active/Désactive l'algorithme de qualité de la lecture (SQAL uniquement pour l'instant). */
	public onQualityAlgoButtonClicked(): void {
		// Ajoute ou supprime le nom de l'algorithme des query params.
		const lsSqual: string = this.isvcReading.hasQualityAlgorithm() ? "" : "sqal";
		this.updateQueryParams("algo", lsSqual);
	}

	/** Ajoute le query param avec la valeur indiquée. Si la valeur est `undefined`, alors supprime le paramètre. */
	private updateQueryParams(psParam: string, psValue?: string): void {
		// Query Params à utiliser lors du routage.
		const loParams: Params = {};

		loParams[psParam] = !StringHelper.isBlank(psValue) ? psValue : null; // Si on met une valeur à null, supprime le paramètre.

		this.ioRouter.navigate(
			[],
			{
				relativeTo: this.ioRoute,
				queryParams: loParams,
				queryParamsHandling: "merge"
			}
		);
	}

	/** Change l'algorithme de qualité. Si `null`, supprime l'algo actuel. */
	private onQualityAlgoChanged(psAlgoName?: string): void {
		if (StringHelper.isBlank(psAlgoName))
			this.isvcReading.changeQualityAlgorithm(EQualityAlgorithm.NONE);
		else if (psAlgoName === "sqal")
			this.isvcReading.changeQualityAlgorithm(EQualityAlgorithm.SQAL);

		this.detectChanges();
	}

	/** Appelée lorsque le type de lecture change. */
	private onTypeChanged(psNewType?: EAcquisitionType): void {
		if (!psNewType) {
			console.error("READ.P::Un type de lecture RFID doit être défini.");
			this.updateQueryParams("type", "rfid");
		}

		if (psNewType !== this.type) {
			this.type = psNewType;
			this.detectChanges();
		}
	}

	/** Fonction appelée lorsque la lecture nominale change.
	 * @param paNominals Si vide, suppression du comportement de lecture nominale. Sinon, ensemble des epc à lire.
	 */
	private onNominalChanged(psNominalId?: string): void {
		if (StringHelper.isBlank(psNominalId)) {
			if (!this.nominal) // Si on a pas de nominal et qu'on ne veut pas en ajouter, on ne fait rien.
				return;
			else { // Suppression de la lecture nominale.
				this.nominal = undefined;
				this.detectChanges();
				return;
			}
		}

		this.isvcReading.loadNominal(psNominalId) // Chargement d'une lecture nominale.
			.pipe(
				tap((poStoreNominal: IStoreNominal) => {
					this.nominal = new Set(poStoreNominal.values);
					this.detectChanges();
				})
			)
			.subscribe();
	}

	/** Boutton appelé lorsque le bouton pour changer la lecture nominale est cliquée. */
	public onNominalButtonClicked(): void {
		// Ajoute ou supprime la lecture nominale des query params.
		const lsValue: string = this.hasNominal() ? "" : "rfid_nominal_000001";
		this.updateQueryParams("nominale", lsValue);
	}

	/** Retourne `true` si la lecture courante possède une lecture nominale. */
	public hasNominal(): boolean {
		return !!this.nominal;
	}

	/** Retourne `true` si un algorithme de qualité est présent. */
	public hasQualityAlgo(): boolean {
		return this.isvcReading.hasQualityAlgorithm();
	}

	public getReadings(): Array<IRfidAcquisition> {
		return this.isvcReading.acquisitions;
	}

	public getQuality(): IReadingQualityAlgorithm & IAcquisitionQualityAlgorithm {
		return this.isvcReading.qualityAlgo;
	}

	/** Envoie la lecture courante par mail. */
	public sendByMail(): void {
		console.log("READ.P::Préparation du mail.");

		const loTimer = new Timer();
		this.updateTimer(this.isvcReading.acquisitions, loTimer);	//! #5367 TODO Correction temporaire pour le Cipherlab, ne pas utiliser loTimer dans d'autres cas !

		const loMailOptions: IMailOptions =
		{
			attachments: [this.isvcReading.generateAcquisitionsAttachment(this.type, { quality: this.getQuality(), exportDateOfScans: this.type === EAcquisitionType.barcode })]
		};

		if (this.type === EAcquisitionType.barcode) { // TODO #3666.
			loMailOptions.attachments.push(this.isvcReading.generateMetaAttachment(
				{
					dureeTotale: `${(loTimer.duration / 1000).toFixed(2)} secondes`,
					dureeMoyenne: `${(loTimer.average(this.isvcReading.numberOfScans) / 1000).toFixed(2)} secondes par acquisition`
				}
			));

			loMailOptions.attachments.push(this.isvcReading.generateTimeAttachment());
		}

		this.isvcMail.sendMail(`Lecture ${this.type}`, "", loMailOptions)
			.pipe(
				tap(
					() => console.log("READ.P::Mail affiché à l'utilisateur."),
					poError => console.error("READ.P::Impossible de générer ou d'envoyer le mail :", poError)
				)
			).subscribe();
	}

	/** Retourne le compteur d'acquisitions. */
	public getCounter(): Map<string, number> {
		return this.isvcReading.counter;
	}

	/** Création d'une modale qui affiche des éléments de débogage. */
	public openModalDebug(): void {
		const loTimer = new Timer();

		this.updateTimer(this.isvcReading.acquisitions, loTimer);	//! #5367 TODO Correction temporaire pour le Cipherlab, ne pas utiliser loTimer dans d'autres cas !

		const loModalParam: IReadingModalDebug = {
			duration: {
				total: (loTimer.duration / 1000).toFixed(2),
				average: (loTimer.average(this.isvcReading.numberOfScans) / 1000).toFixed(2)
			}
		};
		const loModalOptions: ModalOptions = {
			component: ReadingModalDebug,
			componentProps: {
				params: loModalParam
			}
		};

		this.isvcBarcodeReader.stopReadBarcodeAsync();

		from(this.ioModalCtrl.create(loModalOptions))
			.pipe(
				tap((poModal: HTMLIonModalElement) => poModal.present()),
				mergeMap((poModal: HTMLIonModalElement) => poModal.onDidDismiss()),
				takeUntil(this.destroyed$),
			).subscribe();
	}

	//#endregion

}