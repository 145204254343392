import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { UserFeedBackService } from '../user-feedback/services/user-feedback.service';
import { UserFeedbackModule } from '../user-feedback/user-feedback.module';
import { ScanditModalComponent } from './scandit-modal.component';
import { ScanditRenderComponent } from './scandit-render.component';
import { ScanditService } from './scandit.service';

/** Module de gestion du module du SDK de Scandit. */
@NgModule({
	imports: [CommonModule, IonicModule, UserFeedbackModule],
	providers: [ScanditService, UserFeedBackService],
	declarations: [ScanditModalComponent, ScanditRenderComponent],
})
export class ScanditModule {
	constructor() { }
}