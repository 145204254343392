import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TitledInputComponent } from './titled-input.component';

@NgModule({
	declarations: [TitledInputComponent],
	imports: [
		CommonModule,
		IonicModule,
		FormsModule
	],
	exports: [
		TitledInputComponent
	]
})
export class TitledInputModule { }