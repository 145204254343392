<ng-container *ngTemplateOutlet="readOnly ? readonlyContent : (groupOptions?.length > 0 ? editContent : noElements)">
</ng-container>

<ion-button *ngIf="canCreate && !(observableHideCreateButton.value$ | async)" (click)="createNewGroup()">
	<ion-icon name="add" slot="start"></ion-icon>
	{{ createButtonText }}
</ion-button>

<!-- Template mode visu. -->
<ng-template #readonlyContent>
	<ng-container *ngIf="groupOptions?.length > 0; else noElements;">
		<ion-list mode="md" *ngIf="!sortGroupsByType; else groupsByType;">
			<ion-list-header mode="md" *ngIf="!hideTitle">
				<ion-label>{{ title }}</ion-label>
			</ion-list-header>
			<ion-item *ngFor="let groupOption of groupOptions; let idx = index"
				[lines]="idx + 1 === groupOptions.length ? 'none' : 'inset'"
				class="ion-no-padding pad-left-txt groups-list-visu" (click)="navigateToGroup(groupOption.value)">
				{{ groupOption.value.name }}
			</ion-item>
		</ion-list>

		<ng-template #groupsByType>
			<ion-list mode="md" *ngFor="let groupOptionsByType of groupsOptionsByTypes">
				<ion-list-header mode="md">
					<div>
						<ion-label>{{ groupOptionsByType.title }}</ion-label>
					</div>
					<div class="divider"></div>
				</ion-list-header>

				<ng-container *ngIf="groupOptionsByType.mode !== tagsDisplayMode; else tags;">
					<div class="group-list-body">
						<ion-item *ngFor="let groupOption of groupOptionsByType.groupOptions; let idx = index"
							[lines]="idx + 1 === groupOptionsByType.groupOptions.length ? 'none' : 'inset'"
							class="ion-no-padding pad-left-txt groups-list-visu" (click)="navigateToGroup(groupOption.value)">
							{{ groupOption.value.name }}
						</ion-item>
					</div>
				</ng-container>
				<ng-template #tags>
					<div class="tags-button">
						<ion-button *ngFor="let groupOption of groupOptionsByType.groupOptions" class="tag-btn" mode="ios"
							(click)="navigateToGroupIfAllowed(groupOption.value)" shape="round" color="primary">
							{{ groupOption.value.name }}
						</ion-button>
					</div>
				</ng-template>
			</ion-list>
		</ng-template>
	</ng-container>
</ng-template>

<!-- Template mode edit. -->
<ng-template #editContent>
	<ion-list *ngIf="!sortGroupsByType; else editGroupsByType;" lines="none">
		<ion-list-header *ngIf="!hideTitle">
			<ion-label>{{ title }}</ion-label>
		</ion-list-header>
		<osapp-selector [options]="groupOptions" [preselectedValues]="preSelectedGroups" [multiple]="multiple"
			(selectionChanged)="onGroupSelectionChanged($event)" [displayMode]="observableDisplayMode.value$ | async"
			[min]="min" [placeholder]="getPlaceholder(multiple)"></osapp-selector>
	</ion-list>

	<ng-template #editGroupsByType>
		<ion-list *ngFor="let groupOptionsByType of groupsOptionsByTypes">
			<ng-container
				*ngIf="groupOptionsByType.title === 'Groupes' || (groupOptionsByType.title === 'Rôles' && !(this.observableHideRoleGroups.value$ | async))">
				<ion-list-header>
					<ion-label>{{ groupOptionsByType.title }}</ion-label>
				</ion-list-header>
				<div class="groups-list" scrolly="true">
					<osapp-selector [options]="groupOptionsByType.groupOptions" [preselectedValues]="preSelectedGroups"
						[multiple]="multiple" (selectionChanged)="onGroupSelectionChanged($event)"
						[displayMode]="observableDisplayMode.value$ | async" [min]="min" [placeholder]="getPlaceholder(multiple)">
					</osapp-selector>
				</div>
			</ng-container>
		</ion-list>
	</ng-template>
</ng-template>

<!-- Template si aucun groupe. -->
<ng-template #noElements>
	<ion-label class="no-groups">{{emptyText}}</ion-label>
</ng-template>