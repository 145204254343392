import { Injectable } from '@angular/core';
import { EDatabaseRole } from '@calaosoft/osapp/model/store/EDatabaseRole';
import { IDataSource } from '@calaosoft/osapp/model/store/IDataSource';
import { Store } from '@calaosoft/osapp/services/store.service';
import { Observable } from 'rxjs';
import { ICustomer } from '../../../models/icustomer';
import { EAppointmentType } from '../models/EAppointmentType';
import { IAppointment } from '../models/IAppointment';

@Injectable({ providedIn: "root" })
export class MerchTourService {

	//#region FIELDS

	/** Url vers une page blanche de marchandisage pour indiquer la fin de l'historique. */
	public static readonly C_BLANK_MERCHANDISING_PAGE_URL = "blank";

	//#endregion

	//#region METHODS

	public constructor(private readonly isvcStore: Store) { }

	public getClients(paClientsId: string[]): Observable<ICustomer[]> {
		return this.isvcStore.get<ICustomer>({
			role: EDatabaseRole.workspace,
			viewParams: {
				keys: paClientsId,
				include_docs: true
			}
		} as IDataSource);
	}

	/** Retourne un objet de type `IMerchandising` si le rdv passé en paramètre est de ce type, sinon `undefined`. */
	public isMerchandisingAppoint(poAppoint: IAppointment): boolean {
		return poAppoint.appointmentType === EAppointmentType.merchandising;
	}

	//#endregion

}