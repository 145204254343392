import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { distinctUntilChanged, skip, takeUntil, tap } from 'rxjs/operators';
import { IIndexedObject } from '../../../../model/IIndexedObject';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { FilterBarMainContentComponent } from '../filter-bar-main-content/filter-bar-main-content.component';
import { FilterBarModalContentComponent } from '../filter-bar-modal-content/filter-bar-modal-content.component';

@Component({
	selector: 'calao-filter-bar',
	templateUrl: './filter-bar.component.html',
	styleUrls: ['./filter-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		"[style.display]": "'flex'",
		"[style.flex-direction]": "'column'"
	}
})
export class FilterBarComponent extends DestroyableComponentBase implements AfterContentInit {

	//#region FIELDS

	private static readonly C_LOG_ID = "FBAR.C::";

	@ContentChild(FilterBarModalContentComponent) private readonly moFilterBarModalContent: FilterBarModalContentComponent;
	@ContentChild(FilterBarMainContentComponent) private readonly moFilterBarMainContent: FilterBarMainContentComponent;

	/** Événement lors d'un changement dans les filtres. */
	@Output("onFilterValuesChange") private readonly moFilterValuesChangeEvent = new EventEmitter<IIndexedObject>();
	/** Événement lors d'un changement dans les filtres temporaires (édition en modale). */
	@Output("onTmpFilterValuesChange") private readonly moTmpFilterValuesChangeEvent = new EventEmitter<IIndexedObject>();

	//#endregion

	//#region PROPERTIES

	/** Valeur des filtres. */
	@Input() public filterValues?: IIndexedObject | null;
	@ObserveProperty<FilterBarComponent>({ sourcePropertyKey: "filterValues" })
	public readonly observableFilterValues = new ObservableProperty<IIndexedObject>();

	/** Nombre de résultats si les filtres sont validés. */
	@Input() public nbTmpResults?: number | null;
	@ObserveProperty<FilterBarComponent>({ sourcePropertyKey: "nbTmpResults" })
	public readonly observableNbTmpResults = new ObservableProperty<number>();

	private mbHasModal: boolean;
	public get hasModal(): boolean { return this.mbHasModal; }

	//#endregion

	//#region METHODS

	public ngAfterContentInit(): void {
		this.initMain();
		this.initModal();

		this.observableFilterValues.value$.pipe(
			distinctUntilChanged(),
			tap((poFilterValues: IIndexedObject) => this.moFilterValuesChangeEvent.emit(poFilterValues)),
			takeUntil(this.destroyed$)
		).subscribe();
	}

	private initMain(): void {
		if (this.moFilterBarMainContent) {
			this.moFilterBarMainContent.observableFilterValues.bind(this.observableFilterValues.value$, this);
			this.observableFilterValues.bind(this.moFilterBarMainContent.observableFilterValues.value$, this);
		}
		else
			console.warn(`${FilterBarComponent.C_LOG_ID}Veuillez utiliser le composant 'calao-filter-bar-main-content'.`);
	}

	private initModal(): void {
		if (this.mbHasModal = !!this.moFilterBarModalContent) {
			this.moFilterBarModalContent.validate$.pipe(
				tap((poFilterValues: IIndexedObject) => this.observableFilterValues.value = poFilterValues),
				takeUntil(this.destroyed$)
			).subscribe();

			this.moFilterBarModalContent.observableFilterValues.value$.pipe(
				skip(1), // On passe toujours le premier car le filtre sera toujours vide par défaut.
				tap((poFilterValues: IIndexedObject) => this.moTmpFilterValuesChangeEvent.emit(poFilterValues)),
				takeUntil(this.destroyed$)
			).subscribe();

			this.moFilterBarModalContent.observableNbTmpResults.bind(this.observableNbTmpResults.value$, this);
		}
	}

	public presentFilterModal(): void {
		if (this.moFilterBarModalContent) {
			const loFilterValues: IIndexedObject = {};

			if (this.observableFilterValues.value) {
				Object.keys(this.observableFilterValues.value).forEach((psKey: string) =>
					loFilterValues[psKey] = this.observableFilterValues.value[psKey]
				);
			}

			this.moFilterBarModalContent.present(loFilterValues);
		}
	}

	//#endregion

}
