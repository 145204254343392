import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';
import { IDocExlorerFilterValues } from '../../models/idoc-explorer-filter-values';

@Component({
	selector: 'calao-doc-explorer-filter-bar',
	templateUrl: './doc-explorer-filter-bar.component.html',
	styleUrls: ['./doc-explorer-filter-bar.component.scss'],
})
export class DocExplorerFilterBarComponent<T extends IDocExlorerFilterValues> extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	/** Événement lors du changement des filtres. */
	@Output("onFilterValuesChange") private readonly moFilterValuesChangeEvent = new EventEmitter<T>();
	/** Événement lors du changement des filtres temporaires. */
	@Output("onTmpFilterValuesChange") private readonly moTmpFilterValuesChangeEvent = new EventEmitter<T>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** Valeur des filtres. */
	@Input() public filterValues?: IDocExlorerFilterValues | null;
	@ObserveProperty<DocExplorerFilterBarComponent<T>>({ sourcePropertyKey: "filterValues" })
	public readonly observableFilterValues = new ObservableProperty<IDocExlorerFilterValues>();

	/** Nombre de résultats si les filtres sont validés. */
	@Input() public nbTmpResults?: number;
	@ObserveProperty<DocExplorerFilterBarComponent<T>>({ sourcePropertyKey: "nbTmpResults" })
	public readonly observableNbTmpResults = new ObservableProperty<number>();

	public readonly nbFiltersLabel$: Observable<string | undefined> = this.getNbFiltersLabel$().pipe(
		secure(this)
	);

	//#endregion PROPERTIES

	//#region METHODS

	constructor() {
		super();
	}

	public ngOnInit(): void {

	}

	public onFilterValuesChange(poFilterValues: T): void {
		this.moFilterValuesChangeEvent.emit(this.observableFilterValues.value = poFilterValues);
	}

	public onTmpFilterValuesChange(poTmpFilterValues: T): void {
		this.moTmpFilterValuesChangeEvent.emit(poTmpFilterValues);
	}

	private getNbFiltersLabel$(): Observable<string | undefined> {
		return of("");
	}

	//#endregion METHODS

}
