import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ISettingsGroupParams } from '../models/isettings-group-params';

@Component({
	selector: 'calao-settings-group',
	templateUrl: './settings-group.component.html',
	styleUrls: ['./settings-group.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingsGroupComponent extends ComponentBase implements ISettingsGroupParams {

	//#region PROPERTIES

	private msTitle: string;
	/** Titre du groupe.
	 * @implements
	 */
	public get title(): string { return this.msTitle; }
	@Input() public set title(psTitle: string) {
		if (psTitle !== this.msTitle) {
			this.msTitle = psTitle;
			this.detectChanges();
		}
	}

	private msIcon: string;
	/** Icône du groupe
	 * @implements
	 */
	public get icon(): string { return this.msIcon; }
	@Input() public set icon(psIcon: string) {
		if (psIcon !== this.msIcon) {
			this.msIcon = psIcon;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	//#endregion METHODS


}
