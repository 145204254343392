import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { ISlidesHeaderParams } from '../../models/ISlidesHeaderParams';

/** En-tête de slides. Le contenu pour afficher le titre est dynamique et peut être renseigné en faisant :
 * ```html
 * <osapp-slides-header>
 *   Mon contenu dynamique qui sera placé dans un ion-item.
 * </osapp-slides-header>
 * ```
 */
@Component({
	selector: "osapp-slides-header",
	templateUrl: './slides-header.component.html',
	styleUrls: ['./slides-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlidesHeaderComponent extends ComponentBase implements ISlidesHeaderParams {

	//#region FIELDS

	/** Émetteur d'événement pour informer qu'un clic sur le bouton "précédent" a été réalisé. */
	@Output("previousButtonClicked") private readonly moPreviousButtonCLickedEvent = new EventEmitter<void>();
	/** Émetteur d'événement pour informer qu'un clic sur le bouton "suivant" a été réalisé. */
	@Output("nextButtonClicked") private readonly moNextButtonCLickedEvent = new EventEmitter<void>();

	//#endregion

	//#region PROPERTIES

	private mbHasPreviousButton?: boolean;
	/** @implements */
	public get hasPreviousButton(): boolean { return this.mbHasPreviousButton; }
	@Input() public set hasPreviousButton(pbHasPreviousButton: boolean) {
		if (pbHasPreviousButton !== this.mbHasPreviousButton) {
			this.mbHasPreviousButton = pbHasPreviousButton;
			this.detectChanges();
		}
	}

	private mbHasNextButton?: boolean;
	/** @implements */
	public get hasNextButton(): boolean { return this.mbHasNextButton; }
	@Input() public set hasNextButton(pbHasNextButton: boolean) {
		if (pbHasNextButton !== this.mbHasNextButton) {
			this.mbHasNextButton = pbHasNextButton;
			this.detectChanges();
		}
	}

	private msButtonsColor = "dark";
	/** @implements */
	public get buttonsColor(): string { return this.msButtonsColor; }
	@Input() public set buttonsColor(psButtonsColor: string) {
		if (psButtonsColor !== this.msButtonsColor) {
			this.msButtonsColor = psButtonsColor;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	/** Lève un événement pour signaler un clic sur le bouton "précédent". */
	public raisePreviousButtonClicked(): void {
		this.moPreviousButtonCLickedEvent.emit();
	}

	/** Lève un événement pour signaler un clic sur le bouton "suivant". */
	public raiseNextButtonClicked(): void {
		this.moNextButtonCLickedEvent.emit();
	}

	//#endregion

}