import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TourModule } from '../tour/tour.module';
import { TourOptimizationService } from './services/tour-optimization.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		TourModule
	],
	providers: [TourOptimizationService]
})
export class TourOptimizationModule { }