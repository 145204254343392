import { Injectable } from '@angular/core';
import { DateHelper } from '@calaosoft/osapp/helpers/dateHelper';
import { IdHelper } from '@calaosoft/osapp/helpers/idHelper';
import { StringHelper } from '@calaosoft/osapp/helpers/stringHelper';
import { EPrefix } from '@calaosoft/osapp/model/EPrefix';
import { ETimetablePattern } from '@calaosoft/osapp/model/date/ETimetablePattern';
import { DmsFile } from '@calaosoft/osapp/modules/dms/model/DmsFile';
import { IDmsMeta } from '@calaosoft/osapp/modules/dms/model/IDmsMeta';
import { RackHelper } from '@calaosoft/osapp/modules/logistics/task/helpers/rack.helper';
import { IRack } from '@calaosoft/osapp/modules/logistics/task/models/irack';
import { ISignature } from '@calaosoft/osapp/modules/logistics/task/models/isignature';
import { ITask } from '@calaosoft/osapp/modules/logistics/task/models/itask';
import { AppointmentService } from '@calaosoft/osapp/modules/tour/appointment/appointment.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IMerchandising } from '../../tour/models/IMerchandising';

@Injectable()
export class MerchDmsMetaService {

	//#region FIELDS

	// Les labels ne doivent pas dépasser 10 caractères. Voir BUG #11735.
	private static readonly C_SIGNATURE_TASK_DOC_TYPE = "SIGNTASK";
	private static readonly C_SIGNATURE_RACK_DOC_TYPE = "SIGNRACK";
	private static readonly C_CDCLIENT_ATTRIBUTE = "CDCLIENT";
	private static readonly C_DTSIGNATURE_ATTRIBUTE = "DTSIGNATUR";
	private static readonly C_IDTASK_ATTRIBUTE = "IDTASK";
	private static readonly C_IDRACK_ATTRIBUTE = "IDRACK";

	//#endregion

	//#region METHODS

	constructor(private readonly isvcAppointment: AppointmentService) { }

	/** Prépare l'objet `dmsMeta` de la signature d'une tâche ou d'un portant (spécifique à un rendez-vous de type `merchandising`).
	 * @param poDmsFile Fichier du dms qui correspond à la signature.
	 * @param poItem Tâche ou portant dont on veut préparer le databaseMeta de sa signature.
	 */
	public prepareMerchandisingSignatoryMeta(poDmsFile: DmsFile, poSignature: ISignature, poItem: ITask | IRack): Observable<IDmsMeta> {
		const lbIsTask: boolean = poItem._id.startsWith(EPrefix.task);
		const lsAppointmentId = lbIsTask ? AppointmentService.getAppointmentIdFromTaskId(poItem._id) : RackHelper.getAppointIdFromRackId(poItem._id);

		if (!StringHelper.isBlank(poSignature.guid)) {
			return this.isvcAppointment.getAppointmentFromId<IMerchandising>(lsAppointmentId)
				.pipe(
					map((poAppointment?: IMerchandising) => {
						return {
							...poDmsFile.createDmsMeta(poSignature.guid!, lbIsTask ? MerchDmsMetaService.C_SIGNATURE_TASK_DOC_TYPE : MerchDmsMetaService.C_SIGNATURE_RACK_DOC_TYPE),
							attributes: [
								{ name: MerchDmsMetaService.C_CDCLIENT_ATTRIBUTE, value: poAppointment ? poAppointment.clientId : "" },
								{ name: MerchDmsMetaService.C_DTSIGNATURE_ATTRIBUTE, value: DateHelper.transform(poSignature.date, ETimetablePattern.yyyyMMdd) },
								{ name: lbIsTask ? MerchDmsMetaService.C_IDTASK_ATTRIBUTE : MerchDmsMetaService.C_IDRACK_ATTRIBUTE, value: IdHelper.getLastGuidFromId(poItem._id) }
							]
						};
					})
				);
		}
		else
			throw new Error("Signature has no guid.");
	}

	//#endregion

}
