import { NgModule } from '@angular/core';
import { SqlRemoteProvider } from '../../sqlite/services/providers/sql-remote-provider.service';
import { CatalogDiagnosticsModule } from './catalog-diagnostics/catalog-diagnostics.module';
import { CatalogRemoteProviderService } from './services/catalog-remote-provider.service';
import { CatalogService } from './services/catalog.service';

@NgModule({
	imports: [],
	exports: [CatalogDiagnosticsModule],
	declarations: [],
	providers: [
		CatalogService,
		{ provide: SqlRemoteProvider, useClass: CatalogRemoteProviderService, multi: true }
	]
})
export class CatalogModule { }
