<calao-column-list *ngIf="(observableLots.changes$ | async) as lots" [items]="lots">
	<calao-column-list-header>
		<calao-column-list-header-column [size]="5" label="Id lot"></calao-column-list-header-column>
		<calao-column-list-header-column [size]="5" label="Num. séquence"></calao-column-list-header-column>
	</calao-column-list-header>
	<ng-template let-item="$implicit" let-index="index">
		<calao-column-list-row [index]="index">
			<calao-column-list-row-column [size]="5">
				<p>{{ item.id }}</p>
			</calao-column-list-row-column>
			<calao-column-list-row-column [size]="5">
				<p>{{ item.since }}</p>
			</calao-column-list-row-column>
		</calao-column-list-row>
	</ng-template>
</calao-column-list>

<calao-column-list [items]="observableTrackedItems.changes$ | async">
	<calao-column-list-header>
		<calao-column-list-header-column (onSortClicked)="onSortClicked($event)" [size]="5" key="id"
			label="Identifiant"></calao-column-list-header-column>
		<calao-column-list-header-column (onSortClicked)="onSortClicked($event)" [size]="5" key="rev"
			label="Révision"></calao-column-list-header-column>
		<calao-column-list-header-column (onSortClicked)="onSortClicked($event)" [size]="2" key="lotId"
			label="Identifiant de lot"></calao-column-list-header-column>
	</calao-column-list-header>
	<ng-template let-item="$implicit" let-index="index">
		<calao-column-list-row [index]="index">
			<calao-column-list-row-column [size]="5">
				<p>{{item.id}}</p>
			</calao-column-list-row-column>
			<calao-column-list-row-column [size]="5">
				<p>{{item.rev}}</p>
			</calao-column-list-row-column>
			<calao-column-list-row-column [size]="2">
				<p>{{item.lotId}}</p>
			</calao-column-list-row-column>
		</calao-column-list-row>
	</ng-template>
</calao-column-list>