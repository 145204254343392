import { Component, OnInit } from '@angular/core';
import { IdHelper } from '../../../../../../helpers/idHelper';
import { EPrefix } from '../../../../../../model/EPrefix';
import { IContact } from '../../../../../../model/contacts/IContact';
import { ObservableArray } from '../../../../../observable/models/observable-array';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { IRole } from '../../../../../permissions/models/irole';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';
import { IRolesSelectorFieldParams } from './models/iroles-selector-field-params';

@Component({
	selector: 'calao-roles-selector-field',
	templateUrl: './roles-selector-field.component.html',
	styleUrls: ['./roles-selector-field.component.scss'],
})
export class RolesSelectorFieldComponent extends FieldBase<string[], IContact> implements OnInit {
	//#region FIELDS

	/** Paramètres du champs. */
	private readonly moObservableParams = new ObservableProperty<IRolesSelectorFieldParams>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** tableau des identifiants des roles pré-selectionnés. */
	public readonly observablePreSelectedRolesIds = new ObservableArray<string>();

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		protected override readonly isvcForms: FormsService
	) {
		super(isvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.moObservableParams.value = this.props as IRolesSelectorFieldParams;
		if (!this.model._id) // Pas de _id c'est une création, on préselectionne les roles passés en paramètre.
			this.observablePreSelectedRolesIds.resetArray((this.moObservableParams.value).preSelectedRolesIds ?? []);
	}

	public onRolesSelectionChanged(paSelection: IRole[]) {
		this.observableFieldValue.value = paSelection.map((poRole: IRole) => IdHelper.extractIdWithoutPrefix(poRole._id, EPrefix.role));
	}

	//#endregion METHODS

}
