import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { CustomIonSelectEvent } from '../../ionic/models/icustom-ion-input-event';
import { ObserveProperty } from '../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../observable/models/observable-property';
import { ISettingSelectOption } from '../models/isetting-select-option';
import { ISettingSelectParams } from '../models/isetting-select-params';

@Component({
	selector: 'calao-setting-select',
	templateUrl: './setting-select.component.html',
	styleUrls: ['./setting-select.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SettingSelectComponent extends ComponentBase implements ISettingSelectParams {

	//#region FIELDS

	/** Notifie que le paramètre a changé. */
	@Output("onChange") private readonly moSettingChanged = new EventEmitter<ISettingSelectOption>();

	//#endregion

	//#region PROPERTIES

	private msLabel: string;
	/** libellé du paramètre.
	 * @implements
	 */
	public get label(): string { return this.msLabel; }
	@Input() public set label(psLabel: string) {
		if (psLabel !== this.msLabel) {
			this.msLabel = psLabel;
			this.detectChanges();
		}
	}

	/** Permet de savoir si on désactive le sélecteur. */
	@Input() public disabled?: boolean;
	@ObserveProperty<SettingSelectComponent>({ sourcePropertyKey: "disabled" })
	public readonly observableDisabled = new ObservableProperty<boolean>();

	private maValues: ISettingSelectOption[];
	/** Liste des options.
	 * @implements
	 */
	public get values(): ISettingSelectOption[] { return this.maValues; }
	@Input() public set values(paValues: ISettingSelectOption[]) {
		if (!ArrayHelper.areArraysEqual(this.maValues, paValues, (poItemA: ISettingSelectOption, poItemB: ISettingSelectOption) => poItemA.id === poItemB.id)) {
			this.maValues = paValues;
			this.detectChanges();
		}
	}

	private moDefaultValue: ISettingSelectOption;
	/** Option sélectionnée.
	 * @implements
	 */
	public get defaultValue(): ISettingSelectOption { return this.moDefaultValue; }
	@Input() public set defaultValue(poDefaultValue: ISettingSelectOption) {
		if (poDefaultValue !== this.moDefaultValue) {
			this.moDefaultValue = poDefaultValue;
			this.detectChanges();
		}
	}

	//#endregion

	//#region METHODS

	constructor(poChangeDetectorRef: ChangeDetectorRef) {
		super(poChangeDetectorRef);
	}

	/** Option de comparaison pour connaître la valeur sélectionnée.
	 * @param poOptionA
	 * @param poOptionB
	 */
	public compare(poOptionA: ISettingSelectOption, poOptionB: ISettingSelectOption): boolean {
		return poOptionA.id === poOptionB.id;
	}

	/** Notifie que la sélection du paramètre a changé.
	 * @param poEvent Événement associé au changement de sélection du paramètre.
	 */
	public onSelectionChanged(poEvent: Event): void {
		const loEvent: CustomIonSelectEvent<ISettingSelectOption> = poEvent as CustomIonSelectEvent<ISettingSelectOption>;
		if (loEvent.detail.value.id !== this.defaultValue.id)
			this.moSettingChanged.emit(this.defaultValue = loEvent.detail.value);
	}

	//#endregion METHODS

}
