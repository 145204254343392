import { DateHelper } from "../../../helpers/dateHelper";
import { NumberHelper } from "../../../helpers/numberHelper";
import { ETimetablePattern } from "../../../model/date/ETimetablePattern";

export class UserUsageQuota {

	//#region PROPERTIES

	public get percentageLeft(): number {
		const lnPercentage = 100 - +NumberHelper.getPercentage(0, this.quota, this.usage).toFixed(0);
		return NumberHelper.isValidPositive(lnPercentage) ? lnPercentage : 0;
	}

	public get isQuotaExcedeed(): boolean {
		return this.quota <= this.usage;
	}

	public get formattedDate(): string {
		return DateHelper.transform(this.resetUsageDate, ETimetablePattern.dd_MM_yy_slash);
	}

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		public quota: number,
		public usage: number,
		public resetUsageDate: Date
	) { }

	//#endregion METHODS

}