<ion-card>
	<ion-card-header>
		<ion-card-subtitle class="ion-text-center">
			<!-- Acquisitions. -->
			<div class="label">Acquisitions</div>
			<div *ngIf="!hasNominal()" class="value">{{ acquisitions.length }}</div>
			<div *ngIf="!!hasNominal()" class="value">{{ getNominalAcquired() }}/{{ getNominalLength() }}</div>
			<div *ngIf="!!hasNominal() && getNominalExtra() > 0" class="value-detail">
				+{{ getNominalExtra() }} inconnu{{getNominalExtra() > 1 ? "s" : ""}}
			</div>
			<!-- Total. -->
			<ng-container *ngIf="!!displayTotal">
				<div class="label">Total</div>
				<div class="value">{{ getTotalWithDuplicate() }}</div>
			</ng-container>
			<div class="label">Dernière lecture</div>
			<div class="min-txt">{{ lastScan }}</div>
		</ion-card-subtitle>
		<ion-card-title *ngIf="!!quality" class="ion-text-center">
			<div class="label">Qualité de la lecture</div>
			<div class="value">{{ getReadingQuality() }}%</div>
		</ion-card-title>
	</ion-card-header>
</ion-card>