import GrahamScan from '@lucio/graham-scan';
import { ConvertHelper } from "../../../helpers/convertHelper";
import { NumberHelper } from "../../../helpers/numberHelper";
import { ICoordinates } from "../../../model/navigation/ICoordinates";

/** Permet de mettre à disposition des méthodes pour aider à manipuler des données de géolocalisation. */
export abstract class GeolocationHelper {

	//#region METHODS

	private constructor() { }

	/** Calcul la distance entre deux coordonnées (vol d'oiseau) en kilmètres et retourne le résultat, `NaN` si un des paramètres n'est pas bon.
	 * @param pnSourceLatitude Latitude de la coordonnées d'origine (en degrés).
	 * @param pnSourceLongitude Longitude de la coordonnées d'origine (en degrés).
	 * @param pnTargetLatitude Latitude de la coordonnée cible (en degrés).
	 * @param pnTargetLongitude Longitude de la coordonnée cible (en degrés).
	 */
	public static calculateDistanceBetweenCoordinatesKm(pnSourceLatitude: number, pnSourceLongitude: number,
		pnTargetLatitude: number, pnTargetLongitude: number): number {

		if (NumberHelper.areValid([pnSourceLatitude, pnSourceLongitude, pnTargetLatitude, pnTargetLongitude]))
			return ConvertHelper.angularDistanceRadianToKilometers(
				this.calculateAngularDistanceRadian(pnSourceLatitude, pnSourceLongitude, pnTargetLatitude, pnTargetLongitude!)
			);

		else
			return NaN;
	}

	/** Calcul la distance entre deux coordonnées (vol d'oiseau) en kilmètres et retourne le résultat, `NaN` si un des paramètres n'est pas bon.
	 * @param poParam1 Coordonnées d'origine.
	 * @param poParam2 Coordonnées de la cible.
	 */
	public static calculateDistanceUsingCoordinatesKm(poParam1: ICoordinates, poParam2: ICoordinates): number {
		return GeolocationHelper.calculateDistanceBetweenCoordinatesKm(
			poParam1.latitude, poParam1.longitude, poParam2.latitude, poParam2.longitude);
	}

	/** Calcule la distance angulaire en radians depuis des coordonnées en degrés.
	 * @param pnSourceLatitude Latitude de la coordonnées d'origine (en degrés).
	 * @param pnSourceLongitude Longitude de la coordonnées d'origine (en degrés).
	 * @param pnTargetLatitude Latitude de la coordonnée cible (en degrés).
	 * @param pnTargetLongitude Longitude de la coordonnée cible (en degrés).
	 */
	private static calculateAngularDistanceRadian(pnSourceLatitude: number, pnSourceLongitude: number, pnTargetLatitude: number, pnTargetLongitude: number): number {
		return Math.acos(
			(this.getSinRadianFromDegree(pnSourceLatitude) * this.getSinRadianFromDegree(pnTargetLatitude)) +
			(this.getCosRadianFromDegree(pnSourceLatitude) * this.getCosRadianFromDegree(pnTargetLatitude) * this.getCosRadianFromDegree(pnTargetLongitude - pnSourceLongitude))
		);
	}

	/** Récupère le sinus en radian à partir d'une valeur en degrés.
	 * @param pnDegreeValue Valeur en degrés.
	 */
	private static getSinRadianFromDegree(pnDegreeValue: number): number {
		return Math.sin(ConvertHelper.degreeToRadian(pnDegreeValue));
	}

	/** Récupère le cosinus en radian à partir d'une valeur en degrés.
	 * @param pnDegreeValue Valeur en degrés.
	 */
	private static getCosRadianFromDegree(pnDegreeValue: number): number {
		return Math.cos(ConvertHelper.degreeToRadian(pnDegreeValue));
	}

	/** Détermine le niveau de zoom approprié en fonction de la distance maximale entre deux points.
	 * @param pnMaxDstInKM La distance maximale entre les POI.
	 * @param pnZoomLv Zoom par défault.
	 * @param pnCurrScreenSizeInKm Taille en largeur de l'écran en kilomètres (sur IPhone SE), `2048` par défaut.
	 * @returns Le niveau de zoom approprié.
	 */
	public static getZoom(
		pnMaxDstInKM: number,
		pnZoomLv: number = 4,
		pnCurrScreenSizeInKm: number = 2048
	): number {
		const lnHalfCurrScreenSizeInKm: number = pnCurrScreenSizeInKm * 0.5;
		return pnMaxDstInKM <= pnCurrScreenSizeInKm && pnMaxDstInKM >= lnHalfCurrScreenSizeInKm ?
			pnZoomLv : this.getZoom(pnMaxDstInKM, pnZoomLv + 1, lnHalfCurrScreenSizeInKm);
	}

	/** Calcule les coordonnées centrales pour la carte.
 * @param pnLen Le nombre de POI.
 * @param pnSumLat La somme des longitudes de tous les points d'intérêt.
 * @param pnSumLon La somme des latitudes de tous les points d'intérêt.
 * @returns Les coordonnées centrales.
 */
	public static getCenter(pnLen: number, pnSumLat: number, pnSumLon: number): ICoordinates {
		return { latitude: pnSumLat / pnLen, longitude: pnSumLon / pnLen };
	};

	/** Calcule les coordonnées des points appartenant à l'enveloppe convexe.
	 * @param paPoints  Tous les points du polygone.
	 * @returns Les coordonnées des points.
	 */
	public static getConvexHull(paPoints: ICoordinates[]): ICoordinates[] {
		const loGrahamScan = new GrahamScan();
		loGrahamScan.setPoints(
			paPoints.map((poCoords: ICoordinates) => { return [poCoords.latitude, poCoords.longitude]; })
		);

		return loGrahamScan.getHull().map((paCoord: number[]) => {
			return { latitude: paCoord[0], longitude: paCoord[1] };
		});
	}

	//#endregion METHODS

}