/** Sert à stocker un consentement */
export class ConsentObject {

	//#region PROPERTIES

	/** Date du consentement */
	public date: Date;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(pdDate: Date) {
		this.date = pdDate;
	}

	//#endregion METHODS

}