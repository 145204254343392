import { ResolveModel } from "../../../utils/models/decorators/resolve-model.decorator";
import { BarecodeReaderSettings } from "./barecode-reader-settings";
import { ILogisticsSettings } from "./ilogistics-settings";

export class LogisticsSettings implements ILogisticsSettings {

	//#region PROPERTIES

	/** @implements */
	@ResolveModel(BarecodeReaderSettings)
	public barcodeReader = new BarecodeReaderSettings();

	//#endregion

}
