import { ERackStatus } from '../../models/ERackStatus';
import { IDeliveryRackPack } from './idelivery-rack-pack';

export class DeliveryRackPack implements IDeliveryRackPack {

	//#region PROPERTIES

	/** @implements */
	public readonly code: string;
	/** @implements */
	public readonly readDate: Date;
	/** @implements */
	public readonly reasonId?: string;

	public rackStatus: ERackStatus;

	//#region METHODS

	constructor(psCode: string, pdReadDate: Date, psReasonId?: string) {
		this.code = psCode;
		this.readDate = pdReadDate;
		this.reasonId = psReasonId;
	}

	/** Retourne `true` si l'élément en paramètre correspond à l'instance courante en fonction de ses attributs, `false` sinon.
	 * @param poItem Élément à comparer avec l'instance courante.
	 */
	public matches<T extends { code: string }>(poItem: T): boolean {
		return poItem?.code === this.code;
	}

	//#endregion

}