import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomerTourModule } from '../../customer-tour/customer-tour.module';
import { SqlRemoteProvider } from '../../sqlite/services/providers/sql-remote-provider.service';
import { StockRemoteProviderService } from './services/stock-remote-provider.service';
import { StockService } from './services/stock.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		CustomerTourModule
	],
	providers: [
		StockService,
		{ provide: SqlRemoteProvider, useClass: StockRemoteProviderService, multi: true }
	]
})
export class StockModule { }
