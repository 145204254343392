import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/* Transforme une date en date avec une localité spécifiée (France, ...) et son format souhaité.
 * Usage:
 *   variableBindée | dateWithLocale: locale : format
 * Exemple:
 *   {{ variableBindée | dateWithLocale: 'fr-FR' : 'EEE dd MMMM yyyy' }}
 *   => lun. 14 mars 1994
*/
@Pipe({ name: 'dateWithLocale' })
export class DateWithLocalePipe implements PipeTransform {

	//#region METHODS

	constructor() { }

	/** Transforme une date représentée en forme de chaîne de caractères en date avec un pattern et une localisation précise.
	 * Usage: variableBindée | dateWithLocale: locale : format
	 * Exemple: {{ variableBindée | dateWithLocale: 'fr-FR' : 'EEE dd MMMM yyyy' }}
	 * => lun. 14 mars 1994
	 * @param poValue date à transformer.
	 * @param psLocale localisation pour la date (anglais, française, ...).
	 * @param psPattern pattern de date pour le rendu (dd MM YYYY, ...).
	 */
	public transform(poValue: Date | number | string | null, psLocale: string, psPattern: string): string | null {
		return new DatePipe(psLocale).transform(poValue, psPattern);
	}

	//#endregion
}