import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '../../../../services/platform.service';
import { ModalComponentBase } from '../../../modal/model/ModalComponentBase';
import { IReadingModalDebug } from './models/IReadingModalDebug';

@Component({
	templateUrl: './reading-modal-debug.component.html',
})
export class ReadingModalDebug extends ModalComponentBase<void> {

	//#region PROPERTIES

	@Input() public params: IReadingModalDebug;

	public duration: number;

	//#endregion

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		poPlatform: PlatformService,
		poChangeDetectorRef: ChangeDetectorRef
	) {
		super(poModalCtrl, poPlatform, poChangeDetectorRef);
	}

	public validate(): void {
		this.close(undefined);
	}

	//#endregion
}