import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { StringHelper } from '../../../../../../helpers/stringHelper';
import { ICoordinates } from '../../../../../../model/navigation/ICoordinates';
import { IGeolocData } from '../../../../../../model/navigation/IGeolocData';
import { NavigationService } from '../../../../../../services/navigation.service';
import { ObservableArray } from '../../../../../observable/models/observable-array';
import { ObservableProperty } from '../../../../../observable/models/observable-property';
import { FieldBase } from '../../../../models/FieldBase';
import { FormsService } from '../../../../services/forms.service';

interface IPrintGeoloc extends IGeolocData {
	distance?: number
}
@Component({
	templateUrl: './locationField.component.html',
	styleUrls: ['./inputs.component.scss', 'locationField.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationFieldComponent extends FieldBase<string> implements OnInit, AfterViewInit {

	//#region FIELDS
	private static readonly C_MAX_AUTOCOMPLETE_PROPOSAL = 5;
	private readonly moCurrentPosAsync: Promise<Position>;
	public moParams: {
		geocodingSuccessLabel: string;
		forceDisplaying: boolean;
		longitudeKey: string;
		latitudeKey: string;
		readOnly?: boolean;
	};
	@ViewChild("address") private moAddress?: ElementRef<HTMLIonInputElement>;
	//#endregion

	//#region PROPERTIES
	public readonly hasPlaceCoords = new ObservableProperty<boolean>(false);

	public readonly observableItems = new ObservableArray<IPrintGeoloc>();
	//#endregion

	//#region METHODS
	constructor(
		psvcForms: FormsService,
		private readonly isvcNavigation: NavigationService
	) {
		super(psvcForms);
		this.moCurrentPosAsync = Geolocation.getCurrentPosition();
	}

	public override ngOnInit(): void {
		super.ngOnInit();
		this.moParams = this.to.data;
		this.hasPlaceCoords.value = !!this.model[this.moParams.latitudeKey];
	}

	public override ngAfterViewInit(): void {
		super.ngAfterViewInit();
		if (this.moAddress) {
			(this.moAddress as any).value = this.fieldValue ?? "";
		}
	}

	public async inputChanged(): Promise<void> {

		this.fieldValue = (this.moAddress as any)?.value ?? "";

		this.model[this.moParams.latitudeKey] = undefined;
		this.model[this.moParams.longitudeKey] = undefined;
		this.hasPlaceCoords.value = false;

		if (StringHelper.isValid(this.fieldValue) && this.fieldValue.length >= LocationFieldComponent.C_MAX_AUTOCOMPLETE_PROPOSAL) {
			const laSearchPos: IGeolocData[] = await this.isvcNavigation
				.getGeolocDataByAddress(this.fieldValue).toPromise();

			for (let lnIndex = 0; lnIndex < laSearchPos.length; ++lnIndex) {
				const loSearchItem: IGeolocData = laSearchPos[lnIndex];
				const lnDistanceToThisLoc: number = this.getDistance(
					{ latitude: (await this.moCurrentPosAsync).coords.latitude, longitude: (await this.moCurrentPosAsync).coords.longitude },
					{ latitude: loSearchItem.latitude as number, longitude: loSearchItem.longitude as number }
				) * 0.001; //  metre --> km
				const loBuffer: IPrintGeoloc = loSearchItem;
				loBuffer.distance = Math.trunc(lnDistanceToThisLoc);

				if (!this.observableItems.map((poItem: IPrintGeoloc) => poItem.zipCode).includes(loBuffer.zipCode)) {	// 5 choix maximun dans l'autocomplete
					if (this.observableItems.length > LocationFieldComponent.C_MAX_AUTOCOMPLETE_PROPOSAL - 1)
						this.observableItems.splice(lnIndex, 1);
					this.observableItems.push(loBuffer);  // chaque item se voit ajouter un champs distance pour l'affichage
				}
			}

			this.observableItems.sort((poItem1: IPrintGeoloc, poItem2: IPrintGeoloc) => {
				return poItem1.distance - poItem2.distance;
			});
		}
		else {
			this.resetSuggestions();
		}
	}

	public onBlurEvent(): void {
		setTimeout(_ => this.resetSuggestions(), 200); // Utile pour des raisons d'ordre d'exécution.
	}

	private resetSuggestions(): void {
		this.observableItems.clear();
	}

	public selected(poItem: IPrintGeoloc): void {
		this.resetSuggestions();
		(this.moAddress as any).value = `${poItem.street} ${poItem.zipCode} ${poItem.city}`;

		this.model[this.moParams.latitudeKey] = poItem.latitude;
		this.model[this.moParams.longitudeKey] = poItem.longitude;

		this.fieldValue = `${poItem.street} ${poItem.zipCode} ${poItem.city}`;
		if (this.model[this.moParams.latitudeKey]) { // Parfois l'api du gouv ne donne pas les coordonnées pour les coins perdus
			this.hasPlaceCoords.value = true;
		}
	}

	// Donne la distance entre deux points à partir des coordonnés
	// TODO utiliser : GeolocationHelper.calculateDistanceBetweenCoordinatesKm à la place de créer une fonction
	private getDistance(
		poFrom: ICoordinates,
		poTo: ICoordinates
	): number {
		const C_EARTH_RADIUS = 6378137;
		const lfToRad = (lnValue: number) => (lnValue * Math.PI) / 180;
		const lfRobustAcos = (lnValue: number) => lnValue > 1 ? 1 : lnValue < -1 ? -1 : lnValue;

		return (
			Math.acos(
				lfRobustAcos(
					// eslint-disable-next-line no-mixed-operators
					Math.sin(lfToRad(poTo.latitude)) * Math.sin(lfToRad(poFrom.latitude)) +
					Math.cos(lfToRad(poTo.latitude)) *
					// eslint-disable-next-line no-mixed-operators
					Math.cos(lfToRad(poFrom.latitude)) *
					Math.cos(lfToRad(poFrom.longitude) - lfToRad(poTo.longitude))
				)
			) * C_EARTH_RADIUS
		);
	};

	//#endregion
}