import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { UiMessageService } from '../../../services/uiMessage.service';
import { HeaderModule } from '../../header/header.module';
import { BluetoothModule } from '../bluetooth/bluetooth.module';
import { TslService } from '../devices/tsl/tsl.service';
import { DiagnosticsLogisticsPage } from './diagnostics-page.component';

@NgModule({
	declarations: [DiagnosticsLogisticsPage],
	imports: [
		CommonModule, IonicModule, HeaderModule, BluetoothModule
	],
	providers: [UiMessageService, TslService]
})
export class LogisticsDiagnosticsModule { }