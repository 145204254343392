import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, mergeMap } from 'rxjs/operators';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { UserHelper } from '../../../helpers/user.helper';
import { EPrefix } from '../../../model/EPrefix';
import { ActivePageManager } from '../../../model/navigation/ActivePageManager';
import { EDatabaseRole } from '../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../model/store/IDataSource';
import { IStoreDataResponse } from '../../../model/store/IStoreDataResponse';
import { IStoreDocument } from '../../../model/store/IStoreDocument';
import { Store } from '../../../services/store.service';
import { IDataSourceRemoteChanges } from '../../store/model/IDataSourceRemoteChanges';
import { IDocumentFields } from '../models/idocument-fields';
import { IDocumentStatus } from '../models/idocument-status';
import { IUserStatus } from '../models/iuser-status';

@Injectable({ providedIn: "root" })
export class DocumentStatusService {

	//#region METHODS

	constructor(private isvcStore: Store) { }

	/** Crée un objet de type `IDocumentStatus` à partir d'une donnée issue de la base de données.
	 * @param poDocument Donnée issue de la base de données avec laquelle créer un document de statut de lecture.
	 */
	private createDocumentStatus(poDocument: IStoreDocument): IDocumentStatus {
		const loDocumentStatus: IDocumentStatus = {
			_id: this.createDocumentStatusId(poDocument),
			docId: poDocument._id,
			userStatus: {}
		};

		return loDocumentStatus;
	}

	/** Crée un identifiant pour un document de type `IDocumentStatus`.
	 * @param poDocument Donnée issue de la base de données ou identifiant de la donnée.
	 */
	private createDocumentStatusId(poDocument: IStoreDocument | string): string {
		return `${EPrefix.documentStatus}${typeof poDocument === "string" ? poDocument : poDocument._id}`;
	}

	/** Récupère un document de staut de lecture associé à une donnée.
	 * @param poDocument Donnée dont on veut récupérer le statut de lecture.
	 */
	private getDocumentStatus$(poDocument: IStoreDocument): Observable<IDocumentStatus> {
		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				key: this.createDocumentStatusId(poDocument),
				include_docs: true
			}
		};

		return this.isvcStore.getOne<IDocumentStatus>(loDataSource, false);
	}

	/** Récupère les statuts des documents.
	 * @param paDocuments Liste documents dont il faut récupérer les statuts.
	 * @param pbLive Indique si on doit faire une récupération continue des statuts.
	 */
	private getDocumentStatuses$(poDocument?: IStoreDocument[], pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<IDocumentStatus[]> {
		const loDataSource: IDataSourceRemoteChanges = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			live: pbLive,
			viewParams: {
				include_docs: true,
				keys: poDocument?.map((poItem: IStoreDocument) => this.createDocumentStatusId(poItem))
			},
			remoteChanges: !!poActivePageManager,
			activePageManager: poActivePageManager
		};

		return this.isvcStore.get<IDocumentStatus>(loDataSource);
	}

	/** Récupère les statuts de lecture par tableau de documents (ou tous si tableau non renseigné/vide).
	 * @param paDocuments Tableau des documents dont il faut récupérer les statuts de lecture, récupération générique par défaut.
	 * @param pbLive Indique si la récupération est continue ou non, `false` par défaut.
	 */
	public getDocumentsUserStatusesById$(paDocuments: (IStoreDocument & IDocumentFields)[] = [], pbLive?: boolean, poActivePageManager?: ActivePageManager): Observable<Map<string, IUserStatus | undefined>> {
		return this.getDocumentStatuses$(paDocuments, pbLive, poActivePageManager)
			.pipe(
				map((paDocumentStatuses: IDocumentStatus[]) => {
					const loDocumentsUserStatusesById = new Map<string, IUserStatus | undefined>(
						paDocumentStatuses.map((poDocumentStatus: IDocumentStatus) => [poDocumentStatus.docId, poDocumentStatus.userStatus[UserHelper.getUserId()]])
					);

					paDocuments.forEach((poDocument: IStoreDocument & IDocumentFields) => {
						if (!loDocumentsUserStatusesById.has(poDocument._id))
							loDocumentsUserStatusesById.set(poDocument._id, poDocument.authorId === UserHelper.getUserContactId() ? { docRev: poDocument._rev, status: "read", statusUpdate: new Date().toISOString() } : undefined);
					});

					return loDocumentsUserStatusesById;
				})
			);
	}

	/** Change le statut de lecture du document par l'utilisateur courant.
	 * @param poDocument Donnée qu'il faut marquer comme non lue.
	 */
	public changeReadSatus$(poDocument: IStoreDocument, psStatus: "read" | "notRead"): Observable<boolean> {
		return this.getDocumentStatus$(poDocument)
			.pipe(
				mergeMap((poDocumentStatus?: IDocumentStatus) => {
					if (!poDocumentStatus)
						poDocumentStatus = this.createDocumentStatus(poDocument);

					poDocumentStatus.userStatus[UserHelper.getUserId()] = {
						docRev: poDocument._rev,
						status: psStatus,
						statusUpdate: new Date().toISOString()
					};

					return this.isvcStore.put(poDocumentStatus, ArrayHelper.getLastElement(this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace)));
				}),
				map((poResponse: IStoreDataResponse) => poResponse.ok),
				defaultIfEmpty(false),
				catchError((poError: any) => { console.warn(`RS.S:: Échec du marquage '${psStatus}' : `, poError); return of(false); })
			);
	}

	//#endregion

}