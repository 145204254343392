import { Injectable } from '@angular/core';
import { IReason } from '@calaosoft/osapp/modules/logistics/reason/models/IReason';
import { ReasonService } from '@calaosoft/osapp/modules/logistics/reason/services/reason.service';
import { Store } from '@calaosoft/osapp/services/store.service';
import { from, Observable, of } from 'rxjs';
import { map, mapTo, mergeMap, take, toArray } from 'rxjs/operators';
import { ANOMALY_PARENT_REASON_ID, NO_PICKING_REASON_ID, PACK_ANOMALY_PARENT_REASON_ID } from '../../../../app.constants';
import { IReasonData } from '../pages/returns/models/ireason-data';

@Injectable()
export class MerchReasonService extends ReasonService {

	//#region FIELDS

	/** Regex qui permet d'identifier le motif racine de défaut. */
	private static readonly C_DEFAULT_ROOT_REASON_REGEX = /d[eé]faut/i;

	//#endregion

	//#region METHODS

	constructor(psvcStore: Store) {
		super(psvcStore);
	}

	/** Retourne `true` si le motif est le motif racine de défaut, `false` sinon.
	 * @param poReason Motif à tester.
	 */
	public static isDefaultRootReason(poReason: IReason): boolean {
		return !!poReason.childIds && this.C_DEFAULT_ROOT_REASON_REGEX.test(poReason.description ?? "");
	}

	/** Récupère les motifs de défaut (motifs enfants du motif racine de défaut), tableau vide si non trouvés. */
	public getDefaultReasonChildren(): Observable<IReason[]> {
		return this.getReasonChildren(ANOMALY_PARENT_REASON_ID)
			.pipe(mergeMap((paReasons: IReason[]) => {
				const loReason: IReason | undefined = paReasons.find((poReason: IReason) => MerchReasonService.isDefaultRootReason(poReason));
				return loReason ? this.getReasonChildren(loReason) : of([]);
			}));
	}

	/** Récupère les motifs de non prélèvement. */
	public getNoPickingReasonsAsync(): Promise<IReason[]> {
		return this.getReasonChildren(NO_PICKING_REASON_ID)
			.pipe(take(1))
			.toPromise();
	}

	/** Retourne `true` si le motif est un motif de non prélèvement, sinon `false`.
	 * @param poReason La raison ou l'identifiant de la raison qui doit être vérifiée.
	 */
	public isNoPickingReasonAsync(poReason?: IReason | string): Promise<boolean> {
		if (!poReason)
			return Promise.resolve(false);
		else {
			return this.getNoPickingReasonsAsync()
				.then((paNoPickingReasons: IReason[]) => {
					const lsReasonId = typeof poReason === "string" ? poReason : poReason._id;
					return paNoPickingReasons.some((poNoPickingReason: IReason) => poNoPickingReason._id === lsReasonId);
				});
		}
	}

	/** Retourne `true` si le motif est un motif enfant de défaut, sinon `false`.
	 * @param poReason Motif à vérifier.
	 */
	public isDefaultChildReason(poReason?: string | IReason): Observable<boolean> {
		if (!poReason)
			return of(false);
		else {
			return this.getDefaultReasonChildren()
				.pipe(map((paDefaultChildren: IReason[]) => this.isReasonIn(paDefaultChildren, poReason)));
		}
	}

	/** Retourne `true` si le motif est présent dans le tableau source, sinon `false`.
	 * @param paReasons Tableau des motifs source sur lequel s'appuyer pour vérifier le motif.
	 * @param poReasonToTest Motif ou identifiant du motif à vérifier.
	 */
	public isReasonIn(paReasons: IReason[], poReasonToTest?: string | IReason): boolean {
		if (!poReasonToTest)
			return false;
		else {
			const lsReasonId: string = typeof poReasonToTest === "string" ? poReasonToTest : poReasonToTest._id;
			return paReasons.some((poChild: IReason) => poChild._id === lsReasonId);
		}
	}

	/** Retourne la liste des ids de motif dont le comportement de rejet est vrai. */
	public getRejectedReasonsAsync(): Promise<IReason[]> {
		return this.getParentChildrenReasons$(PACK_ANOMALY_PARENT_REASON_ID).pipe(
			map((paReasons: IReason[]) => {
				return paReasons.filter((poReason: IReason) => poReason.behavior?.reject);
			})
		).toPromise();
	}

	/** Récupère une map contenant un motif en fonction de son identifiant.
	 * @param paReasonIds Tableau des identifiant de motifs à récupérer.
	 */
	public getReasonDataByReasonId$(paReasonIds: string[]): Observable<Map<string, IReasonData>> {
		return this.getReasons(paReasonIds)
			.pipe(
				mergeMap((paReasons: IReason[]) => {
					const loReasonDataByReasonId = new Map<string, IReasonData>();

					return from(paReasons)
						.pipe(
							mergeMap((poReason: IReason) => this.addDefaultFlagToReasonIfNeeded(poReason, loReasonDataByReasonId)),
							toArray(),
							mapTo(loReasonDataByReasonId)
						);
				})
			);
	}

	/** Ajoute dans la map en paramètre le flag "défaut" au motif associé si ce motif est motif enfant de défaut.
	 * @param poReason Motif dont il faut ajouter le flag si nécessaire.
	 * @param poReasonDataByReasonId Map contenant un identifiant de motif en clé et le motif associé avec flag en valeur.
	 */
	private addDefaultFlagToReasonIfNeeded(poReason: IReason, poReasonDataByReasonId: Map<string, IReasonData>): Observable<void> {
		return this.isDefaultChildReason(poReason)
			.pipe(
				map((pbIsDefault: boolean) => {
					if (!poReasonDataByReasonId.has(poReason._id))
						poReasonDataByReasonId.set(poReason._id, { ...poReason, isDefaultReason: pbIsDefault });
				})
			);
	}

	//#endregion

}