<div class="flex-align">
	<!-- Bouton précédent. -->
	<ion-button [style.visibility]="!hasPreviousButton ? 'hidden': ''" [color]="buttonsColor" fill="clear"
		(click)="raisePreviousButtonClicked()" class="left-btn">
		<ion-icon slot="start" name="chevron-back-outline"></ion-icon>
	</ion-button>
	<!-- Contenu du header dynamique en fonction de la page appelante. -->
	<ion-item lines="none">
		<div class="center-header">
			<ng-content>
			</ng-content>
		</div>
	</ion-item>
	<!-- Bouton suivant. -->
	<ion-button [style.visibility]="!hasNextButton ? 'hidden': ''" [color]="buttonsColor" fill="clear"
		(click)="raiseNextButtonClicked()" class="right-btn">
		<ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
	</ion-button>
</div>