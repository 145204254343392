import { Transform, TransformFnParams } from "class-transformer";
import { ArrayHelper } from "../../../helpers/arrayHelper";
import { StringHelper } from "../../../helpers/stringHelper";
import { IContact } from "../../../model/contacts/IContact";
import { IGroup } from "../../../model/contacts/IGroup";
import { EConversationType } from "../../../model/conversation/EConversationType";
import { EMessageOrigin } from "../../../model/conversation/EMessageOrigin";
import { IMessage } from "../../../model/conversation/IMessage";
import { IParticipant } from "../../../model/conversation/IParticipant";
import { StoreDocument } from "../../store/model/store-document";
import { ResolveModel } from "../../utils/models/decorators/resolve-model.decorator";
import { MessageAttachment } from "./message-attachment";

export class Message extends StoreDocument implements IMessage {

	//#region PROPERTIES
	public override _id: string;
	public body: string;
	public createDate: Date;
	public type = EConversationType.message;
	public origin: EMessageOrigin = EMessageOrigin.application;
	public isSyncToServer?: boolean;
	@Transform((poParams: TransformFnParams) => ArrayHelper.hasElements(poParams.value) ? poParams.value : undefined, { toPlainOnly: true })
	@ResolveModel(MessageAttachment)
	public attachments: MessageAttachment[] = [];
	public isAutoGenerated?: boolean;
	public senderContactPath?: string;
	public participant?: IParticipant<IContact | IGroup>;

	public get canSend(): boolean {
		return !StringHelper.isBlank(this.body) || ArrayHelper.hasElements(this.attachments);
	}

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: Omit<IMessage, "type" | "createDate" | "origin">) {
		super(poData);
	}

	//#endregion METHODS

}
