import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, Input, OnChanges, Optional, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonInfiniteScroll } from '@ionic/angular';
import { from } from 'rxjs';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { DateHelper } from '../../../../helpers/dateHelper';
import { IAcquisitionSummary } from '../../IAcquisitionSummary';
import { IRfidAcquisition } from '../../devices/models/IRfidAcquisition';
import { ReadingConfigService } from '../ReadingConfig.service';
import { IAcquisitionQualityAlgorithm } from '../models/IAcquisitionQualityAlgorithm';
import { IReadingQualityAlgorithm } from '../models/IReadingQualityAlgorithm';

/** Vue "avancée" pour la Lecture RFID. */
@Component({
	selector: "calao-logistics-advanced-repr",
	templateUrl: './advanced-reading-representation.component.html',
	styleUrls: ['./advanced-reading-representation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedReadingRepresentationComponent extends ComponentBase implements OnChanges, AfterViewInit {

	//#region FIELDS

	/** Nombre de composants créés par lot dans le infinite scroll. */
	private static readonly C_CUSTOM_COMPONENTS_LOAD_SIZE = 20;

	/** Code de l'acquisition qui est affiché avec des informations complémentaires. */
	private msDisplayedTag = "";

	/** Factory pour le composant spécifique qui doit être utilisé pour afficher des informations sur un code-barres acquis. */
	private moScanSummaryComponentFactory: ComponentFactory<IAcquisitionSummary>;

	/** Boolean à `true` si la vue du composant est initialisée, sinon `false`. */
	private mbHasViewInit = false;

	/** Dernier élément chargé dans le ion dynamic scroll. */
	private mnInfiniteScrollLastLoaded = 0;

	/** Permet d'afficher la liste des composants custom de l'application, chargés dynamiquement. */
	@ViewChild(IonInfiniteScroll) private moInfiniteScroll: IonInfiniteScroll;

	/** Tag HTML hôte pour le composant qui décrit de manière sommaire un élément scanné. */
	@ViewChild("scanArticlesSummaries", { static: false, read: ViewContainerRef }) private moScanSummariesHost: ViewContainerRef;

	//#endregion

	//#region PROPERTIES

	@Input() public acquisitions: Array<IRfidAcquisition>;
	@Input() public quality: IReadingQualityAlgorithm & IAcquisitionQualityAlgorithm;
	@Input() public nominal: Set<string>;
	/** Callback à appliquer sur un code pour l'afficher à l'utilisateur, si vide, affiche le code. */
	@Input() public displayCode: (psCode: string) => string;
	/** Si non `undefined` affiche un compteur en face de chaque code. */
	@Input() public counter: Map<string, number>;
	/** Paramètre indiquant si le bouton de recherche est disponible. */
	@Input() public hasSearchButton;
	/** Dernier code-barres scanné. */
	@Input() public lastScan: string;

	//#endregion

	//#region METHODS

	constructor(
		poChangeDetector: ChangeDetectorRef,
		private isvcRouter: Router,
		private isvcRoute: ActivatedRoute,
		@Optional() public isvcReadingConfig: ReadingConfigService,
		poResolver: ComponentFactoryResolver,
	) {
		super(poChangeDetector);

		this.moScanSummaryComponentFactory = poResolver.resolveComponentFactory(this.isvcReadingConfig.barcodeDetail());
	}

	public override ngAfterViewInit(): void {
		super.ngAfterViewInit();

		this.mbHasViewInit = true;

		if (this.hasSpecificSummaryComponent())
			this.loadNextWage();
	}

	public ngOnChanges(): void {
		console.debug("ADVANCED-READING-REP.C::OnChanges().");

		if (this.mbHasViewInit && this.hasSpecificSummaryComponent()) {
			this.restartInfiniteScroll();	// Si les données ont changé, l'infinite scroll repart de 0.
		}
	}

	/** Supprime tous les éléments de l'infinite scroll et recharge les premiers composants. */
	private restartInfiniteScroll(): void {
		this.moScanSummariesHost.clear();
		this.moInfiniteScroll.disabled = false;
		this.mnInfiniteScrollLastLoaded = 0;
		this.loadNextWage();
	}

	/** Charge les prochains composant du Ion infinite scroll. */
	public loadNextWage(): void {
		console.debug("ADVANCED-READING-REP.C::Création de nouveaux composants dans l'infinite scroll.");

		const laAcquisitions: IRfidAcquisition[] = this.getAcquisitionsByDate(this.acquisitions);

		/** Dernier élément qui sera chargé après cette vague. */
		const lnWageEnd: number = (this.mnInfiniteScrollLastLoaded + AdvancedReadingRepresentationComponent.C_CUSTOM_COMPONENTS_LOAD_SIZE > laAcquisitions.length) ?
			laAcquisitions.length : (this.mnInfiniteScrollLastLoaded + AdvancedReadingRepresentationComponent.C_CUSTOM_COMPONENTS_LOAD_SIZE);

		for (let i = this.mnInfiniteScrollLastLoaded; i < lnWageEnd; i++) {
			// Créé un composant sommaire et l'injecte dans le DOM.
			const loCurrent: IRfidAcquisition = laAcquisitions[i];

			const loComponentRef: ComponentRef<IAcquisitionSummary> = this.moScanSummariesHost.createComponent(this.moScanSummaryComponentFactory);
			loComponentRef.instance.code = loCurrent.code;
			loComponentRef.instance.numberOfScans = loCurrent.numberOfScans;
		}

		this.mnInfiniteScrollLastLoaded = lnWageEnd;

		if (lnWageEnd === laAcquisitions.length)	// Si tous les éléments sont affichés, on désactive l'infinite scroll.
			this.moInfiniteScroll.disabled = true;

		this.moInfiniteScroll.complete();
	}

	/** Retourne `true` si l'application définit un composant pour afficher le sommaire d'une acquisition. */
	public hasSpecificSummaryComponent(): boolean {
		return this.isvcReadingConfig && !!this.isvcReadingConfig.barcodeDetail();
	}

	/** Retourne la liste des acquisitions ordonnée de l'acquisition la plus récente à la plus ancienne. */
	private getAcquisitionsByDate(paAcquisitions: IRfidAcquisition[]): IRfidAcquisition[] {
		return [...paAcquisitions].sort((poElementA: IRfidAcquisition, poElementB: IRfidAcquisition): number => {
			/** Retourne la date maximale de l'élément A. */
			const lnMaxA: number = +DateHelper.getMax(poElementA.taken);

			/** Retourne la date maximale de l'élément B. */
			const lnMaxB: number = +DateHelper.getMax(poElementB.taken);

			return (lnMaxB ? lnMaxB : 0) - (lnMaxA ? lnMaxA : 0);
		});
	}

	/** Fonction appelée lors du clique sur le bouton de paramètre d'une ligne. */
	public onParameterClicked(poAcq: IRfidAcquisition): void {
		this.msDisplayedTag = this.msDisplayedTag !== poAcq.code ? poAcq.code : "";
		this.detectChanges();
	}

	public getReadingQuality(): number {
		return this.quality.getReadingQuality();
	}

	public getAcquisitionQuality(poAcquisition: string): number {
		return this.quality.getAcquisitionQuality(poAcquisition);
	}

	/** Retourne `true` si un algorithme de qualité est présent. */
	public hasQualityAlgo(): boolean {
		return !!this.quality;
	}

	/** Change un code à afficher en une valeur pour l'utilisateur. */
	public codeToDisplay(psCode: string): string {
		if (!this.displayCode)
			return psCode;
		else
			return this.displayCode(psCode);
	}

	/** Retourne les acquisitions triées. */
	public getAcquisitions(): string[] {
		const loAllData = new Set<string>(this.nominal);
		this.acquisitions.forEach((poElement: IRfidAcquisition) => loAllData.add(poElement.code));
		return this.sort(Array.from(loAllData));
	}

	/** Retourne un tableau ordonné, avec en haut,
	 * ceux qui ne sont pas dans la courante, mais sont dans la nominale,
	 * ceux avec la plus faible qualité.
	 */
	public sort(poKeys: string[]): string[] {
		return poKeys
			.sort((psAcq: string, psOther: string) => {
				if (this.hasNominal()) { // S'il y a une nominale, on se base sur sa présence/absence.
					if (this.isInNominal(psOther) && !this.isInCurrent(psAcq))
						return -1;

					if (this.isInNominal(psAcq) && !this.isInCurrent(psOther))
						return 1;
				}

				// On se base sur la qualité.
				if (this.hasQualityAlgo())
					return this.quality.getAcquisitionQuality(psAcq) - this.quality.getAcquisitionQuality(psOther);

				return 0;
			});
	}

	public hasNominal(): boolean {
		return !!this.nominal;
	}

	public isInCurrent(psCode: string): boolean {
		return this.acquisitions.some((psCurrentCode: IRfidAcquisition) => psCurrentCode.code === psCode);
	}

	public isInNominal(psCode: string): boolean {
		return this.nominal.has(psCode);
	}

	public displayCounter(): boolean {
		return !!this.counter;
	}

	/** Va à la page de Recherche avec en recherchant l'élément passé en paramètre. */
	public goToSearchPage(psCode: string): void {
		from(this.isvcRouter.navigate(["../search"], { queryParams: { wanted: psCode }, relativeTo: this.isvcRoute })).subscribe();
	}

	//#endregion

}