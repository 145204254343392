import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { NumberHelper } from '../../../../../helpers/numberHelper';
import { StoreHelper } from '../../../../../helpers/storeHelper';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { IActionButtonFieldMapParams } from '../../../../../model/forms/actionButtonFields/IActionButtonFieldMapParams';
import { IGoogleMapParams } from '../../../../../model/googleMap/IGoogleMapParams';
import { IAddress } from '../../../../../model/navigation/IAddress';
import { IGeolocData } from '../../../../../model/navigation/IGeolocData';
import { IStoreDataResponse } from '../../../../../model/store/IStoreDataResponse';
import { IUiResponse } from '../../../../../model/uiMessage/IUiResponse';
import { EntitiesService } from '../../../../../modules/entities/services/entities.service';
import { ContactAddressPipe } from '../../../../../pipes/contactAddress.pipe';
import { EntityLinkService } from '../../../../../services/entityLink.service';
import { FormsService } from '../../../../../services/forms.service';
import { ShowMessageParamsPopup } from '../../../../../services/interfaces/ShowMessageParamsPopup';
import { NavigationService } from '../../../../../services/navigation.service';
import { UiMessageService } from '../../../../../services/uiMessage.service';
import { ActionButtonFieldBaseComponent } from './actionButtonFieldBase.component';

@Component({
	templateUrl: './actionButtonFieldBase.component.html'
})
export class ActionButtonFieldMapComponent extends ActionButtonFieldBaseComponent<IActionButtonFieldMapParams> implements OnInit {

	//#region FIELDS

	/** Latitude fournie par le modèle pour afficher la carte. */
	private mnLatitude: string | number;
	/** Longitude fournie par le modèle pour afficher la carte. */
	private mnLongitude: string | number;
	/** Indique si les coordonnées GPS sont correctes ou non. */
	private mbAreCorrectCoordinates: boolean;
	/** Adresse formattée du modèle. */
	private msAddress: string;
	/** Adresse du modèle. */
	private moAddress: IAddress;

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcUiMessage: UiMessageService,
		private readonly isvcEntityLinks: EntityLinkService,
		private readonly isvcEntities: EntitiesService,
		private readonly ioRouter: Router,
		private readonly ioContactAddressPipe: ContactAddressPipe<IAddress>,
		private readonly isvcNavigation: NavigationService,
		psvcForms: FormsService
	) {
		super(psvcForms);
	}

	public override ngOnInit(): void {
		super.ngOnInit();

		this.initCoordinates();
		this.initAddress();

		this.formControl.valueChanges
			.pipe(
				tap(_ => { // Un changement a été opéré, on réinitialise les coordonnées et l'adresse car elles peuvent avoir changées.
					this.initCoordinates();
					this.initAddress();
				}),
				takeUntil(this.fieldDestroyed$)
			)
			.subscribe();
	}

	private initCoordinates(): void {
		this.mnLatitude = this.model[this.params.specParams.latitudeField];
		this.mnLongitude = this.model[this.params.specParams.longitudeField];

		this.mbAreCorrectCoordinates = (NumberHelper.isValid(this.mnLatitude) || NumberHelper.isStringNumber(this.mnLatitude)) &&
			(NumberHelper.isValid(this.mnLongitude) || NumberHelper.isStringNumber(this.mnLongitude));
	}

	private initAddress(): void {
		if (this.params.specParams?.showAddress) { // Si on veut afficher l'adresse, on l'affecte.
			this.moAddress = {
				city: this.model[this.params.specParams?.cityField],
				zipCode: this.model[this.params.specParams?.postalCodeField],
				street: this.model[this.params.specParams?.streetField]
			};

			this.msAddress = this.ioContactAddressPipe.transform(this.moAddress, false);
		}
	}

	/** Ouvre une fenêtre contenant la carte Google map. */
	public override action(): void {
		if (!this.mbAreCorrectCoordinates && StringHelper.isBlank(this.msAddress)) { // Si pas de coordonnée GPS ni d'adresse.
			const loParamsPopup = new ShowMessageParamsPopup({
				header: "Carte",
				message: ActionButtonFieldBaseComponent.C_UNDEFINED_COORDINATES_AND_ADDRESS_TEXT,
				buttons: [{ text: "OK", handler: () => UiMessageService.getFalsyResponse() }]
			});

			if (this.params.specParams?.addEditGeolocButton)
				loParamsPopup.buttons.push({ text: "Utiliser la position actuelle", handler: () => UiMessageService.getTruthyResponse() });

			this.isvcUiMessage.showAsyncMessage<boolean>(loParamsPopup)
				.pipe(
					filter((poResponse: IUiResponse<boolean>) => !!poResponse.response),
					mergeMap(_ => this.openReverserGeocodingModalAndApplyChanges()),
					tap(_ => {
						this.initCoordinates();
						this.initAddress();
						this.action();
					}),
					takeUntil(this.fieldDestroyed$)
				)
				.subscribe();
		}
		else { // Navigation pour afficher la carte avec les données présentes.
			const loGoogleMapParams: IGoogleMapParams = {
				latitude: this.mnLatitude,
				longitude: this.mnLongitude,
				markerTitle: this.isvcEntityLinks.currentEntity ? this.isvcEntities.getEntityName(this.isvcEntityLinks.currentEntity) : "",
				address: this.msAddress,
				showAddress: this.params.specParams?.showAddress
			};

			this.ioRouter.navigate(["googleMap"], { queryParams: loGoogleMapParams });
		}
	}

	/** Ouvre la modale de reverseGeocoding et applique les modifications apportées. */
	private openReverserGeocodingModalAndApplyChanges(): Observable<IStoreDataResponse> {
		return this.isvcNavigation.getCurrentGeolocData(false)
			.pipe(
				mergeMap((poResult: IGeolocData[]) =>
					this.isvcNavigation.openReverseGeocodingModal([{ title: "Position actuelle", ...ArrayHelper.getFirstElement(poResult) }])
				),
				mergeMap((poResult: IGeolocData) => {
					this.model[this.params.specParams.latitudeField] = poResult.latitude;
					this.model[this.params.specParams.longitudeField] = poResult.longitude;

					// On affecte seulement les propriétés qui ont été définies dans les paramètres pour éviter d'ajouter des champs inutiles / non conformes.
					if (!StringHelper.isBlank(this.params.specParams?.streetField))
						this.model[this.params.specParams.streetField] = poResult.street;
					if (!StringHelper.isBlank(this.params.specParams?.postalCodeField))
						this.model[this.params.specParams.postalCodeField] = poResult.zipCode;
					if (!StringHelper.isBlank(this.params.specParams?.cityField))
						this.model[this.params.specParams.cityField] = poResult.city;

					this.form.patchValue(this.model);

					return this.isvcForms.saveModel(this.model, StoreHelper.getDatabaseIdFromCacheData(this.model));
				})
			);
	}

	//#endregion
}