import { Injectable } from "@angular/core";
import { Directory, FileInfo } from "@capacitor/filesystem";
import { StringHelper } from '../../../helpers/stringHelper';
import { LoadingService } from '../../../services/loading.service';
import { PlatformService } from '../../../services/platform.service';
import { FilesystemService } from "../../filesystem/services/filesystem.service";
import { Loader } from '../../loading/Loader';
import { SqlFilesHelper } from "../../sqlite/helpers/sql-files.helper";
import { PatchBase } from "./patch-base";

@Injectable()
export class Sqlite2ToSqliteEvcoreExtbuildFreePatch extends PatchBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "SQL2toSQLEEF.PATCH::";

	//#endregion

	//#region METHODS

	constructor(
		private readonly isvcPlatform: PlatformService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcFilesystem: FilesystemService
	) {
		super("Migration des bases de données sqlite");
	}

	public override applyPatchAsync(): Promise<boolean> {
		const lsSqliteMobileAppDatabasesPath: string = SqlFilesHelper.mobileAppDatabasesPath;

		if (!StringHelper.isBlank(lsSqliteMobileAppDatabasesPath)) {
			console.debug(`${Sqlite2ToSqliteEvcoreExtbuildFreePatch.C_LOG_ID}apply patch for ${this.isvcPlatform.isAndroid ? "android" : this.isvcPlatform.isIOS ? "iOS" : "another platform"} android`);
			return this.innerApplyPatchAsync("", lsSqliteMobileAppDatabasesPath);
		}
		else {
			console.debug(`${Sqlite2ToSqliteEvcoreExtbuildFreePatch.C_LOG_ID}NOT apply patch`);
			return Promise.resolve(true);
		}
	}

	/** Applique le patch de migration des bases de données depuis le plugin sqlite2 vers le plugin sqliteEvcoreExtbuildFree.
	 * @param psOriginalPath Chemin vers les bases de données avec le plugin sqlite2.
	 * @param psNewDatabasesPath Chemin vers les bases de données avec le plugin sqliteEvcoreExtbuildFree.
	 */
	private async innerApplyPatchAsync(psOriginalPath: string, psNewDatabasesPath: string): Promise<boolean> {
		let loLoader: Loader | undefined;

		try {
			loLoader = await this.isvcLoading.create(`${this.patchDescription} ...`);
			await loLoader.present();
			await this.isvcFilesystem.createDirectoryAsync(psNewDatabasesPath, SqlFilesHelper.mobileAppDatabasesDirectory, false);
			const laEntries: FileInfo[] = await this.isvcFilesystem.listDirectoryEntriesAsync(psOriginalPath, SqlFilesHelper.mobileAppDatabasesDirectory)
				.catch(_ => []);

			return this.moveDatabasesAsync(
				laEntries.filter((poEntry: FileInfo) => poEntry.type === "file" && poEntry.name.includes("_core_") && !poEntry.name.endsWith("-journal")),
				loLoader,
				psNewDatabasesPath
			);
		}
		catch (poError) {
			console.error(`${Sqlite2ToSqliteEvcoreExtbuildFreePatch.C_LOG_ID}Applying patch failed :`, poError);
			return false;
		}
		finally {
			loLoader?.dismiss();
		}
	}

	/** Déplace les bases de données depuis le dossier utilisé par le plugin sqlite2 vers le dossier utilisé par le plugin sqliteEvcoreExtbuildFree.
	 * @param paEntries Tableau des dossiers et fichiers présents dans le dossier dont il faut déplacer les bases de données.
	 * @param poLoader Loader affiché qu'il faut mettre à jour au fur-et-à-mesure des déplacements.
	 */
	private async moveDatabasesAsync(paEntries: FileInfo[], poLoader: Loader, psNewDatabasesPath: string): Promise<boolean> {
		let lbAllMovedDatabasesSuccess = true;

		for (let lnIndex = 0; lnIndex < paEntries.length; ++lnIndex) {
			const loFileEntry: FileInfo = paEntries[lnIndex];
			poLoader.text = this.getPatchMessage(lnIndex, paEntries.length);
			console.info(`${Sqlite2ToSqliteEvcoreExtbuildFreePatch.C_LOG_ID}Migrating database '${loFileEntry.name}' ongoing ...`);

			if (!await this.innerMoveDatabasesAsync(loFileEntry, psNewDatabasesPath))
				lbAllMovedDatabasesSuccess = false;
		}

		return lbAllMovedDatabasesSuccess;
	}

	private async innerMoveDatabasesAsync(poFileEntry: FileInfo, psNewDatabasesPath: string): Promise<boolean> {
		try {
			// On copie la base vers son nouveau chemin puis on la supprime
			// => cela évite des possibles corruptions de bases de données dans le cas où l'app crasherait pendant un déplacement.
			await this.isvcFilesystem.copyAsync(poFileEntry.name, `${psNewDatabasesPath}${poFileEntry.name}`, Directory.Data, SqlFilesHelper.mobileAppDatabasesDirectory);
			await this.isvcFilesystem.removeAsync(poFileEntry.name, Directory.Data);
			console.info(`${Sqlite2ToSqliteEvcoreExtbuildFreePatch.C_LOG_ID}Migration base de données '${poFileEntry.name}' terminée.`);
			return true;
		}
		catch (poError) {
			console.error(`${Sqlite2ToSqliteEvcoreExtbuildFreePatch.C_LOG_ID}Migration base de données '${poFileEntry.name}' échouée.`);
			return false;
		}
	}

	private getPatchMessage(pnIndex: number, pnTotalCount: number): string {
		return `${this.patchDescription} ${pnIndex + 1} sur ${pnTotalCount} ...`;
	}

	//#endregion

}