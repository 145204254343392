<ion-app>
	<ion-split-pane content-id="content">

		<ion-menu contentId="content" type="overlay" persistent="true" id="sideMenu">
			<ion-header *ngIf="menuTitle">
				<ion-toolbar class="toolbar-menu">
					<ion-buttons slot="start">
						<ion-button>
							<ion-icon name="merch-logo"></ion-icon>
						</ion-button>
					</ion-buttons>
					<ion-title>{{menuTitle}}</ion-title>
				</ion-toolbar>
			</ion-header>
			<ion-content [forceOverscroll]="false">
				<ion-list ngClass="side-menu-without-header">
					<calao-menu [menuKey]="menuKey"></calao-menu>
				</ion-list>
			</ion-content>
		</ion-menu>

		<ion-router-outlet id="content" main> </ion-router-outlet>
	</ion-split-pane>
</ion-app>