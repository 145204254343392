import { IExternalDataSource } from "./models/iexternal-data-source";

export abstract class ExternalDataSourcesCache {

	//#region FIELDS

	private static readonly DATA_SOURCES_CACHE: IExternalDataSource<any>[] = [];

	//#endregion FIELDS

	//#region METHODS

	private constructor() { }

	public static add<T>(poExternalDataSource: IExternalDataSource<T>): void {
		this.DATA_SOURCES_CACHE.push(poExternalDataSource);
	}

	public static get<T>(poData: T): IExternalDataSource<T>[] {
		return this.DATA_SOURCES_CACHE.filter((poDataSouce: IExternalDataSource<any>) => poData instanceof poDataSouce.type);
	}

	//#endregion METHODS

}
