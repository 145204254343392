import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { NumberHelper } from '../../../../../helpers/numberHelper';
import { ResolveModel } from "../../../../utils/models/decorators/resolve-model.decorator";
import { ERackStatus } from "../../models/ERackStatus";
import { ItemPrice } from '../../models/item-price';
import { RackValidation } from '../../models/rack-validation';
import { DeliveryRackItem } from "./delivery-rack-item";
import { DeliveryRackPack } from './delivery-rack-pack';
import { IDeliveryRack } from "./idelivery-rack";

export class DeliveryRack implements IDeliveryRack {

	//#region PROPERTIES

	/** @implements */
	public readonly _id: string;

	/** @implements */
	public readonly _rev?: string;

	/** @implements */
	public readonly createContactId: string;

	/** @implements */
	public readonly createDate: Date;

	/** @implements */
	public status: ERackStatus;

	/** @implements */
	@ResolveModel(DeliveryRackPack)
	public packs: DeliveryRackPack[];

	/** @implements */
	@ResolveModel(DeliveryRackItem)
	public items: DeliveryRackItem[];

	/** @implements */
	@ResolveModel(RackValidation)
	public validation?: RackValidation;

	//#endregion

	//#region METHODS

	constructor(
		psId: string,
		paItems?: DeliveryRackItem[],
		paPacks?: DeliveryRackPack[],
		psCreateContactId?: string,
		poCreateDate?: Date,
		peStatus?: ERackStatus,
		poValidation?: RackValidation
	) {
		this._id = psId;
		this.items = paItems ?? [];
		this.packs = paPacks ?? [];
		this.createContactId = psCreateContactId;
		this.createDate = poCreateDate;
		this.status = peStatus;
		this.validation = poValidation;
	}

	/** Indique si le portant possède au moins un article. */
	public hasItems(): boolean {
		return this.items.length > 0;
	}

	/** Indique si l'article est présent dans le portant.
	 * @param psItemId Identifiant de l'article recherché.
	 * @param poItemPrice Détail du prix de l'article recherché.
	 */
	public hasItem(psItemId: string, poItemPrice: ItemPrice): boolean {
		return !!this.getItem(psItemId, poItemPrice);
	}

	/** Retourne l'article correspondant à l'identifiant et au prix, `undefined` si non trouvé.
	 * @param psItemId Identifiant de l'article recherché.
	 * @param poItemPrice Détail du prix de l'article recherché.
	 */
	public getItem(psItemId: string, poItemPrice: ItemPrice): DeliveryRackItem | undefined {
		return this.items.find((poItem: DeliveryRackItem) => poItem.match(psItemId, poItemPrice));
	}

	/** Ajoute un article à la liste des articles du portant.
	 * @param poItem Article à ajouter.
	 */
	public addItem(poItem: DeliveryRackItem): void {
		this.items.push(poItem);
	}

	/** Supprime un item du portant.
	 * @param poItemData Item ou identifiant de l'item à supprimer.
	 * @returns True si l'item a été supprimé.
	 */
	public removeItem(poItemData: DeliveryRackItem | string): boolean {
		const lsItemId: string = typeof poItemData === "string" ? poItemData : poItemData.itemId;
		return !!ArrayHelper.removeElementByFinder(this.items, (poItem: DeliveryRackItem) => poItem.itemId === lsItemId);
	}

	/** Ajoute un conditionnement à la liste des conditionnements du portant.
	 * @param poPack Conditionnement à ajouter.
	 */
	public addPack(poPack: DeliveryRackPack): void {
		ArrayHelper.removeElementByFinder(this.packs, (poDeliveryPack: DeliveryRackPack) => poDeliveryPack.matches(poPack));
		this.packs.push(poPack);
	}

	/** Retourne `true` si le tableau `packs` contient au moins un élément, sinon `false`. */
	public hasReadPack(): boolean {
		return ArrayHelper.hasElements(this.packs);
	}

	/** Retourne le nombre d'articles sur le portant. */
	public getTotalArticles(): number {
		return NumberHelper.reduceNumbers(this.items.map((poItem: DeliveryRackItem) => poItem.qty), 0);
	}

	/** Retourne la quantité livrée d'un article dans le portant.
	 * @param psItemId ItemId de l'article.
	 * @param poPrice Prix de l'article.
	 */
	public getDeliveredQuantity(psItemId: string, poPrice: ItemPrice): number | undefined {
		return this.getItem(psItemId, poPrice)?.qty;
	}

	/** Si l'article est manquant, on retourne l'id du motif, `undefined` sinon.
	 * @param psItemId Code article / Identifiant de l'article.
	 * @param poPrice Prix de l'article.
	 */
	public getItemMissingReasonId(psItemId: string, poPrice: ItemPrice): string | undefined {
		const loItem: DeliveryRackItem | undefined = this.getItem(psItemId, poPrice);
		return loItem?.reasonId;
	}

	/** Indique si un article a été signalé manquant dans le portant courant.
	 * @param psItemId Identifiant de l'article.
	 * @param poPrice Détail du prix de l'article.
	 */
	public isItemMissing(psItemId: string, poPrice: ItemPrice): boolean {
		return !!this.getItemMissingReasonId(psItemId, poPrice);
	}

	//#endregion

}