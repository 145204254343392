import { Component, OnInit } from '@angular/core';
import { ModalComponentBase } from '../../../modal/model/ModalComponentBase';
import { DatabasesSyncStatusModalMainViewComponent } from '../databases-sync-status-modal-main-view/databases-sync-status-modal-main-view.component';

@Component({
	selector: 'calao-databases-sync-status-modal',
	templateUrl: './databases-sync-status-modal.component.html',
	styleUrls: ['./databases-sync-status-modal.component.scss'],
	// Pas de onPush à cause d'un bug du ionNav
})
export class DatabasesSyncStatusModalComponent extends ModalComponentBase<boolean> implements OnInit {

	//#region PROPERTIES

	public readonly rootComponent = DatabasesSyncStatusModalMainViewComponent;

	//#endregion PROPERTIES

}