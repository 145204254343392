import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DiagnosticsLogisticsPage } from './diagnostics/diagnostics-page.component';
import { ReadingPage } from './reading/reading-page.component';
import { SearchPage } from './search/search-page.component';

export const routes: Routes = [
	{
		path: '',
		pathMatch: "full",
		redirectTo: "reading"
	},
	{
		path: 'reading',
		component: ReadingPage
	},
	{
		path: 'search',
		component: SearchPage
	},
	{
		path: 'diagnostics',
		component: DiagnosticsLogisticsPage
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class LogisticsRoutingModule { }