import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Directory, Encoding, FileInfo, Filesystem, ReaddirResult, RenameOptions } from '@capacitor/filesystem';
import write_blob from "capacitor-blob-writer";
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { EFileError } from '../../../model/file/EFileError';
import { IFileError } from '../../../model/file/IFileError';
import { PlatformService } from '../../../services/platform.service';
import { FilesystemRemoveError } from '../models/errors/filesystem-remove-error';
import { FilesystemRenameError } from '../models/errors/filesystem-rename-error';
import { IReadFileResult } from '../models/iread-file-result';

@Injectable()
export class FilesystemService {

	//#region FIELDS

	private static readonly C_LOG_ID = "FS.S::";
	private static readonly C_URI_START = "file://";

	//#endregion

	//#region METHODS

	constructor(private readonly ioHttpClient: HttpClient, private readonly isvcPlatform: PlatformService) { }

	/** Récupère un fichier enregistré.
	 * @param psPath Chemin d'accès jusqu'au fichier qu'on veut récupérer : 'cheminVersFichier/nomFichier'
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 */
	public getFileAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<Blob> {
		return this.getFileUriAsync(psPath, peDirectory)
			.then((psUri: string) => this.getFileFromUriAsync(psUri));
	}

	/** Récupère un fichier depuis son identifiant de ressource unifrome (URI).
	 * @param psUri Identifiant de ressource uniforme du fichier à récupérer.
	 */
	public getFileFromUriAsync(psUri: string): Promise<Blob> {
		return this.ioHttpClient.get(psUri.startsWith("blob") ? psUri : Capacitor.convertFileSrc(psUri), { responseType: "blob" }).toPromise();
	}

	/** `true` si le fichier/répertoire pointé existe, `false` sinon.
	 * @param psPathOrUri Chemin vers le fichier/répertoire à vérifier ou son identifiant de ressource uniforme (URI).
	 * @param peDirectory Répertoire où se trouve le fichier/répertoire à vérifier, `External` par défaut, inutile si `psPathOrUri` est un URI.
	 */
	public async existsAsync(psPathOrUri: string, peDirectory: Directory = Directory.External): Promise<boolean> {
		try {
			console.debug(`${FilesystemService.C_LOG_ID}Checking if '${psPathOrUri}' exists...`);

			const lsUri: string = await this.getFileUriAsync(psPathOrUri, peDirectory);

			await Filesystem.stat({ path: lsUri });

			console.debug(`${FilesystemService.C_LOG_ID}'${psPathOrUri}' exists!`);

			return true;
		}
		catch (poError) {
			console.debug(`${FilesystemService.C_LOG_ID}'${psPathOrUri}' does not exist`);
			return false;
		}
	}

	/** Lecture du contenu textuel d'un fichier.
	 * @param psPath Chemin d'accès au dossier qu'on veut lire, peut contenir le nom du fichier : 'cheminVersFichier/nomFichier'.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 */
	public readFileAsTextAsync(
		psPath: string,
		peDirectory: Directory = Directory.External,
		peEncoding: Encoding = Encoding.UTF8
	): Promise<IReadFileResult> {
		try {
			return Filesystem.readFile({ path: psPath, directory: peDirectory, encoding: peEncoding });
		}
		catch (poError) {
			console.error(`${FilesystemService.C_LOG_ID}Erreur lecture du fichier '${psPath}' : `, poError);

			if (poError.code === 1 || poError.code === 13) // "not found" ou "input is not a file"
				throw { error: poError, errorCode: EFileError.FileNotFound };

			else
				throw { error: poError, errorCode: EFileError.Other };
		}
	}

	/** Crée un dossier à un chemin spécifique.
	 * @param psPath Chemin d'accès au dossier qu'on veut créer : 'cheminVersFichier/nomFichier'.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 * @param pbRaiseError Indique si une erreur dans le cas où le répertoire existe déjà, `true` par défaut.
	 */
	public async createDirectoryAsync(
		psPath: string,
		peDirectory: Directory = Directory.External,
		pbRaiseError: boolean = true
	): Promise<void> {
		if (await this.existsAsync(psPath, peDirectory)) {
			if (pbRaiseError)
				throw { errorCode: EFileError.DirectoryAlreadyExist, error: `Répertoire '${psPath} existe déjà.` } as IFileError;
		}
		else
			await Filesystem.mkdir({ path: psPath, directory: peDirectory, recursive: true });
	}

	/** Crée un fichier à un chemin spécifique et retourne son url.
	 * @param psPath Chemin d'accès au dossier qu'on veut créer, peut contenir le nom du fichier : 'cheminVersFichier/nomFichier'.
	 * @param poData Données du fichier à créer, crée un fichier vide si non renseigné.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 * @param pbOverwriteIfExists Indique si on doit écraser le fichier s'il existe déjà, `false` par défaut.
	 */
	public async createFileAsync(
		psPath: string,
		poData?: string | Blob | ArrayBuffer,
		peDirectory: Directory = Directory.External,
		pbOverwriteIfExists?: boolean
	): Promise<string> {
		if (await this.existsAsync(psPath, peDirectory) && !pbOverwriteIfExists)
			throw { errorCode: EFileError.FileAlreadyExist, error: `Fichier '${psPath} existe déjà.` } as IFileError;

		try {
			let lsUri: string;
			if (!poData || typeof poData === "string") {
				lsUri = (await Filesystem.writeFile({
					data: poData as string ?? "",
					path: psPath,
					directory: peDirectory,
					recursive: true
				})).uri;
			}
			else {
				lsUri = await write_blob({
					blob: poData instanceof Blob ? poData : new Blob([poData]),
					path: psPath,
					directory: peDirectory,
					recursive: true,
				});
			}
			console.debug(`${FilesystemService.C_LOG_ID}Fichier '${psPath}' créé !`);
			return lsUri;
		}
		catch (poError) {
			console.error(`${FilesystemService.C_LOG_ID}Erreur création fichier '${psPath}' : `, poError);
			throw { errorCode: EFileError.Other, error: poError } as IFileError;
		}
	}

	/** Liste le contenu d'un répertoire et renvoie cette liste.
	 * @param psPath Chemin d'accès jusqu'au répertoire dont il faut lister le contenu.
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	 */
	public async listDirectoryEntriesAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<FileInfo[]> {
		try {
			const loResult: ReaddirResult = await Filesystem.readdir({ path: psPath.endsWith("/") ? psPath.slice(0, -1) : psPath, directory: peDirectory });
			console.debug(`${FilesystemService.C_LOG_ID}Content of directory '${psPath}' : `, loResult.files);
			return loResult.files;
		}
		catch (poError) {
			console.error(`${FilesystemService.C_LOG_ID}Error from listing entries in directory '${psPath}' : `, poError);
			throw poError;
		}
	}

	/** Récupère le tableau des dossiers présents dans un répertoire.
	 * @param psPath Chemin d'accès jusqu'au répertoire dont il faut récupérer les dossiers.
	 * @param peDirectory Dossier dans lequel sont stockés les dossiers, `Directory.External` par défaut.
	 */
	public getDirectoriesInfoAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<FileInfo[]> {
		return this.listDirectoryEntriesAsync(psPath, peDirectory)
			.then((paResults: FileInfo[]) => paResults.filter((poResult: FileInfo) => poResult.type === "directory"));
	}

	/** Récupère le tableau des informations des fichiers présents dans un répertoire.
	 * @param psPath Chemin d'accès jusqu'au répertoire dont il faut récupérer les fichiers.
	 * @param peDirectory Dossier dans lequel sont stockés les fichiers, `Directory.External` par défaut.
	 */
	public getFilesInfoAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<FileInfo[]> {
		return this.listDirectoryEntriesAsync(psPath, peDirectory)
			.then((paResults: FileInfo[]) => paResults.filter((poResult: FileInfo) => poResult.type === "file"));
	}

	/** Supprime un répertoire et son contenu depuis son chemin ou son identifiant de ressource uniforme (URI).
	 * @param psPathOrUri Chemin d'accès jusqu'au répertoire qu'il faut supprimer ou son identifiant de ressource uniforme (URI).
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut, inutile si `psPathOrUri` est un URI.
	 * @throws `FilesystemRemoveError` en cas d'erreur.
	 */
	private removeDirectoryAsync(psPathOrUri: string, peDirectory: Directory = Directory.External): Promise<void> {
		return this.getFileUriAsync(psPathOrUri, peDirectory)
			.then((psUri: string) => {
				return Filesystem.rmdir({ path: psUri, recursive: true })
					.then(() => console.debug(`${FilesystemService.C_LOG_ID}Directory with uri '${psUri}' removed.`));
			})
			.catch(poError => {
				console.error(`${FilesystemService.C_LOG_ID}Error when removing directory '${psPathOrUri}' :`, poError);
				throw new FilesystemRemoveError(ArrayHelper.getLastElement(psPathOrUri.split("/")));
			});
	}

	/** Supprime un fichier depuis un chemin ou un identifiant de ressource uniforme (URI).
	 * @param psPathOrUri Chemin vers le fichier à supprimer ou son identifiant de ressource uniforme (URI).
	 * @param peDirectory Répertoire où se trouve le fichier à supprimer, `External` par défaut, inutile si `psPathOrUri` est un URI.
	 * @throws `FilesystemRemoveError` en cas d'erreur.
	 */
	public removeFileAsync(psPathOrUri: string, peDirectory: Directory = Directory.External): Promise<void> {
		return this.getFileUriAsync(psPathOrUri, peDirectory)
			.then((psUri: string) => {
				return Filesystem.deleteFile({ path: psUri })
					.then(() => console.debug(`${FilesystemService.C_LOG_ID}File with uri '${psUri}' removed.`));
			})
			.catch(poError => {
				if (poError.message === "File does not exist") // Pas le choix, l'erreur n'a pas plus d'information.
					return undefined;

				console.error(`${FilesystemService.C_LOG_ID}Error when removing file '${psPathOrUri}' :`, poError);
				throw new FilesystemRemoveError(ArrayHelper.getLastElement(psPathOrUri.split("/")));
			});
	}

	/** Supprime un dossier (et son contenu) ou un fichier.
	 * @param psPathOrUri Chemin d'accès jusqu'à la cible qu'on veut supprimer, peut être le chemin ou l'identifiant de ressource uniforme (URI).
	 * @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut. Non nécessaire si `psPathOrUri` est un URI.
	 */
	public removeAsync(psPathOrUri: string, peDirectory: Directory = Directory.External): Promise<void> {
		return this.isDirectoryAsync(psPathOrUri, peDirectory)
			.then((pbIsDirectory: boolean) => pbIsDirectory ?
				this.removeDirectoryAsync(psPathOrUri, peDirectory) : this.removeFileAsync(psPathOrUri, peDirectory)
			);
	}

	/** Retourne "true" si c'est un répertoire, "false" si c'est un fichier, ou une erreur.
	* @param psPath Chemin d'accès jusqu'au répertoire/fichier qu'on veut identifier, peut contenir le nom du répertoire/fichier : 'cheminVersFichier/nomFichier'.
	* @param peDirectory Dossier dans lequel est stocké le fichier. `Directory.External` par défaut.
	*/
	public async isDirectoryAsync(psPath: string, peDirectory: Directory = Directory.External): Promise<boolean> {
		try {
			return (await Filesystem.stat({ path: psPath, directory: peDirectory })).type === "directory";
		}
		catch (poError) {
			return false;
		}
	}

	/** Copie un fichier dans un répertoire.
	 * @param psFromPath Chemin du fichier/répertoire à copier dans un autre répertoire.
	 * @param psToPath Chemin où copier le fichier/dossier.
	 * @param peFromDirectory Dossier dans lequel est stocké le fichier à copier. `Directory.External` par défaut.
	 * @param peToDirectory Dossier dans lequel copier le fichier. Utilise `peFromDirectory` par défaut.
	 */
	public async copyAsync(
		psFromPath: string,
		psToPath: string,
		peFromDirectory: Directory = Directory.External,
		peToDirectory: Directory = peFromDirectory
	): Promise<void> {
		try {
			if (await this.existsAsync(psToPath, peToDirectory))
				await this.removeAsync(psToPath, peToDirectory);
			await Filesystem.copy({ from: psFromPath, to: psToPath, directory: peFromDirectory, toDirectory: peToDirectory });
		}
		catch (poError) {
			console.error(`${FilesystemService.C_LOG_ID}Error occurred while trying to copy file '${psFromPath}' to ${psToPath} : `, poError);
			throw { errorCode: EFileError.Other, error: poError } as IFileError;
		}
	}

	/** Récupère l'identifiant de ressource uniforme (URI) vers un fichier (== chemin d'accès précis).
	 * @param psPath
	 * @param peDirectory
	 */
	public async getFileUriAsync(psPath: string, peDirectory: Directory): Promise<string> {
		if (psPath.startsWith(FilesystemService.C_URI_START))
			return psPath;

		return `${(await Filesystem.getUri({ path: "", directory: peDirectory })).uri}/${psPath}`;
	}

	/** Récupère la signature (nombre correspondant au type de fichier) d'une donnée.
	 * @param poBlob Donnée dont il faut récupérer la signature (nombre correspondant au type du fichier).
	 */
	public getMagicNumberAsync(poBlob: Blob): Promise<string> {
		return poBlob.slice(0, 4) // On a besoin que des 4 premiers morceaux pour déterminer le magicNumber.
			.arrayBuffer()
			.then((poResult: ArrayBuffer) => {
				const laHexParts: string[] = [];

				// Les nombre en hexadécimal doivent être sur 2 caractères, il faut donc ajouter un 0 au début si le nombre est inférieur à 10.
				// ex: 3 -> "3" donc il faut ajouter un 0 devant -> "03" ; 75 -> "4b" donc pas besoin de 0 devant.
				new Uint8Array(poResult)
					.forEach((pnByte: number) => laHexParts.push(pnByte.toString(16).padStart(2, "0")));

				return laHexParts.join("").toUpperCase();
			});
	}

	/** Renomme un fichier dans un répertoire, bien indiquer l'extension du fichier.
	 * @param psOldNameOrUri Nom du fichier ou URI à renommer.
	 * @param psNewNameOrUri Nouveau nom du fichier ou URI après renommage.
	 * @param peDirectory Répertoire où se trouve le fichier à renommer, `External` par défaut, inutile si `psOldNameOrUri` et `psNewNameOrUri` sont des URIs.
	 * @throws `FilesystemRenameError` en cas d'erreur.
	 */
	public async renameAsync(psOldNameOrUri: string, psNewNameOrUri: string, peDirectory: Directory = Directory.External): Promise<void> {
		const loRenameOptions: RenameOptions = {
			from: await this.getFileUriAsync(psOldNameOrUri, peDirectory),
			to: await this.getFileUriAsync(psNewNameOrUri, peDirectory)
		};

		return Filesystem.rename(loRenameOptions)
			.catch(poError => {
				console.error(`${FilesystemService.C_LOG_ID}Error when renaming from '${loRenameOptions.from}' to '${loRenameOptions.to}' :`, poError);
				throw new FilesystemRenameError(ArrayHelper.getLastElement(loRenameOptions.from.split("/")));
			});
	}

	//#endregion

}