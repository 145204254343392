import { EItemPriceType } from "../../task/models/eitem-price-type";
import { IItemPrice } from "../../task/models/iitem-price";

export abstract class AggregationHelper {

	//#region METHODS

	public static getAggregationKeyByItemIdAndPrice(psItemId: string, poPrice?: IItemPrice): string;
	public static getAggregationKeyByItemIdAndPrice(psItemId: string, pePriceType?: EItemPriceType, pnPriceValue?: number): string;
	public static getAggregationKeyByItemIdAndPrice(psItemId: string, poPriceData?: IItemPrice | EItemPriceType, pnPriceValue?: number): string {
		const lePriceType: EItemPriceType | undefined = (poPriceData as IItemPrice)?.type ?? (poPriceData as EItemPriceType);
		const lnPriceValue: number | undefined = (poPriceData as IItemPrice)?.value ?? pnPriceValue;

		return `${psItemId}${lePriceType ?? ""}${lnPriceValue ?? ""}`;
	}

	/** Retourne `true` si la clé d'agrégation code article - prix est pas la même pour les deux articles, `false` sinon.
	 * @param poItemA Article avec lequel on veut comparer l'autre.
	 * @param poItemB Article à comparer avec l'un.
	 */
	public static areArticleKeyEqual(poItemA: { itemId?: string, price?: IItemPrice }, poItemB: { itemId?: string, price?: IItemPrice }): boolean {
		return this.getAggregationKeyByItemIdAndPrice(poItemA.itemId, poItemA.price) === this.getAggregationKeyByItemIdAndPrice(poItemB.itemId, poItemB.price);
	}

	//#endregion

}