import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../helpers/ComponentBase';
import { IRfidAcquisition } from '../../devices/models/IRfidAcquisition';
import { NominalHelper } from '../helpers/nominal.helper';
import { IReadingQualityAlgorithm } from '../models/IReadingQualityAlgorithm';

/** Vue "simple" pour la lecture RFID. */
@Component({
	selector: "calao-logistics-simple-repr",
	templateUrl: './simple-reading-representation.component.html',
	styleUrls: ['./simple-reading-representation.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleReadingRepresentationComponent extends ComponentBase {

	//#region PROPERTIES

	@Input() public acquisitions: Array<IRfidAcquisition>;
	@Input() public quality: IReadingQualityAlgorithm;
	@Input() public nominal: Set<string>;
	@Input() public displayTotal: boolean;
	/** Dernier code-barres scanné. */
	@Input() public lastScan: string;

	//#endregion


	//#region METHODS

	constructor(poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	public getReadingQuality(): number {
		return this.quality.getReadingQuality();
	}

	public hasNominal(): boolean {
		return !!this.nominal;
	}

	public getNominalLength(): number {
		return this.nominal.size;
	}

	public getNominalAcquired(): number {
		return NominalHelper.getNominalOk(this.acquisitions.map((poElement: IRfidAcquisition) => poElement.code), this.nominal);
	}

	public getNominalExtra(): number {
		return NominalHelper.getNominalExtra(this.acquisitions.map((poElement: IRfidAcquisition) => poElement.code), this.nominal);
	}

	/** Affiche le nombre total d'élément en comptant les codes dupliqués. */
	public getTotalWithDuplicate(): number {
		let lnTotal = 0;
		this.acquisitions.forEach((poAcq: IRfidAcquisition) => lnTotal += poAcq.numberOfScans ? poAcq.numberOfScans : 1);
		return lnTotal;
	}

	//#endregion

}