import { IRackValidation } from "./irack-validation";
import { ISignature } from "./isignature";
import { IValidationRecipient } from "./ivalidation-recipient";

export class RackValidation implements IRackValidation {

	//#region PROPERTIES

	/** @implements */
	public readonly recipients: IValidationRecipient[];

	/** @implements */
	public signature: ISignature;

	//#endregion

	//#region METHODS

	constructor(paRecipients: IValidationRecipient[], poSignature: ISignature) {
		this.recipients = paRecipients;
		this.signature = poSignature;
	}

	//#endregion

}