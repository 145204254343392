import { EOptimizationStatusCode } from "./eoptimization-status-code";
import { ITourOptimizationResultDTO } from "./itour-optimization-result-dto";
import { OptimizedToursDTO } from "./optimized-tours-dto";

export class TourOptimizationResultDTO implements ITourOptimizationResultDTO {
	/** @implements */
	public code: EOptimizationStatusCode;

	/** @implements */
	public error?: string;

	/** @implements */
	public resourcesTours: OptimizedToursDTO;
}
