<div *ngFor="let comp of (observableParams.value$ | async)?.headers" [ngClass]="comp.cssClass || ''">
	<ng-container #header></ng-container>
</div>

<calao-tabs #tabs>
	<calao-tabs-segments>
		<calao-tabs-segment *ngFor="let tab of (observableParams.value$ | async)?.tabs">
			<ion-icon *ngIf="tab.icon" [name]="tab.icon" size="large"></ion-icon>
			<ion-label class="tab-label">{{tab.title}}</ion-label>
		</calao-tabs-segment>
	</calao-tabs-segments>
	<calao-tabs-tab-container>
		<ng-template #tabTemplate *ngFor="let tab of (observableParams.value$ | async)?.tabs">
			<calao-tabs-tab>
				<ng-container #tabContainer></ng-container>
			</calao-tabs-tab>
		</ng-template>
	</calao-tabs-tab-container>
</calao-tabs>