<osapp-header-modal title="Nouveau document"></osapp-header-modal>

<ion-content>
	<ion-label>Type de document</ion-label>
	<ng-container *ngIf="observableFoldersOptions.changes$ | async as options">
		<div class="selector" *ngIf="options.length > 0; else noSubFolder">
			<osapp-selector [options]="options" [displayMode]="selectorDisplayMode.tags" [scrollWrapper]="false"
				(selectionChanged)="onFolderChanged($event)">
			</osapp-selector>
		</div>
	</ng-container>
	<ng-template #noSubFolder>
		<div *ngIf="observableSelectedFolder.value$ | async as folder">{{ folder.name }}</div>
	</ng-template>

	<div class="separator"></div>

	<ng-container *ngIf="observableParentEntity.value$ | async as parentEntity">
		<ion-label>{{ observableParentEntityCategory.value$ | async }}</ion-label>
		<div>{{ getName(parentEntity) }}</div>
	</ng-container>
</ion-content>

<ion-footer class="ion-padding">
	<osapp-selector *ngIf="observableHasForm.value$ | async" [options]="nextStepOptions"
		[preselectedValues]="nextStep.file" [displayMode]="selectorDisplayMode.list"
		(selectionChanged)="onNextStepChanged($event)">
	</osapp-selector>

	<div class="next-btn">
		<ion-fab-button [disabled]="!(observableSelectedFolder.value$ | async)" size="small" color="primary"
			class="fab-label" (click)="onSubmitAsync()">
			<ion-label>Suivant</ion-label>
		</ion-fab-button>
	</div>
</ion-footer>