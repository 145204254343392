import { EItemPriceType } from './eitem-price-type';
import { IItemPrice } from './iitem-price';

export class ItemPrice implements IItemPrice {

	//#region FIELDS

	/** @implements */
	public readonly type: EItemPriceType;

	/** @implements */
	public readonly value: number;

	/** Identifiant unique d'un élément. */
	public get uid(): string { return `${this.type}${this.value}`; }

	//#endregion

	//#region METHODS

	constructor(poType: EItemPriceType, pnValue: number = 0) {
		this.type = poType;
		this.value = pnValue;
	}


	/** Vérifie si le prix est égal en comparant le type et la valeur.
	 * @param poOtherPrice Autre prix à comparer.
	 */
	public equals(poOtherPrice?: IItemPrice): boolean {
		return !!poOtherPrice && this.type === poOtherPrice.type && this.value === poOtherPrice.value;
	}

	//#endregion

}