import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { defer, of } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { StoreDocumentHelper } from '../../../../helpers/storeDocumentHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { IFormDefinition } from '../../../forms/models/IFormDefinition';
import { IFormParams } from '../../../forms/models/IFormParams';
import { FormsService } from '../../../forms/services/forms.service';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';
import { IEntity } from '../../models/ientity';
import { IEntityDescriptor } from '../../models/ientity-descriptor';
import { EntitiesService } from '../../services/entities.service';

@Component({
	selector: 'calao-entity-form',
	templateUrl: './entity-form.component.html',
	styleUrls: ['./entity-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityFormComponent<T extends IEntity> extends DestroyableComponentBase implements OnInit {

	//#region FIELDS

	private moEntityDescriptor: IEntityDescriptor;

	private readonly statePaths: string[];

	//#endregion

	//#region PROPERTIES

	/** Doc propriété. */
	@Input() public params?: IFormParams<T>;
	@ObserveProperty<EntityFormComponent<T>>({ sourcePropertyKey: "params" })
	public readonly observableParams = new ObservableProperty<IFormParams<T>>();

	public readonly observableFormDefinition = new ObservableProperty<IFormDefinition | undefined>();
	public readonly observableReadonly = new ObservableProperty<boolean>();
	public readonly observableDisplaySubmitBtn = new ObservableProperty<boolean>(false);
	public readonly observableModel = new ObservableProperty<T | undefined>();
	public readonly observableFormValid = new ObservableProperty<boolean>(false);
	public readonly observableIsLoading = new ObservableProperty(true);

	public get entityDescriptor(): IEntityDescriptor {
		return this.moEntityDescriptor;
	}

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		private readonly isvcForms: FormsService,
		private readonly isvcEntities: EntitiesService
	) {
		super();
	}

	public ngOnInit(): void {
		this.observableParams.value$.pipe(
			filter((poFormParams?: IFormParams<T>) => !!poFormParams),
			mergeMap((poFormParams: IFormParams<T>) =>
				defer(() =>
					!poFormParams.entityDescriptor && !StringHelper.isBlank(poFormParams.entityDescriptorId) ?
						this.isvcEntities.getDescriptor$(poFormParams.entityDescriptorId) :
						of(poFormParams.entityDescriptor)
				).pipe(
					tap((poEntityDescriptor: IEntityDescriptor) => this.initParams(poEntityDescriptor, poFormParams)),
					mergeMap(() =>
						this.observableModel.value ?
							of(this.observableModel.value) :
							this.isvcForms.getModel$(poFormParams.modelId)
					)
				)
			),
			tap((poModel?: T) => {
				if (!poModel) {
					poModel = {
						_id: this.moEntityDescriptor.idPattern,
						"#paths": this.params?.paths
					} as any;
				}
				else if (!StoreDocumentHelper.hasRevision(poModel) && ArrayHelper.hasElements(this.params?.paths))
					(poModel as any)["#paths"] = this.params.paths;

				// On vient bind la validité du model avec la validité du formulaire (utile pour la gestion de la validation en externe).
				poModel.observableIsValid?.bind(this.observableFormValid.value$, this);
				this.observableModel.value = poModel;
				this.observableIsLoading.value = false;
			}),
			secure(this)
		).subscribe();
	}

	private initParams(poEntityDescriptor: IEntityDescriptor, poFormParams: IFormParams<T>): void {
		this.moEntityDescriptor = poEntityDescriptor;
		if (ArrayHelper.hasElements(this.statePaths))
			poFormParams.paths = [...(poFormParams.paths ?? []), ...this.statePaths];

		// Récupération de la définition du formulaire.
		const loFormDefinition: IFormDefinition =
			poFormParams.formDefinition ??
			this.isvcEntities.getDefinition(poEntityDescriptor, poFormParams.formDefinitionId);

		this.observableFormDefinition.value = loFormDefinition;
		// Affichage du bouton de validation.
		this.observableDisplaySubmitBtn.value = loFormDefinition.allowEdit && !loFormDefinition.readOnly;
		if (poFormParams.model)
			this.observableModel.value = poFormParams.model;
		// Titre du formulaire.

		this.observableReadonly.value = loFormDefinition.readOnly ?? true;
	}

	public onValidityChanged(pbValidity: boolean): void {
		this.observableFormValid.value = pbValidity;
	}

	//#endregion

}
