import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { DateHelper } from '../../../../../helpers/dateHelper';
import { StringHelper } from '../../../../../helpers/stringHelper';
import { AggregationHelper } from '../../../aggregations/helpers/aggregation.helper';
import { IPricedItem } from '../../../catalog/models/ipriced-item';
import { IItemPrice } from '../../models/iitem-price';
import { IRackReturnArticle } from '../models/IRackReturnArticle';
import { IReceipt } from '../models/IReceipt';
import { IReceiptReturnArticle } from '../models/IReceiptReturnArticle';
import { IReturnRack } from '../models/ireturn-rack';

/** Permet de mettre à disposition des méthodes pour aider à manipuler des portants. */
export abstract class ReturnRackHelper {

	//#region METHODS

	protected constructor() { }

	/** Récupère tous les articles prélevés d'un portant.
	 * @param poRack Portant dont il faut extraire les articles prélevés.
	 */
	public static getPickedArticles(poRackData: IReturnRack | IReturnRack[]): IRackReturnArticle[] {
		if (poRackData instanceof Array)
			return ArrayHelper.flat(poRackData.map((poRack: IReturnRack) => this.getPickedValues(poRack)));
		else
			return this.getPickedValues(poRackData);
	}

	private static getPickedValues(poRack: IReturnRack): IRackReturnArticle[] {
		return Object.values(poRack.picking);
	}

	/** Récupère le tableau des identifiants des articles prélevés dans un portant.
	 * @param poRack Portant dont on veut récupérer les identifiants d'articles prélevés.
	 */
	public static getPickedItemIds(poRack: IReturnRack): string[] {
		return Object.keys(poRack.picking);
	}

	/** Récupère le prix d'un article, `undefined` si non trouvé.
	 * @param poItemData Identifiant ou article dont il faut récupérer le prix.
	 * @param poRackData Portant ou tableau des portants où chercher le prix de l'article.
	 * @param paReceipts Tableau des bons disponibles pour récupérer le prix de l'article prioritairement.
	 */
	public static getArticlePrice<T extends { itemId?: string }>(poItemData: string | T, poRackData: IReturnRack | IReturnRack[],
		paReceipts: IReceipt[] | ReadonlyArray<IReceipt> = []): IItemPrice | undefined {

		const lsItemId: string | undefined = typeof poItemData === "string" ? poItemData : poItemData?.itemId;

		if (!StringHelper.isBlank(lsItemId)) {
			const loReceiptPriceResult: IItemPrice | undefined = this.getArticlePriceFromReceipt(
				lsItemId,
				paReceipts,
				(poArticle: IReceiptReturnArticle) => poArticle.itemId
			);

			// Si le prix n'a pas pu être récupéré dans les bons, on le récupère depuis les portants.
			return loReceiptPriceResult ? loReceiptPriceResult : this.getArticlePriceFromRacks(lsItemId, poRackData);
		}
		else
			return undefined; // Par défaut, on retourne `undefined`.
	}

	private static getArticlePriceFromReceipt(psSourceKey: string, paReceipts: IReceipt[] | ReadonlyArray<IReceipt>,
		pfGetReceiptArticleKey: (poArticle: IReceiptReturnArticle) => string): IItemPrice | undefined {

		if (ArrayHelper.hasElements(paReceipts)) { // On cherche le prix en priorité dans les bons.
			let loPriceResult: IItemPrice | undefined;

			for (let lnReceiptIndex = 0; lnReceiptIndex < paReceipts.length; ++lnReceiptIndex) {
				loPriceResult = paReceipts[lnReceiptIndex].items
					.find((poArticle: IReceiptReturnArticle) => pfGetReceiptArticleKey(poArticle) === psSourceKey)
					?.price;

				if (loPriceResult)
					return loPriceResult;
			}
		}

		return undefined;
	}

	private static getArticlePriceFromRacks(psItemId: string, poRackData: IReturnRack | IReturnRack[]): IItemPrice | undefined {
		const laRacks: IReturnRack[] = poRackData instanceof Array ? poRackData : [poRackData];
		let loPriceResult: IItemPrice | undefined;

		for (let lnRackIndex = 0; lnRackIndex < laRacks.length; ++lnRackIndex) {
			if (loPriceResult = laRacks[lnRackIndex].picking[psItemId]?.price)
				return loPriceResult;
		}

		return undefined; // Par défaut.
	}

	/** Récupère le prix d'un article, `undefined` si non trouvé.
	 * @param poItem Article dont il faut récupérer le prix.
	 * @param paReceipts Tableau des bons disponibles pour récupérer le prix de l'article.
	 * @param poRackData Portant ou tableau des portants où chercher le prix de l'article.
	 */
	public static getArticlePriceByItemIdAndPriceKey<T extends IPricedItem>(poItem: T, paReceipts: IReceipt[] | ReadonlyArray<IReceipt>,
		poRackData: IReturnRack | IReturnRack[]): IItemPrice | undefined {

		const lsItemKey: string = AggregationHelper.getAggregationKeyByItemIdAndPrice(poItem.itemId, poItem.priceType, poItem.priceValue);

		if (!StringHelper.isBlank(lsItemKey)) {
			const loReceiptPriceResult: IItemPrice | undefined = this.getArticlePriceFromReceipt(
				lsItemKey,
				paReceipts,
				(poArticle: IReceiptReturnArticle) => AggregationHelper.getAggregationKeyByItemIdAndPrice(poArticle.itemId, poArticle.price)
			);

			// Si le prix n'a pas pu être récupéré dans les bons, on le récupère depuis les portants.
			return loReceiptPriceResult ? loReceiptPriceResult : this.getArticlePriceFromRacks(poItem.itemId!, poRackData);
		}
		else
			return undefined; // Par défaut, on retourne `undefined`.
	}

	/** Récupère la quantité restante d'un article. `undefined` si non trouvée.
	 * @param poItemData Identifiant de l'article dont il faut récupérer la quantité.
	 * @param paReceipts Tableau des bons disponibles pour récupérer la quantité de l'article.
	 */
	public static getArticleQty(psItemId: string | undefined, paReceipts: IReceipt[]): number | undefined {
		let lnPriceResult: number | undefined;

		if (psItemId) {
			paReceipts.forEach((poReceipt: IReceipt) => {
				const loItem: IReceiptReturnArticle | undefined = poReceipt.items.find((poArticle: IReceiptReturnArticle) => poArticle.itemId === psItemId);

				if (loItem) {
					if (!lnPriceResult)
						lnPriceResult = loItem.qty;
					else
						lnPriceResult += (loItem.qty ?? 0);
				}
			});
		}

		return lnPriceResult;
	}

	/** Trie et retourne le tableau trié par date de création des portants (modifie le tableau d'origine, tri croissant).
	 * @param paRacks Tableau des portants à trier.
	 */
	public static sortByCreateDate(paRacks: IReturnRack[]): IReturnRack[] {
		return paRacks.sort((poRackA: IReturnRack, poRackB: IReturnRack) => DateHelper.compareTwoDates(poRackA.createDate, poRackB.createDate));
	}

	//#endregion

}