import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ObserveProperty } from '@calaosoft/osapp/modules/observable/decorators/observe-property.decorator';
import { ObservableProperty } from '@calaosoft/osapp/modules/observable/models/observable-property';

@Component({
	selector: 'calao-sub-header-title',
	templateUrl: './sub-header-title.component.html',
	styleUrls: ['./sub-header-title.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubHeaderTitleComponent {

	//#region PROPERTIES

	@Input() public cssClass: string;
	@ObserveProperty<SubHeaderTitleComponent>({ sourcePropertyKey: "cssClass" })
	public readonly observableCssClass = new ObservableProperty<string>("");

	//#endregion PROPERTIES

}
