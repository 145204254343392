<div class="categories-space">
	<ion-grid>
		<ng-container *ngFor="let category of categories; index as i;">
			<!-- Tableau de 3 par 3. -->
			<ion-row *ngIf="i % 3 === 0">
				<ion-col size="4">
					<calao-category-tile [title]="categories[i].title" [subtitle]="categories[i].subtitle"
						[value]="categories[i].quantity" [color]="categories[i].color" [icon]="categories[i].icon"
						(click)="onCategoryClick({category: $any(categories[i]), index: i})">
					</calao-category-tile>
				</ion-col>
				<ion-col *ngIf="categories.length > i+1" size="4">
					<calao-category-tile [title]="categories[i+1].title" [subtitle]="categories[i+1].subtitle"
						[value]="categories[i+1].quantity" [color]="categories[i+1].color" [icon]="categories[i+1].icon"
						(click)="onCategoryClick({category: $any(categories[i+1]), index: i+1})">
					</calao-category-tile>
				</ion-col>
				<ion-col *ngIf="categories.length > i+2" size="4">
					<calao-category-tile [title]="categories[i+2].title" [subtitle]="categories[i+2].subtitle"
						[value]="categories[i+2].quantity" [color]="categories[i+2].color" [icon]="categories[i+2].icon"
						(click)="onCategoryClick({category: $any(categories[i+2]), index: i+2})">
					</calao-category-tile>
				</ion-col>
			</ion-row>
		</ng-container>
	</ion-grid>
</div>