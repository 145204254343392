import { Type } from "@angular/core";
import { Exclude } from "class-transformer";
import { ExternalDataSourcesCache } from "../external-data-sources-cache";
import { IExternalDecoratorParams } from "../models/iexternal-decorator-params";

export function External<T extends Object>(
	poParams: IExternalDecoratorParams<T>
): PropertyDecorator {
	return function (poTarget: T, psPropertyKey: string, poDescriptor?: TypedPropertyDescriptor<any>) {
		Exclude()(poTarget, psPropertyKey); // Par défaut exclut la valeur car on est sur une donnée "external"

		ExternalDataSourcesCache.add({
			...poParams,
			type: poTarget.constructor as Type<T>,
			fieldName: psPropertyKey
		});

		return poDescriptor;
	};
};
