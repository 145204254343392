import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { FlagService } from '../../services/flag.service';

@Injectable({
	providedIn: 'root'
})
export class WaitForFlagsGuard implements CanActivate {

	//#region METHODS

	constructor(private isvcFlag: FlagService) { }

	public canActivate(
		poRoute: ActivatedRouteSnapshot
	): Observable<boolean> {
		if (!poRoute.data || !poRoute.data.flags || !(poRoute.data.flags instanceof Array))
			throw new Error("Attribut 'flags' manquant pour le gardien WaitForFlags.");

		return this.isvcFlag.waitForFlags(poRoute.data.flags as string[], true);
	}

	//#endregion

}
