import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IBluetoothDevice } from './bluetooth/models/IBluetoothDevice';
import { IInventoryParam } from './devices/models/IInventoryParam';
import { IRfidAcquisition } from './devices/models/IRfidAcquisition';
import { TslService } from './devices/tsl/tsl.service';

/* Un inventaire est en continue. Une lecture est par paquets. */
@Injectable()
export class RfidService {

	//#region METHODS

	constructor(private isvcTsl: TslService) { }

	public connect(psDeviceId: string): Observable<IBluetoothDevice> {
		return this.isvcTsl.connect(psDeviceId);
	}

	/** Démarre un inventaire. L'boservable émet au fur et à mesure. */
	public startInventory(poParam: IInventoryParam): Observable<IRfidAcquisition> {
		return this.isvcTsl.startReading(poParam);
	}

	public get inventory(): Observable<IRfidAcquisition> {
		return this.isvcTsl.inventory$;
	}

	/** Démarre une lecture. L'observable est émis à la fin du scan. */
	public startReading(poParam: IInventoryParam): Observable<IRfidAcquisition> {
		return this.isvcTsl.startReading(poParam);
	}

	public get reading(): Observable<IRfidAcquisition[]> {
		return this.isvcTsl.reading$;
	}

	//#endregion

}