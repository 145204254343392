import { Injectable } from '@angular/core';
import { defer, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { Pack } from '../../../pack/models/Pack';
import { PackService } from '../../../pack/services/pack.service';
import { TaskService } from '../../services/task.service';
import { DeliveryBarcodeItem } from '../models/delivery-barcode-item';
import { DeliveryItem } from '../models/delivery-item';
import { DeliveryRack } from '../models/delivery-rack';
import { DeliveryRackPack } from '../models/delivery-rack-pack';
import { DeliveryReceipt } from '../models/delivery-receipt';
import { DeliveryReceiptPack } from '../models/delivery-receipt-pack';
import { DeliveryTask } from '../models/delivery-task';
import { IDeliveryTask } from '../models/idelivery-task';

@Injectable()
export class DeliveryService {

	//#region FIELDS

	protected static readonly C_LOG_ID = "DLVRY.S::";

	//#endregion FIELDS

	//#region METHODS

	constructor(private readonly isvcTask: TaskService, private readonly isvcPack: PackService) { }

	/** Écoute les changements d'une tâche et retourne la tâche à jour.
	 * @param poTaskData Identifiant de la tâche ou tâche de livraison.
	 */
	public listenTaskChanges$(poTaskData: string | DeliveryTask): Observable<DeliveryTask> {
		return defer(() => {
			// Si la tâche est passée en paramètre, pas besoin la récupérer en base on écoute juste les changements.
			if (poTaskData instanceof DeliveryTask)
				return this.isvcTask.getLocalChanges(poTaskData, true).pipe(startWith(poTaskData));
			else // Sinon on récupère la tâche et ses changements.
				return this.isvcTask.getTask<IDeliveryTask>(poTaskData, true);
		})
			.pipe(map((poTask: IDeliveryTask) => DeliveryTask.getInstance(poTask)));
	}

	/** Récupère la tâche en base de données si l'identifiant est passé en paramètre, sinon retourne directement la tâche de façon asynchrone.
	 * @param poTaskData Identifiant de la tâche ou tâche de livraison.
	 */
	protected getTaskAsync(poTaskData: string | DeliveryTask): Promise<DeliveryTask> {
		return poTaskData instanceof DeliveryTask ?
			Promise.resolve(poTaskData) : this.isvcTask.getTask<IDeliveryTask>(poTaskData).toPromise().then((poTask: IDeliveryTask) => DeliveryTask.getInstance(poTask));
	}

	/** Récupère les conditionnements associés aux identifiants.
	 * @param paPackTypeIds Identifiants de conditionnements.
	 */
	public getPacksFromIds(paPackTypeIds: string[]): Observable<Pack[]> {
		return this.isvcPack.getPacksFromIds(paPackTypeIds);
	}

	/** Récupère le tableau des articles des conditionnements des bons présent dans un portant (articles présents dans les conditionnements scannés).
	 * @param poRack Portant dont il faut récupérer les articles à scanner.
	 * @param poTask Tâche dont est issue le portant.
	 * @param paRejectedReasonIds Identifiants des motifs dont le comportement de rejet est vrai.
	 */
	public getReceiptPackItemsPresentInRack(poRack: DeliveryRack, poTask: DeliveryTask, paRejectedReasonIds: string[]): DeliveryItem[] {
		// Tableau des conditionnements des bons de la tâche.
		const laTaskPacks: DeliveryReceiptPack[] = this.isvcPack.extractReceiptPacks(poTask.receipts);
		// Tableau des conditionnements scannés du portant.
		const laRackPacks: DeliveryRackPack[] = this.isvcPack.getPacksFromCurrentRack(poRack);
		// On filtre les conditionnements des bons de la tâche en récupérant uniquement ceux présents dans ceux scannés du portant et en enlevant ceux dont le comportement de rejet est vrai.
		const laFilteredReceiptPacks: DeliveryReceiptPack[] = laTaskPacks.filter((poReceiptPack: DeliveryReceiptPack) =>
			laRackPacks.some((poRackPack: DeliveryRackPack) => poRackPack.code === poReceiptPack.code && !paRejectedReasonIds.includes(poRackPack.reasonId))
		);

		// Tableau des articles des conditionnements scannés du portant.
		return ArrayHelper.flat(laFilteredReceiptPacks.map((poPack: DeliveryReceiptPack) => poPack.items));
	}

	/** Retourne la liste des articles qui n'ont pas encore été scannés du portant.
	 * @param paToScanItems Liste des articles à scanner du portant.
	 * @param poRack Portant de livraison.
	 */
	public getRemainingBarcodeItems(paRemainingItems: DeliveryBarcodeItem[], poRack: DeliveryRack): DeliveryBarcodeItem[] {
		return paRemainingItems.filter((poRemainingItem: DeliveryBarcodeItem) => !poRack.hasItem(poRemainingItem.itemId, poRemainingItem.price));
	}

	/** Retourne la liste des conditionnements d'une tâche de livraison.
	 * @param poTask Tâche de livraison.
	 */
	public getReceiptPacksFromTask(poTask: DeliveryTask): DeliveryReceiptPack[] {
		return ArrayHelper.flat(poTask.receipts.map((poReceipt: DeliveryReceipt) => poReceipt.packs));
	}

	//#endregion

}
