import { IDmsDocumentData } from "../../dms/model/IDmsDocumentData";
import { ModelMatch } from "../../utils/models/decorators/model-match.decorator";
import { EMessageAttachmentType } from "./emessage-attachment-type";
import { IMessageAttachment } from "./imessage-attachment";
import { IMessageFileAttachment } from "./imessage-file-attachment";
import { MessageAttachment } from "./message-attachment";

const C_TYPE = EMessageAttachmentType.file;

@ModelMatch((poData: IMessageAttachment) => poData.type === C_TYPE, MessageAttachment)
export class MessageFileAttachment extends MessageAttachment implements IMessageFileAttachment {

	//#region PROPERTIES

	public dmsData: IDmsDocumentData;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(poData?: Omit<IMessageFileAttachment, "type">) {
		super({ ...(poData ?? {}), type: C_TYPE });
	}

	//#endregion METHODS

}
