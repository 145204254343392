import { Type } from "class-transformer";
import { Usage } from "./usage";

export class TextGenerationResponse {

	//#region PROPERTIES

	public text!: string;

	@Type(() => Usage)
	public usage?: Usage;

	//#endregion PROPERTIES

}