import { OptionsDTO } from "./options-dto";
import { ResourceDTO } from "./resource-dto";
import { StepDTO } from "./step-dto";

export class TourOptimizationRequestDTO {
	public options?: OptionsDTO;

	public resources: ResourceDTO[];

	public steps: StepDTO[];
}
