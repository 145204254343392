/** Permet de chronométrer la date entre plusieurs événements. */
export class Timer {

	//#region PROPERTIES

	/** Retourne la durée cumulée de tous les ajouts en millisecondes. */
	public duration = 0;

	//#endregion

	//#region METHODS

	constructor() {

	}

	public add(pdStart: string | Date, pdEnd: string | Date): void {
		this.duration += +new Date(pdEnd) - +new Date(pdStart);
	}

	/** Retourne la moyenne de temps pour le nombre d'élément passé en paramètre. */
	public average(pnValue: number): number {
		return pnValue === 0 ? 0 : (this.duration / pnValue);
	}

	//#endregion

}