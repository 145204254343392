<ng-container calaoHeader [title]="type === actionquisitionType.rfid ? 'Lecture RFID' : 'Lecture code-barres'">
</ng-container>

<ion-content>
	<ion-button (click)="onScanClicked()" expand="full">Scanner</ion-button>

	<ng-container [ngSwitch]="representation">
		<calao-logistics-simple-repr *ngSwitchCase="representationMode.SIMPLE" [acquisitions]="getReadings()"
			[quality]="getQuality()" [nominal]="nominal" [displayTotal]="type === actionquisitionType.barcode"
			[lastScan]="lastScan">
		</calao-logistics-simple-repr>

		<calao-logistics-advanced-repr *ngSwitchCase="representationMode.ADVANCED" [acquisitions]="getReadings()"
			[quality]="getQuality()" [nominal]="nominal" [displayCode]="codeCallback"
			[counter]="type === actionquisitionType.barcode ? getCounter() : undefined"
			[hasSearchButton]="type === actionquisitionType.rfid" [lastScan]="lastScan">
		</calao-logistics-advanced-repr>
	</ng-container>
</ion-content>

<!-- Gestion des fab-buttons. -->
<ion-fab horizontal="end" vertical="bottom" slot="fixed">
	<ion-fab-button>
		<ion-icon name="caret-up-circle"></ion-icon>
	</ion-fab-button>
	<ion-fab-list side="top">
		<!-- Export par mail. -->
		<ion-fab-button (click)="sendByMail()">
			<ion-icon name="mail"></ion-icon>
		</ion-fab-button>
		<!-- Ouverture de la modale de débogage. -->
		<ion-fab-button (click)="openModalDebug()">
			<ion-icon name="speedometer"></ion-icon>
		</ion-fab-button>
	</ion-fab-list>
</ion-fab>

<ion-footer>
	<ion-button (click)="onReloadButtonClicked()">
		<ion-icon slot="start" name="refresh"></ion-icon>
		Recharger
	</ion-button>

	<ion-button (click)="onSwitchRepresentationClicked()">
		<ion-icon slot="start" name="map"></ion-icon>
		{{ representation === representationMode.SIMPLE ? "simple" : "Avancé" }}
	</ion-button>

	<ion-button *ngIf="type === actionquisitionType.rfid" (click)="onQualityAlgoButtonClicked()">
		<ion-icon slot="start" name="calculator"></ion-icon>
		{{ this.hasQualityAlgo() ? "SQAL" : "Sans SQAL" }}
	</ion-button>

	<ion-button (click)="onNominalButtonClicked()">
		<ion-icon slot="start" name="git-branch"></ion-icon>
		{{ this.hasNominal() ? "Nominale" : "Sans nominale" }}
	</ion-button>
</ion-footer>