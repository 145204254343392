import { Injectable } from "@angular/core";
import { ArrayHelper } from "../../../helpers/arrayHelper";
import { StringHelper } from "../../../helpers/stringHelper";
import { LoadingService } from "../../../services/loading.service";
import { PlatformService } from "../../../services/platform.service";
import { Loader } from "../../loading/Loader";
import { SqlService } from "../../sqlite/services/sql.service";
import { IChangeTrackerItem } from "../../store/change-tracking/models/ichange-tracker-item";
import { ILot } from "../../store/change-tracking/models/ilot";
import { BrowserChangeTrackingService } from "../../store/change-tracking/services/browser-change-tracking.service";
import { ChangeTrackingService } from "../../store/change-tracking/services/change-tracking.service";
import { MobileChangeTrackingService } from "../../store/change-tracking/services/mobile-change-tracking.service";
import { PatchBase } from "./patch-base";

@Injectable()
export class ChangeTrackingFromIdbToSqlitePatch extends PatchBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "CHG.TRCK.IDB.TO.SQLT.PATCH::";

	//#endregion FIELDS

	//#region METHODS

	constructor(
		private readonly isvcSql: SqlService,
		private readonly isvcLoading: LoadingService,
		private readonly isvcBrowserChangeTracking: BrowserChangeTrackingService,
		private readonly isvcMobileChangeTracking: MobileChangeTrackingService,
		private readonly isvcPlatform: PlatformService
	) {
		super("Migration des bases de données de changeTracking");
	}

	public override async applyPatchAsync(): Promise<boolean> {
		if (this.isvcPlatform.isMobileApp) {
			console.debug(`${ChangeTrackingFromIdbToSqlitePatch.C_LOG_ID}Apply patch \
for ${this.isvcPlatform.isAndroid ? "android" : this.isvcPlatform.isIOS ? "iOS" : this.isvcPlatform.platform}.`);
			let loLoader: Loader | undefined;

			try {
				loLoader = await this.isvcLoading.create(`${this.patchDescription} ...`);
				await loLoader.present();

				const laDatabases: IDBDatabaseInfo[] = await indexedDB.databases();
				const laChangeTrackingDatabases: IDBDatabaseInfo[] = laDatabases.filter((poDb: IDBDatabaseInfo) =>
					poDb.name?.startsWith(ChangeTrackingService.C_TRACKER_DB_NAME_PREFIX)
				);

				for (let lnIndex = 0; lnIndex < laChangeTrackingDatabases.length; ++lnIndex) {
					const loDatabase: IDBDatabaseInfo = laChangeTrackingDatabases[lnIndex];

					await this.migrateDatabase(loLoader, lnIndex, laChangeTrackingDatabases, loDatabase);
				}

				console.debug(`${ChangeTrackingFromIdbToSqlitePatch.C_LOG_ID}Patch applied.`);
			}
			finally {
				loLoader?.dismiss();
			}
		}

		return true;
	}

	private async migrateDatabase(
		poLoader: Loader,
		pnIndex: number,
		paChangeTrackingDatabases: IDBDatabaseInfo[],
		poDatabase: IDBDatabaseInfo
	): Promise<void> {
		poLoader.text = this.getPatchMessage(pnIndex, paChangeTrackingDatabases.length);
		if (!StringHelper.isBlank(poDatabase.name)) {
			console.debug(`${ChangeTrackingFromIdbToSqlitePatch.C_LOG_ID}Migrate database ${poDatabase.name}.`);
			const lsDatabaseId: string = ArrayHelper.getLastElement(poDatabase.name.split(ChangeTrackingService.C_TRACKER_DB_NAME_PREFIX));
			const lsTrackerDatabaseId: string = this.isvcMobileChangeTracking.getTrackerDatabaseId(lsDatabaseId);
			try {
				// On ouvre la base car elle doit être ouverte pour être supprimée.
				await this.isvcMobileChangeTracking.openDatabase(lsTrackerDatabaseId);
				// On supprime la base de données pour gérer le cas de l'interruption du patch.
				await this.isvcSql.removeDatabaseAsync(lsTrackerDatabaseId);
				const laLots: ILot[] = await this.isvcBrowserChangeTracking.getLotsAsync(lsDatabaseId);
				const loLotsById: Map<number | undefined, ILot> = ArrayHelper.groupByUnique(laLots, (poLot: ILot) => poLot.id);
				const laTracked: IChangeTrackerItem[] = await this.isvcBrowserChangeTracking.getTrackedAsync(lsDatabaseId, ArrayHelper.getLastElement(laLots));
				const loTrackedByLotId: Map<number, IChangeTrackerItem[]> = ArrayHelper.groupBy(laTracked, (poTracked: IChangeTrackerItem) => poTracked.lotId!); // On aura obligatoirement un id de lot

				for (const laResults of loTrackedByLotId) {
					const loLot: ILot | undefined = loLotsById.get(laResults[0]);

					if (loLot) { // Check juste pour le typage, le lot devrait toujours exister.
						// On met à jour le lot au début pour profiter de la sauvegarde du since.
						await this.isvcMobileChangeTracking.getAndUpdateLastLotAsync(lsDatabaseId, loLot.since);
						this.isvcMobileChangeTracking.trackMultipleAsync(lsDatabaseId, laResults[1]);
					}
				}

				// Une fois la migration de la base terminée, on supprime la base indexedDb;
				await this.deleteIndexedDbDatabase(poDatabase.name, lsDatabaseId);
			}
			catch (poError) {
				console.error(`${ChangeTrackingFromIdbToSqlitePatch.C_LOG_ID}Error while migrating \
database ${poDatabase.name}.`, poError);
				throw poError;
			}
		}
	}

	private deleteIndexedDbDatabase(psDatabaseName: string, psDatabaseId: string): Promise<void> {
		return new Promise((pfResolve, pfReject) => {
			const loDeleteRequest: IDBOpenDBRequest = indexedDB.deleteDatabase(psDatabaseName);

			loDeleteRequest.onsuccess = () => pfResolve();
			loDeleteRequest.onblocked = () => this.isvcBrowserChangeTracking.closeDbAsync(psDatabaseId);
			loDeleteRequest.onerror = () => pfReject(loDeleteRequest.error);
		});
	}

	private getPatchMessage(pnIndex: number, pnTotalCount: number): string {
		return `${this.patchDescription} ${pnIndex + 1} sur ${pnTotalCount} ...`;
	}

	//#endregion METHODS

}
