<ng-container
	*ngTemplateOutlet="(observableIsReadOnly.value$ | async) ? readonlyTemplate : editTemplate"></ng-container>

<ng-template #editTemplate>
	<div class="date-wrapper">
		<ng-container *ngIf="observableFieldValue.value$ | async as dateValue">
			<osapp-date-time [params]="observableDatePickerParams.value$ | async" [model]="dateValue"
				(modelChange)="onDateChanged($event)" class="picker date"></osapp-date-time>

			<osapp-date-time *ngIf="!(observableHideTimePicker.value$ | async)"
				[params]="observableTimePickerParams.value$ | async" [model]="dateValue" (modelChange)="onDateChanged($event)"
				class="picker time"></osapp-date-time>
		</ng-container>
	</div>
</ng-template>

<ng-template #readonlyTemplate>
	<ion-label>{{ observableFieldValue.value$ | async | dateWithLocale: 'fr-FR' : 'EEE dd MMMM yyyy à HH:mm'
		}}</ion-label>
</ng-template>