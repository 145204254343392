export enum EKnownTaskType {

	/** Stock départ === Comptage. */
	counting = "counting",
	/** Inventaire. */
	inventory = "inventory",
	/** Reprise. */
	return = "return",
	/** Livraison. */
	delivery = "delivery"
}