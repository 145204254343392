/** Permet de mettre à disposition des méthodes pour manipuler les barre-codes. */
export abstract class BarcodeHelper {

	//#region METHODS

	/** Permet de supprimer la clé de contrôle d'un code-barres. */
	public static removeBarcodeControlKey(psCode: string): string {
		const lsResult: string = psCode.length === 13 ? psCode.substring(0, psCode.length - 1) : psCode;

		if (lsResult.length !== 12) // Si ce n'est pas un code EAN-13 (avec ou sans bit de parité).
			console.warn(`BARCODE.H::Lecture d'un code-barres inattendu: '${psCode}'.`);

		return lsResult;
	}

	//#endregion
}