<ion-grid>
	<!-- En-tête -->
	<ion-row class="header">
		<ion-col *ngFor="let header of headers" [size]="columnSize">
			{{ header }}
		</ion-col>
	</ion-row>

	<ion-row *ngFor="let line of lines">
		<ion-col [size]="columnSize">
			<div class="epc">{{ line.code }}</div>
		</ion-col>
		<ion-col [size]="columnSize">
			<div class="percentage">{{ line.percentage }}</div>
		</ion-col>
	</ion-row>
</ion-grid>