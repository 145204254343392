import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SettingsModule } from '../../../settings/settings.module';
import { CatalogDiagnosticsComponent } from './catalog-diagnostics.component';

@NgModule({
	declarations: [CatalogDiagnosticsComponent],
	imports: [
		CommonModule,
		IonicModule,
		SettingsModule
	],
	providers: [],
	exports: [CatalogDiagnosticsComponent]
})
export class CatalogDiagnosticsModule { }
