import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Observable, Subject, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ObservableArray } from '../../../../observable/models/observable-array';
import { SlidingListItemComponent } from '../../../../sliding-list/sliding-list-item/sliding-list-item.component';
import { DestroyableComponentBase } from '../../../../utils/components/destroyable-component-base';
import { secure } from '../../../../utils/rxjs/operators/secure';
import { ILotSegment } from '../models/ILotSegment';
import { IStoreCategory } from '../models/IStoreCategory';
import { IStoreLot } from '../models/IStoreLot';
import { IStoreSegment } from '../models/IStoreSegment';

interface ICategorySegmentWithIndex extends IStoreCategory {
	originalIndex: number;
	quantity: number;
}
interface ILotWithIndex {
	segments: ICategorySegmentWithIndex[];
	originalIndex: number;
}

@Component({
	selector: "calao-lots",
	templateUrl: './lots.component.html',
	styleUrls: ['./lots.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotsComponent extends DestroyableComponentBase implements OnDestroy {

	//#region FIELDS

	/** Évènement levé lorsque l'utilisateur clique sur le bouton de suppression d'un segment. */
	@Output("remove") private readonly moRemoveEvent = new EventEmitter<ILotSegment>();
	/** Évènement levé lorsque l'utilisateur clique sur le bouton d'édition d'un segment. */
	@Output("edit") private readonly moEditEvent = new EventEmitter<ILotSegment>();

	private readonly moCategoryMapChangedSubject = new Subject<void>();

	//#endregion

	//#region PROPERTIES

	private readonly moLots = new ObservableArray<IStoreLot>();
	/** Tableau des lots de comptage et segments à afficher. */
	public get lots(): ObservableArray<IStoreLot> { return this.moLots; }
	@Input() public set lots(poData: IStoreLot[] | ObservableArray<IStoreLot>) {
		//! On force la mise à jour du tableau lors de la réception d'une mise à jour de la tâche afin de pouvoir notifier les abonnés des nouveautés
		//! car on a les mêmes références donc pas de changement pour le tableau quand il essaie de se mettre à jour.
		const lfAreLotsEqual: (poItemA: IStoreLot, poItemB: IStoreLot) => boolean =
			(poItemA: IStoreLot, poItemB: IStoreLot) => false;

		if (poData instanceof ObservableArray)
			this.moLots.resetSubscription(poData.changes$, lfAreLotsEqual);
		else
			this.moLots.resetArray(poData, lfAreLotsEqual);
	}

	private moCategoryMap: Map<string, IStoreCategory>;
	@Input() public set categories(paCategories: Map<string, IStoreCategory>) {
		if (paCategories !== this.moCategoryMap) {
			this.moCategoryMap = paCategories;
			this.moCategoryMapChangedSubject.next();
		}
	}

	private mfSegmentsFilter: (poSegment: IStoreSegment) => boolean = _ => true;
	/** Fonction de filtre pour faire apparaître ou non des segments de lot. */
	@Input() public set segmentsFilter(pfSegmentsFilter: (poSegment: IStoreSegment) => boolean) {
		if (pfSegmentsFilter && pfSegmentsFilter !== this.mfSegmentsFilter) {
			this.mfSegmentsFilter = pfSegmentsFilter;
			this.moCategoryMapChangedSubject.next();
		}
	}

	/** Flux du tableau des lots dans le sens inverse. */
	public readonly reversedLots: ObservableArray<ILotWithIndex>;

	//#endregion

	//#region METHODS

	constructor() {
		super();

		this.reversedLots = new ObservableArray(this.getReversedLots$().pipe(secure(this)));
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
		this.moCategoryMapChangedSubject.complete();
	}

	private getReversedLots$(): Observable<ILotWithIndex[]> {
		return combineLatest([this.moLots.changes$, this.moCategoryMapChangedSubject.asObservable()])
			.pipe(
				filter(_ => !!this.moCategoryMap), // Il faut que le tableau ET la map soient renseignés pour exécuter le code.
				map(([paLots, _]: [IStoreLot[], void]) => {
					return paLots.slice() // slice() sinon c'est le tableau d'origine qui est retourné.
						.reverse()
						.map((poStoreLot: IStoreLot, pnIndex: number): ILotWithIndex => {
							const laFilteredCategorySegments: ICategorySegmentWithIndex[] = poStoreLot.segments.map((poSegment: IStoreSegment, pnSegmentIndex: number): ICategorySegmentWithIndex | undefined => {
								if (this.mfSegmentsFilter(poSegment)) { // Si on doit afficher ce segment, on lui attribue son index.
									return {
										originalIndex: pnSegmentIndex,
										quantity: poSegment.quantity,
										...this.moCategoryMap.get(poSegment.category)
									};
								}
								else // Sinon on retourne un élément à filtrer.
									return undefined;
							})
								.filter((poSegment: ICategorySegmentWithIndex) => !!poSegment); // Filtrage des données à afficher.

							return { originalIndex: paLots.length - pnIndex - 1, segments: laFilteredCategorySegments };
						})
						.filter((poLot?: ILotWithIndex) => !!poLot); // Filtrage des données non renseignées.
				})
			);
	}

	public onEdit(poLot: ILotWithIndex, poCategorySegment: ICategorySegmentWithIndex, poComponent: SlidingListItemComponent): void {
		this.moEditEvent.emit({ lotIndex: poLot.originalIndex, segmentIndex: poCategorySegment.originalIndex } as ILotSegment);
		poComponent.closeItemSlidingOptions();
	}

	public onRemove(poLot: ILotWithIndex, poCategorySegment: ICategorySegmentWithIndex, poComponent: SlidingListItemComponent): void {
		this.moRemoveEvent.emit({ lotIndex: poLot.originalIndex, segmentIndex: poCategorySegment.originalIndex } as ILotSegment);
		poComponent.closeItemSlidingOptions();
	}

	//#endregion

}