import { OSActionButton } from '@awesome-cordova-plugins/onesignal/ngx';
import { capitalize, lowerFirst } from 'lodash';
import { NotificationClickEvent } from 'onesignal-cordova-plugin';
import { ETaskPrefix } from '../model/backgroundTask/ETaskPrefix';

export abstract class StringHelper {

	//#region METHODS

	/** Vérifie qu'une chaîne de caractère est valide : ne doit pas être vide ou composée uniquement d'espaces.
	 * @param psString chaîne de caractères dont il faut vérifier si elle est valide ou non.
	 */
	public static isBlank(psString?: string): psString is undefined | "" {
		return !psString || typeof psString !== "string" || psString.trim().length === 0; // Remplacement des caractères d'espace par rien.
	}

	/** Passe la 1ere lettre de la chaîne de caractère en majuscule.
	 * @param psString
	 */
	public static toPascalCase(psString: string): string {
		return `${psString[0].toUpperCase()}${psString.substring(1)}`;
	}

	/** Passe la 1ere lettre de la chaîne de caractère en majuscule et les autres en minuscules.
	 * @param psString
	 */
	public static toTitleCase(psString: string): string {
		return capitalize(psString);
	}

	/** Passe la 1ère lettre de la chaîne de caractères en minuscule et retourne la nouvelle chaîne obtenue.
	 * @param psString
	 */
	public static lowerFirst(psString: string): string {
		return lowerFirst(psString);
	}

	/** Génère une chaîne de caractère contenant les champs de l'objet dont les nom sont données, en les séparant avec le séparateur indiqué.
	 * @param poObect
	 * @param paAttributeNames
	 * @param psSeparator
	 */
	public static concatenateAttributes(poObect: any, paAttributeNames: string[], psSeparator: string): string {
		let lsResult = "";

		paAttributeNames.forEach((psKey: string) => lsResult += `${StringHelper.isBlank(poObect[psKey]) ? "" : poObect[psKey]}${psSeparator}`);

		return lsResult.trim();
	}

	/** Retourne un Id de taskDescriptor pour les tâches des notifications.\
	 * On recherche l'id contenu dans le ActionID, sinon on recherche un _id dans les données additionnelles, sinon on met un texte représentant aucun ID.
	 * @param peTaskPrefix Préfixe de la tâche.
	 * @param psTaskName Nom donné à la tâche.
	 * @param poNotification Information retournée par une notification quand on clique dessus.
	 */
	public static getFormatedRouteTaskDescriptor(peTaskPrefix: ETaskPrefix, psTaskName: string, poNotification: NotificationClickEvent) {
		const loActionButton: OSActionButton | undefined = poNotification.notification.actionButtons?.[0] as OSActionButton | undefined;
		const lsID: string = poNotification.result.actionId ?? loActionButton?.id ?? "NO_ID";

		return `${peTaskPrefix}${psTaskName}${lsID}`;
	}

	/** Retourne `true` si la donnée est une chaîne de carctères non vide, `false` sinon.
	 * @param poData Donnée dont il faut vérifier si elle est uns string valide ou non.
	 */
	public static isValid(poData: any): poData is string {
		return typeof poData === "string" && !this.isBlank(poData);
	}

	/** Format une chaîne de caractères en remplaçant des chaînes de caractères par d'autres.
	 * @param psTarget Chaîne de caractères de base, qui doit être retourné avec des transformations.
	 * @param pmapReplacer Dictionnaire où les clefs sont les sous-chaîne de `psTarget` à substituer et les valeurs, les chaînes de substitution.
	 * @example StringHelper.format("Je suis {prenom} {nom}", new Map([["{nom}", ["MACHIN"]], ["{prenom}", ["Toto"]]])); // Retourne "Je suis Toto MACHIN".
	 */
	public static format(psTarget: string, pmapReplacer: Map<string, string>): string {
		const loRegex = new RegExp(Array.from(pmapReplacer.keys()).join("|"), "gi");
		return psTarget.replace(loRegex, (psMatchedKey: string) => pmapReplacer.get(psMatchedKey) ?? "");
	}

	/** Retourne un `string` représentant les queries string de l'objet passé en paramètre.
	 * @param poQueries Objet clé-valeur.
	 * @example StringHelper.toQueryString({ "val1": "Tr uc", "val2": "machin"}); // Retourne "val1=Tr%20uc&val2=machin".
	 */
	public static toQueryString(poQueries: Object): string {
		return Object.keys(poQueries)
			.map((poKey) => `${poKey}=${encodeURIComponent(`${poQueries[poKey]}`.replace(/\s/g, '_'))}`)
			.join('&');
	}

	/** Convertit les queryParams d'une URL sous forme d'un objet.
	 * @param psUrl L'url.
	 */
	public static parseQueryParams(psUrl: string): any {
		const laQueryParams: string[] = psUrl.substring(psUrl.indexOf("?") + 1).split("&");
		const loResult: any = {};

		laQueryParams.forEach((lsParam: string) => {
			const [key, value] = lsParam.split("=");
			loResult[key] = value;
		});

		return loResult;
	}

	/** Compare deux chaînes de caractères et renvoie un nombre positif si la première chaîne se trouve alphabétiquement après la seconde,
	 * un nombre négatif si c'est l'inverse ou 0 si elles sont égales.
	 * @param psStringA Chaîne de caractères à comparer.
	 * @param psStringB Autre chaîne de caractères à comparer.
	 */
	public static compare(psStringA: string = "", psStringB: string = ""): number {
		const lbIsBlankA: boolean = this.isBlank(psStringA);
		const lbIsBlankB: boolean = this.isBlank(psStringB);

		if (lbIsBlankA && !lbIsBlankB) // Chaîne A vide/non renseignée mais pas la chaîne B : on considère que la chaîne vide ou non renseignée se trouve après celle valide.
			return 1;
		else if (!lbIsBlankA && lbIsBlankB) // Chaîne A renseignée et non vide mais pas la chaîne B : on considère que la chaîne vide ou non renseignée se trouve après celle valide.
			return -1;
		else if (lbIsBlankA && lbIsBlankB) // Les deux chaînes sont non renseignées ou vide : on considère aucune différence, elles se valent.
			return 0;
		else
			return psStringA.toLocaleLowerCase().localeCompare(psStringB.toLocaleLowerCase());
	}

	/** Retourne une chaîne de caractères sans signes diacritiques, chaîne vide si la chaîne de caractères n'est pas valide.
	 * @example
	 * "Éphémère crème brûlée" -> "Ephemere creme brulee"
	 * @param psString Chaîne de caractères à traiter.
	 */
	public static removeDiacritic(psString: string): string {
		return this.isBlank(psString) ? "" : psString.normalize("NFD").replace(/\p{Diacritic}/gu, "");
	}

	/** Récupère le bon article défini (`le/la/l'/les`) en fonction d'un mot, chaîne vide si le mot n'est pas valide.
	 * @param psWord Mot à traiter.
	 * @param poParams Objet de paramètres pour le traitement.
	 */
	public static isFirstLetterVowel(psWord: string): boolean {
		const lsFirstLetter: string = this.removeDiacritic(psWord[0]?.toLowerCase() ?? "");
		return !this.isBlank(lsFirstLetter) && ["a", "e", "i", "o", "u", "y", "æ", "œ"].some((psLetter: string) => lsFirstLetter === psLetter);
	}

	/** Récupère le début ou la fin en commun entre les 2 chaînes de caractères.
	 * @param psStringA
	 * @param psStringB
	 * @param psFrom `start` par défaut
	 * @returns
	 */
	public static getMatchingSubstring(psStringA?: string, psStringB?: string, psFrom: "start" | "end" = "start"): string {
		let lsMatchingSubstring = "";
		const lbIsFromEnd: boolean = psFrom === "end";

		if (!StringHelper.isBlank(psStringA) && !StringHelper.isBlank(psStringB)) {
			for (
				let lnIndex = lbIsFromEnd ? psStringA.length : 0;
				lbIsFromEnd ? lnIndex > 0 : lnIndex < psStringA.length;
				lbIsFromEnd ? lnIndex-- : lnIndex++
			) {
				const lsChar: string = psStringA.charAt(lnIndex);
				if (lsChar === psStringB.charAt(lnIndex))
					lsMatchingSubstring += lsChar;
				else
					break;
			}
		}

		return lbIsFromEnd ? lsMatchingSubstring.split("").reverse().join("") : lsMatchingSubstring;
	}

	//#endregion

}