import { ResolveModel } from '../../../../utils/models/decorators/resolve-model.decorator';
import { DeliveryReceiptPack } from './delivery-receipt-pack';
import { IDeliveryReceipt } from './idelivery-receipt';

export class DeliveryReceipt implements IDeliveryReceipt {

	//#region PROPERTIES

	/** @implements */
	public readonly label: string;

	/** @implements */
	@ResolveModel(DeliveryReceiptPack)
	public readonly packs: ReadonlyArray<DeliveryReceiptPack>;

	//#endregion

	//#region METHODS

	constructor(psLabel: string, paPacks: ReadonlyArray<DeliveryReceiptPack>) {
		this.label = psLabel;
		this.packs = paPacks;
	}

	//#endregion

}