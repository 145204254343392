import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { SlidesHeaderComponent } from './components/slides-header/slides-header.component';

const components: Type<any>[] = [
	SlidesHeaderComponent
];

@NgModule({
	declarations: components,
	imports: [
		CommonModule,
		IonicModule
	],
	exports: components
})
export class SlidesModule { }