import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StringHelper } from '../../../../helpers/stringHelper';
import { EDatabaseRole } from '../../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../../model/store/IDataSource';
import { Store } from '../../../../services/store.service';
import { IDashboard } from '../models/IDashboard';

@Injectable()
export class DashboardService {

	//#region METHODS

	constructor(private readonly isvcStore: Store) { }

	/** Récupère un dashboard dans la base de données, `undefined` si non trouvé.
	 * @param psId Identifiant du dashboard à récupérer.
	 * @param pbLive Indique si on récupère de façon continue le dashboard (lors de mise à jour par exemple), `false` par défaut.
	 */
	public getDashboard(psId?: string, pbLive: boolean = false): Observable<IDashboard | undefined> {
		if (StringHelper.isBlank(psId))
			return of(undefined);

		const loDataSource: IDataSource = {
			databasesIds: this.isvcStore.getDatabasesIdsByRole(EDatabaseRole.workspace),
			viewParams: {
				key: psId,
				include_docs: true
			},
			live: pbLive,
		} as IDataSource;

		return this.isvcStore.getOne<IDashboard>(loDataSource, false);
	}

	//#endregion
}