<calao-setting-header title="Catalogue" icon="list-outline"></calao-setting-header>

<calao-setting-clickable-item [value]="loadedVersion$ | async" [disabled]="isCatalogUpdating"
	(onItemClick)="updateCatalogVersionAsync()" label="Utilisée">
</calao-setting-clickable-item>

<calao-setting-clickable-item [value]="readyVersion$ | async" [disabled]="true" label="Disponible">
</calao-setting-clickable-item>

<calao-setting-clickable-item (onItemClick)="deleteCatalogAsync()" label="Suppression" value="Supprimer" color="danger">
</calao-setting-clickable-item>