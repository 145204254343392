import { ObjectHelper } from '../../../../helpers/objectHelper';

export class UnableToConnectError extends Error {

	constructor(psMessage: string) {
		super(psMessage);
		// Nécessaire pour que le 'instanceof UnableToConnectError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, UnableToConnectError);
	}

}