import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObserveProperty } from '../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { EDatabaseSyncStatus } from '../../../store/model/EDatabaseSyncStatus';
import { DestroyableComponentBase } from '../../../utils/components/destroyable-component-base';
import { secure } from '../../../utils/rxjs/operators/secure';

@Component({
	selector: 'calao-database-sync-animated-icon',
	templateUrl: './database-sync-animated-icon.component.html',
	styleUrls: ['./database-sync-animated-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatabaseSyncAnimatedIconComponent extends DestroyableComponentBase {

	//#region PROPERTIES

	@Input() public className?: string;
	@ObserveProperty<DatabaseSyncAnimatedIconComponent>({ sourcePropertyKey: "className" })
	private readonly moObservableClassName = new ObservableProperty<string>("");

	@Input() public databaseSyncStatus?: EDatabaseSyncStatus;
	@ObserveProperty<DatabaseSyncAnimatedIconComponent>({ sourcePropertyKey: "databaseSyncStatus" })
	private readonly moObservableDatabaseSyncStatus = new ObservableProperty<EDatabaseSyncStatus | undefined>();

	public readonly className$: Observable<string> = this.getClassName$().pipe(secure(this));

	//#endregion

	//#region METHODS

	constructor() {
		super();
	}

	private getClassName$(): Observable<string> {
		return combineLatest([
			this.moObservableClassName.value$,
			this.moObservableDatabaseSyncStatus.value$
		])
			.pipe(
				map(([psClassName, peStatus]: [string, EDatabaseSyncStatus | undefined]) => {
					if (peStatus === EDatabaseSyncStatus.serverToLocal)
						return `${psClassName} server-to-local`;
					else if (peStatus === EDatabaseSyncStatus.localToServer)
						return `${psClassName} local-to-server`;
					else // Statuts "serverToLocal" et "localToServer" pris en charge uniquement.
						return psClassName;
				})
			);
	}

	//#endregion METHODS

}