import { StringHelper } from "../../../helpers/stringHelper";
import { Document } from "./document";
import { Folder } from "./folder";
import { IDocExlorerFilterValues } from "./idoc-explorer-filter-values";

export class FolderContent {

	//#region PROPERTIES

	public current: Folder;
	/** Liste des dossiers enfants. */
	public folders: FolderContent[] = [];
	/** Liste des fichiers du dossier courant. */
	public documents: Document[] = [];

	//#endregion

	//#region METHODS

	public getDocumentsRecursively(pbExcludeTrash?: boolean): Document[] {
		return [...this.documents, ...(this.folders.map((poFolder: FolderContent) => pbExcludeTrash && poFolder.current.isInTrash ? [] : poFolder.getDocumentsRecursively())).flat()];
	}

	public searchDocumentsRecursively(poFilters: IDocExlorerFilterValues): Document[] {
		return this.getDocumentsRecursively().filter((poDocument: Document) => {
			if (!StringHelper.isBlank(poFilters.name) && poDocument.name.toLowerCase().includes(poFilters.name.toLowerCase()))
				return true;
			return false;
		});
	}

	public searchFolders(psName: string): FolderContent[] {
		return this.folders.filter((poFolderContent: FolderContent) => poFolderContent.current.name.toLowerCase().includes(psName.toLowerCase()));
	}

	//#endregion METHODS

}
