import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { BlankInformationModule } from '../blank-information/blank-information.module';
import { CompletionBarModule } from '../completion-bar/completion-bar.module';
import { SlidesModule } from '../slides/slides.module';
import { StatusDirective } from './directives/status.directive';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { TourHeaderComponent } from './tour-header/tour-header.component';
import { TourItemComponent } from './tour-item/tour-item.component';
import { TourComponent } from './tour/tour.component';

/** Composants accessibles à l'extérieur du module. */
const publicComponents = [
	TourComponent,
	TourItemComponent,
	TasksListComponent,
	TourHeaderComponent
];

/** Directives accessibles à l'extérieur du module. */
const publicDirectives = [
	StatusDirective,
];

@NgModule({
	declarations: [...publicComponents, ...publicDirectives],
	imports: [
		CommonModule,
		IonicModule,
		CompletionBarModule,
		SlidesModule,
		BlankInformationModule
	],
	exports: [...publicComponents, ...publicDirectives],
})
export class TourModule { }