import { ModuleWithProviders, NgModule } from '@angular/core';
import { WaitToNavigateGuard } from './guards/wait-to-navigate.guard';
import { IPageManagerConfig } from './models/ipage-manager-config';
import { PageManagerService } from './services/pageManager.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [
		WaitToNavigateGuard
	]
})
export class RoutingModule {

	public static forRoot(poPageManagerConfig: IPageManagerConfig): ModuleWithProviders<RoutingModule> {
		PageManagerService.addDefaultRouteToCache(poPageManagerConfig.defaultRoute);

		return {
			ngModule: RoutingModule
		};
	}

}