import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ICategoryTileParams } from '../models/ICategoryTileParams';

@Component({
	selector: "calao-category-tile",
	templateUrl: './category-tile.component.html',
	styleUrls: ['./category-tile.component.scss'],
})
export class CategoryTileComponent implements ICategoryTileParams {

	//#region FIELDS

	private static readonly C_LOG = "CTGRY-TL.C::";
	/** Nom de la variable CSS à modifier pour changer la couleur d'arrière-plan du sous-titre. */
	private static readonly C_CSS_COLOR_VAR_NAME = "--tile-subtitle-background-color";

	@Output("click") private readonly moClickEvent = new EventEmitter<void>();

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@Input() public title: string;
	/** @implements */
	@Input() public subtitle?: string;
	/** @implements */
	@Input() public value?: number;
	/** @implements */
	@Input() public icon?: string;

	/** @implements */
	@Input() public set color(psColor: string) {
		if (this.ioElementRef) // Condition car l'input peut être appelé avant initialisation du composant.
			this.ioElementRef.nativeElement.style.setProperty(CategoryTileComponent.C_CSS_COLOR_VAR_NAME, psColor);
	}

	//#endregion

	//#region METHODS

	public constructor(private ioElementRef: ElementRef) { }

	public onClick(): void {
		this.moClickEvent.emit();
	}

	//#endregion

}