import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HooksModule } from '../../hooks/hooks.module';
import { CountingModule } from './counting/counting.module';
import { DeliveryModule } from './delivery/delivery.module';
import { ReturnsModule } from './returns/returns.module';
import { CategoryService } from './services/category.service';
import { RackService } from './services/rack.service';

@NgModule({
	imports: [CommonModule, HooksModule],
	exports: [CountingModule, ReturnsModule, DeliveryModule],
	providers: [CategoryService, RackService]
})
export class TaskModule { }