import { Injectable } from '@angular/core';
import PCancelable from 'p-cancelable';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ETrackingStatus } from '../models/etracking-status.enum';
import { IChangeTrackerItem } from '../models/ichange-tracker-item';
import { ILot } from '../models/ilot';

@Injectable()
export abstract class ChangeTrackingService {

	//#region FIELDS

	public static readonly C_TRACKER_DB_NAME_PREFIX = "change_tracker_db_";
	protected static readonly C_START_LOT_ID = 0;
	protected static readonly C_CHANGES_TABLE_NAME = "changes";
	protected static readonly C_LOTS_TABLE_NAME = "lots";

	protected readonly moTrackingStatusSubjectsByDatabaseId = new Map<string, BehaviorSubject<ETrackingStatus>>();

	//#endregion

	//#region METHODS

	/** Permet de traquer un document.
	 * @param psDatabaseId
	 * @param poChangeTrackedItem
	 */
	public trackAsync(psDatabaseId: string, poChangeTrackedItem: IChangeTrackerItem): Promise<void> {
		return this.trackMultipleAsync(psDatabaseId, [poChangeTrackedItem]);
	}

	/** Permet de traquer plusieurs documents en une seule transaction.
	 * @param psDatabaseId
	 * @param paChangeTrackerItems
	 */
	public abstract trackMultipleAsync(psDatabaseId: string, paChangeTrackerItems: IChangeTrackerItem[]): Promise<void>;

	/** Permet de récupérer tous les marqueurs jusqu'à un lot (inclus).
	 * @param psDatabaseId
	 * @param poToLot
	 */
	public abstract getTrackedAsync(psDatabaseId: string, poToLot?: ILot): PCancelable<IChangeTrackerItem[]>;

	/** Supprime les marqueurs pour un lot et une liste de documents. Supprimera le lot lors de la synchronisation d'un lot supérieur.
	 * @param psDatabaseId
	 * @param pnLotId
	 * @param paDocIds
	 */
	public abstract dropTrackedAsync(psDatabaseId: string, pnLotId: number, paDocIds: string[]): PCancelable<void>;

	/** Permet de récupérer la liste des lots à envoyer puis de créer un nouveau lot, sans l'ajouter au tableau retourné.
	 * @param psDatabaseId
	 * @param pnSince
	 * @returns Retourne les lots avant mise à jour.
	 */
	public abstract getAndUpdateLastLotAsync(psDatabaseId: string, pnSince: number): PCancelable<ILot[]>;

	private async getTrackedSubjectAsync(psDatabaseId: string): Promise<BehaviorSubject<ETrackingStatus>> {
		let loSubject: BehaviorSubject<ETrackingStatus> | undefined = this.moTrackingStatusSubjectsByDatabaseId.get(psDatabaseId);

		if (!loSubject) {
			this.moTrackingStatusSubjectsByDatabaseId.set(psDatabaseId, loSubject = new BehaviorSubject<ETrackingStatus>(
				await this.hasTrackedAsync(psDatabaseId) ? ETrackingStatus.tracked : ETrackingStatus.none
			));
		}

		return loSubject;
	}

	protected async sendTrackingStatusAsync(psDatabaseId: string, peTrackingStatus: ETrackingStatus): Promise<void> {
		(await this.getTrackedSubjectAsync(psDatabaseId)).next(peTrackingStatus);
	}

	public trackingStatus$(psDatabaseId: string): Observable<ETrackingStatus> {
		return defer(() => this.getTrackedSubjectAsync(psDatabaseId)).pipe(
			mergeMap((poSubject: BehaviorSubject<ETrackingStatus>) => poSubject.asObservable())
		);
	}

	protected abstract hasTrackedAsync(psDatabaseId: string): Promise<boolean>;

	public getTrackerDatabaseId(psDatabaseId: string): string {
		return `${ChangeTrackingService.C_TRACKER_DB_NAME_PREFIX}${psDatabaseId}`;
	}

	public abstract getLastLotAsync(psDatabaseId: string): PCancelable<ILot | undefined>;

	public abstract countTrackedAsync(psDatabaseId: string): Promise<number>;

	public abstract getLotsAsync(psDatabaseId: string): PCancelable<ILot[]>;

	public abstract removeAsync(psDatabaseId: string): Promise<void>;

	//#endregion

}