import { Injectable } from "@angular/core";
import { MapHelper } from "../../../helpers/mapHelper";
import { NumberHelper } from "../../../helpers/numberHelper";
import { StringHelper } from "../../../helpers/stringHelper";
import { final } from "../../utils/decorators/final.decorator";
import { BadDatabaseIdError } from "./errors/BadDatabaseIdError";
import { DatabaseAlreadyExistsError } from "./errors/DatabaseAlreadyExistsError";
import { DatabaseNotExistsError } from "./errors/DatabaseNotExistsError";
import { SqlDataSource } from "./sql-data-source";
import { SqlRequestResult } from "./sql-request-result";
import { TRequestParam } from "./trequest-param";
import { TTransactionParams } from "./ttransaction-params";
import { TTransactionRequest } from "./ttransaction-request";

@Injectable()
export abstract class SqlAdapter<Database> {

	//#region FIELDS

	private static readonly C_LOG_ID = "SQL.ADPTR::";

	private readonly moDatabaseBySqlDataSourceHashCode = new Map<string, Database>();

	//#endregion FIELDS

	//#region METHODS

	/** Exécute et retourne le résultat d'une requête.
	 * @param poDataSource Base de données à requêter.
	 * @param psRequest Requête à exécuter.
	 * @param paParams Valeur à satisfaire pour filtrer les résultats (where).
	 * @param psDatabasePartialId L'ID partiel de la base de données.
	 * @returns
	 * - `DatabaseNotExistsError` si la base de données n'existe pas,
	 * - Résultats de la requête,
	 * - Autre erreur quelconque.
	 */
	public requestAsync<T>(
		poDataSource: SqlDataSource,
		psRequest: string,
		paParams: TRequestParam[],
		psDatabasePartialId: string
	): Promise<SqlRequestResult<T>> {
		return this.openCloseDatabasesAsync(poDataSource, psDatabasePartialId)
			.then(() => {
				const loDatabase: Database | undefined = this.getDatabase(poDataSource);

				if (!loDatabase)
					return Promise.reject(new DatabaseNotExistsError(poDataSource.databaseId));

				return this.execRequestAsync<T>(loDatabase, psRequest, paParams);
			});
	}

	/** Exécute et retourne le résultat d'une série de requêtes d'une même transaction.
	 * @param poDataSource Base de données à requêter.
	 * @param paRequests Liste des requêtes à exécuter.
	 * @param paParams Liste des valeurs à injecter pour chaques requêtes.
	 * @param psDatabasePartialId L'ID partiel de la base de données.
	 * @returns
	 * - `DatabaseNotExistsError` si la base de données n'existe pas,
	 * - Résultats de la requête,
	 * - Autre erreur quelconque.
	 */
	public runTransactionAsync(
		poDataSource: SqlDataSource,
		paRequests: TTransactionRequest[],
		paParams: TTransactionParams[],
		psDatabasePartialId: string
	): Promise<SqlRequestResult<any>[]> {
		return this.openCloseDatabasesAsync(poDataSource, psDatabasePartialId)
			.then(() => {
				const loDatabase: Database | undefined = this.getDatabase(poDataSource);

				if (!loDatabase)
					return Promise.reject(new DatabaseNotExistsError(poDataSource.databaseId));

				return this.execTransactionAsync(loDatabase, paRequests, paParams);
			});
	}

	/** Ouvre une base de données et ferme toutes les autres qui commencent par l'identifiant partiel donné en paramètre.
	 * @param poDatasource La base de données à ouvrir.
	 * @param psDatabasePartialId L'ID partiel des bases de données à fermer.
	 */
	private async openCloseDatabasesAsync(poDatasource: SqlDataSource, psDatabasePartialId: string): Promise<void> {
		// Si la BDD n'est pas ouverte, on l'ouvre et ferme toutes les BDD ouvertes.
		if (!this.isOpened(poDatasource)) {
			await this.closeDatabasesAsync(psDatabasePartialId);
			await this.openAsync(poDatasource);
		}
	}

	/** Exécute la requête dans la base de données.
	 * @param poDatabase Base de données dans laquelle exécuter la requête.
	 * @param psRequest Requête à exécuter.
	 * @param paParams Paramètres dynamiques de la requête.
	 */
	protected abstract execRequestAsync<T>(
		poDatabase: Database,
		psRequest: string,
		paParams: TRequestParam[]): Promise<SqlRequestResult<T>>;

	/** Exécute la requête dans la base de données.
	 * @param poDatabase Base de données dans laquelle exécuter la requête.
	 * @param paRequests Liste des requêtes à exécuter.
	 * @param paParams Liste des valeurs à injecter pour chaques requêtes.
	 */
	protected abstract execTransactionAsync(
		poDatabase: Database,
		paRequests: TTransactionRequest[],
		paParams: TTransactionParams[]
	): Promise<SqlRequestResult<any>[]>;

	/** Ouvre une base de données la rendant disponible pour les requêtes.
	 * @param poDataSource Source de données à partir de laquelle ouvrir la base de données associée.
	 */
	@final()
	public async openAsync(poDataSource: SqlDataSource): Promise<void> {
		const lsHashKey: string = poDataSource.toHashcode();

		if (StringHelper.isBlank(lsHashKey))
			return Promise.reject(new BadDatabaseIdError("No id"));
		else if (this.moDatabaseBySqlDataSourceHashCode.has(lsHashKey))
			return Promise.reject(new DatabaseAlreadyExistsError(lsHashKey));

		this.moDatabaseBySqlDataSourceHashCode.set(lsHashKey, await this.openDatabaseAsync(poDataSource));
	}

	/** Ouvre une base de données.
	 * @param poDataSource Source de données à partir de laquelle ouvrir la base de données associée.
	 */
	protected abstract openDatabaseAsync(poDataSource: SqlDataSource): Promise<Database>;

	/** Ferme une base de données à partir de sa source de données.
	 * @param poDataSource Source de données à partir de laquelle déterminer la base de données à fermer.
	 */
	@final()
	public async closeDatabaseFromDataSourceAsync(poDataSource: SqlDataSource): Promise<void> {
		const loDatabase: Database | undefined = this.getDatabase(poDataSource);

		if (loDatabase) {
			await this.closeDatabaseAsync(loDatabase);
			this.moDatabaseBySqlDataSourceHashCode.delete(poDataSource.toHashcode());
		}
		else
			console.error(`${SqlAdapter.C_LOG_ID}Attempting to close database '${poDataSource.databaseId}' in version '${poDataSource.version}' but it is not opened.`);
	}

	/** Ferme la base de données.
	 * @param poDatabase Base de données à fermer.
	 */
	protected abstract closeDatabaseAsync(poDatabase: Database): Promise<void>;

	/** Ferme les sources de données ouvertes dont l'identifiant est associé à celui en paramètre.
	 * @param psPartialDatabaseId Identifiant partiel (ou complet) de la base de données dont on veut fermer les sources de données associées.
	 */
	@final()
	public async closeDatabasesAsync(psPartialDatabaseId: string): Promise<void> {
		const laDataSources: SqlDataSource[] = this.getOpenedDataSources(psPartialDatabaseId);

		for (let lnIndex = 0; lnIndex < laDataSources.length; lnIndex++) {
			await this.closeDatabaseFromDataSourceAsync(laDataSources[lnIndex]);
		}
	}

	/** Indique si la base de données est prête à recevoir des requêtes.
	 * @param poDataSource Source de données à partir de laquelle vérifier si la base de données associée est ouverte ou non.
	 */
	@final()
	public isOpened(poDataSource: SqlDataSource): boolean {
		return !!this.getDatabase(poDataSource);
	}

	/** Récupère les sources de données ouvertes en fonction d'un identifiant (unique résultat) ou d'un identifiant partiel (plusieurs résultats possibles).
	 * @example `stock-123456` // renverra un tableau avec l'instance de source de donnée associée (si elle est ouverte).
	 * @example `stock` // renverra toutes les sources de données qui comprennent `stock` dans leur identifiant (et qui sont ouvertes).
	 * @param psPartialDatabaseId Identifiant partiel (ou complet) de la base de données dont on veut récupérer les sources de données associées.
	 */
	@final()
	public getOpenedDataSources(psPartialDatabaseId: string): SqlDataSource[] {
		return MapHelper.keysToArray(this.moDatabaseBySqlDataSourceHashCode)
			.map((psHashCode: string) => SqlDataSource.fromHashCode(psHashCode))
			.filter((poDatasource: SqlDataSource) => poDatasource.databaseId.includes(psPartialDatabaseId));
	}

	/** Récupère la source de données associée à l'identifiant de base de données en paramètre, `undefined` si non ouverte.
	 * @param psDatabaseId Identifiant de la base de données dont on veut récupérer la source de données associée.
	 */
	@final()
	public getDataSource(psDatabaseId: string): SqlDataSource | undefined {
		return MapHelper.keysToArray(this.moDatabaseBySqlDataSourceHashCode)
			.map((psHashCode: string) => SqlDataSource.fromHashCode(psHashCode))
			.find((poDatasource: SqlDataSource) => poDatasource.databaseId === psDatabaseId);
	}

	/** Récupère la base de données associée à une source de données.
	 * @param poDataSource Source de données à partir de laquele récupérer la base de données associée.
	 */
	private getDatabase(poDataSource: SqlDataSource): Database | undefined {
		return this.moDatabaseBySqlDataSourceHashCode.get(poDataSource.toHashcode());
	}

	/** `true` si au moins une base de données est ouverte, `false` sinon. */
	@final()
	public hasOpenedDatabase(): boolean {
		return this.moDatabaseBySqlDataSourceHashCode.size > 0;
	}

	/** Récupère le tableau des identifiants de base de données qui sont chargées en mémoire. */
	public getDatabaseIds(): string[] {
		return MapHelper.keysToArray(this.moDatabaseBySqlDataSourceHashCode);
	}

	/** Ferme les anciennes bases de données en mémoire de la base de données passée en paramètre.
	 * @param psDatabaseId Identifiant de la base de données.
	 */
	public closeOldDatabases(psDatabaseId: string): void {
		const laDatabaseKeys: string[] = [];

		this.moDatabaseBySqlDataSourceHashCode.forEach((poDatabase: Database, poKey: string) => {
			if (poKey.startsWith(psDatabaseId))
				laDatabaseKeys.push(poKey);
		});

		// Trie et retourne un tableau sans le dernier élément (bdd la plus récente) ("databaseId_version_..." => Hash de la DataSource).
		const laOldDatabaseKeys: string[] = this.sortDatabaseKeys(laDatabaseKeys).slice(0, -1);

		laOldDatabaseKeys.forEach((psDatabaseKey: string) => this.moDatabaseBySqlDataSourceHashCode.delete(psDatabaseKey));
	}

	/** Trie une liste des clés des bases de données par version par ordre croissant.
	 * @param psDatabaseKeys Tableau des clés des bases de donées.
	 */
	private sortDatabaseKeys(psDatabaseKeys: string[]): string[] {
		return psDatabaseKeys.sort((psKeyA: string, psKeyB: string) => {
			const lnVersionA: number = +(psKeyA.split("_")[1]);
			const lnVersionB: number = +(psKeyB.split("_")[1]);
			return NumberHelper.compare(lnVersionA, lnVersionB);
		});
	}

	//#endregion METHODS

}