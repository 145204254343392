<osapp-header-modal title="État de la synchronisation" [hasSafeTopPadding]="false"></osapp-header-modal>

<ion-content class="ion-padding">
	<div class="wrapper">
		<calao-databases-sync-status-list [databaseSyncStatuses]="databaseSyncStatuses$ | async">
		</calao-databases-sync-status-list>

		<div class="buttons-wrapper">
			<div class="sync-buttons-wrapper">
				<ion-button [disabled]="isBusy$ | async" (click)="syncDatabases()" expand="block">{{ syncText$ | async }}
				</ion-button>
				<ion-nav-link router-direction="forward" [component]="detailComponent">
					<ion-button expand="block"><ion-icon slot="icon-only" name="information-circle"></ion-icon></ion-button>
				</ion-nav-link>
			</div>
			<ion-button [disabled]="isBusy$ | async" (click)="resetWorkspaceDatabasesAsync()"
				expand="block">Optimiser</ion-button>
		</div>
	</div>
</ion-content>