import { RouteComponent } from "../model/RouteComponent";
import { DocExplorerSlideComponent } from "../modules/doc-explorer/components/doc-explorer-slide/doc-explorer-slide.component";
import { DocumentHeaderComponent } from "../modules/doc-explorer/components/document-header/document-header.component";
import { EntityFormComponent } from "../modules/entities/components/entity-form/entity-form.component";
import { EntityHeaderComponent } from "../modules/entities/components/entity-header/entity-header.component";
import { PreviewPageComponent } from "../modules/forms/designer/components/preview/preview-page/preview-page.component";
import { SiteDetailsComponent } from "../modules/sites/components/site-details/site-details.component";
import { ContactComponent } from './contacts/contact/contact.component';
import { ContactSelectorComponent } from "./contacts/contactSelector/contactSelector.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { GroupDetailsComponent } from './contacts/group-details/group-details.component';
import { ConversationComponent } from "./conversation/conversation.component";
import { ConversationEditComponent } from "./conversation/edit/conversationEdit.component";
import { ConversationsListComponent } from "./conversation/list/conversations-list.component";
import { ConversationTabComponent } from "./conversation/tab/conversation-tab.component";
import { CurveComponent } from "./curve/curve.component";
import { DiagnosticsComponent } from "./diagnostics/diagnostics.component";
import { DynamicPageComponent } from "./dynamicPage/dynamicPage.component";
import { EntitySelectorComponent } from "./entity/entitySelector/entitySelector.component";
import { EntityTypeSelectorComponent } from "./entity/entityTypeSelector/entityTypeSelector.component";
import { FilePickerComponent } from "./filePicker/filePicker.component";
import { FormComponent } from "./forms/form/form.component";
import { FormListComponent } from "./forms/formList/formList.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { GoogleMapComponent } from "./geolocation/googleMap.component";
import { ImportComponent } from './import/import.component';
import { LikeComponent } from "./like/like.component";
import { LinkedItemsListComponent } from "./linkedItemsList/linkedItemsList.component";
import { MenuComponent } from "./menu/menu.component";
import { SearchComponent } from "./search/search.component";
import { SlideboxComponent } from "./slidebox/slidebox.component";
import { ToolbarComponent } from "./toolbar/toolbar.component";
import { WebViewComponent } from './webview/webview.component';

/** Liste de tous les composants d'OSApp */
export const RouteComponentRegistries: RouteComponent[] = [
	{ id: 'contact-form', componentType: ContactComponent },
	{ id: 'contact-selector', componentType: ContactSelectorComponent },
	{ id: 'contacts-book', componentType: ContactsComponent },
	{ id: 'conversation', componentType: ConversationComponent },
	{ id: 'conversationEdit', componentType: ConversationEditComponent },
	{ id: 'conversationList', componentType: ConversationsListComponent },
	{ id: 'conversationTab', componentType: ConversationTabComponent },
	{ id: 'curve', componentType: CurveComponent },
	{ id: 'dynamicPage', componentType: DynamicPageComponent },
	{ id: 'filePicker', componentType: FilePickerComponent },
	{ id: 'group-details', componentType: GroupDetailsComponent },
	{ id: 'site-details', componentType: SiteDetailsComponent },
	{ id: 'form', componentType: FormComponent },
	{ id: 'formList', componentType: FormListComponent },
	{ id: 'gallery', componentType: GalleryComponent },
	{ id: 'googleMap', componentType: GoogleMapComponent },
	{ id: 'home', componentType: MenuComponent },
	{ id: 'like', componentType: LikeComponent },
	{ id: 'linkedItemsList', componentType: LinkedItemsListComponent },
	{ id: 'menu', componentType: MenuComponent },
	{ id: 'search', componentType: SearchComponent },
	{ id: 'slideBox', componentType: SlideboxComponent },
	{ id: 'webview', componentType: WebViewComponent },
	{ id: 'entityTypeSelector', componentType: EntityTypeSelectorComponent },
	{ id: 'entitySelector', componentType: EntitySelectorComponent },
	{ id: 'toolbar', componentType: ToolbarComponent },
	{ id: 'import', componentType: ImportComponent },
	{ id: "diagnostics-page", componentType: DiagnosticsComponent },
	{ id: "preview", componentType: PreviewPageComponent },
	{ id: "doc-explorer", componentType: DocExplorerSlideComponent },
	{ id: "entity-form", componentType: EntityFormComponent },
	{ id: "entity-header", componentType: EntityHeaderComponent },
	{ id: "document-header", componentType: DocumentHeaderComponent }
];
