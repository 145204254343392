import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { ArrayHelper } from '../../../../helpers/arrayHelper';
import { MapHelper } from '../../../../helpers/mapHelper';
import { StoreHelper } from '../../../../helpers/storeHelper';
import { StringHelper } from '../../../../helpers/stringHelper';
import { EDatabaseRole } from '../../../../model/store/EDatabaseRole';
import { IDataSource } from '../../../../model/store/IDataSource';
import { Store } from '../../../../services/store.service';
import { ModelResolver } from '../../../utils/models/model-resolver';
import { IReason } from '../models/IReason';
import { Reason } from '../models/reason';

@Injectable()
export class ReasonService {

	//#region FIELDS

	private static readonly C_LOG_ID = "RSN.S::";

	/** Sujet qui permet de faire du cache des motifs pour éviter de requêter en base de données à chaque fois. */
	private readonly moCacheReasonsSubject = new BehaviorSubject<IReason[]>([]);

	//#endregion

	//#region PROPERTIES

	/** Observable des motifs en cache qui se désabonne après une réception de valeurs. */
	private get onceReasons$(): Observable<IReason[]> { return this.moCacheReasonsSubject.asObservable().pipe(take(1)); }

	//#endregion

	//#region METHODS

	constructor(private readonly isvcStore: Store) { }

	/** Récupère une source de données afin de récupérer un motif en base de données.
	 * @param psId Identifiant du motif dont on veut récupérer une source de données pour récupérer ce motif en base de données.
	 */
	private getDataSource(psId: string): IDataSource;
	/** Récupère une source de données afin de récupérer un motif en base de données.
	 * @param paIds Tableau des identifiants des motifs à récupérer.
	 */
	private getDataSource(paIds: string[]): IDataSource;
	/** Récupère une source de données afin de récupérer un motif en base de données.
	 * @param poReason Motif dont on veut récupérer une source de données pour récupérer ses motifs enfants en base de données.
	 * @param paIds Tableau des identifiants des motifs qui composent le motif parent.
	 */
	private getDataSource(poReason: IReason, paIds?: string[]): IDataSource;
	private getDataSource(poData: string | string[] | IReason, paIds?: string[]): IDataSource {
		if (typeof poData === "string")
			return { role: EDatabaseRole.workspace, viewParams: { include_docs: true, key: poData } };
		else if (poData instanceof Array)
			return { role: EDatabaseRole.workspace, viewParams: { include_docs: true, keys: poData } };
		else
			return { databaseId: StoreHelper.getDatabaseIdFromCacheData(poData), viewParams: { include_docs: true, keys: paIds || poData.childIds } };
	}

	/** Récupère un motif avec le cache, `undefined` si le motif n'est pas trouvé en base de données.
	 * @param psReasonId Identifiant du motif à récupérer.
	 */
	private getReasonWithCache<T extends IReason>(psReasonId: string): Observable<T | undefined> {
		return this.onceReasons$.pipe(
			mergeMap((paCacheReasons: IReason[]) => {
				const loSearchedReason: IReason | undefined =
					ArrayHelper.getFirstElement(paCacheReasons.filter((poCacheReason: IReason) => poCacheReason._id === psReasonId));

				if (loSearchedReason)
					return of(loSearchedReason as T);
				else {
					return this.isvcStore.getOne<T>(this.getDataSource(psReasonId), false)
						.pipe(
							tap((poGetResult?: T) => {
								if (poGetResult)
									this.moCacheReasonsSubject.next([...paCacheReasons, poGetResult]);
							})
						);
				}
			})
		);
	}

	/** Récupère des motifs avec le cache (gère les doublons), tableau vide si aucun motif n'est trouvé en base de données.
	 * @param paReasonIds Tableau des identifiants de motif à récupérer (gère les doublons).
	 */
	private getReasonsWithCache<T extends IReason>(paReasonIds: string[]): Observable<T[]> {
		return this.onceReasons$.pipe(
			mergeMap((paCacheReasons: IReason[]) => {
				const laMissingReasonIds: string[] = []; // Tableau des motifs absents du cache.
				const laCacheReasons: T[] = []; // Tableau des motifs déjà en cache.

				ArrayHelper.unique(paReasonIds).forEach((psReasonId: string) => {
					const loReason: T | undefined = paCacheReasons.find((poCacheReason: IReason) => poCacheReason._id === psReasonId) as T;
					// Si on a le motif souhaité, on l'ajoute au tableau des motifs à retourner, sinon on ajoute l'identifiant au tableau des motifs à récupérer.
					loReason ? laCacheReasons.push(loReason) : laMissingReasonIds.push(psReasonId);
				});

				// S'il manque des motifs, on les récupère (et les ajoute au cache) et on retourne tous les motifs voulus.
				if (ArrayHelper.hasElements(laMissingReasonIds)) {
					return this.isvcStore.get<T>(this.getDataSource(laMissingReasonIds))
						.pipe(
							map((paGetResults: T[]) => {
								this.moCacheReasonsSubject.next([...paCacheReasons, ...paGetResults]);
								return [...laCacheReasons, ...paGetResults];
							})
						);
				}
				else // Sinon, tous les motifs sont déjà en cache, on les retourne directement.
					return of(laCacheReasons);
			})
		);
	}

	/** Récupère un motif en base de données, `undefined` si non trouvé.
	 * @param psId Identifiant du motif à récupérer.
	 */
	public getReason<T extends IReason>(psId: string): Observable<T | undefined> {
		return StringHelper.isBlank(psId) ? of(undefined) : this.getReasonWithCache<T>(psId);
	}

	/** Récupère un motif en base de données, `undefined` si non trouvé.
	 * @param psId Identifiant du motif à récupérer.
	 */
	public getReasonAsync<T extends IReason>(psId: string): Promise<T | undefined> {
		return this.getReason<T>(psId).toPromise();
	}

	/** Récupère des motifs en base de données (gère les doublons), tableau vide si non trouvés.
	 * @param paReasonIds Tableau des identifiant de motif à réupérer.
	 */
	public getReasons<T extends IReason>(paReasonIds: string[]): Observable<T[]> {
		return this.getReasonsWithCache(ArrayHelper.unique(paReasonIds));
	}

	/** Récupère une map contenant un motif en fonction de son identifiant.
	 * @param paReasonIds Tableau des identifiants de motifs qu'on souhaite récupérer.
	 */
	public getReasonByIdAsync<T extends IReason>(paReasonIds: string[]): Promise<Map<string, T>> {
		return this.getReasons<T>(paReasonIds)
			.pipe(
				map((paReasons: T[]) => {
					const loMapResult = new Map<string, T>();

					if (ArrayHelper.hasElements(paReasonIds)) {
						ArrayHelper.unique(paReasonIds).forEach((psReasonId: string) =>
							loMapResult.set(psReasonId, paReasons.find((poReason: T) => poReason._id === psReasonId)!)
						);
					}

					return loMapResult;
				})
			)
			.toPromise();
	}

	/** Récupère les motifs enfants d'un motif parent.
	 * @param poReason Motif dont on veut récupérer les enfants.
	 * @returns
	 * - Tableau contenant les motifs enfants du motif parent (vide si aucun enfant).
	 * - Tableau vide dans le cas où le motif parent n'est pas récupérable ou qu'aucun enfant n'est présent.
	 */
	public getReasonChildren<T>(poData: string | IReason<T>): Observable<IReason<T>[]> {
		return defer(() => typeof poData === "string" ? this.getReason(poData) : of(poData))
			.pipe(mergeMap((poReason?: IReason<T>) => poReason && ArrayHelper.hasElements(poReason.childIds) ? this.getReasons(poReason.childIds) : of([])));
	}

	/** Récupère une map associant pour chaque identifiant de motif parent tous ses motifs enfants.
	 * @param paParentIds Tableau des identifiants des motifs parents dont on veut récupérer les motifs enfants.
	 */
	public getChildrenReasonsByParentIds(paParentIds: string[]): Observable<Map<string, IReason[]>>;
	/** Récupère une map associant pour chaque identifiant de motif parent tous ses motifs enfants.
	 * @param paParentIds Tableau des motifs parents dont on veut récupérer les motifs enfants.
	 */
	public getChildrenReasonsByParentIds(paParentIds: IReason[]): Observable<Map<string, IReason[]>>;
	public getChildrenReasonsByParentIds(paParentData: string[] | IReason[]): Observable<Map<string, IReason[]>> {
		if (!ArrayHelper.hasElements(paParentData))
			return of(new Map());
		else {
			return defer(() => typeof ArrayHelper.getFirstElement(paParentData as string[]) === "string" ?
				this.getReasons(paParentData as string[]) : of(paParentData as IReason[])
			)
				.pipe(
					mergeMap((paParents: IReason[]) => {
						return this.getReasons(ArrayHelper.flat(paParents.map((poParent: IReason) => poParent.childIds ?? [])))
							.pipe(
								map((paChildren: IReason[]) => {
									const loResultMap = new Map<string, IReason[]>();

									paParents.forEach((poParent: IReason) => {
										loResultMap.set(
											poParent._id,
											paChildren.filter((poChild: IReason) => poParent.childIds?.some((psChildId: string) => poChild._id === psChildId))
										);
									});

									return loResultMap;
								})
							);
					})
				);
		}
	}

	/** Récupère récursivement les motifs d'anomalies enfants à partir d'un identifiant de motif d'anomalie parent.
	 * @param psParentId Identifiant du motif d'anomalie parent.
	 */
	public getReasonChildrenRecursiveFromParentId(psParentId: string): Observable<Reason[]> {
		return this.getChildrenReasonsByParentIds([psParentId])
			.pipe(
				map((poChildrenByParentId: Map<string, IReason[]>) =>
					MapHelper.valuesToArray(poChildrenByParentId)[0].map((poReason: IReason) => ModelResolver.toClass(Reason, poReason))
				)
			);
	}

	/** Récupère tous les motifs enfants.
	 * @param psReasonParentId Id du motif parent.
	 */
	public getParentChildrenReasons$(psReasonParentId: string): Observable<IReason[]> {
		const laChildrenReasons: IReason[] = [];
		return this.getReason(psReasonParentId).pipe(
			mergeMap((poParentReason?: IReason) => this.fillChildrenReasonsRecursivelyAsync(laChildrenReasons, poParentReason)),
			map(_ => laChildrenReasons)
		);
	}

	/** Rempli la liste des motifs enfants.
	 * @param paChildren Liste des motifs enfants.
	 * @param poParentReason Motif parent.
	 */
	private async fillChildrenReasonsRecursivelyAsync(paChildren: IReason[], poParentReason?: IReason): Promise<void> {
		if (poParentReason) {
			const laReasons: IReason[] = await this.getReasonChildren(poParentReason).toPromise();

			if (!ArrayHelper.hasElements(laReasons))
				paChildren.push(poParentReason);
			else
				await forkJoin(laReasons.map((poReason: IReason) => this.fillChildrenReasonsRecursivelyAsync(paChildren, poReason))).toPromise();
		}
	}

	//#endregion

}