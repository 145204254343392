<ion-card class="ion-text-center" (click)="onClick(); $event.stopPropagation();">
	<ion-card-content>
		{{ (value || value === 0) ? value : "-" }}
	</ion-card-content>
	<ion-card-header class="ion-text-center ion-no-padding">
		<ion-card-title>
			<ion-icon *ngIf="icon" [name]="icon"></ion-icon>
			{{ title }}
		</ion-card-title>
		<ion-card-subtitle>
			{{ subtitle }}
		</ion-card-subtitle>
	</ion-card-header>
</ion-card>