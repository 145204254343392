import { ObjectHelper } from "../../../../../helpers/objectHelper";

export class CancelRackError extends Error {

	constructor(psMessage: string) {
		super(psMessage);
		// Nécessaire pour que le 'instanceof CancelRackError' fonctionne (fonctionne sans uniquement sur le tsPlayground).
		ObjectHelper.initInstanceOf(this, CancelRackError);
	}

}