import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FormlyConfig } from '@calaosoft/osapp/components/forms/form/fields.config';
import { EDatabaseRole } from '@calaosoft/osapp/model/store/EDatabaseRole';
import { ChangelogsModule } from '@calaosoft/osapp/modules/about/changelogs/changelogs.module';
import { BatteryModule } from '@calaosoft/osapp/modules/battery/battery.module';
import { IDatabaseGroupingConfiguration } from '@calaosoft/osapp/modules/database-synchro/model/IDatabaseGroupingConfiguration';
import { DATABASES_GROUPING_CONFIG, DMS_SYNC_CONFIG } from '@calaosoft/osapp/modules/database-synchro/services/database-synchro.service';
import { BarcodeReaderConfigService } from '@calaosoft/osapp/modules/logistics/barcode-reader/services/barcode-reader-config.service';
import { UpdateCatalogPatch } from "@calaosoft/osapp/modules/logistics/catalog/patches/update-catalog-patch";
import { LogisticsWithoutRoutingModule } from '@calaosoft/osapp/modules/logistics/logistics-without-routing.module';
import { LogisticsModule } from '@calaosoft/osapp/modules/logistics/logistics.module';
import { ReasonService } from '@calaosoft/osapp/modules/logistics/reason/services/reason.service';
import { RemoveInvalidStocksPatch } from "@calaosoft/osapp/modules/logistics/stock/patches/remove-invalid-stocks-patch";
import { StockModule } from "@calaosoft/osapp/modules/logistics/stock/stock.module";
import { PatchModule } from '@calaosoft/osapp/modules/patch/patch.module';
import { ChangeTrackingFromIdbToSqlitePatch } from '@calaosoft/osapp/modules/patch/patches/change-tracking-from-idb-to-sqlite-patch';
import { RoutingModule } from '@calaosoft/osapp/modules/routing/routing.module';
import { SqlModule } from '@calaosoft/osapp/modules/sqlite/sql.module';
import { OsappModule } from '@calaosoft/osapp/osapp.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProvisioningService } from './appointment/services/provisioning.service';
import { MerchDmsModule } from './dms/merch-dms.module';
import { MerchReasonService } from './task/returns/services/merch-reason.service';
import { MerchReturnRackService } from './task/returns/services/merch-return-rack.service';
import { MerchReturnsService } from './task/returns/services/merch-returns.service';
import { MerchBarcodeReaderConfigService } from './task/services/merch-barcode-reader-config.service';

const databasesGroupingConfigs: IDatabaseGroupingConfiguration[] = [
	{
		roles: [EDatabaseRole.workspace, EDatabaseRole.userContext],
		title: "Espace de travail",
		description: "tournées, tâches"
	}
];

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserAnimationsModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		OsappModule,
		PatchModule.forRoot([UpdateCatalogPatch, RemoveInvalidStocksPatch, ChangeTrackingFromIdbToSqlitePatch]),
		MerchDmsModule,
		LogisticsWithoutRoutingModule,
		SqlModule,
		BatteryModule,
		ChangelogsModule,
		ServiceWorkerModule.register("ngsw-worker.js", { enabled: true, registrationStrategy: "registerImmediately" }),
		LogisticsModule,
		RoutingModule,
		StockModule,
		FormlyModule.forRoot(FormlyConfig)
	],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{ provide: LOCALE_ID, useValue: "fr-FR" },
		{ provide: DATABASES_GROUPING_CONFIG, useValue: databasesGroupingConfigs },
		{ provide: DMS_SYNC_CONFIG, useValue: { title: "Documents", description: "photos, ..." } },
		MerchBarcodeReaderConfigService,
		{ provide: BarcodeReaderConfigService, useClass: MerchBarcodeReaderConfigService },
		UpdateCatalogPatch,
		RemoveInvalidStocksPatch,
		MerchReturnsService,
		ReasonService,
		MerchReturnRackService,
		MerchReasonService,
		ProvisioningService
	],
	bootstrap: [AppComponent]
})
export class AppModule { }