import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { ResolveModel } from '../../../../utils/models/decorators/resolve-model.decorator';
import { ItemPrice } from '../../models/item-price';
import { DeliveryControlItem } from './delivery-control-item';
import { IDeliveryRackItem } from './idelivery-rack-item';

export class DeliveryRackItem implements IDeliveryRackItem {

	//#region PROPERTIES

	/** @implements */
	public readonly itemId: string;

	/** @implements */
	@ResolveModel(ItemPrice)
	public readonly price: ItemPrice;

	/** @implements */
	public qty: number;

	/** @implements */
	public reasonId?: string;

	/** @implements */
	@ResolveModel(DeliveryControlItem)
	public controls?: DeliveryControlItem[];

	/** @implements */
	public readonly receiptId?: string

	constructor(
		psItemId: string,
		poPrice: ItemPrice,
		pnQty?: number,
		paControls?: DeliveryControlItem[],
		psReasonId?: string
	) {
		this.itemId = psItemId;
		this.price = poPrice;
		this.qty = pnQty;
		if (ArrayHelper.hasElements(paControls))
			this.controls = paControls;
		this.reasonId = psReasonId;
	}

	/** Met à jour la quantité.
	 * @param pnNewQty Nouvelle quantité.
	 * @returns True si la quantité a été modifiée.
	 */
	public updateQty(pnNewQty: number): boolean {
		if (pnNewQty !== this.qty) {
			this.qty = pnNewQty;
			if (this.qty > 0)
				this.reasonId = undefined;
			return true;
		}
		else
			return false;
	}

	/** Réinitialise le tableau de contrôle avec un nouveau tableau.
	 * @param paNewControlItems Nouveau tableau de contrôle.
	 */
	public updateControls(paNewControlItems: DeliveryControlItem[]): void {
		this.controls = [...paNewControlItems];
	}

	/** Supprime le tableau de contrôle si existant. */
	public deleteControls(): void {
		if (ArrayHelper.hasElements(this.controls))
			this.controls = undefined;
	}

	/** `true` si un article correspond à cet élément (même code article et prix), `false` sinon.
	 * @param psItemId Identifiant de l'article à vérifier.
	 * @param poItemPrice Prix de l'article à vérifier.
	 */
	public match(psItemId: string, poItemPrice: ItemPrice): boolean {
		return this.itemId === psItemId && this.price.equals(poItemPrice);
	}

	//#endregion

}