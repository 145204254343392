<div *ngIf="observableDocument.value$ | async as document" class="item">
	<ion-item class="ion-no-padding" lines="none" (click)="raiseOpenDocument(document)">
		<calao-list-item>
			<calao-list-item-start backgroundColor="var(--ion-color-background)">
				<ng-container *ngIf="observableDocumentRead.value$ | async; else notRead">
					<div class="item-start">
						<ion-icon [name]="document.icon" slot="start"></ion-icon>
						<span class="type">{{document.type}}</span>
					</div>
				</ng-container>
				<ng-template #notRead>
					<div class="item-start notif-badge" calaoBadgeIcon calaoBadgeIconPosition="after" calaoBadgeSize="2px">
						<ion-icon [name]="document.icon" slot="start"></ion-icon>
						<span class="type">{{document.type}}</span>
					</div>
				</ng-template>
			</calao-list-item-start>
			<calao-list-item-content backgroundColor="var(--ion-color-background)"
				[detail]="((observableOptions.length$ | async) ?? 0) > 0" detailIcon="ellipsis-vertical"
				[ngClass]="(observableDocumentRead.value$ | async) ? 'read' : 'not-read'"
				(onDetailClicked)="onDetailClicked($event)">
				<span *ngIf="observableDisplayNavigationTree.value$ | async" class="breadcrumbs">
					<ng-container *ngFor="let folder of document.observableNavigationTree.changes$ | async; let i = index">
						<div class="folder-name">
							<ion-icon *ngIf="folder.icon" [name]="folder.icon"></ion-icon>
							<ion-label *ngIf="folder.name">{{ folder.name }}</ion-label>
						</div>
						<ion-icon *ngIf="(document.observableNavigationTree.length$ | async) !== i + 1"
							name="chevron-forward"></ion-icon>
					</ng-container>
				</span>
				<calao-list-item-content-title>
					<span class="item-title">{{document.name}}</span>
				</calao-list-item-content-title>
				<span *ngIf="document.authorName as authorName">{{authorName}}</span>
			</calao-list-item-content>
		</calao-list-item>
	</ion-item>
</div>