import { Injectable } from "@angular/core";
import { ArrayHelper } from '../helpers/arrayHelper';

// TODO Transformer en CsvHelper.
@Injectable({ providedIn: "root" })
export class CsvService {

	//#region FIELDS

	private static readonly columnSeparator: string = ";";
	private static readonly lineSeparator: string = "\n";

	//#endregion

	//#region METHODS

	constructor() { }

	public generate<T>(paArray: T[], pfEach: (poValue: T, pnIndex: number) => string[], paHeaders?: string[]): string {
		let lsResult = "";

		if (paHeaders)
			lsResult = `${paHeaders.reduce((psPrevious: string, psCurrent: string) => `${psPrevious}${CsvService.columnSeparator}${psCurrent}`)}\n`;

		if (ArrayHelper.hasElements(paArray))
			lsResult += paArray
				.map((poValue: T, pnIndex: number): string => pfEach(poValue, pnIndex).reduce((psPrevious: string, psCurrent: string) => `${psPrevious}${CsvService.columnSeparator}${psCurrent}`))
				.reduce((psPrevious: string, psCurrent: string) => `${psPrevious}${CsvService.lineSeparator}${psCurrent}`);

		return lsResult;
	}

	//#endregion

}