import { Injectable, Type } from '@angular/core';
import { Observable } from 'rxjs';
import { IAcquisitionSummary } from '../IAcquisitionSummary';

/** Service de configuration pour la lecture du module Calao-Logistics. Doit être redéfini dans une application et injecté dans le module de celle-ci */
@Injectable()
export abstract class ReadingConfigService {

	//#region METHODS

	constructor() {

	}

	/** Méthode appelée lors de l'initialisation du composant. */
	public abstract init(): Observable<void>;

	/** Méthode permettant de transformer un code-barres affiché sur l'interface graphique en un message lisible pour l'utilisateur. */
	public abstract barcodeText(psCode: string): string;

	public abstract barcodeDetail(): Type<IAcquisitionSummary>;

	/** Si `true` le code doit être filtré (refusé). */
	public filter(psCode: string): boolean {
		// Par défaut on accepte tout.
		return false;
	}

	//#endregion
}