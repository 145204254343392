import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IBarcodeReader } from '../../barcode-reader/models/IBarcodeReader';
import { IBarcodeAcquisition } from '../models/ibarcode-acquisition';

/* Classe inutilisée à ce jour mais conservée pour éviter des erreurs dans le BarcodeReaderService.
 * Cette classe sera utilisée après le grand refacto ou la suppression des devices non utilisées.
 */
@Injectable({
	providedIn: "root"
})
export class InputBarcodeReaderServiceService implements IBarcodeReader {

	public constructor() { }

	/** @implements */
	public initializeBarcode(): void {
	}

	/** @implements */
	public readBarcode(): void { }

	/** @implements */
	public onBarcodeReaded(): Observable<IBarcodeAcquisition[]> {
		return of([]);
	}
}
