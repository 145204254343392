import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TslBarcodeModalComponent } from './tsl-barcode-modal/tsl-barcode-modal.component';
import { TslService } from './tsl.service';

@NgModule({
	imports: [IonicModule],
	exports: [],
	declarations: [TslBarcodeModalComponent],
	providers: [TslService]
})
export class TslModule { }