import { ChangeDetectorRef, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlatformService } from '../../../../services/platform.service';
import { ModalComponentBase } from '../../../modal/model/ModalComponentBase';
import { ObservableProperty } from '../../../observable/models/observable-property';
import { Document } from '../../models/document';
import { EExplorerMode } from '../../models/eexplorer-mode';

@Component({
	selector: 'calao-doc-picker',
	templateUrl: './doc-picker.component.html',
	styleUrls: ['./doc-picker.component.scss'],
})
export class DocPickerComponent extends ModalComponentBase<Document> {

	//#region PROPERTIES

	public readonly observableCurrentPath = new ObservableProperty<string | null | undefined>("");
	public readonly mode: EExplorerMode = EExplorerMode.picker;

	//#endregion PROPERTIES

	//#region METHODS

	constructor(
		poModalCtrl: ModalController,
		psvcPlatform: PlatformService,
		poChangeDetector: ChangeDetectorRef
	) {
		super(poModalCtrl, psvcPlatform, poChangeDetector);
	}

	public onDocumentPicked(poDocument: Document): void {
		this.close(poDocument);
	}

	public onPathChanged(psPath: string): void {
		this.observableCurrentPath.value = psPath;
	}

	//#endregion METHODS

}
