import { NgModule, Type } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { ContactsModule as ContactsModuleB } from '../../../components/contacts/contacts.module';
import { DateModule } from '../../../components/date/date.module';
import { FilePickerModule } from '../../../components/filePicker/filePicker.module';
import { GalleryModule } from '../../../components/gallery/gallery.module';
import { LikeModule } from '../../../components/like/like.module';
import { LinkModule } from '../../../components/link/link.module';
import { LinkedItemsListModule } from '../../../components/linkedItemsList/linkedItemsList.module';
import { SharedComponentsModule } from '../../../components/sharedComponents.module';
import { SlideboxModule } from '../../../components/slidebox/slidebox.module';
import { CameraModule } from '../../../modules/camera/camera.module';
import { ColorsModule } from '../../../modules/colors/colors.module';
import { ConversationsModule } from '../../../modules/conversations/conversations.module';
import { DrawingModule } from '../../../modules/drawing/drawing.module';
import { SectorsModule } from '../../../modules/sectors/sectors.module';
import { SelectorModule } from '../../../modules/selector/selector.module';
import { BlankInformationModule } from '../../blank-information/blank-information.module';
import { ContactsModule } from '../../contacts/contacts.module';
import { GroupsModule } from '../../groups/groups.module';
import { HeaderModule } from '../../header';
import { ListsModule } from '../../lists/lists.module';
import { ModalModule } from '../../modal/modal.module';
import { PermissionsModule } from '../../permissions/permissions.module';
import { SliderModule } from '../../slider/slider.module';
import { TourOptimizationModule } from '../../tour-optimization/tour-optimization.module';
import { FormsService } from '../services/forms.service';
import { NotificationAddModalComponent } from './components/customFields/event-notification-field/components/notification-add-modal/notification-add-modal.component';
import { FormlyWrapperComponent } from './components/formly-wrapper/formly-wrapper.component';
import { EventOptmizedModalComponent } from './components/modals/event-optmized-input-modal.component';
import { C_FORMLY_CUSTOM, FormlyConfig } from './fields.config';

const modules: Array<Type<any>> = [
	SharedComponentsModule,
	LinkModule,
	SlideboxModule,
	GalleryModule,
	LikeModule,
	FilePickerModule,
	ContactsModule,
	ContactsModuleB,
	DateModule,
	LinkedItemsListModule,
	MatInputModule,
	MatChipsModule,
	ConversationsModule,
	CameraModule,
	ColorsModule,
	SectorsModule,
	SelectorModule,
	DrawingModule,
	ReactiveFormsModule,
	FormlyMaterialModule,
	PermissionsModule,
	ModalModule,
	HeaderModule,
	BlankInformationModule,
	SliderModule,
	GroupsModule,
	ListsModule,
	TourOptimizationModule
];
const components: Array<Type<any>> = [
	FormlyWrapperComponent,
	EventOptmizedModalComponent,
	NotificationAddModalComponent
];

/** Module de gestion des formulaires. */
@NgModule({
	exports: components,
	providers: [FormsService],
	declarations: [C_FORMLY_CUSTOM, ...components],
	imports: [...modules, FormlyModule.forRoot(FormlyConfig)]
})
export class FormModule { }