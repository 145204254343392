import { ArrayHelper } from '../../../../../helpers/arrayHelper';
import { ResolveModel } from '../../../../utils/models/decorators/resolve-model.decorator';
import { ModelResolver } from '../../../../utils/models/model-resolver';
import { EKnownTaskType } from '../../models/eknown-task-type';
import { ETaskStatus } from '../../models/ETaskStatus';
import { TaskStep } from '../../models/task-step';
import { LastChange } from '../../returns/models/LastChange';
import { DeliveryItem } from './delivery-item';
import { DeliveryReceipt } from './delivery-receipt';
import { DeliveryReceiptPack } from './delivery-receipt-pack';
import { IDeliveryTask } from './idelivery-task';

export class DeliveryTask implements IDeliveryTask {

	//#region PROPERTIES

	/** Identifiant du document.
	 * @pattern prefixe_guid
	 */
	public readonly _id: string;

	/** Révision du document.
	 * @pattern \d+-guid
	 */
	public readonly _rev?: string;

	/** @implements */
	public readonly taskType: EKnownTaskType.delivery;

	/** @implements */
	public readonly type: "task";

	/** @implements */
	@ResolveModel(Date)
	public lastUpdate: Date;

	/** @implements */
	@ResolveModel(DeliveryReceipt)
	public receipts: DeliveryReceipt[];

	/** @implements */
	public readonly order: number;

	/** @implements */
	public status?: ETaskStatus;

	/** @implements */
	@ResolveModel(Date)
	public readonly startDate: Date;

	/** @implements */
	@ResolveModel(Date)
	public readonly endDate: Date;

	/** @implements */
	public reasonId?: string;

	/** @implements */
	public readonly validateUserId?: string;

	/** @implements */
	@ResolveModel(LastChange)
	public readonly lastChange?: LastChange;

	/** @implements */
	public readonly label?: string;

	/** @implements */
	public readonly description?: string;

	/** @implements */
	public readonly rackLabel?: string;

	/** @implements */
	public readonly racksLabel?: string;

	/** @implements */
	public readonly steps?: TaskStep;

	//#endregion

	//#region METHODS

	constructor() { }

	/** Retourne une instance de classe d'une tâche de livraison.
	 * @param poTask La tâche à transformer en instance.
	 */
	public static getInstance(poTask: IDeliveryTask | undefined): DeliveryTask {
		return poTask ? ModelResolver.toClass(DeliveryTask, poTask) : new DeliveryTask();
	}

	/** Extrait les articles d'un conditionnement de la tâche.
	 * @param psPackCode Code du conditionnement où extraire les articles.
	 */
	public getItems(psPackCode: string): DeliveryItem[] {
		return ArrayHelper.flat(this.receipts.map((poReceipt: DeliveryReceipt) =>
			poReceipt.packs.find((poPack: DeliveryReceiptPack) => poPack.code === psPackCode)?.items ?? []
		));
	}

	//#endregion

}
