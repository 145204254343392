import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentBase } from '../../../helpers/ComponentBase';
import { ITourHeaderParams } from '../models/ITourHeaderParams';

@Component({
	selector: 'calao-tour-header',
	templateUrl: './tour-header.component.html',
	styleUrls: ['./tour-header.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourHeaderComponent extends ComponentBase implements ITourHeaderParams {

	//#region FIELDS

	/** Émetteur d'événement pour informer qu'un clic sur le bouton "précédent" a été réalisé. */
	@Output("previousButtonClicked") private readonly moPreviousButtonClickedEvent = new EventEmitter<void>();
	/** Émetteur d'événement pour informer qu'un clic sur le bouton "suivant" a été réalisé. */
	@Output("nextButtonClicked") private readonly moNextButtonClickedEvent = new EventEmitter<void>();

	//#endregion

	//#region PROPERTIES

	private msTitle = "";
	/** @implements */
	public get title(): string { return this.msTitle; }
	@Input() public set title(psTitle: string) {
		if (this.msTitle !== psTitle) {
			this.msTitle = psTitle;
			this.detectChanges();
		}
	}

	private mbIsCurrentTour: boolean;
	/** @implements */
	@Input() public set isCurrentTour(pbIsCurrentTour: boolean) {
		if (this.mbIsCurrentTour !== pbIsCurrentTour) {
			this.mbIsCurrentTour = pbIsCurrentTour;
			this.msTitleColor = pbIsCurrentTour ? "primary" : "dark";
			this.detectChanges();
		}
	}

	private mbHasPreviousButton: boolean;
	/** @implements */
	public get hasPreviousButton(): boolean { return this.mbHasPreviousButton; }
	@Input() public set hasPreviousButton(hasPreviousButton: boolean) {
		if (this.mbHasPreviousButton !== hasPreviousButton) {
			this.mbHasPreviousButton = hasPreviousButton;
			this.detectChanges();
		}
	}

	private mbHasNextButton: boolean;
	/** @implements */
	public get hasNextButton(): boolean { return this.mbHasNextButton; }
	@Input() public set hasNextButton(hasNextButton: boolean) {
		if (this.mbHasNextButton !== hasNextButton) {
			this.mbHasNextButton = hasNextButton;
			this.detectChanges();
		}
	}

	private msTitleColor: "primary" | "dark";;
	/** Couleur du titre (en fonction qu'il s'agit de la tournée courant ou non). */
	public get titleColor(): "primary" | "dark" { return this.msTitleColor; }

	//#endregion

	//#region METHODS

	constructor(poChangeDetector: ChangeDetectorRef) {
		super(poChangeDetector);
	}

	/** Lève un événement pour signaler un clic sur le bouton "précédent". */
	public raisePreviousButtonClicked(): void {
		this.moPreviousButtonClickedEvent.emit();
	}

	/** Lève un événement pour signaler un clic sur le bouton "suivant". */
	public raiseNextButtonClicked(): void {
		this.moNextButtonClickedEvent.emit();
	}

	//#endregion

}