<ion-content>
	<div class="entity-wrapper">
		<calao-sub-header *ngIf="displayHeader$ | async">
			<calao-sub-header-start-buttons>
				<ion-button color="dark" shape="round" (click)="close()">
					<ion-icon slot="icon-only" name="close-circle"></ion-icon>
				</ion-button>
			</calao-sub-header-start-buttons>
			<calao-sub-header-title>{{observableTitle.value$ | async}}</calao-sub-header-title>
			<calao-sub-header-end-buttons *ngIf="(displayActionButtons$ | async) && !(observableIsEdit.value$ | async)">
				<calao-sub-header-button *ngIf="observableCanEdit.value$ | async" (click)="onEditAsync()" class="primary-btn">
					<ion-icon name="create" slot="icon-only"></ion-icon>
				</calao-sub-header-button>
				<calao-sub-header-button *ngIf="canDelete$ | async" (click)="onDeleteAsync()" class="delete-btn">
					<ion-icon name="trash" slot="icon-only"></ion-icon>
				</calao-sub-header-button>
			</calao-sub-header-end-buttons>
		</calao-sub-header>

		<ng-container *ngIf="!(observableHasError.value$ | async); else error">
			<ng-container *ngIf="(params$ | async) as params; else blankInfo">
				<ng-container *ngIf="observableIsEdit.value$ | async">
					<div *ngIf="observableCanEdit.value$ | async; else permissionDenied" class="layout-wrapper">
						<calao-layout [params]="params"></calao-layout>

						<ion-fab *ngIf="(observableDescriptor.value$ | async)?.entry as entry" vertical="bottom" horizontal="end"
							slot="fixed" class="fab-margin">
							<ion-fab-button [disabled]="!(entry?.observableIsValid?.value$| async)" size="small" color="primary"
								class="fab-label" (click)="onSubmitAsync()">
								<ion-label>Enregistrer</ion-label>
							</ion-fab-button>
						</ion-fab>
					</div>
				</ng-container>

				<ng-container *ngIf="!(observableIsEdit.value$ | async)">
					<div *ngIf="canRead$ | async; else permissionDenied" class="layout-wrapper">
						<calao-layout [params]="params"></calao-layout>
					</div>
				</ng-container>
			</ng-container>
		</ng-container>


		<ng-template #blankInfo>
			<calao-blank-information *ngIf="!(observableIsLoading.value$ | async); else loading" class="middle-align"
				title="Affichage impossible" subtitle="Impossible d'afficher l'entité." icon="alert-circle">
			</calao-blank-information>
		</ng-template>

		<ng-template #error>
			<calao-error-message></calao-error-message>
		</ng-template>

		<ng-template #loading>
			<osapp-spinner></osapp-spinner>
		</ng-template>

		<ng-template #permissionDenied>
			<calao-blank-information class="middle-align" title="Permission refusée"
				subtitle="Vous n'avez pas la permission d'accéder à cette entité." icon="alert-circle">
			</calao-blank-information>
		</ng-template>
	</div>

</ion-content>