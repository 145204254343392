import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { BadgeModule } from '../badge/badge.module';
import { EntitiesModule } from '../entities/entities.module';
import { FilterModule } from '../filter/filter.module';
import { HeaderModule } from '../header/header.module';
import { ListsModule } from '../lists/lists.module';
import { ModalModule } from '../modal';
import { SelectorModule } from '../selector/selector.module';
import { SlidingListModule } from '../sliding-list/sliding-list.module';
import { SpinnerModule } from '../spinner';
import { VirtualScrollModule } from '../virtual-scroll/virtual-scroll.module';
import { AddDocumentModalComponent } from './components/add-document-modal/add-document-modal.component';
import { DocExplorerFilterBarComponent } from './components/doc-explorer-filter-bar/doc-explorer-filter-bar.component';
import { DocExplorerSlideComponent } from './components/doc-explorer-slide/doc-explorer-slide.component';
import { DocExplorerComponent } from './components/doc-explorer/doc-explorer.component';
import { DocPickerComponent } from './components/doc-picker/doc-picker.component';
import { DocumentHeaderComponent } from './components/document-header/document-header.component';
import { DocumentItemComponent } from './components/document-item/document-item.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { FolderItemComponent } from './components/folder-item/folder-item.component';
import { FolderListComponent } from './components/folder-list/folder-list.component';
import { SelectSubFolderModalComponent } from './components/select-sub-folder-modal/select-sub-folder-modal.component';

const components: Type<any>[] = [
	DocExplorerComponent,
	DocExplorerSlideComponent,
	AddDocumentModalComponent,
	SelectSubFolderModalComponent,
	DocumentListComponent,
	FolderListComponent,
	DocumentItemComponent,
	FolderItemComponent,
	DocPickerComponent,
	DocumentHeaderComponent,
	DocExplorerFilterBarComponent
];

@NgModule({
	imports: [
		IonicModule,
		CommonModule,
		VirtualScrollModule,
		ListsModule,
		ScrollingModule,
		ModalModule,
		SelectorModule,
		SpinnerModule,
		SlidingListModule,
		BadgeModule,
		EntitiesModule,
		HeaderModule,
		FilterModule
	],
	exports: components,
	declarations: components,
	providers: []
})
export class DocExplorerModule { }