import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ITourItemParams } from '../models/ITourItemParams';

@Component({
	selector: "calao-tour-item",
	templateUrl: './tour-item.component.html',
	styleUrls: ['./tour-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TourItemComponent implements ITourItemParams {

	//#region FIELDS

	@Output("click") private readonly moClickedEvent = new EventEmitter<void>();

	//#endregion

	//#region PROPERTIES

	/** @implements */
	@Input() public titleIcon: string;
	/** @implements */
	@Input() public title: string;
	/** @implements */
	@Input() public subtitle: string;
	/** @implements */
	@Input() public status: string;
	/** @implements */
	@Input() public note: string;
	/** @implements */
	@Input() public logo?: string;

	//#endregion

	//#region METHODS

	public constructor() { }

	public raiseClickedEvent(poEvent: Event): void {
		poEvent.stopPropagation(); // On ne fait pas remonter l'événement du clic.
		this.moClickedEvent.emit();
	}

	//#endregion

}