import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { ArrayHelper } from '../../../helpers/arrayHelper';
import { ITaskListItem } from '../../logistics/task/models/itask-list-item';

@Component({
	selector: 'calao-tasks-list',
	templateUrl: './tasks-list.component.html',
	styleUrls: ['./tasks-list.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksListComponent {

	//#region PROPERTIES

	/** Tâches à afficher. */
	private maTaskListItems: ITaskListItem[] = [];
	public get taskListItems(): ITaskListItem[] { return this.maTaskListItems; }
	@Input() public set taskListItems(paValues: ITaskListItem[]) {
		if (paValues)
			this.maTaskListItems = paValues.sort((poItemA: ITaskListItem, poItemB: ITaskListItem) => ArrayHelper.compareByProperty(poItemA.task, poItemB.task, "order"));
	};

	/** Liste des templates utilisés pour afficher des tâches. */
	@Input() public templates: Array<TemplateRef<any>>;

	//#endregion

	//#region METHODS

	constructor() { }

	//#endregion

}