import { ArrayHelper } from "@calaosoft/osapp/helpers/arrayHelper";
import { CustomerTourService } from "@calaosoft/osapp/modules/customer-tour/services/customer-tour.service";
import { IStock } from "@calaosoft/osapp/modules/logistics/stock/models/istock";
import { StockService } from "@calaosoft/osapp/modules/logistics/stock/services/stock.service";
import { tapComplete } from "@calaosoft/osapp/modules/utils/rxjs/operators/tap-complete";
import { TaskBase } from "@calaosoft/osapp/services/backgroundTask/TaskBase";
import { TaskDescriptor } from "@calaosoft/osapp/services/backgroundTask/TaskDescriptor";
import { FlagService } from "@calaosoft/osapp/services/flag.service";
import { InjectorService } from "@calaosoft/osapp/services/injector.service";
import { Observable, combineLatest, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { EMerchFlag } from "../emerch-flag";

export class RemoveObsoleteCustomerHistoryTask extends TaskBase {

	//#region FIELDS

	private static readonly C_LOG_ID = "RMV.OBSLT.CUST.HSTR.TSK::";

	private readonly msvcCustomerTour: CustomerTourService | undefined;
	private readonly msvcStock: StockService | undefined;
	private readonly msvcFlag: FlagService | undefined;

	//#endregion FIELDS

	//#region METHODS

	constructor(poDescriptor: TaskDescriptor) {
		super(poDescriptor);

		this.msvcCustomerTour = InjectorService.instance.get(CustomerTourService);
		this.msvcStock = InjectorService.instance.get(StockService);
		this.msvcFlag = InjectorService.instance.get(FlagService);

		if (!this.msvcCustomerTour)
			console.error(`${RemoveObsoleteCustomerHistoryTask.C_LOG_ID}CustomerTourService is undefined, can not exec task !`);
		if (!this.msvcStock)
			console.error(`${RemoveObsoleteCustomerHistoryTask.C_LOG_ID}StockService is undefined, can not exec task !`);
		if (!this.msvcFlag)
			console.error(`${RemoveObsoleteCustomerHistoryTask.C_LOG_ID}FlagService is undefined, can not exec task !`);
	}

	/** @override */
	public execTask$(): Observable<void> {
		if (this.msvcCustomerTour && this.msvcStock && this.msvcFlag) {
			return combineLatest([this.msvcCustomerTour.getCustomerIdsAsync(), this.msvcStock!.getAllStocksAsync()])
				.pipe(
					mergeMap(([paCustomerIds, paStocks]: [string[], IStock[]]) =>
						this.msvcStock!.removeStocksAsync(this.getToRemoveStocks(paCustomerIds, paStocks))
					),
					tapComplete(() => this.msvcFlag!.setFlagValue(EMerchFlag.obsoleteStocksRemoved, true))
				);
		}
		else
			return of(undefined).pipe(tapComplete(() => this.msvcFlag?.setFlagValue(EMerchFlag.obsoleteStocksRemoved, true)));
	}

	private getToRemoveStocks(paCustomerIds: string[], paStocks: IStock[]): IStock[] {
		const laToRemoveStocks: IStock[] = [];
		const laWarehouseIds: string[] = paCustomerIds.map((psCustomerId: string) => this.msvcStock!.extractWarehouseIdFromCustomerId(psCustomerId));

		// On groupe les stocks par 'warehouseId' pour faciliter la suppression en cas de plusieurs version pour un même 'warehouseId'.
		ArrayHelper.groupBy(paStocks, (poStock: IStock) => poStock.warehouseId)
			.forEach((paGroupedStocks: IStock[], psStockWarehouseId: string) => {
				// Si tous les identifiants d'entrepôts sont différents de l'identifiant de l'entrepôt courant, il faut supprimer tous les stocks associés.
				if (laWarehouseIds.every((psWarehouseId: string) => psWarehouseId !== psStockWarehouseId))
					laToRemoveStocks.push(...paGroupedStocks);

				else if (paGroupedStocks.length > 1) { // Suppression des versions obsolètes.
					// On trie les stocks par version de la plus ancienne à la plus récente.
					const laSortedGroupedStocks: IStock[] = ArrayHelper.dynamicSort(paGroupedStocks, "version");
					// On supprime le dernier car c'est celui avec la version à jour, on veut le conserver.
					laSortedGroupedStocks.pop();
					laToRemoveStocks.push(...laSortedGroupedStocks);
				}
				// Sinon : un seul stock associé à un entrepôt utilisé, on le conserve.
			});

		return laToRemoveStocks;
	}

	//#endregion METHODS

}