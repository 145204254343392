import { NgModule } from '@angular/core';
import { PackReasonService } from './services/pack-reason.service';
import { PackService } from './services/pack.service';

@NgModule({
	declarations: [],
	imports: [],
	providers: [PackService, PackReasonService]
})
export class PackModule { }
