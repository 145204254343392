import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { IonicModule } from '@ionic/angular';
import { ListsModule } from '../../lists/lists.module';
import { SqlModule } from '../../sqlite/sql.module';
import { ChangeTrackedDatabaseDetailComponent } from './components/change-tracked-database-detail/change-tracked-database-detail.component';
import { BrowserChangeTrackingService } from './services/browser-change-tracking.service';
import { ChangeTrackingService } from './services/change-tracking.service';
import { MobileChangeTrackingService } from './services/mobile-change-tracking.service';

@NgModule({
	declarations: [ChangeTrackedDatabaseDetailComponent],
	exports: [ChangeTrackedDatabaseDetailComponent],
	imports: [SqlModule, ListsModule, IonicModule, CommonModule],
	providers: [
		{
			provide: ChangeTrackingService,
			useClass: ["android", "ios"].includes(Capacitor.getPlatform()) ?
				MobileChangeTrackingService :
				BrowserChangeTrackingService
		},
		BrowserChangeTrackingService,
		MobileChangeTrackingService
	]
})
export class ChangeTrackingModule { }
