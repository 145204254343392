import { ETypeCriterion } from "./etype-criterion";
import { IControlCriterion } from "./icontrol-criterion";

export abstract class ControlCriterion implements IControlCriterion {

	//#region PROPERTIES

	/** @implements */
	public readonly type: ETypeCriterion;

	public constructor(
		/** @implements */
		public readonly negate: boolean = false
	) { }

	//#endregion

}