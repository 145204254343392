import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomerTourService } from './services/customer-tour.service';

@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [CustomerTourService]
})
export class CustomerTourModule { }
