import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NumberHelper } from '../../../../../helpers/numberHelper';
import { ObserveProperty } from '../../../../observable/decorators/observe-property.decorator';
import { ObservableProperty } from '../../../../observable/models/observable-property';
import { DestroyableComponentBase } from '../../../../utils/components/destroyable-component-base';
import { secure } from '../../../../utils/rxjs/operators/secure';
import { ICountingCategory } from '../models/ICountingCategory';
import { ILotEditComponentParam } from '../models/ILotEditComponentParam';

@Component({
	selector: "calao-lot-edit",
	templateUrl: './lot-edit.component.html',
	styleUrls: ['./lot-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotEditComponent extends DestroyableComponentBase implements ILotEditComponentParam {

	//#region FIELDS

	/** Fonction appelée si l'utilisateur veut ajouter la valeur au lot courant. */
	@Output("addToLot") private readonly moAddToLotEvent = new EventEmitter<number>();
	/** Fonction appelée si l'utilisateur veut ajouter la valeur à un nouveau lot. */
	@Output("addNewLot") private readonly moAddNewLotEvent = new EventEmitter<number>();

	//#endregion FIELDS

	//#region PROPERTIES

	/** @implements */
	@Input() public category: ICountingCategory;
	@ObserveProperty<LotEditComponent>({ sourcePropertyKey: "category" })
	public readonly observableCategory = new ObservableProperty<ICountingCategory>();

	/** @implements */
	@Input() public lastLot: number;
	@ObserveProperty<LotEditComponent>({ sourcePropertyKey: "lastLot" })
	public readonly observableLastLot = new ObservableProperty<number>();

	/** @implements */
	@Input() public canAddNewLot: boolean;
	@ObserveProperty<LotEditComponent>({ sourcePropertyKey: "canAddNewLot" })
	public readonly observableCanAddNewLot = new ObservableProperty<boolean>();

	/** @implements */
	@Input() public totalCategoryItems: number;
	@ObserveProperty<LotEditComponent>({ sourcePropertyKey: "totalCategoryItems" })
	public readonly observableTotalCategoryItems = new ObservableProperty<number>();

	public readonly observableValue = new ObservableProperty<number>();

	/** Vérifie si on peut ajouter un nouveau lot ou lot existant. */
	public readonly isValid$: Observable<boolean> = this.getIsValid$().pipe(secure(this));

	//#endregion

	//#region METHODS

	constructor() { super(); }

	private getIsValid$(): Observable<boolean> {
		return this.observableValue.value$.pipe(map((pnValue: number) => NumberHelper.isValidPositive(pnValue)));
	}

	public onAddToLot(): void {
		if (NumberHelper.isValidPositive(this.observableValue.value))
			this.moAddToLotEvent.emit(this.observableValue.value);
	}

	public onAddToNewLot(): void {
		this.moAddNewLotEvent.emit(this.observableValue.value);
	}

	public valueChanged(pnValue: number): void {
		this.observableValue.value = pnValue;
	}

	//#endregion

}