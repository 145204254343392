<ion-content>
	<scandit-render [params]="params" (onRead)="onRead($event)"></scandit-render>

	<div class="current-status">
		Articles ({{ acquisitions?.length }}) (total {{ totalScanned }}):

		<ng-container #scanArticleSummary></ng-container>
	</div>
</ion-content>

<!-- Gestion des fab-buttons. -->
<ion-fab horizontal="end" vertical="bottom" slot="fixed">
	<ion-fab-button>
		<ion-icon name="caret-back-circle"></ion-icon>
	</ion-fab-button>
	<ion-fab-list side="start">
		<!-- Validation de la modale. -->
		<ion-fab-button color="success" (click)="validate()">
			<ion-icon name="checkmark"></ion-icon>
		</ion-fab-button>
		<!-- Changement de l'état du flash. -->
		<ion-fab-button (click)="flash = !flash">
			<ion-icon [name]="flash ? 'flash-off-outline' : 'flash-outline'"></ion-icon>
		</ion-fab-button>
	</ion-fab-list>
</ion-fab>